import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Alert, Button, DateField, MenuItem, SelectField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { ReadTextField } from '@icoach/components';
import useCabinetApi from '@apis/useCabinetApi';
import { refIsRequiredError } from '@util/utils';
import { getToday } from '@util/moment';

const CabinetDetailContent = React.forwardRef((props, ref) => {
    const { className, data, coachesOption = [] } = props;
    const { cabinetStatusText = '租借中' } = data;
    const employeeRef = useRef();
    const dateRef = useRef();
    const today = getToday();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
                }
                if (dateRef.current && dateRef.current.getResult) {
                    result = Object.assign(result, {
                        createCabinetDate: dateRef.current.getResult(),
                    });
                }
                return result;
            },
            isError: () => refIsRequiredError(employeeRef, dateRef),
        }), // eslint-disable-next-line
        [],
    );
    return (
        <React.Fragment>
            <Grid className={className} spacing={3} container>
                <Grid xs={12} item>
                    <ReadTextField label={'狀態'}>{cabinetStatusText}</ReadTextField>
                </Grid>
                <Grid xs={12} item>
                    <SelectField label="擔當" ref={employeeRef} fullWidth required>
                        <MenuItem value={''}>
                            <em>請選擇</em>
                        </MenuItem>
                        {coachesOption.map(({ text, value }) => (
                            <MenuItem value={value} key={value}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid xs={12} item>
                    <DateField label={'修改日期'} ref={dateRef} defaultValue={today} fullWidth />
                </Grid>
            </Grid>
        </React.Fragment>
    );
});

const DeleteCabinetDialog = (props) => {
    const { className, open, sourceData, onClose, refresh } = props;
    const { cabinetID } = sourceData || {};
    const { getInitCabinetApi, deleteCabinetApi } = useCabinetApi();
    const [data, setData] = useState({});
    const { resource = {}, cabinet: cabinetData = {} } = data;
    const { employeeOption = [] } = resource;
    const formRef = useRef();

    const doInitCabinetApi = async (cabinetID) => {
        let res = await getInitCabinetApi(cabinetID);
        if (res) {
            setData(res);
        }
    };

    const doCabinetApi = async (cabinetID, params) => {
        let res = await deleteCabinetApi(cabinetID, params);
        if (res) {
            if (typeof refresh === 'function') refresh();
            if (typeof onClose === 'function') onClose();
        }
    };

    const getParams = () => {
        let result = {};
        if (formRef.current && formRef.current.getResult) Object.assign(result, formRef.current.getResult());
        return result;
    };

    const handleSubmit = () => {
        let isError = refIsRequiredError(formRef);
        if (!isError) {
            let params = getParams();
            doCabinetApi(cabinetID, params);
        }
    };

    useEffect(() => {
        if (cabinetID && open) doInitCabinetApi(cabinetID);
        // eslint-disable-next-line
    }, [cabinetID, open]);

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>確認刪除置物櫃</GradientColorHeader>
            <DialogContent className="p-4">
                <CabinetDetailContent className={'mb-3'} ref={formRef} data={cabinetData} coachesOption={employeeOption} />
                <Alert className="mb-3" severity="error" variant="filled" color="error">
                    請注意，一旦刪除將無法恢復
                </Alert>
            </DialogContent>
            <DialogActions className="px-4 pt-0 pb-2">
                <Button variant="contained" onClick={handleSubmit}>
                    確認刪除
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteCabinetDialog;
