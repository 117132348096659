import React, { useState, useRef, useLayoutEffect, useImperativeHandle } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import { TextField, CheckboxField, Alert } from '@common/components/';
import { PaidList } from '@icoach/documents/components/';
import { ReadTextField, SignBox } from '@icoach/components/';
import { displayDateText } from '@util/moment';
import { getRadioFieldResult } from '@util/utils';

const FeeItem = React.forwardRef((props, ref) => {
    const { temporarySourceDataRef, isReadOnly = false } = props;
    const {
        expectedFirstMonthFee: expectedFirstMonthFeeProps,
        cashPaymentAmount,
        cardPaymentAmount,
        isImmediatelyIssuing = true,
    } = temporarySourceDataRef.current;

    const [expectedFirstMonthFee, setExpectedFirstMonthFee] = useState(expectedFirstMonthFeeProps);
    const paymentAmountRef = useRef(null);
    const immediatelyIssuingRef = useRef(null);
    const total = expectedFirstMonthFee;

    const handleFirstMonthFeeOnChange = (e) => {
        setExpectedFirstMonthFee(e.target.value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const { cashPaymentAmount, cardPaymentAmount } = paymentAmountRef.current.getResult();
                const immChecked = immediatelyIssuingRef.current.getResult();
                const params = {
                    expectedFirstMonthFee,
                    cashPaymentAmount: cashPaymentAmount * 1,
                    cardPaymentAmount: cardPaymentAmount * 1,
                    isImmediatelyIssuing: getRadioFieldResult(immChecked, 'checked', false),
                };
                return params;
            },
        }),
        // eslint-disable-next-line
        [expectedFirstMonthFee]
    );

    return (
        <React.Fragment>
            <PaidList
                ref={paymentAmountRef}
                title="初次費用"
                cashPaymentAmountProps={{
                    defaultValue: cashPaymentAmount,
                    readOnly: isReadOnly,
                    min: 0,
                }}
                cardPaymentAmountProps={{
                    defaultValue: cardPaymentAmount,
                    readOnly: isReadOnly,
                    min: 0,
                }}
                readOnly={isReadOnly}
                className="mb-3"
                total={total}
            >
                <PaidList.PaidListItem label="當月月費" required>
                    <TextField
                        maskType={'MONEY'}
                        value={expectedFirstMonthFee}
                        onChange={handleFirstMonthFeeOnChange}
                        inputProps={{ placeholder: 0, min: 0 }}
                        readOnly={isReadOnly}
                        required
                    />
                </PaidList.PaidListItem>
            </PaidList>
            <CheckboxField ref={immediatelyIssuingRef} className={'mb-1'} defaultChecked={[isImmediatelyIssuing]}>
                <CheckboxField.Checkbox label={'是否收款並直接開立發票'} value={true} />
            </CheckboxField>
            <Alert className={'alert-justify-content-start'} severity={'info'} variant={'filled'} color={'info'}>
                若未直接開立，需到 新增收入 確認結帳方可確認收款及開立
            </Alert>
        </React.Fragment>
    );
});

// 初次費用
const MembershipTransferInFee = React.forwardRef((props, ref) => {
    const { temporarySourceDataRef, isReadOnly = false } = props;
    const [refreshKey, setRefreshKey] = useState(null);
    const { contractStartDate, contractEndDate, memberShipStartDate, memberShipEndDate, firstCheckContract, firstSign } = temporarySourceDataRef.current;
    const feeItemRef = useRef(null);
    const firstCheckContractRef = useRef(null);
    const firstSignRef = useRef(null);

    const setParams = () => {
        if (isReadOnly) return false;
        const { expectedFirstMonthFee, cashPaymentAmount, cardPaymentAmount, isImmediatelyIssuing } = feeItemRef.current.getResult();
        const isConfirm = (firstCheckContractRef.current.getResult() || []).find(({ checked }) => checked);

        temporarySourceDataRef.current.expectedFirstMonthFee = expectedFirstMonthFee;
        temporarySourceDataRef.current.cashPaymentAmount = cashPaymentAmount;
        temporarySourceDataRef.current.cardPaymentAmount = cardPaymentAmount;
        temporarySourceDataRef.current.isImmediatelyIssuing = isImmediatelyIssuing;
        temporarySourceDataRef.current.firstCheckContract = isConfirm ? isConfirm.checked : false;
        temporarySourceDataRef.current.firstSign = firstSignRef.current.getResult();
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => setParams(),
        }),
        // eslint-disable-next-line
        []
    );

    useLayoutEffect(
        () => {
            setRefreshKey(uuid());
            return () => setParams();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment key={refreshKey}>
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <FeeItem ref={feeItemRef} temporarySourceDataRef={temporarySourceDataRef} isReadOnly={isReadOnly} />
                </Grid>
                <Grid xs={6} item>
                    <Grid container spacing={3}>
                        <Grid xs={12} item>
                            <ReadTextField label="合約期間">{displayDateText(contractStartDate, contractEndDate)}</ReadTextField>
                        </Grid>
                        <Grid xs={12} item>
                            <ReadTextField label="會籍期間">{displayDateText(memberShipStartDate, memberShipEndDate)}</ReadTextField>
                        </Grid>
                        <Grid xs={12} item>
                            <CheckboxField ref={firstCheckContractRef} defaultChecked={[firstCheckContract]} readOnly={isReadOnly} required>
                                <CheckboxField.Checkbox label="本人已了解會籍內容，並確認使用。" />
                            </CheckboxField>
                        </Grid>
                        {!isReadOnly && (
                            <Grid xs={12} item>
                                <SignBox ref={firstSignRef} className="m-0" defaultValue={firstSign} title="會員簽名" />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
});

export default MembershipTransferInFee;
