import React, { useMemo } from 'react';
import clsx from 'clsx';
import { PROSPECT_ROUTER, CUSTOMER_ROUTER } from '@icoach/nonMember/staticData';
import { rowsLabel as cutomerRowsLabel } from '@icoach/nonMember/customer/CustomerTable';
import { rowsLabel as prospectRowsLabel } from '@icoach/nonMember/prospect/ProspectTable';
import MoreLabel from '@icoach/nonMember/components/MoreLabel';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import IconGroupHeader from '@icoach/nonMember/components/IconGroupHeader';
import Tablenization from '@icoach/components/Tablenization';
import { FormControlLabel, Checkbox } from '@common/components/';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';

const temporaryMoreLabel = () => ({
    cellKey: 'more',
    headerLabel: '',
    align: 'right',
    isIcon: true,
    formatCell: (cellValue, rowData) => {
        return <MoreLabel moreActionArray={['removetemp']} sourceData={rowData} {...(rowData || {})} />;
    },
});

const TemporariesInfoTable = (props) => {
    const { setBookmarkNum } = useNonMember();
    const { sourceData = {}, type: propsType, refresh } = props;
    let { pageIndex: page = 1, totalPage: count = 1, list: rowsData = [] } = sourceData;
    const [handleCheckAllChange, handleCheckedChange, selectedAllRef, selectedOptRef, indeterminateRef] = useGroupCheckbox(rowsData);

    const tableTitleLabel = useMemo(() => {
        const overviewLabelMap = {
            [PROSPECT_ROUTER]: prospectRowsLabel,
            [CUSTOMER_ROUTER]: cutomerRowsLabel,
        };
        let targetLabel = [...overviewLabelMap[propsType], temporaryMoreLabel()];
        return targetLabel;
    }, [propsType]);

    const handlePageChange = (e, num) => setBookmarkNum(num);

    return (
        <div className="customer-table">
            <div className="customer-table-action">
                <div className={clsx('customer-table-action-icon', rowsData.length === 0 && 'visibility-hidden')}>
                    <FormControlLabel
                        value="all"
                        label="全選"
                        control={
                            <Checkbox
                                color="primary"
                                indeterminateRef={indeterminateRef}
                                inputRef={(el) => (selectedAllRef.current = el)}
                                onChange={handleCheckAllChange}
                            />
                        }
                    />
                    <IconGroupHeader viewIconArray={['removetemp']} selectedRef={selectedOptRef} rowsData={rowsData} refresh={refresh.current} />
                </div>
            </div>
            <div>
                <Tablenization isPagination page={page} count={count} onChange={handlePageChange}>
                    <Tablenization.TableRowHeader headerRow={tableTitleLabel} />
                    <Tablenization.TableRowBody headerRow={tableTitleLabel} rowsData={rowsData} chkRef={selectedOptRef} checkedOnChange={handleCheckedChange} />
                </Tablenization>
            </div>
        </div>
    );
};

export default TemporariesInfoTable;
