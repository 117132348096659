import React, { createContext, useContext } from 'react';

const CartTableItemContext = createContext(undefined);

export const CartTableItemProvider = ({ children, value }) => {
    return <CartTableItemContext.Provider value={value}>{children}</CartTableItemContext.Provider>;
};

export const useCartTableItemContext = () => {
    const context = useContext(CartTableItemContext);
    if (context === undefined) {
        throw new Error('useCartTableItemContext 必須在 CartTableItemProvider 中使用');
    }
    return context;
};
