import React, { useState, useImperativeHandle, useEffect } from 'react';
import clsx from 'clsx';
import { Typography, FormHelperText } from '../../components';
import { SignatureDialog } from './index';

const SignBox = React.forwardRef((props, ref) => {
    const {
        title = '簽名',
        defaultValue = '',
        required = false,
        isError: propsError = false,
        helperText = '此選項為必填，不得為空值',
        readOnly = false,
        className,
    } = props;
    let [isError, setError] = useState(propsError);
    let [isOpen, toggleOpen] = useState(false);
    let [signImg, setSingImg] = useState(defaultValue);
    let classNames = 'sign-box';

    if (isError) classNames = clsx(classNames, 'sign-box-error');

    let handlePNG = (e, dataURL) => {
        setSingImg(dataURL);
    };

    let handleToggle = (toggle) => {
        toggleOpen(toggle);
    };

    useEffect(
        () => {
            setSingImg(defaultValue);
        },
        // eslint-disable-next-line
        [defaultValue],
    );

    useEffect(
        () => {
            if (propsError !== isError) setError(propsError);
        },
        // eslint-disable-next-line
        [propsError],
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => signImg,
            isError: () => {
                let isError = required;

                if (signImg) isError = false;
                setError(isError);
                return isError;
            },
        }),
        // eslint-disable-next-line
        [signImg],
    );

    // 後端不會存簽名圖檔 閱讀模式不顯示
    if (readOnly) return false;

    return (
        <React.Fragment>
            <div className={clsx(classNames, className)}>
                <div className="sign-box-wrap" onClick={() => handleToggle(true)}>
                    <div className="sign-box-title-wrap">
                        <Typography variant="h4" className="sign-box-title">
                            {title}
                        </Typography>
                    </div>

                    {signImg && (
                        <div className="sign-box-img-wrap">
                            <img className="sign-box-img" src={signImg} alt={title} />
                        </div>
                    )}
                </div>
                {isError && <FormHelperText error={isError}>{helperText}</FormHelperText>}
            </div>
            <SignatureDialog
                open={isOpen}
                signImg={signImg}
                onCapture={handlePNG}
                onClose={() => {
                    handleToggle(false);
                }}
                titleText={title}
            />
        </React.Fragment>
    );
});

export default SignBox;
