import React, { useImperativeHandle, useState, useRef } from 'react';
import {
    PointsRedeemRecordDialog,
    PointsGrantDialog,
    PointsProductGuideDialog,
    PointsProductGiftRecordDialog,
    PointsProductExchangeDialog,
    PointsRedeemOrdersTasksDialog,
} from '@icoach/points/dialog/';
import useForkRef from '@util/hook/useForkRef';

const PointsRedeemDialogBox = React.forwardRef((props, ref) => {
    const dialogRef = useRef(null);
    const combinedRef = useForkRef(ref, dialogRef);
    const [openDialog, setOpenDialog] = useState(null);
    const [sourceData, setSourceData] = useState({});
    const refreshRef = useRef(null);

    const doRefresh = () => {
        refreshRef.current && typeof refreshRef.current === 'function' && refreshRef.current();
    };

    useImperativeHandle(
        combinedRef,
        () => {
            return {
                openPointsRedeemMemberRecordDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsRedeemMemberRecord');
                    refreshRef.current = _refreshFn;
                },
                openPointsGrantDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsGrant');
                    refreshRef.current = _refreshFn;
                },
                openPointsProductGuideDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsProductGuide');
                    refreshRef.current = _refreshFn;
                },
                openProductGiftRecordDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('productGiftRecord');
                    refreshRef.current = _refreshFn;
                },
                openPointsProductExchangeDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsProductExchange');
                    refreshRef.current = _refreshFn;
                },
                openPointsRedeemOrdersTasksDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsRedeemOrdersTasks');
                    refreshRef.current = _refreshFn;
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        // eslint-disable-next-line
        []
    );

    const handleCloseDialog = () => {
        setOpenDialog(null);
        setSourceData(null);
        refreshRef.current = null;
    };

    return (
        <React.Fragment>
            <PointsRedeemRecordDialog
                open={openDialog === 'pointsRedeemMemberRecord'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
            <PointsGrantDialog open={openDialog === 'pointsGrant'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            <PointsProductGuideDialog open={openDialog === 'pointsProductGuide'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            <PointsProductGiftRecordDialog open={openDialog === 'productGiftRecord'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            <PointsProductExchangeDialog
                open={openDialog === 'pointsProductExchange'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
            <PointsRedeemOrdersTasksDialog
                open={openDialog === 'pointsRedeemOrdersTasks'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
            />
        </React.Fragment>
    );
});

export default PointsRedeemDialogBox;
