import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { Dialog, DialogContent } from '@mui/material';
import { ContactDailogContentForm } from '@icoach/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Form } from '@common/components/';
import useAppointmentContactRecordApi from '@apis/useAppointmentContactRecordApi';
import { useCustomerContactRecordApi } from '@apis/useContactRecordApi';

const AppointmentContactDialog = React.forwardRef((props, ref) => {
    const { onClose: onCloseProps, open, sourceData: sourceDataProps, refresh } = props;
    const { contactID = null } = sourceDataProps || {};
    const { postAppointmentContactRecordsApi, putAppointmentContactRecordsApi } = useAppointmentContactRecordApi();
    const { getCustomerContactRecordOptionsApi } = useCustomerContactRecordApi();
    const [options, setOptions] = useState({});
    const contactDailogContentFormRef = useRef(null);

    const handleCloseClick = (e) => onCloseProps && onCloseProps(null);

    const handleAddClick = (e) => {
        if (!contactDailogContentFormRef.current.isError()) {
            const { appointmentID, peopleID, peopleType } = sourceDataProps || {};
            let params = {
                peopleID: peopleID,
                peopleType: peopleType,
                appointmentID: appointmentID,
                ...contactDailogContentFormRef.current.getResult(),
            };
            postAppointmentContactApi(appointmentID, params);
        }
    };

    const postAppointmentContactApi = async (appointmentID, params) => {
        let resp;
        if (contactID) {
            resp = await putAppointmentContactRecordsApi(appointmentID, contactID, params);
        } else {
            resp = await postAppointmentContactRecordsApi(appointmentID, params);
        }

        if (resp) {
            onCloseProps && onCloseProps(null);
            refresh && refresh.current && refresh.current();
        }
    };

    const doInitOptionsApi = async () => {
        const resp = await getCustomerContactRecordOptionsApi();
        resp && setOptions(resp);
    };

    useEffect(
        () => {
            if (open && _.isEmpty(options)) doInitOptionsApi();
        },
        // eslint-disable-next-line
        [open]
    );

    return (
        <Dialog
            PaperProps={{
                component: Form,
            }}
            onSubmit={handleAddClick}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={handleCloseClick}>{'新增聯繫'}</GradientColorHeader>
            <DialogContent className="mt-3">
                <ContactDailogContentForm key={open} ref={contactDailogContentFormRef} options={options} sourceData={sourceDataProps} />
            </DialogContent>
        </Dialog>
    );
});

export default AppointmentContactDialog;
