import React, { useEffect, useState } from 'react';
import { Box } from '../../../components';
import { CycleOrderList, CycleOrderMonthStatisticsBox } from '../components';
import { CycleOrderInvoiceDialog, CycleOrderPaymentDialog, CycleOrderShippingDialog } from '../../documents/dialog';
import useMemberCyclePurchaseApi from '../../../apis/useMemberCyclePurchaseApi';
import { useMemberDetailContext } from './MemberDetailContext';

export const dialogType = {
    payment: 'payment',
    shipping: 'shipping',
    invoice: 'invoice',
};

const MemberDetailCycleOrderContentOverview = (props) => {
    const { memberID } = props;
    const { setRefresh } = useMemberDetailContext();
    const { getMemberCycleOrderApi } = useMemberCyclePurchaseApi();
    const [orders, setOrders] = useState([]);
    const [openDialog, setOpenDialog] = useState({
        dialogType: '',
        currentIndex: '',
    });

    const doMemberCycleOrderApi = async (memberID) => {
        let res = await getMemberCycleOrderApi(memberID);
        if (res) {
            setOrders(res);
        }
    };
    const handleShowDialog = (index) => {
        return (dialogType) => {
            setOpenDialog({
                dialogType,
                currentIndex: index,
            });
        };
    };
    const handleCloseDialog = () => {
        setOpenDialog({
            dialogType: '',
            currentIndex: '',
        });
    };

    useEffect(() => {
        if (memberID) {
            setRefresh(doMemberCycleOrderApi);
            doMemberCycleOrderApi(memberID);
        }
        // eslint-disable-next-line
    }, [memberID]);

    return (
        <Box>
            <CycleOrderMonthStatisticsBox className={'mb-4'} memberID={memberID} />
            <CycleOrderList data={orders} handleShowDialog={handleShowDialog} />
            <CycleOrderPaymentDialog
                open={openDialog.dialogType === dialogType.payment}
                data={orders[openDialog.currentIndex]?.payment}
                onClose={handleCloseDialog}
            />
            <CycleOrderInvoiceDialog
                open={openDialog.dialogType === dialogType.invoice}
                data={orders[openDialog.currentIndex]}
                onClose={handleCloseDialog}
            />
            <CycleOrderShippingDialog open={openDialog.dialogType === dialogType.shipping} data={orders[openDialog.currentIndex]} onClose={handleCloseDialog} />
        </Box>
    );
};

export default MemberDetailCycleOrderContentOverview;
