import React from 'react';
import clsx from 'clsx';

const staticClass = `table-container`;

const TableContainer = React.forwardRef((props, ref) => {
    const { className, height, isSkipOverflow, ...other } = props;
    let classNames = clsx(staticClass, isSkipOverflow ? 'table-container-overflow-unset' : '');

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };
    if (height) defaultProperty.style = { height: height };

    return <div {...defaultProperty} />;
});

export default TableContainer;
