import React, { useEffect, useImperativeHandle } from 'react';
import clsx from 'clsx';
import TouchRipple from '../ripple/TouchRipple';

const staticClass = `button-base`;

const ButtonBaseComponent = React.forwardRef((props, ref) => {
    const { as: Component, href, to, ...other } = props;
    let defaultProperty = {
        ...other,
    };
    if (Component === 'button' && !props.type) defaultProperty.type = 'button';
    else if (Component === 'a') defaultProperty.href = to || href;
    else defaultProperty.to = to;
    return <Component ref={ref} {...defaultProperty} />;
});

const ButtonBase = React.forwardRef((props, ref) => {
    const {
        action,
        component = `button`,
        LinkComponent = `a`,
        centerRipple = false,
        children,
        className,
        disabled = false,
        disableRipple = false,
        disableTouchRipple = false,
        focusRipple = false,
        // focusVisibleClassName,
        onBlur,
        onClick,
        onContextMenu,
        onDragLeave,
        onFocus,
        onFocusVisible,
        onKeyDown,
        onKeyUp,
        onMouseDown,
        onMouseLeave,
        onMouseUp,
        onTouchEnd,
        onTouchMove,
        onTouchStart,
        tabIndex = 0,
        // TouchRippleProps, CSS props
        type,
        ...other
    } = props;
    let classNames = staticClass;
    let ComponentProp = component;

    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (ComponentProp === 'button' && (other.href || other.to)) {
        ComponentProp = LinkComponent;
    }

    const buttonRef = React.useRef(null); //DOM
    const rippleRef = React.useRef(null); //DOM
    const [mountedState, setMountedState] = React.useState(false);
    const [focusVisible, setFocusVisible] = React.useState(false);

    useImperativeHandle(
        action,
        () => ({
            focusVisible: () => {
                setFocusVisible(true);
                buttonRef.current.focus();
            },
        }),
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        if (focusVisible && focusRipple && !disableRipple) {
            rippleRef.current.pulsate();
        }
    }, [disableRipple, focusRipple, focusVisible]);

    useEffect(() => {
        setMountedState(true);
    }, []);

    const useRippleHandler = (
        rippleAction,
        eventCallback,
        skipRippleAction = disableTouchRipple
    ) => {
        return (event) => {
            const ignore = skipRippleAction;
            if (eventCallback) eventCallback(event);
            if (!ignore && rippleRef.current) {
                rippleRef.current[rippleAction](event);
            }
        };
    };

    // const handleBlur = useRippleHandler(
    //     'stop',
    //     (event) => {
    //         handleBlurVisible(event);
    //         if (isFocusVisibleRef.current === false) {
    //             setFocusVisible(false);
    //         }
    //         if (onBlur) {
    //             onBlur(event);
    //         }
    //     },
    //     false
    // );
    const handleContextMenu = useRippleHandler('stop', onContextMenu);
    const handleMouseDown = useRippleHandler('start', onMouseDown);
    const handleMouseUp = useRippleHandler('stop', onMouseUp);
    const handleDragLeave = useRippleHandler('stop', onDragLeave);
    const handleMouseLeave = useRippleHandler('stop', (event) => {
        focusVisible && event.preventDefault();
        onMouseLeave && onMouseLeave(event);
    });
    const handleTouchStart = useRippleHandler('start', onTouchStart);
    const handleTouchEnd = useRippleHandler('stop', onTouchEnd);
    const handleTouchMove = useRippleHandler('stop', onTouchMove);

    const enableTouchRipple = mountedState && !disableRipple && !disabled;

    React.useEffect(() => {
        if (enableTouchRipple && !rippleRef.current) {
            console.error(
                [
                    'MUI: The `component` prop provided to ButtonBase is invalid.',
                    'Please make sure the children prop is rendered in this custom component.',
                ].join('\n')
            );
        }
    }, [enableTouchRipple]);

    const defaultProperty = {
        className: clsx(classNames, className),
        as: ComponentProp,
        tabIndex: disabled ? -1 : tabIndex,
        onClick: (e) => {
            onClick && onClick(e);
        },
        onContextMenu: handleContextMenu,
        onMouseDown: handleMouseDown,
        onMouseUp: handleMouseUp,
        onMouseLeave: handleMouseLeave,
        onDragLeave: handleDragLeave,
        onTouchStart: handleTouchStart,
        onTouchEnd: handleTouchEnd,
        onTouchMove: handleTouchMove,
        type,
        ref,
        ...other,
    };

    return (
        <ButtonBaseComponent {...defaultProperty}>
            {children}
            {enableTouchRipple ? (
                /* TouchRipple is only needed client-side, x2 boost on the server. */
                <TouchRipple ref={rippleRef} center={centerRipple} />
            ) : null}
        </ButtonBaseComponent>
    );
});

export default ButtonBase;
