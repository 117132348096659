import React from 'react';
import useInBody from './useInBody';
import { Typography, IconButton } from '../../../components';
import { BorderColor as BorderColorIcon } from '../../../components/icons/SvgIcon/';

const InBodyInterviewNote = React.forwardRef((props, ref) => {
    const { value, sourceData = {}, measureID } = props;
    const { dialogRef } = useInBody();
    const handleOnClick = () => {
        dialogRef.current.openInterviewNotesDialog(sourceData, measureID);
    };

    return (
        <div className="notes-content cursor-pointer" onClick={handleOnClick}>
            {value ? (
                <Typography variant="h6" component={'p'}>
                    {value}
                </Typography>
            ) : (
                <IconButton className="flex-align-self-center">
                    <BorderColorIcon />
                </IconButton>
            )}
        </div>
    );
});

export default InBodyInterviewNote;
