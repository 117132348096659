import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const staticClass = `divider`;
const staticSubClass = `${staticClass}-wrapper`;

const orientationMap = ['horizontal', 'vertical'];
const variantMap = ['inset', 'middle', 'inset'];
const textAlignMap = ['center', 'left', 'right'];

const DividerWrapper = (props) => {
    const { className, orientation, children, ...other } = props;
    let classNames = staticSubClass;

    if (orientation === 'vertical') classNames = clsx(classNames, `${staticSubClass}-vertical`);

    return (
        <span className={clsx(classNames, className)} {...other}>
            {children}
        </span>
    );
};

const Divider = React.forwardRef((props, ref) => {
    const {
        className,
        subClassName,
        children,
        component: Component = children ? 'div' : 'hr',
        absolute = false,
        flexItem = false,
        light = false,
        orientation = 'horizontal',
        textAlign = 'center',
        variant = 'fullWidth',
        ...other
    } = props;
    let classNames = staticClass;

    if (orientationMap.indexOf(orientation) !== -1) classNames = clsx(classNames, `${staticClass}-${orientation}`);
    if (textAlignMap.indexOf(textAlign)) classNames = clsx(classNames, `${staticClass}-text-align-${textAlign}`);
    if (variantMap.indexOf(variant) !== -1) classNames = clsx(classNames, `${staticClass}-${variant}`);
    if (flexItem) classNames = clsx(classNames, `${staticClass}-flex-item`);
    if (light) classNames = clsx(classNames, `${staticClass}-light`);
    if (absolute) classNames = clsx(classNames, `${staticClass}-absolute`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ...other,
    };

    return (
        <Component {...defaultProperty}>
            {children && (
                <DividerWrapper className={subClassName} orientation={orientation}>
                    {children}
                </DividerWrapper>
            )}
        </Component>
    );
});

Divider.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * Absolutely position the element.
     * @default false
     */
    absolute: PropTypes.bool,
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * If `true`, a vertical divider will have the correct height when used in flex container.
     * (By default, a vertical divider will have a calculated height of `0px` if it is the child of a flex container.)
     * @default false
     */
    flexItem: PropTypes.bool,
    /**
     * If `true`, the divider will have a lighter color.
     * @default false
     */
    light: PropTypes.bool,
    /**
     * The component orientation.
     * @default 'horizontal'
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * @ignore
     */
    role: PropTypes.string,
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.object,
    /**
     * The text alignment.
     * @default 'center'
     */
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
    /**
     * The variant to use.
     * @default 'fullWidth'
     */
    variant: PropTypes.oneOfType([PropTypes.oneOf(['fullWidth', 'inset', 'middle']), PropTypes.string]),
};

export default Divider;
