import React from 'react';
import { Grid } from '@mui/material';
import { DateIntervalField, MenuItem, SelectField, Typography } from '@common/components/';
import { QuickMoreLabelBox, BusinessCardBox, DescribeLabelBox, rowLabelCheckboxSheet, SpecialConditionBox } from '@icoach/components/';
import { isNumber, refIsRequiredError } from '@util/utils';
import { parseDate, getAddDays, displayDateText, isValidDate } from '@util/moment';

export const FilterInvalidSearch = React.forwardRef((props, ref) => {
    const { paramsData, resource } = props;
    const { invalidMembershipStatusOption = [] } = resource;
    const dateRangeRef = React.useRef(null);
    const membershipStatusRef = React.useRef(null);

    React.useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(dateRangeRef, membershipStatusRef),
                getResult: () => {
                    const result = {};

                    if (dateRangeRef.current && dateRangeRef.current.getResult) {
                        const p1 = dateRangeRef.current.getResult() || {};
                        Object.assign(result, p1);
                    }

                    if (membershipStatusRef.current && membershipStatusRef.current.getResult) {
                        Object.assign(result, { membershipStatus: membershipStatusRef.current.getResult() });
                    }

                    return result;
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    const startDate = isValidDate(paramsData.startDate) ? paramsData.startDate : parseDate();
    const endDate = isValidDate(paramsData.endDate) ? paramsData.endDate : getAddDays(parseDate(), 1);
    const membershipStatus = isNumber(paramsData.membershipStatus) ? paramsData.membershipStatus : 99;

    return (
        <React.Fragment>
            <Grid item xs={4}>
                <DateIntervalField
                    ref={dateRangeRef}
                    label="時間區間"
                    startDateProps={{ defaultValue: startDate }}
                    endDateProps={{ defaultValue: endDate }}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <SelectField defaultValue={membershipStatus} label={'會籍狀態'} ref={membershipStatusRef} fullWidth>
                    {Array.isArray(invalidMembershipStatusOption) &&
                        invalidMembershipStatusOption
                            .filter((item) => item || !item.disabled)
                            .reverse()
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

//********************** 無效名單 **********************//
export const FilterInvalidLabel = [
    {
        ...rowLabelCheckboxSheet,
        cellKey: 'memberID',
    },
    {
        cellKey: 'memberSheet',
        headerLabel: '會員',
        width: 20,
        maxWidth: 23,
        align: 'left',
        isFixed: true,
        isFixedLeft: true,
        formatCell: (value, rowData, { routerPath, isShowAvatar = true, isShowAvatarImage = true }) => {
            const { isTransferOut } = rowData;
            let isShowLink = !isTransferOut;

            return (
                <BusinessCardBox
                    rowData={{
                        ...rowData,
                        memberNo: isTransferOut ? '移出' : rowData.memberNo,
                    }}
                    routerPath={isShowLink && routerPath}
                    isShowAvatar={isShowAvatar}
                    isShowAvatarImage={isShowAvatarImage}
                />
            );
        },
    },
    {
        cellKey: 'cellPhone',
        headerLabel: '手機',
        width: 13,
        align: 'center',
        formatCell: (value, { isTransferOut }) => {
            if (isTransferOut) return '-';
            return value;
        },
    },
    {
        cellKey: 'specialCondition',
        headerLabel: '特殊狀況',
        width: 18,
        formatCell: (cellValue, { memberID }) => {
            return <SpecialConditionBox sourceData={{ memberID, value: cellValue }} />;
        },
    },
    {
        cellKey: 'weeklyWorkoutQuantity',
        headerLabel: '本週WO',
        width: 9,
        align: 'center',
        formatCell: (value, { isTransferOut }) => {
            if (isTransferOut) return '-';
            return value;
        },
    },
    {
        cellKey: 'monthlyWorkoutFrequency',
        headerLabel: 'WO頻率',
        align: 'center',
        formatCell: (value, { isTransferOut }) => {
            if (isTransferOut) return '-';
            return value;
        },
    },
    {
        cellKey: 'membershipContinueMonth',
        headerLabel: '會籍已持續時間',
        width: 17,
        align: 'center',
        formatCell: (value, { isTransferOut }) => {
            if (isTransferOut) return '-';
            return `${value} 個月`;
        },
    },
    {
        cellKey: 'sendDate',
        headerLabel: '申請書送出日',
        width: 17,
        align: 'center',
        formatCell: (value) => {
            return displayDateText(value);
        },
    },
    {
        cellKey: 'membershipEndDate',
        headerLabel: '現行合約終止日',
        width: 17,
        align: 'center',
        formatCell: (value) => {
            return displayDateText(value);
        },
    },
    {
        cellKey: 'membershipStatusText',
        headerLabel: '會籍狀態',
        align: 'center',
    },
    {
        cellKey: 'reason',
        headerLabel: '原因',
        width: 30,
        formatCell: (value) => {
            return value;
        },
    },
    {
        cellKey: 'xFactorNotes',
        headerLabel: 'X-factor',
        className: 'white-space-normal',
        width: 30,
        formatCell: (value, { isTransferOut }) => {
            if (isTransferOut) return '-';
            return (
                <React.Fragment>
                    {Array.isArray(value) ? (
                        <React.Fragment>
                            {value.map((target) => (
                                <Typography key={target}>{target}</Typography>
                            ))}
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'contact',
        headerLabel: '聯繫',
        width: 30,
        maxWidth: 30,
        align: 'left',
        formatCell: (value, { memberID, isTransferOut }, { setContactState }) => {
            if (isTransferOut) return '-';
            return value ? <DescribeLabelBox {...value} targetID={memberID} peopelType={'2'} setContactState={setContactState} /> : null;
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        width: 6.8,
        formatCell: (value, rowData, { dialogRef, routerPath }) => {
            const { isTransferOut } = rowData;
            if (isTransferOut) return null;
            const newRowData = { ...rowData, id: rowData.memberID, type: 2 };
            return <QuickMoreLabelBox rowData={newRowData} dialogRef={dialogRef} routerPath={routerPath} />;
        },
    },
];
