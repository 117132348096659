import { SET_MESSAGE_DIALOG } from './types';
import { isEmpty } from 'lodash';
import { combineReducers } from 'redux';

const initData = {
    open: false,
    title: '訊息',
    msg: null,
    MsgComp: null,
    onClose: () => {},
    onConfirm: () => {},
    onAsyncConfirm: () => {},
    onOpen: () => {},
};

export function MessageDialogData(state = { Data: initData }, action) {
    switch (action.type) {
        case SET_MESSAGE_DIALOG: {
            if (isEmpty(action.Data)) {
                action.Data = { ...initData };
            }
            return {
                Data: {
                    ...action.Data,
                },
            };
        }
        default:
            return state;
    }
}

const Reducer = combineReducers({
    MessageDialogData,
});

export default Reducer;
