import React from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Tab, Tabs } from '@common/components/';
import { DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_ROUTER_PATH } from '@icoach/router/routerPath';

const MembershipTransferInSearchBarTabs = React.forwardRef((props, ref) => {
    const { enteredType } = useParams();

    return (
        <Tabs className="pl-2" value={enteredType} variant="scrollable" allowScrollButtonsMobile>
            <Tab
                className={'pt-3'}
                label={'未移入'}
                value={'unentered'}
                component={NavLink}
                to={DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_ROUTER_PATH('unentered')}
            />
            <Tab
                className={'pt-3'}
                label={'已移入'}
                value={'entered'}
                component={NavLink}
                to={DOCUMENTS_MEMBERSHIPTRANSFERIN_ENTEREDTYPE_ROUTER_PATH('entered')}
            />
        </Tabs>
    );
});

export default MembershipTransferInSearchBarTabs;
