import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@common/components/';
import Tablenization from '@icoach/components/Tablenization';
import { BusinessCardBox } from '@icoach/components/';
import { formatCurrencyFn } from '@util/utils';

const rowsLabel = [
    {
        cellKey: 'memberSheet',
        headerLabel: '會員',
        align: 'left',
        maxWidth: 20.4,
        formatCell: (cellValue, { memberNo, memberName, memberPicturePath }) => {
            let memberInfo = {
                memberNo,
                memberName,
                memberImg: memberPicturePath,
            };
            return <BusinessCardBox rowData={memberInfo} />;
        },
    },
    {
        cellKey: 'itemName',
        headerLabel: '品項',
        align: 'center',
    },
    {
        cellKey: 'transactionPrice',
        headerLabel: '交易金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'bankAccount',
        headerLabel: '銀行帳號',
        formatCell: (cellValue, { bankCode, bankName }) => {
            return (
                <Box className="payment-card-content">
                    {bankName && (
                        <Typography className={'sub-title font-color-3 mb-0'} variant={'body1'}>
                            {bankName}
                        </Typography>
                    )}
                    <Typography className={'title font-color-3 mb-0'} variant={'body1'}>
                        代號：{bankCode}
                    </Typography>
                    <Typography className={'title mb-0'} variant={'body1'} component={'h6'}>
                        {cellValue}
                    </Typography>
                </Box>
            );
        },
    },
    {
        cellKey: 'bankAccountName',
        headerLabel: '銀行帳戶',
        formatCell: (cellValue, { bankAccountID }) => {
            return (
                <Box className="payment-card-content">
                    <Typography className={'title mb-0'} variant={'body1'}>
                        {`戶名：${cellValue}`}
                    </Typography>
                    {bankAccountID && (
                        <Typography className={'sub-title mb-0'} variant={'body1'}>
                            {bankAccountID}
                        </Typography>
                    )}
                </Box>
            );
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        maxWidth: 30,
    },
];

const BillingPendingPaymentByAchTable = (props) => {
    const { className, rowsData = [] } = props;
    const labelTitle = useMemo(() => {
        return rowsLabel;
    }, []);

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: clsx(className, 'bulk-debit-table') }}>
                <Tablenization.TableRowHeader headerRow={labelTitle} />
                <Tablenization.TableRowBody headerRow={labelTitle} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default BillingPendingPaymentByAchTable;
