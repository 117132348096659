import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, Card, Typography } from '@common/components/';
import { formatCurrencyFn } from '@util/utils';
import { isBefore, isSame, parseDate } from '@util/moment';
import { PettyMoneyDialog } from '../dialog';

const PettyMoneyBox = (props) => {
    const { className, data = {}, isSettled = false } = props;
    const { accountingDate, fundPrice: amount = 0, fundEmployeeName } = data;
    const [isOpen, setOpenDialog] = useState(false);
    const today = parseDate();

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    return (
        <Card className={clsx('petty-money-box text-left px-4 py-3', className)}>
            <Card.CardContent>
                <Typography className={'mt-0'} variant={'body1'} component={'h3'}>
                    零用金
                </Typography>
                <div>
                    {!isSettled &&
                        (isSame(today, accountingDate) || isBefore(today, accountingDate)) && (
                            <Button
                                className={'petty-money-box-btn'}
                                variant={'outlined'}
                                onClick={() => handleOpenDialog(4)}
                            >
                                設定零用金
                            </Button>
                        )}

                    <Typography className={'amount'} variant={'h5'}>
                        {formatCurrencyFn(amount, { symbol: '$' })}
                    </Typography>
                    {fundEmployeeName && (
                        <Typography
                            className={'font-color-3 mb-0'}
                            variant={'body1'}
                        >{`-由${fundEmployeeName}編輯`}</Typography>
                    )}
                </div>
            </Card.CardContent>
            <PettyMoneyDialog open={isOpen} onClose={handleCloseDialog} />
        </Card>
    );
};

export default PettyMoneyBox;
