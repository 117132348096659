import React from 'react';
import { Card, List, ListItem, ListItemIcon, ListItemText, Typography } from '@common/components/';
import { NavigateNext as NavigateNextIcon } from '@common/SvgIcon/';
import { TableLabel } from '@icoach/dataAnalysis/components/';

// 工作項目資料
const taskMatterData = {
    weekly: [
        {
            text: '每週一開會以及上週檢討',
            isStar: false,
        },
        {
            text: '每週CA報告完成',
            isStar: false,
        },
        {
            text: '每週固定時間做GP與不同主題的會議',
            isStar: false,
        },

        {
            text: '持續舉辦店舖內部研習',
            isStar: false,
        },
    ],
    daily: [
        {
            text: 'Projection Sheet完成(內含失敗的FA)',
            isStar: false,
        },
        {
            text: '遵循跨店/轉籍及退會的政策',
            isStar: true,
        },
        {
            text: '不可加入未經認可的課程及器材',
            isStar: true,
        },
        {
            text: '不可教未經認可的運動方式',
            isStar: true,
        },
        {
            text: '不可賣未經總部授權的產品',
            isStar: true,
        },
        {
            text: '不可刊登未經授權的廣告或logo使用',
            isStar: true,
        },
    ],
    monthly: [
        {
            text: '老闆、經理、Staff 參加訓練',
            isStar: true,
        },
        {
            text: '每月3號準時繳交報表,準時繳交權利金',
            isStar: true,
        },
        {
            text: '每月完成行動計劃與會員行事曆',
            isStar: true,
        },
        {
            text: 'Club清潔、器材維護等',
            isStar: true,
        },
        {
            text: '每季員工面談',
            isStar: false,
        },
    ],
};

const DataAnalysisContentTaskMatter = () => {
    return (
        <Card>
            <Card.CardContent className={'p-4 reminders'}>
                <Typography className={'font-weight-bold'} variant={'h5'}>
                    Reminders
                </Typography>
                <Typography className={'font-color-3'}>請確認下列項目是否有執行</Typography>
                <Typography className={'sub-title mb-0'}>每週</Typography>
                <List className={'reminders-list mb-4'}>
                    {taskMatterData.weekly.map(({ text, isStar }) => (
                        <ListItem key={text}>
                            <ListItemIcon>
                                <NavigateNextIcon />
                            </ListItemIcon>
                            <ListItemText primary={<TableLabel title={text} isStar={isStar} />} />
                        </ListItem>
                    ))}
                </List>
                <Typography className={'sub-title mb-1'}>每日</Typography>
                <List className={'reminders-list mb-4'}>
                    {taskMatterData.monthly.map(({ text, isStar }) => (
                        <ListItem key={text}>
                            <ListItemIcon>
                                <NavigateNextIcon />
                            </ListItemIcon>
                            <ListItemText primary={<TableLabel title={text} isStar={isStar} />} />
                        </ListItem>
                    ))}
                </List>
                <Typography className={'sub-title mb-0'}>每月</Typography>
                <List className={'reminders-list mb-4'}>
                    {taskMatterData.daily.map(({ text, isStar }) => (
                        <ListItem key={text}>
                            <ListItemIcon>
                                <NavigateNextIcon />
                            </ListItemIcon>
                            <ListItemText primary={<TableLabel title={text} isStar={isStar} />} />
                        </ListItem>
                    ))}
                </List>
            </Card.CardContent>
        </Card>
    );
};

export default DataAnalysisContentTaskMatter;
