import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Card, Box, Divider, Alert, IconButton } from '@common/components/';
import { useHistory } from 'react-router-dom';
import { Reply as ReplyIcon } from '@common/SvgIcon/';
import useNotificationApi from '@apis/useNotificationApi';
import { NotificationTable } from '@icoach/notifications/components/';
import { initBookmark } from '@icoach/notifications/staticData';
import { isEmpty } from '@util/utils';
import { notificationMenus } from '@icoach/notifications/staticData';
import { keyBy } from 'lodash';
import { HOME_ARRIVE_ROUTER } from '@icoach/router/routerPath';

const NotificationEntireBox = (props) => {
    const { title, location, path } = props;
    const history = useHistory();
    const [sourceData, setSourceData] = useState([]);
    const [pageModel, setPageModel] = useState({ ...initBookmark() });
    const { postNotificationOverviewApi } = useNotificationApi();
    const reloadRef = useRef(false);

    const handleReturnHomePage = () => {
        history.push(HOME_ARRIVE_ROUTER);
    };

    const notificationsApiMap = useMemo(
        () => {
            return keyBy(notificationMenus, 'router');
        },
        // eslint-disable-next-line
        [],
    );

    const initApi = async (type) => {
        const { sourceType } = notificationsApiMap[type] || {};
        const params = { ...pageModel, sourceType };
        const resp = await postNotificationOverviewApi(params);
        if (resp) {
            const { list, ...pModel } = resp;
            setSourceData(list);
            setPageModel(pModel);
        }
    };

    useEffect(
        () => {
            if (!reloadRef.current) {
                initApi(path);
            }
        },
        // eslint-disable-next-line
        [location],
    );

    return (
        <Box className="notification-content">
            <Card className="notification-content-bg" elevation="4">
                <Card.CardHeader
                    title={title}
                    action={
                        <IconButton className="mr-2" onClick={handleReturnHomePage}>
                            <ReplyIcon />
                        </IconButton>
                    }
                />
                <Divider className="mx-2" />
                <Card.CardContent>
                    {!isEmpty(sourceData) ? (
                        sourceData.map((targetData, idx) => {
                            const len = sourceData.length - 1;
                            return (
                                <NotificationTable
                                    key={targetData.notificationID}
                                    sourceData={targetData}
                                    notificationType={targetData.sourceType}
                                    isLast={String(idx) === String(len)}
                                />
                            );
                        })
                    ) : (
                        <Box className="m-2">
                            <Alert variant={'filled'} severity={'info'} color={'info'}>
                                尚無資料
                            </Alert>
                        </Box>
                    )}
                </Card.CardContent>
            </Card>
        </Box>
    );
};

export default NotificationEntireBox;
