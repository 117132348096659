import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, Typography } from '@common/components/';
import { MEMBER_DETAIL_CYCLE_PURCHASE_URL } from '@icoach/router/MembersRouter';

const CycleOrderTipDialog = (props) => {
    const { className, open, memberID, onClose } = props;
    const history = useHistory();
    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-40rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>提醒</GradientColorHeader>
            <DialogContent>
                <Typography paragraph>會員有訂購健康循環訂單，提醒您修改的資料並不會更新於訂單，請檢查訂單資料是否要更新。</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => history.push(MEMBER_DETAIL_CYCLE_PURCHASE_URL(memberID))}>
                    前往會員健康產品訂購單
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CycleOrderTipDialog;
