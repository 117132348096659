import React, { useImperativeHandle, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import clsx from 'clsx';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { Button, Form, MenuItem, SelectField, TextField } from '../../../components';
import { refIsRequiredError } from '../../../util/utils';
import useOrderAccountBookApi from '../../../apis/useOrderAccountBookApi';
import { useSettleCashContext } from '../context/SettleCashContext';

const SettleCreditDialogContent = React.forwardRef((props, ref) => {
    const { data = {}, employeeOptions = [] } = props;
    const { itemName, price, employeeID } = data;
    const itemNameRef = useRef();
    const priceRef = useRef();
    const employeeRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    itemName: itemNameRef.current.getResult(),
                    price: priceRef.current.getResult(),
                    employeeID: employeeRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(itemNameRef, priceRef, employeeRef),
        }),
        []
    );

    return (
        <DialogContent>
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    <TextField label={'品項'} ref={itemNameRef} defaultValue={itemName} InputProps={{ placeholder: '例如:修繕費' }} fullWidth required />
                </Grid>
                <Grid xs={6} item>
                    <TextField label={'金額'} ref={priceRef} defaultValue={price} fullWidth required />
                </Grid>
                <Grid xs={6} item>
                    <SelectField label={'擔當'} ref={employeeRef} defaultValue={employeeID} fullWidth required>
                        {Array.isArray(employeeOptions) &&
                            employeeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const SettleCreditDialog = (props) => {
    const { className, open, itemID, itemName, price, employeeID, onClose } = props;
    const { postOrderAccountBookItemApi, putOrderAccountBookItemApi } = useOrderAccountBookApi();
    const { accountingDate, resource, refresh } = useSettleCashContext();
    const fieldRef = useRef();
    const getParams = () => {
        return {
            accountingDate,
            ...fieldRef.current.getResult(),
        };
    };

    const checkError = () => fieldRef.current.isError();

    const doOrderAccountBookItemApi = async () => {
        let res;
        let params = getParams();
        if (itemID) {
            res = await putOrderAccountBookItemApi(itemID, params);
        } else {
            res = await postOrderAccountBookItemApi(params);
        }
        if (res) {
            refresh && refresh(accountingDate);
            onClose();
        }
    };

    const handleSubmit = () => {
        if (!checkError()) doOrderAccountBookItemApi();
    };

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>新增支出</GradientColorHeader>
            <SettleCreditDialogContent ref={fieldRef} data={{ itemName, price, employeeID }} employeeOptions={resource?.coachOptions} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettleCreditDialog;
