import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import TakePhotoBox from '../components/TakePhotoBox';

import { Button } from '../../components';

const TakePhotoPage = React.forwardRef((props, ref) => {
    const takePhotoRef = useRef(null);
    const [bolbImg, setBolbImg] = useState(null);
    const uploadOnChange = () => {
        takePhotoRef.current.open();
    };

    const handleOnFileChange = (file, bolb) => {
        setBolbImg(file.blobSrc);
    };

    return (
        <div className="main-container-spacing">
            <Grid container>
                <Grid item xs={6}>
                    <Button variant="contained" onClick={uploadOnChange}>
                        上傳照片
                    </Button>
                    <TakePhotoBox ref={takePhotoRef} onFileChange={handleOnFileChange} />
                </Grid>
                <Grid item xs={6}>
                    {bolbImg && <img style={{ width: 300 }} src={bolbImg} alt=""/>}
                </Grid>
            </Grid>
        </div>
    );
});

export default TakePhotoPage;
