import React, { createContext, useContext } from 'react';

const SettleCashContext = createContext(undefined);

export const SettleCashProvider = ({ children, value }) => {
    return <SettleCashContext.Provider value={value}>{children}</SettleCashContext.Provider>;
};

export const useSettleCashContext = () => {
    const context = useContext(SettleCashContext);
    if (context === undefined) {
        throw new Error('useCartCardContext 必須在 CartCardProvider 中使用');
    }
    return context;
};
