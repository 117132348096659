import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import useCyclePurchaseApi from '@apis/useCyclePurchaseApi';
import usePeopleApi from '@apis/usePeopleApi';
import useShowLoading from '@util/hook/useShowLoading';
import { isEmpty } from 'lodash';
import { refIsRequiredError } from '@util/utils';
import { AutocompleteField, Card, DateField } from '@common/components/';
import { Grid } from '@mui/material';
import { ConfirmTemporaryContractDialog } from '@icoach/documents/dialog';
import { SectionHeader } from '@icoach/documents/cyclePurchase/sections/CyclePurchaseUtility';

const ConsumerSection = forwardRef((props, ref) => {
    const { memberID, sourceData: sourceDataProps = {}, setContractData } = props;
    const { defaultData, birthday: memberBirthday } = sourceDataProps;
    const { getTemporaryHasCycleOrderApi, getCyclePurchaseMemberOrderApi, deleteTemporaryCycleOrderApi } = useCyclePurchaseApi();
    // 所有會員 6
    const { people: peopleOption = [] } = usePeopleApi(6);
    const showLoading = useShowLoading();
    const [openDialog, setOpenDialog] = useState({ isOpen: false, contractID: 0, memberID: 0 });
    const memberDataRef = useRef({});
    const memberRef = useRef(null);

    const handleChangeMember = (memberData) => {
        if (!isEmpty(memberData)) {
            doCheckHasTemporaryContractIDApi(memberData.id);
        } else {
            setContractData({});
        }
    };

    // 關閉視窗
    const handleCloseDialog = () => {
        setOpenDialog({ isOpen: false, contractID: 0, memberID: 0 });
        setContractData({});
    };

    // 確定編輯抓取上次資料
    const handleClickYesDialog = () => {
        getTempContractID(openDialog.memberID, openDialog.contractID);
    };

    // 刪除暫存資料並直接抓新資料
    const handleClickNoDialog = () => {
        deleteTempContractID(openDialog.memberID, openDialog.contractID);
    };

    const getResult = () => {
        let result = {};

        if (memberRef.current && memberRef.current.getResult) {
            const memberData = memberRef.current.getResult();
            result.memberID = memberData.id;
        }

        return result;
    };

    const getTempContractID = async (memberID, contractID) => {
        showLoading(true);
        await doCyclePurchaseMemberOrderApi(memberID, contractID);
        setOpenDialog({ isOpen: false, contractID: 0, memberID: 0 });
    };

    // 刪除暫存資料
    const deleteTempContractID = async (memberID, contractID) => {
        showLoading(true);
        await deleteTemporaryCycleOrderApi(contractID);
        await doCyclePurchaseMemberOrderApi(memberID, 0);
        setOpenDialog({ isOpen: false, contractID: 0, memberID: 0 });
    };

    // 檢查是否有暫存資料api
    const doCheckHasTemporaryContractIDApi = async (memberID) => {
        showLoading(true);
        let contractID = 0;
        // 檢查是否有暫存資料
        const checkHasTempID = await getTemporaryHasCycleOrderApi({ memberID });
        if (checkHasTempID !== 0) {
            showLoading(false);
            setOpenDialog({
                isOpen: true,
                contractID: checkHasTempID,
                memberID,
            });
        } else {
            await doCyclePurchaseMemberOrderApi(memberID, contractID);
        }
    };

    // 取得循環訂單資料
    const doCyclePurchaseMemberOrderApi = async (memberID, contractID) => {
        let resp = await getCyclePurchaseMemberOrderApi(memberID, contractID);
        if (resp) {
            setContractData(resp);
        }
        showLoading(false);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(memberRef),
        }),
        // eslint-disable-next-line
        [memberDataRef.current],
    );

    useEffect(
        () => {
            if (memberID) {
                doCheckHasTemporaryContractIDApi(memberID);
            }
        },
        // eslint-disable-next-line
        [memberID],
    );

    return (
        <React.Fragment>
            <SectionHeader title="訂購人資訊" />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <AutocompleteField
                            key={`${defaultData?.memberID}_${peopleOption.length}`}
                            label={'訂購人'}
                            ref={memberRef}
                            defaultValue={defaultData?.memberID}
                            options={peopleOption}
                            optionKeys={['name', 'mobile', 'no']}
                            primaryKey={'id'}
                            inputProps={{
                                placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可)',
                            }}
                            onChange={handleChangeMember}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <DateField key={memberBirthday} label={'生日'} defaultValue={memberBirthday || defaultData?.birthday} fullWidth disabled />
                    </Grid>
                </Grid>
            </Card.CardContent>
            <ConfirmTemporaryContractDialog
                open={openDialog?.isOpen ?? false}
                handleClose={handleCloseDialog}
                handleClickYes={handleClickYesDialog}
                handleClickNo={handleClickNoDialog}
            />
        </React.Fragment>
    );
});

export default ConsumerSection;
