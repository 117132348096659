import React, { useRef, useState } from 'react';
import { isEmpty } from '@util/utils';
import { Box, Button, Stack } from '@common/components/';
import { DollarSign as DollarSignIcon, Download as DownloadIcon, PaperPlane as PaperPlaneIcon } from '@common/SvgIcon/';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import BatchPaymentController from '@icoach/accountBook/components/bulkController/BatchPaymentController';
import ExportCsvController from '@icoach/accountBook/components/bulkController/ExportCsvController';
import SmsController from '@icoach/accountBook/components/bulkController/SmsController';
import SearchMemberField from '@icoach/accountBook/components/SearchMemberField';
import BatchPaymentOrdersCreditCardTable from '@icoach/accountBook/tables/BatchPaymentOrdersCreditCardTable';

export const BatchPaymentControllerBox = React.forwardRef((props, ref) => {
    const { action, setAction, resource, alertInvoiceWordSetting, paramData, refresh: refreshProps, tableRef } = props;
    const { type, date } = paramData || {};

    const handleClickBulkDebit = () => {
        if (typeof setAction === 'function') {
            setAction((prev) => {
                if (prev === 'bulk-debit') {
                    return '';
                }
                return 'bulk-debit';
            });
        }
    };

    const handleClickExportCsv = () => {
        if (typeof setAction === 'function') {
            setAction((prev) => {
                if (prev === 'export-csv') {
                    return '';
                }
                return 'export-csv';
            });
        }
    };

    const handleClickSMS = () => {
        if (typeof setAction === 'function') {
            setAction((prev) => {
                if (prev === 'sms') {
                    return '';
                }
                return 'sms';
            });
        }
    };

    const handleCancel = () => {
        if (typeof setAction === 'function') setAction('');
    };

    const refresh = (...arg) => {
        if (typeof refreshProps === 'function') refreshProps(...arg);
        handleCancel();
    };

    return (
        <Box className={'bulk-controller-box-container'}>
            <Stack className={'mb-2'}>
                <Box className={'ml-auto btn-group tool-btn-group'}>
                    <Button color={'grey'} startIcon={<DollarSignIcon />} onClick={handleClickBulkDebit}>
                        批次扣款
                    </Button>
                    <Button color={'grey'} startIcon={<DownloadIcon />} onClick={handleClickExportCsv}>
                        批次匯出
                    </Button>
                    <Button color={'grey'} startIcon={<PaperPlaneIcon />} onClick={handleClickSMS}>
                        批次簡訊
                    </Button>
                    <SearchMemberField tableRef={tableRef} />
                </Box>
            </Stack>
            {action === 'bulk-debit' && (
                <BatchPaymentController
                    ref={ref}
                    tableRef={tableRef}
                    billingCategory={type}
                    assignmentMonth={date}
                    resource={resource}
                    handleCancel={handleCancel}
                    alertInvoiceWordSetting={alertInvoiceWordSetting}
                    refresh={refresh}
                    paramData={paramData}
                />
            )}
            {action === 'export-csv' && (
                <ExportCsvController ref={ref} tableRef={tableRef} billingCategory={type} assignmentMonth={date} handleCancel={handleCancel} />
            )}
            {action === 'sms' && <SmsController ref={ref} tableRef={tableRef} handleCancel={handleCancel} />}
        </Box>
    );
});

export const BatchPaymentOrdersByCreditCard = (props) => {
    const { tableData } = props;
    const { paramData, resource, refresh, dialogRef } = useAccountBookContext();
    const [action, setAction] = useState('');
    const tableRef = useRef();
    const controllerRef = useRef();

    return (
        <React.Fragment>
            {!isEmpty(tableData) && (
                <BatchPaymentControllerBox
                    ref={controllerRef}
                    tableRef={tableRef}
                    resource={resource}
                    paramData={paramData}
                    action={action}
                    setAction={setAction}
                    refresh={refresh}
                />
            )}
            <BatchPaymentOrdersCreditCardTable
                ref={tableRef}
                className={'theme-gray'}
                rowsData={tableData}
                controllerRef={controllerRef}
                action={action}
                refresh={refresh}
                dialogRef={dialogRef}
                paramData={paramData}
            />
        </React.Fragment>
    );
};

export default BatchPaymentOrdersByCreditCard;
