import React from 'react';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import { RemoveCutomersDialog, RemoveTemparyCustomersDialog, AddInvalidDialog, NoMemberContactDialog, SendSmsDialog } from '@icoach/nonMember/dialog/';
import { MoreLabelBox } from '@icoach/components/';
import {
    PaperPlane as PaperPlaneIcon,
    Assignment as AssignmentIcon,
    Trash as TrashIcon,
    Edit as EditIcon,
    Phone as PhoneIcon,
    UserSlash as UserSlashIcon,
} from '@common/SvgIcon/';

const moreLabelMap = {
    edit: {
        text: '編輯',
        icon: <EditIcon />,
        to: ({ urls }) => `${urls['edit']}`,
    },
    sms: {
        text: '發送簡訊',
        icon: <PaperPlaneIcon />,
        dialogString: 'sms',
        Dialog: SendSmsDialog,
    },
    appointment: {
        text: '新增預約',
        icon: <AssignmentIcon />,
        to: ({ urls }) => `${urls['apo']}`,
    },
    contact: {
        text: '新增聯繫',
        icon: <PhoneIcon />,
        dialogString: 'contact',
        Dialog: NoMemberContactDialog,
    },
    onvalid: {
        text: '加入無效',
        icon: <UserSlashIcon />,
        dialogString: 'onvalid',
        Dialog: AddInvalidDialog,
    },
    remove: {
        text: '刪除',
        icon: <TrashIcon />,
        dialogString: 'remove',
        Dialog: RemoveCutomersDialog,
    },
    removetemp: {
        text: '暫存刪除',
        icon: <TrashIcon />,
        dialogString: 'removetemp',
        Dialog: RemoveTemparyCustomersDialog,
    },
};

const MoreLabel = (props) => {
    const { refresh } = useNonMember();
    return <MoreLabelBox {...props} refresh={refresh} moreLabelMap={moreLabelMap} />;
};

export default MoreLabel;
