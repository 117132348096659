import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_MEMBERS_CONTACT_RECORDS_OPTIONS_API = `/api/members/contact-records/options/`;
const GET_MEMBERS_MEMBERID_CONTACT_RECORDS_API = (memberID) => `/api/members/${memberID}/contact-records/`;
const POST_MEMBERS_MEMBERID_CONTACT_RECORDS_API = (memberID) => `/api/members/${memberID}/contact-records/`;
const GET_MEMBERS_MEMBERID_CONTACT_RECORDS_CONTACTID_CONTACT_HISTORIES_API = (memberID, contactID) =>
    `/api/members/${memberID}/contact-records/${contactID}/contact-histories/`;
const PUT_MEMBERS_MEMBERID_CONTACT_RECORDS_CONTACTID_API = (memberID, contactID) => `/api/members/${memberID}/contact-records/${contactID}/`;
const DELETE_MEMBERS_MEMBERID_CONTACT_RECORDS_CONTACTID_API = (memberID, contactID) => `/api/members/${memberID}/contact-records/${contactID}/`;
const PUT_MEMBERS_MEMBERID_CONTACT_RECORDS_CONTACTID_CONTACT_HISTORIES_HISTORYID_API = (memberID, contactID, historyID) =>
    `/api/members/${memberID}/contact-records/${contactID}/contact-histories/${historyID}/`;

const useMemberContactRecordApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 會員聯繫的下拉式選單
    const getMemberContactOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_MEMBERS_CONTACT_RECORDS_OPTIONS_API);
            if (!resp) enqueueSnackbar('讀取資料失敗，請重新整理', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 會員聯繫紀錄
    const getMembersContactRecordsDataApi = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_MEMBERS_MEMBERID_CONTACT_RECORDS_API(memberID));
            if (!resp) enqueueSnackbar('會員聯繫紀錄存取失敗，請重新整理', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 建立會員聯繫資料(一般)
    const postAddMembersContactRecordsApi = useCallback(
        async (memberID, params) => {
            const resp = await Ajax.post(POST_MEMBERS_MEMBERID_CONTACT_RECORDS_API(memberID), params);
            if (!resp) enqueueSnackbar('新增聯繫失敗，請重新再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 聯繫歷史紀錄
    const getMemberContactRecordsApi = useCallback(
        async (memberID, contactID) => {
            const resp = await Ajax.get(GET_MEMBERS_MEMBERID_CONTACT_RECORDS_CONTACTID_CONTACT_HISTORIES_API(memberID, contactID));
            if (!resp) enqueueSnackbar('存取聯繫歷史紀錄失敗，請重新再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 編輯會員聯繫資料
    const putMemberContactApi = useCallback(
        async (memberID, contactID, params) => {
            const resp = await Ajax.put(PUT_MEMBERS_MEMBERID_CONTACT_RECORDS_CONTACTID_API(memberID, contactID), params);
            if (resp) {
                enqueueSnackbar('修改聯繫紀錄成功', { variant: 'success' });
            } else {
                enqueueSnackbar('編輯會員聯繫失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除會員聯繫資料
    const deleteMemberContactApi = useCallback(
        async (memberID, contactID) => {
            const resp = await Ajax.delete(DELETE_MEMBERS_MEMBERID_CONTACT_RECORDS_CONTACTID_API(memberID, contactID));
            if (!resp) enqueueSnackbar('刪除會員聯繫失敗，請重新再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 編輯會員聯繫資料
    const putMemberContactRecoveryRecordApi = useCallback(
        async (memberID, contactID, historyID) => {
            const resp = await Ajax.put(PUT_MEMBERS_MEMBERID_CONTACT_RECORDS_CONTACTID_CONTACT_HISTORIES_HISTORYID_API(memberID, contactID, historyID));
            if (!resp) enqueueSnackbar('編輯會員聯繫失敗，請重新再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getMemberContactOptionsApi,
        getMembersContactRecordsDataApi,
        postAddMembersContactRecordsApi,
        getMemberContactRecordsApi,
        putMemberContactApi,
        deleteMemberContactApi,
        putMemberContactRecoveryRecordApi,
    };
};

export default useMemberContactRecordApi;
