import React, { useState } from 'react';
import { Dialog, DialogContent, Grid, DialogActions } from '@mui/material';
import { Button, Box, Typography, ClickAwayListener, Tooltip } from '@common/components/';
import { Check as CheckIcon } from '@common/SvgIcon/';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';
import Tablenization from '@icoach/components/Tablenization';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { BusinessCardBox } from '@icoach/components';
import useGiftItemRecordApi from '@apis/useGiftItemRecordApi';
import { parseDate } from '@util/moment';

const GiftClaimedTooltip = (props) => {
    const { tip } = props;
    const [open, setOpen] = useState(false);

    const handleCloseTooltip = () => {
        setOpen(false);
    };

    const handleOpenTooltip = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleCloseTooltip}>
            <Tooltip
                title={tip}
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleCloseTooltip}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <CheckIcon color={'success'} onClick={handleOpenTooltip} />
            </Tooltip>
        </ClickAwayListener>
    );
};

const headerRow = [
    {
        cellKey: 'memberName',
        headerLabel: '受贈人',
        formatCell: (memberName, { memberNo, memberPicturePath }) => {
            let memberInfo = {
                memberNo,
                memberName,
                memberImg: memberPicturePath,
            };
            return <BusinessCardBox rowData={memberInfo} />;
        },
    },
    {
        cellKey: 'eventNameText',
        headerLabel: '活動名稱',
    },
    {
        cellKey: 'giftName',
        headerLabel: '贈品名稱',
    },
    {
        cellKey: 'createDate',
        headerLabel: '發送日',
        formatCell: (cellValue) => {
            return (
                <React.Fragment>
                    <p className="mb-0">{parseDate(cellValue, DateTimeStandard.DisplayDate, false)}</p>
                    <p className="mb-0 font-color-3">{parseDate(cellValue, DateTimeStandard.DisplayTime, false)}</p>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'isGiftClaimed',
        headerLabel: '是否領取',
        align: 'center',
        formatCell: (isGiftClaimed, { claimDate }) => {
            return isGiftClaimed ? <GiftClaimedTooltip tip={claimDate} /> : '';
        },
    },
];

const NonDeletableRecords = (props) => {
    const { rowsData = [] } = props;

    return (
        <Box className={'bg-light p-2'}>
            <Typography className={'font-weight-bold mb-2'}>注意，以下資料無法進行刪除，因為會員已領過囉。</Typography>
            <Tablenization>
                <Tablenization.TableRowHeader headerRow={headerRow} />
                <Tablenization.TableRowBody headerRow={headerRow} rowsData={rowsData} />
            </Tablenization>
        </Box>
    );
};

const BatchDeleteGiftRecordsDialog = React.forwardRef((props, ref) => {
    const { open = false, onClose, sourceData: giftDispatchData = [], refresh } = props;
    const { deleteGiftItemRecordApi } = useGiftItemRecordApi();

    const getParams = (giftDispatchData) => {
        if (!Array.isArray(giftDispatchData)) return [];
        return giftDispatchData.map((item) => item.recordID);
    };

    const filterNonDeletableRecords = (giftDispatchData) => {
        if (!Array.isArray(giftDispatchData)) return [];
        return giftDispatchData.filter((item) => item.isGiftClaimed);
    };
    const nonDeletableRecords = filterNonDeletableRecords(giftDispatchData);

    const doDeleteGiftItemRecordApi = async (params) => {
        let resp = await deleteGiftItemRecordApi(params);

        if (resp) {
            if (typeof refresh === 'function') refresh();
            onClose();
        }
    };

    const handleSubmitForm = () => {
        const params = getParams(giftDispatchData);
        doDeleteGiftItemRecordApi({ recordIDs: params });
    };

    const generatePlanText = (giftDispatchData, nonDeletableRecords) => {
        const totalCount = giftDispatchData.length || 0;
        const undeletableCount = nonDeletableRecords.length;
        const deleteableCount = totalCount - undeletableCount;

        let text = `您已選取${totalCount}筆，`;

        if (deleteableCount !== totalCount) {
            text = text + `可刪除${deleteableCount}筆，不可刪除${undeletableCount}筆，`;
        }

        text = text + '確定要進行刪除嗎？';

        if (undeletableCount === totalCount) {
            text = text + `但沒有可以刪除的資料。`;
        }

        return text;
    };

    if (!open) return null;

    return (
        <Dialog className={'simple-content dialog'} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>確認刪除</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={12} item>
                        <Typography>{generatePlanText(giftDispatchData, nonDeletableRecords)}</Typography>
                    </Grid>
                    {nonDeletableRecords.length > 0 && (
                        <Grid xs={12} item>
                            <NonDeletableRecords rowsData={nonDeletableRecords} />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmitForm}>
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default BatchDeleteGiftRecordsDialog;
