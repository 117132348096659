import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthPrivateRoute from './AuthPrivateRoute';
// mode is exact or strict

const RedirectComponent = (props) => {
    const { ...other } = props;
    return <Redirect {...other} />;
};

const PrivateRoute = ({ as: Component, isAuth = false, ...other }) => {
    return (
        <Route
            {...other}
            render={(props) =>
                localStorage.getItem('user') ? (
                    isAuth ? (
                        <AuthPrivateRoute {...props} {...other} as={Component} />
                    ) : (
                        <Component {...props} {...other} />
                    )
                ) : (
                    <RedirectComponent to={'/login/'} />
                )
            }
        />
    );
};
export default PrivateRoute;
