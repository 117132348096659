import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogContent } from '@mui/material';
import GradientColorHeader from '../../../components/dialog/GradientColorHeader';
import Tablenization from '../../../components/Tablenization';
import { displayDateText } from '../../../../util/moment';
import { displayDateLength } from '../../../../util/utils';
import useMembershipApi from '../../../../apis/useMembershipApi';
import { membershipItemReasonsFilterTypeMap } from '../staticData';

export const rowsLabel = [
    {
        cellKey: 'applyDate',
        headerLabel: '申請日期',
    },
    {
        cellKey: 'itemTypeText',
        headerLabel: '暫停假別',
        align: 'center',
    },
    {
        cellKey: 'dateRange',
        headerLabel: '暫停期間',
    },
    {
        cellKey: 'totalDate',
        headerLabel: '月/天',
    },

    {
        cellKey: 'reasonText',
        headerLabel: '暫停原因',
    },
];

const SuspendReasonsDialogContent = (props) => {
    const { data } = props;
    const handleDataToRowsData = (data) => {
        if (!Array.isArray(data)) return [];

        return data.map(
            ({
                itemID,
                applyDate,
                startDate,
                endDate,
                rangeMonth,
                rangeDay,
                reasonText,
                itemTypeText,
            }) => ({
                id: itemID,
                applyDate: displayDateText(applyDate),
                dateRange: displayDateText(startDate, endDate),
                totalDate: displayDateLength(rangeMonth, rangeDay),
                reasonText,
                itemTypeText,
            })
        );
    };
    return (
        <DialogContent>
            <Tablenization cardProps={{ className: 'theme-gray' }}>
                <Tablenization.TableRowHeader headerRow={rowsLabel} />
                <Tablenization.TableRowBody
                    headerRow={rowsLabel}
                    rowsData={handleDataToRowsData(data)}
                />
            </Tablenization>
        </DialogContent>
    );
};

const SuspendReasonsDialog = (props) => {
    const { className, open, memberID, membershipID, onClose } = props;
    const { getMembershipItemReasonsApi } = useMembershipApi();
    const [data, setData] = useState();

    const doMembershipItemReasonsApi = async (memberID, membershipID) => {
        let res = await getMembershipItemReasonsApi(memberID, membershipID, {
            filter: membershipItemReasonsFilterTypeMap.suspension,
        });
        if (res) {
            setData(res);
        }
    };
    useEffect(() => {
        if (open && memberID && membershipID) {
            doMembershipItemReasonsApi(memberID, membershipID);
        }
        // eslint-disable-next-line
    }, [open, memberID, membershipID]);

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem' }}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>會籍暫停紀錄</GradientColorHeader>
            <SuspendReasonsDialogContent data={data} />
        </Dialog>
    );
};

export default SuspendReasonsDialog;
