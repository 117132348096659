import React from 'react';
import clsx from 'clsx';
import { Box, Avatar } from '@common/components/';
// import { getRandomInt } from '../../util/utils';

// 1正常 2暫停 3退會 4會籍到期 5刪除 6已轉約 7已轉讓 8已轉出 9不正常錯誤
export const membershipStateColors = {
    0: '#bdbdbd',
    1: '#a45bc8',
    2: '#ff9800',
    3: '#bdbdbd',
    4: '#bdbdbd',
    5: '#bdbdbd',
    6: '#bdbdbd',
    7: '#bdbdbd',
    8: '#bdbdbd',
    9: '#ef5350',
    17: '#bdbdbd',
};

const MemberAvatar = ({ className, membershipStatus, src, alt, width }) => {
    if (!membershipStatus) return <Avatar className={clsx('member-avatar', className)} src={src} alt={alt} width={width} />;
    if (/.jpg$/.test(src)) {
        // src = `${src}?r=${getRandomInt(1000)}`;
        src = `${src}?r=20230222`;
    }
    return (
        <Box className={clsx('member-avatar', className)}>
            <Avatar src={src} alt={alt} width={width} />
            <Box className={'state'} style={{ backgroundColor: membershipStateColors[membershipStatus] }}></Box>
        </Box>
    );
};

export default MemberAvatar;
