import React from 'react';
import clsx from 'clsx';
import ListContext from './ListContext';

const staticClass = `list`;

const List = React.forwardRef((props, ref) => {
    const {
        className,
        dense = false,
        component: Component = 'ul',
        disablePadding = false,
        subheader,
        children,
        ...other
    } = props;
    const context = React.useMemo(() => ({ dense }), [dense]);
    let classNames = staticClass;

    if (disablePadding) classNames = clsx(classNames, `${staticClass}-disable-padding`);
    if (subheader) classNames = clsx(classNames, `${staticClass}-subheader`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    return (
        <ListContext.Provider value={context}>
            <Component {...defaultProperty}>
                {subheader}
                {children}
            </Component>
        </ListContext.Provider>
    );
});

export default List;
