import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import NavTop from '@icoach/navigation/NavTop';
import NavContent from '@icoach/navigation/NavContent';
import NavBottom from '@icoach/navigation/NavBottom';
import { ClickAwayListener, Button, Paper } from '@common/components/';
import { Bars as BarsIcon } from '@common/SvgIcon/';

const NavigationPage = () => {
    const [station, setStation] = useState('');
    const [open, setOpen] = useState(false);
    const { origin } = window;

    const handleToggleMenu = () => {
        setOpen((prev) => !prev);
    };
    const handleCloseMenu = () => {
        setOpen(false);
    };

    useEffect(
        () => {
            if (origin.includes('localhost')) {
                setStation('station-localhost');
            } else if (origin.includes('icoach-uat')) {
                setStation('station-uat');
            } else if (origin.includes('icoach-dev')) {
                setStation('station-dev');
            }else if(origin.includes('icoach-sit')){
                setStation('station-sit');
            }
        },
        // eslint-disable-next-line
        [origin]
    );

    return (
        <div className={clsx('nav', { open })}>
            <ClickAwayListener onClickAway={handleCloseMenu}>
                <Paper elevation="4" className={clsx(`nav-container`, station)}>
                    <Button variant="contained" className={clsx('main-menu-btn', station)} onClick={handleToggleMenu}>
                        <BarsIcon htmlColor={'#fff'} />
                    </Button>
                    <NavTop onCloseMenu={handleCloseMenu} />
                    <NavContent onCloseMenu={handleCloseMenu} />
                    <NavBottom />
                </Paper>
            </ClickAwayListener>
        </div>
    );
};

export default NavigationPage;
