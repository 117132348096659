import { v4 as uuid } from 'uuid';

export const makeField = (id, label, value = '', component, gridItemSize = { xs: 4 }) => {
    return {
        id,
        label,
        value,
        component,
        gridItemSize,
    };
};

export const makeTabData = (label, component) => {
    return {
        id: uuid(),
        label,
        component,
    };
};
