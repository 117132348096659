import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import { IconButton, ListItemText, Menu, MenuItem, Tab, Tabs, Card } from '@common/components/';
import { MoreVertical as MoreVerticalIcon } from '@common/SvgIcon/';
import { MembershipProgressBarController } from '@icoach/members/components/';
import MemberDetailMembershipContentRecord from '@icoach/members/memberDetail/MemberDetailMembershipContentRecord';
import MemberDetailMembershipContentInfo from '@icoach/members/memberDetail/MemberDetailMembershipContentInfo';
import MemberDetailMembershipContentBillingRecord from '@icoach/members/memberDetail/MemberDetailMembershipContentBillingRecord';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';
import useMembershipApi from '@apis/useMembershipApi';
import {
    DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH,
    DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH,
    DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH,
} from '@icoach/router/routerPath';
import useToggleDialog from '@util/hook/useToggleDialog';
import { peopleType } from '@apis/usePeopleApi';
import { ManualGiftDialog } from '@icoach/members/memberDetail/dialog';
import { dialogTypeMap } from '@icoach/members/staticData';

const tabsTypeMap = {
    info: 'info',
    record: 'record',
    billing: 'billing',
};

const BtnGroup = (props) => {
    const { memberID } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const MEMBERSHIP_MENU = [
        {
            id: uuid(),
            text: '入會申請書',
            to: () => DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH(peopleType.member, memberID),
        },
        {
            id: uuid(),
            text: '暫停申請書',
            to: () => DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH(peopleType.member, memberID),
        },
        {
            id: uuid(),
            text: '終止申請書',
            to: () => DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER_PATH(peopleType.member, memberID),
        },
    ];

    const handleClick = (event) => {
        if (!open) {
            setAnchorEl(event.currentTarget);
        }
        setOpen((old) => !old);
    };

    const handleColesClick = (e) => {
        setOpen(false);
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <div className="btn-group">
                <IconButton onClick={handleClick}>
                    <MoreVerticalIcon />
                </IconButton>
            </div>
            <Menu anchorEl={anchorEl} open={open} onClose={handleColesClick}>
                {MEMBERSHIP_MENU.map((menu) => {
                    const { text, to, id } = menu;
                    let menuProperty = {};
                    if (to) {
                        menuProperty.to = to;
                        menuProperty.component = NavLink;
                    }
                    return (
                        <MenuItem {...menuProperty} key={id}>
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

const MemberDetailMembershipContent = (props) => {
    const { className, memberID, setTab } = props;
    const tabs = props.match.params.tabs;
    const { getMembershipsApi } = useMembershipApi();
    const { doMemberProfileApi } = useMemberDetailContext();
    const { openTarget, handleCloseDialog, handleOpenDialog } = useToggleDialog();
    const [tabsIndex, setTabsIndex] = useState('info');
    const [membershipData, setMembershipData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { membershipID } = membershipData[currentIndex] || {};

    const doMembershipsApi = async (memberID) => {
        let res = await getMembershipsApi(memberID);
        if (res) {
            setMembershipData(res);
        }
    };

    const handlePrev = () => setCurrentIndex(currentIndex - 1);

    const handleNext = (i) => setCurrentIndex(currentIndex + 1);

    const handleTabChange = (e, value) => {
        setTabsIndex(value);
    };

    const handleSubmitSuccessRefresh = () => {
        doMembershipsApi(memberID);
        doMemberProfileApi(memberID);
        handleTabChange(null, tabsTypeMap.record);
    };

    useEffect(
        () => {
            if (!isEmpty(membershipData)) {
                // 取得當前會籍或第一筆資料
                const getCurrentMembershipData = (membershipData) => {
                    return membershipData.findIndex((item) => item.isCurrent) || 0;
                };
                const currentIndex = getCurrentMembershipData(membershipData);
                setCurrentIndex(currentIndex);
            }
        },
        // eslint-disable-next-line
        [membershipData]
    );

    useEffect(
        () => {
            if (memberID) {
                doMembershipsApi(memberID);
            }
            if (tabs) {
                setTab(tabs);
            }
        },
        // eslint-disable-next-line
        [memberID]
    );

    return (
        <Card className={clsx(className, 'tabs-content-layout')}>
            <header className={'page-header'}>
                <Tabs value={tabsIndex} onChange={handleTabChange}>
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'info',
                        })}
                        label={'會籍資料'}
                        value={'info'}
                    />
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'basic',
                        })}
                        label={'會籍紀錄'}
                        value={'record'}
                    />
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'basic',
                        })}
                        label={'收費明細'}
                        value={'billing'}
                    />
                </Tabs>
                <BtnGroup memberID={memberID} />
            </header>
            <Card.CardContent className="pt-4 px-4 pb-3">
                {tabsIndex !== tabsTypeMap.billing && (
                    <MembershipProgressBarController
                        className={'mb-5 pt-4'}
                        memberID={memberID}
                        membershipData={membershipData}
                        currentIndex={currentIndex}
                        onPrev={handlePrev}
                        onNext={handleNext}
                    />
                )}
                {tabsIndex === tabsTypeMap.info && (
                    <MemberDetailMembershipContentInfo memberID={memberID} membershipID={membershipID} handleOpenDialog={handleOpenDialog} />
                )}
                {tabsIndex === tabsTypeMap.record && (
                    <MemberDetailMembershipContentRecord memberID={memberID} membershipID={membershipID} refresh={handleSubmitSuccessRefresh} />
                )}
                {tabsIndex === tabsTypeMap.billing && <MemberDetailMembershipContentBillingRecord memberID={memberID} membershipID={membershipID} />}
                <ManualGiftDialog
                    open={openTarget === dialogTypeMap.manualGift}
                    memberID={memberID}
                    membershipID={membershipID}
                    onClose={handleCloseDialog}
                    refresh={handleSubmitSuccessRefresh}
                />
            </Card.CardContent>
        </Card>
    );
};

export default MemberDetailMembershipContent;
