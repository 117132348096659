import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { Grid } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import NonMemberContext from '@icoach/nonMember/components/NonMemberContext';
import InviteFriendsBox from '@icoach/nonMember/components/InviteFriendsBox';
import FreeSoloCreateOptionDialog from '@icoach/nonMember/customer/FreeSoloCreateOptionDialog';
import { NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER } from '@icoach/router/NonMemberRouter';
import AppointmentFormDialog, { AppointmentTimelineBox } from '@icoach/landingPage/dialog/AppointmentFormDialog';
import useCustomerApi from '@apis/useCustomerApi';
import useAppointmentApi from '@apis/useAppointmentApi';
import {
    Card,
    TextField,
    Typography,
    CheckboxField,
    Button,
    DateField,
    Stack,
    SelectField,
    MenuItem,
    FormControlLabel,
    Switch,
    Alert,
} from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { parseDate } from '@util/moment';
import useMessageDialog from '@util/hook/useMessageDialog';

// 右側預約單畫面
const AppointmentBox = React.forwardRef((props, ref) => {
    const { options } = props;
    const [isApo, setApo] = useState(true);
    const appointmentRef = useRef(null);
    const nonApoReasonRef = useRef(null);
    const contactNotesRef = useRef(null);
    const friendsRef = useRef(null);
    const { originOption, noApoOption } = options;

    const noApoAction = () => {
        const isError = () => {
            return refIsRequiredError(nonApoReasonRef, contactNotesRef);
        };
        const getParams = () => {
            return {
                nonApo: {
                    nonApoReason: nonApoReasonRef.current.getResult(),
                    contactNotes: contactNotesRef.current.getResult(),
                },
            };
        };
        return { getParams, isError };
    };

    const apoAction = () => {
        const isError = () => refIsRequiredError(appointmentRef, friendsRef);

        const getParams = () => {
            let paramsData = {
                apo: { ...appointmentRef.current.getResult() },
            };
            if (!friendsRef.current.isNull()) {
                Object.assign(paramsData, { friends: [...friendsRef.current.getResult()] });
            }
            return paramsData;
        };

        return { getParams, isError };
    };

    const handleApoSwitch = (e) => setApo(!isApo);

    useImperativeHandle(
        ref,
        () => {
            let target;
            if (isApo) target = apoAction();
            else target = noApoAction();
            return {
                isError: () => target.isError(),
                getResult: () => ({ ...cloneDeep(target.getParams()) }),
            };
        },
        // eslint-disable-next-line
        [isApo]
    );

    return (
        <Grid container spacing={3}>
            <Grid xs={12} item>
                <div className="customer-add-isapo-switch-panel">
                    <span className="pr-2">是否邀約</span>
                    <FormControlLabel control={<Switch defaultChecked={isApo} onChange={handleApoSwitch} />} label="APO" />
                </div>
            </Grid>
            {isApo ? (
                <Grid xs={12} item>
                    <Stack direction="column" spacing={2}>
                        <AppointmentTimelineBox ref={appointmentRef} />
                        <InviteFriendsBox options={originOption} ref={friendsRef} />
                    </Stack>
                </Grid>
            ) : (
                <React.Fragment key={2}>
                    <Grid xs={6} item>
                        <SelectField ref={nonApoReasonRef} label="未APO原因" displayEmpty fullWidth required>
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {noApoOption.map(({ text, value, disabled }) => (
                                <MenuItem value={value} key={text} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField label="聯絡備註" ref={contactNotesRef} rows={2} multiline fullWidth />
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
});

// 左側 包含 主要邏輯
const CustomerAddInquiryPage = () => {
    const setMessageDialog = useMessageDialog();
    const [isMounted, setMounted] = useState(false);
    const [sourceData, setSourceData] = useState({});
    const [memberData, setMembetData] = useState({}); // 無效會員用
    const [open, setOpen] = useState(false);
    const { id: customerIDParams = 0 } = useParams();
    const { getCutomnerOptionsApi, postCustomersApi } = useCustomerApi();
    const { postAppointmentCheckApi } = useAppointmentApi();
    const history = useHistory();
    const checkInDateRef = useRef(null);
    const coachRef = useRef(null);
    const peopleInsertRef = useRef(null);
    const sourceTypeRef = useRef(null);
    const neededRef = useRef(null);
    const askRecordTypeRef = useRef(null);
    const introducerRef = useRef(null);
    const noteRef = useRef(null);
    const jobCheckedRef = useRef([]);
    const appointmentRef = useRef(null);
    const invalidMemberRefreshRef = useRef(null);
    const { resource = {} } = sourceData;
    const {
        sourceType = null,
        requiredType = null,
        askRecordType = null,
        introducer = '',
        notes = '',
        hasLocation = false,
        hasPush = false,
        hasThanks = false,
    } = sourceData;
    const {
        askRecordTypes: askRecordOption = [],
        requiredTypes: demandOption = [],
        coachOptions: employeeOption = [],
        sourceTypes: originOption = [],
        peopleOptions: peopleOption = [],
        customerNonApoTypes: noApoOption = [],
    } = resource;

    // 切換無效會員彈跳視窗
    const handleNameChange = (eventName, targetData) => {
        if (!targetData) return;
        const { isMember } = targetData.option;
        if (eventName === 'selectOption' && isMember) {
            let cloneData = targetData.option;
            cloneData.peopleID = cloneData.id;
            cloneData.peopleType = 2;
            peopleInsertRef.current.resetValue();
            setMessageDialog({
                open: true,
                title: '無效會員',
                msg: '此為無效會員，需轉跳到會員預約，是否要轉倒過去？',
                onAsyncConfirm: (e, onclose) => {
                    setMembetData(cloneData);
                    setOpen(true);
                    onclose();
                },
            });
        } else {
            const { id } = targetData.option;
            initOptionsApi(id);
        }
    };

    const handleBackToOverviewOnClick = (e) => history.push(NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER);

    const handleAddOnClick = (e) => {
        let params = {};
        if (
            !refIsRequiredError(peopleInsertRef, sourceTypeRef, neededRef, introducerRef, askRecordTypeRef, noteRef, coachRef, appointmentRef, checkInDateRef)
        ) {
            let jobs = jobCheckedRef.current.getResult();
            let appointments = appointmentRef.current.getResult();
            let people = peopleInsertRef.current.getResult();
            const customerPar = {
                ...people,
                sourceType: sourceTypeRef.current.getResult(),
                askRecordType: askRecordTypeRef.current.getResult(),
                requiredType: neededRef.current.getResult(),
                introducer: introducerRef.current.getResult(),
                notes: noteRef.current.getResult(),
            };
            jobs = jobs.reduce((acc, cur) => ({ ...acc, [cur.value]: cur.checked }), {});
            params = Object.assign({}, params, { customer: { ...customerPar } });
            params = Object.assign({}, params, appointments);
            params = Object.assign({}, params, jobs);
            if (params.friends) {
                params.friends = params.friends.map((targetItem) => {
                    let introducerName = people.name;
                    if (!introducerName) {
                        introducerName = peopleOption.find(({ id: tarID }) => String(tarID) === String(people.customerID))['name'];
                    }
                    return {
                        ...targetItem,
                        askRecordType: askRecordTypeRef.current.getResult(),
                        requiredType: neededRef.current.getResult(),
                        introducer: introducerName,
                    };
                });
            }
            params = Object.assign({}, params, {
                employeeID: coachRef.current.getResult(),
                askDate: checkInDateRef.current.getResult(),
                registrationDate: parseDate(),
            });
            addAppointmentApi(params);
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    // 新增詢問API
    const addAppointmentApi = async (params) => {
        // 不再options顧客/名單內的處理
        if (!params.customer.customerID) {
            const resp = await postCustomersApi(params);
            if (resp) {
                history.push(NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER);
            }
            return null;
        }

        const isApo = await postAppointmentCheckApi({
            peopleID: params.customer.customerID,
            peopleType: 1,
        });
        if (isApo) {
            if (isApo === 'noAppointmentYet') {
                const resp = await postCustomersApi(params);
                if (resp) {
                    history.push(NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER);
                }
            } else if (Array.isArray(isApo)) {
                setMessageDialog({
                    open: true,
                    title: '預約單訊息',
                    MsgComp: () => (
                        <Alert color="info" variant="filled" severity="warning">
                            <Typography>
                                此顧客已預約FA
                                <br />
                                已預約的日期：
                                <br />
                                {isApo.map((dateString) => (
                                    <Typography key={dateString} className="pl-5" variant="h5" component="span">
                                        {dateString}
                                        <br />
                                    </Typography>
                                ))}
                            </Typography>
                            <Typography>確定要新增預約？？</Typography>
                        </Alert>
                    ),
                    onAsyncConfirm: async (e, closeFn) => {
                        const resp = await postCustomersApi(params);
                        resp && history.push(NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER);
                        closeFn();
                    },
                });
            }
        }
    };

    const initOptionsApi = async (cID) => {
        let resp = await getCutomnerOptionsApi(cID);
        if (resp) {
            setSourceData(resp);
            setMounted(true);
        }
    };

    const childContext = {
        getOriginType: () => sourceTypeRef.current.getResult(),
    };

    useEffect(() => {
        initOptionsApi(customerIDParams);
        invalidMemberRefreshRef.current = () => history.push(NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER);
        return () => setMounted(false);
        // eslint-disable-next-line
    }, []);

    return (
        <NonMemberContext.Provider value={childContext}>
            <div className="container main-container-spacing">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        新增詢問
                    </Typography>
                </header>
                {isMounted && (
                    <Card>
                        <Card.CardContent className="customer-add-apo-header">
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <DateField
                                        label="詢問日期"
                                        placeholder="YYYY/MM/DD"
                                        ref={checkInDateRef}
                                        defaultValue={parseDate()}
                                        lightColor
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4} />
                                <Grid item xs={4}>
                                    <SelectField ref={coachRef} label={'擔當'} displayEmpty lightColor fullWidth required>
                                        <MenuItem value="" disabled>
                                            請選擇
                                        </MenuItem>
                                        {employeeOption
                                            .filter((item) => item)
                                            .map(({ text, value, disabled }) => (
                                                <MenuItem value={value} key={value} disabled={disabled}>
                                                    {text}
                                                </MenuItem>
                                            ))}
                                    </SelectField>
                                </Grid>
                            </Grid>
                        </Card.CardContent>
                        <Card.CardContent className="p-4">
                            <Grid container spacing={3}>
                                <Grid xs={6} item>
                                    <Typography gutterBottom>基本資料</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FreeSoloCreateOptionDialog
                                                label="姓名,手機"
                                                defaultValue={customerIDParams}
                                                options={peopleOption}
                                                optionKeys={['name', 'mobile']}
                                                ref={peopleInsertRef}
                                                onChange={handleNameChange}
                                                disabled={!!customerIDParams}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectField key={sourceType} ref={sourceTypeRef} label="來源" defaultValue={sourceType || null} required fullWidth>
                                                {originOption.map(({ text, value, disabled }) => (
                                                    <MenuItem value={value} key={value} disabled={disabled}>
                                                        {text}
                                                    </MenuItem>
                                                ))}
                                            </SelectField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectField key={requiredType} ref={neededRef} label="需求" defaultValue={requiredType || null} fullWidth required>
                                                {demandOption.map(({ text, value, disabled }) => (
                                                    <MenuItem value={value} key={value} disabled={disabled}>
                                                        {text}
                                                    </MenuItem>
                                                ))}
                                            </SelectField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectField
                                                key={askRecordType}
                                                ref={askRecordTypeRef}
                                                label="詢問方式"
                                                defaultValue={askRecordType || null}
                                                fullWidth
                                                required
                                            >
                                                {askRecordOption.map(({ text, value, disabled }) => (
                                                    <MenuItem value={value} key={value} disabled={disabled}>
                                                        {text}
                                                    </MenuItem>
                                                ))}
                                            </SelectField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField key={introducer} label="介紹人" ref={introducerRef} defaultValue={introducer} fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField key={notes} label="備註" rows={2} ref={noteRef} defaultValue={notes} fullWidth multiline />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={6} item>
                                    <AppointmentBox ref={appointmentRef} options={{ originOption, noApoOption }} />
                                </Grid>
                            </Grid>
                        </Card.CardContent>
                        <Card.CardContent className="px-3 py-4">
                            <div className="customer-add-reply-checkbox-panel">
                                <CheckboxField defaultChecked={[hasPush, hasLocation, hasThanks]} ref={jobCheckedRef} origin="end" fullWidth row>
                                    <CheckboxField.Checkbox value="hasPush" label="推進" />
                                    <CheckboxField.Checkbox value="hasLocation" label="位置說明" />
                                    <CheckboxField.Checkbox value="hasThanks" label="答謝" />
                                </CheckboxField>
                            </div>
                        </Card.CardContent>
                        <Card.CardContent className="flex flex-y-flex-end flex-gap-3 pr-4 pb-3">
                            <Button variant="outlined" color="secondary" onClick={handleBackToOverviewOnClick}>
                                返回
                            </Button>
                            <Button variant="contained" color="secondary" onClick={handleAddOnClick}>
                                新增
                            </Button>
                        </Card.CardContent>
                    </Card>
                )}
            </div>
            <AppointmentFormDialog open={open} onClose={() => setOpen(false)} sourceData={memberData} refresh={invalidMemberRefreshRef} />
        </NonMemberContext.Provider>
    );
};

export default CustomerAddInquiryPage;
