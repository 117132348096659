import React, { useEffect, useImperativeHandle, useState, useRef } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { Button, Card, List, ListItemText, Box } from '@common/components/';
import { ShoppingMenuDialog } from '@icoach/accountBook/dialog';
import { OrderCartTable } from '@icoach/accountBook/tables';
import { CartCardProvider } from '@icoach/accountBook/context/CartCardContext';
import { formatCurrencyFn } from '@util/utils';
import useToggleDialog from '@util/hook/useToggleDialog';
import { dialogType, receiverTypeMap } from '@icoach/accountBook/staticData';

const allMerchandiseData = {
    checkoutItems: [],
    children: null,
    disabled: false,
    text: '全商品',
    value: 'ALL',
};

// 初始化費用品項資訊
const initFeeItemData = (item) => {
    return {
        name: item.text,
        assignPrice: 0,
        revenueType: item.value,
        productTypeText: item.productTypeText,
        notes: '',
    };
};
// 取得紅利類品項資料
const getProductItemData = (item) => {
    const { productID, variantID, assignPrice, quantity, notes } = item;
    return {
        productID,
        variantID,
        assignPrice,
        quantity,
        notes,
    };
};
// 取得紅利類品項資料
const getFeeItemData = (item) => {
    const { revenueType, name, assignPrice, notes } = item;
    return {
        revenueType,
        name,
        assignPrice,
        notes,
    };
};

// 更新購物車品項的資料
export const updateCartData = (cartData, cartRef) => {
    let cart = [...cartData];
    if (cartRef) {
        cart = cart.map((item, i) => {
            let target = cartRef.current[i];
            if (target && target.hasOwnProperty('getResult')) {
                return { ...item, ...cartRef.current[i].getResult() };
            }
            return item;
        });
    }
    return cart;
};

const CartActions = (props) => {
    const { resource = {}, getReceiver } = props;
    const { forMemberOptions = [], otherOptions = [] } = resource;
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    let memberMerchandiseOpt = _.cloneDeep(forMemberOptions);
    let otherMerchandiseOpt = _.cloneDeep(otherOptions);
    memberMerchandiseOpt.unshift(allMerchandiseData);
    otherMerchandiseOpt.unshift(allMerchandiseData);

    const shoppingMenuMap = new Map([
        [receiverTypeMap.member, memberMerchandiseOpt],
        [receiverTypeMap.nonMember, otherMerchandiseOpt],
        [receiverTypeMap.otherClubMember, otherMerchandiseOpt],
        [receiverTypeMap.employee, otherMerchandiseOpt],
    ]);

    return (
        <Box className={'cart-actions'}>
            <Button variant={'outlined'} onClick={() => handleOpenDialog(dialogType.shoppingMenu)}>
                新增收入項目
            </Button>
            <ShoppingMenuDialog
                open={openTarget === dialogType.shoppingMenu}
                onClose={handleCloseDialog}
                menuData={shoppingMenuMap.get(String(getReceiver()))}
            />
        </Box>
    );
};

export const CartContent = (props) => {
    const { className, cartTotal } = props;

    return (
        <Box className={clsx('cart-content', 'overflow-scroll', className)}>
            <OrderCartTable className={'theme-gray mb-3'} />
            <Grid spacing={3} container>
                <Grid className={'ml-auto'} xs={4} item>
                    <List>
                        <ListItemText className={'cart-info total'} secondary={'總計'} primary={formatCurrencyFn(cartTotal)} />
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
};

const CheckoutOrderCart = React.forwardRef((props, ref) => {
    const { className, resource, getReceiver, setShowQuestionnaireBox, updateOrderTotal } = props;
    const [cartTotal, setCartTotal] = useState(0);
    const [cartData, setCartData] = useState([]);
    const cartDataRef = useRef([]);

    const filterCartItem = (cart, cartItem) => {
        return cart.filter((item) => {
            if (item.hasOwnProperty('productID')) {
                if (item.variantID && item.productID === cartItem.productID) {
                    return item.variantID !== cartItem.variantID;
                } else {
                    return item.productID !== cartItem.productID;
                }
            } else {
                if (cartItem.hasOwnProperty('name')) {
                    return item.name !== cartItem.name;
                } else {
                    return item.name !== cartItem.text;
                }
            }
        });
    };
    // 更新暫存車(與購物車一致)
    const updateCacheCart = () => {
        cartDataRef.current = cartData;
    };
    /**
     * 加入暫存車中
     * @param {Object} cartItem
     * @return {Boolean} 是否有加入
     */
    const addCacheCart = (cartItem) => {
        let item = cartItem;
        if (!item.hasOwnProperty('productID')) {
            // 費用品項
            item = initFeeItemData(item);
        }
        cartDataRef.current = [...cartDataRef.current, item];
        return true;
    };
    // 從暫存車移除品項(單品)
    const deleteCacheCart = (cartItem) => {
        cartDataRef.current = filterCartItem(cartDataRef.current, cartItem);
    };
    // 清除暫存車
    const clearCacheCart = () => {
        cartDataRef.current = [];
    };
    // 暫存車轉入至購物車
    const cacheCartToCart = () => {
        setCartData(cartDataRef.current);
    };

    // 計算購物車總金額
    const calculateCartTotal = () => {
        return cartData.reduce((total, currentObj) => {
            let price = 0,
                qty = 1;
            if (currentObj.hasOwnProperty('quantity')) {
                qty = parseInt(currentObj.quantity);
            }
            if (currentObj.hasOwnProperty('assignPrice')) {
                price = parseInt(currentObj.assignPrice);
            } else {
                price = parseInt(currentObj.price);
            }
            return total + price * qty;
        }, 0);
    };

    // 更新購物車
    const updateCart = (cartRef) => {
        const cart = updateCartData(cartData, cartRef);
        setCartData(cart);
    };

    // 從購物車移除品項(單品)
    const deleteCart = (cartItem, cartRef) => {
        let cart = updateCartData(cartData, cartRef);
        cart = filterCartItem(cart, cartItem);

        setCartData(cart);
    };

    // 清除購物車
    const clearCart = () => {
        setCartData([]);
    };

    useEffect(
        () => {
            updateOrderTotal();
            setCartTotal(calculateCartTotal);
            let hasProduct = cartData.some((item) => item.hasOwnProperty('productID'));
            setShowQuestionnaireBox(hasProduct);
        },
        // eslint-disable-next-line
        [cartData]
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let items = [],
                    products = [];
                cartData.forEach((item) => {
                    if (!item) return false;
                    if (item.hasOwnProperty('productID')) {
                        products.push(getProductItemData(item));
                    } else {
                        items.push(getFeeItemData(item));
                    }
                });
                return {
                    items,
                    products,
                };
            },
            clearCart: () => clearCart,
            getTotal: () => calculateCartTotal(),
        }),
        // eslint-disable-next-line
        [cartData]
    );

    return (
        <CartCardProvider
            value={{
                cartData,
                setCartData,
                cartDataRef,
                deleteCart,
                clearCart,
                updateCart,
                addCacheCart,
                deleteCacheCart,
                clearCacheCart,
                cacheCartToCart,
                updateCacheCart,
            }}
        >
            <Card className={clsx('cart', className)}>
                <Card.CardContent>
                    <CartContent cartTotal={cartTotal} />
                    <CartActions resource={resource} getReceiver={getReceiver} />
                </Card.CardContent>
            </Card>
        </CartCardProvider>
    );
});

export default CheckoutOrderCart;
