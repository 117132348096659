import React, { useRef } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import GuestProductionForm from '@icoach/nonMember/guestProduction/GuestProductionForm';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { refIsRequiredError } from '@util/utils';
import { Skeleton, Stack } from '@common/components/';
import useCustomerManufactureApi from '@apis/useCustomerManufactureApi';

const LoadingShell = () => (
    <Stack direction="column" spacing={1}>
        <Stack average spacing={3}>
            <Skeleton height={60} />
            <Skeleton height={60} />
        </Stack>
        <Stack average spacing={3}>
            <Skeleton height={60} />
            <Skeleton height={60} />
        </Stack>
        <Stack average spacing={3}>
            <Skeleton height={60} />
            <Skeleton height={60} />
        </Stack>
        <Stack average spacing={3}>
            <Skeleton height={60} />
            <Skeleton height={60} />
        </Stack>
        <Skeleton height={120} />
    </Stack>
);

const GuestProductionEidtDialog = (props) => {
    const { onClose: onCloseProps, open, defaultData, options = {}, id: customerManufactureID, refresh } = props;
    const { putCustomerManufactureApi } = useCustomerManufactureApi();
    const formRef = useRef(null);

    const handleCloseClick = (e) => {
        onCloseProps(null);
    };

    const handleSendClick = (e) => {
        if (!refIsRequiredError(formRef)) {
            const params = formRef.current.getResult();
            doPutDataApi(params);
        }
    };

    const doPutDataApi = async (params) => {
        const resp = await putCustomerManufactureApi(params, customerManufactureID);
        if (resp) {
            onCloseProps(null);
            refresh && refresh.current && refresh.current();
        }
    };

    return (
        <Dialog open={open} PaperProps={{ sx: { maxWidth: 1020, width: 980 } }} fullWidth>
            <GradientColorHeader onClose={handleCloseClick}>編輯主動顧客製造</GradientColorHeader>
            <DialogContent className="my-3">
                {defaultData ? <GuestProductionForm ref={formRef} onClick={handleSendClick} options={options} sourceData={defaultData} /> : <LoadingShell />}
            </DialogContent>
        </Dialog>
    );
};

export default GuestProductionEidtDialog;
