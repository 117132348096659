import React, { useRef, useImperativeHandle } from 'react';
import Grid from '@mui/material/Grid';
import { displayDateText } from '@util/moment';
import { SimpleContentItem } from '@icoach/members/components/';
import { TextField } from '@common/components/';
import useMemberContactReconrdApi from '@apis/useMemberContactReconrdApi';
import { useCustomerContactRecordApi } from '@apis/useContactRecordApi';

const EditContactRecordCard = React.forwardRef((props, ref) => {
    const { contactDate, title, contactResultTypeText, coachName, description, contactID, targetID, peopelType } = props;
    const { putMemberContactApi } = useMemberContactReconrdApi();
    const { putCustomerContactRecordDataApi } = useCustomerContactRecordApi();
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const getAllParams = () => {
        const title = titleRef.current.getResult();
        const notes = descriptionRef.current.getResult();
        return {
            title,
            notes,
        };
    };

    const putContactRecordApi = async (targetID, contactID, params, { onClose, setContactState }) => {
        let resp;
        if (peopelType === '2') resp = await putMemberContactApi(targetID, contactID, params);
        else if (peopelType === '1') resp = await putCustomerContactRecordDataApi(params, targetID, contactID);
        if (resp) {
            if (typeof setContactState === 'function') {
                setContactState(targetID, {
                    title: params.title,
                    description: params.notes,
                });
            }
            onClose();
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                onUpdateClick: (onClose, setContactState) => {
                    const params = getAllParams();
                    putContactRecordApi(targetID, contactID, params, { onClose, setContactState });
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <SimpleContentItem label={'聯繫日期'}>{displayDateText(contactDate)}</SimpleContentItem>
            </Grid>
            <Grid xs={6} item>
                <SimpleContentItem label={'聯繫狀態'}>{contactResultTypeText}</SimpleContentItem>
            </Grid>
            <Grid xs={6} item>
                <SimpleContentItem label={'聯繫擔當'}>{coachName}</SimpleContentItem>
            </Grid>
            <Grid xs={12} item>
                <TextField ref={titleRef} label="標題" defaultValue={title} fullWidth />
            </Grid>
            <Grid xs={12} item>
                <TextField ref={descriptionRef} label="聯繫備註" defaultValue={description} rows={5} multiline fullWidth />
            </Grid>
        </Grid>
    );
});

export default EditContactRecordCard;
