import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Table, TableHead, TableBody, TableRow, TableCell, TextField } from '@common/components/';
import { ProductBox } from '@icoach/accountBook/components';
import { CartTableItemProvider } from '@icoach/accountBook/context/CartTableItemContext';
import { useCartCardContext } from '@icoach/accountBook/context/CartCardContext';
import { NoDataTableRow, TableItemActions } from '@icoach/accountBook/tables/OrderCartTable';
import { formatCurrencyFn, refIsRequiredError } from '@util/utils';

const CartTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>品項</TableCell>
                <TableCell align={'center'}>費用</TableCell>
                <TableCell align={'center'}>手續費</TableCell>
                <TableCell align={'center'}>總計(發票開立金額)</TableCell>
                <TableCell align={'right'}></TableCell>
            </TableRow>
        </TableHead>
    );
};

const CartTableItemRow = React.forwardRef((props, ref) => {
    const { sourceData = {}, cartRef, handleDelete, handleUpdateCart } = props;
    const { revenueType, name, assignPrice = 0, handingFee = 0, notes = '' } = sourceData;
    const [memo, setMemo] = useState(notes);
    const [total, setTotal] = useState(0);
    const assignPriceRef = useRef(null);
    const handingFeeRef = useRef(null);

    const calculateTotal = () => {
        let total = 0;
        let price = parseInt(assignPriceRef.current.getResult());
        let fee = parseInt(handingFeeRef.current.getResult());
        if (typeof price === 'number') total = total + price;
        if (typeof fee === 'number') total = total + fee;

        setTotal(total);
    };

    useEffect(() => {
        handleUpdateCart(cartRef);
        // eslint-disable-next-line
    }, [memo, total]);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => ({
                revenueType,
                assignPrice: assignPriceRef.current.getResult(),
                handingFee: handingFeeRef.current.getResult(),
                notes: memo,
            }),
            isError: () => refIsRequiredError(assignPriceRef),
        }),
        // eslint-disable-next-line
        [memo]
    );
    return (
        <CartTableItemProvider
            value={{
                data: sourceData,
                cartRef,
                memo,
                setMemo,
                handleDelete,
            }}
        >
            <TableRow className={'cart-table-item fee-item'}>
                <TableCell>
                    <ProductBox className={'fee-item'} name={name} desc={memo && `備註：${memo}`} />
                </TableCell>
                <TableCell align={'center'}>
                    <TextField maskType={'MONEY'} ref={assignPriceRef} defaultValue={assignPrice} onChangeNative={calculateTotal} required />
                </TableCell>
                <TableCell align={'center'}>
                    <TextField maskType={'MONEY'} ref={handingFeeRef} defaultValue={handingFee} onChangeNative={calculateTotal} required />
                </TableCell>
                <TableCell align={'right'}>
                    <strong>{formatCurrencyFn(total)}</strong>
                </TableCell>
                <TableCell align={'right'}>
                    <TableItemActions
                        memoButtonProps={{ title: '發票備註' }}
                        memoDialogProps={{
                            title: '發票備註',
                            notes: '備註欄位可記錄此筆交易品項的來源、用途，比如輸入會員的名字。',
                            maxLength: 40,
                            placeholder: '因綠界發票字數限字為40個字(內)，請勿超過',
                        }}
                    />
                </TableCell>
            </TableRow>
        </CartTableItemProvider>
    );
});

// 匯款-費用類
const RemitCartTable = React.forwardRef((props, ref) => {
    const { className } = props;
    const { cartData, deleteCart, updateCart } = useCartCardContext();
    const cartRef = useRef([]);

    return (
        <Table className={clsx('cart-table', className)}>
            <CartTableHead />
            <TableBody>
                {cartData.length > 0 ? (
                    cartData.map((item, i) => {
                        return (
                            <CartTableItemRow
                                key={item.name}
                                ref={(el) => (cartRef.current[i] = el)}
                                sourceData={item}
                                cartRef={cartRef}
                                handleDelete={deleteCart}
                                handleUpdateCart={updateCart}
                            />
                        );
                    })
                ) : (
                    <NoDataTableRow />
                )}
            </TableBody>
        </Table>
    );
});

export default RemitCartTable;
