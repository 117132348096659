import React, { useEffect, useState } from 'react';
import { Alert, Box } from '@common/components/';
import { isEmpty } from 'lodash';
import useMemberCyclePurchaseApi from '@apis/useMemberCyclePurchaseApi';
import { CycleOrderRecordCard } from '@icoach/members/components/CycleOrderRecordList';

const CyclePurchaseDetailHistory = (props) => {
    const { memberID, cycleOrderID } = props.sourceData || {};
    const { getMemberCycleOrderHistoryApi } = useMemberCyclePurchaseApi();
    const [data, setData] = useState([]);

    const doMemberCycleOrderHistoryApi = async (memberID, cycleOrderID) => {
        let res = await getMemberCycleOrderHistoryApi(memberID, cycleOrderID);
        if (res) {
            setData(res);
            return res;
        }
    };

    useEffect(() => {
        if (memberID && cycleOrderID) {
            doMemberCycleOrderHistoryApi(memberID, cycleOrderID);
        }
        // eslint-disable-next-line
    }, [memberID, cycleOrderID]);

    return (
        <React.Fragment>
            {Array.isArray(data) && !isEmpty(data) ? (
                <Box component={'ul'} className="time-line membership-record">
                    {data.map((item, i) => (
                        <Box component={'li'} key={i} className="time-line-item">
                            <CycleOrderRecordCard data={item} />
                        </Box>
                    ))}
                </Box>
            ) : (
                <Alert className={'mb-4'} color="info" severity="info" variant="filled">
                    尚無資料
                </Alert>
            )}
        </React.Fragment>
    );
};

export default CyclePurchaseDetailHistory;
