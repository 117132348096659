import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { MembershipSuspensionHistoryTable } from '@icoach/documents/table';
import { Button, Card, Typography } from '@common/components/';
import useDocMembershipSuspendApi from '@apis/useDocMembershipSuspendApi';

const MembershipSuspensionHistory = (props) => {
    const { onBlankContractClick } = props;
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const history = useHistory();
    const { postDocSuspendContractHistoryApi } = useDocMembershipSuspendApi();
    const [sourceData, setSourceData] = useState([]);

    // 按鈕 建立新表單
    const handleCreateButtonClick = () => {
        onBlankContractClick && onBlankContractClick();
    };

    // 讀取該對象的歷史紀錄
    const getInitPeopleRecordOverview = async () => {
        const parmas = { type: peopleTypeProps, id: referenceIDProps };
        const resp = await postDocSuspendContractHistoryApi(parmas);
        if (resp) setSourceData(resp);
    };

    useEffect(
        () => {
            getInitPeopleRecordOverview();
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            // 重刷頁面才會用 REPLACE
            if (history.action === 'REPLACE') {
                getInitPeopleRecordOverview();
            }
        },
        // eslint-disable-next-line
        [history.location]
    );

    return (
        <Card>
            <Card.CardContent className="document-history-list">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        歷史紀錄
                    </Typography>
                    <Button name="create" variant="outlined" color="secondary" onClick={handleCreateButtonClick}>
                        建立新表單
                    </Button>
                </header>
                <MembershipSuspensionHistoryTable className={'theme-gray'} sourceData={sourceData} />
            </Card.CardContent>
        </Card>
    );
};

export default MembershipSuspensionHistory;
