import React from 'react';

const CustomerLabel = ({required, children, ...props}) => {
    return(
        <React.Fragment>
            <label {...props}>
                {required && <span className="text-error mr-1">*</span>}
                {children}
            </label>
        </React.Fragment>
    );
};

export default CustomerLabel;
