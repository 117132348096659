import {useCallback} from 'react';
import useAjax from '@apis/useAjax';
import {useSnackbar} from 'notistack';
import useShowPDF from '@util/hook/useShowPDF';

const GET_DOC_HEALTH_DECLARATION_OPTION_API = `/api/doc/health-declaration/options/`;
const GET_DOC_HEALTH_DECLARATION_HISTORY_API = `/api/doc/health-declaration/history/`;
const GET_DOC_HEALTH_DECLARATION_CONTRACTID_API = (contractID) => `/api/doc/health-declaration/${contractID}/`;
const POST_DOC_HEALTH_DECLARATION_CONTRACTID_API = GET_DOC_HEALTH_DECLARATION_CONTRACTID_API;
const DELETE_DOC_HEALTH_DECLARATION_CONTRACTID_API = GET_DOC_HEALTH_DECLARATION_CONTRACTID_API;
const GET_DOC_HEALTH_DECLARATION_TEMPORARY_API = `api/doc/health-declaration/temporary/`;
const POST_DOC_HEALTH_DECLARATION_TEMPORARY_API = GET_DOC_HEALTH_DECLARATION_TEMPORARY_API;
const DELETE_DOC_HEALTH_DECLARATION_TEMPORARY_API = GET_DOC_HEALTH_DECLARATION_TEMPORARY_API;
// const DOWNLOAD_DOC_HEALTH_DECLARATION_PDF_API = (type, id, contractID) =>
//     `/${type}/${id}/doc/health-declaration/${contractID}/contract.pdf`;
const GET_DOC_HEALTH_DECLARATION_VALID_API = `/api/doc/health-declaration/is-valid/`;

const useDocHealthDeclarationApi = () => {
    const Ajax = useAjax();
    const showPDF = useShowPDF();
    const {enqueueSnackbar} = useSnackbar();
    // const isLoading = useShowLoading();

    // 健康申告書歷史頁searchbar選項
    const getInitHealthDeclarationOptionApi = useCallback(async () => {
        const resp = await Ajax.get(GET_DOC_HEALTH_DECLARATION_OPTION_API);
        if (!resp) enqueueSnackbar('讀取資料失敗,請重新整理', {variant: 'error'});
        return resp;
        // eslint-disable-next-line
    }, []);

    // 健康申告書查詢記錄
    const getDocHealthDeclarationHistoryApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_DOC_HEALTH_DECLARATION_HISTORY_API, {...params});
            if (!resp) {
                enqueueSnackbar('查詢失敗,請重新再操作一次', {variant: 'error'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 健康申告書初始資料
    const getDocHealthDeclarationContractApi = useCallback(
        async (params = {}, contractID) => {
            const resp = await Ajax.get(GET_DOC_HEALTH_DECLARATION_CONTRACTID_API(contractID), params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', {variant: 'error'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增健康申告書合約
    const postDocHealthDeclarationContractApi = useCallback(async (params = {}, contractID) => {
        let resp = await Ajax.post(POST_DOC_HEALTH_DECLARATION_CONTRACTID_API(contractID), {
            ...params,
        });
        if (resp) {
            if (resp.completedPath) {
                // showPDF({open: true, pdfUrl: resp.completedPath});
            } else {
                enqueueSnackbar('PDF連結有誤，請重新再操作一次', {variant: 'error'});
                console.error(resp);
                return null;
            }
        } else {
            enqueueSnackbar('新增失敗,請再操作一次', {variant: 'error'});
        }
        return resp;
        // eslint-disable-next-line
    }, []);

    // 刪除健康申告書合約
    const deleteDocHealthDeclarationContractApi = useCallback(async (contractID) => {
        let resp = await Ajax.delete(DELETE_DOC_HEALTH_DECLARATION_CONTRACTID_API(contractID));
        if (resp) {
            enqueueSnackbar('刪除成功', {variant: 'success'});
        } else {
            enqueueSnackbar('刪除失敗,請再操作一次', {variant: 'error'});
        }
        // eslint-disable-next-line
    }, []);

    // 確認是否有暫存的健康申告書
    const getDocHealthDeclarationTemporaryApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_DOC_HEALTH_DECLARATION_TEMPORARY_API, params);
            if (resp !== 0 && !resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', {variant: 'error'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存暫存健康申告書
    const postDocHealthDeclarationTemporaryApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_DOC_HEALTH_DECLARATION_TEMPORARY_API, params);
            if (resp) {
                enqueueSnackbar('暫存健康申告書成功', {variant: 'success'});
                return resp;
            } else {
                enqueueSnackbar('新增暫存失敗，請重新操作', {variant: 'error'});
            }
        },
        // eslint-disable-next-line
        []
    );

    // 刪除暫存健康申告書
    const deleteDocHealthDeclarationTemporaryApi = useCallback(
        async (params) => {
            const resp = await Ajax.delete(DELETE_DOC_HEALTH_DECLARATION_TEMPORARY_API, params);
            if (!resp) {
                enqueueSnackbar('刪除失敗，請重新操作', {variant: 'error'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 健康申告書下載PDF
    const getDocHealthDeclarationPDFApi = useCallback(
        async (params) => {
            const {contractUrl} = params;
            let url = contractUrl;
            showPDF({
                open: true,
                pdfUrl: url,
            });
        },
        // eslint-disable-next-line
        []
    );

    // 檢查對象的健康申告書是否有效
    const getDocHealthDeclarationValidApi = useCallback(async (params) => {
        const resp = await Ajax.get(GET_DOC_HEALTH_DECLARATION_VALID_API, params);

        return resp;
        // eslint-disable-next-line
    }, []);

    return {
        getInitHealthDeclarationOptionApi,
        getDocHealthDeclarationHistoryApi,
        getDocHealthDeclarationContractApi,
        postDocHealthDeclarationContractApi,
        deleteDocHealthDeclarationContractApi,
        getDocHealthDeclarationTemporaryApi,
        postDocHealthDeclarationTemporaryApi,
        deleteDocHealthDeclarationTemporaryApi,
        getDocHealthDeclarationPDFApi,
        getDocHealthDeclarationValidApi,
    };
};

export default useDocHealthDeclarationApi;
