import React, { useRef, useState, useImperativeHandle, useEffect } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import _ from 'lodash';
import GradientColorHeader from './GradientColorHeader';
import { Typography, Button, SelectField, CheckboxField, MenuItem, TextField, Stack, DateField, Form } from '@common/components/';
import { refIsRequiredError, messageCounts } from '@util/utils';
import { initialDate, parseDate } from '@util/moment';
import useShowLoading from '@util/hook/useShowLoading';
import useEmployeeApi from '@apis/useEmployeeApi';
import useSmsTemplateApi from '@apis/useSmsTemplateApi';
import useSmsApi from '@apis/useSmsApi';

const SmsCountedBox = React.forwardRef((props, ref) => {
    const [messageData, setMessageData] = useState({});

    useImperativeHandle(
        ref,
        () => ({
            setMessageData,
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Stack justifyContent="space-between">
            <Typography variant="body2">使用空白、換行、標點符號、英文字母或中文字皆為一個字數</Typography>
            <Typography variant="body2">
                簡訊字數:<span>{messageData.remainder || 0}</span>
                <br />
                簡訊則數:<span>{messageData.point || 1}</span>
            </Typography>
        </Stack>
    );
});

// 簡訊內容
const NewsletterTextareaItem = React.forwardRef((props, ref) => {
    const { options, sourceData, isTemporaries, isPeopleAppointment } = props;
    const [isTemplate, setTemplate] = useState(false);
    const selectTemplateIDRef = useRef(null);
    const textRef = useRef(null);
    const smsCountedBoxRef = useRef(null);

    const handleSmsTextChange = (e) => {
        const value = messageCounts(e.target.value);
        smsCountedBoxRef.current.setMessageData(value);
    };

    const handleTemplateChange = (e, value) => {
        setTemplate(value);
    };

    const handletemplateMessageOnChange = (e, { props }) => {
        const { value } = props;
        const targetData = options.find(({ value: val }) => String(val) === String(value));
        const textNode = textRef.current.node();
        let textValue = targetData ? targetData.text : '';

        if (!isTemporaries) {
            // 如果是單獨發送會直接在簡訊上顯示該名字
            if (sourceData && !Array.isArray(sourceData) && sourceData.name) {
                textValue = textValue.replace(/\[Name\]/g, sourceData?.name ?? '');
            }
            if (Array.isArray(sourceData) && sourceData.length === 1) {
                textValue = textValue.replace(/\[Name\]/g, sourceData[0]?.name ?? '');
            }
        }
        if (isPeopleAppointment) {
            // 如果是預約單發送會顯示預約時間
            if (sourceData && !Array.isArray(sourceData) && sourceData.apoDateTime) {
                textValue = textValue.replace(/\[Time\]/g, parseDate(sourceData?.apoDateTime, 'MM/DD(E)HH:mm') ?? '');
            }
            if (Array.isArray(sourceData) && sourceData.length === 1) {
                textValue = textValue.replace(/\[Time\]/g, parseDate(sourceData[0]?.apoDateTime, 'MM/DD(E)HH:mm') ?? '');
            }
        }

        textNode.value = textValue;
        smsCountedBoxRef.current.setMessageData(messageCounts(textValue));
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(textRef),
                getResult: () => ({
                    content: textRef.current.getResult(),
                    smsTemplateID: (selectTemplateIDRef.current?.getResult() ?? 0) || 0,
                }),
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <CheckboxField>
                    <CheckboxField.Checkbox label="選擇公版簡訊" onChange={handleTemplateChange} checked={isTemplate} />
                </CheckboxField>
                {isTemplate && (
                    <React.Fragment>
                        <SelectField ref={selectTemplateIDRef} onChange={handletemplateMessageOnChange}>
                            <MenuItem disabled value={''}>
                                <em>請選擇</em>
                            </MenuItem>
                            {options.map(({ title, value }) => (
                                <MenuItem value={value} key={value}>
                                    {title}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </React.Fragment>
                )}
                <Stack direction="column">
                    <TextField
                        ref={textRef}
                        label="簡訊內容"
                        onChange={handleSmsTextChange}
                        inputProps={{ wrap: 'soft' }}
                        multiline
                        fullWidth
                        required
                        rows={5}
                    />
                    <SmsCountedBox ref={smsCountedBoxRef} />
                </Stack>
            </Grid>
        </React.Fragment>
    );
});

// 指定發送時間
const DeliveryDateItem = React.forwardRef((props, ref) => {
    const dateRef = useRef(null);
    const hourRef = useRef(null);
    const minutesRef = useRef(null);
    const [isDeliveryTime, setDeliveryTime] = useState(false);
    const hourArray = Array(24)
        .fill()
        .map((x, i) => String(i).padStart(2, 0));
    const minutesArray = Array(60)
        .fill()
        .map((x, i) => String(i).padStart(2, 0));
    const handleIsSelTimeCheckedOnChange = (e, value) => {
        setDeliveryTime(value);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(dateRef, hourRef, minutesRef),
                getResult: () => {
                    let sendDate = parseDate(new Date(), 'YYYY-MM-DDTHH:mm:ss');
                    if (dateRef.current) {
                        const date = dateRef.current.getResult();
                        const hour = hourRef.current.getResult();
                        const minutes = minutesRef.current.getResult();
                        sendDate = `${date}T${hour}:${minutes}:00`;
                    }
                    return { isAppointment: isDeliveryTime, sendDate: sendDate };
                },
            };
        },
        // eslint-disable-next-line
        [isDeliveryTime],
    );

    return (
        <Grid item xs={12}>
            <CheckboxField>
                <CheckboxField.Checkbox label="指定發送時間" onChange={handleIsSelTimeCheckedOnChange} checked={isDeliveryTime} />
            </CheckboxField>
            {isDeliveryTime && (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <DateField ref={dateRef} defaultValue={initialDate(new Date())} required minDate={parseDate(new Date())} label="發送日期" />
                        </Grid>
                        <Grid item xs={3}>
                            <SelectField ref={hourRef} label="時" displayEmpty fullWidth required>
                                <MenuItem disabled value="">
                                    <em>請選擇</em>
                                </MenuItem>
                                {hourArray.map((value) => (
                                    <MenuItem value={value} key={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        </Grid>
                        <Grid item xs={3}>
                            <SelectField ref={minutesRef} label="分" displayEmpty fullWidth required>
                                <MenuItem disabled value="">
                                    <em>請選擇</em>
                                </MenuItem>
                                {minutesArray.map((value) => (
                                    <MenuItem value={value} key={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
});

// main
const SendSmsDialog = (props) => {
    const {
        sourceData: sourceDataProps = {},
        onClose: onCloseProps,
        open,
        affixParams,
        isTemporaries = false, // 暫存名單使用
        isPeopleAppointment = false, // 預約單使用 替換[time]
        refresh,
    } = props;
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const { postSmsTemplateOptionApi } = useSmsTemplateApi();
    const { postSendSmsApi, postSendSmsTemporariesApi } = useSmsApi();
    const showLoading = useShowLoading();
    const [options, setOptions] = useState({});
    const newsletterTextareaItemRef = useRef(null);
    const deliveryDateItemRef = useRef(null);
    const employeeRef = useRef(null);

    const getAllParams = () => {
        const newsletterTextareaItemData = newsletterTextareaItemRef.current.getResult();
        const deliveryDateItemData = deliveryDateItemRef.current.getResult();
        let params = {
            smsID: 0,
            employeeID: employeeRef.current.getResult(),
            ...newsletterTextareaItemData,
            ...deliveryDateItemData,
        };
        if (isPeopleAppointment) {
            const targets = Array.isArray(sourceDataProps)
                ? sourceDataProps.map(({ id, type, appointmentID }) => ({ id, type, appointmentID }))
                : sourceDataProps && sourceDataProps.id && sourceDataProps.type && sourceDataProps.appointmentID
                  ? [
                        {
                            id: sourceDataProps.id,
                            type: sourceDataProps.type,
                            appointmentID: sourceDataProps.appointmentID,
                        },
                    ]
                  : [];

            Object.assign(params, { targets });
        } else {
            const targets = Array.isArray(sourceDataProps)
                ? sourceDataProps.map(({ id, type }) => ({ id, type }))
                : sourceDataProps && sourceDataProps.id && sourceDataProps.type
                  ? [{ id: sourceDataProps.id, type: sourceDataProps.type }]
                  : [];
            Object.assign(params, { targets });
        }

        if (Object.prototype.toString.call(affixParams) === '[object Object]') {
            Object.assign(params, { ...affixParams });
        }

        if (
            sourceDataProps &&
            sourceDataProps.hasOwnProperty('affixParams') &&
            Object.prototype.toString.call(sourceDataProps.affixParams) === '[object Object]'
        ) {
            Object.assign(params, { ...sourceDataProps.affixParams });
        }
        return params;
    };

    const handleCloseOnClick = () => {
        onCloseProps(null);
    };

    const handleSendOnClick = (e) => {
        if (!refIsRequiredError(newsletterTextareaItemRef, deliveryDateItemRef, employeeRef)) {
            const params = getAllParams();
            sendSmsApi(params);
        }
    };

    // 發送簡訊 api
    const sendSmsApi = async (params) => {
        let resp;
        if (isTemporaries) {
            resp = await postSendSmsTemporariesApi(params, params.isAppointment);
        } else {
            resp = await postSendSmsApi(params, params.isAppointment);
        }

        if (resp) {
            onCloseProps && onCloseProps(null);
            if (typeof refresh === 'function') {
                refresh();
            } else if (refresh && refresh.current) {
                refresh.current();
            }
        }
    };

    // 初始畫面要使用資料
    const getInitOptionsApi = async () => {
        let opts = {};
        showLoading(true);
        const smsTemplateOptions = await postSmsTemplateOptionApi();
        const employeeOptions = await getEmployeesOptionsApi();
        Object.assign(opts, { smsTemplateOptions, employeeOptions });
        setOptions(opts);
        showLoading(false);
    };

    useEffect(
        () => {
            if (open && _.isEmpty(options)) getInitOptionsApi();
        },
        // eslint-disable-next-line
        [open],
    );

    const { smsTemplateOptions = [], employeeOptions = [] } = options;

    return (
        <Dialog open={open} PaperProps={{ className: 'wx-65rem', component: Form }} fullWidth>
            <GradientColorHeader onClose={handleCloseOnClick}>發送簡訊</GradientColorHeader>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SelectField label="擔當" ref={employeeRef} fullWidth displayEmpty required>
                            <MenuItem value={''} disabled>
                                <em>請選擇</em>
                            </MenuItem>
                            {employeeOptions.map(({ text, value, disabled }) => (
                                <MenuItem value={value} key={value} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <NewsletterTextareaItem
                        isTemporaries={isTemporaries}
                        isPeopleAppointment={isPeopleAppointment}
                        sourceData={sourceDataProps}
                        options={smsTemplateOptions}
                        ref={newsletterTextareaItemRef}
                    />
                    <DeliveryDateItem ref={deliveryDateItemRef} />
                    <Grid item xs={12}>
                        <div className="flex flex-y-flex-end flex-gap-2">
                            <Button variant="contained" color="secondary" onClick={handleSendOnClick}>
                                發送
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SendSmsDialog;
