import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, CheckboxField, SelectField, MenuItem, Typography, TextField, Stack } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useAppointmentApi from '@apis/useAppointmentApi';

const DeleteCreateDocumentMemo = React.forwardRef((props, ref) => {
    const { getAppointmentNoSalesOptionsApi } = useAppointmentApi();
    const [noSalesTypeState, setNoSalesTypeState] = useState('');
    const [options, setOptions] = useState([]);
    const noSalesRef = useRef(null);
    const noSalesReasonRef = useRef(null);

    const handleOnCahnge = (_target, _e, value) => {
        setNoSalesTypeState(value);
    };

    const initOptions = async () => {
        const resp = await getAppointmentNoSalesOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => ({
                    pushType: noSalesTypeState,
                    pushReasonText: noSalesReasonRef.current?.getResult() ?? '',
                }),
                isError: () => refIsRequiredError(noSalesRef, noSalesReasonRef),
            };
        },
        // eslint-disable-next-line
        [noSalesTypeState],
    );

    useEffect(
        () => {
            if (options.length === 0) {
                initOptions();
            }
        },
        // eslint-disable-next-line
        [options],
    );

    return (
        <DialogContent>
            <Stack spacing={2} direction="column">
                <SelectField label={'未入會'} ref={noSalesRef} value={noSalesTypeState} onChange={handleOnCahnge} displayEmpty fullWidth required>
                    <MenuItem value={''} disabled>
                        <em>請選擇</em>
                    </MenuItem>
                    {options.map(({ text, value }) => (
                        <MenuItem key={value} value={value}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
                {String(noSalesTypeState) === '99' && <TextField label="未入會原因" ref={noSalesReasonRef} rows={3} multiline fullWidth required />}
            </Stack>
        </DialogContent>
    );
});

const DefaultMessage = () => {
    return (
        <Typography>
            刪除後資料就<strong className={'text-error'}>無法恢復</strong>
            囉！
        </Typography>
    );
};

const ConfirmDeleteDocDialogContent = React.forwardRef((props, ref) => {
    const { message, confirmMessage } = props;
    const confirmRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            isError: () => refIsRequiredError(confirmRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <DialogContent>
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    {message}
                </Grid>
                <Grid xs={12} item>
                    <CheckboxField className={'mb-1'} ref={confirmRef} required>
                        <CheckboxField.Checkbox label={confirmMessage} value={true} />
                    </CheckboxField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const ConfirmDeleteDocDialog = (props) => {
    const {
        className,
        title = '確認刪除',
        message = <DefaultMessage />,
        alertMessage,
        confirmMessage = '會員已同意刪除此文書',
        data: sourceDataProps = {},
        open,
        onClose,
        deleteDocApi,
        refresh,
    } = props;
    const contentRef = useRef(null);
    const deleteCreateDocumentMemoRef = useRef(null);
    if (!open) return null;
    const isCreatedDoc = (sourceDataProps.documentType ? String(sourceDataProps.documentType) === '1' : false) && !sourceDataProps.isTemporary;

    const doDeleteDocApi = async (params) => {
        const resp = await deleteDocApi(params);
        if (resp) {
            refresh && refresh();
            onClose();
        }
    };

    const checkError = () => {
        return refIsRequiredError(contentRef, deleteCreateDocumentMemoRef);
    };

    const handleSubmit = () => {
        let isError = checkError();
        if (!isError) {
            let params = {};
            const noSalesModule = deleteCreateDocumentMemoRef.current?.getResult() ?? {};
            Object.assign(params, noSalesModule);
            Object.assign(params, sourceDataProps);
            doDeleteDocApi(params);
        }
    };

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-40rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>{title}</GradientColorHeader>
            {isCreatedDoc && <DeleteCreateDocumentMemo ref={deleteCreateDocumentMemoRef} />}
            <ConfirmDeleteDocDialogContent ref={contentRef} message={message} confirmMessage={confirmMessage} />
            {alertMessage}
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit}>
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteDocDialog;
