import React, { useState } from 'react';
import { ClickAwayListener, Stack, Tooltip, Box } from '../../../components';
import { QuestionCircle as QuestionCircleIcon, Star as StarIcon } from '../../../components/icons/SvgIcon/';

const Title = React.forwardRef((props, ref) => {
    const { title, isStar = false, onClick, tip, justifyContent = 'start', alignItems = 'center' } = props;

    const handleClick = () => {
        if (typeof onClick === 'function') onClick();
    };
    return (
        <Stack ref={ref} alignItems={alignItems} justifyContent={justifyContent} spacing={1} onClick={handleClick}>
            <Box>{title}</Box>
            {Boolean(tip) && <QuestionCircleIcon />}
            {isStar && <StarIcon className={'svg-color-star'} />}
        </Stack>
    );
});

const TableLabel = (props) => {
    const { tip, isStar, title, TitleProps = {} } = props;
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipToggle = () => {
        setOpen((prev) => !prev);
    };

    if (tip) {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    title={tip}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    {...TitleProps}
                >
                    <Title title={title} isStar={isStar} tip={tip} onClick={handleTooltipToggle} />
                </Tooltip>
            </ClickAwayListener>
        );
    }
    return <Title title={title} isStar={isStar} onClick={handleTooltipToggle} />;
};

export default TableLabel;
