import React from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { Dialog, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { ReadTextField } from '../../components';
import { getInvoiceData } from '../../members/memberDetail/MemberDetailBasicInfoContentInvoice';

const CycleOrderInvoiceDialog = (props) => {
    const { className, open, data = {}, onClose } = props;
    const invoice = data.invoice || {};
    const fields = getInvoiceData(invoice);

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-40rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>發票資訊</GradientColorHeader>
            <DialogContent>
                <Grid spacing={3} container>
                    <Grid item xs={12}>
                        <ReadTextField label={'寄送載具信箱'}>{invoice.mail}</ReadTextField>
                    </Grid>
                    <Grid item xs={12}>
                        <ReadTextField label={'通知手機號碼'}>{invoice.cellPhone}</ReadTextField>
                    </Grid>
                    {Array.isArray(fields) &&
                        fields.map((field) => (
                            <Grid key={uuid()} item xs={12}>
                                <ReadTextField label={field.label}>{field.value}</ReadTextField>
                            </Grid>
                        ))}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CycleOrderInvoiceDialog;
