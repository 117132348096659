import React, {useState, useRef, useImperativeHandle, useEffect} from 'react';
import {v4 as uuid} from 'uuid';
import _ from 'lodash';
import {Grid} from '@mui/material';
import {
    Typography,
    Stack,
    DateField,
    SelectField,
    MenuItem,
    IconButton, TextField,
} from '../../../../components';
import {
    PlusCircle as PlusCircleIcon,
    Cancel as CancelIcon,
} from '../../../../components/icons/SvgIcon/';
import {isNumber, mergeArrayByProperty} from '../../../../util/utils';
import {ReadTextField} from "@icoach/components";
import {displayDateText, isValidDate, parseDate} from "@util/moment";
import moment from "moment";
import clsx from "clsx";

const CheckboxDateNumItem = React.memo(
    React.forwardRef((props, ref) => {
        const {sourceData, onDelete: onDeleteProps, disabled: disabledProps} = props;
        const [endDate, setEndDate] = useState('');
        const startDateRef = useRef(null);
        const monthsRef = useRef(null);
        const daysRef = useRef(null);

        const {applyStartDate, applyMonth = 0, applyDay = 0} = sourceData;
        const hideApplyDay = (sourceData.itemType === '11');

        useImperativeHandle(
            ref,
            () => ({
                getResult: () => ({
                    itemType: sourceData.itemType,
                    applyStartDate: startDateRef.current.getResult(),
                    applyMonth: monthsRef.current.getResult(),
                    applyDay: daysRef.current.getResult()
                }),
            }),
            // eslint-disable-next-line
            []
        );

        const handleChange = () => {
            if (!isValidDate(startDateRef.current.getResult())) return;

            let start = moment(new Date(startDateRef.current.getResult()));
            let end = moment(new Date(startDateRef.current.getResult()));
            const applyMonth = monthsRef.current.getResult();
            const applyDay = daysRef.current.getResult();

            let month = isNumber(applyMonth) ? Number(applyMonth) : 0;
            let day = isNumber(applyDay) ? Number(applyDay) : 0;

            end.add(month, 'month').add(day - 1, 'days');

            setEndDate((end >= start) ? parseDate(end) : '');
        };

        useEffect(
            () => {
                if (isValidDate(applyStartDate)) {
                    let start = moment(new Date(applyStartDate));
                    let end = moment(new Date(applyStartDate));

                    end.add(applyMonth, 'month').add(applyDay - 1, 'days');

                    setEndDate((end >= start) ? parseDate(end) : '');
                }
            },
            // eslint-disable-next-line
            []
        );

        return (
            <Grid container spacing={1}>
                <Grid xs={2} item>
                    <Stack>
                        <Typography component={'div'} variant="h6" style={{lineHeight: 2}}>
                            {sourceData.text}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid xs={3} item>
                    <DateField
                        ref={startDateRef}
                        defaultValue={applyStartDate}
                        disabled={disabledProps}
                        placeholder="起始時間"
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid xs={5} item>
                    <Stack alignItems={'end'} spacing={2}>
                        <TextField
                            ref={monthsRef}
                            type={'number'}
                            inputProps={{min: 0}}
                            style={{width: '100px'}}
                            disabled={disabledProps}
                            defaultValue={applyMonth}
                            onChange={handleChange}
                            required
                        />
                        <Typography component={'div'}>月</Typography>
                        <TextField
                            ref={daysRef}
                            type={'number'}
                            inputProps={{min: 0}}
                            className={clsx({'hide': hideApplyDay})}
                            style={{width: '100px'}}
                            disabled={disabledProps}
                            defaultValue={applyDay}
                            onChange={handleChange}
                            required
                        />
                        <Typography component={'div'} className={clsx({'hide': hideApplyDay})}>日</Typography>
                    </Stack>
                </Grid>
                <Grid xs={2} item>
                    <IconButton
                        color={disabledProps ? 'grey' : 'error'}
                        onClick={onDeleteProps}
                        disabled={disabledProps}
                    >
                        <CancelIcon/>
                    </IconButton>
                </Grid>
                <Grid xs={2} item></Grid>
                <Grid xs={8} item>
                    <ReadTextField label="">{endDate !== '' && displayDateText(startDateRef.current.getResult(), endDate)}</ReadTextField>
                </Grid>
            </Grid>
        );
    })
);

const MembershipTransferInSuspendDateItem = React.forwardRef((props, ref) => {
    const {
        options,
        sourceData: sourceDataProps,
        isReadOnly = false,
        disabled: disabledProps = false,
    } = props;
    const [selValue, setSelValue] = useState({});
    const [sourceData, setSourceData] = useState([]);
    const checkboxDateNumItemsRef = useRef([]);

    const handleOnChange = (dom, target) => {
        setSelValue({
            text: target.props.children,
            itemType: target.props.value,
            key: uuid(),
        });
    };

    const handleAddItemClick = () => {
        setSourceData((pre) => {
            return [...pre, {...selValue}];
        });
        setSelValue({});
    };

    const handleDeleteOnClick = (targetKey) => (e) => {
        const newSource = sourceData.filter(({key}) => targetKey !== key);
        setSourceData(newSource);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const filterEmptySource = checkboxDateNumItemsRef.current.reduce((acc, cur) => {
                    let newData = acc;
                    if (cur) {
                        const sourData = cur.getResult();
                        newData.push(sourData);
                    }
                    return newData;
                }, []);
                return filterEmptySource;
            },
        }),
        // eslint-disable-next-line
        []
    );

    // 有資料時初始來源資料跑回圈
    useEffect(
        () => {
            if (!_.isEmpty(sourceDataProps)) {
                let initSourceData = mergeArrayByProperty(
                    sourceDataProps,
                    options,
                    ['itemType', 'value'],
                    'text'
                );
                initSourceData = initSourceData.map((target) => ({...target, key: uuid()}));
                setSourceData(initSourceData);
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Stack direction="column">
            <Grid container>
                <Grid xs={4} item>
                    <SelectField
                        value={selValue.itemType || ''}
                        onChange={handleOnChange}
                        displayEmpty
                        readOnly={isReadOnly}
                        disabled={disabledProps}
                        fullWidth
                    >
                        <MenuItem value="" disabled>
                            <em>請選擇暫停原因</em>
                        </MenuItem>
                        {options.map(({text, value, disabled}) => (
                            <MenuItem key={value} disabled={disabled} value={value}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid xs={3} item>
                    <IconButton
                        onClick={handleAddItemClick}
                        color={selValue.key ? 'success' : ''}
                        disabled={!selValue.key}
                    >
                        <PlusCircleIcon/>
                    </IconButton>
                </Grid>
            </Grid>

            {sourceData.length > 0 &&
                sourceData.map((targetData, index) => {
                    return (
                        <div className="mt-2" key={targetData.key}>
                            <CheckboxDateNumItem
                                ref={(el) => (checkboxDateNumItemsRef.current[index] = el)}
                                sourceData={targetData}
                                disabled={disabledProps}
                                onDelete={handleDeleteOnClick(targetData.key)}
                            />
                        </div>
                    );
                })}
        </Stack>
    );
});

export default MembershipTransferInSuspendDateItem;
