import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
// import useShowLoading from '@util/hook/useShowLoading';
// import { useSnackbar } from 'notistack';

// 簡訊清單排序下拉選項
const GET_NOTIFICATION_OVERVIEW_OPTIONS_API = `/api/notification/overview/options/`;
// 取得通知未讀數量總和
const GET_NOTIFICATION_COUNT_API = `/api/notification/count/`;
// 取得通知列表未讀數量
const GET_NOTIFICATION_UNREAD_API = `/api/notification/unread/`;
// 取得通知清單
const POST_NOTIFICATION_OVERVIEW_API = `/api/notification/overview/`;
// 設定通知為已讀
const PUT_NOTIFICATION_READ_NOTIFICATIONID_API = (notificationID) => `/api/notification/read/${notificationID}/`;

const useNotificationApi = () => {
    const Ajax = useAjax();
    // const showLoading = useShowLoading();

    const getNotificationOverviewOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_NOTIFICATION_OVERVIEW_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getNotificationCountApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_NOTIFICATION_COUNT_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getNotificationUnreadApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_NOTIFICATION_UNREAD_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postNotificationOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_NOTIFICATION_OVERVIEW_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const putNotificationReadApi = useCallback(
        async (id) => {
            const resp = await Ajax.put(PUT_NOTIFICATION_READ_NOTIFICATIONID_API(id));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getNotificationOverviewOptionsApi,
        getNotificationCountApi,
        getNotificationUnreadApi,
        postNotificationOverviewApi,
        putNotificationReadApi,
    };
};

export default useNotificationApi;
