import React from 'react';
import moment from 'moment';

export const CalendarHeader = (props) => {
    const { year, month } = props;
    if (moment.locale() !== 'zh-tw') moment.locale('zh-tw');
    let title = moment([year, month]).locale('zh-TW').format('YYYY年MMMM');
    let weekdays = moment.weekdaysMin();
    
    return (
        <header>
            <div className="calendar-title">{title}</div>
            <div className="calendar-week">
                {weekdays.map((text, i) => (
                    <div key={i} className="calendar-day">
                        {text}
                    </div>
                ))}
            </div>
        </header>
    );
};

export default CalendarHeader;
