import { isEmpty } from 'lodash';
import { floatToPercentage, formatCurrencyFn, toFixed } from '@util/utils';
import { displayDateText, parseDate } from '@util/moment';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
import { BusinessCardBox } from '@icoach/components';
import React from 'react';
import { Box } from '@common/components/';

export const tableTargetMap = {
    daily: 'daily',
    monthly: 'monthly',
};

export const dialogTypeMap = {
    membership: 'membership',
    service: 'service',
    gp: 'gp',
    sales: 'sales',
    hc: 'hc',
};

// 顧客製造 sub tabs
export const guestProductionTabsItemType = {
    apo: 'apo',
    ask: 'ask',
    fa: 'fa',
    list: 'list',
    totalSales: 'totalSales',
    agp: 'agp',
    agpGuest: 'agp-guest',
    calls: 'calls',
};

// 非會籍 sub tabs
export const salesTabItemTypeMap = {
    hc: 'healthCare',
    goods: 'goods',
};

// 轉置周紀錄
export const transposedWeeklyData = (weeklyData) => {
    let _obj = {};

    if (!isEmpty(weeklyData)) {
        let keys = Object.keys(weeklyData[0]);
        weeklyData.forEach((item, i) => {
            const { week = 0 } = item;
            keys.forEach((key, j) => {
                let value = item[key];
                let weekKey = `W${week}`;
                if (key === 'week') {
                    if (_obj[key] === undefined) {
                        _obj[key] = [];
                    }
                    _obj[key].push({
                        cellKey: `W${item.week}`,
                        headerLabel: `W${item.week}`,
                        align: 'center',
                        minWidth: 82,
                    });
                } else if (Array.isArray(value)) {
                    let arr = value;
                    arr.forEach((dynamicItem) => {
                        const { amount, saleCategoryID, dataType, orderType } = dynamicItem;
                        const id = `hc_${dataType}_${saleCategoryID}_${orderType}`;
                        if (_obj[id] === undefined) {
                            _obj[id] = {};
                        }
                        _obj[id][weekKey] = amount;
                    });
                } else {
                    if (_obj[key] === undefined) {
                        _obj[key] = {};
                    }
                    _obj[key][weekKey] = item[key];
                }
            });
        });
    }

    return _obj;
};

// 轉置教練累計比較資料
export const transposedEmployeeData = (employeeData) => {
    let _obj = { employees: [] };
    if (!isEmpty(employeeData)) {
        let keys = Object.keys(employeeData[0].cumulative);
        employeeData.forEach((item, i) => {
            const { employeeID, employeeName } = item;
            _obj.employees.push({
                cellKey: employeeID,
                headerLabel: employeeName,
                align: 'center',
                minWidth: 128,
            });
            keys.forEach((key, j) => {
                let value = item.cumulative[key];

                if (Array.isArray(value)) {
                    let arr = value;
                    arr.forEach((dynamicItem) => {
                        const { amount, saleCategoryID, dataType, orderType } = dynamicItem;
                        const id = `hc_${dataType}_${saleCategoryID}_${orderType}`;
                        if (_obj[id] === undefined) {
                            _obj[id] = {};
                        }
                        _obj[id][employeeID] = amount;
                    });
                } else {
                    if (_obj[key] === undefined) {
                        _obj[key] = {};
                    }
                    _obj[key][employeeID] = value;
                }
            });
        });
    }
    return _obj;
};

// 取得各屬性的加總
export const getRowsDataTotal = (rowsData) => {
    if (isEmpty(rowsData)) return {};
    let result = {};
    const keys = Object.keys(rowsData[0]);

    rowsData.forEach((item) => {
        keys.forEach((key) => {
            let value = parseFloat(item[key]);

            if (result[key] === undefined) {
                result[key] = 0;
            }

            if (!isNaN(value)) {
                result[key] = result[key] + value;
            }
        });
    });
    return result;
};
// 取得各屬性的做大值
export const getRowsDataMaxValue = (rowsData, targets) => {
    let result = {};
    const keys = Object.keys(rowsData[0]);
    rowsData.forEach((item) => {
        keys.forEach((key) => {
            if (targets.includes(key)) {
                let value = parseFloat(item[key]);
                if (result[key] === undefined) {
                    result[key] = 0;
                }
                if (!isNaN(value) && value >= result[key]) {
                    result[key] = value;
                }
            }
        });
    });
    return result;
};
// 取得今天的值
export const getRowsDataTodayValue = (rowsData, targets) => {
    let result = {};
    const today = parseDate();
    const keys = Object.keys(rowsData[0]);
    rowsData.forEach((item) => {
        let itemData = parseDate(item.date);
        keys.forEach((key) => {
            if (targets.includes(key)) {
                if (itemData === today) {
                    result[key] = item[key];
                    // console.log(itemData, 'itemData');
                    // console.log(today, 'today');
                }
            }
        });
    });
    return result;
};

export const displayKpiDefaultValue = (data = {}, key = 'targetSettingValue', isSymbol = false) => {
    const { isRate = false } = data;
    let value = data[key];
    if (value !== '設定目標') {
        if (isRate) {
            value = floatToPercentage(value);
            if (isSymbol) value = value + '%';
        } else {
            value = formatCurrencyFn(toFixed(value));
        }
    }

    return value;
};

// 表格標題與目標資訊
// title: 標題 string
// tip: 說明 string
// isStar: 閃亮之星 boolean 選填
// goal: 目標 string
export const agpMonthlyTableThData = [
    {
        key: 'boxCount',
        title: '信箱傳單投遞張數',
        tip: '每週發出信D總數',
        goal: '>5000/mnth',
        category: guestProductionTabsItemType.agp,
        isShowDetail: true,
    },
    {
        key: 'flyerCount',
        title: '員工手發傳單的張數',
        tip: '每週發出手D總數',
        goal: '',
        category: guestProductionTabsItemType.agp,
        isShowDetail: true,
    },
    {
        key: 'gpHour',
        title: '店外GP的時數',
        tip: '每週所有教練在外GP總時數',
        goal: '',
        category: guestProductionTabsItemType.agp,
        isShowDetail: true,
    },
    {
        key: 'cpVisit',
        title: 'CP拜訪、檢查Lead Box的家數',
        tip: '每週拜訪的CP家數',
        isStar: false,
        goal: '>10家',
        category: guestProductionTabsItemType.agp,
        isShowDetail: true,
    },
    {
        key: 'gpList',
        title: '名單數-GP名單',
        tip: '每週GP獲得的名單數（全名＋手機）',
        goal: '',
        category: guestProductionTabsItemType.agpGuest,
        isShowDetail: true,
    },
    {
        key: 'brList',
        title: '名單數-BR名單',
        tip: '每週BR獲得的名單數（全名＋手機）',
        goal: '',
        category: guestProductionTabsItemType.agpGuest,
        isShowDetail: true,
    },
    {
        key: 'social',
        title: '名單數-自店社群',
        tip: '每週從Blog or 自店FB / LINE@獲得的名單數（全名＋手機）',
        goal: '',
        category: guestProductionTabsItemType.agpGuest,
        isShowDetail: true,
    },
    {
        key: 'cpList',
        title: '名單數-CP名單',
        tip: '每週從CP商家得到的名單數（全名＋手機）',
        goal: '',
        category: guestProductionTabsItemType.agpGuest,
        isShowDetail: true,
    },
    {
        key: 'callGuestTotal',
        title: '得到電話與人名的總數',
        tip: '主動製造的所有名單總數',
        goal: '',
        category: guestProductionTabsItemType.agpGuest,
        isShowDetail: true,
    },
];
export const callsMonthlyTableThData = [
    {
        key: 'incomingLead',
        title: '所有來電及來店詢問',
        tip: '每週來自「自店介紹、 他店介紹、 平面媒體、 網路、 招牌、 策略夥伴、 其他、 信箱、 手發傳單、 CP」的總數',
        goal: '',
        category: guestProductionTabsItemType.calls,
        isShowDetail: true,
    },
    {
        key: 'leadGpList',
        title: '詢問數-GP名單',
        tip: '每週所有打通的電話中，來自GP名單的總數',
        goal: '',
        category: guestProductionTabsItemType.calls,
        isShowDetail: true,
    },
    {
        key: 'leadBrList',
        title: '詢問數-BR名單',
        tip: '每週所有打通的電話中，來自BR名單的總數',
        goal: '',
        category: guestProductionTabsItemType.calls,
        isShowDetail: true,
    },
    {
        key: 'leadSocial',
        title: '詢問數-自店社群',
        tip: '每週所有打通的電話中，來自Blog or 自店FB / LINE@名單的總數',
        goal: '',
        category: guestProductionTabsItemType.calls,
        isShowDetail: true,
    },
    {
        key: 'leadExisting',
        title: '詢問數-既有名單',
        tip: '每週所有打通的電話中，來自過往No Show、No Sale名單的總數',
        goal: '',
        category: guestProductionTabsItemType.calls,
        isShowDetail: true,
    },
    {
        key: 'leadCpList',
        title: '詢問數-CP名單',
        tip: '每週所有打通的電話中，來自CP名單的總數',
        goal: '',
        category: guestProductionTabsItemType.calls,
        isShowDetail: true,
    },
];
export const apoMonthlyTableThData = [
    {
        key: 'totalApo',
        title: 'APO總數',
        tip: '',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoRate',
        title: '來電或來店 APO率',
        tip: '所有來電或來店APO/所有來電或來店詢問',
        goal: '100%',
        isStar: true,
        category: guestProductionTabsItemType.apo,
        isShowDetail: false,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'outgoingCallsToLeads',
        title: '名單轉換率%',
        tip: 'AGP Outgoing Calls APO/AGP Outgoing Calls',
        goal: '>50%',
        category: guestProductionTabsItemType.apo,
        isShowDetail: false,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'brWithAgpRate',
        title: '會員介紹(自店) + AGP佔所有預約比率',
        tip: '店舖主動製造顧客的能力',
        goal: '≥60%',
        isStar: true,
        category: guestProductionTabsItemType.apo,
        isShowDetail: false,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'tog',
        title: 'TOG人數(全部做FA人次)',
        tip: '每週「銷售管理表」中FA人數加總',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'showUpRate',
        title: '來店率',
        tip: '來店FA/APO總數',
        goal: '>80%',
        isStar: true,
        category: guestProductionTabsItemType.apo,
        isShowDetail: false,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'apoService',
        title: 'SERVICE-自店介紹',
        tip: '每週所有APO中，來源為自店介紹的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoOtherClub',
        title: 'BRANDED-他店介紹',
        tip: '每週所有APO中，來源為他店介紹的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoMedia',
        title: 'BRANDED-平面媒體',
        tip: '每週所有APO中，來源為報章雜誌等平面媒體的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoInternet',
        title: 'BRANDED-網路',
        tip: '每週所有APO中，來源為網路廣告、關鍵字等的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoSign',
        title: 'BRANDED-招牌',
        tip: '每週所有APO中，來源為店舖招牌的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoPartner',
        title: 'BRANDED-策略夥伴',
        tip: '每週所有APO中，來源為勵馨基金會、愛買等策略夥伴的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoOther',
        title: 'BRANDED-其它',
        tip: '每週所有APO中，來源無法歸類在以上者的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoBox',
        title: 'AGP來電-信箱傳單',
        tip: '每週所有APO中，來源為信箱傳單的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoFlyer',
        title: 'AGP來電-手發傳單',
        tip: '每週所有APO中，來源為手發傳單的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoCp',
        title: 'AGP來電-CP',
        tip: '每週所有APO中，來源為CP店家介紹的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoGpList',
        title: 'AGP去電-GP名單',
        tip: '每週所有APO中，來源為店外GP名單的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoBrList',
        title: 'AGP去電-BR名單',
        tip: '每週所有APO中，來源為店內BR名單的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoSocial',
        title: 'AGP去電-自店社群',
        tip: '每週所有APO中，來源為Blog or 自店FB / LINE@名單的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoExisting',
        title: 'AGP去電-既有名單',
        tip: '每週所有APO中，來源包含過往的No Show、No Sale名單的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'apoCpList',
        title: 'AGP去電-CP名單',
        tip: '每週所有APO中，來源為CP名單的總數',
        goal: '',
        category: guestProductionTabsItemType.apo,
        isShowDetail: true,
    },
    {
        key: 'boxGetApo',
        title: 'Analysis-投信箱傳單幾張可獲得一個APO',
        tip: '主動顧客制造信箱傳單投遞張數/信箱傳單(APO)',
        goal: '',
        category: '',
        isShowDetail: false,
    },
    {
        key: 'flyerGetApo',
        title: 'Analysis-手發傳單幾張可獲得一個APO',
        tip: '主動顧客制造手發傳單張數/手發傳單(APO)',
        goal: '',
        category: '',
        isShowDetail: false,
    },
    {
        key: 'gpListGetApo',
        title: 'Analysis-店外GP幾小時可獲得一個APO',
        tip: '主動顧客制造GP名單小時數/GP名單(APO)',
        goal: '',
        category: '',
        isShowDetail: false,
    },
];
export const totalSalesMonthlyTableThData = [
    {
        key: 'newOneYear',
        title: '一年合約',
        tip: '每週入會的合約類型採用1年的總數',
        goal: '',
        category: guestProductionTabsItemType.totalSales,
        isShowDetail: true,
    },
    {
        key: 'newTwoYear',
        title: '兩年合約',
        tip: '每週入會的合約類型採用2年的總數',
        goal: '',
        category: guestProductionTabsItemType.totalSales,
        isShowDetail: true,
    },
    {
        key: 'newOther',
        title: '其他',
        tip: '每週入會的合約類型採用其他的總數',
        goal: '',
        category: guestProductionTabsItemType.totalSales,
        isShowDetail: true,
    },
    {
        key: 'totalSales',
        title: '入會數',
        tip: '每週入會總數',
        goal: '',
        category: guestProductionTabsItemType.totalSales,
        isShowDetail: true,
    },
    {
        key: 'salesRate',
        title: '結案率',
        tip: '入會數/FA數',
        goal: '>85%',
        isStar: true,
        category: guestProductionTabsItemType.totalSales,
        isShowDetail: false,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'paymentRate',
        title: '扣款比率',
        tip: '',
        goal: '>90%',
        isStar: true,
        category: guestProductionTabsItemType.totalSales,
        isShowDetail: false,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
];

export const agpMonthlyRowsLabel = [
    {
        cellKey: 'manufactureDate',
        headerLabel: '日期',
        formatCell: (cellValue, { manufactureTimeRange }) => {
            return (
                <React.Fragment>
                    <Box>{parseDate(cellValue, DateTimeStandard.DisplayDate)}</Box>
                    <Box className={'mb-0 font-color-3'}>{manufactureTimeRange}</Box>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'position',
        headerLabel: '地點',
        align: 'center',
    },

    {
        cellKey: 'customerManufactureTypeText',
        headerLabel: '方式',
        align: 'center',
    },
    {
        cellKey: 'quantity',
        headerLabel: '數量/時數/家數',
        align: 'center',
    },
    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
        align: 'center',
    },
    {
        cellKey: 'notes',
        headerLabel: '備註',
    },
];
export const agpGuestMonthlyRowsLabel = [
    {
        cellKey: 'recordDate',
        headerLabel: '日期',
        formatCell: (cellValue) => {
            return <Box>{parseDate(cellValue, DateTimeStandard.DisplayDate)}</Box>;
        },
    },
    {
        cellKey: 'displayName',
        headerLabel: '對象',
        align: 'center',
    },
    {
        cellKey: 'cellPhone',
        headerLabel: '手機',
        align: 'center',
    },
    {
        cellKey: 'sourceTypeText',
        headerLabel: '來源',
        align: 'center',
    },
    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
        align: 'center',
    },
];
export const callsMonthlyRowsLabel = [
    {
        cellKey: 'recordDate',
        headerLabel: '紀錄時間',
        formatCell: (date) => {
            return parseDate(date, DateTimeStandard.DisplayDate);
        },
    },
    {
        cellKey: 'displayName',
        headerLabel: '對象',
        minWidth: 18,
        formatCell: (value, { memberNo, cellPhone }) => {
            const data = {
                memberName: value,
                memberNo,
                sourceTypeText: cellPhone,
            };
            return <BusinessCardBox isShowAvatar={false} rowData={data} />;
        },
    },
    {
        cellKey: 'cellPhone',
        headerLabel: '手機',
    },
    {
        cellKey: 'sourceTypeText',
        headerLabel: '來源',
        align: 'center',
    },
    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
        align: 'center',
    },
];
export const apoMonthlyRowsLabel = [
    {
        cellKey: 'recordDate',
        headerLabel: '紀錄時間',
        formatCell: (date) => {
            return parseDate(date, DateTimeStandard.DisplayDate);
        },
    },
    {
        cellKey: 'displayName',
        headerLabel: '對象',
        minWidth: 18,
        formatCell: (value, { memberNo, cellPhone }) => {
            const data = {
                memberName: value,
                memberNo,
                sourceTypeText: cellPhone,
            };
            return <BusinessCardBox isShowAvatar={false} rowData={data} />;
        },
    },
    {
        cellKey: 'cellPhone',
        headerLabel: '手機',
    },
    {
        cellKey: 'sourceTypeText',
        headerLabel: '來源',
        align: 'center',
    },

    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
        align: 'center',
    },
];
export const totalSalesMonthlyRowsLabel = [
    {
        cellKey: 'recordDate',
        headerLabel: '紀錄時間',
        formatCell: (date) => {
            return parseDate(date, DateTimeStandard.DisplayDate);
        },
    },
    {
        cellKey: 'displayName',
        headerLabel: '會員',
        minWidth: 18,
        formatCell: (value, { memberNo, cellPhone }) => {
            const data = {
                memberName: value,
                memberNo,
                sourceTypeText: cellPhone,
            };
            return <BusinessCardBox isShowAvatar={false} rowData={data} />;
        },
    },
    {
        cellKey: 'cellPhone',
        headerLabel: '手機',
    },
    {
        cellKey: 'membershipTermText',
        headerLabel: '合約長度',
        align: 'center',
    },
    {
        cellKey: 'startDate',
        headerLabel: '合約期間',
        align: 'center',
        formatCell: (value, { endDate }) => {
            return displayDateText(value, endDate);
        },
    },
    {
        cellKey: 'employeeDisplayName',
        headerLabel: '擔當',
        align: 'center',
    },
];
