import React from 'react';
import { Stack, Button } from '@common/components/';

/**
 * 更多
 */
const MorePageBox = (props) => {
    const { count, page, onChange: onChangeProps } = props;

    const handlePageClick = (e) => {
        if (count === page) return;
        const nextPage = page + 1;
        onChangeProps && onChangeProps(e, nextPage);
    };

    return (
        <Stack justifyContent="center" className="my-2">
            {count !== page ? (
                <Button onClick={handlePageClick} fullWidth>
                    更多
                </Button>
            ) : null}
        </Stack>
    );
};
export default MorePageBox;
