import React from 'react';
import Tablelvl2Context from './Tablelvl2Context';
import clsx from 'clsx';

const staticClass = `table-head`;

const tablelvl2 = {
    variant: 'head',
};

const TableHead = React.forwardRef((props, ref) => {
    const { className, ...other } = props;
    let classNames = staticClass;

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };
    return (
        <Tablelvl2Context.Provider value={tablelvl2}>
            <thead {...defaultProperty} />
        </Tablelvl2Context.Provider>
    );
});

export default TableHead;
