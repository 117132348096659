import React, { useRef } from 'react';
import Tablenization from '../../components/Tablenization';
import { formatCurrencyFn } from '../../../util/utils';
import { TableCell, TableRow } from '../../../components';
import clsx from 'clsx';
import { MoreLabelBox } from '../../components';
import { SettleCreditDialog } from '../dialog';
import useMessageDialog from '../../../util/hook/useMessageDialog';
import useOrderAccountBookApi from '../../../apis/useOrderAccountBookApi';
import { useSettleCashContext } from '../context/SettleCashContext';

const MoreLabel = (props) => {
    const { itemID } = props;
    const setMessageDialog = useMessageDialog();
    const { deleteOrderAccountBookItemApi } = useOrderAccountBookApi();
    const { accountingDate, refresh } = useSettleCashContext();

    const doOrderAccountBookItemApi = async (onClose) => {
        let res = await deleteOrderAccountBookItemApi(itemID);
        if (res) {
            refresh && refresh(accountingDate);
            onClose();
        }
    };

    const handleClickConfirm = (_, onClose) => doOrderAccountBookItemApi(onClose);
    const moreLabelMap = useRef({
        edit: {
            text: '編輯',
            dialogString: 'edit',
            Dialog: SettleCreditDialog,
        },
        delete: {
            text: '刪除',
            dialogString: 'delete',
            onClickEvent: () => {
                setMessageDialog({
                    open: true,
                    title: '確認刪除',
                    msg: '刪除後資料就無法恢復，請問確定將該筆支出刪除嗎？',
                    onAsyncConfirm: handleClickConfirm,
                });
            },
        },
    });

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap.current} textAlign={'center'} />;
};

const rowsLabel = [
    {
        cellKey: 'itemName',
        headerLabel: '品項',
    },
    {
        cellKey: 'employeeName',
        headerLabel: '擔當',
        align: 'center',
    },
    {
        cellKey: 'price',
        headerLabel: '支出金額',
        align: 'right',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (cellValue, rowData = {}) => {
            return <MoreLabel {...rowData} moreActionArray={['edit', 'delete']} />;
        },
    },
];

const TotalRow = (props) => {
    const { className, rowsData } = props;

    let handleTotal = (data = []) => {
        return data.reduce(
            (previousValue, currentValue) => {
                return {
                    price: previousValue.price + currentValue.price,
                };
            },
            {
                price: 0,
            }
        );
    };
    let { price } = handleTotal(rowsData);

    return (
        <TableRow className={clsx('total-row', className)}>
            <TableCell>總計</TableCell>
            <TableCell className={'font-weight-bold'} colSpan={2} align={'right'}>
                {formatCurrencyFn(price)}
            </TableCell>
            <TableCell />
        </TableRow>
    );
};

const CreditItemTable = (props) => {
    const { className, rowsData = [] } = props;
    const labelTitle = useRef(rowsLabel);
    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData}>
                    <TotalRow rowsData={rowsData} />
                </Tablenization.TableRowBody>
            </Tablenization>
        </React.Fragment>
    );
};

export default CreditItemTable;
