import React, { useMemo } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import MoreLabel from '@icoach/nonMember/components/MoreLabel';
import IconGroupHeader from '@icoach/nonMember/components/IconGroupHeader';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import Tablenization from '@icoach/components/Tablenization';
import { customerDialogTextMap } from '@icoach/nonMember/staticData';
import { NON_MEMBER_CUSTOMER_ADD_URL, NON_MEMBER_CUSTOMER_INFO_URL } from '@icoach/router/NonMemberRouter';
import { FormControlLabel, Checkbox, Chip, Typography } from '@common/components/';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';
import { DescribeLabelBox, MorePageBox } from '@icoach/components';
import { parseDate } from '@util/moment';

// 顧客姓名電話Cell
export const nameLabel = (sourceData) => {
    const { name, phone, status } = sourceData;
    const invalidCalss = String(status) === '2' ? 'invalid' : '';
    return (
        <React.Fragment>
            <div className="customer-brief-name">
                <Typography>{name}</Typography>
                <Typography component="span" className={clsx('state', invalidCalss)}></Typography>
            </div>
            <Typography>{phone}</Typography>
        </React.Fragment>
    );
};

export const rowsLabel = [
    {
        cellKey: 'customerID',
        headerLabel: '',
        isIcon: true,
        formatCell: (cellValue, rowData, { chkRef, checkedOnChange }) => {
            return (
                <Checkbox
                    color="primary"
                    onChange={checkedOnChange}
                    value={cellValue}
                    inputRef={(el) => {
                        if (el && chkRef.current && chkRef.current.indexOf(el) === -1) {
                            chkRef.current.push(el);
                        }
                    }}
                />
            );
        },
    },
    {
        cellKey: '',
        headerLabel: '顧客',
        width: 14,
        maxWidth: 22,
        align: 'left',
        formatCell: (cellValue, rowData) => {
            const { name, cellPhone: phone, status } = rowData;
            return nameLabel({ name, phone, status });
        },
    },
    {
        cellKey: 'sourceTypeText',
        headerLabel: '來源',
        width: 12,
        maxWidth: 12,
        align: 'center',
        formatCell: (cellValue, rowData) => {
            return <Chip label={cellValue} color="warning" />;
        },
    },
    {
        cellKey: 'requiredTypeText',
        headerLabel: '需求',
        width: 12,
        maxWidth: 12,
        align: 'center',
        formatCell: (cellValue, rowData) => {
            return <Chip label={cellValue} color="success" />;
        },
    },
    {
        cellKey: 'statusText',
        headerLabel: '狀態',
        width: 12,
        maxWidth: 12,
        align: 'center',
        formatCell: (cellValue, rowData) => {
            const isInvalid = String(rowData.status) === '2';
            return <Chip label={cellValue} color={clsx(isInvalid ? 'gray' : 'info')} />;
        },
    },
    {
        cellKey: 'contact',
        headerLabel: '聯繫',
        align: 'left',
        minWidth: 13,
        maxWidth: 15,
        formatCell: (cellValue, rowData, { setContactState }) => {
            return !_.isEmpty(cellValue) ? (
                <DescribeLabelBox {...cellValue} targetID={rowData.customerID} peopelType={'1'} setContactState={setContactState} />
            ) : (
                ''
            );
        },
    },
    {
        cellKey: 'introducer',
        headerLabel: '介紹人',
        align: 'center',
        style: { width: 100 },
    },
    {
        cellKey: 'askDate',
        headerLabel: '詢問日期',
        align: 'center',
        formatCell: (cellValue) => {
            return (
                <Typography variant={'body1'} className={'mb-0'}>
                    {parseDate(cellValue, 'YYYY/MM/DD')}
                </Typography>
            );
        },
    },
];

const customerMoreLabel = {
    cellKey: '',
    headerLabel: '',
    align: 'right',
    isIcon: true,
    formatCell: (cellValue, rowData, { setContactState }) => {
        return (
            <MoreLabel
                moreActionArray={['edit', 'appointment', 'sms', 'contact', 'onvalid', 'remove']}
                urls={{
                    edit: `${NON_MEMBER_CUSTOMER_INFO_URL(rowData.customerID)}`,
                    apo: `${NON_MEMBER_CUSTOMER_ADD_URL(rowData.customerID)}`,
                }}
                sourceData={rowData}
                peopleID={rowData.customerID}
                dialogViewMap={customerDialogTextMap}
                setContactState={setContactState}
                {...(rowData || {})}
            />
        );
    },
};

const CustomerTable = () => {
    const { tableData, setBookmarkNum, setContactState, refresh } = useNonMember();
    const { pageIndex: page, totalPage: count } = tableData;
    let { list: rowsData = [] } = tableData;
    rowsData = rowsData || [];
    const labelTitle = useMemo(() => [...rowsLabel, customerMoreLabel], []);
    const [handleCheckAllChange, handleCheckedChange, selectedAllRef, selectedOptRef, indeterminateRef, , clearAllChecked] = useGroupCheckbox(rowsData);

    const handlePageChange = (e, num) => setBookmarkNum(num);

    return (
        <div className="customer-table">
            <div className="customer-table-action">
                <div className={clsx('customer-table-action-icon', rowsData.length === 0 && 'visibility-hidden')}>
                    <FormControlLabel
                        value="all"
                        label="全選"
                        control={
                            <Checkbox
                                color="primary"
                                indeterminateRef={indeterminateRef}
                                inputRef={(el) => (selectedAllRef.current = el)}
                                onChange={handleCheckAllChange}
                            />
                        }
                    />
                    <IconGroupHeader
                        viewIconArray={['sms', 'folder', 'invalid', 'remove']}
                        selectedRef={selectedOptRef}
                        rowsData={rowsData}
                        customerType={2}
                        dialogViewMap={customerDialogTextMap}
                        refresh={refresh.current}
                        clearAllChecked={clearAllChecked}
                    />
                </div>
            </div>
            <div>
                <Tablenization Pagination={MorePageBox} page={page} count={count} onChange={handlePageChange} isPagination>
                    <Tablenization.TableRowHeader headerRow={labelTitle} />
                    <Tablenization.TableRowBody
                        headerRow={labelTitle}
                        rowsData={rowsData}
                        chkRef={selectedOptRef}
                        checkedOnChange={handleCheckedChange}
                        setContactState={setContactState}
                    />
                </Tablenization>
            </div>
        </div>
    );
};

export default CustomerTable;
