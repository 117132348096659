import React, { useRef } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, TextField, Typography } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { useCartTableItemContext } from '@icoach/accountBook/context/CartTableItemContext';

const EditMemoDialog = (props) => {
    const { title = '品項備註', className, open, notes, maxLength, onClose, placeholder = '' } = props;
    const { memo, setMemo } = useCartTableItemContext();
    const memoRef = useRef(null);

    const handleClickSave = () => {
        let value = memoRef.current.getResult();
        if (value !== memo) {
            setMemo(value);
        }
        onClose();
    };

    if (!open) return null;
    
    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>{title}</GradientColorHeader>
            <DialogContent>
                <TextField
                    className={'mb-1'}
                    ref={memoRef}
                    defaultValue={memo}
                    minRows={2}
                    maxLength={maxLength * 1}
                    inputProps={{ placeholder: placeholder }}
                    fullWidth
                    multiline
                />
                {notes && (
                    <Typography className={'font-color-2 mb-0'} variant={'body2'}>
                        {notes}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClickSave}>
                    儲存
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditMemoDialog;
