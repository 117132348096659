import React, { useState } from 'react';
import useLanding from '@icoach/landingPage/components/useLanding';
import { Typography, Stack, Chip } from '@common/components/';
import useAppointmentApi from '@apis/useAppointmentApi';

const AppointmentItemEmployee = (props) => {
    const {
        appointmentTypeText,
        serveEmployeeID,
        serveEmployeeName,
        appointmentID,
        employeeOption,
    } = props;
    const { putAppointmentServeApi } = useAppointmentApi();
    const { dialogRef } = useLanding();
    const [employeeData, setEmployeeData] = useState({
        id: serveEmployeeID,
        text: serveEmployeeName,
    });

    const handleSetEmployeeClick = (e) => {
        dialogRef.current.openAppointmentCoachDialog(
            {
                title: '服務擔當設定',
                employeeID: employeeData?.id ?? null,
                apiParams: { appointmentID },
                apiFn: putAppointmentServeApi,
                refreshNoApi: setEmployeeData,
            },
            employeeOption
        );
    };
    
    return (
        <React.Fragment>
            <Stack justifyContent={`center`} direction="column" alignItems="center" spacing={1}>
                <Typography variant="h6" component="span">
                    {appointmentTypeText}
                </Typography>
                <Chip
                    className="cursor-pointer"
                    variant="filled"
                    color={employeeData.id ? 'success' : 'error'}
                    label={employeeData.id ? employeeData.text : '未安排'}
                    onClick={handleSetEmployeeClick}
                />
            </Stack>
        </React.Fragment>
    );
};

export default AppointmentItemEmployee;
