import React from 'react';
import { useSnackbar } from 'notistack';
import { halfPeriodOption } from '@icoach/nonMember/staticData';
import { SelectField, MenuItem, Stack } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { isEmpty } from '@util/utils';

const IntervalTimerBox = React.forwardRef((props, ref) => {
    const { startTime: startTimeProps, endTime: endTimeProps } = props;
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const [startTime, setStartTime] = React.useState(startTimeProps);
    const [endTime, setEndTime] = React.useState(endTimeProps);
    const sRef = React.useRef(null);
    const eRef = React.useRef(null);
    React.useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    return { startTime, endTime };
                },
                isError: () => refIsRequiredError(sRef, eRef),
            };
        },
        // eslint-disable-next-line
        [startTime, endTime]
    );

    const handleStartTimeChange = (e, n, value) => {
        setStartTime(value);
    };

    const handleEndTimeChange = (e, n, value) => {
        setEndTime(value);
    };

    React.useLayoutEffect(() => {
        if (!isEmpty(startTimeProps) && !isEmpty(endTimeProps) && startTimeProps > endTimeProps) {
            _snackbar('請重新輸入起始與結束時間', { variant: 'error' });
            setStartTime(null);
            setEndTime(null);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Stack spacing={1}>
            <SelectField
                label="時間-起"
                helperText="請選擇起時間"
                value={startTime}
                onChange={handleStartTimeChange}
                fullWidth
                required
                ref={sRef}
                name="startTime"
            >
                {halfPeriodOption.map(({ text, value: _value }) => {
                    if (!isEmpty(endTime) && _value > endTime) {
                        return null;
                    }
                    return (
                        <MenuItem value={_value} key={`start-time-${_value}`}>
                            {text}
                        </MenuItem>
                    );
                })}
            </SelectField>
            <SelectField
                label="時間-迄"
                helperText="請選擇迄時間"
                value={endTime}
                onChange={handleEndTimeChange}
                fullWidth
                required
                ref={eRef}
                name="endTime"
            >
                {halfPeriodOption.map(({ text, value: _value }) => {
                    if (!isEmpty(startTime) && startTime > _value) return null;
                    return (
                        <MenuItem value={_value} key={`start-time-${_value}`}>
                            {text}
                        </MenuItem>
                    );
                })}
            </SelectField>
        </Stack>
    );
});

export default IntervalTimerBox;
