import React from 'react';
import { Grid } from '@mui/material';
import { TextField } from '@common/components/';

const MaskTextInputTestPage = React.forwardRef((props, ref) => {
    return (
        <div className="main-container-spacing">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField label="手機" maskType="MOB" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextField label="自然人憑證" maskType="CDC" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextField label={'信用卡卡號'} defaultValue={'3535********2172'} as={TextField} maskType={'CCN'} fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextField label="信用卡到期日" maskType="CVV2" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextField label="手機載具" maskType="MB" fullWidth />
                </Grid>

                <Grid item xs={4}>
                    <TextField label="純數字" masktype={'number'} fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextField label="身分證字號" maskType="IDCARD" fullWidth />
                </Grid>
            </Grid>
        </div>
    );
});

export default MaskTextInputTestPage;
