import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, FormControlLabel, Switch, TextField } from '../../../components';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { CabinetDetailContent } from './CreateCabinetDialog';
import useCabinetApi from '../../../apis/useCabinetApi';
import { refIsRequiredError } from '../../../util/utils';
import { cabinetStatusMap, cabinetStatusTextMap } from '../staticData';
import { FieldRow } from '../../documents/components/Layout';
import { ReadTextField } from '../../components';
import { displayDateText } from '../../../util/moment';

const gridItem = [3, 9];

const CabinetStateContent = React.forwardRef((props, ref) => {
    const { className, data = {} } = props;
    const { cabinetStatus, cabinetStatusText, statusMemo = '', memberName, startDate, endDate } = data;
    const [stateSwitch, setStateSwitch] = useState(cabinetStatus === cabinetStatusMap.idle);
    const switchRef = useRef();
    const memoRef = useRef();

    const handleStateChange = (_, value) => {
        setStateSwitch(value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (memoRef.current && memoRef.current.getResult) {
                    result = Object.assign(result, { statusMemo: memoRef.current.getResult() });
                }

                if (switchRef.current) {
                    result = Object.assign(result, {
                        cabinetStatus: switchRef.current.checked ? cabinetStatusMap.idle : cabinetStatusMap.pause,
                    });
                }

                return result;
            },
            isError: () => {
                let memoDom = memoRef.current;
                if (memoDom) {
                    return refIsRequiredError(memoRef);
                } else {
                    return false;
                }
            },
        }),
        //eslint-disable-next-line
        [stateSwitch],
    );

    useEffect(() => {
        if (cabinetStatus) {
            setStateSwitch(cabinetStatus === cabinetStatusMap.idle);
        }
    }, [cabinetStatus]);
    return (
        <div className={clsx('cabinet-state-content bg-light', className)}>
            <Grid spacing={2} container>
                <FieldRow label={'置物櫃狀態'} items={gridItem}>
                    {cabinetStatus === cabinetStatusMap.idle || cabinetStatus === cabinetStatusMap.pause ? (
                        <FormControlLabel
                            className={'ml-0'}
                            control={<Switch key={stateSwitch} defaultChecked={stateSwitch} inputProps={{ ref: switchRef }} onChange={handleStateChange} />}
                            prefixLabel={stateSwitch ? cabinetStatusTextMap.get(cabinetStatusMap.idle) : cabinetStatusTextMap.get(cabinetStatusMap.pause)}
                        />
                    ) : (
                        <ReadTextField>{cabinetStatusText}</ReadTextField>
                    )}
                </FieldRow>
                {cabinetStatus !== cabinetStatusMap.renting && !stateSwitch && (
                    <FieldRow label={'暫停原因'} items={gridItem} required>
                        <TextField ref={memoRef} className={'reason'} defaultValue={statusMemo} minRows={2} multiline fullWidth />
                    </FieldRow>
                )}
                {cabinetStatus === cabinetStatusMap.renting && (
                    <React.Fragment>
                        <FieldRow label={'租借會員'} items={gridItem}>
                            <ReadTextField>{memberName}</ReadTextField>
                        </FieldRow>
                        <FieldRow label={'租借期間'} items={gridItem}>
                            <ReadTextField>{displayDateText(startDate, endDate)}</ReadTextField>
                        </FieldRow>
                    </React.Fragment>
                )}
            </Grid>
        </div>
    );
});
const EditCabinetDialogContent = React.forwardRef((props, ref) => {
    const { data, employeeOption } = props;
    const formRef = useRef();
    const stateRef = useRef();
    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (formRef.current && formRef.current.getResult) Object.assign(result, formRef.current.getResult());
                if (stateRef.current && stateRef.current.getResult) Object.assign(result, stateRef.current.getResult());
                return result;
            },
            isError: () => refIsRequiredError(formRef, stateRef),
        }),
        //eslint-disable-next-line
        [],
    );
    return (
        <DialogContent className={'pt-4'}>
            <CabinetStateContent className={'p-4 mb-4'} ref={stateRef} data={data} />
            <CabinetDetailContent ref={formRef} employeeOption={employeeOption} data={data} isEdit />
        </DialogContent>
    );
});

const EditCabinetDialog = (props) => {
    const { className, open, sourceData, onClose, refresh } = props;
    const { cabinetID } = sourceData || {};
    const { getInitCabinetApi, putCabinetApi } = useCabinetApi();
    const [data, setData] = useState({});
    const { resource = {}, cabinet: cabinetData = {} } = data;
    const { employeeOption = [] } = resource;
    const { cabinetStatus } = cabinetData;
    const contentRef = useRef();

    const doInitCabinetApi = async (cabinetID) => {
        let res = await getInitCabinetApi(cabinetID);
        if (res) {
            setData(res);
        }
    };

    const doCabinetApi = async (cabinetID, params) => {
        let res = await putCabinetApi(cabinetID, params);
        if (res) {
            if (typeof refresh === 'function') refresh();
            if (typeof onClose === 'function') onClose();
        }
    };

    const getParams = () => {
        let result = { cabinetStatus };
        if (contentRef.current && contentRef.current.getResult) Object.assign(result, contentRef.current.getResult());
        return result;
    };

    const handleSubmit = () => {
        let isError = refIsRequiredError(contentRef);
        if (isError) return false;
        let params = getParams();
        doCabinetApi(cabinetID, params);
    };

    useEffect(() => {
        if (cabinetID && open) {
            doInitCabinetApi(cabinetID);
        }
        // eslint-disable-next-line
    }, [cabinetID, open]);

    return (
        <Dialog key={open} className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>修改置物櫃</GradientColorHeader>
            <EditCabinetDialogContent ref={contentRef} data={cabinetData} employeeOption={employeeOption} />
            <DialogActions className="px-4 pt-0 pb-2">
                <Button variant="contained" onClick={handleSubmit}>
                    確認修改
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCabinetDialog;
