import React, { useImperativeHandle, useRef } from 'react';
import { DateField } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { parseDate } from '@util/moment';

const AppointmentGreetingBox = React.forwardRef((props, ref) => {
    const { greetingDate = null } = props;
    const greetingDateRef = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => ({
                    greetingDate: greetingDateRef.current.getResult(),
                }),
                isError: () => refIsRequiredError(greetingDateRef),
            };
        },
        // eslint-disable-next-line
        []
    );

    return <DateField label="詢問日期" ref={greetingDateRef} defaultValue={greetingDate ? parseDate(greetingDate) : parseDate()} required fullWidth />;
});

export default AppointmentGreetingBox;
