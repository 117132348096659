import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Button } from '../../../components';
import { CounterBox } from '../components';
import { OrderUncheckoutTable } from './table';
import { useMemberProfileApi } from '../../../apis/useMemberProfileApi';
import { formatCurrencyFn } from '../../../util/utils';
import { CheckoutCreditOrderDialog } from './dialog';

const MemberDetailOtherContentOrderUncheckout = (props) => {
    const { memberID } = props;
    const { getMemberUncheckoutOrderApi } = useMemberProfileApi();
    const [openDialog, setOpenDialog] = useState(false);
    const [data, setData] = useState({});
    const { orderData = {} } = data;
    const { orders = [], payableTotalPrice = 0, paidTotalPrice = 0 } = orderData;

    const doInitOrderCheckoutApi = async (memberID) => {
        let res = await getMemberUncheckoutOrderApi(memberID);
        if (res) {
            setData(res);
        }
    };

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    useEffect(() => {
        if (memberID) {
            doInitOrderCheckoutApi(memberID);
        }
        // eslint-disable-next-line
    }, [memberID]);

    return (
        <React.Fragment>
            {payableTotalPrice > 0 && (
                <Grid className={'uncheckout mb-4'} spacing={2} container>
                    <Grid item xs={4}>
                        <CounterBox
                            className={'text-left counter-box-xl'}
                            title="累積欠款"
                            counter={formatCurrencyFn(payableTotalPrice - paidTotalPrice, {
                                symbol: '$',
                            })}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <div className="btn-group text-right">
                            <Button variant={'contained'} onClick={handleOpenDialog}>
                                欠費補款
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
            <OrderUncheckoutTable className={'theme-gray'} rowsData={orders} />
            <CheckoutCreditOrderDialog
                open={openDialog}
                memberID={memberID}
                onClose={handleCloseDialog}
                refresh={doInitOrderCheckoutApi}
            />
        </React.Fragment>
    );
};

export default MemberDetailOtherContentOrderUncheckout;
