import React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Tabs, Tab, Stack } from '@common/components/';
import LandingContext from '@icoach/landingPage/components/LandingContext';
import CheckinTable from '@icoach/landingPage/checkin/CheckinTable';
import CheckinHeaderButtons from '@icoach/landingPage/checkin/CheckinHeaderButtons';
import AppointmentScheduleTable from '@icoach/landingPage/appointment/AppointmentScheduleTable';
import AppointmentHeaderButtons from '@icoach/landingPage/appointment/AppointmentHeaderButtons';
import EmployeeSchedulePage from '@icoach/employees/schedule/EmployeeSchedulePage';
import AppointmentOverview from '@icoach/appointment/overview/AppointmentOverview';
import { APPOINTMENT_SCHEDULING_URL, HOME_TABS_ARRIVE_URL, EMPLOYEE_SHIFT_SCHEDULING_URL, APPOINTMENT_OVERVIEW_URL } from '@icoach/router/LandingPageRouter';

const LandingHeader = (props) => {
    const { value: valueProps, handleTabsValue = () => {} } = props;
    const handleChange = (event, newValue) => {
        handleTabsValue && handleTabsValue(newValue);
    };
    return (
        <Stack justifyContent={'space-between'} alignItems={'end'}>
            <Tabs value={valueProps} onChange={handleChange} variant={'scrollable'} allowScrollButtonsMobile>
                <Tab label={'來店會員'} value={'arrive'} component={NavLink} to={HOME_TABS_ARRIVE_URL} />
                <Tab label={'預約表'} value={'time-scheduling'} component={NavLink} to={APPOINTMENT_SCHEDULING_URL} />
                <Tab label={'每日班表'} value={'shift-scheduling'} component={NavLink} to={EMPLOYEE_SHIFT_SCHEDULING_URL} />
                <Tab label={'預約管理'} value={'ovewview'} component={NavLink} to={APPOINTMENT_OVERVIEW_URL} />
            </Tabs>
            <Stack className={'flex-align-self-center'} spacing={2} alignItems={'center'}>
                {valueProps === 'arrive' && <CheckinHeaderButtons />}
                {['time-scheduling', 'shift-scheduling'].includes(valueProps) && <AppointmentHeaderButtons />}
            </Stack>
        </Stack>
    );
};

const LandingPage = () => {
    const { tabs } = useParams();
    const apoOptionsRef = React.useRef({});
    const dialogRef = React.useRef(null);
    const refreshRef = React.useRef(null);
    const appointmentDateRef = React.useRef(null);
    
    let childContext = {
        options: apoOptionsRef,
        setOptions: (params) => (apoOptionsRef.current = { ...params }),
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
        dialogRef: dialogRef,
        setDialogRef: (el) => (dialogRef.current = el),
        appointmentDateRef: appointmentDateRef,
        setAppointmentDateRef: (el) => (appointmentDateRef.current = el),
    };

    return (
        <LandingContext.Provider value={childContext}>
            <div className="container main-container-spacing landing-page">
                <div className="sticky-header">
                    <LandingHeader value={tabs} />
                </div>
                {tabs === 'arrive' && <CheckinTable />}
                {tabs === 'time-scheduling' && <AppointmentScheduleTable />}
                {tabs === 'shift-scheduling' && <EmployeeSchedulePage />}
                {tabs === 'ovewview' && <AppointmentOverview />}
            </div>
        </LandingContext.Provider>
    );
};

export default LandingPage;
