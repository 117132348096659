import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, Typography } from '@common/components/';
import { MembershipCreateHistoryTable } from '@icoach/documents/table';
import useDocMembershipApi from '@apis/useDocMembershipApi';

const MembershipCreateHistory = (props) => {
    const { onBlankContractClick } = props;
    const history = useHistory();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const { getDocMembershipHistoryApi } = useDocMembershipApi();
    const [sourceData, setSourceData] = useState([]);

    // 讀取該對象的歷史紀錄
    const doDocMembershipHistoryApi = async (type, id) => {
        let resp = await getDocMembershipHistoryApi({ type, id });
        if (resp) {
            setSourceData(resp);
        }
    };

    // 按鈕 建立新表單
    const handleCreateButtonClick = () => {
        onBlankContractClick && onBlankContractClick();
    };

    useEffect(
        () => {
            doDocMembershipHistoryApi(peopleTypeProps, referenceIDProps);
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            // 重刷頁面才會用 REPLACE
            if (history.action === 'REPLACE') {
                doDocMembershipHistoryApi(peopleTypeProps, referenceIDProps);
            }
        },
        // eslint-disable-next-line
        [history.location]
    );

    return (
        <Card>
            <Card.CardContent className="document-history-list">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        歷史紀錄
                    </Typography>
                    <Button name="create" variant="outlined" color="secondary" onClick={handleCreateButtonClick}>
                        建立新表單
                    </Button>
                </header>
                <MembershipCreateHistoryTable className={'theme-gray'} rowsData={sourceData} />
            </Card.CardContent>
        </Card>
    );
};

export default MembershipCreateHistory;
