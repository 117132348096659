import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router';
import _ from 'lodash';
import AddOtherScheduleDialog from '@icoach/employees/dialog/AddOtherScheduleDialog';
import AddEmployeeMatterDialog from '@icoach/employees/dialog/AddEmployeeMatterDialog';
import EmployeeScheduleTable from '@icoach/employees/schedule/EmployeeScheduleTable';
import EmployeesContext from '@icoach/employees/compoments/EmployeesContext';
import useEmployees from '@icoach/employees/compoments/useEmployees';
import MobileDatePickerBox from '@icoach/components/MobileDatePickerBox';
import { Typography, Card, Stack, Button } from '@common/components/';
import { parseDate, getDatetime } from '@util/moment';
import { getUrlParameter } from '@util/utils';
import useEmployeeScheduleApi from '@apis/useEmployeeScheduleApi';

const ScheduleServiceItem = (props) => {
    const { title, sourceList: sourceListProps, type: typeProps = '', options: optionsProps = [], dateTime } = props;
    const { refresh } = useEmployees();
    const [open, setOpen] = useState(false);

    const addEmployeeType = {
        leave: {
            title: '排休人員',
            scheduleType: 11,
        },
        goingOutSupport: { title: '店外支援', scheduleType: 12 },
        externalSupport: '',
    };

    const { title: dialogTitle = '', scheduleType = 0 } = addEmployeeType[typeProps];

    return (
        <div className="layout-container">
            <Stack justifyContent="space-between" alignItems={'center'}>
                <Typography className="layout-title" variant="h6" component="h3">
                    {title}
                </Typography>
                <Button variant="contained" className="align-self-center" onClick={() => setOpen(true)}>
                    新增
                </Button>
            </Stack>
            {!_.isEmpty(sourceListProps) && (
                <p className={'mt-1 mb-0'}>
                    {sourceListProps.map(({ employeeDisplayName }, idx) => {
                        let viewTextName = (
                            <Typography variant="span" key={employeeDisplayName}>
                                {employeeDisplayName}
                            </Typography>
                        );
                        return idx === 0 ? viewTextName : <React.Fragment key={employeeDisplayName}>、{viewTextName}</React.Fragment>;
                    })}
                </p>
            )}
            {(typeProps === 'leave' || typeProps === 'goingOutSupport') && (
                <AddEmployeeMatterDialog
                    open={open}
                    sourceData={sourceListProps}
                    title={dialogTitle}
                    onClose={() => setOpen(false)}
                    options={optionsProps}
                    refresh={refresh}
                    apiParams={{
                        scheduleDate: dateTime,
                        scheduleType: scheduleType,
                    }}
                />
            )}
            {typeProps === 'externalSupport' && (
                <AddOtherScheduleDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    option={optionsProps}
                    apiParams={{ scheduleDate: dateTime }}
                    refresh={refresh}
                    sourceList={sourceListProps.map(({ employeeDisplayName }) => employeeDisplayName)}
                />
            )}
        </div>
    );
};

// 排班表
const EmployeeSchedulePage = () => {
    const { location, replace: _replace } = useHistory();
    const { getEmployeeScheduleDataApi } = useEmployeeScheduleApi();
    const [isMounted, setMounted] = useState(false);
    const [sourceData, setSourceData] = useState({});
    const [pickDateTime, setPickDateTime] = useState(getDatetime(getUrlParameter('t', location.search)?.t ?? ''));
    const refresh = useRef(null);
    const { externalSupportCoaches = [], goingOutSupportCoaches = [], leaveCoaches = [], ranges = [], resource = {}, scheduleDate } = sourceData;

    const handleDateOnChange = (date) => setPickDateTime(parseDate(date));

    const childContext = {
        refresh: refresh,
    };

    refresh.current = () => {
        setMounted(false);
        doGetEmployeeScheduleApi();
    };

    // 抓取班表
    const doGetEmployeeScheduleApi = async () => {
        const datetime = parseDate(pickDateTime);
        const resp = await getEmployeeScheduleDataApi({ time: datetime });
        if (resp) {
            setSourceData(resp);
            _replace(`${location.pathname}?t=${pickDateTime}`);
            setMounted(true);
        }
    };

    useEffect(
        () => {
            doGetEmployeeScheduleApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [pickDateTime]
    );

    const { clubEmployees: clubEmployeesOption = [], usableEmployees: usableEmployeesOption = [] } = resource;

    return (
        <EmployeesContext.Provider value={childContext}>
            {isMounted && (
                <Card className="p-3 employees" square isOverflowHidden>
                    <Card.CardContent className="pb-3">
                        <MobileDatePickerBox className={'flex-y-flex-start'} value={pickDateTime} onChange={handleDateOnChange} />
                    </Card.CardContent>
                    <Card.CardContent className="pb-3">
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <ScheduleServiceItem
                                    title="指定排休"
                                    sourceList={[...leaveCoaches]}
                                    options={clubEmployeesOption}
                                    type="leave"
                                    dateTime={pickDateTime}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ScheduleServiceItem
                                    title="外出支援"
                                    sourceList={[...goingOutSupportCoaches]}
                                    options={clubEmployeesOption}
                                    type="goingOutSupport"
                                    dateTime={pickDateTime}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ScheduleServiceItem
                                    title="店內支援"
                                    sourceList={[...externalSupportCoaches]}
                                    options={usableEmployeesOption}
                                    type="externalSupport"
                                    dateTime={pickDateTime}
                                />
                            </Grid>
                        </Grid>
                    </Card.CardContent>
                    <Card.CardContent className="pb-3">
                        <EmployeeScheduleTable
                            className={'theme-gray'}
                            scheduleDate={parseDate(scheduleDate)}
                            sourceData={ranges}
                            options={{ clubEmployeesOption, usableEmployeesOption }}
                        />
                    </Card.CardContent>
                </Card>
            )}
        </EmployeesContext.Provider>
    );
};

export default EmployeeSchedulePage;
