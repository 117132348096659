import React from 'react';
import { Alert } from '@common/components/';
import icon_clothing from '@icoach/assets/img/icon_doc_clothing.png';
import icon_cross_store from '@icoach/assets/img/icon_doc_cross_store.png';
import icon_prohibited from '@icoach/assets/img/icon_doc_prohibited.png';
import icon_sport from '@icoach/assets/img/icon_doc_sport.png';
import icon_suspension from '@icoach/assets/img/icon_doc_suspension.png';
import icon_termination from '@icoach/assets/img/icon_doc_termination.png';

const WarnComponent = () => {
    return (
        <Alert variant="filled" severity="info" color="info">
            為了提供理想的運動環境，若有未能遵守以上事項者，店舖有權禁止運動。
        </Alert>
    );
};

export default {
    101: {
        new: {
            data: [
                '請穿著有袖上衣。',
                '請穿著⾧度過膝的運動褲。',
                '請穿著乾淨的室內運動鞋。',
                '建議配戴運動手套，除了提升舒適及穩定性之外，還能保護您纖纖玉手。',
                'Curves採會員制，請勿帶孩童進入店舖。',
                '請勿攜帶竉物。',
                '請依順時針交換下一台器材/踏板，未經教練指示請勿任意跳過器材或踏板。',
                '請依語音指示替換器材和踏板，完整運動包含依序使用器材/踏板24分鐘與指定伸展動作6分鐘。',
                '踏板目的是讓心跳恢復、肌肉休息，在踏板上維持輕鬆踏步即可。',
                '加入之新會員，於原店舖完成前5次運動後，方可開始跨店運動。',
                '凡為每月自動扣款之會員，皆可於全台各店舖免費跨店運動。',
                '跨店前請向教練申請跨店資格，並且確認跨店店舖之營業時間，方可進行跨店運動。',
                '會員得於契約期限屆滿前，應「以月為單位」辦理，本人親自赴所屬店舖辦理暫停會員權。',
                '為配合銀行作業，會員須於暫停前最近一次扣款日之前十日辦理。',
                '因出國逾十天者持相關證明親自辦理，暫停期間以每年2個月為限。',
                '經醫師指示暫時不宜運動且開立診斷書證明者，持診斷證明書辦理。',
                '會員得於契約期限屆滿前，本人親自赴所屬店舖辦理。',
                '為了保障會員權益，請事先與本公司預約時間，辦理相關流程。',
                '會員於契約期限屆滿前解約，須以單月月費1800元乘以實際經過月數計算應繳交之費用(未滿十 五日者以半月計，逾十五日者以一月計;停權期間及贈送會籍期間不計)。',
                '會員應補繳費用超過3,600元者，本公司最高僅收取3,600元。',
                '其他相關事宜，詳如會員規章內文。',
            ],
            config: [
                {
                    title: '服裝禮儀',
                    img: icon_clothing,
                    start: 0,
                    end: 4,
                },
                {
                    title: '來店提醒，為維護運動品質',
                    img: icon_prohibited,
                    start: 4,
                    end: 6,
                },
                {
                    title: '運動禮儀',
                    img: icon_sport,
                    start: 6,
                    end: 9,
                },
                {
                    title: '跨店須知',
                    img: icon_cross_store,
                    start: 9,
                    end: 12,
                    WarnComponent: WarnComponent,
                },
                {
                    title: '會員權暫停',
                    img: icon_suspension,
                    start: 12,
                    end: 16,
                },
                {
                    title: '會員權終止',
                    img: icon_termination,
                    start: 16,
                },
            ],
        },
        renewal: {
            data: [
                '請穿著有袖上衣。',
                '請穿著⾧度過膝的運動褲。',
                '請穿著乾淨的室內運動鞋。',
                '建議配戴運動手套，除了提升舒適及穩定性之外，還能保護您纖纖玉手。',
                'Curves採會員制，請勿帶孩童進入店舖。',
                '請勿攜帶竉物。',
                '請依順時針交換下一台器材/踏板，未經教練指示請勿任意跳過器材或踏板。',
                '請依語音指示替換器材和踏板，完整運動包含依序使用器材/踏板24分鐘與指定伸展動作6分鐘。',
                '踏板目的是讓心跳恢復、肌肉休息，在踏板上維持輕鬆踏步即可。',
                '凡為每月自動扣款之會員，皆可於全台各店舖免費跨店運動。',
                '跨店前請向教練申請跨店資格，並且確認跨店店舖之營業時間，方可進行跨店運動。',
                '指契約期限屆滿後，接續與本公司締結新契約而未中斷時，無須繳納入會費。',
                '會員得於契約期限屆滿前，應「以月為單位」辦理，本人親自赴所屬店舖辦理暫停會員權。',
                '為配合銀行作業，會員須於暫停前最近一次扣款日之前十日辦理。',
                '因出國逾十天者持相關證明親自辦理，暫停期間以每年2個月為限。',
                '經醫師指示暫時不宜運動且開立診斷書證明者，持診斷證明書辦理。',
                '會員得於契約期限屆滿前，本人親自赴所屬店舖辦理。',
                '為了保障會員權益，請事先與本公司預約時間，辦理相關流程。',
                '已完成二年方案之會員，若於續約期滿前終止契約，如有應補繳費用時，免予補繳。',
                '其他相關事宜，詳如會員規章內文。',
            ],
            config: [
                {
                    title: '服裝禮儀',
                    img: icon_clothing,
                    start: 0,
                    end: 4,
                },
                {
                    title: '來店提醒，為維護運動品質',
                    img: icon_prohibited,
                    start: 4,
                    end: 6,
                },
                {
                    title: '運動禮儀',
                    img: icon_sport,
                    start: 6,
                    end: 9,
                },
                {
                    title: '跨店須知',
                    img: icon_cross_store,
                    start: 9,
                    end: 11,
                    WarnComponent: WarnComponent,
                },
                {
                    title: '會員權續約',
                    img: null,
                    start: 11,
                    end: 12,
                },
                {
                    title: '會員權暫停',
                    img: icon_suspension,
                    start: 12,
                    end: 16,
                },
                {
                    title: '會員權終止',
                    img: icon_termination,
                    start: 16,
                },
            ],
        },
    },
    102: {
        new: {
            data: [
                '請穿著有袖上衣。',
                '請穿著⾧度過膝的運動褲。',
                '請穿著乾淨的室內運動鞋。',
                '建議配戴運動手套，除了提升舒適及穩定性之外，還能保護您纖纖玉手。',
                'Curves採會員制，請勿帶孩童進入店舖。',
                '請勿攜帶竉物。',
                '請依順時針交換下一台器材/踏板，未經教練指示請勿任意跳過器材或踏板。',
                '請依語音指示替換器材和踏板，完整運動包含依序使用器材/踏板24分鐘與指定伸展動作6分鐘。',
                '踏板目的是讓心跳恢復、肌肉休息，在踏板上維持輕鬆踏步即可。',
                '加入之新會員，於原店舖完成前5次運動後，方可開始跨店運動。',
                '凡為每月自動扣款之會員，皆可於全台各店舖免費跨店運動。',
                '跨店前請向教練申請跨店資格，並且確認跨店店舖之營業時間，方可進行跨店運動。',
                '會員得於契約期限屆滿前，應「以月為單位」辦理，本人親自赴所屬店舖辦理暫停會員權。',
                '為配合銀行作業，會員須於暫停前最近一次扣款日之前十日辦理。',
                '因出國逾一個月者，持相關證明親自辦理。',
                '經醫師指示暫時不宜運動且開立診斷書證明者，持診斷證明書辦理。',
                '會員得於契約期限屆滿前，本人親自赴所屬店舖辦理。',
                '為了保障會員權益，請事先與本公司預約時間，辦理相關流程。',
                '終止手續費：以簽約時月平均價格乘以契約期間未到期月數之20%(未滿十五日者以半月計，逾十五日者以一月計)。',
                '會員應補繳費用超過3,600元者，本公司最高僅收取3,600 元。',
                '其他相關事宜，詳如會員規章內文。',
            ],
            config: [
                {
                    title: '服裝禮儀',
                    img: icon_clothing,
                    start: 0,
                    end: 4,
                },
                {
                    title: '來店提醒，為維護運動品質',
                    img: icon_prohibited,
                    start: 4,
                    end: 6,
                },
                {
                    title: '運動禮儀',
                    img: icon_sport,
                    start: 6,
                    end: 9,
                },
                {
                    title: '跨店須知',
                    img: icon_cross_store,
                    start: 9,
                    end: 12,
                    WarnComponent: WarnComponent,
                },
                {
                    title: '會員權暫停',
                    img: icon_suspension,
                    start: 12,
                    end: 16,
                },
                {
                    title: '會員權終止',
                    img: icon_termination,
                    start: 16,
                },
            ],
        },
        renewal: {
            data: [
                '請穿著有袖上衣。',
                '請穿著⾧度過膝的運動褲。',
                '請穿著乾淨的室內運動鞋。',
                '建議配戴運動手套，除了提升舒適及穩定性之外，還能保護您纖纖玉手。',
                'Curves採會員制，請勿帶孩童進入店舖。',
                '請勿攜帶竉物。',
                '請依順時針交換下一台器材/踏板，未經教練指示請勿任意跳過器材或踏板。',
                '請依語音指示替換器材和踏板，完整運動包含依序使用器材/踏板24分鐘與指定伸展動作6分鐘。',
                '踏板目的是讓心跳恢復、肌肉休息，在踏板上維持輕鬆踏步即可。',
                '凡為每月自動扣款之會員，皆可於全台各店舖免費跨店運動。',
                '跨店前請向教練申請跨店資格，並且確認跨店店舖之營業時間，方可進行跨店運動。',
                '指契約期限屆滿後，接續與本公司締結新契約而未中斷時，無須繳納入會費。',
                '會員得於契約期限屆滿前，應「以月為單位」辦理，本人親自赴所屬店舖辦理暫停會員權。',
                '為配合銀行作業，會員須於暫停前最近一次扣款日之前十日辦理。',
                '因出國逾一個月者，持相關證明親自辦理。',
                '經醫師指示暫時不宜運動且開立診斷書證明者，持診斷證明書辦理。',
                '會員得於契約期限屆滿前，本人親自赴所屬店舖辦理。',
                '為了保障會員權益，請事先與本公司預約時間，辦理相關流程。',
                '已完成二年方案之會員，若於續約期滿前終止契約，如有應補繳費用時，免予補繳。',
                '其他相關事宜，詳如會員規章內文。',
            ],
            config: [
                {
                    title: '服裝禮儀',
                    img: icon_clothing,
                    start: 0,
                    end: 4,
                },
                {
                    title: '來店提醒，為維護運動品質',
                    img: icon_prohibited,
                    start: 4,
                    end: 6,
                },
                {
                    title: '運動禮儀',
                    img: icon_sport,
                    start: 6,
                    end: 9,
                },
                {
                    title: '跨店須知',
                    img: icon_cross_store,
                    start: 9,
                    end: 11,
                    WarnComponent: WarnComponent,
                },
                {
                    title: '會員權續約',
                    img: null,
                    start: 11,
                    end: 12,
                },
                {
                    title: '會員權暫停',
                    img: icon_suspension,
                    start: 12,
                    end: 16,
                },
                {
                    title: '會員權終止',
                    img: icon_termination,
                    start: 16,
                },
            ],
        },
    },
    103: {
        new: {
            data: [
                '請穿著有袖上衣。',
                '請穿著⾧度過膝的運動褲。',
                '請穿著乾淨的室內運動鞋。',
                '建議配戴運動手套，除了提升舒適及穩定性之外，還能保護您纖纖玉手。',
                'Curves採會員制，請勿帶孩童進入店舖。',
                '請勿攜帶竉物。',
                '請依順時針交換下一台器材/踏板，未經教練指示請勿任意跳過器材或踏板。',
                '請依語音指示替換器材和踏板，完整運動包含依序使用器材/踏板24分鐘與指定伸展動作6分鐘。',
                '踏板目的是讓心跳恢復、肌肉休息，在踏板上維持輕鬆踏步即可。',
                '加入之新會員，於原店舖完成前5次運動後，方可開始跨店運動。',
                '凡為每月自動扣款之會員，皆可於全台各店舖免費跨店運動。',
                '跨店前請向教練申請跨店資格，並且確認跨店店舖之營業時間，方可進行跨店運動。',
                '會員得於契約期限屆滿前，應「以月為單位」辦理，本人親自赴所屬店舖辦理暫停會員權。',
                '為配合銀行作業，會員須於暫停前最近一次扣款日之前十日辦理。',
                '因出國逾一個月者，持相關證明親自辦理。',
                '經醫師指示暫時不宜運動且開立診斷書證明者，持診斷證明書辦理。',
                '會員得於契約期限屆滿前，本人親自赴所屬店舖辦理。',
                '為了保障會員權益，請事先與本公司預約時間，辦理相關流程。',
                '終止手續費：以簽約時月平均價格乘以契約期間未到期月數之20%(未滿十五日者以半月計，逾十五日者以一月計)。',
                '會員應補繳費用超過3,600元者，本公司最高僅收取3,600 元。',
                '其他相關事宜，詳如會員規章內文。',
            ],
            config: [
                {
                    title: '服裝禮儀',
                    img: icon_clothing,
                    start: 0,
                    end: 4,
                },
                {
                    title: '來店提醒，為維護運動品質',
                    img: icon_prohibited,
                    start: 4,
                    end: 6,
                },
                {
                    title: '運動禮儀',
                    img: icon_sport,
                    start: 6,
                    end: 9,
                },
                {
                    title: '跨店須知',
                    img: icon_cross_store,
                    start: 9,
                    end: 12,
                    WarnComponent: WarnComponent,
                },
                {
                    title: '會員權暫停',
                    img: icon_suspension,
                    start: 12,
                    end: 16,
                },
                {
                    title: '會員權終止',
                    img: icon_termination,
                    start: 16,
                },
            ],
        },
        newStudent: {
            data: [
                '請穿著有袖上衣。',
                '請穿著⾧度過膝的運動褲。',
                '請穿著乾淨的室內運動鞋。',
                '建議配戴運動手套，除了提升舒適及穩定性之外，還能保護您纖纖玉手。',
                'Curves採會員制，請勿帶孩童進入店舖。',
                '請勿攜帶竉物。',
                '請依順時針交換下一台器材/踏板，未經教練指示請勿任意跳過器材或踏板。',
                '請依語音指示替換器材和踏板，完整運動包含依序使用器材/踏板24分鐘與指定伸展動作6分鐘。',
                '踏板目的是讓心跳恢復、肌肉休息，在踏板上維持輕鬆踏步即可。',
                '加入之新會員，於原店舖完成前5次運動後，方可開始跨店運動。',
                '凡為每月自動扣款之會員，皆可於全台各店舖免費跨店運動。',
                '跨店前請向教練申請跨店資格，並且確認跨店店舖之營業時間，方可進行跨店運動。',
                '會員得於契約期限屆滿前，應「以月為單位」辦理，本人親自赴所屬店舖辦理暫停會員權。',
                '為配合銀行作業，會員須於暫停前最近一次扣款日之前十日辦理。',
                '因出國逾一個月者，持相關證明親自辦理。',
                '經醫師指示暫時不宜運動且開立診斷書證明者，持診斷證明書辦理。',
                '會員得於契約期限屆滿前，本人親自赴所屬店舖辦理。',
                '為了保障會員權益，請事先與本公司預約時間，辦理相關流程。',
                '終止手續費：以簽約時月平均價格乘以契約期間未到期月數之20%(未滿十五日者以半月計，逾十五日者以一月計)。',
                '學生方案期間退會，無需收取終止手續費；學籍結束即轉一般合約，終止手續費則由一般合約起始時間進行計算。',
                '會員應補繳費用超過3,600元者，本公司最高僅收取3,600 元。',
                '其他相關事宜，詳如會員規章內文。',
            ],
            config: [
                {
                    title: '服裝禮儀',
                    img: icon_clothing,
                    start: 0,
                    end: 4,
                },
                {
                    title: '來店提醒，為維護運動品質',
                    img: icon_prohibited,
                    start: 4,
                    end: 6,
                },
                {
                    title: '運動禮儀',
                    img: icon_sport,
                    start: 6,
                    end: 9,
                },
                {
                    title: '跨店須知',
                    img: icon_cross_store,
                    start: 9,
                    end: 12,
                    WarnComponent: WarnComponent,
                },
                {
                    title: '會員權暫停',
                    img: icon_suspension,
                    start: 12,
                    end: 16,
                },
                {
                    title: '會員權終止',
                    img: icon_termination,
                    start: 16,
                },
            ],
        },
        renewal: {
            data: [
                '請穿著有袖上衣。',
                '請穿著⾧度過膝的運動褲。',
                '請穿著乾淨的室內運動鞋。',
                '建議配戴運動手套，除了提升舒適及穩定性之外，還能保護您纖纖玉手。',
                'Curves採會員制，請勿帶孩童進入店舖。',
                '請勿攜帶竉物。',
                '請依順時針交換下一台器材/踏板，未經教練指示請勿任意跳過器材或踏板。',
                '請依語音指示替換器材和踏板，完整運動包含依序使用器材/踏板24分鐘與指定伸展動作6分鐘。',
                '踏板目的是讓心跳恢復、肌肉休息，在踏板上維持輕鬆踏步即可。',
                '凡為每月自動扣款之會員，皆可於全台各店舖免費跨店運動。',
                '跨店前請向教練申請跨店資格，並且確認跨店店舖之營業時間，方可進行跨店運動。',
                '指契約期限屆滿後，接續與本公司締結新契約而未中斷時，無須繳納入會費。',
                '會員得於契約期限屆滿前，應「以月為單位」辦理，本人親自赴所屬店舖辦理暫停會員權。',
                '為配合銀行作業，會員須於暫停前最近一次扣款日之前十日辦理。',
                '因出國逾一個月者，持相關證明親自辦理。',
                '經醫師指示暫時不宜運動且開立診斷書證明者，持診斷證明書辦理。',
                '會員得於契約期限屆滿前，本人親自赴所屬店舖辦理。',
                '為了保障會員權益，請事先與本公司預約時間，辦理相關流程。',
                '已完成二年方案之會員，若於續約期滿前終止契約，如有應補繳費用時，免予補繳。',
                '其他相關事宜，詳如會員規章內文。',
            ],
            config: [
                {
                    title: '服裝禮儀',
                    img: icon_clothing,
                    start: 0,
                    end: 4,
                },
                {
                    title: '來店提醒，為維護運動品質',
                    img: icon_prohibited,
                    start: 4,
                    end: 6,
                },
                {
                    title: '運動禮儀',
                    img: icon_sport,
                    start: 6,
                    end: 9,
                },
                {
                    title: '跨店須知',
                    img: icon_cross_store,
                    start: 9,
                    end: 11,
                    WarnComponent: WarnComponent,
                },
                {
                    title: '會員權續約',
                    img: null,
                    start: 11,
                    end: 12,
                },
                {
                    title: '會員權暫停',
                    img: icon_suspension,
                    start: 12,
                    end: 16,
                },
                {
                    title: '會員權終止',
                    img: icon_termination,
                    start: 16,
                },
            ],
        },
    },
};
