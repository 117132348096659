import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';
import useShowPDF from '@util/hook/useShowPDF';

const GET_DOC_MEMBERSHIP_TRANSFER_IN_UNENTERED_OPTIONS_API = `/api/doc/membership/transfer-in/unentered/options/`;
const GET_DOC_MEMBERSHIP_TRANSFER_IN_ENTERED_OPTIONS_ALREADY_API = `/api/doc/membership/transfer-in/entered/options/`;
const POST_DOC_MEMBERSHIP_TRANSFER_IN_RANGE_API = `/api/doc/membership/transfer-in/range`;
const GET_DOC_MEMBERSHIP_TRANSFER_IN_HISTORY_API = `/api/doc/membership/transfer-in/history/`;
const GET_DOC_MEMBERSHIP_TRANSFER_IN_CONTRACTID_API = (contractID) => `/api/doc/membership/transfer-in/${contractID}/`;
const POST_DOC_MEMBERSHIP_TRANSFER_IN_CONTRACTID_API = GET_DOC_MEMBERSHIP_TRANSFER_IN_CONTRACTID_API;
const DELETE_DOC_MEMBERSHIP_TRANSFER_IN_CONTRACTID_API = GET_DOC_MEMBERSHIP_TRANSFER_IN_CONTRACTID_API;
const GET_DOC_MEMBERSHIP_TRANSFER_IN_TEMPORARY_API = `/api/doc/membership/transfer-in/temporary/`;
const POST_DOC_MEMBERSHIP_TRANSFER_IN_TEMPORARY_API = GET_DOC_MEMBERSHIP_TRANSFER_IN_TEMPORARY_API;
const DELETE_DOC_MEMBERSHIP_TRANSFER_IN_TEMPORARY_API = GET_DOC_MEMBERSHIP_TRANSFER_IN_TEMPORARY_API;

const useDocMembershipTransferInApi = () => {
    const Ajax = useAjax();
    const showPDF = useShowPDF();
    const { enqueueSnackbar } = useSnackbar();

    // 移入 search 預設option
    const getInitTransferInUnenteredOptionApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_IN_UNENTERED_OPTIONS_API);
            if (!resp) {
                enqueueSnackbar('預載資料有誤，請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 移入 search 預設option
    const getInitTransferInEnteredOptionApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_IN_ENTERED_OPTIONS_ALREADY_API);
            if (!resp) {
                enqueueSnackbar('預載資料有誤，請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 依入會方案取得合約日期、會籍日期、初次費用
    const postTransferInRangeApi = useCallback(
        async (params) => {
            const respOptions = await Ajax.post(POST_DOC_MEMBERSHIP_TRANSFER_IN_RANGE_API, params);
            if (!respOptions) {
                enqueueSnackbar('預載資料有誤，請重新整理', { variant: 'error' });
            }
            return respOptions;
        },
        // eslint-disable-next-line
        []
    );

    // 會籍移入歷史紀錄
    const postDocTransferInContractHistoryApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_IN_HISTORY_API, params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 讀取會籍移入申請書
    const getDocTransferInContractApi = useCallback(
        async (contractID, params) => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_IN_CONTRACTID_API(contractID), params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增會籍移入申請書
    const postDocTransferInContractApi = useCallback(
        async (contractID, params) => {
            const resp = await Ajax.post(POST_DOC_MEMBERSHIP_TRANSFER_IN_CONTRACTID_API(contractID), params);
            if (resp && !resp.isError) {
                enqueueSnackbar('新增成功', { variant: 'success' });
                return resp;
            }
            return null;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除會籍移入申請書
    const deleteDocTransferInContractApi = useCallback(
        async (contractID) => {
            const resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_TRANSFER_IN_CONTRACTID_API(contractID));
            if (resp) {
                enqueueSnackbar('刪除成功', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 確認是否有會籍移入暫存資料
    const getDocTemporaryTransferInContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_IN_TEMPORARY_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 讀取暫存會籍移入資料
    const postDocTemporaryTransferInContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_DOC_MEMBERSHIP_TRANSFER_IN_TEMPORARY_API, params);
            if (resp) {
                enqueueSnackbar('暫存移入申請書成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除 暫存會籍移入資料
    const deleteDocTemporaryTransferInContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_TRANSFER_IN_TEMPORARY_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 移入申請書下載
    const getDocTransferInPDFApi = useCallback(
        async (params) => {
            showPDF({
                open: true,
                pdfUrl: params,
            });
        },
        // eslint-disable-next-line
        []
    );

    return {
        getInitTransferInUnenteredOptionApi,
        getInitTransferInEnteredOptionApi,
        postTransferInRangeApi,
        postDocTransferInContractHistoryApi,
        getDocTransferInContractApi,
        postDocTransferInContractApi,
        deleteDocTransferInContractApi,
        getDocTemporaryTransferInContractApi,
        postDocTemporaryTransferInContractApi,
        deleteDocTemporaryTransferInContractApi,
        getDocTransferInPDFApi,
    };
};

export default useDocMembershipTransferInApi;
