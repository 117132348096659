export default {
  101: {
    data: [
      "以簽約時單月月費1800元乘以實際經過月數(未滿十五日者以半月計，逾十五日者以一月計;停權期間及贈送會籍期間不計)計算已使用應繳交之費用。",
      "如計算結果會員應補繳差額月費，扣除本公司應退還之費用後，會員應補繳費用超過3,600元者，本公 司最高僅收取3,600元。",
      "繳回會員電子簽到卡。若遺失電子簽到卡，須補工本費",
      "如有承租置物櫃辦理退租與領回物品。",
      "Curves 關心您身體健康及營養均衡的重要性，您可用循環優惠價持續訂購Curves健康產品。為了保障您的權益，如需調整訂單內容，請洽詢該店舖教練。",
    ],
  },
  102: {
    data: [
      "終止手續費：以簽約時月平均價格乘以契約期間未到期月數之 20% (未滿十五日者以半月計，逾十五日者以一月計)。",
      "若會員應補繳費用超過3,600元者，本公司最高收取3,600元。",
      "繳回會員電子簽到卡。若遺失電子簽到卡，須補工本費",
      "如有承租置物櫃辦理退租與領回物品。",
      "Curves 關心您身體健康及營養均衡的重要性，您可用循環優惠價持續訂購健康產品。為了保障您的權益，如需調整訂單內容，請洽詢該店舖教練。",
      "完成指定流程相關費用之辦理。",
    ],
  },
  103: {
    data: [
      "終止手續費：以簽約時月平均價格乘以契約期間未到期月數之 20% (未滿十五日者以半月計，逾十五日者以一月計)。",
      "若會員應補繳費用超過3,600元者，本公司最高收取3,600元。",
      "繳回會員電子簽到卡。若遺失電子簽到卡，須補工本費",
      "如有承租置物櫃辦理退租與領回物品。",
      "Curves 關心您身體健康及營養均衡的重要性，您可用循環優惠價持續訂購健康產品。為了保障您的權益，如需調整訂單內容，請洽詢該店舖教練。",
      "完成指定流程相關費用之辦理。",
    ],
  },
};
