import React from 'react';
import { Card } from '@common/components/';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { displayDateText } from '@util/moment';

const ConsumerSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { memberName, memberNo, cellPhone, birthday } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="訂購人資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'會員姓名'}>{memberName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'手機/電話'}>{cellPhone}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'會員編號'}>{memberNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'生日'}>{displayDateText(birthday)}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

export default ConsumerSectionByRead;
