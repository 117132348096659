import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, Box, MenuItem, SelectField, Card, Typography, TextField, Stack, IconButton, Collapse, Alert } from '@common/components/';
import { AngleDown as AngleDownIcon, AngleUp as AngleUpIcon } from '@common/SvgIcon/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { SimpleContentItem } from '@icoach/members/components';
import { SalesAllowanceItemTable } from '@icoach/accountBook/tables';
import { formatCurrencyFn, refIsRequiredError } from '@util/utils';
import useOrderReturnApi from '@apis/useOrderReturnApi';
import { displayDateText } from '@util/moment';
import { PaidList } from '@icoach/documents/components';
import { PaidListNew } from '@icoach/documents/components/PaidList';
import { BATCH_ORDER } from '@icoach/accountBook/staticData';

// 取得付款金額與退款金額
const getTotalPriceData = (data = []) => {
    return data.reduce(
        (totalObj, current) => {
            const { salePrice, quantity, returnPrice, returnQuantity } = current;
            let _paidPrice = parseInt(salePrice) * parseInt(quantity);
            let _returnPrice = parseInt(returnPrice) * parseInt(returnQuantity);
            return {
                paidPrice: totalObj.returnPrice + _paidPrice,
                returnPrice: totalObj.returnPrice + _returnPrice,
            };
        },
        { paidPrice: 0, returnPrice: 0 },
    );
};

// 發票資訊
export const InvoiceInfoBox = (props) => {
    const { className, data = {} } = props;
    const { invoiceNo, invoiceTotalPrice, invoiceReturnPrice, isManual = false } = data;
    return (
        <Card className={className}>
            <Card.CardContent className={'pt-2 px-3 pb-3'}>
                <Grid spacing={2} container>
                    <Grid xs={4} item>
                        <SimpleContentItem label={'發票號碼'}>{invoiceNo}</SimpleContentItem>
                    </Grid>
                    <Grid xs={4} item>
                        <SimpleContentItem label={'發票金額'}>{formatCurrencyFn(invoiceTotalPrice)}</SimpleContentItem>
                    </Grid>
                    <Grid xs={4} item>
                        <SimpleContentItem label={'退單金額'}>{formatCurrencyFn(invoiceReturnPrice)}</SimpleContentItem>
                    </Grid>
                    {isManual && (
                        <Grid xs={12} item>
                            <Alert className={'alert-justify-content-start'} color={'info'} severity={'info'} variant={'filled'}>
                                此為手開發票訂單，發票異動需由店舖自行處理。
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </Card.CardContent>
        </Card>
    );
};

// 結帳資訊
export const CheckoutPaidListBox = React.forwardRef((props, ref) => {
    const { total, isShowCash = false, isShowCredit = false, belowText } = props;
    const cashRef = useRef();
    const cardRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = { cashRefund: 0, cardRefund: 0 };
                if (cashRef.current && cashRef.current.getResult) {
                    result = Object.assign(result, { cashRefund: cashRef.current.getResult() });
                }
                if (cardRef.current && cardRef.current.getResult) {
                    result = Object.assign(result, { cardRefund: cardRef.current.getResult() });
                }
                return result;
            },
            isError: () => {
                return refIsRequiredError(cashRef, cardRef);
            },
        }),
        [],
    );

    return (
        <PaidListNew className={clsx('checkout-box')}>
            <PaidList.PaidListGroup>
                <PaidList.PaidListItem label={'折讓總計'}>
                    <strong className={'paid-list-cell'}>{formatCurrencyFn(total)}</strong>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label={'實際退款金額'}>
                    <strong className={'paid-list-cell'}>{formatCurrencyFn(total)}</strong>
                </PaidList.PaidListItem>
            </PaidList.PaidListGroup>
            <PaidList.PaidListGroup>
                {isShowCash && (
                    <PaidList.PaidListItem label={'現金退款金額'}>
                        <TextField maskType={'MONEY'} ref={cashRef} defaultValue={0} required />
                    </PaidList.PaidListItem>
                )}
                {isShowCredit && (
                    <PaidList.PaidListItem label={'信用卡退款金額'}>
                        <TextField maskType={'MONEY'} ref={cardRef} defaultValue={0} required />
                    </PaidList.PaidListItem>
                )}
                {belowText && <Typography>{belowText}</Typography>}
            </PaidList.PaidListGroup>
        </PaidListNew>
    );
});

export const OtherFieldBox = React.forwardRef((props, ref) => {
    const { data = {} } = props;
    const { coachOptions: employeeOptions } = data;
    const employeeRef = useRef();
    const noteRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
                }
                if (noteRef.current && noteRef.current.getResult) {
                    result = Object.assign(result, { notes: noteRef.current.getResult() });
                }
                return result;
            },
            isError: () => refIsRequiredError(employeeRef, noteRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Card>
            <Card.CardContent className={'p-3'}>
                <Grid className={'mb-4'} spacing={3} container>
                    <Grid xs={12} item>
                        <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                            {Array.isArray(employeeOptions) &&
                                employeeOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField label="備註" ref={noteRef} rows={4} fullWidth multiline required />
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
});

// 當前訂單
export const OrderCard = React.forwardRef((props, ref) => {
    const { className, data = {}, calculateOrderTotal } = props;
    const { orderNo, orderDate, items } = data;
    const { paidPrice, returnPrice } = getTotalPriceData(items);
    return (
        <Card className={clsx('cart', className)}>
            <Card.CardContent>
                <Box className={'px-4 py-2'}>
                    <Typography className={'font-weight-bold text-primary'} variant={'h5'} component={'h4'}>
                        請選擇要折讓的品項
                    </Typography>
                    <Grid spacing={2} container>
                        <Grid xs={4} item>
                            <SimpleContentItem label={'訂單編號'}>{orderNo}</SimpleContentItem>
                        </Grid>
                        <Grid xs={4} item>
                            <SimpleContentItem label={'訂單日期'}>{displayDateText(orderDate)}</SimpleContentItem>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <SalesAllowanceItemTable
                        ref={ref}
                        className={'theme-gray'}
                        data={items}
                        paidPrice={paidPrice}
                        returnPrice={returnPrice}
                        calculateOrderTotal={calculateOrderTotal}
                    />
                </Box>
            </Card.CardContent>
        </Card>
    );
});

// 其他訂單
export const OtherOrderCard = React.forwardRef((props, ref) => {
    const { className, data = [], calculateOrderTotal } = props;
    const [open, setOpen] = useState(false);
    const { paidPrice, returnPrice } = getTotalPriceData(data);

    return (
        <Card className={clsx('cart', className)}>
            <Card.CardContent>
                <Stack className={'px-4 py-2'} justifyContent={'space-between'}>
                    <Typography className={'m-0 font-weight-bold'} variant={'body1'} component={'h4'}>
                        其他訂單銷售品項
                    </Typography>
                    <IconButton onClick={() => setOpen((prev) => !prev)}>{open ? <AngleUpIcon /> : <AngleDownIcon />}</IconButton>
                </Stack>
                <Collapse in={open}>
                    <Box>
                        <SalesAllowanceItemTable
                            ref={ref}
                            className={'theme-gray'}
                            data={data}
                            paidPrice={paidPrice}
                            returnPrice={returnPrice}
                            calculateOrderTotal={calculateOrderTotal}
                        />
                    </Box>
                </Collapse>
            </Card.CardContent>
        </Card>
    );
});

const OrderAllowanceDialogContent = React.forwardRef((props, ref) => {
    const { data } = props;
    const { otherItems, paymentType, isBatchOrder } = data || {};
    const [total, setTotal] = useState(0);
    const currentRef = useRef();
    const otherItemsRef = useRef();
    const checkoutRef = useRef();
    const otherRef = useRef();

    const isCreditCardPayment = String(paymentType) === '1';
    const isACHPayment = String(paymentType) === '2';
    const isCashPayment = String(paymentType) === '3';
    const creditCardRefundHint = '此訂單完成折讓或作廢時將合併於批次扣款刷退中一併退還予會員';
    const achRefundHint = '此訂單完成折讓或作廢，請退還現金給會員';

    let calculateOrderTotal = (currentRef, otherItemsRef, setTotal) => {
        return function () {
            let total = 0;
            if (currentRef.current && currentRef.current.getTotal) total = total + currentRef.current.getTotal();
            if (otherItemsRef.current && otherItemsRef.current.getTotal) total = total + otherItemsRef.current.getTotal();
            setTotal(total);
        };
    };

    calculateOrderTotal = calculateOrderTotal(currentRef, otherItemsRef, setTotal);

    const getResult = () => {
        let result = {};
        let items = [];

        if (isBatchOrder && !isCashPayment) {
            let cashRefund = 0;
            let cardRefund = 0;

            if (isCreditCardPayment) {
                // 批次信用卡付款,批次退刷
                cardRefund = total;
            } else {
                // 批次ACH付款,退還現金
                cashRefund = total;
            }
            result = Object.assign(result, { cashRefund, cardRefund });
        } else {
            if (checkoutRef.current && checkoutRef.current.getResult) {
                result = Object.assign(result, checkoutRef.current.getResult());
            }
        }

        if (currentRef.current && currentRef.current.getResult) {
            items = items.concat(currentRef.current.getResult());
        }

        if (otherItemsRef.current && otherItemsRef.current.getResult) {
            items = items.concat(otherItemsRef.current.getResult());
        }

        if (otherRef.current && otherRef.current.getResult) {
            result = Object.assign(result, otherRef.current.getResult());
        }

        result = Object.assign(result, { items });

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(currentRef, otherItemsRef, checkoutRef, otherRef),
        }),
        // eslint-disable-next-line
        [total],
    );

    return (
        <DialogContent className={'bg-blue-gray-50 container main-container-spacing'}>
            <InvoiceInfoBox className={'mb-4'} data={data} />
            <OrderCard ref={currentRef} className={'mb-4'} data={data} calculateOrderTotal={calculateOrderTotal} />
            {!isEmpty(otherItems) && <OtherOrderCard ref={otherItemsRef} data={otherItems} className={'mb-4'} calculateOrderTotal={calculateOrderTotal} />}
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <OtherFieldBox ref={otherRef} data={data} />
                </Grid>
                <Grid className={'ml-auto'} xs={6} item>
                    <CheckoutPaidListBox
                        className={'mb-4'}
                        ref={checkoutRef}
                        total={total}
                        isShowCash={!isBatchOrder || isCashPayment} // 現場訂單或現金訂單才顯示
                        isShowCredit={!isBatchOrder || isCashPayment} // 現場訂單或現金訂單才顯示
                        belowText={isBatchOrder && isCreditCardPayment ? creditCardRefundHint : isACHPayment ? achRefundHint : ''}
                    />
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const OrderAllowanceDialog = (props) => {
    const { className, open, sourceData = {}, onClose, refresh } = props;
    const orderID = sourceData?.orderID;
    const { getOrderAllowanceApi, postOrderAllowanceConfirmApi } = useOrderReturnApi();
    const [data, setData] = useState({});
    const { orderNo, orderSourceType } = data;
    const isBatchOrder = String(orderSourceType) === BATCH_ORDER;
    const contentRef = useRef(null);

    const getParams = () => {
        let result = {
            isRefund: !isBatchOrder,
        };
        if (contentRef.current && contentRef.current.getResult) {
            result = Object.assign(result, contentRef.current.getResult());
        }

        return result;
    };

    const doOrderAllowanceApi = async (orderID) => {
        let res = await getOrderAllowanceApi(orderID);
        if (res) {
            setData(res);
        } else {
            onClose();
        }
    };

    const doOrderAllowanceConfirmApi = async (params) => {
        let res = await postOrderAllowanceConfirmApi(params);
        if (res) {
            if (typeof refresh === 'function') refresh();
            onClose();
        }
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doOrderAllowanceConfirmApi(params);
        }
    };

    useEffect(() => {
        if (open) doOrderAllowanceApi(orderID);
        // eslint-disable-next-line
    }, [open]);

    if (!open || isEmpty(data)) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>{`訂單(${orderNo})進行折讓`}</GradientColorHeader>
            <OrderAllowanceDialogContent ref={contentRef} data={{ ...data, isBatchOrder }} />
            <DialogActions className={'bg-blue-gray-50'}>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrderAllowanceDialog;
