import React from 'react';
import { Card } from '@common/components/';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';

const ReceiverSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { receiverName, receiverMobile, receiverPostCode = '', receiverAddress = '', receiverNotes } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title={'收件人資訊'} className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'收件人'}>{receiverName}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'收件手機'}>{receiverMobile}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'收件地址'}>{`${receiverPostCode} ${receiverAddress}`}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'備註'}>{receiverNotes}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

export default ReceiverSectionByRead;
