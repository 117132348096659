import React, { useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';

const EditRecordDialog = (props) => {
    const {
        setOpen: propsSetOpen,
        open: propsOpen,
        doAction,
        notes: propsNotes = '',
        title: propsTitle,
    } = props;

    const titleRef = useRef(null);
    const noteRef = useRef(null);

    const handleCloseOnClick = () => {
        propsSetOpen && propsSetOpen();
    };

    const handleSendOnClick = (e) => {
        let params = {
            title: titleRef.current.getResult(),
            notes: noteRef.current.getResult(),
        };
        doAction && doAction(params);
    };

    return (
        <Dialog open={propsOpen} fullWidth>
            <GradientColorHeader onClose={handleCloseOnClick}>編輯聯繫資訊</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="標題"
                            ref={titleRef}
                            fullWidth
                            defaultValue={propsTitle}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="聯繫內容"
                            ref={noteRef}
                            rows={5}
                            multiline
                            fullWidth
                            defaultValue={propsNotes}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={handleSendOnClick}
                >
                    確定
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditRecordDialog;
