import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import useShowLoading from '@util/hook/useShowLoading';
import { useSnackbar } from 'notistack';

const GET_SMS_OVERVIEW_OPTIONS_API = `/api/sms/overview/options/`;
const POST_SMS_OVERVIEW_API = `/api/sms/overview/`;
const GET_SMS_SMSID_API = (smsID) => `/api/sms/${smsID}/`;
const POST_SMS_API = `/api/sms/`;
const POST_SMS_TEMPORARIES_API = `/api/sms/temporaries/`;
const POST_SMS_SMSID_CANCEL_API = (smsID) => `/api/sms/${smsID}/cancel/`;
const GET_SMS_POINT_API = `/api/sms/point/`;

const useSmsTemplateApi = () => {
    const Ajax = useAjax();
    const showLoading = useShowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const getSmsOverviewOptions = useCallback(
        async () => {
            const resp = await Ajax.get(GET_SMS_OVERVIEW_OPTIONS_API);
            if (!resp) {
                enqueueSnackbar('簡訊總覽預設選項讀取失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 簡訊資料總覽
    const postSmsOverviewApi = useCallback(
        async (params) => {
            let sourceData = {};
            showLoading(true);
            const respData = await Ajax.post(POST_SMS_OVERVIEW_API, params);
            if (!respData) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            } else {
                Object.assign(sourceData, respData);
            }
            const points = await Ajax.post(GET_SMS_POINT_API);
            if (points) {
                Object.assign(sourceData, { points });
            }
            showLoading(false);
            return sourceData;
        },
        // eslint-disable-next-line
        []
    );

    // 取得單筆簡訊明細列表
    const getSmsIDOverviewApi = useCallback(
        async (smsID) => {
            const resp = await Ajax.get(GET_SMS_SMSID_API(smsID));
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增發送簡訊
    const postSendSmsApi = useCallback(
        async (params, isSpecify) => {
            const resp = await Ajax.post(POST_SMS_API, params);
            if (resp) {
                const text = isSpecify ? '預約簡訊發送成功' : '簡訊直接發送成功';
                enqueueSnackbar(text, { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增發送簡訊(顧客/名單的暫存清單使用)
    const postSendSmsTemporariesApi = useCallback(
        async (params, isSpecify) => {
            const resp = await Ajax.post(POST_SMS_TEMPORARIES_API, params);
            if (resp) {
                const text = isSpecify ? '預約簡訊發送成功' : '簡訊直接發送成功';
                enqueueSnackbar(text, { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取消簡訊
    const deleteSmsIDCancelApi = useCallback(
        async (smsid) => {
            const resp = await Ajax.delete(POST_SMS_SMSID_CANCEL_API(smsid));
            if (resp) {
                enqueueSnackbar('簡訊已取消', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 門店簡訊點數
    const getSmsPointApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_SMS_POINT_API);
            if (!resp) {
                enqueueSnackbar('讀取簡訊點數失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getSmsOverviewOptions,
        postSmsOverviewApi,
        getSmsIDOverviewApi,
        postSendSmsApi,
        postSendSmsTemporariesApi,
        deleteSmsIDCancelApi,
        getSmsPointApi,
    };
};

export default useSmsTemplateApi;
