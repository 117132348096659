import React, { useEffect, useState, useImperativeHandle, useRef } from 'react';
import MemberContactDialog from '@icoach/members/dialog/MemberContactDialog';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';
import { ContactRecordCard, ContactSMSRecordCard } from '@icoach/components/';
import { Alert, Button, Card, Typography, Box } from '@common/components/';
import useMemberContactReconrdApi from '@apis/useMemberContactReconrdApi';
import { matchOptionsText, mergeContactAndSMSData } from '@util/utils';

const RecordButtonItemGroup = (props) => {
    const { sourceData, refresh } = props;
    const dialogRef = useRef(null);
    return (
        <Box className="btn-group">
            <Button variant={'outlined'} onClick={() => dialogRef.current.openSmsDialog(sourceData)}>
                發送簡訊
            </Button>
            <Button variant={'outlined'} onClick={() => dialogRef.current.openContactDialog(sourceData)}>
                新增聯繫
            </Button>
            <RecordDialogBox ref={dialogRef} refresh={refresh} />
        </Box>
    );
};

const RecordDialogBox = React.forwardRef((props, ref) => {
    const { refresh } = props;
    const [dialogOpen, setDialogOpen] = useState(null);
    const [sourceData, setSourceData] = useState(null);

    const handleOnClose = () => {
        setDialogOpen(null);
    };

    useImperativeHandle(
        ref,
        () => ({
            openContactDialog: (target) => {
                setSourceData(target);
                setDialogOpen('contact');
            },
            openSmsDialog: (target) => {
                setSourceData(target);
                setDialogOpen('sendSMS');
            },
            closeDialog: () => {
                setDialogOpen(null);
            },
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <MemberContactDialog open={dialogOpen === 'contact'} sourceData={sourceData} onClose={handleOnClose} refresh={refresh} />
            <SendSmsDialog open={dialogOpen === 'sendSMS'} sourceData={sourceData} onClose={handleOnClose} refresh={refresh} />
        </React.Fragment>
    );
});

const MemberDetailContactRecordContent = ({ memberID: mID, name }) => {
    const { getMembersContactRecordsDataApi, deleteMemberContactApi, putMemberContactApi } = useMemberContactReconrdApi();
    const [sourceData, setSourceData] = useState(null);
    const [isMounted, setMounted] = useState(false);
    const refreshRef = useRef(null);

    const handleDeleteContact = (_, contactID) => {
        doDeleteMemberContactApi(mID, contactID);
    };

    const handleEditContact = (_, contactID, params) => {
        doPutMemberContactApi(mID, contactID, params);
    };

    const doPutMemberContactApi = async (memberID, contactID, params) => {
        const resp = await putMemberContactApi(memberID, contactID, params);
        if (resp) {
            setMounted(false);
            await initContactRecordsDataApi();
            setMounted(true);
        }
    };

    const doDeleteMemberContactApi = async (memberID, contactID) => {
        const resp = await deleteMemberContactApi(memberID, contactID);
        if (resp) {
            setMounted(false);
            await initContactRecordsDataApi();
            setMounted(true);
        }
    };

    const initContactRecordsDataApi = async () => {
        const resp = await getMembersContactRecordsDataApi(mID);
        if (resp) {
            setSourceData(resp);
            setMounted(true);
        }
    };

    useEffect(
        () => {
            initContactRecordsDataApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [],
    );

    const { resource: options = {} } = sourceData || {};
    const { list = [], sms = [] } = sourceData || {};
    const newList = mergeContactAndSMSData(list, sms);
    refreshRef.current = initContactRecordsDataApi;

    return (
        <Box className={'contact-record-content'}>
            {isMounted ? (
                <Card className={'member-layout'}>
                    <header className={'page-header'}>
                        <Typography className={'title'} variant={'h5'} component={'h2'}>
                            聯繫紀錄
                        </Typography>
                        <RecordButtonItemGroup sourceData={{ memberID: mID, id: mID, type: 2, name }} refresh={refreshRef} />
                    </header>
                    <Card.CardContent className="px-4 pb-3">
                        {Array.isArray(newList) && newList.length > 0 ? (
                            <ul className="time-line contact-record">
                                {newList.map((item) => {
                                    const { contactResultTypes, coaches } = options;
                                    return (
                                        <li key={item.key} className="time-line-item">
                                            {item.isSMS ? (
                                                <ContactSMSRecordCard {...item} employeeName={matchOptionsText(coaches, item.employeeID, '')} />
                                            ) : (
                                                <ContactRecordCard
                                                    {...item}
                                                    customerID={mID}
                                                    contactID={item.contactID}
                                                    contactResultTypeText={matchOptionsText(contactResultTypes, item.contactResultType, '')}
                                                    coachName={matchOptionsText(coaches, item.employeeID, '')}
                                                    handleDeleteContact={handleDeleteContact}
                                                    handleEditContact={handleEditContact}
                                                />
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <Alert color="info" severity="info">
                                尚無任何聯繫資料
                            </Alert>
                        )}
                    </Card.CardContent>
                </Card>
            ) : null}
        </Box>
    );
};

export default MemberDetailContactRecordContent;
