import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';
import useShowLoading from '@util/hook/useShowLoading';
import { GET_CABINET_API } from '@apis/useCabinetApi';

// 租賃
const GET_RENT_CABINET_API = `/api/cabinet/rental/`;
const GET_RENT_CABINET_OPTIONS_API = `/api/cabinet/rental/options/`;
const GET_RENT_CABINET_CHECK_DEBIT_PERSONAL_API = (memberID) => `/api/cabinet/rental/check-debit-personal/${memberID}/`;
const POST_RENT_CABINET_LIST_API = `/api/cabinet/rental/list/`;
const GET_RENT_CABINET_ID_API = (cabinetRentalID) => `/api/cabinet/rental/${cabinetRentalID}/`;
const PUT_QUIT_RENT_CABINET_API = (cabinetRentalID) => `/api/cabinet/rental/quit/${cabinetRentalID}/`;
const DELETE_QUIT_RENT_CABINET_API = (cabinetRentalID, employeeID) => `/api/cabinet/rental/delete-quit/${cabinetRentalID}/${employeeID}/`;
const POST_RENT_CABINET_ORDERS_API = (memberID) => `/api/cabinet/rental/${memberID}/orders/`;
const GET_RENT_CABINET_MEMBERID_API = (memberID) => `/api/cabinet/rental/member/${memberID}/`;
const PUT_RENT_CABINET_MEMBERID_QUIT_API = (memberID) => `/api/cabinet/rental/member/quit/${memberID}/`;

// 置物櫃管理 api
const useCabinetRentalApi = () => {
    const Ajax = useAjax();
    const isLoading = useShowLoading();
    const { enqueueSnackbar } = useSnackbar();

    // 置物櫃的下拉式選單
    // 取得所有閒置的置物櫃列表(ID 名稱 租金 備註)
    const getInitRentCabinetApi = useCallback(
        async () => {
            let result = {};
            isLoading(true);
            const cabinets = await Ajax.get(GET_CABINET_API);
            const resource = await Ajax.get(GET_RENT_CABINET_OPTIONS_API);
            if (cabinets) result = Object.assign(result, { cabinets });
            if (resource) result = Object.assign(result, { resource });
            isLoading(false);
            return result;
        },
        // eslint-disable-next-line
        []
    );

    const getInitRentCabinetByEditApi = useCallback(
        async (cabinetRentalID) => {
            let result = {};
            isLoading(true);
            const rentCabinetData = await Ajax.get(GET_RENT_CABINET_ID_API(cabinetRentalID));
            const resource = await Ajax.get(GET_RENT_CABINET_OPTIONS_API);
            if (rentCabinetData) result = Object.assign(result, { rentCabinetData });
            if (resource) result = Object.assign(result, { resource });
            isLoading(false);
            return result;
        },
        // eslint-disable-next-line
        []
    );

    const getInitQuitRentCabinetByEditApi = useCallback(
        async (cabinetRentalID) => {
            let result = {};
            isLoading(true);
            const rentCabinetData = await Ajax.get(GET_RENT_CABINET_ID_API(cabinetRentalID));
            const resource = await Ajax.get(GET_RENT_CABINET_OPTIONS_API);
            if (rentCabinetData) result = Object.assign(result, { rentCabinetData });
            if (resource) result = Object.assign(result, { resource });
            isLoading(false);
            return result;
        },
        // eslint-disable-next-line
        []
    );

    // 租櫃的下拉式選單
    const getRentCabinetOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_RENT_CABINET_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 確認信用卡是否為本人 如無信用卡資訊則顯示錯誤(無可用的扣款方式，請於會籍新增信用卡)
    const getCheckDebitPersonalApi = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_RENT_CABINET_CHECK_DEBIT_PERSONAL_API(memberID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得租賃合約列表
    const postRentCabinetListApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_RENT_CABINET_LIST_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得置物櫃租約
    const getRentCabinetApi = useCallback(
        async (cabinetRentalID) => {
            const resp = await Ajax.get(GET_RENT_CABINET_ID_API(cabinetRentalID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增租櫃合約
    const postRentCabinetApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(GET_RENT_CABINET_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 更新租櫃合約
    const putRentCabinetApi = useCallback(
        async (cabinetRentalID, params) => {
            const resp = await Ajax.put(GET_RENT_CABINET_ID_API(cabinetRentalID), params);
            if (resp) {
                enqueueSnackbar('更新成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 終止租櫃合約
    const putQuitRentCabinetApi = useCallback(
        async (cabinetRentalID, params) => {
            const resp = await Ajax.put(PUT_QUIT_RENT_CABINET_API(cabinetRentalID), params);
            if (resp) {
                enqueueSnackbar('更新成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );
    // 刪除退租置物櫃租約
    const deleteQuitRentCabinetApi = useCallback(
        async (cabinetRentalID, employeeID) => {
            const resp = await Ajax.delete(DELETE_QUIT_RENT_CABINET_API(cabinetRentalID, employeeID));
            if (resp) {
                enqueueSnackbar('成功刪除退租申請', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得收費明細資訊
    const postRentCabinetOrdersApi = useCallback(
        async (memberID, params) => {
            const resp = await Ajax.post(POST_RENT_CABINET_ORDERS_API(memberID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getAllRentCabinetDataApi = useCallback(
        async (memberID) => {
            let result = {};
            isLoading(true);
            const rentCabinetsResp = await Ajax.get(GET_RENT_CABINET_MEMBERID_API(memberID));
            const optionsResp = await Ajax.get(GET_RENT_CABINET_OPTIONS_API);
            if (rentCabinetsResp) result = Object.assign(result, { rentCabinetsData: rentCabinetsResp });
            if (optionsResp) result = Object.assign(result, { options: optionsResp });
            isLoading(false);
            return result;
        },
        // eslint-disable-next-line
        []
    );

    const putAllRentCabinetQuitApi = useCallback(
        async (memberID, params) => {
            const resp = await Ajax.put(PUT_RENT_CABINET_MEMBERID_QUIT_API(memberID), params);
            if (!resp) {
                enqueueSnackbar('退租失敗，請再操作一次', { variant: 'error' });
            }else{
                enqueueSnackbar('成功退出', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getInitRentCabinetApi,
        getInitRentCabinetByEditApi,
        getInitQuitRentCabinetByEditApi,
        getRentCabinetOptionsApi,
        getCheckDebitPersonalApi,
        postRentCabinetListApi,
        getRentCabinetApi,
        postRentCabinetApi,
        putRentCabinetApi,
        putQuitRentCabinetApi,
        deleteQuitRentCabinetApi,
        postRentCabinetOrdersApi,
        getAllRentCabinetDataApi,
        putAllRentCabinetQuitApi,
    };
};

export default useCabinetRentalApi;
