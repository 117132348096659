import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, Typography } from '@common/components/';
import useForkRef from '@util/hook/useForkRef';
import GradientColorHeader from './GradientColorHeader';
import { getMessageDialogData } from '@redux/messageDialog/selector';

const MessageDialog = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const messageData = useSelector(getMessageDialogData);
    const {
        open,
        title,
        msg,
        MsgComp,
        msgProps,
        msgRef,
        onClose,
        onConfirm,
        onAsyncConfirm,
        onOpen,
        buttonText = '確定',
        dialogPaperProps = {},
        DialogActionsProps = { className: '' },
        buttonColor: buttonColorProps,
    } = messageData;
    const childRef = useRef(null);
    const forkRef = useForkRef(msgRef, childRef);

    const handleOpen = (e) => {
        setOpenDialog(true);
        onOpen && onOpen();
    };

    const handleClose = (e) => {
        onClose && onClose(e);
        setOpenDialog(false);
    };

    const handleConfirm = (e) => {
        if (onAsyncConfirm) {
            onAsyncConfirm(e, () => setOpenDialog(false));
        } else {
            onConfirm && onConfirm();
            setOpenDialog(false);
        }
    };

    useEffect(
        () => {
            if (open) {
                handleOpen();
            } else {
                handleClose();
            }
        },
        // eslint-disable-next-line
        [messageData]
    );

    if (!openDialog) return null;
    if (msgRef) {
        msgProps.ref = forkRef;
    }
    
    return (
        <Dialog
            className={clsx('simple-content', 'dialog')}
            PaperProps={{ className: clsx('wx-40rem', dialogPaperProps.className) }}
            open={openDialog}
            fullWidth
        >
            <GradientColorHeader onClose={handleClose}>{title}</GradientColorHeader>
            <DialogContent>
                {msg && <Typography paragraph>{msg}</Typography>}
                {MsgComp && React.cloneElement(<MsgComp {...msgProps} />)}
            </DialogContent>
            <DialogActions className={DialogActionsProps.className || ''}>
                <Button variant="contained" onClick={handleConfirm} color={buttonColorProps}>
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MessageDialog;
