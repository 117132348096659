import React, { useState, useRef, useImperativeHandle, useEffect } from 'react';
import { ReadTextField } from '@icoach/components/';
import { AutocompleteField } from '@common/components/';
import { refIsRequiredError, matchOptionsText } from '@util/utils';

const MembershipTransferOutTransferClubCard = React.forwardRef((props, ref) => {
    const { options: optionProps = [], originalClubID, transferClubID, isReadOnly = false } = props;
    const [options, setOptions] = useState([]);
    const transferClubIDRef = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(transferClubIDRef),
                getResult: () => {
                    return {
                        originalClubID: originalClubID,
                        targetClubID: transferClubIDRef.current.getResult().value || 0,
                    };
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            const newOpt = optionProps.filter(({ value }) => String(value) !== String(originalClubID));
            setOptions(newOpt);
        },
        // eslint-disable-next-line
        [optionProps]
    );

    return (
        <div className="transfer-card">
            <div className="transfer-card-item">
                <ReadTextField label="原始店舖">{matchOptionsText(optionProps, originalClubID)}</ReadTextField>
            </div>
            <div className="transfer-card-item">
                <AutocompleteField
                    key={options}
                    defaultValue={String(transferClubID)}
                    ref={transferClubIDRef}
                    label={'接收店舖'}
                    options={options}
                    optionKeys={['text']}
                    primaryKey={'value'}
                    InputProps={{
                        readOnly: isReadOnly,
                    }}
                    fullWidth
                    required
                />
            </div>
        </div>
    );
});

export default MembershipTransferOutTransferClubCard;
