import React from 'react';
import clsx from 'clsx';

const Ripple = (props) => {
    const { pulsate = false, rippleX, rippleY, rippleSize, in: inProp, onExited, timeout } = props;
    const [leaving, setLeaving] = React.useState(false);

    const rippleStyles = {
        width: rippleSize,
        height: rippleSize,
        top: -(rippleSize / 2) + rippleY,
        left: -(rippleSize / 2) + rippleX,
    };

    const rippleClassName = clsx('ripple', 'ripple-visible', pulsate && 'touch-ripple-pulsate');
    const childClassName = clsx('ripple-child', leaving && 'ripple-child-leaving', pulsate && 'ripple-child-pulsate');

    if (!inProp && !leaving) setLeaving(true);

    React.useEffect(() => {
        if (!inProp && onExited != null) {
            // react-transition-group#onExited
            const timeoutId = setTimeout(onExited, timeout);
            return () => {
                clearTimeout(timeoutId);
            };
        }
        return undefined;
    }, [onExited, inProp, timeout]);

    return (
        <span className={rippleClassName} style={rippleStyles}>
            <span className={childClassName}></span>
        </span>
    );
};

export default Ripple;
