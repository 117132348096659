import React, {useImperativeHandle, useRef, useState} from 'react';
import {DateIntervalField, Typography} from '@common/components/';

const MembershipTransferInContactDateItem = React.forwardRef((props, ref) => {
    const {startDate: startDateProps, endDate: endDateProps, errorMessage: errorMessageProps} = props;
    const [startDate, setStartDate] = useState(startDateProps);
    const [endDate, setEndDate] = useState(endDateProps);
    const dateIntervalRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => dateIntervalRef.current.getResult(),
            setDate: (sDate, eDate) => {
                setStartDate(sDate);
                setEndDate(eDate);
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <DateIntervalField
                ref={dateIntervalRef}
                startDateProps={{value: startDate}}
                endDateProps={{value: endDate}}
                className={'mb-2'}
                fullWidth
                disabled
                required
            />
            {errorMessageProps && errorMessageProps !== '' &&
                <Typography className={'text-error mt-1'} compoment="div">
                    {errorMessageProps}
                </Typography>}
        </React.Fragment>
    );
});

export default MembershipTransferInContactDateItem;
