import React, { useState, useEffect, useRef } from 'react';
import { Route, Switch, useParams, Redirect } from 'react-router-dom';
import InBodyInterviewNotes from '@icoach/inbody/interview/InBodyInterviewNotesTable';
import InBodyInterviewSummary from '@icoach/inbody/interview/InBodyInterviewSummary';
import InBodyInterviewMeasure from '@icoach/inbody/interview/InBodyInterviewMeasureTable';
import InBodyContext from '@icoach/inbody/components/InBodyContext';
import { PrivateRoute } from '@common/components/';
import {
    INBODY_INTERVIEW_SUMMARY_PATH,
    INBODY_INTERVIEW_MEASURE_PATH,
    INBODY_INTERVIEW_NOTES_PATH,
    INBODY_INTERVIEW_COMPARE_PATH,
} from '@icoach/router/routerPath';
import useInBodyApi from '@apis/useInBodyApi';
import InBodyInterviewMeasureCompareTable from '@icoach/inbody/interview/InBodyInterviewMeasureCompareTable';
import InBodyInterviewMenberInfoCard from '@icoach/inbody/interview/InBodyInterviewMemberInfoCard';

const InBodyInterviewPage = React.forwardRef((props, ref) => {
    const { getInterviewInitDataApi, getInterviewMemberProfileApi } = useInBodyApi();
    const [sourceData, setSourceData] = useState({});
    const [isMounted, setMounted] = useState(false);
    const { id: peopleID } = useParams();
    const dialogRef = useRef(null);
    const refreshRef = useRef(null);

    const initAllDateApi = async () => {
        const resp = await getInterviewInitDataApi(peopleID);
        setSourceData(resp);
        setMounted(true);
    };

    const refreshProfileApi = async () => {
        const resp = await getInterviewMemberProfileApi(peopleID);
        setSourceData((prev) => ({ ...prev, profileData: resp.profileData }));
    };

    const inbodyInterviewValue = {
        dialogRef: dialogRef,
        peopleID: peopleID,
        memberHeight: sourceData.memberHeight,
        isInterviewNotMeasure: sourceData.isInterviewNotMeasure,
        setSourceData: setSourceData,
        setDialogRef: (el) => (dialogRef.current = el),
        options: sourceData.options || {},
        refresh: refreshRef,
    };

    useEffect(() => {
        if (peopleID) initAllDateApi();
        // eslint-disable-next-line
    }, []);

    refreshRef.current = initAllDateApi;

    const { profileData, options, measureData = [] } = sourceData;

    return (
        <InBodyContext.Provider value={inbodyInterviewValue}>
            <div className="container main-container-spacing inbody-interview">
                {isMounted && (
                    <React.Fragment>
                        <InBodyInterviewMenberInfoCard className={'mb-3'} sourceData={sourceData} peopleID={peopleID} setSourceData={setSourceData} />
                        <Switch>
                            <PrivateRoute
                                exact
                                path={INBODY_INTERVIEW_SUMMARY_PATH(peopleID)}
                                as={InBodyInterviewSummary}
                                sourceData={profileData}
                                options={options}
                                initAllDateApi={initAllDateApi}
                                refreshProfileApi={refreshProfileApi}
                            />
                            <PrivateRoute
                                exact
                                path={INBODY_INTERVIEW_COMPARE_PATH(peopleID)}
                                as={InBodyInterviewMeasureCompareTable}
                                sourceData={measureData.filter((target) => target.inbodyScore)}
                            />
                            <PrivateRoute
                                exact
                                path={INBODY_INTERVIEW_MEASURE_PATH(peopleID)}
                                as={InBodyInterviewMeasure}
                                sourceData={measureData.filter((target) => target.inbodyScore)}
                            />
                            <PrivateRoute exact path={INBODY_INTERVIEW_NOTES_PATH(peopleID)} as={InBodyInterviewNotes} sourceData={measureData} />
                            <Route path="/">
                                <Redirect to={INBODY_INTERVIEW_SUMMARY_PATH(peopleID)} />
                            </Route>
                        </Switch>
                    </React.Fragment>
                )}
            </div>
        </InBodyContext.Provider>
    );
});

export default InBodyInterviewPage;
