import React, { useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Button, Form, TextField, Switch as SwitchField, FormControlLabel } from '../../../../components';
import GradientColorHeader from '../../../components/dialog/GradientColorHeader';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { refIsRequiredError } from '../../../../util/utils';
import { useMemberProfileApi } from '../../../../apis/useMemberProfileApi';
import useInBodyApi from '../../../../apis/useInBodyApi';

const MeasureDayDialogContent = React.forwardRef((props, ref) => {
    const { sourceData, onSwitchChange, showInterviewNotMeasureIcon } = props;
    const { measureDay, isInterviewNotMeasure } = sourceData;
    const measureDayRef = useRef(null);

    const handleIsMeasureOnChange = (event, isInterview) => {
        onSwitchChange &&
            onSwitchChange({
                isInterviewNotMeasure: isInterview,
            });
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    measureDay: measureDayRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(measureDayRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <DialogContent>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        label="量身日"
                        ref={measureDayRef}
                        defaultValue={measureDay}
                        inputProps={{ placeholder: '請輸入', max: 30, min: 1 }}
                        type={'number'}
                        fullWidth
                        required
                    />
                </Grid>
                {showInterviewNotMeasureIcon && (
                    <Grid item xs={12}>
                        <FormControlLabel
                            onChange={handleIsMeasureOnChange}
                            className="m-0"
                            labelPlacement="start"
                            control={<SwitchField defaultChecked={isInterviewNotMeasure} />}
                            label="只面談不量身"
                        />
                    </Grid>
                )}
            </Grid>
        </DialogContent>
    );
});

const MeasureDayDialog = (props) => {
    const { className, open, memberID, data, onClose, showInterviewNotMeasureIcon = true, refresh } = props;
    // console.log(props);
    const { postMemberProfileMeasureDayApi } = useMemberProfileApi();
    const { putInterviewNotMeasureStatusApi } = useInBodyApi();
    const measureDayDialogContentRef = useRef();

    const doMemberProfileMeasureDayApi = async (memberID, params) => {
        let res = await postMemberProfileMeasureDayApi(memberID, params);
        if (res) {
            typeof refresh === 'function' && refresh(memberID);
            onClose();
        }
    };

    const doPutInterviewNotMeasureStatusApi = async (params) => {
        const resp = await putInterviewNotMeasureStatusApi({
            memberID: memberID,
            ...params,
        });
        resp && typeof refresh === 'function' && refresh(memberID);
    };

    const handleSubmit = () => {
        if (!refIsRequiredError(measureDayDialogContentRef)) {
            let params = measureDayDialogContentRef.current && measureDayDialogContentRef.current.getResult();
            doMemberProfileMeasureDayApi(memberID, params);
        }
    };

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-40rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>設定量身日</GradientColorHeader>
            <MeasureDayDialogContent
                ref={measureDayDialogContentRef}
                sourceData={data}
                onSwitchChange={doPutInterviewNotMeasureStatusApi}
                showInterviewNotMeasureIcon={showInterviewNotMeasureIcon}
            />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MeasureDayDialog;
