import React from 'react';
import TableContext from './TableContext';
import Tablelvl2Context from './Tablelvl2Context';
import clsx from 'clsx';

const staticClass = `table-cell`;
const rowMap = ['head', 'body', 'footer'];
const paddingMap = ['checkbox', 'none', 'normal'];
const alignMap = ['left', 'center', 'right', 'justify'];
const whiteSpaceMap = ['normal', 'pre', 'nowrap'];

const TableCell = React.forwardRef((props, ref) => {
    const {
        align = 'inherit',
        className,
        whiteSpace = 'nowrap',
        component: componentProp,
        padding: paddingProp,
        scope: scopeProp,
        size: sizeProp,
        sortDirection,
        variant: variantProp,
        children,
        ...other
    } = props;
    const table = React.useContext(TableContext);
    const tablelvl2 = React.useContext(Tablelvl2Context);
    const isHeadCell = tablelvl2 && tablelvl2.variant === 'head';
    let classNames = staticClass;

    let Component;
    if (componentProp) {
        Component = componentProp;
    } else {
        Component = isHeadCell ? 'th' : 'td';
    }

    let scope = scopeProp;
    if (!scope && isHeadCell) {
        scope = 'col';
    }

    const variant = variantProp || (tablelvl2 && tablelvl2.variant);

    // let ariaSort = null;
    // if (sortDirection) {
    //     ariaSort = sortDirection === 'asc' ? 'ascending' : 'descending';
    // }
    if (rowMap.indexOf(tablelvl2.variant) !== -1) classNames = clsx(classNames, `${staticClass}-${tablelvl2.variant}`);
    if (sizeProp === 'small') classNames = clsx(classNames, `${staticClass}-size-small`);
    if (paddingMap.indexOf(paddingProp) !== -1) classNames = clsx(classNames, `${staticClass}-padding-${paddingProp}`);
    else if (table && table.padding) classNames = clsx(classNames, `${staticClass}-padding-${table.padding}`);
    else classNames = clsx(classNames, `${staticClass}-padding-normal`);
    if (variant === 'head' && table && table.stickyHeader)
        classNames = clsx(classNames, `${staticClass}-sticky-header`);
    if (alignMap.indexOf(align) !== -1) classNames = clsx(classNames, `${staticClass}-align-${align}`);
    if (whiteSpaceMap.indexOf(whiteSpace) !== -1)
        classNames = clsx(classNames, `${staticClass}-white-space-${whiteSpace}`);

    const defaultProperty = {
        scope,
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    return <Component {...defaultProperty}>{children}</Component>;
});

export default TableCell;
