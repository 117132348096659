import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '../../../util/hook/useForkRef';

const CaNoPush = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#C32C4F', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12.1,0.1c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S18.7,0.1,12.1,0.1z M4.5,18.2
	c-1.4-1.7-2.2-3.9-2.2-6.2c0-5.4,4.4-9.8,9.8-9.8c2.4,0,4.5,0.8,6.2,2.2c-0.1,0-0.2,0.1-0.3,0.2l-2.9,2.9c0.1-0.5,0.1-1.2-0.2-1.6
	c-0.4-0.6-0.5-1.1-0.8-1.8c-0.3-0.7-1.2-0.2-2.1,0.4s-2.2,1-2.7,1.1C8.8,5.6,9,6.1,8.9,6.2C8.6,6.4,8.5,6.1,8.4,5.7
	C8.2,5.3,7.8,5.1,7.2,5.7C6.6,6.3,7.5,6.5,7.4,6.9c-0.1,0.4-2,0.6-2.3,0.8C4.8,7.9,5.3,9.6,5.8,9.9c0.5,0.3,2.4-0.3,2.6-1
	C8.7,8.2,8.7,8,9.1,8.1s0.7,1.2,1.3,1.4c0.6,0.1,2.8-0.6,3.8-1l-1,1c-1,0.3-2.1,0.7-2.3,0.8l0,0c-0.3,0.2,0,1.1-0.5,1.2
	c-0.5,0.1-0.6-0.3-0.8-0.9c-0.2-0.6-0.8-0.3-1.1,0.1c-0.4,0.3-0.1,0.7-0.1,1c0,0.3-0.6,0.5-1.1,0.8c-0.5,0.3-0.3,1.5,0.3,1.9
	c0.2,0.2,0.5,0.1,0.7,0L4.7,18C4.6,18,4.5,18.1,4.5,18.2z M12,21.8c-2.4,0-4.5-0.8-6.2-2.2c0.1,0,0.2-0.1,0.3-0.2l4.3-4.3
	c0.2,0.4,0.8,0.7,0.7,0.9c-0.2,0.3-0.9,0.1-1.6,0.2c-0.7,0.1-0.7,0.5-0.5,1.8c0.1,1.2,1,0.9,1.4,0.5c0.4-0.5,1.1-0.7,1.9-1
	c0.7-0.3,0.5,0.1,0.8,0.6c0.3,0.6,0.9,0.4,1.7,0.4c0.8-0.1,1.5,0.8,2.4,0.3c0.9-0.5,0.2-2.1,0.1-2.8c-0.2-0.6,0.7-1.6,0.3-2.2
	c-0.4-0.6-4.1,0.2-4.4,0.6s-0.1,1.4-0.5,1.5h0c-0.9,0.2-0.5-0.9-0.7-1.4c-0.1-0.2-0.2-0.3-0.4-0.3l0.9-0.9c0,0,0.1,0.1,0.1,0.1
	c0.4,0.4,1,0.1,1.9-0.2c0.8-0.3,2-0.2,2.5-0.3c0.6-0.1,0.7-0.9,0.1-1.5c-0.6-0.6-0.7-1-0.8-1.7c0-0.1,0-0.1,0-0.2L19.3,6
	c0.1-0.1,0.1-0.2,0.2-0.3c1.4,1.7,2.2,3.9,2.2,6.2C21.8,17.4,17.4,21.8,12,21.8z"
            />
        </SvgIcon>
    );
});

export default CaNoPush;
