import React from 'react';
import { Box, Button } from '@common/components/';
import useDocMembershipApi from '@apis/useDocMembershipApi';
import { fake_membership, bulkData } from './FakerMembershipData';

const BulkMambershipDataPage = (props) => {
    const { postDocMembershipApi } = useDocMembershipApi();

    const handleOnClick = () => {
        const bulkAry = bulkData.map(({ memberName, nickName, cellPhone, birthday, mail, identifierID, postCode, address }) => {
            const n = JSON.parse(JSON.stringify(fake_membership));
            n.contractBasic.memberName = memberName;
            n.contractBasic.nickName = nickName;
            n.contractBasic.cellPhone = cellPhone;
            n.contractBasic.birthday = birthday;
            n.contractBasic.mail = mail;
            n.contractBasic.identifierID = identifierID;
            n.contractBasic.postCode = postCode;
            n.contractBasic.address = address;
            return n;
        });

        sendBulkApi(bulkAry);
    };

    const sendBulkApi = async (targetData) => {
        let tmpAry = [];
        for (const params of targetData) {
            const resp = await postDocMembershipApi(0, params);
            if (!resp) {
                tmpAry.push({ memberName: params.memberName, cellPhone: params.cellPhone, identifierID: params.identifierID });
            }
        }
        console.log(tmpAry);
    };

    return (
        <Box className="p-2">
            <Button variant="contained" onClick={handleOnClick}>
                批次上傳會籍
            </Button>
        </Box>
    );
};

export default BulkMambershipDataPage;
