import React, { useState } from 'react';
import { NavLink, Switch, useHistory } from 'react-router-dom';
import { Building as BuildingIcon, DisplayCode as DisplayCodeCode } from '../../components/icons/SvgIcon/';
import { SYSTEM_CONFIGURATION_BASIC, SYSTEM_CONFIGURATION_OTHERS } from '../router/EmployeesRouter';
import ConfigurationBasicPage from './systemConfBasic/ConfigurationBasicPage';
import ConfigurationOthersPage from './systemConfOthers/ConfigurationOthersPage';
import { Box, Tabs, Tab, PrivateRoute } from '@common/components/';

/**
 * 初始tabs
 * @param {array} targetData
 * @param {string} pathname
 */
const initTabsValue = (targetData, pathname) => {
    return targetData.find((tar) => tar.pathname === pathname)?.value ?? 'clubsetting';
};

const tabsIndex = () => [
    { value: 'clubsetting', pathname: SYSTEM_CONFIGURATION_BASIC },
    {
        value: 'othersetting',
        pathname: SYSTEM_CONFIGURATION_OTHERS,
    },
];

const ClubSystemPage = React.forwardRef((props, ref) => {
    const history = useHistory();
    const [tabsValue, setTabsValue] = useState(initTabsValue(tabsIndex(), history.location.pathname));

    const handleTabsChange = (e, t) => {
        setTabsValue(t);
    };

    return (
        <Box className="container main-container-spacing">
            <Box component="header" className="page-tabs tabs-bottom-line mb-4">
                <Tabs value={tabsValue} onChange={handleTabsChange}>
                    <Tab
                        icon={<BuildingIcon />}
                        key={'店舖設定'}
                        className={'py-3'}
                        label={'店舖設定'}
                        value={tabsIndex()[0].value}
                        component={NavLink}
                        to={SYSTEM_CONFIGURATION_BASIC}
                    />
                    <Tab
                        icon={<DisplayCodeCode />}
                        key={'其他設定'}
                        className={'py-3'}
                        label={'其他設定'}
                        value={tabsIndex()[1].value}
                        component={NavLink}
                        to={SYSTEM_CONFIGURATION_OTHERS}
                    />
                </Tabs>
            </Box>
            <Switch>
                <PrivateRoute exact path={SYSTEM_CONFIGURATION_BASIC} as={ConfigurationBasicPage} />
                <PrivateRoute exact path={SYSTEM_CONFIGURATION_OTHERS} as={ConfigurationOthersPage} />
            </Switch>
        </Box>
    );
});

export default ClubSystemPage;
