import React, { useRef, useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, TextField, DateField, Typography, Stack, SelectField, MenuItem, Form, Box } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useEmployeeApi from '@apis/useEmployeeApi';
import useClubApi from '@apis/useClubApi';
import { getNowTime, parseDate } from '@util/moment';

// suspendType 目前只有店休 先寫死 by Allen

const ClubSuspensionCreateDialog = React.forwardRef((props, ref) => {
    const { open, onClose: onCloseProps, onRefrash, isEdit: isEditProps = false, rowData: sourceDataProps = {} } = props;
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const { postClubsSuspensionApi, putClubsSuspensionApi } = useClubApi();
    const [options, setOptions] = useState([]);
    const employeeIDRef = useRef(null);
    const monthCountRef = useRef(null);
    const dayCountRef = useRef(null);
    const applyDateRef = useRef(null);
    const reasonRef = useRef(null);
    // 送出 按鈕
    const handleSubmitOnClick = (id) => {
        const params = {
            suspendType: 1,
            applyDate: getNowTime(),
            suspendStartDate: parseDate(applyDateRef.current.getResult()),
            suspendMonth: monthCountRef.current.getResult(),
            suspendDay: dayCountRef.current.getResult(),
            employeeID: employeeIDRef.current.getResult(),
            reasonText: reasonRef.current.getResult(),
        };

        isEditProps ? doEditClubsSuspensionApi(params, id) : doAddClubsSuspensionApi(params);
    };

    const doAddClubsSuspensionApi = async (params) => {
        const resp = await postClubsSuspensionApi(params);
        if (resp) {
            onCloseProps && onCloseProps();
            onRefrash && onRefrash();
        }
    };

    const doEditClubsSuspensionApi = async (params, suspensionID) => {
        const resp = await putClubsSuspensionApi(params, suspensionID);
        if (resp) {
            onCloseProps && onCloseProps();
            onRefrash && onRefrash();
        }
    };

    const getInitOptionsDataApi = async () => {
        const resp = await getEmployeesOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    useEffect(
        () => {
            if (open && options.length === 0) {
                getInitOptionsDataApi();
            }
        },
        // eslint-disable-next-line
        [open],
    );

    return (
        <Dialog open={open} PaperProps={{ className: 'wx-40rem', component: Form }} onSubmit={() => handleSubmitOnClick(sourceDataProps.suspendID)} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>
                {isEditProps ? `編輯` : `新增`}
                {`店舖店休`}
            </GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SelectField
                            label={'擔當'}
                            ref={employeeIDRef}
                            defaultValue={(sourceDataProps?.employeeID ?? null) || null}
                            displayEmpty
                            fullWidth
                            required
                        >
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {Array.isArray(options) &&
                                options
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>

                    <Grid item xs={12}>
                        <DateField label={'選擇店休日期'} ref={applyDateRef} defaultValue={parseDate(sourceDataProps.suspendStartDate)} fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack alignItems={'end'} justifyContent={'end'} spacing={2} direction={'row'}>
                            <TextField
                                ref={monthCountRef}
                                defaultValue={sourceDataProps.suspendMonth || 0}
                                type={'number'}
                                inputProps={{ min: 0 }}
                                // style={{ width: '80px' }}
                                fullWidth
                                disabled
                            />
                            <Typography component={'div'}>月</Typography>
                            <TextField
                                ref={dayCountRef}
                                defaultValue={sourceDataProps.suspendDay || 0}
                                type={'number'}
                                inputProps={{ min: 0 }}
                                // style={{ width: '80px' }}
                                fullWidth
                                required
                            />
                            <Typography component={'div'}>日</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={12} item>
                        <Box>※如果要請月份的店休請向督導詢問與確認</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField ref={reasonRef} defaultValue={sourceDataProps.reasonText || ''} label="原因" rows={6} multiline fullWidth required />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type="submit">
                    送出
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default ClubSuspensionCreateDialog;
