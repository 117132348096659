import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const ErrorNoOutline = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M6.875,16.875A3.125,3.125,0,1,1,3.75,13.75,3.129,3.129,0,0,1,6.875,16.875ZM.987.984l.531,10.625a.937.937,0,0,0,.936.891H5.046a.937.937,0,0,0,.936-.891L6.513.984A.937.937,0,0,0,5.577,0H1.923A.938.938,0,0,0,.987.984Z"
                transform="translate(8.375 2)"
            />
        </SvgIcon>
    );
});

export default ErrorNoOutline;
