import React, { useEffect, useState } from 'react';
import { PhysicalOrderTable } from './table';
import { useMemberProfileApi } from '../../../apis/useMemberProfileApi';

const MemberDetailOtherContentOrderRecord = (props) => {
    const { memberID } = props;
    const { getMemberProfileOrderApi } = useMemberProfileApi();
    const [data, setData] = useState([]);

    const doMemberProfileOrderApi = async (memberID) => {
        let res = await getMemberProfileOrderApi(memberID);
        if (res) {
            setData(res);
        }
    };

    useEffect(() => {
        if (memberID) {
            doMemberProfileOrderApi(memberID);
        }
        // eslint-disable-next-line
    }, [memberID]);

    return <PhysicalOrderTable className={'theme-gray'} rowsData={data} />;
};

export default MemberDetailOtherContentOrderRecord;
