import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const THundred = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#6E488B', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M19.8,10.8c-0.1,0.1-0.2,0.2-0.4,0.2
		c0,0-0.1,0-0.1,0l-1.7-0.5v8.2c0,0.2-0.2,0.4-0.4,0.4H6.9c-0.2,0-0.4-0.2-0.4-0.4v-8.2L4.7,11c0,0-0.1,0-0.1,0
		c-0.2,0-0.3-0.1-0.4-0.2L2.7,7.7c-0.1-0.1-0.1-0.2,0-0.3c0-0.1,0.1-0.2,0.2-0.2l3.6-1.7C7.2,5.2,8,5,8.8,4.9c0,0,0.1,0,0.1,0
		C9,4.9,9.2,5,9.3,5.2c0.2,1.1,1.1,1.9,2.1,2.1c1.5,0.3,3-0.6,3.4-2.1c0-0.2,0.2-0.3,0.4-0.3c0,0,0.1,0,0.1,0
		C16,5,16.8,5.2,17.6,5.5l3.6,1.7c0.1,0,0.2,0.1,0.2,0.2h0c0,0.1,0,0.2,0,0.3L19.8,10.8z"
            />
            <path d="M7.3,11.1v0.8c0.5-0.2,0.9-0.4,1.3-0.7V14h0.9V10H8.8C8.4,10.5,7.9,10.9,7.3,11.1z" />
            <path
                d="M11.7,9.9c-1,0.1-1.5,0.8-1.6,2.1c0,1.4,0.5,2,1.6,2.1c1.1,0,1.6-0.7,1.6-2.1C13.2,10.6,12.7,9.9,11.7,9.9z
		 M11.7,13.5C11.3,13.5,11,13,11,12c0-1,0.2-1.5,0.6-1.5c0.4,0,0.6,0.5,0.6,1.5C12.3,13,12.1,13.5,11.7,13.5z"
            />
            <path
                d="M15.1,9.9c-1,0.1-1.5,0.8-1.6,2.1c0,1.4,0.5,2,1.6,2.1c1.1,0,1.6-0.7,1.6-2.1C16.7,10.6,16.2,9.9,15.1,9.9z
		 M15.1,13.5c-0.4,0-0.6-0.5-0.6-1.4c0-1,0.2-1.5,0.6-1.5c0.4,0,0.6,0.5,0.6,1.5C15.7,13,15.6,13.5,15.1,13.5z"
            />
        </SvgIcon>
    );
});

export default THundred;
