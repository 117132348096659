import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogContent } from '@mui/material';
import { Card, Typography } from '@common/components';
import {
    ConsumerSectionByRead,
    InvoiceSectionByRead,
    OrderEffectStateSectionByRead,
    PaymentSectionByRead,
    ProductSectionByRead,
    QuitSectionByRead,
    ReceiverSectionByRead,
    ResuspensionSectionByRead,
    ShippingSectionByRead,
    SuspensionSectionByRead,
} from '@icoach/documents/cyclePurchase/sections';
import useCyclePurchaseEffectApi from '@apis/useCyclePurchaseEffectApi';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import { isEmpty } from '@util/utils';

const _effectTypeMap = new Map([
    [effectTypeMap.product, ProductSectionByRead],
    [effectTypeMap.shippingDay, ShippingSectionByRead],
    [effectTypeMap.receiver, ReceiverSectionByRead],
    [effectTypeMap.payment, PaymentSectionByRead],
    [effectTypeMap.invoice, InvoiceSectionByRead],
    [effectTypeMap.suspension, SuspensionSectionByRead],
    [effectTypeMap.quit, QuitSectionByRead],
    [effectTypeMap.resuspension, ResuspensionSectionByRead],
]);

const CycleOrderEffectDialogContent = (props) => {
    const { data } = props;
    const { effectType } = data;
    return (
        <DialogContent>
            <Card className={'order-info-card mb-4'}>
                <ConsumerSectionByRead data={data} />
                <OrderEffectStateSectionByRead data={data} />
            </Card>
            <Typography className={'font-weight-bold mb-1'} variant={'h5'}>
                變動的項目
            </Typography>
            <Card className={'order-info-card mb-4'}>
                {!isEmpty(effectType) &&
                    effectType.map((type) => {
                        let Component = _effectTypeMap.get(type);
                        console.log(Component);
                        console.log(type);
                        return <Component key={type} data={data} />;
                    })}
            </Card>
        </DialogContent>
    );
};

const CycleOrderEffectDialog = (props) => {
    const { className, open, cycleOrderID, effectID, onClose } = props;
    const { getCyclePurchaseEffectOrderApi } = useCyclePurchaseEffectApi();
    const [data, setData] = useState({});
    const doCyclePurchaseEffectOrderApi = async (cycleOrderID, effectID) => {
        let res = await getCyclePurchaseEffectOrderApi(cycleOrderID, effectID);
        if (res) {
            setData(res);
        }
    };

    useEffect(() => {
        if (open) {
            doCyclePurchaseEffectOrderApi(cycleOrderID, effectID);
        }
        // eslint-disable-next-line
    }, [open]);
    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-90rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>健康產品變更單</GradientColorHeader>
            <CycleOrderEffectDialogContent data={data} />
        </Dialog>
    );
};

export default CycleOrderEffectDialog;
