import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Locker = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#6E488B', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M17.3,5.5H6.7c-.6,0-1.1.5-1.1,1.1v9.2c0,.6.5,1.1,1.1,1.1h10.6c.6,0,1.1-.5,1.1-1.1V6.6c0-.6-.5-1.1-1.1-1.1ZM17.8,15.8c0,.3-.2.5-.5.5H6.7c-.3,0-.5-.2-.5-.5v-.2c0,0,.1,0,.2,0h.2c.2,0,.4-.2.4-.4v-2c0-.2-.2-.4-.4-.4h-.2c0,0-.1,0-.2,0v-2.9c0,0,.1,0,.2,0h.2c.2,0,.4-.2.4-.4v-2c0-.2-.2-.4-.4-.4h-.2c0,0-.1,0-.2,0v-.5c0-.3.2-.5.5-.5h10.6c.3,0,.5.2.5.5v9.2ZM15.8,11.5l.5,2.1c0,.2,0,.3-.2.3h-2.6c-.1,0-.3-.2-.2-.3l.5-2.1c-.4-.3-.7-.8-.7-1.4,0-.9.8-1.7,1.7-1.7s1.7.8,1.7,1.7-.3,1.1-.7,1.4ZM12,0C5.4,0,0,5.4,0,12s5.4,12,12,12,12-5.4,12-12S18.6,0,12,0ZM19.5,17c0,.5-.4.8-.8.8h-.4v1.1c0,.3-.1.5-.3.5h-1.2c0,0-.2,0-.2-.2l-.7-1.3s0,0,0-.1h-7.4s0,0,0,.1l-.7,1.3c0,.1-.1.2-.2.2h-1.2c-.2,0-.3-.2-.3-.5v-1.1h-.4c-.5,0-.8-.4-.8-.8V5.4c0-.5.4-.8.8-.8h13.2c.5,0,.8.4.8.8v11.6Z"></path>
        </SvgIcon>
    );
});

export default Locker;
