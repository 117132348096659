export const effectTypeMap = {
    product: 2 ** 0,
    shippingDay: 2 ** 1,
    receiver: 2 ** 2,
    payment: 2 ** 3,
    suspension: 2 ** 4,
    quit: 2 ** 5,
    resuspension: 2 ** 6,
    invoice: 2 ** 7,
};

export const cycleOrderTypeMap = {
    credit: 1,
    year: 2,
    suspension: 3,
    quit: 4,
};

export const COLOR_MATCH = {
    1: 'success',
    2: 'info',
    3: 'grey',
    4: 'warning',
    9: 'error',
};
