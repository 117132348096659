import React, { useImperativeHandle, useState } from 'react';
import useLanding from '@icoach/landingPage/components/useLanding';
import MemberSummaryTipDialog from '@icoach/landingPage/dialog/MemberSummaryTipDialog';
import AppointmentCoachDialog from '@icoach/landingPage/dialog/AppointmentCoachDialog';
import AppointmentContactDialog from '@icoach/appointment/dialog/AppointmentContactDialog';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';

// table 重複元件使用(單一來源彈跳視窗)，如果只有一個元件在使用就不用寫在這
const LandingDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState(null);
    const [sourceData, setSourceData] = useState(null);
    const [options, setOptions] = useState(null);
    const { setDialogRef } = useLanding();

    const handleCloseDialog = () => {
        setOpenDialog(null);
        setSourceData(null);
        setOptions(null);
    };

    useImperativeHandle(
        setDialogRef,
        () => {
            return {
                openSmsDialog: (targetData) => {
                    setSourceData(targetData);
                    setOpenDialog('sendSMS');
                },
                openMemberSummaryDialog: (targetData) => {
                    setSourceData(targetData);
                    setOpenDialog('memberSummary');
                },
                openAppointmentCoachDialog: (targetData, opts) => {
                    setSourceData(targetData);
                    setOptions(opts);
                    setOpenDialog('appointmentCoach');
                },
                openAppointmentContactDialog: (targetData) => {
                    setSourceData(targetData);
                    setOpenDialog('appointmentContact');
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <SendSmsDialog open={openDialog === 'sendSMS'} onClose={handleCloseDialog} sourceData={sourceData} isPeopleAppointment />
            <MemberSummaryTipDialog open={openDialog === 'memberSummary'} onClose={handleCloseDialog} id={sourceData} />
            <AppointmentCoachDialog open={openDialog === 'appointmentCoach'} onClose={handleCloseDialog} sourceData={sourceData} options={options || []} />
            <AppointmentContactDialog open={openDialog === 'appointmentContact'} onClose={handleCloseDialog} sourceData={sourceData} />
        </React.Fragment>
    );
});

export default LandingDialogBox;
