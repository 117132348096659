import React, { useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, IconButton, ListItem, TextField } from '../../../../components';
import { ErrorCircle as ErrorCircleIcon, Plus as PlusIcon } from '../../../../components/icons/SvgIcon/';
import GradientColorHeader from '../../../components/dialog/GradientColorHeader';
import { FieldRow } from '../../../documents/components/Layout';
import { useMemberProfileApi } from '../../../../apis/useMemberProfileApi';
import { refIsRequiredError } from '../../../../util/utils';

export const NotesItemDialogContent = React.forwardRef((props, ref) => {
    const { title, defaultValue } = props;
    const [fields, setFields] = useState(() => {
        if (Array.isArray(defaultValue)) {
            return defaultValue.map((text) => ({
                id: uuid(),
                value: text,
            }));
        }
        return [];
    });
    const fieldsRef = useRef([]);

    const handleAddNoteItem = () => {
        if (fields.length >= 5) return null;
        setFields((old) => {
            let arr = [...old];
            arr.push({
                id: uuid(),
                value: '',
            });
            return arr;
        });
    };
    const handleDeleteNoteItem = (id) => {
        setFields((old) => {
            return old.filter((item) => item.id !== id);
        });
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return fieldsRef.current.filter((item) => item).map((item) => item.getResult());
            },
            isError: () => refIsRequiredError(fieldsRef.current),
        }),
        // eslint-disable-next-line
        []
    );
    return (
        <DialogContent>
            <div className="text-right mb-4">
                <Button startIcon={<PlusIcon />} onClick={handleAddNoteItem}>
                    新增事項
                </Button>
            </div>
            <Grid container spacing={3}>
                {fields.map(({ value, id }, i) => (
                    <FieldRow key={id} items={[3, 9]} label={`${title}-${i + 1}`}>
                        <ListItem className="p-0" required>
                            <TextField ref={(el) => (fieldsRef.current[i] = el)} InputProps={{ placeholder: '請輸入' }} defaultValue={value} fullWidth />
                            <IconButton onClick={() => handleDeleteNoteItem(id)}>
                                <ErrorCircleIcon />
                            </IconButton>
                        </ListItem>
                    </FieldRow>
                ))}
            </Grid>
        </DialogContent>
    );
});

const AgreedNotesDialog = (props) => {
    const { className, open, memberID, data, onClose, refresh } = props;
    const { postMemberProfileNotesApi } = useMemberProfileApi();
    const contentRef = useRef();

    const doMemberProfileNotesApi = async (memberID, params) => {
        let res = await postMemberProfileNotesApi(memberID, params);
        if (res) {
            refresh && refresh(memberID);
            onClose();
        }
    };

    const getParams = () => {
        return {
            noteType: 2,
            noteItems: contentRef.current && contentRef.current.getResult(),
        };
    };
    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doMemberProfileNotesApi(memberID, params);
        }
    };
    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>編輯約定事項</GradientColorHeader>
            <NotesItemDialogContent ref={contentRef} defaultValue={data} title={'約定事項'} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AgreedNotesDialog;
