import React, { useEffect, useState } from 'react';
import { GiftClaimTable } from './table';
import useMemberGiftItemApi from '@apis/useMemberGiftItemApi';

const MemberDetailOtherContentGiftClaimRecord = (props) => {
    const { memberID } = props;
    const { getMemberGiftItemApi } = useMemberGiftItemApi();
    const [giftRecord, setGiftRecord] = useState([]);

    const doMemberGiftItemApi = async (memberID) => {
        const resp = await getMemberGiftItemApi(memberID);
        if (resp) {
            setGiftRecord(resp);
        }
    };

    useEffect(() => {
        if (memberID) {
            doMemberGiftItemApi(memberID);
        }
        // eslint-disable-next-line
    }, [memberID]);

    return <GiftClaimTable className={'theme-gray'} rowsData={giftRecord} />;
};

export default MemberDetailOtherContentGiftClaimRecord;
