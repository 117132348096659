import React, { createContext, useContext } from 'react';

const MemberDetailContext = createContext(undefined);

export const MemberDetailProvider = ({ children, value }) => {
    return <MemberDetailContext.Provider value={value}>{children}</MemberDetailContext.Provider>;
};

export const useMemberDetailContext = () => {
    const context = useContext(MemberDetailContext);
    if (context === undefined) {
        throw new Error('useMemberDetailContext 必須在 MemberDetailProvider 中使用');
    }
    return context;
};
