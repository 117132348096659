import React, { useRef } from 'react';
import Tablenization from '../../components/Tablenization';
import { Chip } from '../../../components';
import { allowanceStatusMap, dialogType, orderStatusColorMap } from '../staticData';
import { displayDateText } from '../../../util/moment';
import { MoreLabelBox } from '../../components';
import useToggleDialog from '../../../util/hook/useToggleDialog';
import { OrderAllowanceAbandonDialog } from '../dialog';
import { formatCurrencyFn } from '../../../util/utils';

const MoreLabel = (props) => {
    const { allowanceID, items, handleOpenDialog } = props;

    const moreLabelMap = useRef({
        allowanceAbandon: {
            text: '作廢折讓',
            onClickEvent: () =>
                handleOpenDialog(dialogType.allowanceAbandon, { allowanceID, items }),
        },
    });

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap.current} textAlign={'center'} />;
};

export const rowsLabel = [
    {
        cellKey: 'allowanceDate',
        headerLabel: '折讓日期',
        width: 13,
        formatCell: (cellValue) => displayDateText(cellValue, null, { format: 'YYYY/MM/DD HH:mm' }),
    },
    {
        cellKey: 'allowanceStatusText',
        headerLabel: '折讓狀態',
        align: 'center',
        formatCell: (cellValue, { allowanceStatus }) => {
            return <Chip label={cellValue} color={orderStatusColorMap.get(allowanceStatus)} />;
        },
    },
    {
        cellKey: 'allowanceAmount',
        headerLabel: '折讓金額',
        align: 'center',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票編號',
    },
    {
        cellKey: 'invoiceDate',
        headerLabel: '發票開立時間',
        formatCell: (cellValue) => displayDateText(cellValue, null, { format: 'YYYY/MM/DD HH:mm' }),
    },
    {
        cellKey: 'more',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (cellValue, rowData, { handleOpenDialog }) => {
            const { allowanceStatus } = rowData;
            if (allowanceStatus === allowanceStatusMap.abandon) return null;
            return (
                <MoreLabel
                    {...rowData}
                    moreActionArray={['allowanceAbandon']}
                    handleOpenDialog={handleOpenDialog}
                />
            );
        },
    },
];

const OrderAllowanceRecordTable = (props) => {
    const { className, rowsData = [], refresh } = props;
    const {
        openTarget,
        handleOpenDialog,
        handleCloseDialog,
        allowanceID,
        items,
    } = useToggleDialog();
    const labelTitle = useRef(rowsLabel);
    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={rowsData}
                    handleOpenDialog={handleOpenDialog}
                />
            </Tablenization>
            <OrderAllowanceAbandonDialog
                open={openTarget === dialogType.allowanceAbandon}
                allowanceID={allowanceID}
                items={items}
                onClose={handleCloseDialog}
                refresh={refresh}
            />
        </React.Fragment>
    );
};

export default OrderAllowanceRecordTable;
