import React, { useImperativeHandle, useRef } from 'react';
import moment from 'moment';
import { Calendar, Stack, IconButton, FormControl, FormHelperText } from '../';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '../icons/SvgIcon/';
import { parseDate } from '../../util/moment';

const CalendarGrid = React.forwardRef((props, ref) => {
    const {
        calendarSize = 2,
        minDate = null,
        maxDate = null,
        onDayClick: onDayClickProps = () => {},
        ...other
    } = props;
    const calendarRef = useRef([]);
    const [current, setCurrent] = React.useState([]);

    const getCalendarMonths = () => {
        let months = [];
        for (let i = 0; i < calendarSize; i++) {
            months.push(moment(current).subtract(i, 'month'));
        }
        return months.reverse();
    };

    const handleChangeMonth = (unit) => {
        var _current = moment(current).add(unit, 'month');
        setCurrent(_current);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let dates = [];
                for (let i = 0; i < calendarSize; i++) {
                    dates.push(...calendarRef.current[i].getResult());
                }
                return dates;
            },
        }),
        // eslint-disable-next-line
        []
    );

    let calendars = getCalendarMonths();
    return (
        <Stack>
            <Stack direction="column">
                <div style={{ position: 'relative' }}>
                    <IconButton
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        onClick={() => handleChangeMonth(-1)}
                    >
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton
                        style={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={() => handleChangeMonth(1)}
                    >
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </div>
                <Stack spacing={1}>
                    {calendars.map((calendar, i) => {
                        return (
                            <Calendar
                                key={i}
                                ref={(el) => (calendarRef.current[i] = el)}
                                year={calendar.year()}
                                month={calendar.month()}
                                onDayClick={onDayClickProps}
                                minDate={minDate}
                                maxDate={maxDate}
                                {...other}
                            />
                        );
                    })}
                </Stack>
            </Stack>
        </Stack>
    );
});

const StaticDateRangeCalendar = React.forwardRef((props, ref) => {
    const {
        format: formatProps = 'YYYY-MM-DD',
        minDate,
        maxDate,
        direction: directionProps = 'column',
    } = props;
    const [isError, setError] = React.useState(false);
    // const [startDateTime, setStartDateTime] = React.useState('');
    // const [endDateTime, setEndDateTime] = React.useState('');
    const calendarGridRef = useRef(null);
    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const dates = calendarGridRef.current.getResult();
                return dates.map((target) => parseDate(target, formatProps));
            },
            isError: () => {
                let error = true;
                const dates = calendarGridRef.current.getResult();
                if (dates.length > 0) error = false;
                setError(error);
                return error;
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <FormControl direction={directionProps} error={isError}>
            {isError && <FormHelperText>{'請勾選起始與結束日期'}</FormHelperText>}
            <CalendarGrid
                ref={calendarGridRef}
                minDate={minDate ? moment(minDate) : null}
                maxDate={maxDate ? moment(maxDate) : null}
                isChecked={true}
                hasHover={true}
                multiChoices={true}
            />
        </FormControl>
    );
});

export default StaticDateRangeCalendar;
