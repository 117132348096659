import React, { useEffect, useRef } from 'react';

const Spinner = () => {
    const spinnerRef = useRef(null);

    useEffect(
        () => {
            const parentElement = spinnerRef.current?.parentElement;

            if (parentElement) {
                const parentStyle = window.getComputedStyle(parentElement);

                // 如果父容器的 position 是 static，則將其設為 relative
                if (parentStyle.position === 'static') {
                    parentElement.style.position = 'relative';
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div className={'spinner-container'}>
            <div className={'spinner'}></div>
        </div>
    );
};

export default Spinner;
