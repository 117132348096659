import React from 'react';
import clsx from 'clsx';
const staticClass = `form-group`;

const FormGroup = React.forwardRef((props, ref) => {
    const {
        className,
        row = false,
        gap = false,
        origin = '',
        children,
        color: propsColor,
        onChange: propsOcChange,
        disabled = false,
        ...other
    } = props;
    let classNames = `${staticClass}-root`;

    if (row) classNames = clsx(classNames, `${staticClass}-flex-direction-row`);
    if (gap) classNames = clsx(classNames, `${staticClass}-gap`);
    if (origin === 'end') classNames = clsx(classNames, `${staticClass}-flex-origin-end`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    return (
        <div {...defaultProperty}>
            {React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) return null;
                const propsProperty = {
                    color: child.props.color || propsColor,
                    onChange: child.props.onChange || propsOcChange,
                    disabled: child.props.disabled || disabled,
                };
                return React.cloneElement(child, propsProperty);
            })}
        </div>
    );
});

export default FormGroup;
