import React, { useRef } from 'react';
import { Dialog, DialogContent, Slide } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import GuestProductionForm from '@icoach/nonMember/guestProduction/GuestProductionForm';
import { refIsRequiredError } from '@util/utils';
import useCustomerManufactureApi from '@apis/useCustomerManufactureApi';

export const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GuestProductionAddDialog = (props) => {
    const { onClose: onCloseProps, open, defaultData = {}, options = [], refresh } = props;
    const { postCustomerManufactureApi } = useCustomerManufactureApi();
    const formRef = useRef(null);

    const handleSendClick = (e) => {
        if (!refIsRequiredError(formRef)) {
            const params = formRef.current.getResult();
            postAddManufactureApi(params);
        }
    };

    const postAddManufactureApi = async (params) => {
        const resp = await postCustomerManufactureApi(params);
        if (resp) {
            onCloseProps(null);
            refresh && refresh.current && refresh.current();
        }
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} fullScreen>
            <GradientColorHeader onClose={() => onCloseProps(null)}>新增主動顧客製造</GradientColorHeader>
            <DialogContent className="my-3">
                <GuestProductionForm ref={formRef} onClick={handleSendClick} options={options} sourceData={defaultData} isAddMode />
            </DialogContent>
        </Dialog>
    );
};

export default GuestProductionAddDialog;
