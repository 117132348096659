import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';


const BellRegular = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="m23.72,17.67l-.8-.87c-1.52-1.66-2.35-3.73-2.35-5.86v-1.19c0-3.63-2.95-6.66-6.86-7.35v-.9c0-.83-.77-1.5-1.71-1.5s-1.71.67-1.71,1.5v.9c-3.91.69-6.86,3.72-6.86,7.35v1.19c0,2.13-.83,4.2-2.35,5.85l-.8.88c-.31.34-.37.8-.16,1.19.21.39.66.64,1.16.64h21.43c.49,0,.94-.25,1.16-.64.21-.39.16-.85-.16-1.19Zm-19.84-.42c1.38-1.88,2.13-4.06,2.13-6.31v-1.19c0-2.9,2.68-5.25,6-5.25s6,2.35,6,5.25v1.19c0,2.25.74,4.43,2.13,6.31H3.87Zm8.13,3.75h3.43c0,.8-.36,1.56-1,2.12s-1.52.88-2.43.88-1.78-.31-2.43-.88-1-1.33-1-2.12h3.43Z" />
        </SvgIcon>
    );
});

export default BellRegular;
