import React from 'react';
import clsx from 'clsx';
import useEventCallback from '../../util/hook/useEventCallback';
import animate from '../../util/animate';
import { duration } from '../../util/createTransitions';
import { debounce, ownerWindow, ownerDocument } from '../../util/utils';
import {
    detectScrollType,
    getNormalizedScrollLeft,
} from '../../util/scrollLeft';
import ScrollbarSize from './ScrollbarSize';
import TabScrollButton from './TabScrollButton';

const nextItem = (list, item) => {
    if (list === item) return list.firstChild;
    if (item && item.nextElementSibling) return item.nextElementSibling;
    return list.firstChild;
};

const previousItem = (list, item) => {
    if (list === item) return list.lastChild;
    if (item && item.previousElementSibling) return item.previousElementSibling;
    return list.lastChild;
};

const moveFocus = (list, currentFocus, traversalFunction) => {
    let wrappedOnce = false;
    let nextFocus = traversalFunction(list, currentFocus);

    while (nextFocus) {
        // Prevent infinite loop.
        if (nextFocus === list.firstChild) {
            if (wrappedOnce) return;
            wrappedOnce = true;
        }
        // Same logic as useAutocomplete.js
        const nextFocusDisabled =
            nextFocus.disabled ||
            nextFocus.getAttribute('aria-disabled') === 'true';

        if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
            // Move to the next element.
            nextFocus = traversalFunction(list, nextFocus);
        } else {
            nextFocus.focus();
            return;
        }
    }
};

const TabsRoot = React.forwardRef((props, ref) => {
    const {
        as: Component,
        scrollButtonsHideMobile,
        vertical,
        children,
        className,
    } = props;
    const staticClass = 'tabs-root';
    let classNames = staticClass;
    if (scrollButtonsHideMobile)
        classNames = clsx(classNames, 'scroll-buttons-hide-mobile');
    if (vertical) classNames = clsx(classNames, `${staticClass}-vertical`);
    return (
        <Component ref={ref} className={clsx(classNames, className)}>
            {children}
        </Component>
    );
});

const TabsScroller = React.forwardRef((props, ref) => {
    const {
        style,
        fixed,
        hideScrollbar,
        scrollableX,
        scrollableY,
        children,
    } = props;
    const staticClass = 'tabs-scroller';
    let classNames = staticClass;
    if (fixed) classNames = clsx(classNames, `${staticClass}-fixed`);
    if (hideScrollbar)
        classNames = clsx(classNames, `${staticClass}-hide-scrollbar`);
    if (scrollableX)
        classNames = clsx(classNames, `${staticClass}-scrollable-x`);
    if (scrollableY)
        classNames = clsx(classNames, `${staticClass}-scrollable-y`);

    return (
        <div style={style} className={classNames} ref={ref}>
            {children}
        </div>
    );
});

const FlexContainer = React.forwardRef((props, ref) => {
    const { fixed, vertical, centered, children } = props;
    const staticClass = 'tabs-flex-container';
    let classNames = staticClass;
    if (fixed) classNames = clsx(classNames, `${staticClass}-fixed`);
    // if (scrollableX) classNames = clsx(classNames, `${staticClass}-scrollable-x`);
    // if (scrollableY) classNames = clsx(classNames, `${staticClass}-scrollable-y`);
    if (vertical) classNames = clsx(classNames, `${staticClass}-vertical`);
    if (centered) classNames = clsx(classNames, `${staticClass}-centered`);
    return (
        <div ref={ref} className={classNames}>
            {children}
        </div>
    );
});

const TabsIndicator = (props) => {
    const { color, style, vertical } = props;
    const colorAry = ['primary', 'secondary'];
    const staticClass = `tabs-indicator`;
    let classNames = staticClass;
    if (colorAry.indexOf(color) !== -1)
        classNames = clsx(classNames, `${staticClass}-color-${color}`);
    if (vertical) classNames = clsx(classNames, `${staticClass}-vertical`);

    return <span className={classNames} style={style} />;
};

const TabsScrollbarSize = (props) => {
    const { onChange } = props;
    let classNames = `tabs-scrollbar-size`;
    return <ScrollbarSize className={classNames} onChange={onChange} />;
};

const defaultIndicatorStyle = {};
// let warnedOnceTabPresent = false;

const Tabs = React.forwardRef((props, ref) => {
    const {
        action,
        centered = false,
        children: childrenProp,
        className,
        component = 'div',
        allowScrollButtonsMobile = false,
        indicatorColor = 'primary',
        onChange,
        orientation = 'horizontal',
        ScrollButtonComponent = TabScrollButton,
        scrollButtons = 'auto',
        selectionFollowsFocus,
        TabIndicatorProps = {},
        TabScrollButtonProps = {},
        textColor = 'primary',
        value,
        variant = 'standard',
        visibleScrollbar = false,
        isRtl = false,
        // ...other
    } = props;

    const scrollable = variant === 'scrollable';
    const vertical = orientation === 'vertical';

    const scrollStart = vertical ? 'scrollTop' : 'scrollLeft';
    const start = vertical ? 'top' : 'left';
    const end = vertical ? 'bottom' : 'right';
    const clientSize = vertical ? 'clientHeight' : 'clientWidth';
    const size = vertical ? 'height' : 'width';

    // const defaultProperty = { ownerState
    //     ...props,
    //     component,
    //     allowScrollButtonsMobile,
    //     indicatorColor,
    //     orientation,
    //     vertical,
    //     scrollButtons,
    //     textColor,
    //     variant,
    //     visibleScrollbar,
    //     fixed: !scrollable,
    //     hideScrollbar: scrollable && !visibleScrollbar,
    //     scrollableX: scrollable && !vertical,
    //     scrollableY: scrollable && vertical,
    //     centered: centered && !scrollable,
    //     scrollButtonsHideMobile: !allowScrollButtonsMobile,
    // };

    const [mounted, setMounted] = React.useState(false);
    const [indicatorStyle, setIndicatorStyle] = React.useState(
        defaultIndicatorStyle
    );
    const [displayScroll, setDisplayScroll] = React.useState({
        start: false,
        end: false,
    });

    const [scrollerStyle, setScrollerStyle] = React.useState({
        overflow: 'hidden',
        scrollbarWidth: 0,
    });

    const valueToIndex = new Map();
    const tabsRef = React.useRef(null);
    const tabListRef = React.useRef(null);

    const getTabsMeta = () => {
        const tabsNode = tabsRef.current;
        let tabsMeta;
        if (tabsNode) {
            const rect = tabsNode.getBoundingClientRect();
            // create a new object with ClientRect class props + scrollLeft
            tabsMeta = {
                clientWidth: tabsNode.clientWidth,
                scrollLeft: tabsNode.scrollLeft,
                scrollTop: tabsNode.scrollTop,
                scrollLeftNormalized: getNormalizedScrollLeft(tabsNode, 'rtl'),
                scrollWidth: tabsNode.scrollWidth,
                top: rect.top,
                bottom: rect.bottom,
                left: rect.left,
                right: rect.right,
            };
        }
        let tabMeta;
        if (tabsNode && value !== false) {
            const children = tabListRef.current.children;
            if (children.length > 0) {
                const tab = children[valueToIndex.get(value)];
                tabMeta = tab ? tab.getBoundingClientRect() : null;
            }
        }
        return { tabsMeta, tabMeta };
    };

    const updateIndicatorState = useEventCallback(() => {
        const { tabsMeta, tabMeta } = getTabsMeta();
        let startValue = 0;
        let startIndicator;

        if (vertical) {
            startIndicator = 'top';
            if (tabMeta && tabsMeta) {
                startValue = tabMeta.top - tabsMeta.top + tabsMeta.scrollTop;
            }
        } else {
            startIndicator = isRtl ? 'right' : 'left';
            if (tabMeta && tabsMeta) {
                const correction = isRtl
                    ? tabsMeta.scrollLeftNormalized +
                      tabsMeta.clientWidth -
                      tabsMeta.scrollWidth
                    : tabsMeta.scrollLeft;
                startValue =
                    (isRtl ? -1 : 1) *
                    (tabMeta[startIndicator] -
                        tabsMeta[startIndicator] +
                        correction);
            }
        }

        const newIndicatorStyle = {
            [startIndicator]: startValue,
            // May be wrong until the font is loaded.
            [size]: tabMeta ? tabMeta[size] : 0,
        };

        // IE11 support, replace with Number.isNaN
        // eslint-disable-next-line no-restricted-globals
        if (
            isNaN(indicatorStyle[startIndicator]) ||
            isNaN(indicatorStyle[size])
        ) {
            setIndicatorStyle(newIndicatorStyle);
        } else {
            const dStart = Math.abs(
                indicatorStyle[startIndicator] -
                    newIndicatorStyle[startIndicator]
            );
            const dSize = Math.abs(
                indicatorStyle[size] - newIndicatorStyle[size]
            );

            if (dStart >= 1 || dSize >= 1) setIndicatorStyle(newIndicatorStyle);
        }
    });

    const scroll = (scrollValue, { animation = true } = {}) => {
        if (animation) {
            animate(scrollStart, tabsRef.current, scrollValue, {
                duration: duration.standard,
            });
        } else {
            tabsRef.current[scrollStart] = scrollValue;
        }
    };

    const moveTabsScroll = (delta) => {
        let scrollValue = tabsRef.current[scrollStart];

        if (vertical) {
            scrollValue += delta;
        } else {
            scrollValue += delta * (isRtl ? -1 : 1);
            // Fix for Edge
            scrollValue *= isRtl && detectScrollType() === 'reverse' ? -1 : 1;
        }

        scroll(scrollValue);
    };

    const getScrollSize = () => {
        const containerSize = tabsRef.current[clientSize];
        let totalSize = 0;
        const children = Array.from(tabListRef.current.children);

        for (let i = 0; i < children.length; i += 1) {
            const tab = children[i];
            if (totalSize + tab[clientSize] > containerSize) {
                break;
            }
            totalSize += tab[clientSize];
        }
        return totalSize;
    };

    const handleStartScrollClick = () => {
        moveTabsScroll(-1 * getScrollSize());
    };

    const handleEndScrollClick = () => {
        moveTabsScroll(getScrollSize());
    };

    // TODO Remove <ScrollbarSize /> as browser support for hidding the scrollbar
    // with CSS improves.
    const handleScrollbarSizeChange = React.useCallback((scrollbarWidth) => {
        setScrollerStyle({
            overflow: null,
            scrollbarWidth,
        });
    }, []);

    // 方向鍵頭
    const getConditionalElements = () => {
        const conditionalElements = {};
        conditionalElements.scrollbarSizeListener = scrollable ? (
            <TabsScrollbarSize
                onChange={handleScrollbarSizeChange}
                // className={clsx(classes.scrollableX, ".hide-scrollbar")}
            />
        ) : null;

        const scrollButtonsActive = displayScroll.start || displayScroll.end;
        const showScrollButtons =
            scrollable &&
            ((scrollButtons === 'auto' && scrollButtonsActive) ||
                scrollButtons === true);

        conditionalElements.scrollButtonStart = showScrollButtons ? (
            <ScrollButtonComponent
                orientation={orientation}
                direction={isRtl ? 'right' : 'left'}
                onClick={handleStartScrollClick}
                disabled={!displayScroll.start}
                {...TabScrollButtonProps}
            />
        ) : null;

        conditionalElements.scrollButtonEnd = showScrollButtons ? (
            <ScrollButtonComponent
                orientation={orientation}
                direction={isRtl ? 'left' : 'right'}
                onClick={handleEndScrollClick}
                disabled={!displayScroll.end}
                {...TabScrollButtonProps}
            />
        ) : null;

        return conditionalElements;
    };

    const scrollSelectedIntoView = useEventCallback((animation) => {
        const { tabsMeta, tabMeta } = getTabsMeta();
        if (!tabMeta || !tabsMeta) return;
        if (tabMeta[start] < tabsMeta[start]) {
            // left side of button is out of view
            const nextScrollStart =
                tabsMeta[scrollStart] + (tabMeta[start] - tabsMeta[start]);
            scroll(nextScrollStart, { animation });
        } else if (tabMeta[end] > tabsMeta[end]) {
            // right side of button is out of view
            const nextScrollStart =
                tabsMeta[scrollStart] + (tabMeta[end] - tabsMeta[end]);
            scroll(nextScrollStart, { animation });
        }
    });

    const updateScrollButtonState = useEventCallback(() => {
        if (scrollable && scrollButtons !== false) {
            const {
                scrollTop,
                scrollHeight,
                clientHeight,
                scrollWidth,
                clientWidth,
            } = tabsRef.current;
            let showStartScroll;
            let showEndScroll;

            if (vertical) {
                showStartScroll = scrollTop > 1;
                showEndScroll = scrollTop < scrollHeight - clientHeight - 1;
            } else {
                const scrollLeft = getNormalizedScrollLeft(
                    tabsRef.current,
                    'rtl'
                );
                // use 1 for the potential rounding error with browser zooms.
                showStartScroll = isRtl
                    ? scrollLeft < scrollWidth - clientWidth - 1
                    : scrollLeft > 1;
                showEndScroll = !isRtl
                    ? scrollLeft < scrollWidth - clientWidth - 1
                    : scrollLeft > 1;
            }

            if (
                showStartScroll !== displayScroll.start ||
                showEndScroll !== displayScroll.end
            ) {
                setDisplayScroll({
                    start: showStartScroll,
                    end: showEndScroll,
                });
            }
        }
    });

    React.useEffect(() => {
        const handleResize = debounce(() => {
            updateIndicatorState();
            updateScrollButtonState();
        });
        const win = ownerWindow(tabsRef.current);
        win.addEventListener('resize', handleResize);

        let resizeObserver;

        if (typeof ResizeObserver !== 'undefined') {
            resizeObserver = new ResizeObserver(handleResize);
            Array.from(tabListRef.current.children).forEach((child) => {
                resizeObserver.observe(child);
            });
        }

        return () => {
            handleResize.clear();
            win.removeEventListener('resize', handleResize);
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [updateIndicatorState, updateScrollButtonState]);

    const handleTabsScroll = React.useMemo(
        () =>
            debounce(() => {
                updateScrollButtonState();
            }),
        [updateScrollButtonState]
    );

    React.useEffect(() => {
        return () => {
            handleTabsScroll.clear();
        };
    }, [handleTabsScroll]);

    React.useEffect(() => {
        setMounted(true);
    }, []);

    React.useEffect(() => {
        updateIndicatorState();
        updateScrollButtonState();
    });

    React.useEffect(() => {
        // Don't animate on the first render.
        scrollSelectedIntoView(defaultIndicatorStyle !== indicatorStyle);
    }, [scrollSelectedIntoView, indicatorStyle]);

    React.useImperativeHandle(
        action,
        () => ({
            updateIndicator: updateIndicatorState,
            updateScrollButtons: updateScrollButtonState,
        }),
        [updateIndicatorState, updateScrollButtonState]
    );

    // 文字底部的線
    const indicator = (
        <TabsIndicator
            color={indicatorColor}
            vertical={vertical}
            style={{
                ...indicatorStyle,
                ...(TabIndicatorProps?.style ?? {}),
            }}
        />
    );

    let childIndex = 0;
    const children = React.Children.map(childrenProp, (child) => {
        if (!React.isValidElement(child)) return null;
        const childValue =
            child.props.value === undefined ? childIndex : child.props.value;
        valueToIndex.set(childValue, childIndex);
        const selected = childValue === value;
        childIndex += 1;
        return React.cloneElement(child, {
            fullWidth: variant === 'fullWidth',
            indicator: selected && !mounted && indicator,
            selected,
            selectionFollowsFocus,
            onChange,
            textColor,
            value: childValue,
            ...(childIndex === 1 && value === false && !child.props.tabIndex
                ? { tabIndex: 0 }
                : {}),
        });
    });

    const handleKeyDown = (event) => {
        const list = tabListRef.current;
        const currentFocus = ownerDocument(list).activeElement;
        // Keyboard navigation assumes that [role="tab"] are siblings
        // though we might warn in the future about nested, interactive elements
        // as a a11y violation
        const role = currentFocus.getAttribute('role');
        if (role !== 'tab') return;

        let previousItemKey =
            orientation === 'horizontal' ? 'ArrowLeft' : 'ArrowUp';
        let nextItemKey =
            orientation === 'horizontal' ? 'ArrowRight' : 'ArrowDown';
        if (orientation === 'horizontal' && isRtl) {
            // swap previousItemKey with nextItemKey
            previousItemKey = 'ArrowRight';
            nextItemKey = 'ArrowLeft';
        }

        switch (event.key) {
            case previousItemKey:
                event.preventDefault();
                moveFocus(list, currentFocus, previousItem);
                break;
            case nextItemKey:
                event.preventDefault();
                moveFocus(list, currentFocus, nextItem);
                break;
            case 'Home':
                event.preventDefault();
                moveFocus(list, null, nextItem);
                break;
            case 'End':
                event.preventDefault();
                moveFocus(list, null, previousItem);
                break;
            default:
                break;
        }
    };

    const conditionalElements = getConditionalElements();

    return (
        <TabsRoot
            scrollButtonsHideMobile={!allowScrollButtonsMobile}
            vertical={vertical}
            as={component}
            ref={ref}
            className={className}
        >
            {conditionalElements.scrollButtonStart}
            {conditionalElements.scrollbarSizeListener}
            <TabsScroller
                fixed={!scrollable}
                hideScrollbar={scrollable && !visibleScrollbar}
                scrollableX={scrollable && !vertical}
                scrollableY={scrollable && vertical}
                style={{
                    overflow: scrollerStyle.overflow,
                    [vertical
                        ? `margin${isRtl ? 'Left' : 'Right'}`
                        : 'marginBottom']: visibleScrollbar
                        ? undefined
                        : -scrollerStyle.scrollbarWidth,
                }}
                ref={tabsRef}
                onScroll={handleTabsScroll}
            >
                <FlexContainer
                    fixed={!scrollable}
                    // scrollableX={scrollable && !vertical}
                    // scrollableY={scrollable && vertical}
                    vertical={vertical}
                    centered={centered}
                    ref={tabListRef}
                    role="tablist"
                    onKeyDown={handleKeyDown}
                >
                    {children}
                </FlexContainer>
                {mounted && indicator}
            </TabsScroller>
            {conditionalElements.scrollButtonEnd}
        </TabsRoot>
    );
});

export default Tabs;
