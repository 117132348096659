import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const staticClass = `svg-icon`;
const fontSizeMap = ['small', 'medium', 'large'];
const colorMap = ['primary', 'secondary', 'success', 'error', 'warning', 'info', 'gray'];

const SvgIcon = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        viewBox = '0 0 24 24',
        fontSize = 'medium',
        color,
        disabled,
        htmlColor,
        titleAccess = '',
        style = {},
        ...others
    } = props;

    let classNames = `${staticClass}-root`;
    let styles = {};
    if (colorMap.indexOf(color) === -1) Object.assign(styles, { color: htmlColor });
    if (fontSizeMap.indexOf(fontSize) !== -1)
        classNames = clsx(classNames, `${staticClass}-${fontSize}`);
    if (colorMap.indexOf(color) !== -1 && !disabled)
        classNames = clsx(classNames, `${staticClass}-color-${color}`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);

    const defaultProperty = {
        className: clsx(classNames, className),
        viewBox,
        ref,
        style: Object.assign(styles, style),
        'aria-hidden': titleAccess ? undefined : true,
        role: titleAccess ? 'img' : undefined,
        ...others,
    };

    return <svg {...defaultProperty}>{children}</svg>;
});

SvgIcon.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * Node passed into the SVG element.
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     * You can use the `htmlColor` prop to apply a color attribute to the SVG element.
     * @default 'inherit'
     */
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'disabled',
            'primary',
            'secondary',
            'error',
            'info',
            'success',
            'warning',
        ]),
        PropTypes.string,
    ]),
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * The fontSize applied to the icon. Defaults to 24px, but can be configure to inherit font size.
     * @default 'medium'
     */
    fontSize: PropTypes.oneOfType([
        PropTypes.oneOf(['large', 'medium', 'small']),
        PropTypes.string,
    ]),
    /**
     * Applies a color attribute to the SVG element.
     */
    htmlColor: PropTypes.string,
    /**
     * The shape-rendering attribute. The behavior of the different options is described on the
     * [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/shape-rendering).
     * If you are having issues with blurry icons you should investigate this prop.
     */
    shapeRendering: PropTypes.string,
    /**
     * Provides a human-readable title for the element that contains it.
     * https://www.w3.org/TR/SVG-access/#Equivalent
     */
    titleAccess: PropTypes.string,
    /**
     * Allows you to redefine what the coordinates without units mean inside an SVG element.
     * For example, if the SVG element is 500 (width) by 200 (height),
     * and you pass viewBox="0 0 50 20",
     * this means that the coordinates inside the SVG will go from the top left corner (0,0)
     * to bottom right (50,20) and each unit will be worth 10px.
     * @default '0 0 24 24'
     */
    viewBox: PropTypes.string,

    /**
     * Applies a disabled params to the color.
     */
    disabled: PropTypes.bool,
};

SvgIcon.curName = 'SvgIcon';

export default SvgIcon;
