import React, { useState, useEffect, useRef, useCallback, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Grid, Box } from '@mui/material';
import { FormLabel, Select, OutlinedInput, MenuItem, FormHelperText } from '../../../../components';

// 資料來源
import { default as addressData } from './addressDataTw';

const AddressField = React.forwardRef((props, ref) => {
    const {
        className,
        label,
        postCode: postCodeProps = '',
        address: addressProps = '',
        readOnly,
        addressPlaceholder = '請輸入',
        required: requiredProps = false,
        isError: errorProps = false,
        helperText: helperTextProps = '此選項為必填，不得為空值',
        disabled: disabledProps = false,
        tips,
        containerSpacing = 4,
    } = props;
    const { counties: cityOptions, districts: districtOptions, zipCodeObj } = addressData;
    const [isError, setError] = useState(errorProps);
    const [helperText, setHelperText] = useState(helperTextProps);
    const [required, setRequired] = useState(requiredProps);
    const [data, setData] = useState({
        postCode: '',
        cityIndex: '',
        districtIndex: '',
        others: '',
        districtOptions: [],
        postCodeOptions: [],
    });
    const postCodeRef = useRef();
    const cityRef = useRef();
    const districtRef = useRef();
    const otherRef = useRef();

    // 解析 郵遞區號 postCode 縣市 city 鄉鎮市區 district 其他地址資訊 others
    const parseByAddress = (addressString) => {
        const group = {
            city: '',
            postCode: '',
            district: '',
            others: '',
        };
        const reg = /(?<postCode>(^\d{5}|^\d{3})?)(?<city>(\D{1,2}[縣市])?)(?<district>(\D+?(市區|鎮區|鎮市|[鄉鎮市區]))?)(?<others>.+)/gm;
        const _regObj = reg.exec(addressString);

        return _regObj ? _regObj.groups : group;
    };

    // 取得對應 鄉鎮市區 district options
    const getDistrictsOptions = useCallback(
        (cityIndex) => {
            return districtOptions[cityIndex];
        },
        [districtOptions]
    );

    const getAddressByPostCode = useCallback(
        (postCode) => {
            let addressObj = {},
                result = {
                    postCode,
                    cityIndex: '',
                    districtIndex: '',
                    districtOptions: [],
                    postCodeOptions: [],
                };
            let zipCode = postCode;
            if (zipCode.length > 3) zipCode = zipCode.slice(0, 3);
            if (zipCodeObj.hasOwnProperty(zipCode)) addressObj = zipCodeObj[postCode];

            if (!isEmpty(addressObj)) {
                let districtOptions = getDistrictsOptions(addressObj.city);
                result.districtOptions = districtOptions[0];
                result.postCodeOptions = districtOptions[1];
                result.cityIndex = addressObj.city;
                result.districtIndex = addressObj.district;
            }
            return result;
        },
        // eslint-disable-next-line
        [zipCodeObj]
    );

    const getAddressByCity = (cityIndex) => {
        let result = {
            cityIndex: '',
            districtOptions: [],
            postCodeOptions: [],
        };

        if (cityIndex !== -1) {
            let districtOptions = getDistrictsOptions(cityIndex);
            result.cityIndex = cityIndex;
            if (!isEmpty(districtOptions)) {
                result.districtOptions = districtOptions[0];
                result.postCodeOptions = districtOptions[1];
            }
        }

        return result;
    };

    const getAddressByDistrict = (districtIndex, postCodeOptions) => {
        let result = {
            postCode: '',
            districtIndex: '',
        };
        if (districtIndex !== -1) {
            let zipCode = postCodeOptions[districtIndex];
            if (zipCode) result.postCode = zipCode;
            result.districtIndex = districtIndex;
        }
        return result;
    };

    const handleAddressDefaultValue = (addressString, postCodeString) => {
        const {
            postCode: zipCode,
            city: cityText,
            district: districtText,
            others,
        } = parseByAddress(addressString);
        let result = {
            postCode: zipCode || postCodeString,
            others,
        };

        if (cityText) {
            let cityIndex = cityOptions.findIndex((name) => name === cityText);
            if (cityIndex === -1) {
                // 錯誤資料塞到input 顯示
                result.others = addressString;
            } else {
                result = {
                    ...result,
                    ...getAddressByCity(cityIndex),
                };
                if (districtText) {
                    let districtIndex = result.districtOptions.findIndex(
                        (name) => name === districtText
                    );
                    if (districtIndex === -1) {
                        // 錯誤資料塞到input 顯示
                        result.others = districtText + others;
                    } else {
                        result = {
                            ...result,
                            ...getAddressByDistrict(districtIndex, result.postCodeOptions),
                        };
                    }
                }
            }
        } else if (result.postCode && result.postCode >= 3) {
            result = {
                ...result,
                ...getAddressByPostCode(result.postCode),
            };
        } else {
            result.others = addressString;
        }

        setData((prev) => ({
            ...prev,
            ...result,
        }));
    };

    const handleChangePostcode = (e) => {
        const { others } = parseByAddress(data.others);
        const value = e.target.value;
        let result = getAddressByPostCode(value);

        setData((prev) => ({
            ...prev,
            ...result,
            others,
        }));
    };

    const handleChangeCity = (e) => {
        const value = e.target.value;
        let result = getAddressByCity(value);
        setData((prev) => ({
            ...prev,
            ...result,
        }));
    };

    const handleChangeDistrict = (e) => {
        const { others } = parseByAddress(data.others);
        const value = e.target.value;
        const result = getAddressByDistrict(value, data.postCodeOptions);

        setData((prev) => ({
            ...prev,
            ...result,
            others,
        }));
    };

    const handleChangeOther = (e) => {
        const value = e.target.value;
        setData((prev) => ({
            ...prev,
            others: value,
        }));
    };

    useEffect(() => {
        if (!addressProps) return false;
        handleAddressDefaultValue(addressProps, postCodeProps);
        // eslint-disable-next-line
    }, [addressProps]);

    useEffect(() => {
        setError(errorProps);
    }, [errorProps]);

    useEffect(() => {
        setHelperText(helperTextProps);
    }, [helperTextProps]);

    useEffect(() => {
        if (readOnly) {
            setRequired(false);
        }
    }, [readOnly]);

    useImperativeHandle(ref, () => ({
        getResult: () => {
            let address = '';
            let postCode = '';

            if (cityRef && cityRef.current && cityRef.current.value)
                address = address + cityOptions[cityRef.current.value];

            if (districtRef && districtRef.current && districtRef.current.value)
                address = address + data.districtOptions[districtRef.current.value];

            if (otherRef && otherRef.current && otherRef.current.value)
                address = address + otherRef.current.value;

            if (postCodeRef && postCodeRef.current && postCodeRef.current.value)
                postCode = postCodeRef.current.value;

            return {
                postCode,
                address,
            };
        },
        isError: () => {
            let isError = false;
            let field = [];
            let helperText;

            if (required) {
                let cityError = cityRef && cityRef.current && isEmpty(cityRef.current.value);
                let districtError =
                    districtRef && districtRef.current && isEmpty(districtRef.current.value);
                let otherError = otherRef && otherRef.current && isEmpty(otherRef.current.value);

                if (cityError) {
                    isError = cityError;
                    field.push('鄉鎮[市]區');
                }
                if (districtError) {
                    isError = districtError;
                    field.push('鄉鎮[市]區');
                }
                if (otherError) {
                    isError = otherError;
                    helperText = helperTextProps;
                } else {
                    if (field.length > 0) {
                        helperText = field.join('、');
                        helperText = helperText + '為必填欄位';
                    }
                }
            }
            setError(isError);
            setHelperText(helperText);
        },
        resetValue: (address, postCode) => {
            if (address && typeof address === 'string')
                handleAddressDefaultValue(address, postCode);
        },
    }));

    return (
        <Box className={clsx('address-field', className)}>
            {label && <FormLabel required={required}>{label}</FormLabel>}
            <Box className="address ">
                <Grid container spacing={containerSpacing}>
                    <Grid item xs={4}>
                        <OutlinedInput
                            className={'address-item'}
                            type={'number'}
                            inputRef={postCodeRef}
                            value={data.postCode}
                            placeholder={!disabledProps ? '郵遞區號 三碼' : ''}
                            onChange={handleChangePostcode}
                            readOnly={readOnly}
                            isError={isError}
                            disabled={disabledProps}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            className={'address-item'}
                            inputRef={cityRef}
                            value={data.cityIndex}
                            onChange={handleChangeCity}
                            required={required}
                            readOnly={readOnly}
                            isError={isError}
                            disabled={disabledProps}
                            displayEmpty={!disabledProps}
                            fullWidth
                        >
                            <MenuItem disabled value="">
                                <em>縣市</em>
                            </MenuItem>
                            {Array.isArray(cityOptions) &&
                                cityOptions.map((name, i) => (
                                    <MenuItem key={name} value={i}>
                                        {name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            name={'district'}
                            inputRef={districtRef}
                            value={data.districtIndex}
                            className={'address-item'}
                            fullWidth
                            onChange={handleChangeDistrict}
                            required={required}
                            readOnly={readOnly}
                            isError={isError}
                            disabled={disabledProps}
                            displayEmpty={!disabledProps}
                        >
                            <MenuItem disabled value="">
                                <em>鄉鎮[市]區</em>
                            </MenuItem>
                            {Array.isArray(data.districtOptions) &&
                                data.districtOptions.map((name, i) => (
                                    <MenuItem key={name} value={i}>
                                        {name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={'address-item'}>
                            <OutlinedInput
                                name={'others'}
                                inputRef={otherRef}
                                value={data.others}
                                placeholder={!disabledProps ? addressPlaceholder : ''}
                                onChange={handleChangeOther}
                                required={required}
                                readOnly={readOnly}
                                isError={isError}
                                disabled={disabledProps}
                                fullWidth
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
            {!isEmpty(tips) && <FormHelperText className={'tips'}>{tips}</FormHelperText>}
            {isError && <FormHelperText isError={isError}>{helperText}</FormHelperText>}
        </Box>
    );
});

export default AddressField;
