import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { TextField, DateField } from '../../../components/';
import { getAgeData, parseDate } from '../../../util/moment';

// 顯示年齡日期元件
const BirthDayAgeBox = React.forwardRef((props, ref) => {
    const { defaultValue: birthday, isError: birthdayError, isRead = false, fieldRef } = props;
    const [ageData, setAgeData] = useState(getAgeData(birthday));
    const handleDateChange = (_, dateString) => {
        setAgeData(getAgeData(dateString));
    };
    
    return (
        <React.Fragment>
            <Grid item xs={4}>
                <DateField
                    label="生日"
                    ref={fieldRef}
                    defaultValue={birthday}
                    isError={Boolean(birthdayError)}
                    helperText={birthdayError}
                    readOnly={isRead}
                    required={!isRead}
                    onChange={handleDateChange}
                    maxDate={parseDate()}
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="年齡"
                    value={ageData ? ageData.years || 0 : '請選擇生日日期'}
                    readOnly
                />
            </Grid>
        </React.Fragment>
    );
});

export default BirthDayAgeBox;
