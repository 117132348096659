// redux
import { combineReducers } from 'redux';
// types
import { SET_HISTORY_LOCK_DATA } from './types';

export function HistoryLockData(state = { Data: { isLock: false, fn: null } }, action) {
    switch (action.type) {
        case SET_HISTORY_LOCK_DATA:
            if (action.Data === null) {
                action.Data = { isLock: false, fn: null };
            }
            return {
                Data: {
                    ...action.Data,
                },
            };
        default:
            return state;
    }
}

const Reducer = combineReducers({
    HistoryLockData,
});

export default Reducer;
