import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import moment from 'moment';
import { getFirstOfMonth, getLastOfMonth, getNowTime, isValidDate } from '@util/moment';
import { refIsRequiredError } from '@util/utils';
import { isEmpty } from 'lodash';
import { Alert, Card, CheckboxField, DateIntervalField, TextField } from '@common/components/';
import { Grid } from '@mui/material';
import { getEffectiveDate, SectionHeader, SelectWithMenuItems, useSectionEffect } from '@icoach/documents/cyclePurchase/sections/CyclePurchaseUtility';

const SuspensionSection = forwardRef((props, ref) => {
    const {
        title = '暫停(限單獨申請)',
        shippingDayData,
        data = {},
        isCreate = false,
        resource = {},
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
        onChangeSuspendDate,
    } = props;
    const effectTypeID = effectTypeMap.suspension;
    const { suspendReasonOptions: reasonOptions } = resource;
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [shippingMonth, setShippingMonth] = useState(); // 下次出貨月份
    const pickItemRef = useRef();
    const dateRef = useRef();
    const reasonRef = useRef();
    const otherRef = useRef();
    const isConfirmRef = useRef();

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    const getEffectiveMonth = (endDate) => {
        return moment(endDate).add(1, 'months').month() + 1;
    };

    const handleChangeStartDate = (date) => {
        if (isValidDate(date)) {
            const suspendStartDate = getFirstOfMonth(date);
            if (typeof onChangeSuspendDate === 'function') onChangeSuspendDate({ suspendStartDate: suspendStartDate });
        }
    };

    const handleChangeEndDate = (date) => {
        if (isValidDate(date)) {
            let month = getEffectiveMonth(date);
            setShippingMonth(month);

            if (typeof onChangeSuspendDate === 'function') onChangeSuspendDate({ suspendEndDate: date });
        }
    };

    const getResult = () => {
        let result = {};
        refIsRequiredError(dateRef, reasonRef, otherRef, isConfirmRef);
        if (dateRef.current && dateRef.current.getResult) {
            let dateRange = dateRef.current.getResult();
            result.suspendStartDate = getFirstOfMonth(dateRange.startDate);
            result.suspendEndDate = getLastOfMonth(dateRange.endDate);
        }
        if (reasonRef.current && reasonRef.current.getResult) result.suspendReason = reasonRef.current.getResult();
        if (otherRef.current && otherRef.current.getResult) result.suspendNote = otherRef.current.getResult();
        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(dateRef, reasonRef, otherRef, isConfirmRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useSectionEffect(setShowTip, onChangeEffectTypeProps, isShow, effectTypeID);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (!isEmpty(data.effectType)) setShow(data.effectType.includes(effectTypeID));
            if (isValidDate(data.suspendEndDate)) setShippingMonth(getEffectiveMonth(data.suspendEndDate));
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <React.Fragment>
            <SectionHeader title={title} isShowCheckBox={!isCreate} refItem={pickItemRef} handleChange={handleShowContent} defaultChecked={isShow} />
            {isShow && (
                <Card.CardContent className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={6} item>
                            <DateIntervalField
                                ref={dateRef}
                                label={'暫停期間'}
                                startDateProps={{
                                    defaultValue: data.suspendStartDate,
                                    minDate: getFirstOfMonth(getEffectiveDate(getNowTime(), shippingDayData.text)),
                                    onChange: handleChangeStartDate,
                                    placeholder: '請選擇月份',
                                }}
                                endDateProps={{
                                    defaultValue: data.suspendEndDate,
                                    minDate: getFirstOfMonth(getEffectiveDate(getNowTime(), shippingDayData.text)),
                                    onChange: handleChangeEndDate,
                                    placeholder: '請選擇月份',
                                }}
                                views={['year', 'month']}
                                openTo="month"
                                format="YYYY/MM"
                                mask="____/__"
                                placeholder={'請選擇月份'}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid xs={6} item>
                            <SelectWithMenuItems
                                refItem={reasonRef}
                                label="暫停原因"
                                defaultValue={data.suspendReason}
                                options={reasonOptions}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField
                                key={data.suspendNote}
                                ref={otherRef}
                                label={'暫停原因補充說明'}
                                defaultValue={data.suspendNote}
                                minRows={3}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        {shippingMonth && (
                            <Grid xs={12} item>
                                <CheckboxField ref={isConfirmRef} required>
                                    <CheckboxField.Checkbox value={true} label={`我已確認將於${shippingMonth}月，恢復出貨。`} />
                                </CheckboxField>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <Alert severity={'info'} variant={'filled'} color={'info'}>
                                如暫停超過三個月，請先辦理退訂
                            </Alert>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

export default SuspensionSection;
