import React, { useEffect, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { Autocomplete } from '@mui/material';
import { Box, FormControl, FormHelperText, FormLabel, OutlinedInput, Stack, Typography } from '@common/components/';
import GiftDialog from '@icoach/gift/dialog/GiftDialog';
import { createFilterOptions, findIndexFn, isEmpty, isValid } from '@util/utils';

const filter = createFilterOptions();

const multipleView = (_keys, _option, sign) =>
    _keys
        .reduce((acc, cur) => {
            let newAry = [...acc];
            let currentValue = _option[cur];
            if (isValid(currentValue)) newAry.push(_option[cur]);
            return newAry;
        }, [])
        .join(sign);

const getAllState = (_keys) => {
    return _keys.reduce((acc, cur) => ({ ...acc, [cur]: '' }), {});
};

const initValue = (props) => {
    const { value: _value, key, options } = props;
    let _index = -1;
    if (isEmpty(_value)) return {};
    _index = findIndexFn(options, key, _value);
    if (_index === -1) return {};
    return options[_index];
};

const GiftSoloCreateOptionDialog = React.forwardRef((props, ref) => {
    const {
        options: optionsProp = [],
        defaultValue: defaultValueProps = undefined,
        optionKeys = ['giftName', 'giftSpecifications', 'giftPrice'],
        primaryKey = 'giftID',
        name: nameProps,
        isError: propsError = false,
        disabled = false,
        fullWidth = false,
        required = false,
        isLightColor = false,
        className,
        label,
        direction: directionProps = 'column',
        helperText = '請填寫必填欄位',
        InputProps = {},
        sign: signProps = ',',
    } = props;
    const [isError, setError] = React.useState(propsError);
    const [value, setValue] = React.useState(initValue({ value: defaultValueProps, options: optionsProp, key: primaryKey }));
    const [open, toggleOpen] = React.useState(false);
    const [dialogValue, setDialogValue] = React.useState(getAllState(optionKeys));
    const inputRef = React.useRef(null);

    const onChange = (e, newValue) => {
        const getGiftData = (value, sign = ',') => {
            let _value = value;
            if (typeof value === 'object') _value = value.inputValue;
            return _value.split(sign);
        };

        if (typeof newValue === 'string' || (newValue && newValue.inputValue)) {
            const [giftName, giftSpecifications, giftPrice, giftDescription] = getGiftData(newValue);
            setTimeout(() => {
                toggleOpen(true);
                setDialogValue({ ...getAllState(optionKeys), giftName, giftSpecifications, giftPrice, giftDescription });
            });
        } else {
            setValue(newValue);
        }
    };

    const handleSubmitForm = (e, { params }) => {
        setValue(params);
        toggleOpen(false);
    };

    const handleClose = () => {
        setDialogValue(getAllState(optionKeys));
        toggleOpen(false);
    };

    const getOptionLabel = (_option) => {
        if (typeof _option === 'string') return _option;
        if (_option.inputValue) return _option.inputValue;
        return multipleView(optionKeys, _option, signProps);
    };

    const renderOption = (props, _option) => {
        const { key: label } = props;
        const viewText = multipleView(optionKeys, _option, signProps);
        return (
            <Box {...props} key={`${_option[primaryKey]}_${label}`}>
                <Typography component="span">{viewText}</Typography>
            </Box>
        );
    };

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                [optionKeys[0]]: `新增 "${params.inputValue}"`,
            });
        }
        return filtered;
    };

    const renderInput = (params) => {
        const defaultProperty = {
            disabled: params.disabled,
            fullWidth: params.fullWidth,
            InputProps: { ...params.InputProps, ...InputProps },
            ...params,
            name: nameProps,
        };
        return <OutlinedInput {...defaultProperty} inputRef={inputRef} />;
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    const { giftID } = value;
                    let result = {
                        giftID: 0,
                        giftName: '',
                        giftSpecifications: '',
                        giftPrice: 0,
                        giftDescription: '',
                    };
                    if (value.giftID) Object.assign(result, { giftID });
                    else Object.assign(result, value);
                    return result;
                },
                isError: () => {
                    let error = true;
                    if (!isEmpty(value)) error = false;
                    setError(error);
                    return error;
                },
                resetValue: () => {
                    setValue('');
                },
            };
        },
        // eslint-disable-next-line
        [value],
    );

    const autoCompleteProperty = {
        value,
        onChange,
        filterOptions,
        options: optionsProp,
        getOptionLabel,
        renderInput,
        renderOption,
        selectOnFocus: true,
        clearOnBlur: true,
        freeSolo: true,
        disabled: disabled,
        ...InputProps,
    };

    useEffect(() => {
        if (!isEmpty(optionsProp)) {
            setValue(initValue({ value: defaultValueProps, options: optionsProp, key: primaryKey }));
        }
        // eslint-disable-next-line
    }, [defaultValueProps, optionsProp, primaryKey]);

    return (
        <React.Fragment>
            <FormControl
                disabled={disabled}
                fullWidth={fullWidth}
                required={required}
                className={clsx('autocomplete-field', className)}
                direction={directionProps}
                error={isError}
            >
                {label && <FormLabel lightColor={isLightColor}>{label}</FormLabel>}
                <Stack direction="column" className="field-input-root">
                    <Autocomplete {...autoCompleteProperty} />
                    {isError && <FormHelperText>{helperText}</FormHelperText>}
                </Stack>
            </FormControl>
            <GiftDialog open={open} sourceData={dialogValue} onClose={handleClose} onSubmitForm={handleSubmitForm} />
        </React.Fragment>
    );
});

export default GiftSoloCreateOptionDialog;
