import Mock from 'mockjs';
import _ from 'lodash';
import { successApi } from './respondData';
import { getUrlParameter } from '../util/utils';

// 依來源類型查詢AutoComplete清單
export const withMockPeopleProvider = () => {
    Mock.mock(/^\/api\/people(\?.+)?$/, 'get', ({ url }) => {
        let sourceData = { ...successApi };
        const { type } = getUrlParameter('type', url);
        sourceData.result = _.cloneDeep(PEOPLE_ENUMS[type]);
        return sourceData;
    });
    Mock.setup({ timeout: '200-800' });
};

// 來源類型:1顧客 2會員(預設) 3無效會員 4承接會員 5名單 6全會員 8FA顧客 9顧客+名單
const PEOPLE_ENUMS = {
    1: [
        {
            id: 103,
            no: null,
            name: '曾晴天',
            mobile: '0928374623',
        },
        {
            id: 104,
            no: null,
            name: '鄒靖雯',
            mobile: '0977231521',
        },
        {
            id: 111,
            no: null,
            name: '酒瓶蘭',
            mobile: '0960611888',
        },
        {
            id: 112,
            no: null,
            name: '霸王花',
            mobile: '0900112333',
        },
        {
            id: 115,
            no: null,
            name: '杜鵑花',
            mobile: '0934489829',
        },
    ],
    2: [
        {
            id: 33,
            no: 'A000000021',
            name: '賴小曉',
            mobile: '0977221133',
        },
        {
            id: 34,
            no: 'A000000022',
            name: '徐若若',
            mobile: '0922113344',
        },
        {
            id: 37,
            no: 'A000000025',
            name: '藍小雲',
            mobile: '0966123234',
        },
        {
            id: 42,
            no: 'A000000029',
            name: '王大陸',
            mobile: '0911111111',
        },
        {
            id: 44,
            no: 'A000000031',
            name: '王大陸',
            mobile: '0911111111',
        },
        {
            id: 45,
            no: 'A000000032',
            name: '張曉涵',
            mobile: '09123321123',
        },
        {
            id: 46,
            no: 'A000000033',
            name: '花柚子',
            mobile: '0911111111',
        },
        {
            id: 47,
            no: 'A000000034',
            name: '安拉拉',
            mobile: '0933445566',
        },
        {
            id: 48,
            no: 'A000000035',
            name: '花橘子',
            mobile: '0911111111',
        },
        {
            id: 49,
            no: 'A000000036',
            name: '花爸爸',
            mobile: '0911111111',
        },
        {
            id: 52,
            no: 'A000000039',
            name: '張愛玲',
            mobile: '0933456788',
        },
        {
            id: 54,
            no: 'A000000041',
            name: '同嘉欣',
            mobile: '0944441111',
        },
        {
            id: 62,
            no: 'A000000048',
            name: '食人花',
            mobile: '0987587987',
        },
        {
            id: 63,
            no: 'A000000047',
            name: '陳橘子',
            mobile: '',
        },
        {
            id: 64,
            no: 'A000000046',
            name: '獅子丸',
            mobile: '',
        },
        {
            id: 65,
            no: 'A000000045',
            name: '陳新亞',
            mobile: '',
        },
    ],
    3: [
        {
            id: 21,
            no: 'A000000009',
            name: '梁逸君',
            mobile: '0988377800',
        },
        {
            id: 23,
            no: 'A000000011',
            name: '辛小琪',
            mobile: '0988445213',
        },
        {
            id: 24,
            no: 'A000000012',
            name: '陳筱曉',
            mobile: '0932123321',
        },
        {
            id: 36,
            no: 'A000000024',
            name: '黃小米',
            mobile: '0933147223',
        },
        {
            id: 38,
            no: 'A000000026',
            name: '張筱雨',
            mobile: '0910234043',
        },
        {
            id: 43,
            no: 'A000000030',
            name: '胡椒粉',
            mobile: '0911111111',
        },
    ],
    4: [],
    5: [
        {
            id: 100,
            no: null,
            name: '陳曉萱',
            mobile: '0912546212',
        },
        {
            id: 101,
            no: null,
            name: '林曉萱',
            mobile: '0912546212',
        },
        {
            id: 110,
            no: null,
            name: '辣手摧花',
            mobile: '0988000586',
        },
    ],
    6: [
        {
            id: 21,
            no: 'A000000009',
            name: '梁逸君',
            mobile: '0988377800',
        },
        {
            id: 23,
            no: 'A000000011',
            name: '辛小琪',
            mobile: '0988445213',
        },
        {
            id: 24,
            no: 'A000000012',
            name: '陳筱曉',
            mobile: '0932123321',
        },
        {
            id: 33,
            no: 'A000000021',
            name: '賴小曉',
            mobile: '0977221133',
        },
        {
            id: 34,
            no: 'A000000022',
            name: '徐若若',
            mobile: '0922113344',
        },
        {
            id: 36,
            no: 'A000000024',
            name: '黃小米',
            mobile: '0933147223',
        },
        {
            id: 37,
            no: 'A000000025',
            name: '藍小雲',
            mobile: '0966123234',
        },
        {
            id: 38,
            no: 'A000000026',
            name: '張筱雨',
            mobile: '0910234043',
        },
        {
            id: 42,
            no: 'A000000029',
            name: '王大陸',
            mobile: '0911111111',
        },
        {
            id: 43,
            no: 'A000000030',
            name: '胡椒粉',
            mobile: '0911111111',
        },
        {
            id: 44,
            no: 'A000000031',
            name: '王大陸',
            mobile: '0911111111',
        },
        {
            id: 45,
            no: 'A000000032',
            name: '張曉涵',
            mobile: '09123321123',
        },
        {
            id: 46,
            no: 'A000000033',
            name: '花柚子',
            mobile: '0911111111',
        },
        {
            id: 47,
            no: 'A000000034',
            name: '安拉拉',
            mobile: '0933445566',
        },
        {
            id: 48,
            no: 'A000000035',
            name: '花橘子',
            mobile: '0911111111',
        },
        {
            id: 49,
            no: 'A000000036',
            name: '花爸爸',
            mobile: '0911111111',
        },
        {
            id: 52,
            no: 'A000000039',
            name: '張愛玲',
            mobile: '0933456788',
        },
        {
            id: 54,
            no: 'A000000041',
            name: '同嘉欣',
            mobile: '0944441111',
        },
        {
            id: 62,
            no: 'A000000048',
            name: '食人花',
            mobile: '0987587987',
        },
        {
            id: 63,
            no: 'A000000047',
            name: '陳橘子',
            mobile: '',
        },
        {
            id: 64,
            no: 'A000000046',
            name: '獅子丸',
            mobile: '',
        },
        {
            id: 65,
            no: 'A000000045',
            name: '陳新亞',
            mobile: '',
        },
    ],
    7: [],
    8: [
        {
            id: 111,
            no: null,
            name: '酒瓶蘭',
            mobile: '0960611888',
        },
        {
            id: 115,
            no: null,
            name: '杜鵑花',
            mobile: '0934489829',
        },
    ],
    9: [
        {
            id: 103,
            no: null,
            name: '曾晴天',
            mobile: '0928374623',
        },
        {
            id: 104,
            no: null,
            name: '鄒靖雯',
            mobile: '0977231521',
        },
        {
            id: 111,
            no: null,
            name: '酒瓶蘭',
            mobile: '0960611888',
        },
        {
            id: 112,
            no: null,
            name: '霸王花',
            mobile: '0900112333',
        },
        {
            id: 115,
            no: null,
            name: '杜鵑花',
            mobile: '0934489829',
        },
        {
            id: 100,
            no: null,
            name: '陳曉萱',
            mobile: '0912546212',
        },
        {
            id: 101,
            no: null,
            name: '林曉萱',
            mobile: '0912546212',
        },
        {
            id: 110,
            no: null,
            name: '辣手摧花',
            mobile: '0988000586',
        },
    ],
};
