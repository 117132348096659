import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { IconButton, Typography, Table, TableHead, TableBody, TableRow, TableCell, TextField, Alert } from '@common/components/';
import { Cancel as CancelIcon, Appointment as AppointmentIcon } from '@common/SvgIcon/';
import { ProductBox } from '@icoach/accountBook/components/';
import { EditMemoDialog } from '@icoach/accountBook/dialog/';
import { CartTableItemProvider, useCartTableItemContext } from '@icoach/accountBook/context/CartTableItemContext';
import { useCartCardContext } from '@icoach/accountBook/context/CartCardContext';
import { formatCurrencyFn, refIsRequiredError } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';

export const NoDataTableRow = () => {
    return (
        <TableRow tabIndex={-1}>
            <TableCell colSpan={7}>
                <Alert variant={'standard'} severity={'info'} color={'info'}>
                    尚無資料
                </Alert>
            </TableCell>
        </TableRow>
    );
};

export const TableItemActions = (props) => {
    const { memoDialogProps, memoButtonProps = {}, deleteButtonProps = {} } = props;
    const { title: memoTitle = '備註' } = memoButtonProps;
    const { title: deleteTitle = '刪除' } = deleteButtonProps;
    const setMessageDialog = useMessageDialog();
    const { cartRef, data: itemData, handleDelete } = useCartTableItemContext();
    const [openDialog, setOpenDialog] = useState(false);
    const handleShowDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleDeleteButton = () => {
        const handleClickConfirm = () => {
            handleDelete(itemData, cartRef);
        };
        setMessageDialog({
            open: true,
            title: '確認刪除商品',
            msg: '請問您要將商品移除嗎?就無法恢復囉',
            onConfirm: handleClickConfirm,
        });
    };

    return (
        <React.Fragment>
            <IconButton className="icon-button-column" onClick={handleShowDialog}>
                <AppointmentIcon />
                <Typography className="icon-button-column-text">{memoTitle}</Typography>
            </IconButton>
            <IconButton className="icon-button-column" onClick={handleDeleteButton}>
                <CancelIcon />
                <Typography className="icon-button-column-text">{deleteTitle}</Typography>
            </IconButton>
            <EditMemoDialog {...memoDialogProps} open={openDialog} onClose={handleCloseDialog} />
        </React.Fragment>
    );
};

const ProductItemRow = React.forwardRef((props, ref) => {
    const { data = {}, cartRef, handleDelete, handleUpdateCart } = props;
    const { sku, productTypeText, productName, variantName, price, pictureSrc, notes = '' } = data;
    const [memo, setMemo] = useState(notes);
    const [total, setTotal] = useState(0);
    const assignPriceRef = useRef();
    const qtyRef = useRef();

    const calculateTotal = () => {
        let price = assignPriceRef.current.getResult();
        let qty = qtyRef.current.getResult();
        if (price) price = parseInt(price);
        if (qty) qty = parseInt(qty);
        if (typeof price !== 'number' || typeof qty !== 'number') return false;
        setTotal(price * qty);
    };

    useEffect(
        () => {
            calculateTotal();
        },
        // eslint-disable-next-line
        []
    );
    useEffect(
        () => {
            handleUpdateCart(cartRef);
        },
        // eslint-disable-next-line
        [memo, total]
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => ({
                ...data,
                assignPrice: assignPriceRef.current.getResult(),
                quantity: qtyRef.current.getResult(),
                notes: memo,
                total: total,
            }),
            isError: () => refIsRequiredError(assignPriceRef, qtyRef),
        }),
        // eslint-disable-next-line
        [memo, total]
    );

    return (
        <CartTableItemProvider
            value={{
                data,
                cartRef,
                memo,
                setMemo,
                handleDelete,
            }}
        >
            <TableRow className={'cart-table-item product-item'}>
                <TableCell>
                    <ProductBox imgUrl={pictureSrc} name={productName} variantName={variantName} sku={sku} desc={memo && `備註：${memo}`} />
                </TableCell>
                <TableCell align={'center'}>{productTypeText}</TableCell>
                <TableCell />
                {/* <TableCell align={'center'}>{formatCurrencyFn(price)}</TableCell> */}
                <TableCell>
                    <TextField maskType={'MONEY'} ref={assignPriceRef} defaultValue={price} onChangeNative={calculateTotal} required />
                </TableCell>
                <TableCell>
                    <TextField type={'number'} ref={qtyRef} defaultValue={1} inputProps={{ min: 1 }} onChangeNative={calculateTotal} required />
                </TableCell>
                <TableCell align={'right'}>
                    <strong>{formatCurrencyFn(total)}</strong>
                </TableCell>
                <TableCell align={'right'}>
                    <TableItemActions />
                </TableCell>
            </TableRow>
        </CartTableItemProvider>
    );
});
const FeeItemRow = React.forwardRef((props, ref) => {
    const { data = {}, cartRef, handleDelete, handleUpdateCart } = props;
    const { name, assignPrice, productTypeText, notes = '' } = data;
    const [memo, setMemo] = useState(notes);
    const [total, setTotal] = useState(0);
    const assignPriceRef = useRef();

    const calculateTotal = () => {
        let price = parseInt(assignPriceRef.current.getResult());
        if (typeof price !== 'number') return false;
        setTotal(price);
    };

    useEffect(() => {
        handleUpdateCart(cartRef);
        // eslint-disable-next-line
    }, [memo, total]);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => ({
                ...data,
                assignPrice: assignPriceRef.current.getResult(),
                notes: memo,
            }),
            isError: () => refIsRequiredError(assignPriceRef),
        }),
        // eslint-disable-next-line
        [memo]
    );
    return (
        <CartTableItemProvider
            value={{
                data,
                cartRef,
                memo,
                setMemo,
                handleDelete,
            }}
        >
            <TableRow className={'cart-table-item fee-item'}>
                <TableCell>
                    <ProductBox className={'fee-item'} name={name} desc={memo && `備註：${memo}`} />
                </TableCell>
                <TableCell align={'center'}>{productTypeText}</TableCell>
                <TableCell />
                <TableCell colSpan={2}>
                    <TextField maskType={'MONEY'} ref={assignPriceRef} defaultValue={assignPrice} onChangeNative={calculateTotal} required />
                </TableCell>
                <TableCell align={'right'}>
                    <strong>{formatCurrencyFn(total)}</strong>
                </TableCell>
                <TableCell align={'right'}>
                    <TableItemActions />
                </TableCell>
            </TableRow>
        </CartTableItemProvider>
    );
});

const CartTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>品項</TableCell>
                <TableCell align={'center'}>類型</TableCell>
                <TableCell />
                {/* <TableCell align={'center'}>定價</TableCell> */}
                <TableCell align={'center'}>價格</TableCell>
                <TableCell align={'center'}>數量</TableCell>
                <TableCell align={'right'}>總計</TableCell>
                <TableCell align={'right'}></TableCell>
            </TableRow>
        </TableHead>
    );
};

const OrderCartTable = React.forwardRef((props, ref) => {
    const { className } = props;
    const { cartData, deleteCart, updateCart } = useCartCardContext();
    const cartRef = useRef([]);

    return (
        <Table className={clsx('cart-table', className)}>
            <CartTableHead />
            <TableBody>
                {cartData.length > 0 ? (
                    cartData.map((item, i) => {
                        if (item.hasOwnProperty('productID')) {
                            return (
                                <ProductItemRow
                                    key={`product-${item.productID}-${item.variantID}`}
                                    ref={(el) => (cartRef.current[i] = el)}
                                    cartRef={cartRef}
                                    data={item}
                                    handleDelete={deleteCart}
                                    handleUpdateCart={updateCart}
                                />
                            );
                        } else {
                            return (
                                <FeeItemRow
                                    key={`fee-${item.name}`}
                                    ref={(el) => (cartRef.current[i] = el)}
                                    cartRef={cartRef}
                                    data={item}
                                    handleDelete={deleteCart}
                                    handleUpdateCart={updateCart}
                                />
                            );
                        }
                    })
                ) : (
                    <NoDataTableRow />
                )}
            </TableBody>
        </Table>
    );
});

export default OrderCartTable;
