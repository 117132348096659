import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const RingBall = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#51A69E', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="m12.02,19.51c-3.42,0-6.85,0-10.27,0q-.25,0-.25-.24c0-.61,0-1.23,0-1.84,0-.13.04-.22.12-.32.9-1.01,1.79-2.02,2.69-3.03.14-.15.2-.31.2-.52,0-1.21,0-2.41,0-3.62,0-1.36.32-2.65.97-3.85.27-.5.62-.96,1.01-1.39.76-.84,1.68-1.43,2.71-1.87.36-.16.75-.24,1.13-.35.15-.04.21-.11.21-.27-.01-.69-.01-1.39-.02-2.08,0-.09.02-.12.11-.12.93,0,1.85,0,2.78,0,.14,0,.13.08.13.17,0,.66,0,1.31,0,1.97,0,.2.07.28.24.33.48.15.96.28,1.41.49,1.02.48,1.88,1.17,2.61,2.01.38.45.7.95.96,1.49.31.64.52,1.3.65,1.99.09.5.14,1.02.14,1.53,0,1.22,0,2.44,0,3.66,0,.17.05.29.16.42.92,1.02,1.83,2.04,2.74,3.06.07.08.11.17.11.29,0,.63-.01,1.26,0,1.9,0,.17-.05.21-.21.21-3.44,0-6.87,0-10.31,0Zm10.88-10.13c.32,0,.64,0,.96,0,.12,0,.18-.03.17-.17-.04-.66-.09-1.32-.2-1.97-.28-1.6-.87-3.07-1.74-4.43-.47-.74-1.04-1.39-1.66-2-.08-.08-.13-.1-.22,0-.45.46-.9.92-1.36,1.37-.09.09-.11.14-.01.24.24.25.47.5.7.76.9,1.03,1.53,2.2,1.9,3.51.23.82.32,1.67.35,2.52,0,.14.05.18.19.18.31,0,.63,0,.94,0h0Zm-21.78,0c.32,0,.64,0,.96,0,.13,0,.18-.04.18-.17,0-.43.04-.85.1-1.27.19-1.33.63-2.57,1.34-3.71.42-.67.95-1.25,1.51-1.81.09-.09.1-.15,0-.23-.5-.42-.92-.92-1.37-1.39-.09-.09-.14-.1-.24,0-1.01,1.01-1.85,2.14-2.45,3.45C.43,5.83.07,7.48,0,9.2c0,.15.05.18.18.18.31,0,.63,0,.94,0h0Zm8.08,11.64c-.14,0-.17.02-.16.18.07.95.53,1.68,1.27,2.24.89.68,2.31.75,3.2.16.85-.57,1.38-1.34,1.5-2.37.02-.15-.01-.21-.18-.21-.93,0-1.86,0-2.8,0-.94,0-1.89,0-2.83,0Z" />
        </SvgIcon>
    );
});

export default RingBall;
