import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_PRODUCT_SEARCH_SALE_OPTIONS_API = `/api/products/search-sale-options/`;
const GET_PRODUCT_SALE_OPTIONS_API = `/api/products/sale-options/`;
export const GET_CYCLE_PURCHASE_PRODUCTS_API = `/api/products/cycle-purchase/`;

const useProductApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得結帳需使用到的下拉式選單
    const getProductSaleOptionsApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_PRODUCT_SALE_OPTIONS_API, params);
            !resp && enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得循環訂單商品清單
    const getCyclePurchaseProductsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_CYCLE_PURCHASE_PRODUCTS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 全商品分類
    const getAllMerchandiseDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_PRODUCT_SEARCH_SALE_OPTIONS_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getProductSaleOptionsApi,
        getCyclePurchaseProductsApi,
        getAllMerchandiseDataApi,
    };
};

export default useProductApi;
