import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isEmpty, keyBy } from 'lodash';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router';
import { Box, Button, Card, DateField, Form, MenuItem, SelectField, Typography } from '@common/components/';
import { CheckoutOrderCart, CheckoutPaidListBox, SetTargetSearchBar, CreditOrderList, QuestionnaireBox } from '@icoach/accountBook/components';
import useOrderCheckoutApi from '@apis/useOrderCheckoutApi';
import { refIsRequiredError } from '@util/utils';
import { getToday } from '@util/moment';
import useMessageDialog from '@util/hook/useMessageDialog';
import { ACCOUNT_BOOK_ORDERS_OVERVIEW_URL } from '@icoach/router/AccountBookRouter';
import { checkoutDateMessageConfig, isBeforeOfCurrentMonth } from '@icoach/accountBook/staticData';
import useInvoiceApi from '@apis/useInvoiceApi';
import {
    InvoiceByCarrierNo,
    InvoiceByCarrierType,
    InvoiceByDonate,
    InvoiceByEmail,
    InvoiceByIssuingType,
    InvoiceByManual,
    InvoiceByTriplicateUniform,
    invoiceCarrierTypeMap,
    issuingTypeMap,
} from '@icoach/components/Invoice';

// 設定建單日與建單人
export const CreateOrderInfoSection = React.forwardRef((props, ref) => {
    const { className, resource = {} } = props;
    const { coachOptions: employeeOptions = [] } = resource;
    const setMessageDialog = useMessageDialog();
    const employeeRef = useRef(null);
    const orderDateRef = useRef(null);
    const today = getToday();

    const handleChangeDate = (d, v) => {
        if (isBeforeOfCurrentMonth(v)) {
            setMessageDialog(checkoutDateMessageConfig);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (employeeRef.current && employeeRef.current.getResult) result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
                if (orderDateRef.current && orderDateRef.current.getResult) result = Object.assign(result, { orderDate: orderDateRef.current.getResult() });
                return result;
            },
            isError: () => {
                let isError = false;
                // 檢核建立日期是否在當月之前
                if (orderDateRef.current && orderDateRef.current.getResult) {
                    let orderDate = orderDateRef.current.getResult();
                    if (!isError) {
                        isError = isBeforeOfCurrentMonth(orderDate);
                        if (isError) setMessageDialog(checkoutDateMessageConfig);
                    }
                }

                if (!isError) {
                    isError = refIsRequiredError(employeeRef, orderDateRef);
                }
                return isError;
            },
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Box className={className}>
            <Grid spacing={3} container>
                <Grid xs={3} item>
                    <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                        {Array.isArray(employeeOptions) &&
                            employeeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
                <Grid xs={3} item>
                    <DateField label={'建立日期'} ref={orderDateRef} defaultValue={today} onChange={handleChangeDate} fullWidth />
                </Grid>
            </Grid>
        </Box>
    );
});

export const InvoiceCard = React.forwardRef((props, ref) => {
    const { className, sourceData, options: optionsProps = {}, errorModule = {}, readOnly } = props;
    const { getInvoiceOptionsApi } = useInvoiceApi();
    const {
        issuingType: issuingTypeProps,
        invoiceCarrierType: invoiceCarrierTypeProps,
        invoiceCarrierNo,
        donationCode,
        invoiceNo,
        issuingEmail,
    } = sourceData || {};
    const [options, setOptions] = useState(optionsProps);
    const { invoiceTypeOptions = [], invoiceCarrierTypeOptions = [] } = options;
    const [issuingType, setIssuingType] = useState(issuingTypeProps || 2);
    const [invoiceCarrierType, setInvoiceCarrierType] = useState(invoiceCarrierTypeProps || 1);
    const issuingTypeRef = useRef(null);
    const donateRef = useRef(null);
    const invoiceCarrierTypeRef = useRef(null);
    const invoiceCarrierNoRef = useRef(null);
    const triplicateUniformRef = useRef(null);
    const manualRef = useRef(null);
    const emailRef = useRef(null);

    const initOptionsApi = async () => {
        const resp = await getInvoiceOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    const handleChangeIssuingType = (value) => {
        setIssuingType(value);
    };

    const handleChangeInvoiceCarrierType = (value) => {
        setInvoiceCarrierType(value);
    };

    const getResult = () => {
        let result = {};

        if (issuingTypeRef.current && issuingTypeRef.current.getResult) {
            result = Object.assign(result, issuingTypeRef.current.getResult());
        }

        if (donateRef.current && donateRef.current.getResult) {
            result = Object.assign(result, donateRef.current.getResult());
        }

        if (invoiceCarrierTypeRef.current && invoiceCarrierTypeRef.current.getResult) {
            const { carrierType } = invoiceCarrierTypeRef.current.getResult();
            Object.assign(result, { invoiceCarrierType: carrierType });
        }

        if (invoiceCarrierNoRef.current && invoiceCarrierNoRef.current.getResult) {
            const { carrierNo } = invoiceCarrierNoRef.current.getResult();
            Object.assign(result, { invoiceCarrierNo: carrierNo });
        }

        if (triplicateUniformRef.current && triplicateUniformRef.current.getResult) {
            result = Object.assign(result, triplicateUniformRef.current.getResult());
        }

        if (manualRef.current && manualRef.current.getResult) {
            result = Object.assign(result, manualRef.current.getResult());
        }

        if (emailRef.current && emailRef.current.getResult) {
            result = Object.assign(result, emailRef.current.getResult());
        }

        return result;
    };

    useEffect(
        () => {
            if (isEmpty(options)) {
                initOptionsApi();
            }
        },
        // eslint-disable-next-line
        [optionsProps],
    );

    useEffect(
        () => {
            if (issuingTypeProps) setIssuingType(issuingTypeProps);
        },
        // eslint-disable-next-line
        [issuingTypeProps],
    );

    useEffect(
        () => {
            if (invoiceCarrierTypeProps) setInvoiceCarrierType(invoiceCarrierTypeProps);
        },
        // eslint-disable-next-line
        [invoiceCarrierTypeProps],
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(issuingTypeRef, donateRef, invoiceCarrierTypeRef, invoiceCarrierNoRef, triplicateUniformRef, manualRef, emailRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Card className={className}>
            <Card.CardContent className={'p-4'}>
                <Grid className={className} spacing={2} container>
                    <Grid xs={12} item>
                        <InvoiceByIssuingType
                            ref={issuingTypeRef}
                            defaultValue={issuingTypeProps}
                            options={invoiceTypeOptions}
                            errorData={errorModule}
                            onChange={handleChangeIssuingType}
                            readOnly={readOnly}
                        />
                    </Grid>
                    {String(issuingType) === issuingTypeMap.donate && (
                        <Grid xs={12} item>
                            <InvoiceByDonate ref={donateRef} defaultValue={donationCode} errorData={errorModule} readOnly={readOnly} />
                        </Grid>
                    )}
                    {String(issuingType) === issuingTypeMap.duplicateUniform && (
                        <React.Fragment>
                            <Grid xs={12} item>
                                <InvoiceByCarrierType
                                    ref={invoiceCarrierTypeRef}
                                    defaultValue={invoiceCarrierType}
                                    options={invoiceCarrierTypeOptions}
                                    errorData={errorModule}
                                    onChange={handleChangeInvoiceCarrierType}
                                    readOnly={readOnly}
                                />
                            </Grid>
                            {(String(invoiceCarrierType) === invoiceCarrierTypeMap.citizenDigitalCertificate1 ||
                                String(invoiceCarrierType) === invoiceCarrierTypeMap.mobile) && (
                                <Grid xs={12} item>
                                    <InvoiceByCarrierNo
                                        ref={invoiceCarrierNoRef}
                                        defaultValue={invoiceCarrierNo}
                                        invoiceCarrierType={invoiceCarrierType}
                                        errorData={errorModule}
                                        readOnly={readOnly}
                                    />
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                    {String(issuingType) === issuingTypeMap.triplicateUniform && (
                        <InvoiceByTriplicateUniform ref={triplicateUniformRef} sourceData={sourceData} errorData={errorModule} readOnly={readOnly} />
                    )}
                    {String(issuingType) === issuingTypeMap.manual && (
                        <Grid xs={12} item>
                            <InvoiceByManual ref={manualRef} sourceData={{ invoiceNo }} errorData={errorModule} readOnly={readOnly} />
                        </Grid>
                    )}
                    {String(issuingType) !== issuingTypeMap.manual && (
                        <Grid item xs={12}>
                            <InvoiceByEmail
                                ref={emailRef}
                                defaultValue={issuingEmail}
                                InputProps={{ placeholder: '未填寫信箱，電子發票開立通知將寄到店舖信箱' }}
                                errorData={errorModule}
                                readOnly={readOnly}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card.CardContent>
        </Card>
    );
});

export const CheckOutContent = React.forwardRef((props, ref) => {
    const { className, resource, getReceiver, errorModule } = props;
    const [orderTotal, setOrderTotal] = useState(0);
    const [paidPrice, setPaidPrice] = useState(0);
    const [isShowQuestionnaireBox, setShowQuestionnaireBox] = useState(false);
    const [creditOrders, setCreditOrder] = useState({});
    const { orders, invoiceSetting } = creditOrders;
    const [isShowCreditOrders, setShowCreditOrders] = useState(false);
    const checkoutCartRef = useRef(null);
    const creditOrderRef = useRef(null);
    const checkoutRef = useRef(null);
    const questionnaireRef = useRef(null);
    const invoiceRef = useRef(null);

    let calculateOrderTotal = () => {
        let total = 0;
        if (checkoutCartRef && checkoutCartRef.current && checkoutCartRef.current.getTotal) {
            let checkoutCartTotal = checkoutCartRef.current.getTotal();
            total += checkoutCartTotal;
        }

        if (creditOrderRef && creditOrderRef.current && creditOrderRef.current.getTotalData) {
            let data = creditOrderRef.current.getTotalData();

            total += data.total;
            setPaidPrice(data.paidPrice);
        }

        setOrderTotal(total);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (checkoutCartRef.current && checkoutCartRef.current.getResult) result = Object.assign(result, checkoutCartRef.current.getResult());
                if (creditOrderRef.current && creditOrderRef.current.getResult) {
                    result = Object.assign(result, creditOrderRef.current.getResult());
                } else {
                    result.checkoutOrderIDs = [];
                }
                if (checkoutRef.current && checkoutRef.current.getResult) result = Object.assign(result, checkoutRef.current.getResult());
                if (questionnaireRef.current && questionnaireRef.current.getResult) result = Object.assign(result, questionnaireRef.current.getResult());
                if (invoiceRef.current && checkoutRef.current.getResult) result = Object.assign(result, { invoiceSetting: invoiceRef.current.getResult() });
                return result;
            },
            isError: () => refIsRequiredError(checkoutCartRef, creditOrderRef, checkoutRef, questionnaireRef, invoiceRef),
            setCreditOrder,
            setShowCreditOrders,
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Box className={className}>
            <Grid spacing={3} container>
                {isShowCreditOrders && (
                    <Grid xs={12} item>
                        <CreditOrderList ref={creditOrderRef} orders={orders} updateOrderTotal={calculateOrderTotal} />
                    </Grid>
                )}
                <Grid xs={12} item>
                    <CheckoutOrderCart
                        className={'mb-1'}
                        ref={checkoutCartRef}
                        resource={resource}
                        getReceiver={getReceiver}
                        setShowQuestionnaireBox={setShowQuestionnaireBox}
                        updateOrderTotal={calculateOrderTotal}
                    />
                </Grid>
                <Grid xs={6} item>
                    <InvoiceCard
                        ref={invoiceRef}
                        sourceData={{ ...invoiceSetting, issuingEmail: creditOrders.member?.issuingEmail || '' }}
                        options={resource}
                        showEmail
                    />
                </Grid>
                <Grid xs={6} item>
                    {isShowQuestionnaireBox && <QuestionnaireBox ref={questionnaireRef} className={'mb-4'} resource={resource} />}
                    <CheckoutPaidListBox ref={checkoutRef} receivableAmount={orderTotal} paidPrice={paidPrice} resource={resource} errorModule={errorModule} />
                </Grid>
            </Grid>
        </Box>
    );
});

// 新增收入
const AccountBookCreateOrderPage = () => {
    const { getOrderCheckoutOptionsApi, getOrderCheckoutApi, postOrderCheckoutByPeopleApi } = useOrderCheckoutApi();
    const history = useHistory();
    const setMessageDialog = useMessageDialog();
    const [resource, setResource] = useState({});
    const [isShowContent, setShowContent] = useState(false);
    const [errorModule, setErrorModule] = useState({});
    const orderInfoRef = useRef(null);
    const searchBarRef = useRef(null);
    const checkoutRef = useRef(null);

    const getParams = () => {
        let params = {};
        if (orderInfoRef.current && orderInfoRef.current.getResult) {
            params = Object.assign(params, orderInfoRef.current.getResult());
        }
        if (searchBarRef.current && searchBarRef.current.getResult) {
            params = Object.assign(params, searchBarRef.current.getResult());
        }
        if (checkoutRef.current && checkoutRef.current.getResult) {
            params = Object.assign(params, checkoutRef.current.getResult());
        }
        return params;
    };

    const checkError = () => {
        return refIsRequiredError(orderInfoRef, searchBarRef, checkoutRef);
    };

    const handleClickCheckout = () => {
        let params = getParams();
        let isError = checkError();
        if (!isError) {
            doOrderCheckoutApi(params);
        }
    };

    const handleCancel = () => {
        history.push(ACCOUNT_BOOK_ORDERS_OVERVIEW_URL);
    };

    const doOptionsApi = async () => {
        let resp = await getOrderCheckoutOptionsApi();
        if (resp) {
            setResource(resp);
        }
    };

    // 會員訂單確認
    const doConfirmMemberOrderApi = async (memberID) => {
        let resp = await getOrderCheckoutApi(memberID);
        if (resp) {
            if (checkoutRef.current && checkoutRef.current.setCreditOrder) {
                checkoutRef.current.setCreditOrder(resp);
            }
            if (!isEmpty(resp.orders)) {
                const handleClickConfirm = (checkoutRef) => {
                    if (checkoutRef.current && checkoutRef.current.setShowCreditOrders) checkoutRef.current.setShowCreditOrders(true);
                };
                setMessageDialog({
                    open: true,
                    title: '欠費提醒',
                    msg: '查詢到會員尚有未結清的訂單，請問是否於本次消費一起結帳？',
                    onConfirm: () => handleClickConfirm(checkoutRef),
                });
            }
        }
    };

    // 新增收入api
    const doOrderCheckoutApi = async (params) => {
        const resp = await postOrderCheckoutByPeopleApi(params);
        if (resp) {
            if (resp.isError) {
                setErrorModule(keyBy(resp.result, 'key'));
            } else {
                history.push(ACCOUNT_BOOK_ORDERS_OVERVIEW_URL);
                setErrorModule({});
            }
        } else {
            setErrorModule({});
        }
    };

    useEffect(
        () => {
            doOptionsApi();
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <div className="container main-container-spacing">
            <Form onSubmit={handleClickCheckout}>
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        新增收入
                    </Typography>
                </header>
                <CreateOrderInfoSection className={'mb-2'} ref={orderInfoRef} resource={resource} />
                <SetTargetSearchBar
                    className={'mb-2'}
                    title={'設定購買人'}
                    ref={searchBarRef}
                    resource={resource}
                    isShowContent={isShowContent}
                    setShowContent={setShowContent}
                    doConfirmMemberOrderApi={doConfirmMemberOrderApi}
                />
                {isShowContent && (
                    <React.Fragment>
                        <CheckOutContent
                            ref={checkoutRef}
                            resource={resource}
                            getReceiver={searchBarRef.current.getReceiver}
                            errorModule={errorModule}
                            isShowCash
                            isShoCredit
                        />
                        <Grid spacing={3} container>
                            <Grid xs={12} item>
                                <div className="btn-group text-right mt-4">
                                    <Button variant={'outlined'} onClick={handleCancel}>
                                        取消，回列表
                                    </Button>
                                    <Button type={'submit'} variant={'contained'}>
                                        確認結帳
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Form>
        </div>
    );
};

export default AccountBookCreateOrderPage;
