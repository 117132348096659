import {
    AccountBookPage,
    AccountBookCreateOrderPage,
    AccountBookCreateRemitPage,
    AccountBookOrderDetailPage,
    AccountBookSettleCashPage,
    AccountBookOrderBulkDebitBillingDetailPage,
    AccountBookOverviewFilterPage,
    AccountBookOrderAllowanceRecordPage,
    AccountBookRemitAllowanceRecordPage,
} from '../accountBook';

export const ACCOUNT_BOOK_ORDER_CREATE = `/club/account-book/orders/create/`;
export const ACCOUNT_BOOK_REMIT_CREATE = `/club/account-book/remit/create/`;
export const ACCOUNT_BOOK_ORDERS_OVERVIEW = `/club/account-book/:tabs(orders)/overview/`;
export const ACCOUNT_BOOK_ORDERS_OVERVIEW_URL = `/club/account-book/orders/overview/`;
export const ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW = `/club/account-book/:tabs(remit-orders)/overview/`;
export const ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL = `/club/account-book/remit-orders/overview/`;
export const ACCOUNT_BOOK_REMIT_ALLOWANCE = `/club/account-book/remit/:remittanceID/allowance-history/`;
export const ACCOUNT_BOOK_REMIT_ALLOWANCE_URL = (remittanceID) => `/club/account-book/remit/${remittanceID}/allowance-history/`;
export const ACCOUNT_BOOK_SITE_ORDER_STAT = `/club/account-book/:tabs(site-order-stat)/overview/`;
export const ACCOUNT_BOOK_SITE_ORDER_STAT_URL = '/club/account-book/site-order-stat/overview/';
export const ACCOUNT_BOOK_ALL_ORDER_STAT = `/club/account-book/:tabs(all-order-stat)/overview/`;
export const ACCOUNT_BOOK_ALL_ORDER_STAT_URL = `/club/account-book/all-order-stat/overview/`;
export const ACCOUNT_BOOK_BULK_DEBIT = `/club/account-book/:tabs(bulk-debit)/`;
export const ACCOUNT_BOOK_BULK_DEBIT_URL = `/club/account-book/bulk-debit/`;

export const ACCOUNT_BOOK_ORDERS_ORDER = `/club/account-book/orders/:orderID/`;
export const ACCOUNT_BOOK_ORDERS_ORDER_URL = (orderID) => `/club/account-book/orders/${orderID}/`;
export const ACCOUNT_BOOK_ORDERS_ALLOWANCE = `/club/account-book/orders/:orderID/allowance-history/`;
export const ACCOUNT_BOOK_ORDERS_ALLOWANCE_URL = (orderID) => `/club/account-book/orders/${orderID}/allowance-history/`;

export const ACCOUNT_BOOK_TABS_SETTLE_CASH_PRICE = `/club/account-book/settle-cash-price/:date/`;
export const ACCOUNT_BOOK_TABS_SETTLE_CASH_PRICE_URL = (date) => `/club/account-book/settle-cash-price/${date}/`;

export const ACCOUNT_BOOK_BULK_DEBIT_BILLING_DETAIL = `/club/account-book/:tabs(bulk-debit)/billing/:billingID`;
export const ACCOUNT_BOOK_BULK_DEBIT_BILLING_DETAIL_URL = (billingID) => `/club/account-book/bulk-debit/billing/${billingID}`;

// 批次-循環訂單
export const ACCOUNT_BOOK_BULK_DEBIT_CYCLE_ORDER = `/club/account-book/:tabs(bulk-debit)/cycle-order/:date/`;
export const ACCOUNT_BOOK_BULK_DEBIT_CYCLE_ORDER_URL = (date) => `/club/account-book/bulk-debit/cycle-order/${date}/`;
// 批次-會籍月費
export const ACCOUNT_BOOK_BULK_DEBIT_MEMBERSHIP = `/club/account-book/:tabs(bulk-debit)/membership/:date/`;
export const ACCOUNT_BOOK_BULK_DEBIT_MEMBERSHIP_URL = (date) => `/club/account-book/bulk-debit/membership/${date}/`;
// 批次-租櫃
export const ACCOUNT_BOOK_BULK_DEBIT_RENT_CABINET = `/club/account-book/:tabs(bulk-debit)/rent-cabinet/:date/`;
export const ACCOUNT_BOOK_BULK_DEBIT_RENT_CABINET_URL = (date) => `/club/account-book/bulk-debit/rent-cabinet/${date}/`;

export const ACCOUNT_BOOK_OVERVIEW_FILTER = `/club/account-book/overview/filter/`;

const accountBookRouter = [
    {
        description: '營收管理',
        frontKey: 'C6X01',
    },
    {
        description: '收入總覽',
        exact: true,
        path: ACCOUNT_BOOK_ORDERS_OVERVIEW,
        href: ACCOUNT_BOOK_ORDERS_OVERVIEW_URL,
        tabsIndex: 'orders',
        as: AccountBookPage,
        frontKey: 'C6X02',
    },
    {
        description: '匯款總覽',
        exact: true,
        path: ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW,
        href: ACCOUNT_BOOK_REMIT_ORDERS_OVERVIEW_URL,
        tabsIndex: 'remit-orders',
        as: AccountBookPage,
        frontKey: 'C6X07',
    },
    {
        description: '現場收入總覽',
        exact: true,
        path: ACCOUNT_BOOK_SITE_ORDER_STAT,
        href: ACCOUNT_BOOK_SITE_ORDER_STAT_URL,
        tabsIndex: 'site-order-stat',
        as: AccountBookPage,
        frontKey: 'C6X03',
    },
    {
        description: '總收入總覽',
        exact: true,
        path: ACCOUNT_BOOK_ALL_ORDER_STAT,
        href: ACCOUNT_BOOK_ALL_ORDER_STAT_URL,
        tabsIndex: 'all-order-stat',
        as: AccountBookPage,
        frontKey: 'C6X04',
    },

    {
        description: '新增收入',
        exact: true,
        path: ACCOUNT_BOOK_ORDER_CREATE,
        href: ACCOUNT_BOOK_ORDER_CREATE,
        as: AccountBookCreateOrderPage,
        frontKey: 'C6X06',
    },
    {
        description: '新增匯款',
        exact: true,
        path: ACCOUNT_BOOK_REMIT_CREATE,
        href: ACCOUNT_BOOK_REMIT_CREATE,
        as: AccountBookCreateRemitPage,
        frontKey: 'C6X08',
    },
    {
        description: '訂單折讓歷史列表',
        exact: true,
        path: ACCOUNT_BOOK_ORDERS_ALLOWANCE,
        as: AccountBookOrderAllowanceRecordPage,
        frontKey: 'C6X19',
    },
    {
        description: '匯款折讓歷史列表',
        exact: true,
        path: ACCOUNT_BOOK_REMIT_ALLOWANCE,
        as: AccountBookRemitAllowanceRecordPage,
        frontKey: 'C6X20',
    },
    {
        description: '訂單明細頁',
        exact: true,
        path: ACCOUNT_BOOK_ORDERS_ORDER,
        as: AccountBookOrderDetailPage,
        frontKey: 'C6X09',
    },
    {
        description: '每日結算',
        strict: true,
        path: ACCOUNT_BOOK_TABS_SETTLE_CASH_PRICE,
        as: AccountBookSettleCashPage,
        frontKey: 'C6X10',
    },
    {
        description: 'BILLING明細頁',
        exact: true,
        path: ACCOUNT_BOOK_BULK_DEBIT_BILLING_DETAIL,
        as: AccountBookOrderBulkDebitBillingDetailPage,
        frontKey: 'C6X11',
    },
    {
        description: '批次扣款',
        strict: true,
        path: ACCOUNT_BOOK_BULK_DEBIT,
        href: ACCOUNT_BOOK_BULK_DEBIT_URL,
        tabsIndex: 'bulk-debit',
        as: AccountBookPage,
        frontKey: 'C6X05',
    },
    {
        description: '快速查詢',
        exact: true,
        path: ACCOUNT_BOOK_OVERVIEW_FILTER,
        href: ACCOUNT_BOOK_OVERVIEW_FILTER,
        as: AccountBookOverviewFilterPage,
        frontKey: 'C6X18',
    },
];

export default accountBookRouter;
