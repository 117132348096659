import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Edit as EditIcon } from '@common/SvgIcon/';
import {
    Stack,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    SelectField,
    MenuItem,
    OutlinedInput,
    InputAdornment,
} from '@common/components/';
import { mergeArrayByProperty, refIsRequiredError } from '@util/utils';
import useAppointmentApi from '@apis/useAppointmentApi';

// 其他 對應的Enums
const otherNum = 99;

// hasReasonOption 是否有原因
const faHasReasonOption = [
    { value: 1, hasReasonOption: false, text: '入會' },
    { value: 2, hasReasonOption: true, text: '未入會' },
];

// 填寫原因元件
const OtherNoteBox = React.forwardRef((props, ref) => {
    const { value: valueProp = '' } = props;
    const inputValueRef = React.useRef(null);
    React.useImperativeHandle(
        ref,
        () => ({ getResult: () => inputValueRef.current.value }),
        // eslint-disable-next-line
        []
    );

    return (
        <div className="pt-2">
            <OutlinedInput
                defaultValue={valueProp}
                inputRef={inputValueRef}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <EditIcon />
                        </InputAdornment>
                    ),
                }}
                fullWidth
            />
        </div>
    );
});

// 下拉選單搭配其他原因元件
const ReasonSelectBox = React.forwardRef((props, ref) => {
    const { option = [], sourceData: sourceDataProps } = props;
    const [value, setValue] = React.useState(sourceDataProps.pushType || null);
    const [isError, setIsError] = React.useState(false);
    const reasonNoteRef = React.useRef(null);
    React.useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    let _text = reasonNoteRef.current?.getResult() ?? '';
                    return [value, _text];
                },
                isError: () => {
                    let err = true;
                    if (value) err = false;
                    setIsError(err);
                    return err;
                },
            };
        },
        // eslint-disable-next-line
        [value]
    );
    const handleOnChange = (e, node, _v) => setValue(_v);
    return (
        <React.Fragment>
            <SelectField
                isError={isError}
                helperText={`請選擇未入會原因`}
                defaultValue={value}
                onChange={handleOnChange}
                displayEmpty
                required
            >
                <MenuItem disabled value="">
                    <em>請選擇</em>
                </MenuItem>
                {option.map(({ text, value, disabled }) => (
                    <MenuItem key={value} value={value} disabled={disabled}>
                        {text}
                    </MenuItem>
                ))}
            </SelectField>
            {String(otherNum) === String(value) && (
                <OtherNoteBox ref={reasonNoteRef} value={sourceDataProps.pushReasonText} />
            )}
        </React.Fragment>
    );
});

// 入會或未入會元件
const ResultBox = React.forwardRef((props, ref) => {
    const { sourceData: sourceDataProps = {}, options = {} } = props;
    const [faPushTypeOption] = React.useState(
        mergeArrayByProperty(faHasReasonOption, options.faPushType, 'value', [
            'disabled',
            'text',
            'value',
        ])
    );
    
    const [faReasonTypeOption] = React.useState(options.faReasonType);
    const [sourceValue, setSourceValue] = React.useState(
        ['0', '1'].includes(String(sourceDataProps.pushType)) ? '1' : '2'
    );
    const faReasonTypeRef = React.useRef(null);
    const { value: _hasReasonValue = '' } = faPushTypeOption.find(
        ({ hasReasonOption }) => hasReasonOption
    );

    React.useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const [
                    pushType = null,
                    pushReasonText = '',
                ] = faReasonTypeRef.current?.getResult() ?? [sourceValue, ''];
                return { pushType, pushReasonText };
            },
            isError: () => refIsRequiredError(faReasonTypeRef),
        }),
        // eslint-disable-next-line
        [sourceValue]
    );

    const handleOnChange = (e, _value) => {
        _value = String(_value);
        setSourceValue(_value);
    };
    
    return (
        <RadioGroup defaultValue={sourceValue} onChange={handleOnChange} required>
            {faPushTypeOption.map(({ text, value }) => (
                <FormControlLabel key={value} value={value} label={text} control={<Radio />} />
            ))}
            {String(sourceValue) === String(_hasReasonValue) && (
                <ReasonSelectBox
                    ref={faReasonTypeRef}
                    option={faReasonTypeOption}
                    sourceData={sourceDataProps}
                />
            )}
        </RadioGroup>
    );
});

const SaleFigureAnalysisDialog = React.forwardRef((props, ref) => {
    const { putAppointmentPushApi } = useAppointmentApi();
    const {
        onClose: onCloseProp,
        open: openProp,
        id: apoId,
        options = {},
        sourceData: sourceDataProps = {},
        refresh,
    } = props;
    const resultRef = React.useRef(null);

    const handleCloseClick = (e) => {
        onCloseProp && onCloseProp(null);
    };

    const handleOnClick = (e) => {
        if (!refIsRequiredError(resultRef)) {
            const results = resultRef.current.getResult();
            const params = {
                appointmentID: apoId,
                ...results,
            };
            doAppointmentPushApi(params);
        }
    };

    const doAppointmentPushApi = async (params) => {
        const resp = await putAppointmentPushApi(params);
        if (resp) {
            onCloseProp && onCloseProp(null);
            refresh && refresh.current && refresh.current(params);
        }
    };

    const {
        figureAnalysisPushTypeOption: faPushType,
        figureAnalysisReasonTypeOption: faReasonType,
    } = options;

    return (
        <Dialog open={openProp} PaperProps={{ sx: { minWidth: 360 } }}>
            <GradientColorHeader onClose={handleCloseClick}>FA結果</GradientColorHeader>
            <DialogContent className="pt-2">
                <ResultBox
                    ref={resultRef}
                    sourceData={sourceDataProps}
                    options={{ faPushType, faReasonType }}
                />
                <Stack justifyContent="end" className="pt-2">
                    <Button variant="contained" onClick={handleOnClick}>
                        確定
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
});

export default SaleFigureAnalysisDialog;
