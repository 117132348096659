import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import clsx from 'clsx';
import { FormControl, FormLabel, Stack, TextField, FormHelperText } from '@common/components/';

const LeaveTextField = React.forwardRef((props, ref) => {
    const {
        label,
        className,
        dayProps = {},
        monthProps = {},
        lightColor,
        required,
        isShowDay = false,
        isError: propsError = false,
        helperText = '此選項為必填，不得為空值',
        fullWidth,
        readOnly = false,
        disabled = false,
    } = props;
    const monthRef = useRef(null);
    const dayRef = useRef(null);
    const [isError, setError] = useState(propsError);

    useImperativeHandle(ref, () => ({
        isError: () => {
            let source = required;
            if (required && monthRef && monthRef.current && monthRef.current.value !== '') {
                source = false;
            }
            if (required && dayRef && dayRef.current && dayRef.current.value !== '') {
                source = false;
            }
            setError(source);
            return source;
        },
        getResult: () => {
            let result = {};
            if (monthRef.current && monthRef.current.getResult) {
                result.month = monthRef.current.getResult();
            }
            if (dayRef.current && dayRef.current.getResult) {
                result.day = dayRef.current.getResult();
            }
            return result;
        },
    }));

    const handleOnMonthChange = (e) => {
        monthProps.onChange && monthProps.onChange(e.target.value);
    };
    const handleOnDayChange = (e) => {
        dayProps.onChange && dayProps.onChange(e.target.value);
    };

    useEffect(() => {
        setError(propsError);
    }, [propsError]);

    return (
        <FormControl
            fullWidth={fullWidth}
            className={clsx('leaveTextField', className)}
            error={isError}
        >
            {label && <FormLabel lightColor={lightColor}>{label}</FormLabel>}
            <Stack direction="row" spacing="2" className="">
                <Stack
                    direction="row"
                    spacing="2"
                    alignItems="center"
                    className="leaveTextField-wrap"
                >
                    <TextField
                        {...monthProps}
                        ref={monthRef}
                        readOnly={readOnly}
                        disabled={disabled}
                        onChange={handleOnMonthChange}
                    />
                    <span>月</span>
                </Stack>

                {isShowDay && (
                    <Stack
                        direction="row"
                        spacing="2"
                        alignItems="center"
                        className="leaveTextField-wrap"
                    >
                        <TextField
                            {...dayProps}
                            ref={dayRef}
                            readOnly={readOnly}
                            disabled={disabled}
                            onChange={handleOnDayChange}
                        />
                        <span>天</span>
                    </Stack>
                )}
            </Stack>
            {isError && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
});

export default LeaveTextField;
