import React, { useRef, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import GuestProductionAddDialog from '@icoach/nonMember/guestProduction/GuestProductionAddDialog';
import { initBookmark } from '@icoach/nonMember/staticData';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import { Button, DateField, Stack, Form } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { parseDate, getToday } from '@util/moment';
import useCustomerManufactureApi from '@apis/useCustomerManufactureApi';

const GuestProductionSaerchBar = () => {
    const { getCustomerManufactureOverviewApi } = useCustomerManufactureApi();
    const { setSourceData, bookmark, setBookmarkNum, setRefresh, refresh, optionEnums = {} } = useNonMember();
    const [dialogOpen, setDialogOpen] = useState(false);
    const startDateRef = useRef(null);
    const preloadRef = useRef(false);

    const getParams = (targetPage) => {
        preloadRef.current = false;
        const paramsJson = {
            startDate: parseDate(startDateRef.current.getResult(), 'YYYY-MM'),
            ...targetPage,
        };
        return paramsJson;
    };
    const handleSearchOnClick = (e) => {
        if (!refIsRequiredError(startDateRef)) {
            const params = getParams(initBookmark);
            doSearchApi(params);
            setBookmarkNum(1);
        }
    };

    const handleAddClick = (e) => {
        setDialogOpen(true);
    };

    const doSearchApi = async (params) => {
        const resp = await getCustomerManufactureOverviewApi(params);
        if (resp) {
            setSourceData && setSourceData(resp);
        }
        preloadRef.current = true;
    };

    // 切頁使用
    useEffect(
        () => {
            if (preloadRef.current) {
                const params = getParams(bookmark);
                doSearchApi(params);
            }
        },
        // eslint-disable-next-line
        [bookmark.pageIndex]
    );

    // init
    useEffect(
        () => {
            setRefresh(handleSearchOnClick);
            preloadRef.current = true;
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div className="px-3 py-4">
            <Form>
                <Grid container spacing={2}>
                    <Grid item lg={5} md={6} sm={8}>
                        <DateField
                            ref={startDateRef}
                            defaultValue={getToday()}
                            label="日期"
                            views={['year', 'month']}
                            mask="____/__"
                            format="YYYY/MM"
                            openTo="month"
                            direction="row"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Stack spacing={1}>
                            <Button color="secondary" variant="contained" onClick={handleSearchOnClick} type="submit">
                                搜尋
                            </Button>
                            <Button color="info" variant="contained" onClick={handleAddClick}>
                                新增
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
            <GuestProductionAddDialog open={dialogOpen} onClose={() => setDialogOpen(false)} options={dialogOpen ? optionEnums : {}} refresh={refresh} />
        </div>
    );
};

export default GuestProductionSaerchBar;
