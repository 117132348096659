import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@common/components/';
import { NavigateNext as NavigateNextIcon, Star as StarIcon } from '@common/SvgIcon/';
import { ReportTableHeader, SearchBar, TableLabel } from '@icoach/dataAnalysis/components/';
import { ServiceDailyTable, ServiceMonthlyTable } from '@icoach/dataAnalysis/tables/';
import useReportApi from '@apis/useReportApi';
import { tableTargetMap } from './staticData';
import { DataAnalysisProvider } from '@icoach/dataAnalysis/components/DataAnalysisContext';

// 工作項目資料
const taskMatterData = [
    {
        text: 'Staff電話話術教育訓練',
        isStar: false,
    },
    {
        text: '聯繫最近兩週或一月沒來的會員',
        isStar: false,
    },
    {
        text: '回顧會員FWO問券',
        isStar: false,
    },
    {
        text: '對新會員進行2週面談',
        isStar: false,
    },

    {
        text: '每週5張謝謝您小卡與每位會員生日卡',
        isStar: false,
    },
    {
        text: '在CC時對會員用3C(糾正,激勵,慶祝)',
        isStar: false,
    },
    {
        text: '使用Curves商品獎勵會員參與店內活動',
        isStar: true,
    },
    {
        text: '使用忠誠方案獎勵會員(例如WO 100 T)',
        isStar: true,
    },
];

const ServiceRemindersContent = () => {
    return (
        <Box className={'bg-light px-4 pt-2 pb-3'}>
            <Typography className={'font-weight-bold'} variant={'h5'}>
                Reminders
            </Typography>
            <Typography className={'font-color-3 mb-0'}>請確認下列項目是否有執行</Typography>
            <List className={'reminders-list'}>
                {taskMatterData.map(({ text, isStar }) => (
                    <ListItem key={text}>
                        <ListItemIcon>
                            <NavigateNextIcon />
                        </ListItemIcon>
                        <ListItemText primary={<TableLabel title={text} isStar={isStar} />} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

const ServiceReportTableContent = (props) => {
    const { data = {} } = props;
    const { daily = [], weekly = [], cumulative } = data;
    const [tableTarget, setTable] = useState(tableTargetMap.monthly);
    const handleToggleTable = (target) => {
        setTable(target);
    };
    return (
        <Card className={'mb-4'}>
            <Card.CardContent className={'px-4 pt-3 pb-4'}>
                <ReportTableHeader className={'mb-2'} tableTarget={tableTarget} onToggleTable={handleToggleTable} />
                {tableTarget === tableTargetMap.daily && <ServiceDailyTable rowsData={daily} />}
                {tableTarget === tableTargetMap.monthly && (
                    <React.Fragment>
                        <Stack className={'mb-2'} alignItems={'center'} spacing={1}>
                            <StarIcon className={'svg-color-star'} />
                            <Typography className={'font-color-3'}>閃亮之星相關項目</Typography>
                        </Stack>
                        <ServiceMonthlyTable className={'mb-4'} rowsData={weekly} cumulative={cumulative} />
                        <ServiceRemindersContent />
                    </React.Fragment>
                )}
            </Card.CardContent>
        </Card>
    );
};

const DataAnalysisContentService = (props) => {
    const { defaultDate, dialogRef } = props;
    const { getReportServiceApi } = useReportApi();
    const [data, setData] = useState({});
    const searchRef = useRef();

    const doReportServiceApi = async (params) => {
        let res = await getReportServiceApi(params);
        if (res) {
            setData(res);
        }
    };

    const getParams = () => {
        let result = {};
        if (searchRef.current && searchRef.current.getResult) {
            result = Object.assign(result, searchRef.current.getResult());
        }

        return result;
    };

    const handleSubmit = () => {
        let isError = searchRef.current && searchRef.current.isError();
        if (!isError) {
            let params = getParams();
            doReportServiceApi(params);
        }
    };

    const childContext = {
        data,
        defaultDate,
        dialogRef,
    };

    useEffect(() => {
        handleSubmit();
        // eslint-disable-next-line
    }, []);

    return (
        <DataAnalysisProvider value={childContext}>
            <SearchBar ref={searchRef} className={'mb-4'} onSubmit={handleSubmit} defaultDate={props.defaultDate} />
            <ServiceReportTableContent data={data} />
        </DataAnalysisProvider>
    );
};

export default DataAnalysisContentService;
