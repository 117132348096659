import useAjax from '@apis/useAjax';
import useShowPDF from '@util/hook/useShowPDF';
import { checkResponse } from '@apis/utils';

const GET_DOC_MEMBERSHIP_QUIT_OPTIONS_API = `/api/doc/membership/quit/options/`;
const GET_DOC_MEMBERSHIP_QUIT_HISTORY_API = `/api/doc/membership/quit/history/`;
const GET_DOC_MEMBERSHIP_QUIT_ID_API = (contractID) => `/api/doc/membership/quit/${contractID}/`;
const POST_MEMBERSHIP_QUIT_ID_API = GET_DOC_MEMBERSHIP_QUIT_ID_API;
const DELETE_DOC_MEMBERSHIP_QUIT_ID_API = GET_DOC_MEMBERSHIP_QUIT_ID_API;
const POST_DOC_MEMBERSHIP_QUIT_RANGE_FEE_API = `/api/doc/membership/quit/range-fee/`;
const GET_DOC_MEMBERSHIP_QUIT_TEMPORARY_API = `/api/doc/membership/quit/temporary/`;
const POST_DOC_MEMBERSHIP_QUIT_TEMPORARY_API = GET_DOC_MEMBERSHIP_QUIT_TEMPORARY_API;
const DELETE_DOC_MEMBERSHIP_QUIT_TEMPORARY_API = GET_DOC_MEMBERSHIP_QUIT_TEMPORARY_API;
const GET_DOC_MEMBERSHIP_QUIT_TEMPLATE_PDF_API = `/api/doc/membership/quit/template.pdf`;
const GET_DOC_MEMBERSHIP_QUIT_CONTRACTID_INVALID_DIALOG_API = (contractID) => `/api/doc/membership/quit/${contractID}/invalid-dialog`;
const DELETE_DOC_MEMBERSHIP_CONTRACTID_API = (contractID) => `/api/doc/membership/quit/${contractID}`;

const useDocMembershipQuitApi = () => {
    const Ajax = useAjax();
    const showPDF = useShowPDF();

    // 取得退會申請書的下拉式選單
    const getDocMembershipQuitOptionApi = async () => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_QUIT_OPTIONS_API);
        return resp;
    };

    // 取得會員歷史紀錄(包含暫存)
    const getDocMembershipQuitHistoryApi = async (params) => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_QUIT_HISTORY_API, params);
        return resp;
    };

    // 取得退會會籍申請書內容
    const getDocMembershipQuitApi = async (contractID, params) => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_QUIT_ID_API(contractID), params);
        return resp;
    };

    // 新增/重填退會合約
    const postDocMembershipQuitApi = async (contractID = 0, parameters) => {
        const resp = await Ajax.post(POST_MEMBERSHIP_QUIT_ID_API(contractID), parameters);
        return resp;
    };

    // 刪除退會申請書 TODO: 後續處理事項，尚未撰寫
    const deleteDocMembershipQuitApi = async (contractID) => {
        const resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_QUIT_ID_API(contractID));
        return resp;
    };

    // 依輸入的內容重新計算日期及金額結果
    const postDocMembershipQuitRangeFeeApi = async (parameters) => {
        const resp = await Ajax.post(POST_DOC_MEMBERSHIP_QUIT_RANGE_FEE_API, parameters);
        return resp;
    };

    // 確認來源對象是否有暫存合約
    const getDocMembershipQuitTemporaryApi = async (params) => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_QUIT_TEMPORARY_API, params);
        return resp;
    };

    // 暫存退會申請書合約
    const postDocMembershipQuitTemporaryApi = async (params) => {
        const resp = await Ajax.post(POST_DOC_MEMBERSHIP_QUIT_TEMPORARY_API, params);
        return resp;
    };

    // 刪除來源對象的暫存合約
    const deleteDocMembershipQuitTemporaryApi = async (params) => {
        const resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_QUIT_TEMPORARY_API, params);
        return resp;
    };

    // 下載空白PDF文件
    const getDocMembershipQuitTemplatePDFApi = async () => {
        showPDF({
            open: true,
            pdfUrl: GET_DOC_MEMBERSHIP_QUIT_TEMPLATE_PDF_API,
        });
    };

    // 下載終止申請書(非空白)
    const getDocMembershipQuitPDFApi = async (contractUrl) => {
        showPDF({
            open: true,
            pdfUrl: contractUrl,
        });
    };

    // 刪除終止申請書初始資料
    const getDocMembershipQuitContractInvalidApi = async (contractID) => {
        const resp = await Ajax.get(GET_DOC_MEMBERSHIP_QUIT_CONTRACTID_INVALID_DIALOG_API(contractID));
        if (checkResponse(resp)) {
            return resp;
        }
    };

    // 刪除終止申請書
    const deleteDocMembershipQuitContractApi = async (params, contractID) => {
        const resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_CONTRACTID_API(contractID), params);
        if (checkResponse(resp)) {
            return resp;
        }
    };

    return {
        getDocMembershipQuitOptionApi,
        getDocMembershipQuitHistoryApi,
        getDocMembershipQuitApi,
        postDocMembershipQuitApi,
        deleteDocMembershipQuitApi,
        postDocMembershipQuitRangeFeeApi,
        getDocMembershipQuitTemporaryApi,
        postDocMembershipQuitTemporaryApi,
        deleteDocMembershipQuitTemporaryApi,
        getDocMembershipQuitTemplatePDFApi,
        getDocMembershipQuitPDFApi,
        getDocMembershipQuitContractInvalidApi,
        deleteDocMembershipQuitContractApi,
    };
};

export default useDocMembershipQuitApi;
