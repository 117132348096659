import React, { useRef } from 'react';
import { formatCurrencyFn } from '../../../util/utils';
import Tablenization from '../../components/Tablenization';
import { ProductBox } from '../components';

const rowsLabel = [
    {
        cellKey: 'itemSerialNumber',
        headerLabel: '品項',
        formatCell: (cellValue, rowData = {}) => {
            const { itemName } = rowData;
            return <ProductBox className={'flex-align-center'} name={itemName} />;
        },
    },
    {
        cellKey: 'itemPrice',
        headerLabel: '價格',
        align: 'center',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'itemCount',
        headerLabel: '數量',
        align: 'right',
    },
    {
        cellKey: 'total',
        headerLabel: '總計',
        align: 'right',
        formatCell: (cellValue, rowData) => {
            const { itemCount, itemPrice } = rowData;
            return formatCurrencyFn(itemPrice * itemCount);
        },
    },
];

const OrderAllowanceItemTable = (props, ref) => {
    let { rowsData = [], className } = props;
    const labelTitle = useRef(rowsLabel);

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default OrderAllowanceItemTable;
