import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const ChartColumnSolid = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M1.5,1.5C2.3,1.5,3,2.2,3,3v15.8c0,0.4,0.3,0.8,0.8,0.8h18.8c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5H3.8
	c-2.1,0-3.8-1.7-3.8-3.8V3C0,2.2,0.7,1.5,1.5,1.5z M7.5,10.5C8.3,10.5,9,11.2,9,12v3c0,0.8-0.7,1.5-1.5,1.5S6,15.8,6,15v-3
	C6,11.2,6.7,10.5,7.5,10.5z M13.5,7.5V15c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V7.5C10.5,6.7,11.2,6,12,6
	C12.8,6,13.5,6.7,13.5,7.5z M16.5,9c0.8,0,1.5,0.7,1.5,1.5V15c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5v-4.5
	C15,9.7,15.7,9,16.5,9z M22.5,4.5V15c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V4.5C19.5,3.7,20.2,3,21,3S22.5,3.7,22.5,4.5z"
            />
        </SvgIcon>
    );
});

export default ChartColumnSolid;
