import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Typography, IconButton, Tooltip } from '@common/components/';
import { Close as CloseIcon, Check as CheckIcon } from '@common/SvgIcon/';
import Tablenization from '@icoach/components/Tablenization';
import useMessageDialog from '@util/hook/useMessageDialog';
import { parseDate } from '@util/moment';
import { isEmpty } from '@util/utils';

const headerRow = [
    {
        cellKey: 'memberName',
        headerLabel: '會員姓名',
    },
    {
        cellKey: 'memberNo',
        headerLabel: '編號',
    },
    {
        cellKey: 'membershipEndDate',
        headerLabel: '合約終止日',
        formatCell: (value, rowData, { onDeleteItem }) => {
            return parseDate(value);
        },
    },
    {
        cellKey: 'hasCyclePurchase',
        headerLabel: '循環訂單',
        align: 'center',
        formatCell: (value) => {
            return value ? <CheckIcon color={'error'} /> : '無';
        },
    },
    {
        cellKey: 'hasCabinet',
        headerLabel: '租櫃',
        align: 'center',
        formatCell: (value) => {
            return value ? <CheckIcon color={'error'} /> : '無';
        },
    },
    {
        cellKey: 'memberID',
        align: 'right',
        headerLabel: '',
        formatCell: (value, rowData, { onDeleteItem }) => {
            return (
                <Tooltip title="刪除">
                    <IconButton onClick={() => onDeleteItem(value)}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            );
        },
    },
];

const MemberList = (props) => {
    const { rowsData, handleDeleteItem } = props;
    const memberDataRef = useRef(null);

    return (
        <Box className={'bg-light p-2'}>
            <Typography className={'font-weight-bold mb-1'} variant={'h6'}>
                {`會員清單(${rowsData.length})`}
            </Typography>
            <Tablenization>
                <Tablenization.TableRowHeader headerRow={headerRow} />
                <Tablenization.TableRowBody
                    headerRow={headerRow}
                    rowsData={rowsData}
                    memberData={memberDataRef.current || {}}
                    onDeleteItem={handleDeleteItem}
                />
            </Tablenization>
        </Box>
    );
};

const MemberSelectionPanel = React.forwardRef((props, ref) => {
    const { members } = props;
    const [memberData, setMemberData] = useState([]);
    const setMessageDialog = useMessageDialog();

    const handleDeleteItem = (id) => {
        const _members = memberData.filter(({ memberID }) => String(memberID) !== String(id));
        setMemberData(_members);
    };

    useEffect(
        () => {
            if (!isEmpty(members)) {
                setMemberData(members);
            }
        },
        // eslint-disable-next-line
        [members],
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return memberData.map((item) => item.memberID);
            },
            isError: () => {
                let isError = false;
                const memberIDs = memberData.map((item) => item.memberID);

                if (memberIDs.length < 1) {
                    setMessageDialog({
                        open: true,
                        title: '提醒',
                        msg: '至少要設定一位會員',
                    });
                    isError = true;
                }

                return isError;
            },
        }),
        // eslint-disable-next-line
        [memberData],
    );

    if (isEmpty(members)) return null;

    return (
        <React.Fragment>
            <MemberList rowsData={memberData} handleDeleteItem={handleDeleteItem} />
        </React.Fragment>
    );
});

export default MemberSelectionPanel;
