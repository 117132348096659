import React, { useImperativeHandle, useRef, useState } from 'react';
import ReportDetailDialog from '@icoach/dataAnalysis/dialog/ReportDetailDialog';

const REPORT_DETAIL_DIALOG = 'report-detail';

const DataAnalysisDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState('');
    const [sourceData, setSourceData] = useState(null);
    const refreshRef = useRef(null);

    const handleDialogClose = () => {
        setOpenDialog(null);
        setSourceData(null);
    };

    const doRefresh = (...arg) => {
        if (refreshRef.current && typeof refreshRef === 'function') {
            setTimeout(() => refreshRef.current(...arg), 5);
        }
    };

    useImperativeHandle(ref, () => ({
        openReportDetailDialog: (targetData, refreshFn) => {
            setSourceData(targetData);
            setOpenDialog(REPORT_DETAIL_DIALOG);
            refreshRef.current = refreshFn;
        },
    }));

    if (!openDialog) return  null;

    return (
        <React.Fragment>
            <ReportDetailDialog open={openDialog === REPORT_DETAIL_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />
        </React.Fragment>
    );
});

export default DataAnalysisDialogBox;
