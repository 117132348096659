import React from 'react';
import ListContext from './ListContext';
import clsx from 'clsx';

const staticClass = `list-item-icon`;

const ListItemIcon = React.forwardRef((props, ref) => {
    const { className, ...other } = props;
    const context = React.useContext(ListContext);
    const { alignItems } = context;
    let classNames = staticClass;

    if (alignItems === 'flex-start') classNames = clsx(classNames, `${staticClass}-margin-top`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };
    return <div {...defaultProperty} />;
});

export default ListItemIcon;
