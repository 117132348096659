import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { AppointmentItemBox, AppointmentTimelineBox, Transition } from '@icoach/landingPage/dialog/AppointmentFormDialog';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { AppointmentGreetingBox } from '@icoach/landingPage/appointment/';
import { FaEnum } from '@icoach/landingPage/staticData';
import { AutocompleteField, Button, Divider, MenuItem, SelectField, Stack, TextField, Typography, Alert, Box } from '@common/components/';
import usePeopleApi from '@apis/usePeopleApi';
import useAppointmentApi from '@apis/useAppointmentApi';
import { refIsRequiredError, isEmpty } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';
import { ReadTextField } from '@icoach/components';

// 顧客預約
// 預約者資料
const AppointmentPeopleInformationBox = React.forwardRef((props, ref) => {
    const { options: employeeOption, sourceData, isEdit } = props;
    const { peopleID = null, employeeID = null, notes = null } = sourceData;
    const { getPeopleApi } = usePeopleApi();
    const [peopleOption, setPeopleOption] = useState([]);
    const peopleRef = useRef(null);
    const employeeRef = useRef(null);
    const appointmentGreetingRef = React.useRef(null);
    const noteRef = useRef(null);

    const getAllCustomerApi = async () => {
        const isEditCutomer = isEdit ? 1 : 8;
        const resp = await getPeopleApi(isEditCutomer);
        if (resp) setPeopleOption(resp);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    return {
                        peopleType: 1,
                        peopleID: peopleID || peopleRef.current.getResult('id'),
                        employeeID: employeeRef.current.getResult(),
                        notes: noteRef.current.getResult(),
                        ...appointmentGreetingRef.current?.getResult() ?? {},
                    };
                },
                isError: () => {
                    return refIsRequiredError(peopleRef, employeeRef, noteRef, appointmentGreetingRef);
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            getAllCustomerApi();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Box className="pt-4">
                <Typography variant="h4" component="h4">
                    預約者資料
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid xs={12} item>
                    {isEdit ? (
                        <ReadTextField label={'顧客'}>{`${sourceData.name},${sourceData.cellPhone}`}</ReadTextField>
                    ) : (
                        <React.Fragment>
                            {!isEmpty(peopleOption) && (
                                <AutocompleteField
                                    label="顧客"
                                    defaultValue={peopleID}
                                    ref={peopleRef}
                                    options={peopleOption}
                                    optionKeys={['name', 'mobile']}
                                    primaryKey={'id'}
                                    fullWidth
                                    InputProps={{ disabled: !!peopleID }}
                                    required
                                />
                            )}
                        </React.Fragment>
                    )}
                </Grid>
                <Grid xs={12} item>
                    {isEdit && FaEnum.includes(String(sourceData.appointmentType)) && <ReadTextField label="來源">{sourceData.sourceTypeText}</ReadTextField>}
                </Grid>
                <Grid xs={6} item>
                    <SelectField label="擔當" ref={employeeRef} defaultValue={employeeID} displayEmpty required fullWidth>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {employeeOption.map(({ text, value, disabled }) => (
                            <MenuItem key={value} value={value} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid xs={6} item>
                    {!isEdit && <AppointmentGreetingBox ref={appointmentGreetingRef} />}
                </Grid>
                <Grid xs={12} item>
                    <TextField label="備註" defaultValue={notes} ref={noteRef} multiline fullWidth rows={4} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
});

// 顧客 FA預約用
const AppointmentFigureAnalysisFormDialog = (props) => {
    const {
        open: openProps,
        onClose: onCloseProps,
        options: optionsProps,
        refresh: refreshProps,
        sourceData: sourceDataProps,
        isEdit: isEditProps = false,
    } = props;
    const {
        appointmentID,
        appointmentDate = null,
        appointmentType = null,
        peopleID = null,
        employeeID = null,
        notes = null,
        name = null,
        cellPhone = null,
        peopleType = null,
        sourceTypeText = '',
    } = sourceDataProps || {};
    const setMessageDialog = useMessageDialog();
    const { postAppointmentAddApi, putAppointmentEditApi, postAppointmentCheckApi, getAppointmentFormOptionsDataApi } = useAppointmentApi();
    const apoTypeRef = React.useRef(null);
    const apoInfoRef = React.useRef(null);
    const apoTimelineRef = React.useRef(null);
    const [options, setOptions] = React.useState({});

    const { appointmentCustomerFaTypeOption: customerFaOption = [], appointmentCustomerTypeOption: woOption = [], employeeOption: empOption = [] } = options;

    const handleAddAppointmentForm = () => {
        if (!refIsRequiredError(apoTypeRef, apoInfoRef, apoTimelineRef)) {
            let params = {};
            const apoParam = apoTypeRef.current.getResult();
            const infoParam = apoInfoRef.current.getResult();
            const apoTimelineParam = apoTimelineRef.current.getResult();
            // const greetingDateParam = appointmentGreetingRef.current.getResult();
            Object.assign(params, apoParam, infoParam, apoTimelineParam);
            postAppointmentApi(params);
        }
    };

    const handleEditAppointmentForm = () => {
        if (!refIsRequiredError(apoTypeRef, apoInfoRef, apoTimelineRef)) {
            let params = {};
            const apoParam = apoTypeRef.current.getResult();
            const infoParam = apoInfoRef.current.getResult();
            const apoTimelineParam = apoTimelineRef.current.getResult();
            Object.assign(params, apoParam, infoParam, apoTimelineParam);
            putAppointmentApi(params, appointmentID);
        }
    };

    const handleOnclose = () => {
        onCloseProps && onCloseProps();
    };

    // 新增預約單
    const postAppointmentApi = async (params) => {
        const isApo = await postAppointmentCheckApi({
            peopleID: params.peopleID,
            peopleType: params.peopleType,
        });
        if (isApo) {
            if (Array.isArray(isApo) && ['1', '305'].includes(String(appointmentType))) {
                setMessageDialog({
                    open: true,
                    title: '預約單訊息',
                    MsgComp: () => (
                        <Alert color="info" variant="filled" severity="warning">
                            <Typography>
                                此顧客已預約FA
                                <br />
                                已預約的日期：
                                <br />
                                {isApo.map((dateString) => (
                                    <Typography key={dateString} className="pl-5" variant="h5" component="span">
                                        {dateString}
                                        <br />
                                    </Typography>
                                ))}
                            </Typography>
                            <Typography>確定要新增預約？？</Typography>
                        </Alert>
                    ),
                    onAsyncConfirm: async (e, closeFn) => {
                        const resp = await postAppointmentAddApi(params);
                        if (resp) {
                            handleOnclose();
                            closeFn();
                            refreshProps.current && refreshProps.current();
                        }
                    },
                });
            } else {
                const resp = await postAppointmentAddApi(params);
                if (resp) {
                    handleOnclose();
                    refreshProps.current && refreshProps.current();
                }
            }
        }
    };

    // 修改預約單
    const putAppointmentApi = async (params, apoID) => {
        const resp = await putAppointmentEditApi(params, apoID);
        if (resp) {
            handleOnclose();
            refreshProps.current && refreshProps.current();
        }
    };

    let customerAppointmentTypeOption = [
        {
            text: 'WO',
            value: 'wo',
            option: woOption,
        },
    ];

    if (isEditProps && FaEnum.includes(String(appointmentType))) {
        customerAppointmentTypeOption.push({ text: '銷售', value: 'cusFa', option: customerFaOption });
    }

    // 初始資料
    const getAppointmentItemDataApi = async () => {
        const resp = await getAppointmentFormOptionsDataApi();
        resp && setOptions(resp);
    };

    useEffect(
        () => {
            if (openProps && isEmpty(options)) {
                if (isEmpty(optionsProps)) {
                    getAppointmentItemDataApi();
                } else {
                    setOptions(optionsProps);
                }
            }
        },
        // eslint-disable-next-line
        [options, optionsProps, openProps]
    );

    return (
        <Dialog fullScreen open={openProps} TransitionComponent={Transition}>
            <GradientColorHeader onClose={handleOnclose}>{isEditProps ? `編輯預約單` : `FA入會約運動`}</GradientColorHeader>
            {!isEmpty(options) ? (
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AppointmentItemBox
                                ref={apoTypeRef}
                                sourceData={{ item: appointmentType, peopleType }}
                                options={customerAppointmentTypeOption}
                                isEdit={isEditProps}
                            />
                            {/* {!isEditProps && <AppointmentGreetingBox ref={appointmentGreetingRef} sourceData={{ greetingDate }} />} */}
                            <Divider />
                            <AppointmentPeopleInformationBox
                                isEdit={isEditProps}
                                ref={apoInfoRef}
                                options={empOption}
                                sourceData={{
                                    peopleID,
                                    employeeID,
                                    notes,
                                    name,
                                    cellPhone,
                                    appointmentType,
                                    sourceTypeText,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <Typography variant="h4" component="h4">
                                    選擇時間
                                </Typography>
                                <AppointmentTimelineBox ref={apoTimelineRef} apoDate={appointmentDate} />
                                <Stack justifyContent="end">
                                    {isEditProps ? (
                                        <Button variant="contained" onClick={handleEditAppointmentForm}>
                                            確認編輯
                                        </Button>
                                    ) : (
                                        <Button variant="contained" onClick={handleAddAppointmentForm}>
                                            確認新增
                                        </Button>
                                    )}
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            ) : null}
        </Dialog>
    );
};

export default AppointmentFigureAnalysisFormDialog;
