import React from 'react';
import { SelectField, MenuItem } from '@common/components/';
import useEmployeeScheduleApi from '@apis/useEmployeeScheduleApi';

const EmployeeScheduleCircuitMatter = React.forwardRef((props, ref) => {
    const { postEmployeeScheduleCircuitDataApi } = useEmployeeScheduleApi();
    const { option, sourceData: sourceDataProp = {}, scheduleDate, scheduleTime } = props;
    const { employeeID = '', scheduleID = 0 } = sourceDataProp;

    const handleOnChange = (e, node, value) => {
        let params = {
            scheduleDate: scheduleDate,
            scheduleTime: scheduleTime,
            employeeID: value,
            scheduleID: scheduleID,
        };
        postCircuitApi(params);
    };

    const postCircuitApi = async (params) => {
        await postEmployeeScheduleCircuitDataApi(params);
    };

    return (
        <SelectField defaultValue={employeeID || '0'} onChange={handleOnChange}>
            <MenuItem value="0">
                <em>未安排</em>
            </MenuItem>
            {option.map(({ text, value, disabled }) => (
                <MenuItem value={value} disabled={disabled} key={`${value}-${text}`}>
                    {text}
                </MenuItem>
            ))}
        </SelectField>
    );
});

export default EmployeeScheduleCircuitMatter;
