import React, { useState } from 'react';
import CheckinDialog from '../dialog/CheckinDialog';
import CheckoutDialog from '../dialog/CheckoutDialog';
import { Button } from '../../../components';

const CheckinHeaderButtons = React.forwardRef((props, ref) => {
    const [dailogOpen, setDialogOpen] = useState(null);
    return (
        <React.Fragment>
            <Button variant="contained" onClick={() => setDialogOpen('checkin')}>
                手動簽到
            </Button>
            {/*<Button variant="outlined" onClick={() => setDialogOpen('checkout')}>*/}
            {/*    手動簽退*/}
            {/*</Button>*/}
            <CheckinDialog open={dailogOpen === 'checkin'} onClose={setDialogOpen} />
            <CheckoutDialog open={dailogOpen === 'checkout'} onClose={setDialogOpen} />
        </React.Fragment>
    );
});

export default CheckinHeaderButtons;
