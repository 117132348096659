import React, { useRef } from 'react';
import { Typography, Box } from '@common/components/';
import { parseDate } from '@util/moment';
import { EditContactRecordCard } from '@icoach/components/';
import useMessageDialog from '@util/hook/useMessageDialog';

/**
 * 顧客/名單 聯繫資訊Cell  以外的禁止使用
 */
const DescribeLabelBox = (props = {}) => {
    const { contactDate, contactResultType, contactResultTypeText: resultTypeText, coachName, description, title, setContactState } = props;
    const setMessageDialog = useMessageDialog();
    const editContactRecordCardRef = useRef(null);

    const handleAsyncConfirm = (_e, onClose) => {
        editContactRecordCardRef.current.onUpdateClick(onClose, setContactState);
    };

    const getTextNote = (text_1, text_2) => {
        let textAry = [];
        if (text_1) textAry.push(text_1);
        if (textAry.length !== 0 && text_2) textAry.push('~');
        if (text_2) textAry.push(text_2);
        return textAry.join('');
    };

    const handleClickNotes = (target = {}) => {
        setMessageDialog({
            open: true,
            title: '聯繫內容',
            MsgComp: () => {
                return <EditContactRecordCard ref={editContactRecordCardRef} {...target} />;
            },
            onAsyncConfirm: handleAsyncConfirm,
            buttonText: `更新`,
        });
    };

    return (
        <Box
            className={'cursor-pointer'}
            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            onClick={() => handleClickNotes({ ...props, description: description, title: title })}
        >
            <Typography>
                <span>{parseDate(contactDate, 'YYYY/MM/DD')}</span>
                &nbsp;
                {!!contactResultType && <span>{resultTypeText}</span>}
                {!!coachName && <span>{` - ${coachName}`}</span>}
            </Typography>
            <Typography className={'font-color-3'} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {getTextNote(title, description)}
            </Typography>
        </Box>
    );
};

export default DescribeLabelBox;
