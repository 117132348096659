import React, { useRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { FormControlLabel, Checkbox, Box } from '@common/components/';
import { refIsRequiredError } from '@util/utils';

const NoticeCard = React.forwardRef((props, ref) => {
    const { img, title, noticeData, className, defaultValue = false, readOnly, WarnComponent } = props;
    const checkRef = useRef(null);
    useImperativeHandle(ref, () => ({
        getResult: () => {
            return checkRef.current;
        },
        isError: refIsRequiredError.apply(checkRef),
    }));
    return (
        <React.Fragment>
            {WarnComponent && <WarnComponent />}
            <Box className={clsx(className, 'notice-card')}>
                <Box className="notice-card-img">{img && <img className="img-fluid" src={img} alt="title" />}</Box>
                <Box className="notice-card-content">
                    <FormControlLabel
                        className="control"
                        control={<Checkbox inputRef={(el) => (checkRef.current = el)} defaultChecked={defaultValue} />}
                        label={title}
                        readOnly={readOnly}
                    />
                    <ul className="list">
                        {noticeData.map((item) => (
                            <li key={uuid()} className="list-item">
                                {item}
                            </li>
                        ))}
                    </ul>
                </Box>
            </Box>
        </React.Fragment>
    );
});

export default NoticeCard;
