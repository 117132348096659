import React from 'react';
import clsx from 'clsx';

const staticClass = `button-group`;

const variantArray = ['contained', 'outlined', 'text'];
const orientationArray = ['horizontal', 'vertical'];
const colorArray = ['primary', 'secondary', 'error', 'info', 'success', 'warning'];

const ButtonGroup = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        color = 'primary',
        component: Component = 'div',
        disabled = false,
        // disableElevation = false,
        // disableFocusRipple = false,
        disableRipple = false,
        fullWidth = false,
        orientation = 'horizontal',
        size = 'medium',
        variant = 'outlined',
        style,
        // ...other
    } = props;

    let classNames = '';
    if (variantArray.indexOf(variant) !== -1)
        classNames = clsx(classNames, `${staticClass}-variant-${variant}`);
    // if (disableElevation) classNames = clsx(classNames, `${staticClass}-disable-elevation`);
    if (fullWidth) classNames = clsx(classNames, `${staticClass}-full-width`);
    if (orientationArray.indexOf(orientation) !== -1)
        classNames = clsx(classNames, `${staticClass}-orientation-${orientation}`);
    if (colorArray.indexOf(color) !== -1)
        classNames = clsx(classNames, `${staticClass}-color-${color}`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);

    // const ownerState = {
    //     ...props,
    //     color,
    //     component,
    //     disabled,
    //     disableElevation,
    //     disableFocusRipple,
    //     disableRipple,
    //     fullWidth,
    //     orientation,
    //     size,
    //     variant,
    // };

    return (
        <Component className={clsx(`${staticClass}-root`, classNames, className)} style={style}>
            {React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) return null;
                return React.cloneElement(child, {
                    className: clsx(child.props.className, `${staticClass}-grouped`, classNames),
                    color: child.props.color || color,
                    disabled: child.props.disabled || disabled,
                    // disableElevation: child.props.disableElevation || disableElevation,
                    // disableFocusRipple,
                    disableRipple,
                    fullWidth,
                    size: child.props.size || size,
                    variant: child.props.variant || variant,
                });
            })}
        </Component>
    );
});

export default ButtonGroup;
