import React, { useRef, useImperativeHandle, useCallback } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, InputAdornment, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { percentageToFloat, refIsRequiredError } from '@util/utils';
import useReportApi from '@apis/useReportApi';
import { displayKpiDefaultValue } from '@icoach/dataAnalysis/staticData';
import { TitleByTooltip } from '@icoach/members/components/CounterBox';

const HCKpiTextField = React.forwardRef((props, ref) => {
    const { className, data, labelData } = props;
    const { title, tip, goal } = labelData;
    const { isRate } = data;
    let InputProps = {},
        inputProps = {
            step: '0.01',
            placeholder: '設定目標',
        };
    if (isRate) {
        InputProps.endAdornment = <InputAdornment position={'end'}>%</InputAdornment>;
    }
    if (goal) {
        inputProps.placeholder = goal;
    }
    return (
        <TextField
            ref={ref}
            className={clsx('hc-kpi-text-filed', className)}
            type={'number'}
            key={displayKpiDefaultValue(data)}
            label={<TitleByTooltip title={title} tip={tip} />}
            defaultValue={displayKpiDefaultValue(data)}
            inputProps={inputProps}
            InputProps={InputProps}
            fullWidth
        />
    );
});

const HcKpiGoalDialogContent = React.forwardRef((props, ref) => {
    const { data } = props;
    const { healthcare } = data;
    const refs = useRef([]);

    const makeFieldItem = useCallback(
        (refs) => {
            return healthcare.map((item, i) => {
                let labelData = {
                    title: item.itemName,
                };
                return (
                    <Grid key={`${item.dataType}-${item.saleCategoryID}-${item.orderType}`} xs={4} item>
                        <HCKpiTextField ref={(el) => (refs.current[i] = el)} className={'mb-1'} data={item.detail} labelData={labelData} />
                    </Grid>
                );
            });
        },
        // eslint-disable-next-line
        [data]
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const result = [];
                const handleValue = (data, ref) => {
                    const { dataType, saleCategoryID, orderType, detail = {} } = data;
                    const { isRate } = detail;
                    let targetSettingValue = ref.getResult() || 0;
                    if (isRate) {
                        targetSettingValue = percentageToFloat(targetSettingValue);
                    }
                    return {
                        dataType,
                        saleCategoryID,
                        orderType,
                        targetSettingValue,
                    };
                };

                if (refs.current) {
                    let healthcareRef = refs.current;

                    healthcareRef.forEach((item, i) => {
                        if (item.getResult) {
                            result.push(handleValue(healthcare[i], item));
                        }
                    });
                }

                return result;
            },
            isError: () => refIsRequiredError(Object.values(refs.current)),
        }),
        //eslint-disable-next-line
        [data]
    );
    return (
        <DialogContent className={'pt-4'}>
            <Grid spacing={3} container>
                {makeFieldItem(refs)}
            </Grid>
        </DialogContent>
    );
});

const HcKpiGoalDialog = (props) => {
    const { className, open, data, getDate, onClose, refresh } = props;
    const { postHcReportKpiGoalApi } = useReportApi();
    const contentRef = useRef();

    const doHcReportKpiGoalApi = async (date, params) => {
        let res = await postHcReportKpiGoalApi(date, params);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const getParams = () => {
        let result;
        if (contentRef.current && contentRef.current.getResult) result = contentRef.current.getResult();
        return result;
    };

    const handleSubmit = () => {
        let isError = refIsRequiredError(contentRef);
        if (!isError) {
            let params = getParams();
            let { date } = getDate();
            doHcReportKpiGoalApi(date, params);
        }
    };

    return (
        <Dialog
            key={open}
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>設定HC目標</GradientColorHeader>
            <HcKpiGoalDialogContent ref={contentRef} data={data} />
            <DialogActions className="px-4 pt-0 pb-2">
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HcKpiGoalDialog;
