import Mock from 'mockjs';
import { successApi } from './respondData';

// 快速查詢會員
export const withMockMemberQuickFilterProvider = () => {
    // 取得入會申請書內容api/members/type
    Mock.mock(/^\/api\/members\/type$/, 'post', (option) => {
        const { body } = option;
        const { filterType } = JSON.parse(body);
        const data = filterMap[filterType];
        let sourceData = { ...successApi };
        sourceData.result = {
            ...data,
        };

        return sourceData;
    });

    Mock.setup({ timeout: '200-800' });
};

// 次數名單
const memberWorkoutTimes = [
    {
        workoutFrequency: '1-1-1-1',
        workoutTimes: 1,
        comeClubDay: '2022-05-19T16:08:34.628146',
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 續約名單
const memberRenews = [
    {
        workoutFrequency: '1-1-1-1',
        contractEndDated: '2022-05-19T15:43:45.929774',
        membershipContinueMonth: 1,
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 請假名單
const memberOfficialLeaves = [
    {
        leaveDay: 1,
        suspendApplyDated: '2022-05-18T00:00:00',
        leaveStartDated: '2022-05-18T16:52:04.171812',
        leaveEndDated: '2022-05-20T16:52:04.172227',
        membershipStatus: 3,
        membershipContractEndDated: '2022-05-19T16:52:04.173938',
        leaveReason: '其他',
        xFactor: 'NA',
        contactDescription: '2019.12.09 其他：會去Curves拜訪',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 無效名單
const memberInvalids = [
    {
        workoutFrequency: '1-1-1-1',
        membershipContinueMonth: 1,
        membershipQuitSendDated: '2022-05-19T16:52:28.315622',
        contractEndDated: '2022-05-19T15:52:28.315624',
        membershipQuitReason: '其他',
        xFactor: 'NA',
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 生日名單
const memberBirthdayList = [
    {
        workoutFrequency: '1-1-1-1',
        xFactor: 'NA',
        birthday: '2022-05-19T16:59:36.920196',
        address: '新北市',
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 未量身名單
const memberNonMeasures = [
    {
        measureDay: 1,
        lastMeasureDate: '2022-05-19T00:00:00',
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 已量身名單
const memberMeasureList = [
    {
        measureDay: 1,
        lastMeasureDate: '2022-05-19T00:00:00',
        coachName: '面談教練_1',
        interviewContent: '面談內容_1',
        xFactor: '會員激勵因子_1',
        dateItem: '約定事項_1',
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 百T名單
const memberHundredShirts = [
    {
        workoutFrequency: '0-0-0-0',
        totalWorkoutCount: 1,
        hundredWorkoutDate: '2022/5/19 下午5:00:12',
        apparelType: '1',
        apparelSize: 'XXXL',
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 新會員名單
const newMembers = [
    {
        membershipContractSendDate: '2022-05-19T17:03:15.585691',
        firstWorkoutReserveDate: '2022-05-19T17:03:15.585836',
        secondWorkoutReserveDate: '2022-05-20T17:03:15.586238',
        thirdWorkoutReserveDate: '2022-05-21T17:03:15.586871',
        sourceType: 'CP去電',
        firstWorkoutDateTime: '2022-05-19T17:03:15.587488',
        secondWorkoutDateTime: '2022-05-20T17:03:15.587709',
        thirdWorkoutDateTime: '2022-05-21T17:03:15.587905',
        fourWorkoutDateTime: '2022-05-22T17:03:15.588176',
        fiveWorkoutDateTime: '2022-05-23T17:03:15.589432',
        twelveWeekWorkoutFrequency: '1111-1111-1111',
        threeMonthIsMeasure: [true, false, true],
        proteinOrderStatus: '未訂購',
        probioticsOrderStatus: '未訂購',
        isGiftReFreshMemberThirty: true,
        isGiftReFreshMemberNinety: false,
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 租櫃名單
const memberRentCabinets = [
    {
        rentCabinetStartDate: '2022-05-18T17:03:34.238189',
        rentCabinetEndDate: '2022-05-20T17:03:34.238281',
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 蛋白&益菌名單
const memberHealthyChoics = [
    {
        proteinOrderStatus: '訂購中',
        proteinReason: '',
        probioticsOrderStatus: '退訂',
        probioticsReason: '太貴',
        membershipStatus: '會籍到期',
        workoutFrequency: '1-1-1-1',
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];
// 欠費名單
const memberArrears = [
    {
        membershipStatus: '正常',
        arrears: 1,
        memberArrearsItems: [
            {
                arrearsID: 1,
                arrearsDate: '2022-05-18T00:00:00',
                arrearsItem: '月費批次扣款',
                arrearsDetail: '2021年九月月費',
                receivables: 1,
                paid: 0,
                errorReason: '卡片過期',
                coachName: '教練_1',
            },
            {
                arrearsID: 1,
                arrearsDate: '2022-05-18T00:00:00',
                arrearsItem: '月費批次扣款',
                arrearsDetail: '2021年九月月費',
                receivables: 1,
                paid: 0,
                errorReason: '卡片過期',
                coachName: '教練_1',
            },
        ],
        contactDescription: '聯繫紀錄1',
    },
];
// 月費收費名單
const memberMonthCharges = [
    {
        membershipStatus: '暫停',
        receivedPaymentStatus: '應收款',
        membershipType: '新約',
        paymentType: '銀行自動扣款',
        creditCaredValidDate: '1234',
        bankAccount: '12345',
        monthPrice: 1380,
        receivables: 1380,
        paid: 0,
        invoiceNumber: 'ZS46729832',
        contactDescription: '聯繫紀錄1',
        memberID: 1,
        memberNo: 'M000000001',
        memberName: 'MemberName1',
        nickName: 'NickName1',
        cellPhone: 'CellPhone1',
    },
];

const filterMap = {
    1: { memberWorkoutTimes },
    2: { memberRenews },
    3: { memberOfficialLeaves },
    4: { memberInvalids },
    5: { memberBirthdayList },
    6: { memberNonMeasures },
    7: { memberMeasureList },
    8: { memberHundredShirts },
    9: { newMembers },
    10: { memberRentCabinets },
    11: { memberHealthyChoics },
    12: { memberArrears },
    13: { memberMonthCharges },
};
