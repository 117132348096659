import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, MenuItem, SelectField } from '../../../components';
import { UploadBox, UploadFeedbackBox } from '../../components';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import useCabinetApi from '../../../apis/useCabinetApi';
import { refIsRequiredError } from '../../../util/utils';

const getInitFeedbackData = () => {
    return {
        isShow: false,
        isError: false,
        data: {},
    };
};

const ImportCabinetDialog = (props) => {
    const { className, open, refresh, onClose } = props;
    const { postCabinetExcelApi, getCabinetExcelTemplateApi, getCabinetOptionsApi } = useCabinetApi();
    const [feedbackData, setFeedBackData] = useState(getInitFeedbackData());
    const [options, setOptions] = useState({});
    const { employeeOption } = options;
    const uploadFileRef = useRef();
    const employeeRef = useRef();

    const doCabinetOptionsApi = async () => {
        let res = await getCabinetOptionsApi();
        if (res) {
            setOptions(res);
        }
    };

    const doCabinetExcelApi = async (formData, params) => {
        let res = await postCabinetExcelApi(formData, params);
        if (res) {
            if (res.isError) {
                const { isError, result } = res;
                setFeedBackData({
                    isShow: true,
                    isError,
                    data: result,
                });
            } else {
                if (typeof refresh === 'function') refresh();
                if (typeof onClose === 'function') onClose();
            }
        }
    };

    const getParams = () => {
        let result = {};
        if (employeeRef.current && employeeRef.current.getResult) {
            result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
        }
        return result;
    };

    const checkError = () => refIsRequiredError(employeeRef);

    const handleFileChange = (file) => {
        if (file) uploadFileRef.current = file;
    };

    const handleSubmit = () => {
        const isError = checkError();
        if (!isError) {
            const formData = new FormData();
            const { employeeID } = getParams();
            if (uploadFileRef.current) {
                let file = uploadFileRef.current;
                formData.append('file', file, file.name);
                doCabinetExcelApi(employeeID, formData);
            }
        }
    };

    useEffect(() => {
        doCabinetOptionsApi();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (open) {
            setFeedBackData(getInitFeedbackData());
        }
    }, [open]);

    if (!open) return null;

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>批次新增置物櫃</GradientColorHeader>
            <DialogContent>
                {!feedbackData.isError ? (
                    <UploadBox className={'import-ach-box'} onFileChange={handleFileChange} fileExtension={['xlsx']} />
                ) : (
                    <UploadFeedbackBox
                        isShow={feedbackData.isShow}
                        className={'import-ach-box'}
                        isError={feedbackData.isError}
                        {...feedbackData.data}
                        onCononConfirmfirm={onClose}
                        onDownload={onClose}
                    />
                )}
                <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                    {Array.isArray(employeeOption) &&
                        employeeOption
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </DialogContent>
            {!feedbackData.isShow && (
                <DialogActions>
                    <Button className={'mr-auto'} variant="outlined" onClick={getCabinetExcelTemplateApi}>
                        下載excel範本
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        確認匯入
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ImportCabinetDialog;
