import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Button, Form, MenuItem, SelectField } from '../../../../components';
import GradientColorHeader from '../../../components/dialog/GradientColorHeader';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { refIsRequiredError } from '../../../../util/utils';
import { useMemberProfileApi } from '../../../../apis/useMemberProfileApi';

const HundredTShirtSizeDialogContent = forwardRef((props, ref) => {
    const { defaultValue, resource } = props;
    const hundredTShirtSizeRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    hundredTShirtSize: hundredTShirtSizeRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(hundredTShirtSizeRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <SelectField label="百Ｔ尺寸" defaultValue={defaultValue} ref={hundredTShirtSizeRef} displayEmpty fullWidth required>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {Array.isArray(resource) &&
                            resource
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const HundredTShirtSizeDialog = (props) => {
    const { className, open, memberID, data, resource, onClose, refresh } = props;
    const { postMemberProfileHundredTShirtApi } = useMemberProfileApi();
    const contentRef = useRef();

    const doMemberProfileHundredTShirtApi = async (memberID, params) => {
        let res = await postMemberProfileHundredTShirtApi(memberID, params);
        if (res) {
            refresh && refresh(memberID);
            onClose();
        }
    };
    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = contentRef.current && contentRef.current.getResult();
            doMemberProfileHundredTShirtApi(memberID, params);
        }
    };
    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-40rem' }} open={open} fullWidth>
            <Form onSubmit={handleSubmit}>
                <GradientColorHeader onClose={onClose}>編輯百Ｔ尺寸</GradientColorHeader>
                <HundredTShirtSizeDialogContent ref={contentRef} defaultValue={data} resource={resource.hundredTShirtSizeTextOptions} />
                <DialogActions>
                    <Button type={'submit'} variant="contained">
                        確認送出
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default HundredTShirtSizeDialog;
