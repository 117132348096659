import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Inbox = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M22.185,9.528,18.057,3.335A1.875,1.875,0,0,0,16.5,2.5H6a1.875,1.875,0,0,0-1.56.835L.315,9.528A1.875,1.875,0,0,0,0,10.568v5.057A1.875,1.875,0,0,0,1.875,17.5h18.75A1.875,1.875,0,0,0,22.5,15.625V10.568a1.875,1.875,0,0,0-.315-1.04ZM6.338,5h9.824L19.5,10H14.688l-1.25,2.5H9.063L7.813,10H3Z"
                transform="translate(0.75 1.999)"
            />
        </SvgIcon>
    );
});

export default Inbox;
