import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { Box, Card, Checkbox, FormControlLabel, List, ListItemText, Typography } from '../../../components';
import clsx from 'clsx';
import { displayDateText } from '../../../util/moment';
import { formatCurrencyFn, isEmpty } from '../../../util/utils';
import { OrderItemsTable } from '../tables';
import { Grid } from '@mui/material';
import useForkRef from '@util/hook/useForkRef';

const CartHeader = React.forwardRef((props, ref) => {
    const { data = {}, updateOrderTotal, isPick = false } = props;
    const { orderID, orderNo, orderDate } = data;
    const confirmRef = useRef();

    const handleChange = () => {
        updateOrderTotal && updateOrderTotal();
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return confirmRef.current.checked && orderID;
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <Box className={'cart-header'}>
            {isPick && (
                <FormControlLabel
                    className="control"
                    control={<Checkbox inputRef={(el) => (confirmRef.current = el)} defaultChecked={true} />}
                    onChange={handleChange}
                />
            )}

            <Typography component={'h4'} className={'credit-order-card-title'}>
                {`訂單編號：${orderNo}`}
                {orderDate && <span className={'font-color-3'}>{`於 ${displayDateText(orderDate)} 建立`}</span>}
            </Typography>
        </Box>
    );
});

export const CartContent = (props) => {
    const { className, data = {}, showOrderPrice = false } = props;
    const { payablePrice, paidPrice, items } = data;
    return (
        <Box className={clsx('cart-content', className)}>
            <OrderItemsTable className={'theme-gray'} data={items} />
            {showOrderPrice && (
                <Grid spacing={3} container>
                    <Grid className={'ml-auto'} xs={4} item>
                        <List>
                            <ListItemText className={'cart-info total'} secondary={'應付金額'} primary={formatCurrencyFn(payablePrice)} />
                            <ListItemText className={'cart-info total'} secondary={'已付金額'} primary={formatCurrencyFn(paidPrice)} />
                        </List>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

// 欠款訂單
const CreditOrderCard = React.forwardRef((props, ref) => {
    const { className, data, updateOrderTotal } = props;
    const handleRef = React.useRef(null);
    const headerRef = useForkRef(handleRef, ref);

    return (
        <Card className={clsx('cart', className)}>
            <Card.CardContent>
                <CartHeader ref={headerRef} data={data} updateOrderTotal={updateOrderTotal} isPick />
                <CartContent data={data} showOrderPrice />
            </Card.CardContent>
        </Card>
    );
});
// 查看訂單，用於重開發票
export const ReviewOrderCard = (props) => {
    const { className, data } = props;

    return (
        <Card className={clsx('cart', className)}>
            <Card.CardContent>
                <CartHeader data={data} />
                <CartContent data={data} />
            </Card.CardContent>
        </Card>
    );
};

// 欠款訂單列表
const CreditOrderList = React.forwardRef((props, ref) => {
    const { orders, updateOrderTotal } = props;
    const ordersRef = useRef([]);

    const calculateTotalData = () => {
        let pickOrder = ordersRef.current.filter((item) => item).map((item) => item.getResult());
        if (!Array.isArray(orders)) return 0;
        let totalData = orders.reduce(
            (data, currentObj) => {
                let price = 0,
                    paidPrice = 0;
                if (pickOrder.includes(currentObj.orderID)) {
                    price = parseInt(currentObj.payablePrice);
                    paidPrice = parseInt(currentObj.paidPrice);
                }
                return {
                    total: data.total + price,
                    paidPrice: data.paidPrice + paidPrice,
                };
            },
            {
                total: 0,
                paidPrice: 0,
            }
        );

        return totalData;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    checkoutOrderIDs: ordersRef.current.map((item) => item.getResult()).filter((item) => item),
                };
            },
            getTotalData: () => {
                return calculateTotalData();
            },
        }),
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        if (!isEmpty(orders)) {
            if (typeof updateOrderTotal === 'function') {
                updateOrderTotal();
            }
        }
        // eslint-disable-next-line
    }, [orders]);

    return (
        <React.Fragment>
            <Typography className={'font-weight-medium mb-1'} variant={'h5'}>
                請勾選要結帳的過往訂單
            </Typography>
            {Array.isArray(orders) &&
                orders.map((item, i) => {
                    return (
                        <CreditOrderCard
                            key={item.orderID}
                            ref={(el) => (ordersRef.current[i] = el)}
                            className={'mb-2'}
                            data={item}
                            updateOrderTotal={updateOrderTotal}
                        />
                    );
                })}
        </React.Fragment>
    );
});

export default CreditOrderList;
