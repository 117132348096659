import React from 'react';
import { Card } from '@common/components/';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { displayDateText } from '@util/moment';

const OrderEffectStateSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { effectNo, effectStatusText, effectDate, effectCreateDate, employeeName } = data;
    return (
        <React.Fragment>
            <Card.CardHeader title="變更單資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'變更單編號'}>{effectNo}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'生效日期'}>{displayDateText(effectDate)}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'建單日期'}>{displayDateText(effectCreateDate)}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'變更單狀態'}>{effectStatusText}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'建單擔當'}>{employeeName}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

export default OrderEffectStateSectionByRead;
