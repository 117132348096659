import React from 'react';
import clsx from 'clsx';
import { Button, Typography } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import useInBodyApi from '@apis/useInBodyApi';
import useMessageDialog from '@util/hook/useMessageDialog';

const InBodyInterviewCancelMeasureDialog = (props) => {
    const { className, open, onClose, refresh } = props;
    const { postCancelMeasureApi } = useInBodyApi();
    const setMessageDialog = useMessageDialog();

    const handleConfirm = () => {
        onClose();
        setMessageDialog({
            open: true,
            title: '警告',
            DialogActionsProps: { className: 'flex-y-flex-start' },
            buttonColor: 'success',
            onAsyncConfirm: (_e, _onClose) => {
                doCancelMeasureApi(_onClose);
            },
            MsgComp: () => (
                <React.Fragment>
                    <Typography paragraph>請再次確認是否要取代正在量身會員的紀錄</Typography>
                </React.Fragment>
            ),
        });
    };

    const doCancelMeasureApi = async (_onClose) => {
        let res = await postCancelMeasureApi();
        if (res) {
            _onClose();
            refresh && refresh();
        }
    };

    if (!open) return null;
    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-40rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>警告</GradientColorHeader>
            <DialogContent>
                <Typography paragraph>有人正在量身，確定要取消當前的量身嗎?</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="error" variant="contained" onClick={onClose}>
                    否
                </Button>
                <Button color="success" variant="contained" onClick={handleConfirm}>
                    是
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InBodyInterviewCancelMeasureDialog;
