import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import useEmployees from '@icoach/employees/compoments/useEmployees';
import Tablenization from '@icoach/components/Tablenization';
import { Button } from '@common/components/';
import useEmployeeCheckInApi from '@apis/useEmployeeCheckInApi';
import { parseDate } from '@util/moment';

const rowsLabel = [
    {
        cellKey: 'recordDate',
        headerLabel: '日期',
        formatCell: (value) => parseDate(value),
    },
    {
        cellKey: 'checkInTime',
        headerLabel: '上班打卡時間',
    },
    {
        cellKey: 'checkOutTime',
        headerLabel: '下班打卡時間',
    },
];

const EmployeesInformationsClockInTable = React.forwardRef((props, ref) => {
    const { sourceData, refresh, searchParams } = useEmployees();
    const { employeeID } = useParams();
    const { getEmployeesClockInExcelApi } = useEmployeeCheckInApi();
    const labelTitle = useRef(rowsLabel);
    const rowsData = _.isEmpty(sourceData) ? [] : sourceData;

    const handleDownloadClick = () => {
        postDownloadExcel(employeeID, searchParams.current);
    };

    const postDownloadExcel = async (empID, params) => {
        await getEmployeesClockInExcelApi(empID, params);
    };

    useEffect(
        () => {
            refresh.current();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Button
                onClick={handleDownloadClick}
                variant="outlined"
                className="mb-2"
                color="success"
                disabled={_.isEmpty(rowsData)}
            >
                下載Excel
            </Button>
            <Tablenization>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
});

export default EmployeesInformationsClockInTable;
