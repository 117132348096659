import React, { useState, useRef } from 'react';
import { NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Box, Button, Card, PrivateRoute, Tab, Tabs } from '@common/components/';
import {
    RENT_CABINET_DOCUMENT_CREATE_URL,
    RENT_CABINET_DOCUMENT_URL,
    RENT_CABINET_CABINETS_URL,
    rentCabinetRouter as routers,
} from '@icoach/router/MembersRouter';
import RentCabinetDialogBox from '@icoach/rentCabinet/dialog/RentCabinetDialogBox';

const tabContentType = {
    rental: 'document',
    cabinets: 'cabinets',
};

const RentCabinetPage = (props) => {
    const { className } = props;
    const history = useHistory();
    const [tabs, setTabs] = useState('');
    const dialogRef = useRef();
    const refreshRef = useRef();

    const handleTabChange = (e, value) => {
        setTabs(value);
    };

    return (
        <Card className={clsx(className, 'tabs-content-layout', 'rent-cabinet-page')}>
            <Box component={'header'} className={'page-header'}>
                <Tabs value={tabs} onChange={handleTabChange}>
                    <Tab label={'租約管理'} className={'py-3'} value={tabContentType.rental} component={NavLink} to={RENT_CABINET_DOCUMENT_URL} />
                    <Tab label={'置物櫃管理'} className={'py-3'} value={tabContentType.cabinets} component={NavLink} to={RENT_CABINET_CABINETS_URL} />
                </Tabs>
                {tabs === tabContentType.cabinets && (
                    <Box className="btn-group">
                        <Button variant={'outlined'} onClick={() => dialogRef.current && dialogRef.current.openCreateCabinetDialog({}, refreshRef.current)}>
                            新增置物櫃
                        </Button>
                        <Button variant="outlined" onClick={() => dialogRef.current && dialogRef.current.openImportCabinetDialog({}, refreshRef.current)}>
                            批次新增
                        </Button>
                    </Box>
                )}
                {tabs === tabContentType.rental && (
                    <Box className="btn-group">
                        <Button variant="outlined" onClick={() => history.push(RENT_CABINET_DOCUMENT_CREATE_URL)}>
                            新增租約
                        </Button>
                    </Box>
                )}
            </Box>
            <Card.CardContent className="pt-4 px-4 pb-3">
                {
                    <Switch>
                        {routers.map(({ description, exact, path, as, ...other }) => {
                            return (
                                <PrivateRoute
                                    {...other}
                                    key={description}
                                    exact={exact}
                                    path={path}
                                    as={as}
                                    setTabs={setTabs}
                                    dialogRef={dialogRef}
                                    refreshRef={refreshRef}
                                />
                            );
                        })}
                        <Route path="/">
                            <Redirect to={RENT_CABINET_DOCUMENT_URL} />
                        </Route>
                    </Switch>
                }
                <RentCabinetDialogBox ref={dialogRef} />
            </Card.CardContent>
        </Card>
    );
};

export default RentCabinetPage;
