import React, { useImperativeHandle, useState, useRef } from 'react';
import { ConfirmDeleteDocDialog } from '@icoach/documents/membership/dialog';
import MembershipTransferOutMoveOutDialog from '@icoach/documents/membership/transferOut/MembershipTransferOutMoveOutDialog';

// table 重複元件使用(單一來源彈跳視窗)，如果只有一個元件在使用就不用寫在這
const MembershipTransferOutDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState(null);
    const [sourceData, setSourceData] = useState(null);
    const refreshRef = useRef(null);
    const doRefresh = () => {
        refreshRef.current && typeof refreshRef.current === 'function' && refreshRef.current();
    };

    const handleCloseDialog = () => {
        setOpenDialog(null);
        setSourceData(null);
        refreshRef.current = null;
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                openConfirmDeleteDocDialog: (targetData, _refreshFn = () => {}) => {
                    refreshRef.current = _refreshFn;
                    setSourceData(targetData);
                    setOpenDialog('deleteDocumentTransferOut');
                },
                openMembershipTransferOutMoveOutDialog: (targetData, _refreshFn = () => {}) => {
                    refreshRef.current = _refreshFn;
                    setSourceData(targetData);
                    setOpenDialog('membershipTransferOutMoveOut');
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <ConfirmDeleteDocDialog
                open={openDialog === 'deleteDocumentTransferOut'}
                title={sourceData?.isTemporary ? '確認刪除暫存移出申請書' : '確認刪除移出申請書'}
                onClose={handleCloseDialog}
                data={sourceData}
                deleteDocApi={sourceData?.doDeleteApi ?? (() => {})}
                refresh={doRefresh}
            />
            <MembershipTransferOutMoveOutDialog
                open={openDialog === 'membershipTransferOutMoveOut'}
                sourceData={sourceData}
                onClose={handleCloseDialog}
                refresh={doRefresh}
            />
        </React.Fragment>
    );
});

export default MembershipTransferOutDialogBox;
