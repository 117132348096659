import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const NotCall = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#51a69e', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M16.5,3C14.015,3,12,4.6,12,6.562a3.382,3.382,0,0,0,1.822,2.857,3.571,3.571,0,0,1-.932,1.77,4.412,4.412,0,0,0,2.882-1.116,5.619,5.619,0,0,0,.728.051c2.485,0,4.5-1.6,4.5-3.562S18.985,3,16.5,3ZM14.391,7.265a.7.7,0,1,1,.7-.7.7.7,0,0,1-.7.7Zm2.109,0a.7.7,0,1,1,.7-.7.7.7,0,0,1-.7.7Zm2.109,0a.7.7,0,1,1,.7-.7.7.7,0,0,1-.7.7Z" />
            <path d="M18.133,14.261a1.392,1.392,0,0,0-2.106,0c-.493.488-.987.976-1.47,1.474a.292.292,0,0,1-.408.075c-.319-.175-.658-.313-.966-.5A15.232,15.232,0,0,1,9.5,11.947,8.673,8.673,0,0,1,8.175,9.831a.3.3,0,0,1,.074-.388c.494-.477.977-.965,1.459-1.453A1.4,1.4,0,0,0,9.7,5.831c-.382-.388-.769-.775-1.157-1.157-.4-.4-.79-.8-1.193-1.194a1.4,1.4,0,0,0-2.112,0c-.493.488-.976.992-1.475,1.47a2.41,2.41,0,0,0-.753,1.623,6.952,6.952,0,0,0,.531,2.955,18.129,18.129,0,0,0,3.183,5.306,19.665,19.665,0,0,0,6.531,5.109,9.368,9.368,0,0,0,3.618,1.05,2.662,2.662,0,0,0,2.276-.865c.424-.472.9-.9,1.347-1.353a1.406,1.406,0,0,0,.011-2.143c-.8-.8-1.586-1.581-2.377-2.371Zm0,0" />
        </SvgIcon>
    );
});

export default NotCall;
