import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, Grid, DialogActions } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { SelectField, MenuItem, Button, TextField } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useGiftItemApi from '@apis/useGiftItemApi';

const GiftDialog = React.forwardRef((props, ref) => {
    const { open = false, onClose, sourceData: giftData, refresh, onSubmitForm } = props;
    const { giftID = 0, giftName = '', giftPrice = 0, giftSpecifications = '無', giftDescription = '' } = giftData || {};
    const [options, setOptions] = useState({});
    const { giftItemSpecificationsOption: giftSpecificationsOption = [] } = options || {};
    const { getGiftItemOptionsApi, postGiftItemApi, putGiftItemApi } = useGiftItemApi();
    const giftNameRef = useRef(null);
    const giftPriceRef = useRef(null);
    const giftSpecificationsRef = useRef(null);
    const giftDescriptionRef = useRef(null);
    const isCreate = giftID === 0;

    const getParams = () => {
        let result = { giftID };

        if (giftNameRef.current && giftNameRef.current.getResult) {
            Object.assign(result, { giftName: giftNameRef.current.getResult() });
        }

        if (giftPriceRef.current && giftPriceRef.current.getResult) {
            Object.assign(result, { giftPrice: giftPriceRef.current.getResult() || 0 });
        }

        if (giftSpecificationsRef.current && giftSpecificationsRef.current.getResult) {
            Object.assign(result, { giftSpecifications: giftSpecificationsRef.current.getResult() });
        }

        if (giftDescriptionRef.current && giftDescriptionRef.current.getResult) {
            Object.assign(result, { giftDescription: giftDescriptionRef.current.getResult() });
        }

        return result;
    };

    const handleGiftOptionsApi = async () => {
        let resp = await getGiftItemOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    const handleGiftApi = async (params) => {
        const { giftID } = params;
        let resp;

        if (isCreate) {
            resp = await postGiftItemApi(params);
        } else {
            resp = await putGiftItemApi(giftID, params);
        }

        if (resp) {
            if (typeof refresh === 'function') refresh({ resp, params });
            if (typeof onClose === 'function') onClose();
        }
    };

    const handleSubmitForm = (e) => {
        const isError = refIsRequiredError(giftNameRef, giftPriceRef, giftSpecificationsRef, giftDescriptionRef);
        const params = getParams();
        if (!isError) {
            if (typeof onSubmitForm === 'function') {
                onSubmitForm(e, { isError, params });
            } else {
                handleGiftApi(params);
            }
        }
    };

    useEffect(
        () => {
            if (open) {
                handleGiftOptionsApi();
            }
        },
        // eslint-disable-next-line
        [open],
    );

    if (!open) return null;

    return (
        <Dialog open={open}>
            <GradientColorHeader onClose={onClose}>{`${isCreate ? '新增' : '編輯'}贈品`}</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={12} item>
                        <TextField key={giftName} ref={giftNameRef} label={'贈品名稱'} defaultValue={giftName} fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField key={giftPrice} ref={giftPriceRef} maskType={'MONEY'} label={'單價'} defaultValue={giftPrice} fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <SelectField
                            key={giftSpecifications}
                            ref={giftSpecificationsRef}
                            label={'規格'}
                            defaultValue={giftSpecifications}
                            displayEmpty
                            fullWidth
                            required
                        >
                            {giftSpecificationsOption.map(({ value, text }) => (
                                <MenuItem key={value} value={value}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            key={giftDescription}
                            ref={giftDescriptionRef}
                            label={'描述'}
                            defaultValue={giftDescription}
                            minRows={3}
                            multiline
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmitForm}>
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default GiftDialog;
