import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import useShowLoading from '@util/hook/useShowLoading';
import { useSnackbar } from 'notistack';

const GET_MEMBER_WORKOUT_TIME_LINE_API = (memberID) => `/api/members/${memberID}/workout-timeline/`;
const GET_MEMBER_WORKOUT_CALENDAR_API = (memberID) => `/api/members/${memberID}/workout-calendar/`;
const DELETE_MEMBER_MEMBERWORKOUTID_API = `/api/members/workout/`;

const useMemberWorkoutApi = () => {
    const Ajax = useAjax();
    const showLoading = useShowLoading();
    const { enqueueSnackbar } = useSnackbar();

    // 依月份顯示運動次數(以目前時間往前分頁)
    const getMemberWorkoutTimeLineApi = useCallback(
        async (memberID, params) => {
            const resp = await Ajax.get(GET_MEMBER_WORKOUT_TIME_LINE_API(memberID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 顯示指定月曆日期兩個月的運動次數
    const getMemberWorkoutCalendarApi = useCallback(
        async (memberID, params) => {
            const resp = await Ajax.get(GET_MEMBER_WORKOUT_CALENDAR_API(memberID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 依月份顯示運動次數(以目前時間往前分頁)
    // 顯示指定月曆日期兩個月的運動次數
    const getInitMemberWorkoutApi = useCallback(
        async (memberID, params) => {
            let result = {};
            showLoading(true);
            const timeLine = await Ajax.get(GET_MEMBER_WORKOUT_TIME_LINE_API(memberID), params);
            const calendar = await Ajax.get(GET_MEMBER_WORKOUT_CALENDAR_API(memberID), params);
            if (timeLine) result = Object.assign(result, { timeLine });
            if (calendar) result = Object.assign(result, { calendar });
            showLoading(false);
            return result;
        },
        // eslint-disable-next-line
        []
    );

    const deleteWorkoutRecordApi = useCallback(
        async (params) => {
            const resp = Ajax.delete(DELETE_MEMBER_MEMBERWORKOUTID_API, params);
            if (resp) {
                enqueueSnackbar('刪除紀錄成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getMemberWorkoutTimeLineApi,
        getMemberWorkoutCalendarApi,
        getInitMemberWorkoutApi,
        deleteWorkoutRecordApi,
    };
};

export default useMemberWorkoutApi;
