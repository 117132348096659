import React, { useRef, useImperativeHandle } from 'react';
import { Grid } from '@mui/material';
import { TextField, SelectField, MenuItem } from '@common/components/';
import { refIsRequiredError } from '@util/utils';

const ProspectFormBox = React.forwardRef((props, ref) => {
    const { introducer: introducerProps = '', originValue: originValueProps = null, originOptin = [], demandOption = [] } = props;
    const nameRef = useRef(null);
    const phoneRef = useRef(null);
    const originRef = useRef(null);
    const neededRef = useRef(null);
    const referRef = useRef(null);
    const noteRef = useRef(null);
    useImperativeHandle(
        ref,
        () => ({
            isError: () => refIsRequiredError(nameRef, phoneRef, originRef, neededRef, referRef, noteRef),
            getResult: () => {
                return {
                    name: nameRef.current.getResult(),
                    cellPhone: phoneRef.current.getResult(),
                    requiredType: neededRef.current.getResult(),
                    sourceType: originRef.current.getResult(),
                    introducer: referRef.current.getResult(),
                    notes: noteRef.current.getResult(),
                };
            },
            getFullName: () => {
                const { isError: err, getResult: res } = nameRef.current;
                return err() ? '' : res();
            },

            getMulti: () => {
                const { isError: nameErr, getResult: nameRes } = nameRef.current;
                const { isError: oriErr, getResult: oriRes } = originRef.current;
                return nameErr() && oriErr() ? '' : { sourceType: oriRes(), name: nameRes() };
            },
        }),
        // eslint-disable-next-line
        [],
    );
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <TextField label="姓名" ref={nameRef} required fullWidth />
            </Grid>
            <Grid item xs={4}>
                <TextField label="手機" type="tel" ref={phoneRef} maskType={'MOB'} required fullWidth />
            </Grid>
            <Grid item xs={4}>
                {originOptin.length > 0 && (
                    <SelectField ref={originRef} label={'來源'} defaultValue={originValueProps} displayEmpty fullWidth required>
                        <MenuItem disabled value="">
                            請選擇
                        </MenuItem>
                        {originOptin.map(({ text, value, disabled }) => (
                            <MenuItem value={value} key={text} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                )}
            </Grid>
            <Grid item xs={4}>
                {demandOption.length > 0 && (
                    <SelectField ref={neededRef} label={'需求'} name={'needed'} displayEmpty fullWidth>
                        <MenuItem disabled value="">
                            請選擇
                        </MenuItem>
                        {demandOption.map(({ text, value, disabled }) => (
                            <MenuItem value={value} key={text} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                )}
            </Grid>
            <Grid item xs={4}>
                <TextField defaultValue={introducerProps} label="介紹人" ref={referRef} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField label="備註" rows={2} ref={noteRef} fullWidth multiline />
            </Grid>
        </Grid>
    );
});

export default ProspectFormBox;
