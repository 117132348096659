import React from 'react';
import PropTypes from 'prop-types';
import useForkRef from '../../util/hook/useForkRef';
import useEventCallback from '../../util/hook/useEventCallback';
import { ownerDocument } from '../../util/utils';

//  eventProp: 'onClick' | 'onMouseDown' | 'onMouseUp' | 'onTouchStart' | 'onTouchEnd';
//  return 'click' | 'mousedown' | 'mouseup' | 'touchstart' | 'touchend'
const mapEventPropToEvent = (eventProp) => {
    return eventProp.substring(2).toLowerCase();
};

// event: MouseEvent
// doc: Document
const clickedRootScrollbar = (event, doc) => {
    return doc.documentElement.clientWidth < event.clientX || doc.documentElement.clientHeight < event.clientY;
};

const ClickAwayListener = (props) => {
    const {
        children,
        disableReactTree = false,
        mouseEvent = 'onClick',
        onClickAway,
        touchEvent = 'onTouchEnd',
    } = props;
    const movedRef = React.useRef(false);
    const nodeRef = React.useRef(null);
    const activatedRef = React.useRef(false);
    const syntheticEventRef = React.useRef(false);

    React.useEffect(() => {
        // Ensure that this component is not "activated" synchronously.
        // https://github.com/facebook/react/issues/20074
        setTimeout(() => {
            activatedRef.current = true;
        }, 0);
        return () => {
            activatedRef.current = false;
        };
    }, []);

    const handleRef = useForkRef(children.ref, nodeRef);

    // The handler doesn't take event.defaultPrevented into account:
    //
    // event.preventDefault() is meant to stop default behaviors like
    // clicking a checkbox to check it, hitting a button to submit a form,
    // and hitting left arrow to move the cursor in a text input etc.
    // Only special HTML elements have these default behaviors.
    // event: MouseEvent | TouchEvent
    const handleClickAway = useEventCallback((event) => {
        // Given developers can stop the propagation of the synthetic event,
        // we can only be confident with a positive value.
        const insideReactTree = syntheticEventRef.current;
        syntheticEventRef.current = false;

        const doc = ownerDocument(nodeRef.current);

        // 1. IE11 support, which trigger the handleClickAway even after the unbind
        // 2. The child might render null.
        // 3. Behave like a blur listener.
        if (!activatedRef.current || !nodeRef.current || ('clientX' in event && clickedRootScrollbar(event, doc))) {
            return;
        }

        // Do not act if user performed touchmove
        if (movedRef.current) {
            movedRef.current = false;
            return;
        }

        let insideDOM;

        // If not enough, can use https://github.com/DieterHolvoet/event-propagation-path/blob/master/propagationPath.js
        if (event.composedPath) {
            insideDOM = event.composedPath().indexOf(nodeRef.current) > -1;
        } else {
            insideDOM =
                !doc.documentElement.contains(
                    // @ts-expect-error returns `false` as intended when not dispatched from a Node
                    event.target
                ) ||
                nodeRef.current.contains(
                    // @ts-expect-error returns `false` as intended when not dispatched from a Node
                    event.target
                );
        }

        if (!insideDOM && (disableReactTree || !insideReactTree)) {
            onClickAway(event);
        }
    });

    // Keep track of mouse/touch events that bubbled up through the portal.
    // event : React.SyntheticEvent
    const createHandleSynthetic = (handlerName) => (event) => {
        syntheticEventRef.current = true;
        const childrenPropsHandler = children.props[handlerName];
        if (childrenPropsHandler) {
            childrenPropsHandler(event);
        }
    };

    const childrenProps = { ref: handleRef };

    if (touchEvent !== false) {
        childrenProps[touchEvent] = createHandleSynthetic(touchEvent);
    }

    React.useEffect(() => {
        if (touchEvent !== false) {
            const mappedTouchEvent = mapEventPropToEvent(touchEvent);
            const doc = ownerDocument(nodeRef.current);

            const handleTouchMove = () => {
                movedRef.current = true;
            };

            doc.addEventListener(mappedTouchEvent, handleClickAway);
            doc.addEventListener('touchmove', handleTouchMove);

            return () => {
                doc.removeEventListener(mappedTouchEvent, handleClickAway);
                doc.removeEventListener('touchmove', handleTouchMove);
            };
        }

        return undefined;
    }, [handleClickAway, touchEvent]);

    if (mouseEvent !== false) {
        childrenProps[mouseEvent] = createHandleSynthetic(mouseEvent);
    }

    React.useEffect(() => {
        if (mouseEvent !== false) {
            const mappedMouseEvent = mapEventPropToEvent(mouseEvent);
            const doc = ownerDocument(nodeRef.current);

            doc.addEventListener(mappedMouseEvent, handleClickAway);

            return () => {
                doc.removeEventListener(mappedMouseEvent, handleClickAway);
            };
        }

        return undefined;
    }, [handleClickAway, mouseEvent]);

    return <React.Fragment>{React.cloneElement(children, childrenProps)}</React.Fragment>;
};

ClickAwayListener.propTypes = {
    /**
     * The wrapped element.
     */
    children: PropTypes.any,
    /**
     * If `true`, the React tree is ignored and only the DOM tree is considered.
     * This prop changes how portaled elements are handled.
     * @default false
     */
    disableReactTree: PropTypes.bool,
    /**
     * The mouse event to listen to. You can disable the listener by providing `false`.
     * @default 'onClick'
     */
    mouseEvent: PropTypes.oneOf(['onClick', 'onMouseDown', 'onMouseUp', false]),
    /**
     * Callback fired when a "click away" event is detected.
     */
    onClickAway: PropTypes.func.isRequired,
    /**
     * The touch event to listen to. You can disable the listener by providing `false`.
     * @default 'onTouchEnd'
     */
    touchEvent: PropTypes.oneOf(['onTouchEnd', 'onTouchStart', false]),
};

export default ClickAwayListener;
