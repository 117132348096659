import React from 'react';
import {
    RadioButtonChecked as RadioButtonCheckedIcon,
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '../icons/SvgIcon/';
import clsx from 'clsx';

const staticClass = `radio-button-icon`;
const staticClassUnchecked = `radio-button-icon-unchecked`;
const staticClassIconDot = `radio-button-icon-icondot`;

const RadioButtonIconBackground = (props) => {
    const { fontSize, checked, ...other } = props;
    let classNames = staticClassUnchecked;
    const defaultProperty = {
        className: clsx(classNames),
        ...other,
    };
    return <RadioButtonUncheckedIcon {...defaultProperty} />;
};

const RadioButtonIconDot = (props) => {
    const { fontSize, checked, ...other } = props;
    let classNames = staticClassIconDot;
    if (checked) classNames = clsx(classNames, `${staticClassIconDot}-checked`);
    const defaultProperty = {
        className: clsx(classNames),
        ...other,
    };
    return <RadioButtonCheckedIcon {...defaultProperty} />;
};

const RadioButtonIcon = (props) => {
    const { checked = false, className, fontSize, color, ...other } = props;
    let classNames = staticClass;
    const defaultProperty = {
        className: clsx(classNames, className),
        ...other,
    };
    
    const iconProperty = {
        fontSize,
        checked,
        color,
    };
    return (
        <span {...defaultProperty}>
            <RadioButtonIconBackground {...iconProperty} />
            <RadioButtonIconDot {...iconProperty} />
        </span>
    );
};

export default RadioButtonIcon;
