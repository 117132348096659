import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { Dialog, DialogContent } from '@mui/material';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { Stack, TextField, IconButton, Chip, Alert, Button, Tooltip } from '../../../components';
import { PlusCircle as PlusCircleIcon } from '../../../components/icons/SvgIcon/';
import useEmployeeScheduleApi from '@apis/useEmployeeScheduleApi';
import { isEmpty } from '@util/utils';

const AddOtherScheduleDialog = React.forwardRef((props, ref) => {
    const { onClose: onCloseProp, open: openProp, sourceList: sourceListProp = [], apiParams = {}, refresh } = props;

    if (!openProp) return null;

    const { postEmployeeScheduleCoachSupportDataApi } = useEmployeeScheduleApi();
    const [names, setNames] = useState([...sourceListProp]);
    const nameRef = useRef(null);
    // console.log(sourceListProp);
    const handleAddName = () => {
        const _text = nameRef.current.getResult();
        if (!_text) return;
        if (names.includes(_text)) {
            return;
        }
        setNames((pre) => [...pre, _text]);
        const node = nameRef.current.node();
        if (node && node.value) node.value = '';
    };

    const handleKeyDown = (event) => {
        const { keyCode = 0 } = event;
        if (keyCode === 13) handleAddName();
    };

    const handleOnDelete = (_index) => {
        const newArray = [...names];
        newArray.splice(_index, 1);
        setNames([...newArray]);
    };

    const getParams = () => {
        let result = {
            supportEmployeeNames: names.slice(),
        };

        if (!isEmpty(apiParams)) {
            result = {
                ...result,
                ...apiParams,
            };
        }
        return result;
    };

    const handleAddListOnClick = () => {
        if (_.isEqual(names, sourceListProp)) {
            onCloseProp && onCloseProp();
            return;
        }
        let params = getParams();
        postCoachSupportApi(params);
    };

    const postCoachSupportApi = async (params) => {
        const resp = await postEmployeeScheduleCoachSupportDataApi(params);
        if (resp) {
            onCloseProp && onCloseProp();
            refresh.current && refresh.current();
        }
    };

    return (
        <Dialog open={openProp}>
            <GradientColorHeader onClose={onCloseProp}>新增他店人員支援</GradientColorHeader>
            <DialogContent>
                <Stack className="pt-4" direction="column" spacing={2}>
                    <Stack justifyContent="space-between">
                        <TextField label="姓名" direction="row" ref={nameRef} onKeyDown={handleKeyDown} />
                        <Tooltip disableFocusListener title={'新增'}>
                            <IconButton onClick={handleAddName} color="success">
                                <PlusCircleIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    {!_.isEmpty(names) ? (
                        <Stack direction="column" className="add-other-schedule-mx add-other-schedule-max-height" spacing={1}>
                            {names.map((_name, _index) => (
                                <Chip label={_name} onDelete={() => handleOnDelete(_index)} key={_name} isShrink={false} />
                            ))}
                        </Stack>
                    ) : (
                        <Alert color="gray" severity="info" variant="filled" className="add-other-schedule-mx">
                            尚無名單
                        </Alert>
                    )}
                    <Button className="add-other-schedule-mx" variant="contained" onClick={handleAddListOnClick}>
                        儲存名單
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
});

export default AddOtherScheduleDialog;
