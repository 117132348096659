import { useCallback, useState } from 'react';

export const useLocalStorage = (key, defaultValue) => {
    return useStorage(key, defaultValue, window.localStorage);
};

export const useSessionStorage = (key, defaultValue) => {
    return useStorage(key, defaultValue, window.sessionStorage);
};

const useStorage = (key, defaultValue, storageObject) => {
    const [value, setValue] = useState(() => {
        const jsonValue = storageObject.getItem(key);
        if (jsonValue !== null) return JSON.parse(jsonValue);
        if (typeof defaultValue === 'function') {
            return defaultValue();
        } else {
            return defaultValue;
        }
    });

    const setStorageValue = (_value) => {
        if (_value === undefined) {
            setValue(key);
            return storageObject.removeItem(key);
        }
        setValue(_value);
        storageObject.setItem(key, JSON.stringify(_value));
    };

    const remove = useCallback(
        () => {
            storageObject.removeItem(key);
            setValue(null);
        },
        // eslint-disable-next-line
        []
    );

    return { value, setValue: setStorageValue, remove };
};
