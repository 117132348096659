import React from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { CustomerLabel } from './index';

// 單欄
export const FieldRow = ({ label, labelProps = {}, children, className, items = [2, 10], required = false }) => {
    return (
        <React.Fragment>
            <Grid item xs={items[0]} className={clsx('field-label text-right', className)}>
                {label && (
                    <CustomerLabel required={required} {...labelProps}>
                        {label}
                    </CustomerLabel>
                )}
            </Grid>
            <Grid item xs={items[1]} className={clsx('field-content', className)}>
                {React.isValidElement(children) && React.cloneElement(children, { required })}
            </Grid>
        </React.Fragment>
    );
};

// 多欄
export const FieldGroup = ({ label, children, className, items = [2, 10], required, spacing: spacingProps = 3, ...props }) => {
    return (
        <Grid {...props} container spacing={3} className={clsx('field-group', className)}>
            <Grid item xs={items[0]} className="text-right field-label">
                {label && <CustomerLabel required={required}>{label}</CustomerLabel>}
            </Grid>
            <Grid item xs={items[1]}>
                <Grid container spacing={spacingProps} className="pr-5">
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
};
