import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TextField, Button, Box } from '@common/components/';
import { SignBox } from '@icoach/components/';
import useMessageDialog from '@util/hook/useMessageDialog';
import { getErrorModuleStatus, refIsRequiredError } from '@util/utils';

const GRID_ITEM_SIZE = {
    1: { xs: 4 },
    2: { xs: 8 },
    3: { xs: 4 },
    4: { xs: 8 },
    5: { xs: 4 },
    6: { xs: 4 },
    7: { xs: 4 },
    8: { xs: 8 },
};

const ACHCard = React.forwardRef((props, ref) => {
    const {
        defaultValue = {},
        className,
        errorModule = {},
        identifierID = 0,
        userName = '',
        isSameBtn = false,
        readOnly: readOnlyProp = false,
        gridItemsSize: propsGridItemsSize,
        isSign = true,
        bankAccountProps = {},
    } = props;
    const { bankCode, bankName, bankAccount, bankAccountName: bankAccountNameProps, bankAccountID: bankAccountIDProps, signImg } = defaultValue;
    const setMessageDialog = useMessageDialog();
    const fieldRef = useRef({});
    const signImgRef = useRef(null);
    const [bankAccountID, setBankAccountID] = useState(bankAccountIDProps);
    const [bankAccountName, setBankAccountName] = useState(bankAccountNameProps);
    // 設定每個欄位的大小
    const gridItemsSize = Object.assign(GRID_ITEM_SIZE, propsGridItemsSize);

    const handleSameId = () => {
        if (!identifierID || !userName) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '很抱歉！無法取得相關資料，謝謝。',
            });
        } else {
            setBankAccountID(identifierID);
            setBankAccountName(userName);
        }
    };

    const handleChangeBankAccountID = (e) => {
        setBankAccountID(e.target.value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const fieldObj = fieldRef.current;
                let result = {};
                for (let key in fieldObj) {
                    const field = fieldObj[key];
                    if (field && field.hasOwnProperty('getResult')) {
                        const val = field.getResult();
                        result[key] = val;
                    }
                }
                
                if (isSign) {
                    result.signImg = signImgRef.current.getResult();
                }
                return result;
            },
            isError: () => {
                const allObj = {
                    ...fieldRef.current,
                };

                if (isSign) allObj.signImg = signImgRef.current;

                return refIsRequiredError.apply(null, Object.values(allObj));
            },
        }),
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        if (bankAccountIDProps) setBankAccountID(bankAccountIDProps);
    }, [bankAccountIDProps]);

    return (
        <Box className={className}>
            <Grid container spacing={3}>
                <Grid item {...gridItemsSize[1]}>
                    <TextField
                        key={bankCode}
                        ref={(el) => (fieldRef.current.bankCode = el)}
                        defaultValue={bankCode}
                        label={'銀行代碼'}
                        {...getErrorModuleStatus(errorModule, 'bankCode')}
                        readOnly={readOnlyProp}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item {...gridItemsSize[2]} />
                <Grid item {...gridItemsSize[3]}>
                    <TextField
                        key={bankName}
                        ref={(el) => (fieldRef.current.bankName = el)}
                        defaultValue={bankName}
                        label={'銀行名稱'}
                        {...getErrorModuleStatus(errorModule, 'bankName')}
                        readOnly={readOnlyProp}
                        fullWidth
                    />
                </Grid>
                <Grid item {...gridItemsSize[4]}>
                    <TextField
                        key={bankAccount}
                        ref={(el) => (fieldRef.current.bankAccount = el)}
                        defaultValue={bankAccount}
                        label={'銀行帳號'}
                        {...getErrorModuleStatus(errorModule, 'bankAccount')}
                        readOnly={readOnlyProp}
                        fullWidth
                        required
                        {...bankAccountProps}
                    />
                </Grid>
                <Grid item {...gridItemsSize[5]}>
                    <TextField
                        label={'銀行帳戶'}
                        key={bankAccountName}
                        ref={(el) => (fieldRef.current.bankAccountName = el)}
                        defaultValue={bankAccountName}
                        {...getErrorModuleStatus(errorModule, 'bankAccountName')}
                        readOnly={readOnlyProp}
                        fullWidth
                    />
                </Grid>
                <Grid item {...gridItemsSize[6]}>
                    <TextField
                        key={bankAccountID}
                        ref={(el) => (fieldRef.current.bankAccountID = el)}
                        defaultValue={bankAccountID}
                        label={'帳戶身分證字號'}
                        onChangeNative={handleChangeBankAccountID}
                        {...getErrorModuleStatus(errorModule, 'bankAccountID')}
                        readOnly={readOnlyProp}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item {...gridItemsSize[7]}>
                    {isSameBtn && !readOnlyProp && (
                        <Button variant="contained" color="secondary" className="mt-4" onClick={handleSameId}>
                            同基本資料
                        </Button>
                    )}
                </Grid>
                {isSign && (
                    <Grid item {...gridItemsSize[8]}>
                        <SignBox
                            title="帳戶持有人簽名"
                            ref={signImgRef}
                            defaultValue={signImg}
                            {...getErrorModuleStatus(errorModule, 'signImg')}
                            readOnly={readOnlyProp}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
});

export default ACHCard;
