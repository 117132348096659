import React, { useRef, useImperativeHandle, useState } from 'react';
import { useMembershipContext } from './MembershipContext';
import { Grid } from '@mui/material';
import { DateField, TextField, Typography } from '@common/components/';
import { SignBox, PrivateInformationBox } from '@icoach/components/';
import { AddressField } from '@icoach/documents/components/';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { refIsRequiredError } from '@util/utils';
import { getAgeData, parseDate } from '@util/moment';

const MembershipCreateContentBasic = React.forwardRef((props, ref) => {
    const { data, errorModule, isReadOnly, getErrorModuleStatus } = useMembershipContext();
    const { contractBasic = {} } = data;
    const {
        memberName,
        nickName,
        identifierID,
        birthday,
        signImg,
        mail,
        cellPhone,
        tel,
        companyTel,
        address,
        postCode,
        contactName,
        contactPhone,
        contactship,
        memberCardNo,
    } = contractBasic;
    const [ageData, setAgeData] = useState(getAgeData(birthday));
    const contractBasicRef = useRef({});
    const addressRef = useRef();

    const handleBirthDayChange = (_, dateString) => {
        setAgeData(getAgeData(dateString));
    };

    const getResult = () => {
        let result = {
            contractBasic: {},
        };
        const contractBasicObj = contractBasicRef.current;

        if (contractBasicObj) {
            for (let key in contractBasicObj) {
                const target = contractBasicObj[key];

                if (target && target.getResult) {
                    let val = target.getResult();
                    result.contractBasic[key] = val;
                }
            }
        }

        if (addressRef.current && addressRef.current.getResult) {
            result.contractBasic = Object.assign(result.contractBasic, addressRef.current.getResult());
        }

        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => {
                const allObj = {
                    ...contractBasicRef.current,
                    address: addressRef.current,
                };
                return refIsRequiredError.apply(null, Object.values(allObj));
            },
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <FieldGroup label={'基本資料'}>
                <Grid item xs={4}>
                    <TextField
                        key={memberName}
                        ref={(el) => (contractBasicRef.current.memberName = el)}
                        defaultValue={memberName}
                        label={'姓名'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'memberName')}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        key={nickName}
                        ref={(el) => (contractBasicRef.current['nickName'] = el)}
                        defaultValue={nickName}
                        label={'暱稱'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'nickName')}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <PrivateInformationBox
                        key={identifierID}
                        ref={(el) => (contractBasicRef.current.identifierID = el)}
                        defaultValue={identifierID}
                        label={'身分證/護照號碼'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'identifierID')}
                        readOnly={isReadOnly}
                        as={TextField}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <DateField
                        key={birthday}
                        label={'生日'}
                        ref={(el) => (contractBasicRef.current.birthday = el)}
                        defaultValue={birthday}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'birthday')}
                        onChange={handleBirthDayChange}
                        maxDate={parseDate()}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField label="年齡" value={ageData ? ageData.years || 0 : '請選擇生日日期'} readOnly />
                </Grid>
                {!isReadOnly && (
                    <Grid item xs={8}>
                        <Typography className="text-gray-700">未滿十八歲者請由監護人簽名</Typography>
                        <SignBox
                            key={signImg}
                            ref={(el) => (contractBasicRef.current.signImg = el)}
                            defaultValue={signImg}
                            title={'監護人簽名'}
                            {...getErrorModuleStatus(errorModule.contractBasic, 'signImg')}
                            readOnly={isReadOnly}
                        />
                    </Grid>
                )}
            </FieldGroup>
            <FieldGroup label={'聯繫方式'}>
                <Grid item xs={8}>
                    <TextField
                        key={mail}
                        ref={(el) => (contractBasicRef.current.mail = el)}
                        defaultValue={mail}
                        label={'Email'}
                        inputProps={{
                            placeholder: '建議填寫，以便收取重要權益異動、發票、優惠等相關訊息',
                        }}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'mail')}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <TextField
                        key={cellPhone}
                        ref={(el) => (contractBasicRef.current.cellPhone = el)}
                        defaultValue={cellPhone}
                        label={'手機'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'cellPhone')}
                        readOnly={isReadOnly}
                        maskType={'MOB'}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        key={tel}
                        ref={(el) => (contractBasicRef.current.tel = el)}
                        defaultValue={tel}
                        label={'住家電話'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'el')}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        key={companyTel}
                        ref={(el) => (contractBasicRef.current.companyTel = el)}
                        defaultValue={companyTel}
                        label={'公司電話'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'companyTel')}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label={'地址'}>
                <Grid item xs={12}>
                    <AddressField
                        key={`${postCode}_${address}`}
                        ref={addressRef}
                        address={address}
                        postCode={postCode}
                        {...getErrorModuleStatus(errorModule.contractBasic, address)}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label={'緊急聯絡人'}>
                <Grid item xs={4}>
                    <TextField
                        key={contactName}
                        ref={(el) => (contractBasicRef.current.contactName = el)}
                        defaultValue={contactName}
                        label={'姓名'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'contactName')}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        key={contactPhone}
                        ref={(el) => (contractBasicRef.current.contactPhone = el)}
                        defaultValue={contactPhone}
                        label={'電話'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'contactPhone')}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        key={contactship}
                        ref={(el) => (contractBasicRef.current.contactship = el)}
                        defaultValue={contactship}
                        label={'關係'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'contactship')}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label="會員資訊">
                <Grid item xs={4}>
                    <TextField
                        key={memberCardNo}
                        ref={(el) => (contractBasicRef.current.memberCardNo = el)}
                        defaultValue={memberCardNo}
                        label={'簽到卡號'}
                        {...getErrorModuleStatus(errorModule.contractBasic, 'memberCardNo')}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
        </React.Fragment>
    );
});

export default MembershipCreateContentBasic;
