import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';
import useShowPDF from '@util/hook/useShowPDF';

const GET_DOC_MEMBERSHIP_TRANSFER_OUT_UNMOVEOUT_OPTIONS_API = `/api/doc/membership/transfer-out/unmoveout/options/`;
const GET_DOC_MEMBERSHIP_TRANSFER_OUT_MOVEOUT_OPTIONS_API = `/api/doc/membership/transfer-out/moveout/options/`;
const GET_DOC_MEMBERSHIP_TRANSFER_OUT_HISTORY_API = `/api/doc/membership/transfer-out/history/`;
const GET_DOC_MEMBERSHIP_TRANSFER_OUT_CONTRACTID_API = (contractID) => `/api/doc/membership/transfer-out/${contractID}/`;
const PUT_DOC_MEMBERSHIP_TRANSFEROUT_CONTRACTID_PATH = GET_DOC_MEMBERSHIP_TRANSFER_OUT_CONTRACTID_API;
const POST_DOC_MEMBERSHIP_TRANSFER_OUT_CONTRACTID_API = GET_DOC_MEMBERSHIP_TRANSFER_OUT_CONTRACTID_API;
const DELETE_DOC_MEMBERSHIP_TRANSFER_OUT_CONTRACTID_API = GET_DOC_MEMBERSHIP_TRANSFER_OUT_CONTRACTID_API;
const GET_DOC_MEMBERSHIP_TRANSFER_OUT_TEMPORARY_API = `/api/doc/membership/transfer-out/temporary/`;
const POST_DOC_MEMBERSHIP_TRANSFER_OUT_TEMPORARY_API = GET_DOC_MEMBERSHIP_TRANSFER_OUT_TEMPORARY_API;
const DELETE_DOC_MEMBERSHIP_TRANSFER_OUT_TEMPORARY_API = GET_DOC_MEMBERSHIP_TRANSFER_OUT_TEMPORARY_API;

const useDocMembershipTransferOutApi = () => {
    const Ajax = useAjax();
    const showPDF = useShowPDF();
    const { enqueueSnackbar } = useSnackbar();

    const getInitTransferOutUnMoveoutOptionApi = useCallback(
        async () => {
            const respOptions = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_OUT_UNMOVEOUT_OPTIONS_API);
            return respOptions;
        },
        // eslint-disable-next-line
        []
    );

    const getInitTransferOutMoveoutOptionApi = useCallback(
        async () => {
            const respOptions = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_OUT_MOVEOUT_OPTIONS_API);
            return respOptions;
        },
        // eslint-disable-next-line
        []
    );

    // 會籍轉讓歷史紀錄
    const postTransferOutContractHistoryApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_OUT_HISTORY_API, params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 讀取會籍轉讓申請書
    const getTransferOutContractApi = useCallback(
        async (contractID, params) => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_OUT_CONTRACTID_API(contractID), params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
                return null;
            } else if (resp.isError) {
                return null;
            } else {
                return resp;
            }
        },
        // eslint-disable-next-line
        []
    );

    // 新增會籍轉讓申請書
    const postTransferOutContractApi = useCallback(
        async (contractID, params) => {
            const resp = await Ajax.post(POST_DOC_MEMBERSHIP_TRANSFER_OUT_CONTRACTID_API(contractID), params);
            if (resp) {
                if (resp.completedPath) {
                    showPDF({
                        open: true,
                        pdfUrl: resp.completedPath,
                    });
                } else {
                    enqueueSnackbar('PDF連結有誤，請重新再操作一次', { variant: 'error' });
                    return null;
                }
            } else {
                enqueueSnackbar('新增失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除會籍轉讓申請書
    const deleteTransferOutContractApi = useCallback(
        async (contractID) => {
            const resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_TRANSFER_OUT_CONTRACTID_API(contractID));
            if (resp) {
                enqueueSnackbar('刪除成功', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 確認是否有會籍轉讓暫存資料
    const getDocTemporaryTransferOutContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_OUT_TEMPORARY_API, params);
            if (resp !== 0 && !resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 暫存會籍轉讓暫存資料
    const postDocTemporaryTransferOutContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_DOC_MEMBERSHIP_TRANSFER_OUT_TEMPORARY_API, params);
            if (resp) {
                enqueueSnackbar('暫存移出申請書成功', { variant: 'success' });
                return resp;
            } else {
                enqueueSnackbar('新增暫存失敗，請重新操作', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除暫存會籍轉讓暫存資料
    const deleteDocTemporaryTransferOutContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_TRANSFER_OUT_TEMPORARY_API, params);
            if (!resp) {
                enqueueSnackbar('刪除失敗，請重新操作', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 確認移出Api
    const putDocMembershipTransferOutConfirmApi = useCallback(
        async (params, contractID) => {
            const resp = await Ajax.put(PUT_DOC_MEMBERSHIP_TRANSFEROUT_CONTRACTID_PATH(contractID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 健康申告書下載PDF
    const downloadTransferOutPDFApi = useCallback(
        async (params) => {
            const { contractUrl } = params;
            showPDF({
                open: true,
                pdfUrl: contractUrl,
            });
        },
        // eslint-disable-next-line
        []
    );

    return {
        getInitTransferOutUnMoveoutOptionApi,
        getInitTransferOutMoveoutOptionApi,
        postTransferOutContractHistoryApi,
        getTransferOutContractApi,
        postTransferOutContractApi,
        deleteTransferOutContractApi,
        getDocTemporaryTransferOutContractApi,
        postDocTemporaryTransferOutContractApi,
        deleteDocTemporaryTransferOutContractApi,
        downloadTransferOutPDFApi,
        putDocMembershipTransferOutConfirmApi,
    };
};

export default useDocMembershipTransferOutApi;
