import React, { useState } from 'react';
import { IconButton } from '@common/components/';
import { QrCode as QrCodeIcon } from '@common/SvgIcon/';
import MemberSiteQrCodeDialog from '@icoach/inbody/interview/MemberSiteQrCodeDialog';

const MeasureInformationBox = (props) => {
    const { sourceData } = props;
    const [open, setOpen] = useState(false);

    const handleOnClose = () => {
        setOpen(false);
    };

    const handleOnClick = () => {
        setOpen(true);
    };

    return (
        <React.Fragment>
            <MemberSiteQrCodeDialog open={open} onClose={handleOnClose} sourceData={{ memberID: sourceData.memberID }} />
            <IconButton onClick={handleOnClick}>
                <QrCodeIcon />
            </IconButton>
        </React.Fragment>
    );
};

export default MeasureInformationBox;
