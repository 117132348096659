import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    InvoiceByCarrierNo,
    InvoiceByCarrierType,
    InvoiceByDonate,
    InvoiceByEmail,
    InvoiceByIssuingType,
    InvoiceByPhone,
    InvoiceByTriplicateUniform,
    invoiceCarrierTypeMap,
    issuingTypeMap,
} from '@icoach/components';
import { Grid } from '@mui/material';
import { refIsRequiredError } from '@util/utils';

const CyclePurchaseInvoiceBox = React.forwardRef((props, ref) => {
    const { className = '', sourceData, options, errorModule, readOnly } = props;
    const { issuingType: issuingTypeProps, carrierType: carrierTypeProps, donationCode, issuingEmail, issuingPhone, carrierNo } = sourceData || {};
    const { invoiceIssuingTypeOptions = [], invoiceCarrierTypeOptions = [] } = options || {};
    const [issuingType, setIssuingType] = useState(issuingTypeProps || 2);
    const [invoiceCarrierType, setInvoiceCarrierType] = useState(carrierTypeProps || 1);
    const issuingTypeRef = useRef(null);
    const donateRef = useRef(null);
    const invoiceCarrierTypeRef = useRef(null);
    const invoiceCarrierNoRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const triplicateUniformRef = useRef(null);

    const isNotificationSMS = String(invoiceCarrierType) === invoiceCarrierTypeMap.sms;

    const handleChangeIssuingType = (value) => {
        setIssuingType(value);
    };

    const handleChangeInvoiceCarrierType = (value) => {
        setInvoiceCarrierType(value);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};

                if (issuingTypeRef.current && issuingTypeRef.current.getResult) {
                    Object.assign(result, issuingTypeRef.current.getResult());
                }

                if (donateRef.current && donateRef.current.getResult) {
                    Object.assign(result, donateRef.current.getResult());
                }

                if (invoiceCarrierTypeRef.current && invoiceCarrierTypeRef.current.getResult) {
                    Object.assign(result, invoiceCarrierTypeRef.current.getResult());
                }

                if (invoiceCarrierNoRef.current && invoiceCarrierNoRef.current.getResult) {
                    Object.assign(result, invoiceCarrierNoRef.current.getResult());
                }

                if (triplicateUniformRef.current && triplicateUniformRef.current.getResult) {
                    Object.assign(result, triplicateUniformRef.current.getResult());
                }

                if (emailRef.current && emailRef.current.getResult) {
                    Object.assign(result, emailRef.current.getResult());
                }

                return result;
            },
            isError: () => refIsRequiredError(issuingTypeRef, donateRef, invoiceCarrierNoRef, invoiceCarrierTypeRef, triplicateUniformRef, emailRef, phoneRef),
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (issuingTypeProps) setIssuingType(issuingTypeProps);
        },
        // eslint-disable-next-line
        [issuingTypeProps],
    );

    useEffect(
        () => {
            if (carrierTypeProps) setInvoiceCarrierType(carrierTypeProps);
        },
        // eslint-disable-next-line
        [carrierTypeProps],
    );

    return (
        <Grid className={className} spacing={3} container>
            <Grid xs={12} item>
                <InvoiceByIssuingType
                    ref={issuingTypeRef}
                    defaultValue={issuingType}
                    options={invoiceIssuingTypeOptions}
                    errorData={errorModule}
                    onChange={handleChangeIssuingType}
                    readOnly={readOnly}
                />
            </Grid>
            {String(issuingType) === issuingTypeMap.donate && (
                <InvoiceByDonate ref={donateRef} defaultValue={donationCode} errorData={errorModule} readOnly={readOnly} />
            )}
            {String(issuingType) === issuingTypeMap.duplicateUniform && (
                <React.Fragment>
                    <Grid xs={12} item>
                        <InvoiceByCarrierType
                            ref={invoiceCarrierTypeRef}
                            defaultValue={invoiceCarrierType}
                            options={invoiceCarrierTypeOptions}
                            errorData={errorModule}
                            onChange={handleChangeInvoiceCarrierType}
                            readOnly={readOnly}
                        />
                    </Grid>
                    {(String(invoiceCarrierType) === invoiceCarrierTypeMap.citizenDigitalCertificate1 ||
                        String(invoiceCarrierType) === invoiceCarrierTypeMap.mobile) && (
                        <Grid xs={12} item>
                            <InvoiceByCarrierNo
                                ref={invoiceCarrierNoRef}
                                defaultValue={carrierNo}
                                invoiceCarrierType={invoiceCarrierType}
                                errorData={errorModule}
                                readOnly={readOnly}
                            />
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {String(issuingType) === issuingTypeMap.triplicateUniform && (
                <InvoiceByTriplicateUniform ref={triplicateUniformRef} sourceData={sourceData} errorData={errorModule} readOnly={readOnly} />
            )}
            <Grid item xs={6}>
                <InvoiceByEmail ref={emailRef} defaultValue={issuingEmail} errorData={errorModule} readOnly={readOnly} required={!isNotificationSMS} />
            </Grid>
            <Grid item xs={6}>
                <InvoiceByPhone label={'通知手機號碼'} ref={phoneRef} defaultValue={issuingPhone} errorData={errorModule} disabled />
            </Grid>
        </Grid>
    );
});

export default CyclePurchaseInvoiceBox;
