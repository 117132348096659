import React from 'react';
import { Grid } from '@mui/material';
import { Box, DateField, MenuItem, SelectField, Typography } from '@common/components/';
import { memberSheet, moreSheet, cellPhoneSheet, contactSheet, rowLabelCheckboxSheet, SpecialConditionBox } from '@icoach/components/';
import { refIsRequiredError } from '@util/utils';
import { displayDateText, parseDate } from '@util/moment';
import { MultipleSelectCheckedBox } from '@icoach/nonMember/components/NonMemberSearchBar';
import { StatusChipTooltip } from "@icoach/rentCabinet/CabinetOverviewTable";
import { COLOR_MATCH } from "@icoach/accountBook/staticData";

// 月收費名單
export const FilterMonthlyFeeSearch = React.forwardRef((props, ref) => {
    const { resource = {} } = props;
    const {
        membershipTermOption: membershipTermOptionProps = [],
        paymentTypeOption: paymentTypeOptionProps = [],
        monthlyFeeOption: monthlyFeeOptionProps = [],
        receivedPaymentOption: receivedPaymentOptionProps = [],
    } = resource;

    const dateMonthRef = React.useRef(null);
    const membershipTermRef = React.useRef(null);
    const paymentTypeRef = React.useRef(null);
    const membershipMonthlyFeeRef = React.useRef(null);
    const payablePriceRef = React.useRef(null);
    const receivedPaymentRef = React.useRef(null);

    React.useImperativeHandle(
        ref,
        () => {
            return {
                isError: () =>
                    refIsRequiredError(dateMonthRef, membershipTermRef, paymentTypeRef, membershipMonthlyFeeRef, payablePriceRef, receivedPaymentRef),
                getResult: () => {
                    let result = {};
                    if (dateMonthRef.current && dateMonthRef.current.getResult) {
                        Object.assign(result, { date: parseDate(dateMonthRef.current.getResult(), 'YYYY-MM') });
                    }
                    if (membershipTermRef.current && membershipTermRef.current.getResult) {
                        Object.assign(result, { membershipTerm: membershipTermRef.current.getResult() });
                    }
                    if (paymentTypeRef.current && paymentTypeRef.current.getResult) {
                        Object.assign(result, { paymentType: paymentTypeRef.current.getResult() });
                    }
                    if (membershipMonthlyFeeRef.current && membershipMonthlyFeeRef.current.getResult) {
                        Object.assign(result, { membershipMonthlyFeeType: membershipMonthlyFeeRef.current.getResult() || [] });
                    }
                    if (payablePriceRef.current && payablePriceRef.current.getResult) {
                        Object.assign(result, { payablePriceType: payablePriceRef.current.getResult() || [] });
                    }
                    if (receivedPaymentRef.current && receivedPaymentRef.current.getResult) {
                        Object.assign(result, { receivedPayment: receivedPaymentRef.current.getResult() });
                    }
                    return result;
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid xs={3} item>
                <DateField
                    ref={dateMonthRef}
                    views={['year', 'month']}
                    openTo="month"
                    format="YYYY/MM"
                    label="月份"
                    mask="____/__"
                    defaultValue={parseDate(new Date(), 'YYYY-MM')}
                    required
                    fullWidth
                />
            </Grid>
            <Grid xs={3} item>
                <SelectField label={'會籍期間'} defaultValue={'999'} ref={membershipTermRef} fullWidth>
                    {Array.isArray(membershipTermOptionProps) &&
                        membershipTermOptionProps
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
            <Grid xs={3} item>
                <SelectField label={'繳費方式'} defaultValue={'99'} ref={paymentTypeRef} fullWidth>
                    {Array.isArray(paymentTypeOptionProps) &&
                        paymentTypeOptionProps
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
            <Grid xs={3} item>
                <MultipleSelectCheckedBox label="月費" options={monthlyFeeOptionProps} ref={membershipMonthlyFeeRef} showLabel={true}/>
            </Grid>
            <Grid xs={3} item>
                <MultipleSelectCheckedBox label="應收金額" options={monthlyFeeOptionProps} ref={payablePriceRef} showLabel={true}/>
            </Grid>
            <Grid xs={3} item>
                <SelectField label={'收款狀態'} defaultValue={'99'} ref={receivedPaymentRef} fullWidth>
                    {Array.isArray(receivedPaymentOptionProps) &&
                        receivedPaymentOptionProps
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

export const FilterMonthlyFeeLabel = [
    {
        ...rowLabelCheckboxSheet,
        cellKey: 'memberID',
    },
    { ...memberSheet, isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
    { ...cellPhoneSheet },
    {
        cellKey: 'specialCondition',
        headerLabel: '特殊狀況',
        width: 18,
        formatCell: (cellValue, { memberID }) => {
            return <SpecialConditionBox sourceData={{ memberID, value: cellValue }}/>;
        },
    },
    {
        cellKey: 'receivedPaymentText',
        headerLabel: '收款狀態',
        align: 'center',
    },
    {
        cellKey: 'membershipTermText',
        headerLabel: '會籍期間',
        align: 'center',
    },
    {
        cellKey: 'paymentTypeText',
        headerLabel: '繳款方式',
        width: 15,
        align: 'center',
    },
    {
        cellKey: 'creditCardNo',
        headerLabel: '信用卡',
        width: 16,
        align: 'center',
        formatCell: (cellValue, { cardExpiryDate }) => {
            return (
                <Box className="payment-card-content">
                    <Typography className={'title'} variant="body1" component={'h6'}>
                        {cellValue}
                    </Typography>
                    {cardExpiryDate ? (
                        <Typography className={'sub-title font-color-3'} variant="body2">
                            {`有效期限：${cardExpiryDate}`}
                        </Typography>
                    ) : (
                        '-'
                    )}
                </Box>
            );
        },
    },
    {
        cellKey: 'bankAccount',
        headerLabel: 'ACH',
        width: 16,
        align: 'center',
        formatCell: (cellValue, { bankAccountName }) => {
            return (
                <Box className="payment-card-content">
                    <Typography className={'title'} variant="body1" component={'h6'}>
                        {cellValue}
                    </Typography>
                    {bankAccountName ? (
                        <Typography className={'sub-title font-color-3'} variant="body2">
                            {`帳號名稱：${bankAccountName}`}
                        </Typography>
                    ) : (
                        '-'
                    )}
                </Box>
            );
        },
    },
    {
        cellKey: 'productName',
        headerLabel: '品項',
        width: 16,
        align: 'center',
    },
    {
        cellKey: 'membershipMonthlyFee',
        headerLabel: '月費',
        align: 'center',
    },
    {
        cellKey: 'payablePrice',
        headerLabel: '應收金額',
        align: 'center',
    },
    {
        cellKey: 'paidPrice',
        headerLabel: '已收金額',
        align: 'center',
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票號碼',
        align: 'center',
    },
    {
        cellKey: 'statusText',
        headerLabel: '狀態',
        width: 16,
        align: 'center',
        formatCell: (cellValue, { statusColor = 0, processDate }) => {
            return <Box>
                <StatusChipTooltip statusText={cellValue} color={COLOR_MATCH[statusColor]}/>
                <Typography className={'sub-title font-color-3'} variant="subtitle2">
                    {displayDateText(processDate, null, { format: 'YYYY/MM/DD' })}
                </Typography>
            </Box>;
        },
    },
    { ...contactSheet },
    { ...moreSheet },
];
