import React, { useRef } from 'react';
import Tablenization from '../../../components/Tablenization';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';
import { parseDate } from '@util/moment';

const rowsLabel = [
    {
        cellKey: 'claimDate',
        headerLabel: '領取日',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
    },
    {
        cellKey: 'giftName',
        headerLabel: '贈品名稱',
    },
    {
        cellKey: 'eventNameText',
        headerLabel: '活動名稱',
    },
];

const GiftClaimTable = (props) => {
    const { className, rowsData = [] } = props;
    const labelTitle = useRef(rowsLabel);

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default GiftClaimTable;
