import React, { useMemo } from 'react';
import { Box, Typography } from '@common/components/';
import { BusinessCardBox, MemoControllerBox } from '@icoach/components/';
import { formatCurrencyFn } from '@util/utils';
import { COLOR_MATCH } from '@icoach/accountBook/staticData';
import { StatusChipTooltip } from '@icoach/rentCabinet/CabinetOverviewTable';
import { BatchPaymentOrdersTable, checkRowsLabel, OrderMoreRowsLabel } from '@icoach/accountBook/tables/BatchPaymentOrdersCreditCardTable';

const rowsLabel = [
    {
        cellKey: 'memberSheet',
        headerLabel: '會員',
        align: 'left',
        maxWidth: 20.4,
        formatCell: (cellValue, { memberNo, memberName, memberPicturePath }) => {
            let memberInfo = {
                memberNo,
                memberName,
                memberImg: memberPicturePath,
            };
            return <BusinessCardBox rowData={memberInfo} />;
        },
    },
    {
        cellKey: 'statusText',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (cellValue, { status, statusColor = 0, processResult }) => {
            return <StatusChipTooltip statusText={cellValue} statusMemo={processResult} color={COLOR_MATCH[statusColor]} isShowTip={String(status) === '9'} />;
        },
    },
    {
        cellKey: 'itemName',
        headerLabel: '品項',
        align: 'center',
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '扣款金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'returnPrice',
        headerLabel: '退款金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票號碼',
        align: 'center',
    },
    {
        cellKey: 'bankAccount',
        headerLabel: '銀行帳號',
        maxWidth: 21,
        formatCell: (cellValue, { bankCode, bankName }) => {
            return (
                <Box className="payment-card-content">
                    {bankName && (
                        <Typography className={'sub-title font-color-3 mb-0'} variant={'body1'}>
                            {bankName}
                        </Typography>
                    )}
                    <Typography className={'title font-color-3 mb-0'} variant={'body1'}>
                        代號：{bankCode}
                    </Typography>
                    <Typography className={'title mb-0'} variant={'body1'} component={'h6'}>
                        {cellValue}
                    </Typography>
                </Box>
            );
        },
    },
    {
        cellKey: 'bankAccountName',
        headerLabel: '銀行帳戶',
        maxWidth: 21,
        formatCell: (cellValue, { bankAccountID }) => {
            return (
                <div className="payment-card-content">
                    <Typography className={'title'} variant="body2">
                        {`戶名：${cellValue}`}
                    </Typography>
                    {bankAccountID && (
                        <Typography className={'sub-title'} variant="body1">
                            {bankAccountID}
                        </Typography>
                    )}
                </div>
            );
        },
    },
    {
        cellKey: 'notes',
        headerLabel: '備註',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
    { ...OrderMoreRowsLabel },
];

const BatchPaymentOrdersAchTable = React.forwardRef((props, ref) => {
    const { action } = props;
    const labelTitle = useMemo(() => {
        let array = [...rowsLabel];
        if (action) array = [checkRowsLabel(action), ...array];
        return array;
    }, [action]);

    return <BatchPaymentOrdersTable {...props} ref={ref} labelTitle={labelTitle} />;
});

export default BatchPaymentOrdersAchTable;
