import { useCallback, useState } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_MEMBER_MEMBERSHIPS_API = (memberID) => `api/members/${memberID}/memberships/`;
const GET_MEMBER_MEMBERSHIP_API = (memberID, membershipID) => `api/members/${memberID}/memberships/${membershipID}/`;
const GET_MEMBER_MEMBERSHIP_RANGES_API = (memberID, membershipID) => `api/members/${memberID}/memberships/${membershipID}/bar-ranges/`;
const GET_MEMBER_MEMBERSHIP_ITEM_REASONS_API = (memberID, membershipID) => `api/members/${memberID}/memberships/${membershipID}/item-reasons/`;
const POST_MEMBER_MEMBERSHIP_ORDERS_API = (memberID) => `api/members/${memberID}/memberships/orders/`;
const POST_MEMBER_MEMBERSHIP_MANUAL_GIFT_API = (memberID, membershipID) => `api/members/${memberID}/memberships/${membershipID}/manual-gift/`;
const DELETE_MEMBER_MEMBERSHIP_MANUAL_GIFT_ITEM_API = (memberID, membershipID, itemID) =>
    `api/members/${memberID}/memberships/${membershipID}/manual-gift/${itemID}/`;
const GET_MEMBER_MEMBERSHIP_UNUSED_SUSPEND_API = (memberID, membershipID) => `/api/members/${memberID}/memberships/${membershipID}/unused-suspend`;

const useMembershipApi = () => {
    const Ajax = useAjax();

    // 取得會員全部會籍BarRange
    const getMembershipsApi = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_MEMBER_MEMBERSHIPS_API(memberID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得單一會籍資訊內容
    const getMembershipApi = useCallback(
        async (memberID, membershipID) => {
            const resp = await Ajax.get(GET_MEMBER_MEMBERSHIP_API(memberID, membershipID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得會籍項目及時間
    const getMembershipBarRangeApi = useCallback(
        async (memberID, membershipID) => {
            const resp = await Ajax.get(GET_MEMBER_MEMBERSHIP_RANGES_API(memberID, membershipID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得會籍期間項目資訊
    const getMembershipItemReasonsApi = useCallback(
        async (memberID, membershipID, params) => {
            const resp = await Ajax.get(GET_MEMBER_MEMBERSHIP_ITEM_REASONS_API(memberID, membershipID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得會籍期間收費明細資訊
    const postMemberMembershipOrdersApi = useCallback(
        async (memberID, params) => {
            const resp = await Ajax.post(POST_MEMBER_MEMBERSHIP_ORDERS_API(memberID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 手動贈送後置日期
    const postMemberMembershipManualGiftApi = useCallback(
        async (memberID, membershipID, params) => {
            const resp = await Ajax.post(POST_MEMBER_MEMBERSHIP_MANUAL_GIFT_API(memberID, membershipID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除手動贈送後置日期
    const deleteMemberMembershipManualGiftItemApi = useCallback(
        async (memberID, membershipID, itemID, params) => {
            const resp = await Ajax.delete(DELETE_MEMBER_MEMBERSHIP_MANUAL_GIFT_ITEM_API(memberID, membershipID, itemID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getMemberMembershipNonUseSuspendApi = useCallback(
        async (memberID, membershipID) => {
            const resp = await Ajax.get(GET_MEMBER_MEMBERSHIP_UNUSED_SUSPEND_API(memberID, membershipID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getMembershipsApi,
        getMembershipApi,
        getMembershipBarRangeApi,
        getMembershipItemReasonsApi,
        postMemberMembershipOrdersApi,
        postMemberMembershipManualGiftApi,
        deleteMemberMembershipManualGiftItemApi,
        getMemberMembershipNonUseSuspendApi,
    };
};

// 取得會籍期間項目資訊
export const useGetMembershipItemReasonsApi = () => {
    const [payload, setPayload] = useState(null);
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    const getMembershipItemReasonsApi = useCallback(
        async (memberID, membershipID, filter) => {
            let resp;
            let params = {};

            if (filter) params.filter = filter;

            resp = await Ajax.get(GET_MEMBER_MEMBERSHIP_ITEM_REASONS_API(memberID, membershipID), params);
            if (resp) {
                setPayload(resp);
            } else {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
        },
        // eslint-disable-next-line
        []
    );

    return {
        payload,
        getMembershipItemReasonsApi,
    };
};

export default useMembershipApi;
