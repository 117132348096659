import React, { useRef, useState, useImperativeHandle } from 'react';
import useMessageDialog from '@util/hook/useMessageDialog';
import { isEmpty } from '@util/utils';
import { Box, Typography, TextField } from '@common/components/';
import useAppointmentApi from '@apis/useAppointmentApi';
import clsx from 'clsx';

const MemoEditBox = React.forwardRef((props, ref) => {
    const { text } = props;
    const textRef = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => textRef.current.getResult(),
            };
        },
        []
    );

    return (
        <React.Fragment>
            <TextField label="備註" rows={10} ref={textRef} defaultValue={text} multiline fullWidth />
        </React.Fragment>
    );
});

const AppointmentMemoEditControllerBox = (props) => {
    const { className, dialogTitle = '備註', text, apoId, isTableSpace = true } = props;
    const { putAppointmentNotesApi } = useAppointmentApi();
    const setMessageDialog = useMessageDialog();
    const [textState, setTextState] = useState(text || '');
    const notesRef = useRef(null);

    const doPutEditNotesApi = async (e, onClose) => {
        const notes = notesRef.current.getResult();
        const params = {
            appointmentID: apoId,
            notes,
        };
        const resp = await putAppointmentNotesApi(params);
        if (resp) {
            onClose();
            setTextState(notes);
        }
    };

    const handleClickNotes = () => {
        setMessageDialog({
            open: true,
            title: dialogTitle,
            MsgComp: MemoEditBox,
            msgRef: notesRef,
            buttonText: '更新',
            msgProps: {
                text: textState,
            },
            onAsyncConfirm: doPutEditNotesApi,
        });
    };

    let boxProperty = {
        className: clsx(`memo-controller-box`, 'cursor-pointer', className),
        onClick: handleClickNotes,
    };

    return (
        <Box {...boxProperty}>
            {isEmpty(textState) ? (
                <Typography className={clsx('text-center', 'text-gray-500')}>尚無備註</Typography>
            ) : (
                <Typography className={clsx(isTableSpace && 'white-space-normal')}>{textState}</Typography>
            )}
        </Box>
    );
};

export default AppointmentMemoEditControllerBox;
