import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import {
    DocumentFile as DocumentFileIcon,
    History as HistoryIcon,
    DollarSign as DollarSignIcon,
    ShippingFast as ShippingFastIcon,
    FileInvoiceDollar as FileInvoiceDollarIcon,
} from '@common/SvgIcon/';
import { Box, IconButton, Typography, Chip } from '@common/components/';
import { MoreLabelBox } from '@icoach/components/';
import { CycleOrderProductsTable } from '@icoach/members/memberDetail/table';
import { CycleOrderDeleteDialog } from '@icoach/documents/dialog';
import { SimpleContentItem } from '@icoach/members/components/';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';
import { formatCurrencyFn } from '@util/utils';
import { displayDateText } from '@util/moment';
import { DOCUMENT_CYCLE_PURCHASE_PATH, DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH } from '@icoach/router/routerPath';
import PendingCycleEffectOrderList from '@icoach/members/components/PendingCycleEffectOrderList';
import CycleOrderRecordList from '@icoach/members/components/CycleOrderRecordList';
import { cycleOrderTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import { dialogType } from '@icoach/members/memberDetail/MemberDetailCycleOrderContentOverview';

// 主單的子項目定義，如歷史與預計變更單。
const subInfoType = {
    effectOrder: 'effectOrder', // 預計變更單
    history: 'history', // 訂購單變更紀錄
};

export const getMoreActionArray = (data = {}, view = true) => {
    const { canEffection, canDeleted } = data;
    const moreAction = {
        view: view,
        effect: canEffection,
        delete: canDeleted,
    };
    let array = [];
    for (let key in moreAction) {
        let bool;
        if (moreAction.hasOwnProperty(key)) {
            bool = moreAction[key];
        }
        if (bool) {
            array.push(key);
        }
    }
    return array;
};

const CycleOrderMoreLabel = (props) => {
    const { memberID, cycleOrderID, effectID } = props;
    const { refresh } = useMemberDetailContext();
    const moreLabelMap = useRef({
        view: {
            text: '查看',
            to: () => DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID),
        },
        effect: {
            text: '新增變更單',
            to: () => DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH(memberID, cycleOrderID, effectID),
        },
        delete: {
            text: '刪除',
            dialogString: 'delete',
            Dialog: CycleOrderDeleteDialog,
        },
    });

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap.current} textAlign={'center'} refresh={refresh.current} />;
};

// 循環訂購單
const CycleOrderCard = (props) => {
    const { className, data = {}, showSubInfo, handleToggle, handleShowDialog } = props;
    const {
        memberID,
        cycleOrderID,
        cycleOrderNo,
        cycleOrderType,
        cycleOrderTypeText,
        cycleOrderDate,
        firstShipmentMonth,
        employeeName,
        shippingDayText,
        saleAmount,
        effectCount,
        items,
    } = data;

    const moreAction = getMoreActionArray(data);

    return (
        <Box className={clsx('cycle-order-card', { quit: cycleOrderType === cycleOrderTypeMap.quit }, className)}>
            <Box className={'cycle-order-card-header'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <SimpleContentItem label={'訂單編號'} title={cycleOrderNo}>
                            {cycleOrderNo}
                        </SimpleContentItem>
                    </Grid>
                    <Grid xs={8} item>
                        <SimpleContentItem label={'訂單狀態'}>
                            <Typography
                                className={clsx({
                                    'text-error': cycleOrderType === cycleOrderTypeMap.quit || cycleOrderType === cycleOrderTypeMap.suspension,
                                })}
                            >
                                {cycleOrderTypeText}
                            </Typography>
                        </SimpleContentItem>
                    </Grid>
                    <Grid xs={4} item>
                        <SimpleContentItem label={'建單日期'}>{displayDateText(cycleOrderDate)}</SimpleContentItem>
                    </Grid>
                    <Grid xs={4} item>
                        <SimpleContentItem label={'首次出貨日'}>{displayDateText(firstShipmentMonth)}</SimpleContentItem>
                    </Grid>
                    <Grid xs={4} item>
                        <SimpleContentItem label={'建單擔當'}>{employeeName}</SimpleContentItem>
                    </Grid>
                    {!!effectCount && (
                        <Grid xs={4} item>
                            <SimpleContentItem label={'預計變更數量'}>
                                <Chip label={effectCount} color={'error'} onClick={() => handleToggle(subInfoType.effectOrder)} />
                            </SimpleContentItem>
                        </Grid>
                    )}
                </Grid>
                {!isEmpty(moreAction) && (
                    <CycleOrderMoreLabel
                        buttonProps={{ className: 'cycle-order-card-header-actions' }}
                        moreActionArray={moreAction}
                        memberID={memberID}
                        cycleOrderID={cycleOrderID}
                    />
                )}
            </Box>
            <CycleOrderProductsTable className={'cycle-order-card-products'} data={items} />
            <Box className={'cycle-order-card-footer'}>
                <Box className={'cycle-order-card-footer-info'}>
                    <SimpleContentItem label={'出貨週期'}>{`${shippingDayText}日`}</SimpleContentItem>
                    <Box component={'span'} className={'total'}>
                        <Box component={'span'}>總金額</Box>
                        <Box component={'span'} className={'h5'}>
                            {formatCurrencyFn(saleAmount, { symbol: '$' })}
                        </Box>
                    </Box>
                </Box>
                <Box className={'cycle-order-card-footer-actions'}>
                    <IconButton className={'icon-button-column'} onClick={() => handleShowDialog && handleShowDialog(dialogType.shipping)}>
                        <ShippingFastIcon />
                        <Typography className={'icon-button-column-text'}>宅配</Typography>
                    </IconButton>
                    <IconButton className={'icon-button-column'} onClick={() => handleShowDialog && handleShowDialog(dialogType.payment)}>
                        <DollarSignIcon />
                        <Typography className={'icon-button-column-text'}>付款</Typography>
                    </IconButton>
                    <IconButton className={'icon-button-column'} onClick={() => handleShowDialog && handleShowDialog(dialogType.invoice)}>
                        <FileInvoiceDollarIcon />
                        <Typography className={'icon-button-column-text'}>發票</Typography>
                    </IconButton>
                    <IconButton
                        className={clsx('icon-button-column', {
                            current: showSubInfo === subInfoType.history,
                        })}
                        onClick={() => handleToggle(subInfoType.history)}
                    >
                        <HistoryIcon />
                        <Typography className={'icon-button-column-text'}>歷史</Typography>
                    </IconButton>
                    <IconButton
                        className={clsx('icon-button-column', {
                            current: showSubInfo === subInfoType.effectOrder,
                        })}
                        onClick={() => handleToggle(subInfoType.effectOrder)}
                    >
                        <DocumentFileIcon />
                        <Typography className={'icon-button-column-text'}>預計</Typography>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

const CycleOrderBox = (props) => {
    const { className, cycleOrderData, handleShowDialog } = props;
    const { cycleOrderID, memberID } = cycleOrderData;
    const [showSubInfo, setShowSubInfo] = useState('');

    const handleToggle = (target) => {
        setShowSubInfo((prevState) => {
            let _target = target;
            if (prevState === target) _target = '';
            return _target;
        });
    };

    return (
        <React.Fragment>
            <CycleOrderCard
                className={className}
                data={cycleOrderData}
                showSubInfo={showSubInfo}
                handleToggle={handleToggle}
                handleShowDialog={handleShowDialog}
            />
            {showSubInfo === subInfoType.effectOrder && <PendingCycleEffectOrderList cycleOrderID={cycleOrderID} memberID={memberID} />}
            {showSubInfo === subInfoType.history && <CycleOrderRecordList cycleOrderID={cycleOrderID} memberID={memberID} />}
        </React.Fragment>
    );
};

export default CycleOrderBox;
