import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Edit = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M13.36,5.64l5,5L7.5,21.5,3,22a.93.93,0,0,1-1-.83A1.27,1.27,0,0,1,2,21l.5-4.46Zm8.09-.74L19.1,2.55a1.88,1.88,0,0,0-2.65,0L14.24,4.76l5,5,2.21-2.21a1.88,1.88,0,0,0,0-2.65Z" />
        </SvgIcon>
    );
});

export default Edit;
