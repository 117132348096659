import React from 'react';
import { debounce, ownerDocument, ownerWindow, getOffsetTop, getOffsetLeft } from '../../util/utils';
import useForkRef from '../../util/hook/useForkRef';
import Grow from '../grow/Grow';
import Modal from '../modal/Modal';
import Paper from '../paper/Paper';
import clsx from 'clsx';

const staticClass = `popover`;

const getTransformOriginValue = (transformOrigin) => {
    return [transformOrigin.horizontal, transformOrigin.vertical]
        .map((n) => (typeof n === 'number' ? `${n}px` : n))
        .join(' ');
};

const resolveAnchorEl = (anchorEl) => {
    return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
};

const Popover = React.forwardRef((props, ref) => {
    const {
        action,
        anchorEl,
        anchorOrigin = {
            vertical: 'top',
            horizontal: 'left',
        },
        anchorPosition,
        anchorReference = 'anchorEl',
        children,
        className,
        container: containerProp,
        elevation = 8,
        marginThreshold = 16,
        open,
        PaperProps = {},
        transformOrigin = {
            vertical: 'top',
            horizontal: 'left',
        },
        TransitionComponent = Grow,
        transitionDuration: transitionDurationProp = 'auto',
        TransitionProps: { onEntering, ...TransitionProps } = {},
        ...other
    } = props;

    const paperRef = React.useRef();
    const handlePaperRef = useForkRef(paperRef, PaperProps.ref);

    // Returns the top/left offset of the position
    // to attach to on the anchor element (or body if none is provided)
    const getAnchorOffset = React.useCallback(() => {
        if (anchorReference === 'anchorPosition') return anchorPosition;

        const resolvedAnchorEl = resolveAnchorEl(anchorEl);

        // If an anchor element wasn't provided, just use the parent body element of this Popover
        const anchorElement =
            resolvedAnchorEl && resolvedAnchorEl.nodeType === 1
                ? resolvedAnchorEl
                : ownerDocument(paperRef.current).body;
        const anchorRect = anchorElement.getBoundingClientRect();

        return {
            top: anchorRect.top + getOffsetTop(anchorRect, anchorOrigin.vertical),
            left: anchorRect.left + getOffsetLeft(anchorRect, anchorOrigin.horizontal),
        };
    }, [anchorEl, anchorOrigin.horizontal, anchorOrigin.vertical, anchorPosition, anchorReference]);

    // Returns the base transform origin using the element
    const getTransformOrigin = React.useCallback(
        (elemRect) => {
            return {
                vertical: getOffsetTop(elemRect, transformOrigin.vertical),
                horizontal: getOffsetLeft(elemRect, transformOrigin.horizontal),
            };
        },
        [transformOrigin.horizontal, transformOrigin.vertical]
    );

    const getPositioningStyle = React.useCallback(
        (element) => {
            const elemRect = {
                width: element.offsetWidth,
                height: element.offsetHeight,
            };

            // Get the transform origin point on the element itself
            const elemTransformOrigin = getTransformOrigin(elemRect);

            if (anchorReference === 'none') {
                return {
                    top: null,
                    left: null,
                    transformOrigin: getTransformOriginValue(elemTransformOrigin),
                };
            }

            // Get the offset of the anchoring element
            const anchorOffset = getAnchorOffset();

            // Calculate element positioning
            let top = anchorOffset.top - elemTransformOrigin.vertical;
            let left = anchorOffset.left - elemTransformOrigin.horizontal;
            const bottom = top + elemRect.height;
            const right = left + elemRect.width;

            // Use the parent window of the anchorEl if provided
            const containerWindow = ownerWindow(resolveAnchorEl(anchorEl));

            // Window thresholds taking required margin into account
            const heightThreshold = containerWindow.innerHeight - marginThreshold;
            const widthThreshold = containerWindow.innerWidth - marginThreshold;

            // Check if the vertical axis needs shifting
            if (top < marginThreshold) {
                const diff = top - marginThreshold;
                top -= diff;
                elemTransformOrigin.vertical += diff;
            } else if (bottom > heightThreshold) {
                const diff = bottom - heightThreshold;
                top -= diff;
                elemTransformOrigin.vertical += diff;
            }

            // Check if the horizontal axis needs shifting
            if (left < marginThreshold) {
                const diff = left - marginThreshold;
                left -= diff;
                elemTransformOrigin.horizontal += diff;
            } else if (right > widthThreshold) {
                const diff = right - widthThreshold;
                left -= diff;
                elemTransformOrigin.horizontal += diff;
            }

            return {
                top: `${Math.round(top)}px`,
                left: `${Math.round(left)}px`,
                transformOrigin: getTransformOriginValue(elemTransformOrigin),
            };
        },
        [anchorEl, anchorReference, getAnchorOffset, getTransformOrigin, marginThreshold]
    );

    const setPositioningStyles = React.useCallback(() => {
        const element = paperRef.current;

        if (!element) return;

        const positioning = getPositioningStyle(element);

        if (positioning.top !== null) element.style.top = positioning.top;
        if (positioning.left !== null) element.style.left = positioning.left;

        element.style.transformOrigin = positioning.transformOrigin;
    }, [getPositioningStyle]);

    const handleEntering = (element, isAppearing) => {
        onEntering && onEntering(element, isAppearing);
        setPositioningStyles();
    };

    React.useEffect(() => {
        open && setPositioningStyles();
    });

    React.useImperativeHandle(
        action,
        () =>
            open
                ? {
                      updatePosition: () => {
                          setPositioningStyles();
                      },
                  }
                : null,
        [open, setPositioningStyles]
    );

    React.useEffect(() => {
        if (!open) {
            return undefined;
        }

        const handleResize = debounce(() => {
            setPositioningStyles();
        });

        const containerWindow = ownerWindow(anchorEl);
        containerWindow.addEventListener('resize', handleResize);
        return () => {
            handleResize.clear();
            containerWindow.removeEventListener('resize', handleResize);
        };
    }, [anchorEl, open, setPositioningStyles]);

    let transitionDuration = transitionDurationProp;

    if (transitionDurationProp === 'auto' && !TransitionComponent.muiSupportAuto) {
        transitionDuration = undefined;
    }

    // If the container prop is provided, use that
    // If the anchorEl prop is provided, use its parent body element as the container
    // If neither are provided let the Modal take care of choosing the container
    const container = containerProp || (anchorEl ? ownerDocument(resolveAnchorEl(anchorEl)).body : undefined);
    const defaultPaperProperties = {
        elevation: elevation,
        ...PaperProps,
        ref: handlePaperRef,
        className: clsx(`${staticClass}-paper`, `${staticClass}-paper-border-radius`, PaperProps.className),
    };

    return (
        <Modal
            BackdropProps={{ invisible: true }}
            container={container}
            open={open}
            ref={ref}
            className={staticClass}
            {...other}
        >
            <TransitionComponent
                appear
                in={open}
                onEntering={handleEntering}
                timeout={transitionDuration}
                {...TransitionProps}
            >
                <Paper {...defaultPaperProperties}>{children}</Paper>
            </TransitionComponent>
        </Modal>
    );
});

export default Popover;
