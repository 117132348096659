import React from 'react';
import clsx from 'clsx';
import Tablenization from '../../components/Tablenization';
import {floatToPercentage, toFixed} from '../../../util/utils';

const rowsLabel = [
    {
        cellKey: 'itemName',
        headerLabel: '項目',
    },
    {
        cellKey: 'cumulative',
        headerLabel: '最新數字',
        align: 'center',
        formatCell: (value, { isRate }) => {
            let val = value;
            if (isRate) {
                val = floatToPercentage(value);
                val += '%';
            }
            return toFixed(val);
        },
    },
];

const HCMonthlyTable = (props) => {
    const { className, rowsData } = props;

    return (
        <Tablenization cardProps={{ className: clsx('daily-table', className) }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel} />
            <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={rowsData} />
        </Tablenization>
    );
};

export default HCMonthlyTable;
