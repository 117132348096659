import React, { useState } from 'react';
import { useGiftContext, GiftProvider } from '@icoach/gift/components/GiftContext';
import GiftSearchBar from '@icoach/gift/GiftSearchBar';
import GiftTable from '@icoach/gift/GiftTable';
import { initBookmark } from '@icoach/gift/staticData';

const GiftPage = () => {
    const giftContext = useGiftContext();
    const [sourceData, setSourceData] = useState({});
    const [bookmark, setBookmark] = useState(initBookmark());

    const updateGiftItemData = (giftData) => {
        const _list = sourceData.list;
        const targetID = giftData?.giftID ?? 0;
        const list = _list.map((item) => {
            if (item.giftID === targetID) {
                item = {
                    ...item,
                    ...giftData,
                };
            }
            return item;
        });

        setSourceData((prev) => {
            return {
                ...prev,
                list,
            };
        });
    };

    const childContext = {
        ...giftContext,
        sourceData,
        setSourceData,
        bookmark,
        setBookmarkNum: (num) => setBookmark((prev) => ({ ...prev, pageIndex: num })),
        updateGiftItemData,
    };

    return (
        <GiftProvider value={childContext}>
            <GiftSearchBar className={'mb-2'} />
            <GiftTable />
        </GiftProvider>
    );
};

export default GiftPage;
