import React from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { ErrorCircle as ErrorCircleIcon } from '../../../components/icons/SvgIcon/';
import { Tabs, Tab, TabPanel, FormLabel, SelectField, MenuItem, IconButton, Button } from '../../../components';

// normal(大眾) ,enterprise(企業/商業)
const categorys = [
    { label: '大眾優惠', value: '1' },
    { label: '企業優惠', value: '2' },
];

const normalOption = [
    { label: '當月優惠', optionName: 'monthlyPlan' },
    { label: '合約優惠', optionName: 'contractPlan' },
    { label: '其他優惠', optionName: 'otherPlan', isMultiple: true },
];

const enterpriseOption = [
    { label: '企業優惠', optionName: 'enterprisePlan' },
    { label: '合約優惠', optionName: 'contractPlan' },
];

/** 多個選擇 */
const MutipleBox = React.forwardRef((props, ref) => {
    const { optionData: optionDataProp = {}, defaultData: defaultDataProp = [], onChange: onChangeProp, readOnly = false } = props;
    const [option, setOption] = React.useState([]);
    const [seletKey, setSelectKey] = React.useState(uuid());
    const [selected, setSelected] = React.useState(false);
    const oriOptionRef = React.useRef([]);
    const pickItemRef = React.useRef([]);
    const selRef = React.useRef(null);
    const isMountedRef = React.useRef(false);

    const getFilterOption = (_oriOption, _pickOption) => _.pullAllBy(_oriOption.slice(), _pickOption.slice(), 'planID');

    const getPickItemOption = (_oriOption, _pickOption) => _.intersectionBy(_oriOption.slice(), _pickOption.slice(), 'planID');

    const handleOnChange = (e, n, _value) => {
        selRef.current = _value;
        setSelected(true);
    };

    React.useImperativeHandle(ref, () => ({
        getResult: () => pickItemRef.current.map(({ planID, planType }) => ({ planID, planType })),
    }));

    const handleAddPlanClick = () => {
        if (!selRef.current) return;
        const _opt = oriOptionRef.current.find(({ planID: _id }) => String(_id) === String(selRef.current));
        pickItemRef.current.push(_opt);
        const filterOption = getFilterOption(oriOptionRef.current, pickItemRef.current);
        setOption([...filterOption]);
        setSelectKey(uuid());
        setSelected(false);
        selRef.current = null;
        isMountedRef.current = true;
    };

    const handleOnDelete = (_id) => {
        pickItemRef.current = pickItemRef.current.filter(({ planID: _planID }) => String(_planID) !== String(_id));
        const selOption = getFilterOption(oriOptionRef.current, pickItemRef.current);
        setOption(selOption);
        setSelectKey(uuid());
        isMountedRef.current = true;
    };

    React.useEffect(() => {
        if (_.isEmpty(option)) {
            const _op = optionDataProp.option;
            oriOptionRef.current = _op.slice();
            pickItemRef.current = getPickItemOption(_op, defaultDataProp);
            setOption(getFilterOption(_op, defaultDataProp));
        }
        return () => (isMountedRef.current = false);
        // eslint-disable-next-line
    }, [optionDataProp]);

    React.useLayoutEffect(() => {
        if (isMountedRef.current && onChangeProp) onChangeProp();
        // eslint-disable-next-line
    }, [option, selected]);

    const pickItem = pickItemRef.current;
    const { label } = optionDataProp;
    return (
        <React.Fragment>
            <React.Fragment>
                <Grid item xs={3} className="text-right">
                    <FormLabel className="label">{label}</FormLabel>
                </Grid>
                <Grid item xs={9}>
                    <div className="field">
                        <SelectField key={seletKey} defaultValue={''} onChange={handleOnChange} fullWidth={false} readOnly={readOnly} displayEmpty>
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {option.map(({ planID: _id, planName: _name }) => (
                                <MenuItem key={_name} value={_id}>
                                    {_name}
                                </MenuItem>
                            ))}
                        </SelectField>
                        {!readOnly && (
                            <Button variant="contained" color="secondary" onClick={handleAddPlanClick} disabled={!selected}>
                                新增優惠
                            </Button>
                        )}
                    </div>
                    {pickItem.length > 0 && (
                        <ul className="multiple-options desc">
                            {pickItem.map(({ planID: _id, planDescription, planName }) => {
                                return (
                                    <li key={_id} className="multiple-options-item">
                                        <span>
                                            {`【${label}】${planName}`}
                                            <span className="text-info ml-2">{planDescription}</span>
                                        </span>
                                        {!readOnly && (
                                            <IconButton onClick={() => handleOnDelete(_id)}>
                                                <ErrorCircleIcon />
                                            </IconButton>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </Grid>
            </React.Fragment>
        </React.Fragment>
    );
});

/** 單一選擇 */
const NormalBox = React.forwardRef((props, ref) => {
    const { label, option: optionProp = [], defaultData: defaultDataProp = [], onChange: onChangeProp, readOnly = false } = props;
    const [option, setoption] = React.useState([]);
    const [value, setValue] = React.useState('');
    const initMounted = React.useRef(false);

    React.useImperativeHandle(ref, () => ({
        getResult: () => {
            const { planID = '', planType = '' } = getOptionData(option, value) || {};
            return planID ? [{ planID, planType }] : [];
        },
    }));

    const handleOnChange = (e, n, _value) => {
        setValue(_value);
        initMounted.current = true;
    };

    const handleOnDelete = () => {
        setValue('');
        initMounted.current = true;
    };

    const getOptionData = (_opt, _value) => _opt.find(({ planID }) => String(planID) === String(_value));

    React.useEffect(() => {
        if (_.isEmpty(option) && !_.isEmpty(optionProp)) {
            const { planID = '' } = _.intersectionBy(optionProp, defaultDataProp, 'planID')[0] || {};
            setoption(optionProp);
            setValue(planID);
        }
        return () => (initMounted.current = false);
        //eslint-disable-next-line
    }, [optionProp]);

    React.useLayoutEffect(() => {
        if (initMounted.current && onChangeProp) onChangeProp();
        //eslint-disable-next-line
    }, [value]);

    const targetData = getOptionData(option, value);

    return (
        <React.Fragment>
            <Grid item xs={3} className="text-right">
                <FormLabel className="label">{label}</FormLabel>
            </Grid>
            <Grid item xs={9}>
                <div className="field mr-2">
                    <SelectField value={value} onChange={handleOnChange} fullWidth={false} readOnly={readOnly} displayEmpty>
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {option.map(({ planID: _id, planName: _name }) => {
                            return (
                                <MenuItem key={_name} value={_id} selected={String(_id) === '3'}>
                                    {_name}
                                </MenuItem>
                            );
                        })}
                    </SelectField>
                    {!readOnly && !_.isEmpty(targetData) && (
                        <IconButton onClick={handleOnDelete}>
                            <ErrorCircleIcon />
                        </IconButton>
                    )}
                </div>
                {!_.isEmpty(targetData) && <p className="desc text-info">{targetData.planDescription}</p>}
            </Grid>
        </React.Fragment>
    );
});
/** 處理Get選單所有參數邏輯 */
const PromotionBox = React.forwardRef((props, ref) => {
    const { optionEnums: optionEnumsProp = {}, typeOption, defaultData: defaultDataProp = [], onChange: onChangeProp, readOnly } = props;
    const [options, setOptions] = React.useState([]);
    const normalRef = React.useRef([]); //  normalSelect
    const mutiRef = React.useRef(null); //  mutipleSelect

    const getAllParams = () => {
        let getAllPlanParams = [];
        if (normalRef.current) {
            normalRef.current.forEach((item) => {
                if (item && item.getResult) getAllPlanParams.push(...item.getResult());
            });
        }
        if (mutiRef.current) {
            getAllPlanParams.push(...mutiRef.current.getResult());
        }
        return getAllPlanParams;
    };

    React.useImperativeHandle(ref, () => ({
        getResult: () => getAllParams(),
    }));

    React.useEffect(() => {
        if (_.isEmpty(options) && Object.values(optionEnumsProp).every((x) => x)) {
            const _options = typeOption.map(({ optionName: _name, ...another }) => {
                return { option: optionEnumsProp[_name] || [], ...another, optionName: _name };
            });
            setOptions(_options);
        }
        // eslint-disable-next-line
    }, [optionEnumsProp]);

    const handleOnChange = () => {
        let getAllPlans = getAllParams();
        onChangeProp && onChangeProp(getAllPlans);
    };

    const otherPlanOption = options.find(({ optionName }) => optionName === 'otherPlan');
    return (
        <Grid container spacing={3}>
            {options.length > 0 ? (
                <React.Fragment>
                    {options.map(({ label, option, isMultiple }, _index) => {
                        if (isMultiple) return null;
                        return (
                            <NormalBox
                                ref={(el) => (normalRef.current[_index] = el)}
                                key={label}
                                label={label}
                                option={option}
                                defaultData={defaultDataProp}
                                onChange={handleOnChange}
                                readOnly={readOnly}
                            />
                        );
                    })}
                    {otherPlanOption && (
                        <MutipleBox ref={mutiRef} defaultData={defaultDataProp} optionData={otherPlanOption} onChange={handleOnChange} readOnly={readOnly} />
                    )}
                </React.Fragment>
            ) : null}
        </Grid>
    );
});

const Promotions = React.forwardRef((props, ref) => {
    const { source: optionEnums = {}, defaultData: defaultDataProp, onChange: onChangeProp, planTab = '1', readOnly = false } = props;
    let [tabsIndex, setTabsIndex] = React.useState(categorys[0]['value']);
    const paramsRef = React.useRef(null);
    React.useImperativeHandle(ref, () => {
        return {
            getResult: () => paramsRef.current.getResult(),
        };
    });

    const handleChangeCategory = (e, _value) => {
        if (readOnly) return false;
        setTabsIndex(_value);
        onChangeProp && onChangeProp([]);
    };

    const handleOnChange = (_date) => {
        onChangeProp && onChangeProp(_date);
    };

    React.useEffect(() => {
        setTabsIndex(String(planTab));
    }, [planTab]);

    return (
        <div className="promotions">
            <Tabs value={tabsIndex} className="promotions-control" onChange={handleChangeCategory}>
                {categorys.map(({ label: _label, value: _value }) => {
                    return <Tab key={_value} label={_label} value={_value} />;
                })}
            </Tabs>
            <TabPanel value={categorys[0]['value']} index={tabsIndex} className="promotions-panel">
                <PromotionBox
                    ref={paramsRef}
                    optionEnums={optionEnums}
                    defaultData={defaultDataProp}
                    onChange={handleOnChange}
                    typeOption={normalOption}
                    readOnly={readOnly}
                />
            </TabPanel>
            <TabPanel value={categorys[1]['value']} index={tabsIndex} className="promotions-panel">
                <PromotionBox
                    ref={paramsRef}
                    optionEnums={optionEnums}
                    defaultData={defaultDataProp}
                    onChange={handleOnChange}
                    typeOption={enterpriseOption}
                    readOnly={readOnly}
                />
            </TabPanel>
        </div>
    );
});

export default Promotions;
