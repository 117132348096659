import React from 'react';

const useControlled = ({ controlled, default: defaultProp }) => {
    // isControlled is ignored in the hook dependency lists as it should never change.
    const { current: isControlled } = React.useRef(controlled !== undefined);
    const [valueState, setValue] = React.useState(defaultProp);
    const value = isControlled ? controlled : valueState;

    const setValueIfUncontrolled = React.useCallback(
        (newValue) => {
            if (!isControlled) {
                setValue(newValue);
            }
        },
        [isControlled]
    );

    return [value, setValueIfUncontrolled];
};

export default useControlled;
