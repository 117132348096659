import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Tablenization from '@icoach/components/Tablenization';
import { parseDate } from '@util/moment';
import { moreColData } from '@icoach/documents/components/moreColData';
import { pathRouterShit } from '@util/utils';
import {
    DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
} from '@icoach/router/routerPath';
import useDocMembershipQuitApi from '@apis/useDocMembershipQuitApi';
import { ConfirmDeleteDocQuitDialog } from '@icoach/documents/membership/dialog';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import useToggleDialog from '@util/hook/useToggleDialog';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

const rowsLabel = [
    {
        cellKey: 'applyDate',
        headerLabel: '申請時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayDateBySlash, false),
    },
    {
        cellKey: 'changeDate',
        headerLabel: '最後更新時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayFullBySlash, false),
    },
    {
        cellKey: 'isTemporary',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (isTemporary, { isDeleted }) => {
            return isTemporary ? '暫存' : isDeleted ? '已刪除' : '已受理';
        },
    },
    moreColData,
];

const MembershipQuitHistoryTable = (props) => {
    const { className, rowsData = [] } = props;
    const history = useHistory();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const { getDocMembershipQuitPDFApi, deleteDocMembershipQuitTemporaryApi, deleteDocMembershipQuitContractApi } = useDocMembershipQuitApi();
    const { openTarget, handleOpenDialog, handleCloseDialog, rowData: sourceData } = useToggleDialog();
    const labelTitle = useRef(rowsLabel);

    // 查看
    const handleReviewOnClick = (rowData) => {
        const { contractID } = rowData;
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER, {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        });
        history.push(path);
    };

    // 編輯
    const handleEditOnClick = (rowData) => {
        const { contractID } = rowData;
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        });
        history.push(path);
    };

    // 下載
    const handleDownloadPDFOnClick = (rowData) => {
        const { contractUrl } = rowData;
        getDocMembershipQuitPDFApi(contractUrl);
    };

    // 刪除暫停合約資料
    const doDeleteApi = async (rowData) => {
        const { isTemporary, contractID, ...others } = rowData;
        let resp;
        if (isTemporary) {
            resp = await deleteDocMembershipQuitTemporaryApi({
                type: peopleTypeProps,
                id: referenceIDProps,
            });
        } else {
            resp = await deleteDocMembershipQuitContractApi(others, contractID);
        }
        return resp;
    };

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={rowsData}
                    onReviewEvent={handleReviewOnClick}
                    onEditEvent={handleEditOnClick}
                    onDownloadPDFEvent={handleDownloadPDFOnClick}
                    onDeleteOnEvent={(rowData) => {
                        handleOpenDialog(dialogTypeMap.deleteDoc, { rowData });
                    }}
                />
            </Tablenization>
            <ConfirmDeleteDocQuitDialog
                title={sourceData?.isTemporary ? '確認刪除暫存終止申請書' : '確認刪除終止申請書'}
                open={openTarget === dialogTypeMap.deleteDoc}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                deleteDocApi={doDeleteApi}
                refresh={() => {
                    const path = pathRouterShit(DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER, {
                        peopleType: peopleTypeProps,
                        referenceID: referenceIDProps,
                    });
                    history.replace(path);
                }}
            />
        </React.Fragment>
    );
};

export default MembershipQuitHistoryTable;
