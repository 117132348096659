import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Bars = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d={"M.786,6.348H21.214A.786.786,0,0,0,22,5.563V3.6a.786.786,0,0,0-.786-.786H.786A.786.786,0,0,0,0,3.6V5.563A.786.786,0,0,0,.786,6.348Zm0,7.857H21.214A.786.786,0,0,0,22,13.42V11.455a.786.786,0,0,0-.786-.786H.786A.786.786,0,0,0,0,11.455V13.42A.786.786,0,0,0,.786,14.205Zm0,7.857H21.214A.786.786,0,0,0,22,21.277V19.313a.786.786,0,0,0-.786-.786H.786A.786.786,0,0,0,0,19.313v1.964A.786.786,0,0,0,.786,22.063Z"} transform={"translate(1 -0.688)"}/>
        </SvgIcon>
    );
});

export default Bars;
