import {useCallback} from 'react';
import useAjax from '@apis/useAjax';
import {useSnackbar} from 'notistack';

const GET_POINTS_REWARDS_OVERVIEW_OPTIONS_API = `/api/points/rewards/overview/options/`;
const GET_POINTS_REWARDS_OVERVIEW_API = `/api/points/rewards/overview/`;
const GET_POINTS_REWARDS_REWARDID_RECORDS_API = (rewardID) => `/api/points/rewards/${rewardID}/records/`;
export const DELETE_PONITS_REWARDS_REWARDID_API = (rewardID) => `/api/points/rewards/${rewardID}`;
const GET_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API = (rewardID, activityID) =>
    `/api/points/rewards/${rewardID}/activity/${activityID}/redeem-cart/`;
const POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API = GET_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API;
const POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_UNREDEEMED_API = (rewardID, activityID) =>
    `/api/points/rewards/${rewardID}/activity/${activityID}/un-redeem/`;
const POST_POINTS_REWARDS_REWARDID_ACCUMULATE_API = (rewardID) => `/api/points/rewards/${rewardID}/accumulate/`;
const POST_POINTS_REWARDS_REWARDID_REDEEM_STATUS_REDEEMRECORDID_API = (rewardID, redeemRecordID) =>
    `/api/points/rewards/${rewardID}/redeem-status/${redeemRecordID}/`;
const GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_API = (redeemItemID) => `/api/points/redeem-items/${redeemItemID}/`;
const GET_POINTS_REWARDS_EXPORT_API = `/api/points/rewards/export`;
const GET_POINTS_REDEEM_ORDERS_ALL_RECORDS_API = `/api/points/redeem-orders/all-records/`;
const POST_POINTS_REDEEM_ORDERS_SYNC_TASKS_API = `/api/points/redeem-orders/sync-tasks/`;

const usePointsApi = () => {
    const Ajax = useAjax();
    const {enqueueSnackbar} = useSnackbar();

    const getPointsRewardsOverviewOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_POINTS_REWARDS_OVERVIEW_OPTIONS_API);
            if (!resp) {
                enqueueSnackbar('讀取資料錯誤，請重新整理畫面', {variant: 'error'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardsOverviewApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_POINTS_REWARDS_OVERVIEW_API, params);
            if (!resp) {
                enqueueSnackbar(resp.message, {variant: 'error'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardsRecordApi = useCallback(
        async (params, rewardID) => {
            const resp = await Ajax.get(GET_POINTS_REWARDS_REWARDID_RECORDS_API(rewardID), params);
            if (!resp) {
                enqueueSnackbar(resp.message, {variant: 'error'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardsActivityRedeemCartApi = useCallback(
        async (rewardID, activityID) => {
            const resp = await Ajax.get(GET_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API(rewardID, activityID));
            if (!resp) {
                const {message = ''} = resp || {};
                enqueueSnackbar(message || '讀取資料錯誤，請重新再操作一次', {variant: 'error'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postPointsRewardsActivityRedeemCartApi = useCallback(
        async (params, rewardID, activityID) => {
            const resp = await Ajax.post(POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API(rewardID, activityID), params);
            if (!resp) {
                const {message = ''} = resp || {};
                enqueueSnackbar(message || '新增失敗，請重新再操作一次', {variant: 'error'});
            } else if (!resp.isError) {
                enqueueSnackbar('更新成功', {variant: 'success'});
                return resp;
            }
            return null;
        },
        // eslint-disable-next-line
        []
    );

    const postPointsRewardsActivityUnredeemedApi = useCallback(
        async (rewardID, activityID) => {
            const resp = await Ajax.post(POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_UNREDEEMED_API(rewardID, activityID), {});
            if (!resp) {
                const {message = ''} = resp || {};
                enqueueSnackbar(message || '新增失敗，請重新再操作一次', {variant: 'error'});
            } else if (!resp.isError) {
                enqueueSnackbar('更新成功', {variant: 'success'});
                return resp;
            }
            return null;
        },
        // eslint-disable-next-line
        []
    );

    const postPointsRewardsIdAccumulateApi = useCallback(
        async (params, rewardID) => {
            const resp = await Ajax.post(POST_POINTS_REWARDS_REWARDID_ACCUMULATE_API(rewardID), params);
            if (!resp) {
                enqueueSnackbar('更新失敗請重新再操作一次', {variant: 'error'});
            } else {
                enqueueSnackbar('補點成功', {variant: 'success'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postPointsRewardsRedeemStatusApi = useCallback(
        async (params, rewardID, redeemRecordID) => {
            const resp = await Ajax.post(POST_POINTS_REWARDS_REWARDID_REDEEM_STATUS_REDEEMRECORDID_API(rewardID, redeemRecordID), params);
            if (!resp) {
                enqueueSnackbar('更新失敗請重新再操作一次', {variant: 'error'});
            } else {
                enqueueSnackbar('簽收成功', {variant: 'success'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得兌換品內容
    const getPointsRedeemItemsApi = useCallback(
        async (redeemItemID) => {
            const resp = await Ajax.get(GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_API(redeemItemID));
            if (!resp) {
                enqueueSnackbar('取得商品失敗，請重新再操作一次', {variant: 'error'});
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得兌換品內容
    const getRewardsExportApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_POINTS_REWARDS_EXPORT_API, params);
            if (resp && typeof resp === 'string') {
                enqueueSnackbar(resp, {variant: 'success'});
                return null;
            } else if (resp && Object.prototype.toString.call(resp) === '[object Object]') {
                return resp;
            }
        },
        // eslint-disable-next-line
        []
    );

    // 取得兌換品內容
    const getPointsRedeemOrdersAllRecordsApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_POINTS_REDEEM_ORDERS_ALL_RECORDS_API, params);
            if (resp) {
                return resp;
            }
        },
        // eslint-disable-next-line
        []
    );

    // 取得兌換品內容
    const postPointsRedeemOrdersSyncTasksApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_POINTS_REDEEM_ORDERS_SYNC_TASKS_API, params);
            if (resp) {
                enqueueSnackbar('成功通知', {variant: 'success'});
                return resp;
            }
        },
        // eslint-disable-next-line
        []
    );

    const deletePointsRewardApi = useCallback(
        async (rewardID) => {
            const resp = await Ajax.delete(DELETE_PONITS_REWARDS_REWARDID_API(rewardID), {});
            if (resp) {
                enqueueSnackbar('刪除成功', {variant: 'success'});
                return resp;
            }
        },
        // eslint-disable-next-line
        []
    );

    return {
        getPointsRewardsOverviewOptionsApi,
        getPointsRewardsOverviewApi,
        getPointsRewardsRecordApi,
        getPointsRewardsActivityRedeemCartApi,
        postPointsRewardsActivityRedeemCartApi,
        postPointsRewardsActivityUnredeemedApi,
        postPointsRewardsIdAccumulateApi,
        postPointsRewardsRedeemStatusApi,
        getPointsRedeemItemsApi,
        getRewardsExportApi,
        getPointsRedeemOrdersAllRecordsApi,
        postPointsRedeemOrdersSyncTasksApi,
        deletePointsRewardApi,
    };
};

export default usePointsApi;
