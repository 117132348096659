import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const UserAlt = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,13.2c3.1,0,5.6-2.5,5.6-5.6S15.1,2,12,2S6.4,4.5,6.4,7.6
	C6.4,10.7,8.9,13.2,12,13.2z M17,14.5h-2.2c-1.8,0.8-3.9,0.8-5.7,0H7c-2.8,0-5,2.2-5,5v0.6c0,1,0.8,1.9,1.9,1.9l0,0h16.2
	c1,0,1.9-0.8,1.9-1.9l0,0v-0.6C22,16.7,19.8,14.5,17,14.5z"
            />
        </SvgIcon>
    );
});

export default UserAlt;
