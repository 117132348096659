import React, { useImperativeHandle, useRef, useEffect } from 'react';
import parseISO from 'date-fns/parseISO';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Typography, Stack, Box, IconButton } from '@common/components/';
import { CalendarDays as CalendarDaysIcon, NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon } from '@common/SvgIcon/';
import { parseDate, getAddDays, getSubDays, isValidDate, getDiffDate } from '@util/moment';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';
/**
 * isMountedRef 當作初始時，不去觸發外部的事件
 * useEffect    專門偵測value的變化，去觸發外部提供的事件
 */
const MobileDatePickerBox = React.forwardRef((props, ref) => {
    const {
        className,
        value: valueProp,
        format: inputFormat = 'YYYY/MM/DD',
        maxDate: maxDateProp,
        minDate: minDateProp,
        onChange: onChangeProp,
        dateFontSize = 'h3',
        showCalendarIcon = true,
    } = props;

    const [value, setValue] = React.useState(valueProp || parseDate());
    const isMountedRef = useRef(false);

    const handleNextOnClick = () => {
        if (!maxDateProp || !isValidDate(maxDateProp) || (maxDateProp && isValidDate(maxDateProp) && getDiffDate(maxDateProp, value) > 0)) {
            setValue(getAddDays(value, 1));
        } else {
            alert('已達最高限制');
        }
    };

    const handlePreOnClick = () => {
        if (!minDateProp || !isValidDate(minDateProp) || (minDateProp && isValidDate(minDateProp) && getDiffDate(minDateProp, value) < 0)) {
            setValue(getSubDays(value, 1));
        } else {
            alert('已達最低限制');
        }
    };

    const handleOnChange = (_value) => {
        onChangeProp && onChangeProp(_value);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    return value;
                },
            };
        },
        // eslint-disable-next-line
        [value]
    );

    useEffect(
        () => {
            if (isMountedRef.current) handleOnChange(value);
            isMountedRef.current = true;
        },
        // eslint-disable-next-line
        [value]
    );

    return (
        <Stack className={className} alignItems={'center'} justifyContent="center">
            <IconButton onClick={handlePreOnClick}>
                <NavigateBeforeIcon />
            </IconButton>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={'zh-tw'}
                localeText={{
                    cancelButtonLabel: '取消',
                    clearButtonLabel: '清空',
                    okButtonLabel: '確定',
                    todayButtonLabel: '今日',
                }}
            >
                <MobileDatePicker
                    componentsProps={{ actionBar: { className: 'mobile-date-picker-action' } }}
                    toolbarTitle="請選擇日期"
                    showToolbar={false}
                    views={['year', 'month', 'day']}
                    mask="____/__/__"
                    inputFormat={inputFormat}
                    value={value}
                    maxDate={maxDateProp ? parseISO(maxDateProp) : null}
                    minDate={minDateProp ? parseISO(minDateProp) : null}
                    onChange={(newValue) => {
                        const formatDate = newValue ? newValue['$d'] : new Date('');
                        setValue(formatDate);
                    }}
                    renderInput={({ inputRef, inputProps, InputProps, ...other }) => {
                        return (
                            <Box className="cursor-pointer">
                                <Stack alignItems={'center'} spacing={1} onClick={inputProps.onClick}>
                                    {showCalendarIcon && <CalendarDaysIcon color="primary" />}
                                    <Typography style={{ fontWeight: 500 }} variant="span" className={dateFontSize} ref={inputRef}>{`${parseDate(
                                        inputProps.value,
                                        DateTimeStandard.DisplayDateOfWeek
                                    )}`}</Typography>
                                </Stack>
                            </Box>
                        );
                    }}
                />
            </LocalizationProvider>
            <IconButton onClick={handleNextOnClick}>
                <NavigateNextIcon />
            </IconButton>
        </Stack>
    );
});

export default MobileDatePickerBox;
