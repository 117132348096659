import React, {useCallback, useRef} from 'react';
import {isEmpty} from 'lodash';
import clsx from 'clsx';
import Tablenization from '../../components/Tablenization';
import {DateLabel} from '../components';
import {isValidDate} from '../../../util/moment';
import TableLabel from "@icoach/dataAnalysis/components/TableLabel";
// import { getRowsDataMaxValue, getRowsDataTodayValue, getRowsDataTotal } from '../staticData';

const rowsLabel = [
    {
        cellKey: 'date',
        headerLabel: () => {
            return;
        },
        width: 12,
        formatCell: (date) => {
            if (isValidDate(date)) return <DateLabel date={date}/>;
            return <TableLabel title={'最新數字'} tip={'當月1日至今日的統計數值'}/>;
        },
    },
    {
        cellKey: 'newSales',
        headerLabel: '新銷售',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'expiredRenew',
        headerLabel: '補續約',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'drop',
        headerLabel: '退會',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'notYetRenew',
        headerLabel: '未續約',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'leave',
        headerLabel: '請假',
        align: 'center',
        minWidth: 98,
    },

    {
        cellKey: 'moveOut',
        headerLabel: '移出',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'moveIn',
        headerLabel: '移入',
        align: 'center',
        minWidth: 98,
    },
    {
        cellKey: 'increase',
        headerLabel: '純增',
        align: 'center',
        minWidth: 98,
    },
];

const MembershipDailyTable = (props) => {
    const {rowsData, cumulative, className} = props;
    const labelTitle = useRef(rowsLabel);

    const handleRowsData = useCallback(
        () => {
            let totalRow = [
                {
                    ...cumulative,
                    date: 'cumulative'
                },
            ];
            let array = [...rowsData];
            if (!isEmpty(totalRow)) array = totalRow.concat(array);
            return array;
        },
        // eslint-disable-next-line
        [rowsData],
    );

    return (
        <Tablenization cardProps={{className: clsx('daily-table', className)}}>
            <Tablenization.TableRowHeader headerRow={labelTitle.current}/>
            <Tablenization.TableRowBody
                headerRow={labelTitle.current}
                rowsData={handleRowsData()}
                bodyRowClass={{
                    fn: (data) => {
                        if (data.date === 'cumulative') return 'total-row';
                        else if (data.skipSummary === true) return 'table-row-invalid';
                        return '';
                    }
                }}
            />
        </Tablenization>
    );
};

export default MembershipDailyTable;
