import React from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import { Button, Typography, Alert } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useTemporaryApi from '@apis/useTemporaryApi';

// 刪除暫存內所儲存的列表人員
const RemoveTemparyCustomersDialog = (props) => {
    const { onClose: propsOnClose, open, sourceData = [], refresh, refreshParams = [] } = props;
    const { id: temporaryID } = useParams();
    const { title } = useNonMember();
    const { deleteTemporaryCustomersApi } = useTemporaryApi();

    const handleCloseOnClick = () => {
        propsOnClose(null);
    };

    const handleSendOnClick = (e) => {
        const params = { targetIDs: sourceData.map(({ customerID }) => customerID) };
        removeTemparyDataApi(params);
    };

    const removeTemparyDataApi = async (params) => {
        const resp = await deleteTemporaryCustomersApi(temporaryID, params);
        if (resp) {
            propsOnClose(null);
            refresh && refresh.current && refresh.current(...refreshParams);
        }
    };

    return (
        <Dialog open={open}>
            <GradientColorHeader onClose={handleCloseOnClick}>刪除暫存資料</GradientColorHeader>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Alert severity="remove" variant="filled" color="error">
                            確認將以下資訊從【{title}】刪除({sourceData.length})?
                        </Alert>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="span" className="text-gray-500">
                                    {'姓名'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="span" className="text-gray-500">
                                    {'電話'}
                                </Typography>
                            </Grid>
                        </Grid>
                        {sourceData.map((target, index) => {
                            const { name, cellPhone } = target;
                            return (
                                <Grid container spacing={1} key={`remove_tempary_${index}`}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        <Typography className="ml-5" variant="h5" compoment="span">
                                            {name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        {cellPhone && (
                                            <Typography className="ml-3" variant="h5" compoment="span">
                                                {cellPhone}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size="large" variant="contained" color="error" onClick={handleSendOnClick}>
                    確定
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveTemparyCustomersDialog;
