import {generateRandomString} from "@util/utils";
import CryptoJS from "crypto-js/crypto-js";

/**
 * 產生API發送時需加密的字串
 * @source {string}
 * @returns hmacSecurity, hmacKey {string, string}
 */
export const generateSecurity = (source) => {
    
    const doubleMd5Security = getDoubleMD5Hash(source);
    const hmacKey = generateRandomString(32);
    const hmacHash = CryptoJS.HmacSHA256(doubleMd5Security, hmacKey);
    const hmacSecurity = CryptoJS.enc.Base64.stringify(hmacHash);
    
    return {hmacSecurity, hmacKey};
}

/**
 * 取得MD5雜湊加密後的Base64文字
 * @param {string} source 需加密文字
 * @returns {string} 加密後的Base64文字
 */
const getMD5Hash = (source) => {
    const md5Hash = CryptoJS.MD5(source);
    return md5Hash.toString(CryptoJS.enc.Base64);
};

/**
 * 取得MD5加密後的十六進位文字
 * @param {string} source 需加密文字
 * @returns {string} 加密後的十六進位文字
 */
function getMD5(source) {
    if (!source) return '';

    const md5Hash = CryptoJS.MD5(source);
    return md5Hash.toString(CryptoJS.enc.Hex);
}

/**
 * 取得Double MD5加密
 * @param {string} source 需加密文字
 * @returns {string} 加密後文字
 */
const getDoubleMD5Hash = (source) => {
    return getMD5(getMD5Hash(source));
};