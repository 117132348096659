import React from 'react';
import _ from 'lodash';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { Button, Stack, Typography } from '../../../components';
import { parseDate } from '../../../util/moment';
import useInBodyApi from '../../../apis/useInBodyApi';

const InBodyDeleteMeasureDialog = React.forwardRef((props, ref) => {
    const {
        open: propsOpen = false,
        onClose: propsOnClose,
        sourceData = {},
        measureID,
        refresh,
    } = props;
    const { deleteMeasureApi } = useInBodyApi();

    const handleSendOnClick = () => {
        doDeleteApi();
    };

    const doDeleteApi = async () => {
        await deleteMeasureApi(measureID);
        propsOnClose();
        refresh && refresh();
    };

    return (
        <Dialog open={propsOpen} PaperProps={{ className: 'wx-40rem' }} fullWidth>
            {!_.isEmpty(sourceData) && (
                <React.Fragment>
                    <GradientColorHeader onClose={propsOnClose}>刪除量身資料</GradientColorHeader>
                    <DialogContent className="mt-3">
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="column"
                            spacing={2}
                        >
                            <Typography variant="h5" component={'span'}>
                                確定要刪除量身日期為
                            </Typography>
                            <Typography variant="h4" component={'span'}>
                                {parseDate(sourceData.measureDate)}
                            </Typography>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'contained'} onClick={handleSendOnClick}>
                            確定
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </Dialog>
    );
});

export default InBodyDeleteMeasureDialog;
