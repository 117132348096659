import React from 'react';
import Portal from '../portal/Portal';
import ModalManager, { ariaHidden } from './ModalManager';
import { createChainedFunction, ownerDocument } from '../../util/utils';
import useForkRef from '../../util/hook/useForkRef';
import useEventCallback from '../../util/hook/useEventCallback';

const getContainer = (container) => {
    return typeof container === 'function' ? container() : container;
};

const getHasTransition = (props) => {
    return props.children ? props.children.props.hasOwnProperty('in') : false;
};

const defaultManager = new ModalManager();

const ModalUnstyled = React.forwardRef((props, ref) => {
    const {
        // private
        // eslint-disable-next-line react/prop-types
        manager = defaultManager,
        BackdropComponent,
        BackdropProps,
        children,
        className,
        closeAfterTransition = false,
        component = 'div',
        components = {},
        container,
        // disableAutoFocus = false,
        // disableEnforceFocus = false,
        // disableRestoreFocus = false,
        disableEscapeKeyDown = false,
        disablePortal = false,
        disableScrollLock = false,
        hideBackdrop = false,
        keepMounted = false,
        onBackdropClick,
        onClose,
        onKeyDown,
        open,
        onTransitionEnter,
        onTransitionExited,
        // ...other
    } = props;

    const [exited, setExited] = React.useState(true);
    const modal = React.useRef({});
    const mountNodeRef = React.useRef(null);
    const modalRef = React.useRef(null);
    const handleRef = useForkRef(modalRef, ref);
    const hasTransition = getHasTransition(props);

    const getDoc = () => ownerDocument(mountNodeRef.current);
    const getModal = () => {
        modal.current.modalRef = modalRef.current;
        modal.current.mountNode = mountNodeRef.current;
        return modal.current;
    };

    const handleMounted = () => {
        manager.mount(getModal(), { disableScrollLock });
        // Fix a bug on Chrome where the scroll isn't initially 0.
        modalRef.current.scrollTop = 0;
    };

    const handleOpen = useEventCallback(() => {
        const resolvedContainer = getContainer(container) || getDoc().body;
        manager.add(getModal(), resolvedContainer);
        // The element was already mounted.
        if (modalRef.current) {
            handleMounted();
        }
    });

    const isTopModal = React.useCallback(() => manager.isTopModal(getModal()), [manager]);

    const handlePortalRef = useEventCallback((node) => {
        mountNodeRef.current = node;

        if (!node) return;
        if (open && isTopModal()) handleMounted();
        else ariaHidden(modalRef.current, true);
    });

    const handleClose = React.useCallback(() => {
        manager.remove(getModal());
    }, [manager]);

    React.useEffect(() => {
        return () => handleClose();
    }, [handleClose]);

    React.useEffect(() => {
        if (open) handleOpen();
        else if (!hasTransition || !closeAfterTransition) handleClose();
    }, [open, handleClose, hasTransition, closeAfterTransition, handleOpen]);

    // const defaultProperty = {
    //     closeAfterTransition,
    //     disableAutoFocus,
    //     disableEnforceFocus,
    //     disableEscapeKeyDown,
    //     disablePortal,
    //     disableRestoreFocus,
    //     disableScrollLock,
    //     exited,
    //     hideBackdrop,
    //     keepMounted,
    // };

    if (!keepMounted && !open && (!hasTransition || exited)) {
        return null;
    }

    const handleEnter = () => {
        setExited(false);

        if (onTransitionEnter) {
            onTransitionEnter();
        }
    };

    const handleExited = () => {
        setExited(true);

        if (onTransitionExited) {
            onTransitionExited();
        }

        if (closeAfterTransition) {
            handleClose();
        }
    };

    const handleBackdropClick = (event) => {
        if (event.target !== event.currentTarget) return;
        if (onBackdropClick) onBackdropClick(event);
        if (onClose) onClose(event, 'backdropClick');
    };

    const handleKeyDown = (event) => {
        if (onKeyDown) {
            onKeyDown(event);
        }
        // The handler doesn't take event.defaultPrevented into account:
        //
        // event.preventDefault() is meant to stop default behaviors like
        // clicking a checkbox to check it, hitting a button to submit a form,
        // and hitting left arrow to move the cursor in a text input etc.
        // Only special HTML elements have these default behaviors.
        if (event.key !== 'Escape' || !isTopModal()) {
            return;
        }

        if (!disableEscapeKeyDown) {
            // Swallow the event, in case someone is listening for the escape key on the body.
            event.stopPropagation();
            if (onClose) onClose(event, 'escapeKeyDown');
        }
    };

    const childProps = {};
    if (children.props.tabIndex === undefined) {
        childProps.tabIndex = '-1';
    }

    // It's a Transition like component
    if (hasTransition) {
        childProps.onEnter = createChainedFunction(handleEnter, children.props.onEnter);
        childProps.onExited = createChainedFunction(handleExited, children.props.onExited);
    }
    const Root = components.Root || component;
    // console.log(BackdropProps);
    return (
        <Portal ref={handlePortalRef} container={container} disablePortal={disablePortal}>
            <Root role="presentation" ref={handleRef} className={className} onKeyDown={handleKeyDown}>
                {!hideBackdrop && BackdropComponent ? (
                    <BackdropComponent open={open} onClick={handleBackdropClick} {...BackdropProps} />
                ) : null}
                {React.cloneElement(children, childProps)}
            </Root>
        </Portal>
    );
});

export default ModalUnstyled;
