import React, { useState } from 'react';
import useLanding from '@icoach/landingPage/components/useLanding';
import { Chip } from '@common/components/';
import useAppointmentApi from '@apis/useAppointmentApi';

// CC教練設定
const AppointmentCircuitDutyItem = (props) => {
    const { time: timeProps, date: dateProps, options, value: valueProps, name: nameProps } = props;
    const { dialogRef } = useLanding();
    const { putAppointmentCircuitApi } = useAppointmentApi();
    const [employeeData, setEmployeeData] = useState({
        id: valueProps,
        text: nameProps,
    });

    const handleSetEmployeeClick = () => {
        dialogRef.current.openAppointmentCoachDialog(
            {
                title: 'CC教練設定',
                employeeID: employeeData.id,
                apiParams: {
                    scheduleDate: dateProps,
                    scheduleTime: timeProps,
                },
                refreshNoApi: setEmployeeData,
                apiFn: putAppointmentCircuitApi,
            },
            options
        );
    };

    return (
        <React.Fragment>
            <Chip
                onClick={handleSetEmployeeClick}
                color={employeeData.id ? 'info' : 'error'}
                label={employeeData.id ? `${employeeData.text}` : `未安排`}
            />
        </React.Fragment>
    );
};

export default AppointmentCircuitDutyItem;
