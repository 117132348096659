import React, { useState } from 'react';
import clsx from 'clsx';
import { OrdersDialog } from '../dialog';
import { Box } from '@common/components/';

const CellController = (props) => {
    const { children, isShow = false, filterType, date, isOrder, isRemit } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    // 開啟彈跳介面
    const handleOpenDialog = () => {
        if (isShow) setDialogOpen(true);
    };

    // 關閉彈跳介面
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <React.Fragment>
            <Box className={clsx('table-cell-controller', { 'no-control': !isShow })} onClick={handleOpenDialog}>
                {children}
            </Box>
            <OrdersDialog open={dialogOpen} filterType={filterType} date={date} onClose={handleCloseDialog} isOrder={isOrder} isRemit={isRemit} />
        </React.Fragment>
    );
};

export default CellController;
