import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import MemberSummaryTipDialog from '@icoach/landingPage/dialog/MemberSummaryTipDialog';
import Tablenization from '@icoach/components/Tablenization';
import { memberSheet } from '@icoach/components/';
import { GuidanceNotesTitle } from '@icoach/members/components';
import { Typography, Stack, Avatar, AvatarGroup, Tooltip, Button } from '@common/components/';
import {
    BirthdayCake as BirthdayCakeIcon,
    Dollar as DollarIcon,
    Heart as HeartIcon,
    Exclamation as ExclamationIcon,
    Exclamations as ExclamationsIcon,
    Gift as GiftIcon,
    Leave as LeaveIcon,
    NoPushForMonths as NoPushForMonthsIcon,
    Renew as RenewIcon,
    THundred as THundredIcon,
    Todo as TodoIcon,
    WheyOrdered as WheyOrderedIcon,
    WheySuspended as WheySuspendedIcon,
    NoPushWhey as NoPushWheyIcon,
    ProbioticsOrdered as ProbioticsOrderedIcon,
    ProbioticsSuspended as ProbioticsSuspendedIcon,
    NoPushProbiotics as NoPushProbioticsIcon,
    DialogOutlined as DialogOutlinedIcon,
    Check as CheckIcon,
    Measuring as MeasuringIcon,
    CaNoPush as CaNoPushIcon,
    CaOrdered as CaOrderedIcon,
    CaSuspended as CaSuspendedIcon,
    Locker as LockerIcon,
} from '@common/SvgIcon/';
import useMemberCheckInApi from '@apis/useMemberCheckInApi';
import { parseDate } from '@util/moment';
import useSignalr from '../../../../signalr/useSignalr';
import { CheckoutCreditOrderDialog } from '@icoach/members/memberDetail/dialog';
import useOrderCheckoutApi from '@apis/useOrderCheckoutApi';

export const attentionArray = [
    { id: 2 ** 0, Icon: TodoIcon, title: '待辦事項' },
    { id: 2 ** 1, Icon: TodoIcon, title: '服銷待辦' },
    { id: 2 ** 2, Icon: HeartIcon, title: <GuidanceNotesTitle /> },
    { id: 2 ** 3, Icon: DollarIcon, title: '欠款', className: 'bg-error-light' },
    { id: 2 ** 4, Icon: DollarIcon, title: '信用卡號未填', className: 'bg-error-light' },
    { id: 2 ** 5, Icon: DollarIcon, title: '信用卡有效期限', className: 'bg-error-light' },
    { id: 2 ** 6, Icon: DollarIcon, title: 'HC訂單欠款', className: 'bg-error-light' },
    { id: 2 ** 7, Icon: DollarIcon, title: 'HC訂單信用卡有效期限', className: 'bg-error-light' },
    { id: 2 ** 8, Icon: ExclamationsIcon, title: '超危險', className: 'bg-error-light' },
    { id: 2 ** 9, Icon: ExclamationIcon, title: '危險', className: 'bg-info-light' },
    { id: 2 ** 10, Icon: BirthdayCakeIcon, title: '當日壽星', className: 'bg-error-light' },
    { id: 2 ** 11, Icon: THundredIcon, title: '百Ｔ資格', className: 'bg-error-light' },
    { id: 2 ** 12, Icon: GiftIcon, title: '贈品待領取', className: 'bg-info-light' },
    { id: 2 ** 13, Icon: RenewIcon, title: '會籍續約' },
    { id: 2 ** 14, Icon: LeaveIcon, title: '會籍暫停中' },
    { id: 2 ** 15, Icon: TodoIcon, title: '二周面談' },
    { id: 2 ** 16, Icon: TodoIcon, title: '量身提醒' },
    { id: 2 ** 17, Icon: GiftIcon, title: '30天新手禮物' },
    { id: 2 ** 18, Icon: GiftIcon, title: '90天新手禮物' },
    { id: 2 ** 19, Icon: GiftIcon, title: '點數贈品待領取' },
    { id: 2 ** 20, Icon: LockerIcon, title: '租櫃待續約' },
];

export const popularizeArray = [
    { id: 2 ** 0, Icon: WheyOrderedIcon, title: '乳清訂購中' },
    { id: 2 ** 1, Icon: ProbioticsOrderedIcon, title: '益菌訂購中' },
    { id: 2 ** 2, Icon: WheySuspendedIcon, title: '乳清暫停中' },
    { id: 2 ** 3, Icon: ProbioticsSuspendedIcon, title: '益菌暫停中' },
    { id: 2 ** 4, Icon: NoPushForMonthsIcon, title: '乳清未訂購(三個月不推)' },
    { id: 2 ** 5, Icon: NoPushForMonthsIcon, title: '益菌未訂購(三個月不推)' },
    { id: 2 ** 6, Icon: NoPushWheyIcon, title: '永不推乳清' },
    { id: 2 ** 7, Icon: NoPushProbioticsIcon, title: '永不推益生菌' },
    { id: 2 ** 10, Icon: CaOrderedIcon, title: '鈣訂購中' },
    { id: 2 ** 11, Icon: CaSuspendedIcon, title: '鈣暫停中' },
    { id: 2 ** 12, Icon: NoPushForMonthsIcon, title: '鈣未訂購(三個月不推)' },
    { id: 2 ** 13, Icon: CaNoPushIcon, title: '永不推鈣' },
];

const morePageProperty = {
    size: 50,
    latestID: 0,
};

/* 注意/訂單 icons  **/
const IconGroupLabel = (props) => {
    const { value: valueProps, iconArray: iconArrayProps = [], max: maxProps = 5 } = props;
    return (
        <Stack>
            <AvatarGroup
                isIcon={true}
                max={maxProps}
                componentsProps={{
                    additionalAvatar: { style: { backgroundColor: '#000' } },
                }}
            >
                {iconArrayProps.map(({ id: itemID, Icon, title }) => {
                    if (!Array.isArray(valueProps)) return null;
                    if (valueProps.find((v) => v === itemID)) {
                        return (
                            <Tooltip key={itemID} title={title} disableFocusListener>
                                <Avatar className={'line-height-1'}>
                                    <Icon />
                                </Avatar>
                            </Tooltip>
                        );
                    } else return null;
                })}
            </AvatarGroup>
        </Stack>
    );
};

/* 服銷待辦 **/
const ServiceSaleLabelSheet = (props) => {
    const { items = [] } = props;
    return (
        <div className="text-ellipsis-columns-2">
            {Array.isArray(items) && items.map((target, _index) => <Typography key={`${target}_${_index}`}>{target}</Typography>)}
        </div>
    );
};

/* Table Label **/
const checkinLabel = [
    {
        cellKey: 'checkinTime',
        headerLabel: '入場',
        align: 'center',
        formatCell: (_value, _rowData) => {
            const order = _rowData['order'];
            return (
                <Stack direction="column" alignItems="center" className="checkin-table-checkin-time-label">
                    <div className="checkin-count">{order}</div>
                    {parseDate(_value, 'HH:mm', false)}
                </Stack>
            );
        },
    },
    { ...memberSheet, maxWidth: 20 },
    {
        cellKey: 'measureDay',
        headerLabel: 'WM日',
        align: 'center',
        formatCell: (value, { isFinishSevenDay, isInterviewNotMeasure, isMonthMeasured, isNotify }) => {
            return (
                <Stack alignItems="center" justifyContent="center" spacing={1}>
                    {isFinishSevenDay ? (
                        <Tooltip title="七日內面談量身完成" disableFocusListener disableTouchListener>
                            <CheckIcon fontSize="large" color={'primary'} />
                        </Tooltip>
                    ) : (
                        <div className="pos-relative">
                            {isInterviewNotMeasure ? (
                                <React.Fragment>
                                    <Tooltip title="只面談不量身" disableFocusListener disableTouchListener>
                                        <DialogOutlinedIcon fontSize="large" htmlColor={`#51a69e`} />
                                    </Tooltip>
                                    <Typography color={'gray'} className={'interview-not-measure'} component={'span'}>
                                        {value}
                                    </Typography>
                                </React.Fragment>
                            ) : (
                                <Typography
                                    color={isNotify && !isMonthMeasured ? 'error' : 'secondary'}
                                    className={clsx(isNotify && !isMonthMeasured && 'font-weight-bold')}
                                    component={'span'}
                                >
                                    {value}
                                </Typography>
                            )}
                        </div>
                    )}
                    {isMonthMeasured ? (
                        <Tooltip title="本月已量身或面談" disableFocusListener disableTouchListener>
                            <MeasuringIcon color="primary" />
                        </Tooltip>
                    ) : null}
                </Stack>
            );
        },
    },
    {
        cellKey: 'workoutWeekCount',
        headerLabel: '本週WO',
        align: 'center',
    },
    {
        cellKey: 'workoutFrequency',
        headerLabel: 'WO頻率',
        align: 'center',
    },
    {
        cellKey: 'workoutCount',
        headerLabel: '百T',
        align: 'center',
        formatCell: (value, rowData) => {
            const modulo = value % 100;
            const ViewComp = modulo === 0 ? value : modulo >= 80 ? value : '';
            return ViewComp;
        },
    },
    {
        cellKey: 'warinessTypes',
        headerLabel: '注意',
        align: 'left',
        width: 11,
        formatCell: (_value) => <IconGroupLabel value={_value} iconArray={attentionArray} />,
    },
    {
        cellKey: 'promotionTypes',
        headerLabel: '訂單狀態',
        align: 'left',
        width: 8,
        formatCell: (_value) => {
            return <IconGroupLabel value={_value} iconArray={popularizeArray} max={3} />;
        },
    },
    {
        cellKey: 'serviceSaleItems',
        headerLabel: '服銷待辦',
        align: 'center',
        maxWidth: 12,
        formatCell: (_value) => {
            return <ServiceSaleLabelSheet items={_value} />;
        },
    },
];

const CheckinTable = (props) => {
    const signalr = useSignalr();
    const { getMemberCheckinApi } = useMemberCheckInApi();
    const { getMemberInfoCardApi } = useOrderCheckoutApi();
    const rowLabel = useMemo(() => checkinLabel, []);
    const [rowsData, setRowsData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState('');
    const [sourceData, setSourceData] = useState({});
    const { orderData, resource } = sourceData;
    const memberIDRef = useRef(null);
    const morePageRef = useRef(0);

    /**
     * 新增來店會員清單
     */
    const addCheckin = (_t) => {
        console.log('addCheckin');
        setRowsData((pre) => {
            let newRowData = [_t, ...pre];
            return newRowData;
        });
    };

    /**
     * 刷新來店會員清單  會有取不到state ***Allen 可教學用***
     */
    const upDateCheckin = (resuorceData) => {
        console.log('upDateCheckin');
        try {
            const { memberID: upDateMemberID } = resuorceData;
            setRowsData((prev) => {
                let membersData = _.cloneDeep(prev);
                if (membersData.find(({ memberID: curMemberID }) => String(curMemberID) === String(upDateMemberID))) {
                    membersData = membersData.map((memItem) => {
                        let newMemberData = memItem;
                        if (String(memItem.memberID) === String(upDateMemberID)) {
                            newMemberData = resuorceData;
                        }
                        return newMemberData;
                    });
                } else {
                    // 不在畫面的資料不會刷新
                    // membersData.unshift(resuorceData);
                }
                return membersData;
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleOpenDialog = (target, memberID) => {
        if (memberID) memberIDRef.current = memberID;
        setDialogOpen(target);
    };

    const handleCloseDialog = () => {
        memberIDRef.current = null;
        setDialogOpen('');
    };

    const handleRowOnClick = useCallback(
        (rowData) => {
            memberIDRef.current = rowData['memberID'];
            setDialogOpen('tip');
        },
        // eslint-disable-next-line
        [],
    );

    const handleMoreClick = () => {
        doCheckinDataApi();
    };

    // 判斷 row 是否要變色
    const handleRowClass = (rowData) => {
        const { warinessTypes = [] } = rowData;
        let classNames = '';
        // 判斷是有否交集
        const intersection = _.intersection(warinessTypes, cacheAttentionArray);
        const len = intersection.length;
        if (len) {
            const colorMap = intersection.reduce((acc, cur) => {
                let newAcc = { ...acc };
                const tarData = attentionArray.find(({ id }) => String(id) === String(cur));
                Object.assign(newAcc, { [tarData.className]: true });
                return newAcc;
            }, {});
            // 此處可與attentionArray className做變化 先不修改
            classNames = Object.keys(colorMap)[0];
        }
        return classNames;
    };

    /**
     * 初始來店會員資料
     */
    const doInitData = async () => {
        await signalr.start();
        await signalr.on('CHECKIN_ADD', addCheckin);
        await signalr.on('CHECKIN_UPDATE', upDateCheckin);
        await doCheckinDataApi();
    };

    /**
     * 來店會員列表api
     */
    const doCheckinDataApi = async () => {
        const resp = await getMemberCheckinApi({
            ...morePageProperty,
            latestID: morePageRef.current,
        });
        if (resp) setRowsData((pre) => [...pre, ...resp]);
    };

    /**
     * 取得會員訂單狀態 欠費用
     */
    const doMemberInfoApi = async (memberID) => {
        let res = await getMemberInfoCardApi(memberID);
        if (res) {
            setSourceData(res);
        }
    };

    // 暫存有className的變數
    const cacheAttentionArray = useMemo(
        () => {
            return attentionArray.reduce((acc, cur) => {
                if (cur.className) acc.push(cur.id);
                return acc;
            }, []);
        },
        // eslint-disable-next-line
        [],
    );

    /**
     * 列表Comp
     */
    const tableUseMemo = useMemo(
        () => {
            return (
                <Tablenization cardProps={{ square: true, isOverflowHidden: true }}>
                    <Tablenization.TableRowHeader headerRow={rowLabel} className={'checkin sticky-card-header'} />
                    <Tablenization.TableRowBody headerRow={rowLabel} rowsData={rowsData} rowOnClick={handleRowOnClick} bodyRowClass={{ fn: handleRowClass }} />
                </Tablenization>
            );
        },
        // eslint-disable-next-line
        [handleRowOnClick, rowLabel, rowsData],
    );

    /**
     * 更多Comp
     */
    const remaingingMemo = useMemo(
        () => {
            const { length } = rowsData;
            let isMore = false;
            if (!length) return null;
            else {
                const { order, memberID } = rowsData[length - 1];
                if (order > 1) {
                    isMore = true;
                    morePageRef.current = memberID;
                } else {
                    morePageRef.current = 0;
                }
            }

            return (
                <React.Fragment>
                    {isMore && (
                        <Stack justifyContent="center" className="my-3">
                            <Button onClick={handleMoreClick} fullWidth>
                                更多
                            </Button>
                        </Stack>
                    )}
                </React.Fragment>
            );
        },
        // eslint-disable-next-line
        [rowsData],
    );

    useEffect(
        () => {
            doInitData();
            return () => signalr.stop();
        },
        //eslint-disable-next-line
        [],
    );

    useEffect(() => {
        if (memberIDRef.current) {
            doMemberInfoApi(memberIDRef.current);
        }
        // eslint-disable-next-line
    }, [memberIDRef.current]);

    return (
        <div>
            {tableUseMemo}
            {remaingingMemo}
            <MemberSummaryTipDialog open={dialogOpen === 'tip'} id={memberIDRef.current} onClose={handleCloseDialog} handleOpenDialog={handleOpenDialog} />
            <CheckoutCreditOrderDialog
                open={dialogOpen === 'checkout'}
                title={'欠費明細'}
                data={orderData}
                memberID={memberIDRef.current}
                resource={resource}
                onClose={handleCloseDialog}
            />
        </div>
    );
};

export default CheckinTable;
