import { useEffect, useRef } from 'react';
import useAppSystemApi from '@apis/useAppSystemApi';
import { useDispatch } from 'react-redux';
import { setVersionData } from '@redux/version/action';
import { getIsAndroid } from '@util/utils';

const AppVersion = () => {
    const lastCheckRef = useRef(0);
    const CHECK_INTERVAL = 10 * 60 * 1000; // 10min
    const { getWebClubInfoApi } = useAppSystemApi();
    const _dispatch = useDispatch();

    const checkVersion = async () => {
        const now = Date.now();
        if (now - lastCheckRef.current < CHECK_INTERVAL) {
            return;
        }
        lastCheckRef.current = now;

        try {
            const response = await getWebClubInfoApi();
            const remoteVersion = response.version;
            const localVersion = process.env.Version;

            if (remoteVersion !== localVersion) {
                console.error([remoteVersion, localVersion]);
                if (window.confirm('有新版本可用，請重新加載網頁更新')) {
                    window.location.reload();
                }
            } else {
                _dispatch(setVersionData(response));
            }
        } catch (error) {
            console.error('檢查版號發生錯誤:', error);
        }
    };

    useEffect(
        () => {
            // 應用加載時檢查版本號
            checkVersion();

            // 當頁面從不可見變為可見時檢查版本號
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    checkVersion('2.5.4.0');
                }
            };

            // 當頁面獲得焦點時檢查版本號
            const handleFocus = () => {
                checkVersion('2.5.4.0');
            };

            if (getIsAndroid()) document.addEventListener('visibilitychange', handleVisibilityChange);
            else window.addEventListener('focus', handleFocus);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
                window.addEventListener('focus', handleFocus);
            };
        },
        // eslint-disable-next-line
        [],
    );

    return null;
};

export default AppVersion;
