import React from 'react';
import { NavLink } from 'react-router-dom';
import CustomerTable from '@icoach/nonMember/customer/CustomerTable';
import ProspectTable from '@icoach/nonMember/prospect/ProspectTable';
import TemporariesTable from '@icoach/nonMember/temporary/TemporariesTable';
import { Button, Tabs, Tab, TabPanel, Box } from '@common/components/';
import { UserCircle as UserCircleIcon } from '@common/SvgIcon/';

const TabsHeader = (props) => {
    const { value: propsValue, indexValues, onChange: onChangeProp } = props;
    const { CUSTOMER_VALUE = {}, PROSPECT_VALUE = {}, TEMPORARIES_VALUE = {}, ADD_CUSTOMER = {}, ADD_PROSPECT = {} } = indexValues;
    const handleChange = (event, newValue) => {
        onChangeProp && onChangeProp(newValue);
    };

    return (
        <React.Fragment>
            <Tabs value={propsValue} onChange={handleChange} variant="scrollable" allowScrollButtonsMobile>
                <Tab
                    className={'py-3'}
                    label="顧客總覽"
                    icon={<UserCircleIcon />}
                    value={CUSTOMER_VALUE.tabsIndex}
                    component={NavLink}
                    to={CUSTOMER_VALUE.href}
                />
                <Tab
                    className={'py-3'}
                    label="名單總覽"
                    icon={<UserCircleIcon />}
                    value={PROSPECT_VALUE.tabsIndex}
                    component={NavLink}
                    to={PROSPECT_VALUE.href}
                />
                <Tab
                    className={'py-3'}
                    label="暫存名單"
                    icon={<UserCircleIcon />}
                    value={TEMPORARIES_VALUE.tabsIndex}
                    component={NavLink}
                    to={TEMPORARIES_VALUE.href}
                />
            </Tabs>
            <Box className="btn-group">
                <Button variant="outlined" component={NavLink} to={ADD_CUSTOMER.href}>
                    新增詢問
                </Button>
                <Button variant="contained" component={NavLink} to={ADD_PROSPECT.href}>
                    新增名單
                </Button>
            </Box>
        </React.Fragment>
    );
};

const TabContext = (props) => {
    const { value: propsValue, indexValues } = props;
    const { CUSTOMER_VALUE = {}, PROSPECT_VALUE = {}, TEMPORARIES_VALUE = {} } = indexValues;
    return (
        <React.Fragment>
            <TabPanel value={propsValue} index={CUSTOMER_VALUE.tabsIndex}>
                <CustomerTable name="顧客總覽" />
            </TabPanel>
            <TabPanel value={propsValue} index={PROSPECT_VALUE.tabsIndex}>
                <ProspectTable name="名單總覽" />
            </TabPanel>
            <TabPanel value={propsValue} index={TEMPORARIES_VALUE.tabsIndex}>
                <TemporariesTable name="暫存名單" />
            </TabPanel>
        </React.Fragment>
    );
};

export { TabsHeader, TabContext };
