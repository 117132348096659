import React from 'react';
import clsx from 'clsx';
import IconButton from '../iconButton/IconButton';
import Paper from '../paper/Paper';
import {
    Check as SuccessOutlinedIcon,
    ReportProblemOutlined as ReportProblemOutlinedIcon,
    ErrorNoOutline as ErrorOutlineIcon,
    Info as InfoOutlinedIcon,
    Trash as TrashIcon,
    UserSlash as UserSlashIcon,
    Close as CloseIcon,
} from '../icons/SvgIcon/';

const variantArray = ['standard', 'outlined', 'filled'];
const colorArray = ['primary', 'secondary', 'success', 'error', 'warning', 'info', 'gray'];

const defaultIconMapping = {
    success: <SuccessOutlinedIcon fontSize="inherit" />,
    info: <InfoOutlinedIcon fontSize="inherit" />,
    warning: <ReportProblemOutlinedIcon fontSize="inherit" />,
    error: <ErrorOutlineIcon fontSize="inherit" />,
    remove: <TrashIcon fontSize="inherit" />,
    invalid: <UserSlashIcon fontSize="inherit" />,
};

const AlertRoot = React.forwardRef((props, ref) => {
    const { className, color, variant, children, ...other } = props;
    const staticClass = 'alert-root';
    let classNames = staticClass;

    if (variantArray.indexOf(variant) !== -1) classNames = clsx(classNames, `alert-${variant}`);
    if (colorArray.indexOf(color) !== -1) classNames = clsx(classNames, `alert-color-${color}`);

    return (
        <Paper ref={ref} elevation={0} className={clsx(classNames, className)} {...other}>
            {children}
        </Paper>
    );
});

const AlertIcon = (props) => {
    const { children, color } = props;
    const staticClass = 'alert-icon';
    let classNames = '';
    if (colorArray.indexOf(color) !== -1) classNames = `${staticClass}-${color}`;

    return (
        <div className={staticClass}>{React.cloneElement(children, { className: classNames })}</div>
    );
};

const AlertMessage = (props) => {
    const { children } = props;
    return <div className="alert-message">{children}</div>;
};

const AlertAction = (props) => {
    const { children } = props;
    return <div className="alert-action">{children}</div>;
};

const Alert = React.forwardRef((props, ref) => {
    const {
        action,
        className,
        closeText = 'Close',
        variant = 'standard',
        color = 'primary',
        severity = 'success',
        icon,
        role = 'alert',
        iconMapping = defaultIconMapping,
        onClose,
        children,
    } = props;

    return (
        <AlertRoot role={role} variant={variant} color={color} ref={ref} className={className}>
            {icon !== false ? (
                <AlertIcon color={color}>
                    {icon || iconMapping[severity] || defaultIconMapping[severity]}
                </AlertIcon>
            ) : null}
            <AlertMessage>{children}</AlertMessage>
            {action != null ? <AlertAction>{action}</AlertAction> : null}
            {action == null && onClose ? (
                <AlertAction>
                    <IconButton size="small" title={closeText} color="inherit" onClick={onClose}>
                        <CloseIcon fontSize="small" htmlColor="#fff" />
                    </IconButton>
                </AlertAction>
            ) : null}
        </AlertRoot>
    );
});

export default Alert;
