import React, { useRef } from 'react';
import { MoreLabelBox } from '../../components';

const MoreLabel = (props) => {
    const moreLabelMap = useRef({
        view: {
            text: '查看',
            to: () => '#',
        },
        edit: {
            text: '編輯',
            to: () => '#',
        },
        test: {
            text: '測試',
            to: () => '#',
        },
        remove: {
            text: '刪除',
            to: () => '#',
        },
    });

    return <MoreLabelBox {...props} moreLabelMap={moreLabelMap.current} textAlign={'center'} />;
};

export default MoreLabel;
