import React, { useRef } from 'react';
import clsx from 'clsx';
import { Stack, Typography } from '../../../components';
import { Reply as ReplyIcon } from '../../../components/icons/SvgIcon/';
import Tablenization from '../../components/Tablenization';
import { ProductBox } from '../components';
import { parseDate } from '../../../util/moment';
import { formatCurrencyFn, isEmpty } from '../../../util/utils';
import { MemoControllerBox } from '@icoach/components';

export const ReturnTitleComponent = (props) => {
    const { date } = props;
    return (
        <Stack className={'return-qty'} alignItems={'center'} justifyContent={'center'}>
            <ReplyIcon />
            <span>{parseDate(date, 'YYYY/MM/DD')}</span>
        </Stack>
    );
};

const rowsLabel = [
    {
        cellKey: 'orderItemID',
        headerLabel: '品項',
        className: 'vertical-align-middle',
        formatCell: (cellValue, { pictureSrc, productName, variantName, sku }) => {
            return <ProductBox imgUrl={pictureSrc} name={productName} variantName={variantName} sku={sku} />;
        },
    },
    {
        cellKey: 'productTypeText',
        headerLabel: '類型',
        align: 'center',
        className: 'vertical-align-middle',
        formatCell: (cellValue, { returnItems }) => {
            return (
                <React.Fragment>
                    <Typography className={'mb-0'}>{cellValue}</Typography>
                    {!isEmpty(returnItems) && returnItems.map((item) => <ReturnTitleComponent key={item.orderReturnItemID} date={item.orderReturnDate} />)}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'unitPrice',
        headerLabel: '價格',
        align: 'right',
        minWidth: 100,
        className: 'vertical-align-middle',
        formatCell: (cellValue, { returnItems }) => {
            return (
                <React.Fragment>
                    <Typography className={'mb-0'}>{formatCurrencyFn(cellValue)}</Typography>
                    {!isEmpty(returnItems) &&
                        returnItems.map((item) => (
                            <Typography key={item.orderReturnItemID} className={'text-error'}>
                                {formatCurrencyFn(item.returnUnitPrice)}
                            </Typography>
                        ))}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'quantity',
        headerLabel: '數量',
        align: 'right',
        minWidth: 80,
        className: 'vertical-align-middle',
        formatCell: (cellValue, { returnItems }) => {
            return (
                <React.Fragment>
                    <Typography className={'mb-0'}>{cellValue}</Typography>
                    {!isEmpty(returnItems) &&
                        returnItems.map((item) => (
                            <Typography key={item.orderReturnItemID} className={'text-error'}>
                                {item.returnQuantity}
                            </Typography>
                        ))}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '總計',
        align: 'right',
        minWidth: 100,
        className: 'vertical-align-middle',
        formatCell: (cellValue, { returnItems }) => {
            return (
                <React.Fragment>
                    <Typography className={'mb-0'}>{formatCurrencyFn(cellValue)}</Typography>
                    {!isEmpty(returnItems) &&
                        returnItems.map((item) => (
                            <Typography key={item.orderReturnItemID} className={'text-error'}>
                                {formatCurrencyFn(item.returnTotalPrice)}
                            </Typography>
                        ))}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'notes',
        headerLabel: '備註',
        className: 'vertical-align-middle',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
];

const ReadOnlyProductCartTable = (props) => {
    const { className, rowsData = [] } = props;
    const labelTitle = useRef(rowsLabel);

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: clsx('read-only-cart-table', className) }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default ReadOnlyProductCartTable;
