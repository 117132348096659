import React from 'react';
import { useSelector } from 'react-redux';
import { getVersionData } from '@redux/version/selector';
import InBodyTranscriptDialog from '@icoach/inbody/components/InBodyTranscriptDialog';
import { upperCase } from 'lodash';

const InBodyInterviewMeasureTranscriptDialog = (props) => {
    const { sourceData, isShow } = props;
    const { measureID, memberID, measureDate } = sourceData;
    const { env } = useSelector(getVersionData);
    let isProd = false;
    if (upperCase(env) === 'PRODUCTION') isProd = true;
    console.log(env);
    return <React.Fragment>{(isShow || !isProd) && <InBodyTranscriptDialog sourceData={{ measureID, memberID, measureDate }} />}</React.Fragment>;
};

export default InBodyInterviewMeasureTranscriptDialog;
