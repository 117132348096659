import React, { useImperativeHandle, useState, useRef, useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Select, Stack } from '@common/components/';
import { isEmpty, isInvaild } from '@util/utils';

const SelectField = React.forwardRef((props, ref) => {
    const {
        name: propsName,
        id: propsId,
        className,
        children: childrenProp,
        defaultValue = '',
        value: valueProp,
        disabled = false,
        isError: propsError = false,
        helperText = '此選項為必填，不得為空值',
        inputProps,
        InputProps,
        label,
        multiple = false,
        onClose,
        onOpen,
        onChange: onChangeProp,
        open,
        renderValue,
        required = false,
        lightColor = false,
        SelectDisplayProps,
        displayEmpty = false,
        IconComponent,
        input,
        labelId,
        MenuProps,
        fullWidth = false,
        readOnly = false,
        direction: directionProps = 'column',
        FormLabelTip = '',
        ...other
    } = props;
    const [isError, setError] = useState(propsError);
    const inputValueRef = useRef(null);

    const handleOnChange = (event, node, _value) => {
        onChangeProp && onChangeProp(event, node, _value);
    };

    const defaultProps = {
        name: propsName,
        id: propsId,
        autoWidth: false,
        displayEmpty: displayEmpty,
        IconComponent: IconComponent,
        input: input,
        labelId: labelId,
        MenuProps: MenuProps,
        multiple: multiple,
        onClose: onClose,
        onOpen: onOpen,
        onChange: handleOnChange,
        open: open,
        renderValue: renderValue,
        SelectDisplayProps: SelectDisplayProps,
        children: childrenProp,
        inputProps: inputProps,
        defaultValue: multiple
            ? Array.isArray(defaultValue)
                ? defaultValue.map((s) => String(s))
                : []
            : isInvaild(defaultValue)
              ? null
              : String(defaultValue) || '',
        value: valueProp,
        inputRef: inputValueRef,
        fullWidth: fullWidth,
        readOnly: readOnly,
        disabled: disabled,
        ...InputProps,
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => {
                    let source = required;
                    if (required && inputValueRef && inputValueRef.current && !isEmpty(inputValueRef.current.value)) {
                        source = false;
                    }
                    setError(source);
                    return source;
                },
                getResult: () => inputValueRef.current.value,
                getName: () => inputValueRef.current.name,
                getId: () => inputValueRef.current.id,
                node: () => inputValueRef.current,
            };
        },
        // eslint-disable-next-line
        [],
    );

    const formlabelProperties = {
        lightColor: lightColor,
        inline: directionProps === 'row',
        FormLabelProps: {
            labelTip: FormLabelTip,
        },
    };

    useEffect(() => {
        setError(propsError);
    }, [propsError]);

    return (
        <FormControl
            disabled={disabled}
            error={isError}
            fullWidth={fullWidth}
            required={required}
            className={className}
            direction={directionProps}
            readOnly={readOnly}
            {...other}
        >
            {label && <FormLabel {...formlabelProperties}>{label}</FormLabel>}
            <Stack direction="column" style={{ flexGrow: 1 }}>
                <Select {...defaultProps} />
                {isError && <FormHelperText>{helperText}</FormHelperText>}
            </Stack>
        </FormControl>
    );
});

export default SelectField;
