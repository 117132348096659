import React from 'react';
import { Grid } from '@mui/material';
import { Card, Stack, Skeleton } from '@common/components/';

const MembershipTransferOverContentSkeleton = React.forwardRef((props, ref) => {
    return (
        <Stack spacing={3} direction="column">
            <Card>
                <Card.CardContent className={'p-4'}>
                    <Stack justifyContent="center">
                        <Skeleton height={80} width={300} />
                    </Stack>
                    <Stack className="pb-2" spacing={2} direction="column">
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                    </Stack>
                </Card.CardContent>
            </Card>
            <Card>
                <Card.CardContent className={'p-4'}>
                    <Stack className="pb-2" spacing={2}>
                        <Skeleton width={240} height={60} />
                        <Skeleton width={240} height={60} />
                    </Stack>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Stack justifyContent="end">
                                <Skeleton width={120} height={48} />
                            </Stack>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card.CardContent>
                <Card.CardContent className={'p-4'}>
                    <Stack className="pb-2" spacing={2}>
                        <Skeleton width={240} height={60} />
                        <Skeleton width={240} height={60} />
                    </Stack>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Stack justifyContent="end">
                                <Skeleton width={120} height={48} />
                            </Stack>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card.CardContent>
                <Card.CardContent className={'p-4'}>
                    <Stack className="pb-2" spacing={2}>
                        <Skeleton width={240} height={60} />
                        <Skeleton width={240} height={60} />
                    </Stack>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Stack justifyContent="end">
                                <Skeleton width={120} height={48} />
                            </Stack>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton width={260} height={50} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Card>
        </Stack>
    );
});

export default MembershipTransferOverContentSkeleton;
