import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Stack, Button, SelectField, MenuItem } from '@common/components/';
import { refIsRequiredError, matchOptionsText } from '@util/utils';

const AppointmentCoachDialog = React.forwardRef((props, ref) => {
    const { open: openProps, onClose: onCloseProps, options: optionProps = [], sourceData: sourceDataProps } = props;
    const [sourceData, setSourceData] = useState(sourceDataProps || {});
    const employeeRef = React.useRef(null);
    const { title = '', employeeID = null, apiParams = {}, apiFn, refresh, refreshNoApi } = sourceData;

    const handleCloseClick = () => {
        onCloseProps && onCloseProps(null);
    };

    const handleOnClick = () => {
        if (!refIsRequiredError(employeeRef)) {
            const employeeID = employeeRef.current.getResult();
            const params = {
                ...apiParams,
                employeeID,
            };
            putAppointmentAction(params, employeeID);
        }
    };

    const putAppointmentAction = async (params, employeeID) => {
        const resp = await apiFn(params);
        if (resp) {
            onCloseProps && onCloseProps(null);
            if (refresh && refresh.current) {
                refresh.current();
            } else if (refreshNoApi) {
                refreshNoApi({
                    id: String(employeeID) === '0' ? 0 : employeeID,
                    text: matchOptionsText(optionProps, employeeID, '未安排'),
                });
            }
        }
    };

    useEffect(
        () => {
            if (openProps) {
                setSourceData(sourceDataProps);
            }
        },
        // eslint-disable-next-line
        [openProps]
    );

    return (
        <Dialog open={openProps} PaperProps={{ sx: { minWidth: 360 } }}>
            <GradientColorHeader onClose={handleCloseClick}>{title}</GradientColorHeader>
            <DialogContent className="pt-2">
                <SelectField label="教練" defaultValue={employeeID || '0'} ref={employeeRef} options={optionProps} fullWidth required>
                    <MenuItem value="0">未安排</MenuItem>
                    {optionProps.map(({ text, value, disabled }) => {
                        return (
                            <MenuItem key={value} value={value} disabled={disabled}>
                                {text}
                            </MenuItem>
                        );
                    })}
                </SelectField>
                <Stack justifyContent="end" className="pt-2">
                    <Button variant="contained" onClick={handleOnClick}>
                        確定
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
});

export default AppointmentCoachDialog;
