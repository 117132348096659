import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import { FilterMonthlyFeeSearch, FilterMonthlyFeeLabel } from '@icoach/accountBook/FilterMonthlyFeeTemplate';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { FilterDebtLabel } from '@icoach/accountBook/FilterDebtTemplate';
import { initBookmark } from '@icoach/members/staticData';
import { Card, Form, Button, SelectField, MenuItem, Stack, Typography } from '@common/components/';
import useMemberFilterApi from '@apis/useMemberFilterApi';
import useMemberFilterExportApi from '@apis/useMemberFilterExportApi';
import useMessageDialog from '@util/hook/useMessageDialog';

const AccountBookOverviewFilterSearchBar = React.memo(
    React.forwardRef((props, ref) => {
        const { className: classNameProps } = props;
        const {
            setSourceData,
            setRowsLabel,
            setSortOption,
            setResource,
            setBookmarkNum,
            setBookmarkSortBy,
            setRefresh,
            resource,
            bookmark,
            reSetCheckedItems,
        } = useAccountBookContext();
        const { overdraftOrderByOption: debtSortOption = [], monthFeeOrderByOption: monthFeeSortOption = [] } = resource;
        const { getMemberFilterOptionsApi, getOverdraftFilterDataApi, getMonthFeeFilterDataApi } = useMemberFilterApi();
        const { getMemberFilterOverdraftApi, getMemberFilterMonthFeeApi } = useMemberFilterExportApi();
        const [filterType, setFilterType] = useState(2);
        const searchPanelsRef = useRef(null);
        const preloadRef = useRef(false);
        const isMountingRef = useRef(true);
        const prevFilterTypeRef = useRef(filterType);
        const setMessageDialog = useMessageDialog();

        const getParams = (targetPage = {}) => {
            let params = { ...targetPage };
            if (searchPanelsRef && searchPanelsRef.current) {
                Object.assign(params, {
                    ...searchPanelsRef.current.getResult(),
                });
            }
            return params;
        };

        const handleTypeChange = (dom, { props }) => {
            setFilterType(props.value);
        };

        const handleSearchClick = () => {
            const params = getParams(initBookmark(bookmark?.sortBy));
            setBookmarkNum(1);
            reSetCheckedItems();
            doSearchApi(params);
        };

        const handleExcelOnClick = () => {
            const params = getParams(initBookmark(bookmark?.sortBy));
            doExcelSendEmailApi(params);
        };

        const doMemberFilterOptionsApi = async () => {
            const resp = await getMemberFilterOptionsApi();
            if (resp) {
                setResource(resp);
            }
        };

        const doSearchApi = async (params) => {
            const doApi = memberFilterMap[filterType]['doApi'];
            const resp = await doApi(params);
            if (resp) {
                const { list, sortBy, ...pageParams } = resp;
                setSourceData((prev) => {
                    let targetList = [...list];
                    if (String(params.pageIndex) !== '1') {
                        targetList = [...(Array.isArray(prev.list) ? prev.list : []), ...targetList];
                    }
                    return {
                        ...pageParams,
                        list: targetList,
                    };
                });
            }
            isMountingRef.current = false;
        };

        const doExcelSendEmailApi = async (params) => {
            const doApi = memberFilterMap[filterType]['excelApi'];
            const resp = await doApi(params);

            if (resp) {
                let { message, url } = resp;
                setMessageDialog({
                    open: true,
                    title: '個資資訊安全原則',
                    MsgComp: () => (
                        <div>
                            <Typography className="word-break-break-word">{message}</Typography>
                            <p />
                            <Button to={url} color="error">
                                保管責任書
                            </Button>
                        </div>
                    ),
                });
            }
        };

        const memberFilterMap = useMemo(
            () => {
                return {
                    1: {
                        description: '欠費名單',
                        rowLabel: FilterDebtLabel,
                        sortOption: debtSortOption,
                        Component: null,
                        excelApi: getMemberFilterOverdraftApi,
                        doApi: getOverdraftFilterDataApi,
                    },
                    2: {
                        description: '月費收費名單',
                        rowLabel: FilterMonthlyFeeLabel,
                        sortOption: monthFeeSortOption,
                        Component: FilterMonthlyFeeSearch,
                        excelApi: getMemberFilterMonthFeeApi,
                        doApi: getMonthFeeFilterDataApi,
                    },
                };
            },
            // eslint-disable-next-line
            [resource]
        );

        useEffect(
            () => {
                const filterTypeIsSame = String(prevFilterTypeRef.current) === String(filterType);
                const { rowLabel = [], sortOption = [] } = memberFilterMap[filterType];

                setRowsLabel(rowLabel);
                setSortOption(sortOption);

                if (!filterTypeIsSame) {
                    setBookmarkSortBy(1);
                    prevFilterTypeRef.current = filterType;
                    isMountingRef.current = true;
                }
                return () => {
                    setSourceData([]);
                    setRowsLabel([]);
                };
            },
            // eslint-disable-next-line
            [filterType, memberFilterMap]
        );

        // 換頁
        useEffect(
            () => {
                if (preloadRef.current) {
                    const params = getParams(bookmark);
                    bookmark && String(bookmark.pageIndex) !== '1' && doSearchApi(params);
                } else {
                    preloadRef.current = true;
                }
            },
            // eslint-disable-next-line
            [bookmark.pageIndex]
        );

        // 變更排序
        useEffect(
            () => {
                if (preloadRef.current) {
                    const params = getParams(bookmark);
                    if (!isMountingRef.current || (isMountingRef.current && String(bookmark.sortBy) !== '1')) {
                        doSearchApi(params);
                    } else {
                        isMountingRef.current = false;
                    }
                } else {
                    preloadRef.current = true;
                }
            },
            // eslint-disable-next-line
            [bookmark.sortBy]
        );

        useEffect(
            () => {
                doMemberFilterOptionsApi();
                isMountingRef.current = false;
            },
            // eslint-disable-next-line
            []
        );

        setRefresh(() => {
            const params = getParams(bookmark);
            doSearchApi(params);
        });
        const Comp = memberFilterMap[filterType]['Component'];
        return (
            <Card key={filterType} className={clsx(classNameProps)}>
                <Card.CardContent className="px-4 py-3">
                    <Form onSubmit={handleSearchClick}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <SelectField label="篩選名單" value={filterType} onChange={handleTypeChange} fullWidth>
                                    <MenuItem value="1">欠費名單</MenuItem>
                                    <MenuItem value="2">月費收費名單</MenuItem>
                                </SelectField>
                            </Grid>
                            {Comp ? <Comp ref={searchPanelsRef} resource={resource} /> : null}
                            <Grid item xs={3}>
                                <Stack direction={'row'} spacing={1} className="pt-4">
                                    <Button variant="contained" color="secondary" type="submit">
                                        搜尋
                                    </Button>
                                    <Button variant="contained" color="success" onClick={handleExcelOnClick}>
                                        下載Excel
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Form>
                </Card.CardContent>
            </Card>
        );
    })
);

export default AccountBookOverviewFilterSearchBar;
