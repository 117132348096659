import React, { useLayoutEffect } from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import MessageDialog from '@icoach/components/dialog/MessageDialog';
import ShowPdfDialog from '@icoach/components/dialog/ShowPdfDialog';
import { PrivateRoute } from '@common/components/';
import IcoachHome from '@icoach/home/';
import { NotFoundPage } from '@error/';

const Home = () => {
    const { replace: routeReplace } = useHistory();
    const { pathname: rootPath = '' } = useLocation();

    useLayoutEffect(
        () => {
            const splitPath = rootPath.split('/');
            if (splitPath.length === 2 && splitPath.filter((x) => x).length === 0) {
                routeReplace('/club');
            } else if (splitPath.includes('index') || splitPath.includes('index.html')) {
                routeReplace('/');
            }
        },
        // eslint-disable-next-line
        [rootPath, routeReplace]
    );

    return (
        <React.Fragment>
            <Switch>
                <PrivateRoute static path="/club" as={IcoachHome} />
                <PrivateRoute static path="/club-notifications/" as={IcoachHome} />
                <PrivateRoute path="*" as={NotFoundPage} />
            </Switch>
            <ShowPdfDialog />
            <MessageDialog />
        </React.Fragment>
    );
};

export default Home;
