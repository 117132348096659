import React from 'react';
import img_cloud from '../assets/img/cloud.png';
import { Box, Button, Typography } from '../../components';
import clsx from 'clsx';

const UploadFeedbackBox = (props) => {
    const {
        className,
        isShow,
        isError = false,
        totalAmount = 0,
        successAmount = 0,
        failureAmount = 0,
        failureDownloadPath: link,
        onDownload,
        onConfirm,
    } = props;

    let titleClassName = 'is-success';
    let titleText = '上傳成功';
    if (isError) {
        titleClassName = 'is-error';
        titleText = '上傳失敗';
    }

    if (!isShow) return null;

    return (
        <Box className={clsx('upload-feedback-box text-center', className)}>
            <Typography
                className={clsx('upload-feedback-box-title', titleClassName)}
                variant={'h6'}
            >
                {titleText}
            </Typography>
            <div className={`upload-feedback-box-wrap`}>
                <img className={`upload-feedback-box-img`} src={img_cloud} alt="" />
                <Typography>總筆數： {totalAmount}</Typography>
                <Typography className={clsx('desc', { 'is-success': !isError })}>
                    正確數： {successAmount}
                </Typography>
                <Typography className={clsx('desc mb-3', { 'is-error': isError })}>
                    錯誤數： {failureAmount}
                </Typography>
            </div>
            {isError ? (
                link && (
                    <React.Fragment>
                        <Typography variant={'body2'}>
                            下載並檢視錯誤欄位，修正後請重新上傳
                        </Typography>
                        <Button variant={'contained'} href={link} onClick={onDownload} download>
                            下載
                        </Button>
                    </React.Fragment>
                )
            ) : (
                <Button variant={'contained'} onClick={onConfirm}>
                    完成
                </Button>
            )}
        </Box>
    );
};

export default UploadFeedbackBox;
