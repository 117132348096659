import React from 'react';
import clsx from 'clsx';
import { Typography, Box, IconButton, Alert } from '@common/components/';
import img_creditCard from '@icoach/assets/img/credit-card.png';
import img_visa from '@icoach/assets/img/visa.png';
import img_mastercard from '@icoach/assets/img/mastercard.png';
import img_jcb from '@icoach/assets/img/jcb.png';
import img_cash from '@icoach/assets/img/cash.png';
import img_bank from '@icoach/assets/img/bank.png';
import { Edit as EditIcon } from '@common/SvgIcon/';

const matchImg = (type) => {
    switch (String(type)) {
        case '1-1':
            return img_visa;
        case '1-2':
            return img_mastercard;
        case '1-3':
            return img_jcb;
        case '2':
            return img_bank;
        case '3':
            return img_cash;
        default:
            return img_creditCard;
    }
};

const PaymentCard = ({ className, title = '標題', paymentInfo = '', otherInfo = '', otherInfo2 = '', isNull, onEdit: onEditProps, imgType }) => {
    let img = matchImg(imgType);

    let handleOnEdit = (e) => {
        if (typeof onEditProps === 'function') onEditProps();
    };

    return (
        <Box className={clsx('payment-card', className)}>
            <Typography className={'payment-card-title'} variant="h6">
                {title}
            </Typography>
            <IconButton className="payment-card-btn" onClick={handleOnEdit}>
                <EditIcon />
            </IconButton>
            <Box className="payment-card-wrap">
                {isNull ? (
                    <Alert className={'mt-2 flex-grow-1'} variant="filled" severity="error" color="error">
                        尚未設定，請儘速設定
                    </Alert>
                ) : (
                    <React.Fragment>
                        <Box className="payment-card-img">
                            <img src={img} alt="" />
                        </Box>
                        <Box className="payment-card-content">
                            <Typography className={'pt-0'} variant="body1" component={'h6'}>
                                {paymentInfo}
                            </Typography>
                            <Typography className={'mb-1 font-color-3'} variant="body2">
                                {otherInfo}
                            </Typography>
                            <Typography className={'mb-1 font-color-3'} variant="body2">
                                {otherInfo2}
                            </Typography>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </Box>
    );
};

export default PaymentCard;
