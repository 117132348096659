import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Card, Button, Form, Stack, DateIntervalField } from '@common/components/';
import { isEmpty, objectToQueryString, paramsToObject } from '@util/utils';
import { MEMBERS_TABS_GIFT_DISPATCH_METRICS_URL } from '@icoach/router/MembersRouter';
import { useGiftContext } from '@icoach/gift/components/GiftContext';
import useGiftItemRecordApi from '@apis/useGiftItemRecordApi';
import { getSubDays, getToday } from '@util/moment';
import { initBookmark } from '@icoach/gift/staticData';

const GiftDispatchMetricsSearchBar = (props) => {
    const { className } = props;
    const history = useHistory();
    const { setSourceData, bookmark, setBookmarkNum, setRefresh } = useGiftContext();
    const { getGiftItemRecordStatisticsApi } = useGiftItemRecordApi();
    const [paramsData, setParamsData] = useState({});
    const periodRef = useRef(null);
    const today = getToday();

    const getParams = (bookmark) => {
        const result = {};

        if (bookmark) {
            Object.assign(result, bookmark);
        }

        if (periodRef.current && periodRef.current.getResult) {
            Object.assign(result, periodRef.current.getResult());
        }

        return result;
    };

    /** 更新網址上的搜尋條件 **/
    const updateUrlWithSearchQuery = (params) => {
        let url = MEMBERS_TABS_GIFT_DISPATCH_METRICS_URL;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getOverviewApi = async (params) => {
        let resp = await getGiftItemRecordStatisticsApi(params);
        if (resp) {
            setSourceData(resp);
        }
        updateUrlWithSearchQuery(params);
    };

    const handleFormSubmit = () => {
        const params = getParams(initBookmark());
        getOverviewApi(params);
        setBookmarkNum(1);
    };

    useEffect(
        () => {
            if (String(bookmark.pageIndex) === '1') return;
            const params = getParams(bookmark);
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [bookmark.pageIndex],
    );

    useEffect(() => {
        const { location } = history;
        const search = location.search;
        const params = search ? paramsToObject(search) : getParams(bookmark);

        if (!isEmpty(params)) {
            setParamsData(params);
        }
        getOverviewApi(params);

        setRefresh(handleFormSubmit);
        // eslint-disable-next-line
    }, []);

    return (
        <Card className={clsx('search-bar-light p-3', className)}>
            <Card.CardContent>
                <Form onSubmit={handleFormSubmit}>
                    <Stack spacing={2} alignItems={'start'}>
                        <Grid spacing={2} container>
                            <Grid xs={12} item>
                                <DateIntervalField
                                    key={`${paramsData.startDate}-${paramsData.endDate}`}
                                    ref={periodRef}
                                    label={'發送期間'}
                                    symbol={'~'}
                                    startDateProps={{
                                        defaultValue: getSubDays(today, 30),
                                    }}
                                    endDateProps={{
                                        defaultValue: today,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Button className={'white-space-nowrap mt-4'} type={'submit'} variant={'contained'}>
                            搜尋
                        </Button>
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default GiftDispatchMetricsSearchBar;
