import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import EmployeesInformationsBasicEditContent from './EmployeesInformationsBasicEditContent';
import EmployeesInformationsBasicReadContent from './EmployeesInformationsBasicReadContent';
import useEmployeeApi from '../../../apis/useEmployeeApi';
import { Card } from '../../../components/';

const EmployeesInformationsBasic = React.forwardRef((props, ref) => {
    const { employeeID } = props;
    const [sourceData, setSouceData] = useState({});
    const [isEdit, setEdit] = useState(false);
    const { getEmployeesEmployeeIDApi } = useEmployeeApi();

    const getInitDataApi = async (id) => {
        const resp = await getEmployeesEmployeeIDApi(id);
        if (resp) {
            setSouceData(resp);
        }
    };

    const handleEditClick = () => {
        setEdit(true);
    };

    const handleCancelEditClick = () => {
        setEdit(false);
    };

    useEffect(
        () => {
            getInitDataApi(parseInt(employeeID, 10));
            return () => setSouceData({});
        },
        // eslint-disable-next-line
        []
    );

    const { resource, ...otherKeys } = sourceData;

    return (
        <React.Fragment>
            {!_.isEmpty(sourceData) ? (
                <Card.CardContent className="pt-4 px-4 pb-3">
                    {isEdit ? (
                        <EmployeesInformationsBasicEditContent
                            sourceData={otherKeys}
                            options={resource || {}}
                            onCancelEditClick={handleCancelEditClick}
                            onReadOnlyApi={getInitDataApi}
                        />
                    ) : (
                        <EmployeesInformationsBasicReadContent
                            sourceData={otherKeys}
                            options={resource || {}}
                            onEditClick={handleEditClick}
                        />
                    )}
                </Card.CardContent>
            ) : null}
        </React.Fragment>
    );
});

export default EmployeesInformationsBasic;
