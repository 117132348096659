import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { ReadTextField } from '../../components';

const CycleOrderShippingDialog = (props) => {
    const { className, data = {}, open, onClose } = props;
    const { receiverName, receiverMobile, receiverPostCode, receiverAddress, receiverNotes } = data;
    const getAddress = (receiverPostCode, receiverAddress) => {
        let address = '';
        if (receiverPostCode) address += receiverPostCode + ' ';
        address += receiverAddress;
        return address;
    };
    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-40rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>收件人資訊</GradientColorHeader>
            <DialogContent>
                <Grid spacing={3} container>
                    <Grid xs={12} item>
                        <ReadTextField label={'收件人'}>{receiverName}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'收件人手機'}>{receiverMobile}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'收件地址'}>{getAddress(receiverPostCode, receiverAddress)}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'備註'}>{receiverNotes}</ReadTextField>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CycleOrderShippingDialog;
