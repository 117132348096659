import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const KeySolid = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M15.8,16.5c4.6,0,8.2-3.7,8.2-8.2S20.3,0,15.8,0S7.5,3.7,7.5,8.2c0,0.9,0.1,1.7,0.4,2.5l-7.6,7.6C0.1,18.5,0,18.8,0,19.1
	v3.8C0,23.5,0.5,24,1.1,24h3.8C5.5,24,6,23.5,6,22.9V21h1.9C8.5,21,9,20.5,9,19.9V18h1.9c0.3,0,0.6-0.1,0.8-0.3l1.6-1.6
	C14,16.4,14.9,16.5,15.8,16.5z M17.6,4.5c1,0,1.9,0.8,1.9,1.9s-0.8,1.9-1.9,1.9s-1.9-0.8-1.9-1.9S16.6,4.5,17.6,4.5z"
            />
        </SvgIcon>
    );
});

export default KeySolid;
