import { useCallback } from 'react';
import useAjax from '@apis/useAjax';

const GET_AGP_REPORT_DETAIL_API = (dateString, dataKeyName) => `/api/reports/monthly/${dateString}/agp/${dataKeyName}-details`;
const GET_AGP_GUEST_REPORT_DETAIL_API = (dateString, dataKeyName) => `/api/reports/monthly/${dateString}/agp-guest/${dataKeyName}-details`;
const GET_CALLS_REPORT_DETAIL_API = (dateString, dataKeyName) => `/api/reports/monthly/${dateString}/calls/${dataKeyName}-details`;
const GET_APO_REPORT_DETAIL_API = (dateString, dataKeyName) => `/api/reports/monthly/${dateString}/apo/${dataKeyName}-details`;
const GET_TOTAL_SALES_REPORT_DETAIL_API = (dateString, dataKeyName) => `/api/reports/monthly/${dateString}/totalSales/${dataKeyName}-details`;

const useReportDetailApi = () => {
    const Ajax = useAjax();

    const getAGPReportDetailApi = useCallback(
        async (dateString, dataKeyName) => {
            return await Ajax.get(GET_AGP_REPORT_DETAIL_API(dateString, dataKeyName));
        },
        // eslint-disable-next-line
        [],
    );

    const getAGPGuestReportDetailApi = useCallback(
        async (dateString, dataKeyName) => {
            return await Ajax.get(GET_AGP_GUEST_REPORT_DETAIL_API(dateString, dataKeyName));
        },
        // eslint-disable-next-line
        [],
    );

    const getCallsReportDetailApi = useCallback(
        async (dateString, dataKeyName) => {
            return await Ajax.get(GET_CALLS_REPORT_DETAIL_API(dateString, dataKeyName));
        },
        // eslint-disable-next-line
        [],
    );

    const getAPOReportDetailApi = useCallback(
        async (dateString, dataKeyName) => {
            return await Ajax.get(GET_APO_REPORT_DETAIL_API(dateString, dataKeyName));
        },
        // eslint-disable-next-line
        [],
    );

    const getTotalSalesReportDetailApi = useCallback(
        async (dateString, dataKeyName) => {
            return await Ajax.get(GET_TOTAL_SALES_REPORT_DETAIL_API(dateString, dataKeyName));
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getAGPReportDetailApi,
        getAGPGuestReportDetailApi,
        getCallsReportDetailApi,
        getAPOReportDetailApi,
        getTotalSalesReportDetailApi,
    };
};

export default useReportDetailApi;
