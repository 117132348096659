import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import {
    DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER as READONLYCONTRACTPAGEROUTER,
    DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER as CONTRACTPAGEROUTER,
} from '@icoach/router/routerPath';
import { HealthDeclarationHistoryTable } from '@icoach/documents/table';
import { parseDate } from '@util/moment';
import { pathRouterShit } from '@util/utils';
import useDocHealthDeclarationApi from '@apis/useDocHealthDeclarationApi';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

const TH_DATA = [
    {
        cellKey: 'applyDate',
        headerLabel: '申請時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayDateBySlash, false),
    },
    {
        cellKey: 'changeDate',
        headerLabel: '最後更新時間',
        width: 20,
        formatCell: (cellValue) => parseDate(cellValue, DateTimeStandard.DisplayFullBySlash, false),
    },
    {
        cellKey: 'isTemporary',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (isTemporary, { isDeleted }) => {
            return isTemporary ? '暫存' : isDeleted ? '已刪除' : '已受理';
        },
    },
];

const HealthDeclarationHistory = React.forwardRef((props, ref) => {
    const { onBlankContractClick } = props;
    const {
        getDocHealthDeclarationHistoryApi,
        deleteDocHealthDeclarationContractApi,
        deleteDocHealthDeclarationTemporaryApi,
        getDocHealthDeclarationPDFApi,
    } = useDocHealthDeclarationApi();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const history = useHistory();
    const [historySourceData, setHistorySourceData] = useState([]);

    // 按鈕 建立新表單
    const handleCreateButtonClick = () => {
        onBlankContractClick && onBlankContractClick();
    };

    // icon 編輯
    const handleEditContractIconClick = (contractID) => {
        const params = {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        };
        const path = pathRouterShit(CONTRACTPAGEROUTER, params);
        history.push(path);
    };

    // icon 查看閱讀模式
    const handleReadContractIconClick = (rowData) => {
        const { contractID } = rowData;
        const params = {
            peopleType: peopleTypeProps,
            referenceID: referenceIDProps,
            contractID: contractID,
        };
        const path = pathRouterShit(READONLYCONTRACTPAGEROUTER, params);
        history.push(path);
    };

    // icon 刪除暫存的健康申告書
    const handleDeleteTemporaryContractIconClick = () => {
        deleteTemporaryHealthDeclarationApi({ id: referenceIDProps, type: peopleTypeProps });
    };

    // icon 刪除健康申告書
    const handleDeleteContractIconClick = (rowData) => {
        deleteHealthDeclarationApi(rowData.contractID);
    };

    // icon 下載健康申告書PDF
    const handleDownloadContractIconClick = (rowData) => {
        const { contractUrl } = rowData;
        const param = {
            contractUrl,
        };
        downloadHealthDeclarationPDF(param);
    };

    // 刪除暫存健康申告書
    const deleteTemporaryHealthDeclarationApi = async (params) => {
        const resp = await deleteDocHealthDeclarationTemporaryApi(params);
        if (resp) await getInitPeopleRecordOverview();
    };

    // 刪除健康申告書
    const deleteHealthDeclarationApi = async (contractID) => {
        const resp = await deleteDocHealthDeclarationContractApi(contractID);
        if (resp) await getInitPeopleRecordOverview();
    };

    // 下載健康申告書PDF
    const downloadHealthDeclarationPDF = async (params) => {
        await getDocHealthDeclarationPDFApi(params);
    };

    // 讀取該對象的歷史紀錄
    const getInitPeopleRecordOverview = async () => {
        const parmas = { type: peopleTypeProps, id: referenceIDProps };
        const resp = await getDocHealthDeclarationHistoryApi(parmas);
        if (resp) setHistorySourceData(resp);
    };

    useEffect(
        () => {
            getInitPeopleRecordOverview();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <HealthDeclarationHistoryTable
            thSource={TH_DATA}
            source={historySourceData}
            onCreateEvent={handleCreateButtonClick}
            onEditEvent={handleEditContractIconClick}
            onDeleteTemporaryEvent={handleDeleteTemporaryContractIconClick}
            onDeleteEvent={handleDeleteContractIconClick}
            onReadEvent={handleReadContractIconClick}
            onDownloadEvent={handleDownloadContractIconClick}
            getInitPeopleRecordOverview={getInitPeopleRecordOverview}
        />
    );
});

export default HealthDeclarationHistory;
