import React, { forwardRef } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { Button } from '../../../components';
import clsx from 'clsx';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';

const SimpleContentDialog = forwardRef((props, ref) => {
    const {
        className,
        children,
        open,
        title,
        handleClose,
        handleSubmit,
        fullScreen = false,
        isDialogActions = true,
        btnText = '更新',
        fullWidth,
        submitBtnProps,
    } = props;
    const handleCloseEvent = (e) => {
        if (typeof handleClose === 'function') handleClose(e);
    };

    const handleSubmitEvent = (e) => {
        if (typeof handleSubmit === 'function') handleSubmit();
    };

    const submitBtnAttr = {
        ...submitBtnProps,
        onClick: handleSubmitEvent,
    };

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            fullScreen={fullScreen}
            open={open}
            fullWidth={fullWidth}
            PaperProps={{ className: 'wx-65rem' }}
        >
            <GradientColorHeader onClose={handleCloseEvent}>{title}</GradientColorHeader>
            <DialogContent>{children}</DialogContent>
            {isDialogActions && (
                <DialogActions className="px-4 pt-0 pb-2">
                    <Button variant="contained" {...submitBtnAttr}>
                        {btnText}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
});

export default SimpleContentDialog;
