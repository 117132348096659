import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Ruler = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M23.8,8.7l-3-5.1c-0.3-0.6-1.1-0.8-1.6-0.4l-2.6,1.5l2.2,3.8c0.1,0.1,0,0.3-0.1,0.4l-0.5,0.3c-0.1,0.1-0.3,0-0.4-0.1
	l-2.2-3.8l-2.1,1.2l1,1.8c0.1,0.1,0,0.3-0.1,0.4L14,8.9c-0.1,0.1-0.3,0-0.4-0.1l-1-1.8l-2.1,1.2l2.2,3.8c0.1,0.1,0,0.3-0.1,0.4
	L12,12.7c-0.1,0.1-0.3,0-0.4-0.1L9.4,8.7L7.3,9.9l1,1.8c0.1,0.1,0,0.3-0.1,0.4l-0.5,0.3c-0.1,0.1-0.3,0-0.4-0.1l-1-1.8l-2.1,1.2
	l2.2,3.8c0.1,0.1,0,0.3-0.1,0.4l-0.5,0.3c-0.1,0.1-0.3,0-0.4-0.1l-2.2-3.8l-2.6,1.5c-0.6,0.3-0.8,1-0.4,1.6l3,5.1
	c0.3,0.6,1.1,0.8,1.6,0.4l18.6-10.6C24,10,24.2,9.2,23.8,8.7L23.8,8.7z"
            />
        </SvgIcon>
    );
});

export default Ruler;
