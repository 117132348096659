import { useCallback } from 'react';
import useAjax from '@apis/useAjax';

const GET_MEMBER_QRCODE_API = (memberID) => `api/members/${memberID}/qrcode/`;

const useMemberTokenApi = () => {
    const Ajax = useAjax();

    const getMemberQrcodeApi = useCallback(
        async (memberID) => await Ajax.get(GET_MEMBER_QRCODE_API(memberID)),
        // eslint-disable-next-line
        []
    );

    return {
        getMemberQrcodeApi,
    };
};

export default useMemberTokenApi;
