import React, { useRef } from 'react';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import BatchPaymentOrdersCashTable from '@icoach/accountBook/tables/BatchPaymentOrdersCashTable';
import { Box, Stack } from '@common/components/';
import SearchMemberField from '@icoach/accountBook/components/SearchMemberField';

const BatchPaymentControllerBox = (props) => {
    const { tableRef } = props;

    return (
        <Box className={'bulk-controller-box-container'}>
            <Stack className={'mb-2'}>
                <Box className={'ml-auto btn-group tool-btn-group'}>
                    <SearchMemberField tableRef={tableRef} />
                </Box>
            </Stack>
        </Box>
    );
};

export const BatchPaymentOrdersByCash = (props) => {
    const { tableData } = props;
    const { paramData, refresh, dialogRef } = useAccountBookContext();
    const tableRef = useRef();

    return (
        <React.Fragment>
            <BatchPaymentControllerBox tableRef={tableRef} />
            <BatchPaymentOrdersCashTable
                ref={tableRef}
                className={'theme-gray'}
                rowsData={tableData}
                refresh={refresh}
                dialogRef={dialogRef}
                paramData={paramData}
            />
        </React.Fragment>
    );
};

export default BatchPaymentOrdersByCash;
