import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_REMITTANCE_LIST_API = `/api/remittances/`;
const GET_REMITTANCE_OPTIONS_API = `/api/remittances/options/`;
const POST_REMITTANCE_BY_CLUB_API = `/api/remittances/by-club/`;
const POST_REMITTANCE_BY_HEADQUARTER_API = `/api/remittances/by-headquarter/`;
const DELETE_REMITTANCE_ID_API = (remittanceID) => `/api/remittances/${remittanceID}/`;

const useClubRemittanceApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得匯款需使用到的下拉式選單
    const getRemittanceOptionsApi = useCallback(
        async () => {
            let resp = await Ajax.get(GET_REMITTANCE_OPTIONS_API);

            if (!resp) {
                enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得店舖匯款分頁清單
    const getRemittanceListApi = useCallback(
        async (_params) => {
            const { pageSize, pageIndex, ...other } = _params;
            const params = {
                ...other,
                size: pageSize,
                page: pageIndex,
            };
            let resp = await Ajax.get(GET_REMITTANCE_LIST_API, params);

            if (!resp) {
                enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            }

            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 發送店舖交易收入資料
    const postRemittanceByClubApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_REMITTANCE_BY_CLUB_API, params);

            if (resp) {
                enqueueSnackbar('結帳完成', { variant: 'success' });
            } else {
                enqueueSnackbar('結帳失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 發送總部紅利收入資料
    const postRemittanceByHeadquarterApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_REMITTANCE_BY_HEADQUARTER_API, params);

            if (resp) {
                enqueueSnackbar('結帳完成', { variant: 'success' });
            } else {
                enqueueSnackbar('結帳失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 發送總部紅利收入資料
    const deleteRemittanceApi = useCallback(
        async (remittanceID) => {
            let resp = await Ajax.delete(DELETE_REMITTANCE_ID_API(remittanceID));

            if (resp) {
                enqueueSnackbar('成功刪除', { variant: 'success' });
            }

            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getRemittanceOptionsApi,
        getRemittanceListApi,
        postRemittanceByClubApi,
        postRemittanceByHeadquarterApi,
        deleteRemittanceApi,
    };
};

export default useClubRemittanceApi;
