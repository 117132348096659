import React, { useRef, useState } from 'react';
import _ from 'lodash';
import AccountBookOverviewFilterSearchBar from '@icoach/accountBook/AccountBookOverviewFilterSearchBar';
import AccountBookOverviewFilterTable from '@icoach/accountBook/AccountBookOverviewFilterTable.jsx';
import { AccountBookProvider } from '@icoach/accountBook/components/AccountBookContext';
import { Typography } from '@common/components/';
import { initBookmark } from '@icoach/members/staticData';

const AccountBookOverviewFilterPage = () => {
    const [rowsLabel, setRowsLabel] = useState([]);
    const [sortOption, setSortOption] = useState([]);
    const [sourceData, setSourceData] = useState({});
    const [resource, setResource] = useState({});
    const [bookmark, setBookmark] = useState(initBookmark());
    const reSetCheckedItemsRef = useRef(null);
    const refreshRef = useRef(null);

    const refreshFn = () => {
        typeof refreshRef.current === 'function' && refreshRef.current();
    };

    const setContactState = (memberID, params) => {
        setSourceData(({ list, ...others }) => {
            let newData = _.cloneDeep(list);
            newData = newData.map((target) => {
                if (String(target.memberID) === String(memberID)) {
                    return {
                        ...target,
                        contact: params,
                    };
                }
                return target;
            });
            return {
                list: newData,
                ...others,
            };
        });
    };

    const childValue = {
        sourceData,
        setSourceData,
        bookmark,
        rowsLabel,
        setRowsLabel,
        sortOption,
        setSortOption,
        setContactState,
        resource,
        setResource,
        reSetCheckedItemsRef: (fn) => (reSetCheckedItemsRef.current = fn),
        reSetCheckedItems: () => typeof reSetCheckedItemsRef.current === 'function' && reSetCheckedItemsRef.current(),
        refresh: refreshFn,
        setRefresh: (el) => (refreshRef.current = el),
        setBookmarkNum: (num) => setBookmark((prev) => ({ ...prev, pageIndex: num })),
        setBookmarkSortBy: (sortBy) => setBookmark(() => initBookmark(sortBy)),
    };
    
    return (
        <AccountBookProvider value={childValue}>
            <div className="container main-container-spacing">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        快速名單查詢
                    </Typography>
                </header>
                <AccountBookOverviewFilterSearchBar className={'mb-4'} />
                <AccountBookOverviewFilterTable />
            </div>
        </AccountBookProvider>
    );
};

export default AccountBookOverviewFilterPage;
