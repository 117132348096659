import React, { useRef, useState } from 'react';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { BatchPaymentControllerBox } from '@icoach/accountBook/BatchPaymentOrdersByACH';
import BillingResultAchTable from '@icoach/accountBook/tables/BillingResultAchTable';
import { isEmpty } from '@util/utils';

export const BatchPaymentBillingByAch = (props) => {
    const { tableData } = props;
    const { paramData, resource, refresh } = useAccountBookContext();
    const [action, setAction] = useState('');
    const tableRef = useRef();
    const controllerRef = useRef();

    return (
        <React.Fragment>
            {!isEmpty(tableData) && (
                <BatchPaymentControllerBox
                    ref={controllerRef}
                    tableRef={tableRef}
                    resource={resource}
                    paramData={paramData}
                    action={action}
                    setAction={setAction}
                />
            )}
            <BillingResultAchTable
                className={'theme-gray'}
                ref={tableRef}
                controllerRef={controllerRef}
                rowsData={tableData}
                action={action}
                refresh={refresh}
            />
        </React.Fragment>
    );
};

export default BatchPaymentBillingByAch;
