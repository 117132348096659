import React, { useRef, useImperativeHandle, useCallback } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { percentageToFloat, refIsRequiredError } from '@util/utils';
import useReportApi from '@apis/useReportApi';
import { kpiData } from '@icoach/dataAnalysis/DataAnalysisContentKpi';
import { KpiTextField } from '@icoach/dataAnalysis/components';

const GuestProductionKpiGoalDialogContent = React.forwardRef((props, ref) => {
    const { data } = props;
    const refs = useRef({});

    const makeFieldItem = useCallback(
        (refs) => {
            return kpiData.gp
                .filter((item) => item && item.targetEnabled)
                .map((item, i) => {
                    return <KpiTextField ref={(el) => (refs.current[item.key] = el)} key={item.key} data={data[item.key]} labelData={item} items={[4, 7]} />;
                });
        },
        [data],
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const result = [];
                const handleValue = (data, ref) => {
                    const { dataType, isRate } = data;
                    let targetSettingValue = ref.getResult() || 0;
                    if (isRate) {
                        targetSettingValue = percentageToFloat(targetSettingValue);
                    }
                    return {
                        dataType,
                        targetSettingValue,
                    };
                };

                if (refs.current) {
                    let refObj = refs.current;
                    for (let key in refObj) {
                        let current = refObj[key];
                        if (current.getResult) {
                            result.push(handleValue(data[key], current));
                        }
                    }
                }

                return result;
            },
            isError: () => refIsRequiredError(Object.values(refs.current)),
        }),
        //eslint-disable-next-line
        [data],
    );
    return (
        <DialogContent className={'pt-4'}>
            <Grid spacing={2} container>
                {makeFieldItem(refs)}
            </Grid>
        </DialogContent>
    );
});

const GuestProductionKpiGoalDialog = (props) => {
    const { className, open, data, getDate, onClose, refresh } = props;
    const { postReportKpiGoalApi } = useReportApi();
    const contentRef = useRef();

    const doReportKpiGoalApi = async (date, params) => {
        let res = await postReportKpiGoalApi(date, params);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const getParams = () => {
        let result;
        if (contentRef.current && contentRef.current.getResult) result = contentRef.current.getResult();
        return result;
    };

    const handleSubmit = () => {
        let isError = refIsRequiredError(contentRef);
        if (!isError) {
            let params = getParams();
            let { date } = getDate();
            doReportKpiGoalApi(date, params);
        }
    };

    return (
        <Dialog
            key={open}
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>設定目標</GradientColorHeader>
            <GuestProductionKpiGoalDialogContent ref={contentRef} data={data} />
            <DialogActions className="px-4 pt-0 pb-2">
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GuestProductionKpiGoalDialog;
