import React from 'react';
import { isValidDate, parseDate } from '../../../util/moment';
import moment from 'moment';
import { Typography } from '../../../components';

const DateLabel = (props) => {
    const { date: dateProps } = props;
    if (!dateProps || !isValidDate(dateProps)) return '無效日期';
    let date, days;
    const getWeekDay = (date) => {
        const weekday = ['日', '一', '二', '三', '四', '五', '六'];
        let current = moment(date).weekday();
        return weekday[current];
    };
    date = parseDate(dateProps, 'MM/DD');
    days = getWeekDay(dateProps);

    return (
        <Typography className={'my-0'}>
            <span className={'mr-1'}>{date}</span>
            <span className={'font-color-3'}>({days})</span>
        </Typography>
    );
};

export default DateLabel;
