import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HOME_TABS_ARRIVE_URL } from '@icoach/router/LandingPageRouter';
import { Typography, Box } from '@common/components/';
import { localUser } from '@util/utils';
import { getVersionData } from '@redux/version/selector';
import LogoSignDrop from '@images/login_1.svg';
import LogoSignPoint from '@images/login_2.svg';
import NavLogo from '@images/nav_logo.svg';
import NotificationBellBox from '@icoach/navigation/NotificationBellBox';

const NavTop = (props) => {
    const { onCloseMenu } = props;
    const user = localUser.get();
    const reduxVersion = useSelector(getVersionData);
    let { env: ENV = '' } = reduxVersion;
    if (!['UAT', 'DEV'].includes(String(ENV).toUpperCase())) {
        ENV = '';
    }

    return (
        <Box className="nav-top">
            <Box className="logo-container">
                <img src={LogoSignDrop} className="logo-drop" alt="Curves_Design" />
                <img src={LogoSignPoint} className="logo-point" alt="Curves_Design" />
                <Box className="logo-text">
                    <Link to={HOME_TABS_ARRIVE_URL} onClick={onCloseMenu}>
                        <img src={NavLogo} className="logo-nav" alt="Curves" />
                        <Typography variant="h6" className="logo-store-text">
                            {user.displayName}
                        </Typography>
                    </Link>
                    {ENV && <span className="env-store">{String(ENV).toUpperCase()}</span>}
                </Box>
            </Box>
            <Box className="notify-bell">
                <NotificationBellBox />
            </Box>
        </Box>
    );
};

export default NavTop;
