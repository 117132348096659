import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Alert, Button, MenuItem, SelectField } from '../../../components';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { refIsRequiredError } from '../../../util/utils';
import useCabinetRentalApi from '../../../apis/useCabinetRentalApi';

const DeleteQuitRentCabinetDialogContent = React.forwardRef((props, ref) => {
    const { className, coachesOption = [] } = props;
    const employeeRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
                }
                return result;
            },
            isError: () => refIsRequiredError(employeeRef),
        }), // eslint-disable-next-line
        [],
    );
    return (
        <React.Fragment>
            <Grid className={className} spacing={3} container>
                <Grid xs={12} item>
                    <SelectField label="擔當" ref={employeeRef} fullWidth required>
                        <MenuItem value={''}>
                            <em>請選擇</em>
                        </MenuItem>
                        {coachesOption.map(({ text, value }) => (
                            <MenuItem value={value} key={value}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
            </Grid>
        </React.Fragment>
    );
});

const DeleteQuitRentCabinetDialog = (props) => {
    const { className, open, sourceData, onClose, refresh } = props;
    const { cabinetRentalID } = sourceData || {};
    const { getRentCabinetOptionsApi, deleteQuitRentCabinetApi } = useCabinetRentalApi();
    const [resource, setResource] = useState({});
    const { employeeOption = [] } = resource;
    const formRef = useRef();

    const doInitCabinetApi = async () => {
        let res = await getRentCabinetOptionsApi();
        if (res) {
            setResource(res);
        }
    };

    const doCancelQuitRentCabinetApi = async (cabinetRentalID, employeeID) => {
        let res = await deleteQuitRentCabinetApi(cabinetRentalID, employeeID);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const getParams = () => {
        let result = {};
        if (formRef.current && formRef.current.getResult) Object.assign(result, formRef.current.getResult());
        return result;
    };

    const handleSubmit = () => {
        let isError = refIsRequiredError(formRef);
        if (!isError) {
            let { employeeID } = getParams();
            doCancelQuitRentCabinetApi(cabinetRentalID, employeeID);
        }
    };

    useEffect(() => {
        if (open) doInitCabinetApi();
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>確認取消退租申請</GradientColorHeader>
            <DialogContent className="p-4">
                <DeleteQuitRentCabinetDialogContent className={'mb-3'} ref={formRef} coachesOption={employeeOption} />
                <Alert className="mb-3" severity="error" variant="filled" color="error">
                    請注意，一旦刪除將無法恢復
                </Alert>
            </DialogContent>
            <DialogActions className="px-4 pt-0 pb-2">
                <Button variant="contained" onClick={handleSubmit}>
                    確認刪除
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteQuitRentCabinetDialog;
