import React, { useRef } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { Box, IconButton, Typography } from '../../../components';
import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
} from '../../../components/icons/SvgIcon/';

const colors = {
    1: '#66bb6a', // 正常
    2: '#ffa726', // 注意
    3: '#ef5350', // 危險
    4: '#bdbdbd',
};

const WorkoutTimeline = ({ data, itemsWidth }) => {
    return (
        <div className="workout-timeline">
            <ul className="workout-timeline-wrap">
                {Array.isArray(data) &&
                    data.map((item, i) => {
                        return (
                            <li
                                key={uuid()}
                                className="workout-timeline-item"
                                style={{
                                    '--levelColor': colors[item.level],
                                    flexBasis: i !== 0 && itemsWidth.current + '%',
                                    maxWidth: i !== 0 && itemsWidth.current + '%',
                                }}
                            >
                                {item.month === '1' ? (
                                    <Typography variant="body2" className="name">
                                        {`${item.year}年`}
                                        <br />
                                        {`${item.month}月`}
                                    </Typography>
                                ) : (
                                    <Typography variant="body2" className="name">
                                        {`${item.month}月`}
                                    </Typography>
                                )}
                                <Typography className="text" style={{ color: colors[item.level] }}>
                                    {item.count}
                                </Typography>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

const WorkoutTimelineBox = ({ className, data = {}, pageSize = 7, handlePrev, handleNext }) => {
    let { prevText = 'prev', nextText = 'next', items } = data;
    let itemsWidth = useRef(100 / (pageSize - 1));

    const onClickNext = () => {
        if (typeof handleNext === 'function') handleNext();
    };

    const onClickPrev = () => {
        if (typeof handlePrev === 'function') handlePrev();
    };

    return (
        <Box className={clsx('workout-timeline-box', className)}>
            <div className="workout-timeline-controller prev">
                <Typography variant="body2" className="text">
                    {prevText}
                </Typography>
                <IconButton onClick={onClickPrev} disabled={!prevText}>
                    <NavigateBeforeIcon />
                </IconButton>
            </div>

            <div className="workout-timeline-controller next">
                <Typography variant="body2" className="text">
                    {nextText}
                </Typography>
                <IconButton onClick={onClickNext} disabled={!nextText}>
                    <NavigateNextIcon />
                </IconButton>
            </div>
            <WorkoutTimeline data={items} itemsWidth={itemsWidth} />
        </Box>
    );
};

export default WorkoutTimelineBox;
