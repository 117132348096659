import React from 'react';
import ButtonBase from './ButtonBase';
import clsx from 'clsx';

const staticClass = `button`;
const sizeMap = ['small', 'medium', 'large'];
const variantMap = ['text', 'contained', 'outlined'];
const colorMap = ['primary', 'secondary', 'success', 'error', 'warning', 'info', 'gray'];

const ButtonIcon = (props) => {
    const { className, size, ...others } = props;
    const defaultProperty = {
        className: clsx(className, size === 'small' && 'button-small'),
        ...others,
    };
    return <span {...defaultProperty} />;
};

const Button = React.forwardRef((props, ref) => {
    const {
        className,
        color = 'secondary',
        variant = 'text',
        size = 'medium',
        disabled = false,
        startIcon: startIconProp,
        endIcon: endIconProp,
        onClick: propsOnClick,
        square = false,
        children,
        style: propsStyle = {},
        width = 0,
        fullWidth,
        ...other
    } = props;

    let classNames = `${staticClass}-root`;
    if (sizeMap.indexOf(size) !== -1) classNames = clsx(classNames, `${staticClass}-size-${size}`);
    if (variantMap.indexOf(variant) !== -1) classNames = clsx(classNames, `${staticClass}-variant-${variant}`);
    if (colorMap.indexOf(color) !== -1 && !disabled) classNames = clsx(classNames, `${staticClass}-color-${color}`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (square) classNames = clsx(classNames, `${staticClass}-square`);
    if (fullWidth && !width) classNames = clsx(classNames, `${staticClass}-full-width`);

    const startIcon = startIconProp && (
        <ButtonIcon className={clsx('button-start-icon')} size={size}>
            {React.cloneElement(startIconProp, { color: color })}
        </ButtonIcon>
    );

    const endIcon = endIconProp && (
        <ButtonIcon className={clsx('button-end-icon')} size={size}>
            {React.cloneElement(endIconProp, { color: color })}
        </ButtonIcon>
    );

    const handleOnClick = (e) => {
        propsOnClick && propsOnClick(e);
    };

    let styles = {
        width: width ? `${width}rem` : null,
        ...propsStyle,
    };

    const defaultProperty = {
        className: clsx(classNames, className),
        onClick: handleOnClick,
        disabled,
        style: { ...styles },
        ref,
        ...other,
    };
    return (
        <ButtonBase {...defaultProperty}>
            {startIcon}
            {children}
            {endIcon}
        </ButtonBase>
    );
});

export default Button;
