import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, Tab, Tabs } from '../../components';
import { AccountBookProvider, useAccountBookContext } from './components/AccountBookContext';
import AccountBookOrderOverviewSearchBar from '@icoach/accountBook/AccountBookOrderOverviewSearchBar';
import AccountBookOrderOverviewTable from '@icoach/accountBook/AccountBookOrderOverviewTable';
import { paramsToObject } from '@util/utils';
import { initBookmark } from './staticData';

const AccountBookOrderOverviewTabs = () => {
    const { orderSourceType, setOrderSourceType } = useAccountBookContext();

    const handleTabChange = (e, value) => setOrderSourceType(value);

    return (
        <Box component={'header'} className={'page-header'}>
            <Tabs value={orderSourceType} onChange={handleTabChange}>
                <Tab className={'py-3'} label={'現場訂單'} value={'1'} />
                <Tab className={'py-3'} label={'批次訂單'} value={'2'} />
            </Tabs>
        </Box>
    );
};
const AccountBookOrderOverviewPage = (props) => {
    const history = useHistory();
    const search = history.location.search;
    const { orderSourceType: orderSourceTypeByUrl, pageIndex } = paramsToObject(search);
    const [bookmark, setBookmark] = useState(initBookmark());
    const [tableData, setTableData] = useState({});
    const [orderSourceType, setOrderSourceType] = useState(orderSourceTypeByUrl || '1');
    const refreshRef = useRef(() => {});
    const preloadRef = useRef(true);

    const setBookmarkNum = (num) => setBookmark((prev) => ({ ...prev, pageIndex: num }));

    const childContext = {
        tableData,
        setTableData,
        orderSourceType,
        setOrderSourceType,
        bookmark,
        setBookmarkNum,
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
        dialogRef: props.dialogRef,
    };

    useEffect(() => {
        if (!pageIndex) return null;
        if (preloadRef.current) {
            setBookmarkNum(pageIndex);
            preloadRef.current = false;
        }
    }, [pageIndex]);

    return (
        <AccountBookProvider value={childContext}>
            <Card className={'tabs-content-layout'}>
                <AccountBookOrderOverviewTabs />
                <Card.CardContent className={'py-3 px-4'}>
                    <AccountBookOrderOverviewSearchBar className={'mb-2'} />
                    <AccountBookOrderOverviewTable />
                </Card.CardContent>
            </Card>
        </AccountBookProvider>
    );
};

export default AccountBookOrderOverviewPage;
