import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const staticClass = 'skeleton';
const animationArray = ['pulse', 'wave'];
const variantArray = ['circular', 'rectangular', 'text'];

const Skeleton = React.forwardRef((props, ref) => {
    const {
        animation = 'pulse',
        variant = 'text',
        className,
        component: Component = 'span',
        height,
        width,
        style = {},
        ...other
    } = props;
    let classNames = `${staticClass}-root`;
    if (animationArray.includes(animation)) classNames = clsx(classNames, `${staticClass}-animation-${animation}`);
    if (variantArray.includes(variant)) classNames = clsx(classNames, `${staticClass}-${variant}`);
    if (Boolean(other.children)) classNames = clsx(classNames, `${staticClass}-children`);
    if (Boolean(width)) classNames = clsx(classNames, `${staticClass}-no-width`);
    if (Boolean(height)) classNames = clsx(classNames, `${staticClass}-no-height`);

    const defaultProperty = {
        className: clsx(classNames, className),
        style: {
            height,
            width,
            ...style,
        },
        ref,
    };
    return <Component {...defaultProperty} />;
});

Skeleton.propTypes = {
    /**
     * The animation.
     * If `false` the animation effect is disabled.
     * @default 'pulse'
     */
    animation: PropTypes.oneOf(['pulse', 'wave', false]),
    /**
     * Optional children to infer width and height from.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * Height of the skeleton.
     * Useful when you don't want to adapt the skeleton to a text element but for instance a card.
     */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Width of the skeleton.
     * Useful when the skeleton is inside an inline element with no width of its own.
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * @ignore
     */
    style: PropTypes.object,
    /**
     * The type of content that will be rendered.
     * @default 'text'
     */
    variant: PropTypes.oneOfType([
        PropTypes.oneOf(['circular', 'rectangular', 'text']),
        PropTypes.string,
    ]),
};

export default Skeleton;