import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import _ from 'lodash';
import AppointmentPeopleInformationItem from '@icoach/appointment/information/AppointmentPeopleInformationItem';
import AppointmentMessageRecordItem from '@icoach/appointment/information/AppointmentMessageRecordItem';
import AppointmentAttendInformationItem from '@icoach/appointment/information/AppointmentAttendInformationItem';
import AppointmentInformationDataSkeleton from '@icoach/appointment/information/AppointmentInformationDataSkeleton';
import AppointmentContext from '@icoach/appointment/components/AppointmentContext';
import { APPOINTMENT_SCHEDULING_URL } from '@icoach/router/LandingPageRouter';
import { Typography, Stack, Button, Box } from '@common/components/';
import useAppointmentApi from '@apis/useAppointmentApi';
import useMessageDialog from '@util/hook/useMessageDialog';
import { parseDate } from '@util/moment';

const AppointmentInformationPage = React.forwardRef((props, ref) => {
    const setMessageDialog = useMessageDialog();
    const { computedMatch = {} } = props;
    const { appointmentID = null } = computedMatch.params;
    const { getAppointmentInitDataApi, deleteAppointmentIDApi } = useAppointmentApi();
    const history = useHistory();
    const [sourceData, setSourceData] = useState(null);
    const [options, setOptions] = useState({});
    const [isMounted, setMounted] = useState(false);
    const refreshRef = useRef(null);

    const childContext = {
        refresh: refreshRef,
        options: options,
    };

    const handleGoAppointmentSchedule = () => {
        const { appointmentDate } = sourceData;
        const datetime = parseDate(appointmentDate);
        history.push(`${APPOINTMENT_SCHEDULING_URL}?t=${datetime}`);
    };

    const handleDeleteButton = () => {
        setMessageDialog({
            open: true,
            title: '確認刪除',
            msg: '刪除後將無法恢復囉，確定要刪除預約單嗎？',
            onAsyncConfirm: (e, onClose) => deleteAppointmentApi(appointmentID, onClose),
        });
    };

    const getAppointmentInformationApi = async () => {
        const resp = await getAppointmentInitDataApi(appointmentID);
        if (resp) {
            const { options: opts, result } = resp;
            setSourceData(result);
            setOptions(opts);
            setMounted(true);
        }
    };

    const deleteAppointmentApi = async (appointmentID, onClose) => {
        const resp = await deleteAppointmentIDApi(appointmentID);
        if (resp) {
            onClose();
            history.push(APPOINTMENT_SCHEDULING_URL);
        }
    };

    useEffect(
        () => {
            if (!parseInt(appointmentID, 10)) return null;
            if (appointmentID && _.isEmpty(sourceData)) getAppointmentInformationApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [appointmentID]
    );

    refreshRef.current = getAppointmentInformationApi;

    return (
        <AppointmentContext.Provider value={childContext}>
            <Box className="container main-container-spacing">
                <Box className={'page-header'} component="header">
                    <Typography className={'page-header-title'} variant={'h3'}>
                        預約單
                    </Typography>
                    <Box className="btn-group">
                        {isMounted && (
                            <Button onClick={handleDeleteButton} variant="outlined">
                                刪除預約單
                            </Button>
                        )}
                        <Button variant="contained" onClick={handleGoAppointmentSchedule}>
                            返回預約表
                        </Button>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    {isMounted ? (
                        <React.Fragment>
                            <Grid xs={9} item>
                                <Stack spacing={2} direction="column">
                                    <AppointmentPeopleInformationItem sourceData={sourceData} />
                                    <AppointmentMessageRecordItem sourceData={sourceData} options={options.employeeOption} />
                                </Stack>
                            </Grid>
                            <Grid xs={3} item>
                                <AppointmentAttendInformationItem sourceData={sourceData} options={options} />
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <AppointmentInformationDataSkeleton />
                    )}
                </Grid>
            </Box>
        </AppointmentContext.Provider>
    );
});

export default AppointmentInformationPage;
