import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { cloneDeep, isEmpty } from 'lodash';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { Box, Card, Typography } from '@common/components/';
import { UserCircle as UserCircleIcon, Dollar as DollarIcon, Edit as EditIcon } from '@common/SvgIcon/';
import { parseDate } from '@util/moment';
import useToggleDialog from '@util/hook/useToggleDialog';
import useShowPDF from '@util/hook/useShowPDF';
import { getErrorModuleStatus, mergeDeep, organizeErrorMessages } from '@util/utils';
import PromptDialog from '@icoach/components/dialog/PromptDialog';
import { MembershipProvider } from '@icoach/documents/membership/create/MembershipContext';
import MembershipCreateNewMemberBox from '@icoach/documents/membership/create/MembershipCreateNewMemberBox';
import MembershipCreateContentSurvey from '@icoach/documents/membership/create/MembershipCreateContentSurvey';
import MembershipCreateContentBasic from '@icoach/documents/membership/create/MembershipCreateContentBasic';
import MembershipCreateContentFee from '@icoach/documents/membership/create/MembershipCreateContentFee';
import MembershipCreateContentPayment from '@icoach/documents/membership/create/MembershipCreateContentPayment';
import MembershipCreateContentProgram, { handlePlansData } from '@icoach/documents/membership/create/MembershipCreateContentProgram';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import { MembershipBillingRecordDialog, MembershipRecordDialog } from '@icoach/documents/membership/dialog';
import { OldStepper } from '@icoach/documents/components';
import { DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH } from '@icoach/router/routerPath';
import useDocMembershipApi from '@apis/useDocMembershipApi';
import { peopleType as peopleTypeMap } from '@apis/usePeopleApi';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';

// 入會申請書步驟內容
const tabsData = [
    {
        id: 'contractMain',
        label: '入會方案',
        content: <MembershipCreateContentProgram />,
        isShowState: false,
    },
    {
        id: 'contractSurvey',
        label: '會員須知',
        content: <MembershipCreateContentSurvey desc="因涉及會員權益與金額之認定，務必親自赴所屬店舖辦理。" isSign />,
        isShowState: false,
    },
    {
        id: 'contractFee',
        label: '初次費用',
        content: <MembershipCreateContentFee />,
        isShowState: false,
    },
    {
        id: 'contractBasic',
        label: '基本資料',
        content: <MembershipCreateContentBasic />,
        isShowState: false,
    },
    {
        id: 'contractPayment',
        label: '扣款授權',
        content: <MembershipCreateContentPayment />,
        isShowState: false,
    },
];

const WorkspaceBox = (props) => {
    const { handleOpenDialog } = props;
    return (
        <Box className="workspace-box">
            <SpeedDial ariaLabel="SpeedDial openIcon example" icon={<SpeedDialIcon openIcon={<EditIcon />} />}>
                <SpeedDialAction icon={<UserCircleIcon />} tooltipTitle={'會籍紀錄'} onClick={() => handleOpenDialog(dialogTypeMap.membershipRecord)} />
                <SpeedDialAction
                    icon={<DollarIcon htmlColor="#a45bc8" />}
                    tooltipTitle={'收費明細'}
                    onClick={() => handleOpenDialog(dialogTypeMap.membershipBillingRecord)}
                />
            </SpeedDial>
        </Box>
    );
};

/**
 * isReadOnly 是否唯讀
 * onReturnHistory 返回總覽頁面
 * isApplySuccess 是否為申請成功，用來轉倒回會員選項
 */
const MembershipCreateContent = (props) => {
    const { isReadOnly = false, onReturnHistory, isApplySuccess = false } = props;
    const { referenceID: referenceIDProps, contractID: contractIDProps, peopleType: peopleTypeProps } = useParams();
    const { getDocMembershipApi, postDocMembershipApi, postDocMembershipTemporaryApi, postDocMembershipRangeFeeApi } = useDocMembershipApi();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const showPDF = useShowPDF();
    const history = useHistory();
    // 判斷是否阻擋重整
    const [tabs, setTabs] = useState(tabsData);
    const [isMounted, setMounted] = useState(false);
    const [showPrompt, setShowPrompt] = useState(true);
    const [data, setData] = useState({});
    const [options, setOptions] = useState({});
    const [errorModule, setErrorModule] = useState({});
    const [newMemberAsset, setNewMemberAsset] = useState({});
    const stepperRef = useRef(null);
    const apiSendingRef = useRef(false);
    const {
        membershipID,
        contractID,
        memberID,
        contractVersionText,
        targetType,
        isCompleteContract, // 是否為正式文件
    } = data;

    // 取得入會申請書資料
    const doGetDocMembershipApi = async (contractID, params) => {
        const res = await getDocMembershipApi(contractID, params);
        if (res) {
            const { resource, ...others } = res;
            setData(others);
            setOptions(resource);
            setMounted(true);
        }
    };

    // 新增/重填入會申請書
    const doPostDocMembershipApi = async (contractID = 0, params) => {
        const res = await postDocMembershipApi(contractID, params);
        if (res) {
            if (res.isError) {
                const errorData = organizeErrorMessages(res.result);
                const updatedTabData = tabsData.map((item) => {
                    const key = item.id;
                    // 後端資料分類與前端步驟分類不同，需手動分類。
                    const fieldCategorization = {
                        contractMain: ['faDate', 'applyDate', 'employeeID'],
                    };

                    let _item = { ...item };
                    let _state = false;

                    if (errorData.hasOwnProperty(key)) {
                        _state = true;
                    } else if (fieldCategorization[key]) {
                        for (const fieldName of fieldCategorization[key]) {
                            if (errorData.hasOwnProperty(fieldName)) {
                                _state = true;
                                break;
                            }
                        }
                    }
                    _item.isShowState = _state;
                    return _item;
                });

                setErrorModule(errorData);
                setTabs(updatedTabData);
                apiSendingRef.current = false;
            } else {
                const { completedPath, htmlPath, memberID: mID, contractID: cID } = res;
                const routerPath = DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER_PATH(2, mID, cID);
                showPDF({ open: true, pdfUrl: completedPath, htmlUrl: htmlPath || '', fileName: '入會申請書' });
                setErrorModule({});
                setTabs(tabsData);
                setShowPrompt(false);
                setNewMemberAsset({ memberID: mID });
                window.setTimeout(() => {
                    history.replace(routerPath, { isApplySuccess: true });
                }, 5);
            }
        } else {
            setErrorModule({});
            setTabs(tabsData);
            apiSendingRef.current = false;
        }
    };

    // 暫存入會申請書
    const doDocMembershipTemporaryApi = async (params) => {
        const resp = await postDocMembershipTemporaryApi(params);
        if (resp) {
            setShowPrompt(false);
            window.setTimeout(() => {
                onReturnHistory && onReturnHistory();
            }, 5);
        } else {
            apiSendingRef.current = false;
        }
    };

    // 依入會方案取得合約日期、會籍日期、初次費用
    const doDocMembershipRangeFeeApi = async (params) => {
        const handleParams = (_params) => {
            let temporarySourceData = cloneDeep(data);
            temporarySourceData = mergeDeep(temporarySourceData, _params);
            temporarySourceData = handlePlansData(_params, temporarySourceData);
            return {
                targetType: temporarySourceData.targetType,
                targetID: temporarySourceData.targetID,
                tabIndex: _params.tabIndex,
                applyDate: parseDate(temporarySourceData.applyDate, DateTimeStandard.DisplayUtcFull),
                memberType: temporarySourceData.contractMain.memberType,
                paymentType: temporarySourceData.contractMain.paymentType,
                membershipTerm: temporarySourceData.contractMain.membershipTerm,
                membershipType: temporarySourceData.contractMain.membershipType,
                plans: temporarySourceData.contractMain.plans,
                membershipStartDate: parseDate(temporarySourceData.contractMain.startDate, DateTimeStandard.DisplayUtcFull),
                contractVersion: temporarySourceData.contractVersion,
                debitDayOfMonth: temporarySourceData.contractPayment.debitDayOfMonth,
                hasRefill: temporarySourceData.hasRefill,
                nextMonthFee: temporarySourceData.contractFee.nextMonthFee,
                firstMonthFee: temporarySourceData.contractFee.firstMonthFee,
            };
        };
        const _params = handleParams(params);
        const resp = await postDocMembershipRangeFeeApi(_params);
        if (resp) {
            const {
                contractStartDate,
                contractEndDate,
                startDate,
                endDate,
                reviewStartDate,
                reviewEndDate,
                monthlyFee,
                joinFee,
                firstMonthFee,
                nextMonthFee,
                debitStartDate,
                debitEndDate,
                debitDayOfMonth,
                debitFirstDate,
                canChangeMembershipTerm,
                canExchange,
                canModifyMembershipTerm,
            } = resp;
            const result = {
                canChangeMembershipTerm,
                canExchange,
                canModifyMembershipTerm,
                contractMain: {
                    contractStartDate,
                    contractEndDate,
                    startDate,
                    endDate,
                    reviewStartDate,
                    reviewEndDate,
                },
                contractFee: {
                    monthlyFee,
                    joinFee,
                    firstMonthFee,
                    nextMonthFee,
                },
                contractPayment: {
                    debitStartDate,
                    debitEndDate,
                    debitDayOfMonth,
                    debitFirstDate,
                },
            };
            if (_params.membershipType) result.contractMain.membershipType = _params.membershipType;
            setData((prev) => {
                let prevData = cloneDeep(prev);
                return mergeDeep(prevData, result);
            });
        }
        apiSendingRef.current = false;
    };

    const getParams = () => {
        let result = {};
        if (stepperRef.current && stepperRef.current.getResult) {
            result = Object.assign(result, stepperRef.current.getResult());
        }
        result = mergeDeep(cloneDeep(data), result);
        return result;
    };

    // 確認送出入會申請入 按鈕
    const handleSubmitCreate = () => {
        if (apiSendingRef.current) return false;
        const params = getParams();
        apiSendingRef.current = true;
        doPostDocMembershipApi(contractIDProps, params);
    };

    // 暫存入會申請書 按鈕
    const handleSubmitTemporary = () => {
        if (apiSendingRef.current) return false;
        const params = getParams();
        apiSendingRef.current = true;
        doDocMembershipTemporaryApi(params);
    };

    const handleChangeRangeFee = (params) => {
        if (apiSendingRef.current) return false;
        if (!isEmpty(params)) {
            apiSendingRef.current = true;
            params['tabIndex'] = stepperRef.current.getTabIndex() + 1;
            doDocMembershipRangeFeeApi(params);
        }
    };

    // 取得當前頁面的資料
    const handleChangeStepper = (tabIndex) => {
        let result = stepperRef.current.getResult();
        setData((prev) => {
            return mergeDeep(cloneDeep(prev), result);
        });
    };

    useEffect(
        () => {
            doGetDocMembershipApi(contractIDProps, { type: peopleTypeProps, id: referenceIDProps });
            return () => {
                setMounted(false);
            };
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            // 重刷頁面才會用 REPLACE
            if (history.action === 'REPLACE') {
                doGetDocMembershipApi(contractIDProps, { type: peopleTypeProps, id: referenceIDProps });
            }
        },
        // eslint-disable-next-line
        [history.location],
    );

    useEffect(
        () => {
            if (isApplySuccess) {
                setShowPrompt(true);
            }
        },
        // eslint-disable-next-line
        [isApplySuccess],
    );

    if (!isMounted) return null;

    return (
        <MembershipProvider
            value={{
                data,
                options,
                errorModule,
                isReadOnly,
                handleCloseDialog,
                getErrorModuleStatus,
                handleChangeRangeFee,
            }}
        >
            <Card>
                <Card.CardContent>
                    <OldStepper
                        ref={stepperRef}
                        stepSourceContent={tabs}
                        onChange={handleChangeStepper}
                        onTemporaryStorageEvent={handleSubmitTemporary}
                        onSubmitEvent={handleSubmitCreate}
                        onReturnHistory={() => onReturnHistory(newMemberAsset.memberID, isApplySuccess)}
                        isTemporary={!isReadOnly && String(targetType) !== peopleTypeMap.onlyJoin && !isCompleteContract}
                        isSubmit={!isReadOnly}
                        isCancel={!isReadOnly}
                        isBack={isReadOnly}
                        ButtonEls={<MembershipCreateNewMemberBox memberID={newMemberAsset.memberID || null} />}
                    />
                    <Typography variant="body2" className="bg-light text-center mb-0 pb-2 px-2 font-color-3">
                        {contractVersionText}
                    </Typography>
                </Card.CardContent>
            </Card>
            {isCompleteContract && <WorkspaceBox memberID={memberID} contractID={contractID} handleOpenDialog={handleOpenDialog} />}
            <MembershipRecordDialog
                open={openTarget === dialogTypeMap.membershipRecord}
                memberID={memberID}
                membershipID={membershipID}
                onClose={handleCloseDialog}
            />
            <MembershipBillingRecordDialog
                open={openTarget === dialogTypeMap.membershipBillingRecord}
                memberID={memberID}
                membershipID={membershipID}
                onClose={handleCloseDialog}
            />
            {(!isReadOnly || isApplySuccess) && <PromptDialog when={showPrompt} />}
        </MembershipProvider>
    );
};

export default MembershipCreateContent;
