// pageIndex: 第幾頁
// pageSize:  一頁幾筆
export const initBookmark = () => ({
    pageIndex: 1,
    pageSize: 10,
});

export const dialogType = {
    createCabinet: 'createCabinet',
    editCabinet: 'editCabinet',
    deleteCabinet: 'deleteCabinet',
    quitRentCabinet: 'quitRentCabinet',
    importCabinet: 'importCabinet',
    deleteQuitRentCabinet: 'deleteQuitRentCabinet',
};

// cabinetStatus 1租借中 2閒置 3暫停
export const CABINET_STATUS_COLOR_MATCH = new Map([
    [1, 'success'],
    [2, 'grey'],
    [3, 'error'],
]);

// cabinetStatus 1租借中 2閒置 3暫停
export const RENT_CABINET_STATUS_COLOR_MATCH = new Map([
    [1, 'success'],
    [2, 'error'],
    [3, 'warning'],
    [4, 'info'],
]);

// cabinetStatus 1租借中 2閒置 3暫停
export const cabinetStatusMap = {
    renting: 1,
    idle: 2,
    pause: 3,
    all: 99,
};
// cabinetStatus 1有效 2終止
export const cabinetRentalStatusMap = {
    valid: 1,
    quit: 2,
    all: 99,
};

export const cabinetStatusTextMap = new Map([
    [1, '租借中'],
    [2, '閒置'],
    [3, '暫停'],
    [4, '逾期'],
]);

export const cabinetPaymentCycleMap = {
    payOff: '2',
    monthly: '1',
};

export const getMoreActionArray = (moreActionObj) => {
    let array = [];
    for (let key in moreActionObj) {
        let bool;
        if (moreActionObj.hasOwnProperty(key)) {
            bool = moreActionObj[key];
        }
        if (bool) {
            array.push(key);
        }
    }
    return array;
};

// 置物櫃列表操作項
export const getCabinetMoreActionArray = (data = {}) => {
    const { isEditable = true, isDeletable = true, isRentalable = true } = data;
    const moreAction = {
        edit: isEditable,
        addRent: isRentalable,
        delete: isDeletable,
    };
    return getMoreActionArray(moreAction);
};

// 租約列表操作項
export const getRentCabinetMoreActionArray = (data = {}) => {
    const { isEditable, isQuitDeletable, canRenew } = data;
    const moreAction = {
        view: true,
        edit: isEditable,
        renew: canRenew,
        quit: isEditable,
        deleteQuit: isQuitDeletable,
        sms: true,
    };
    return getMoreActionArray(moreAction);
};

export const paymentTypeMap = {
    credit: '1',
    ach: '2',
    cash: '3',
};

export const paymentTypeIntMap = {
    credit: '1',
    ach: '2',
    cash: '3',
};

export const keyStatusMap = {
    inClub: 1,
    inMember: 2,
};

export const cabinetQuitKeyStatusMap = {
    lost: 3,
};

export const parseQueryStringToObject = (search) => {
    let params = new URLSearchParams(search);
    let obj = {};
    for (let pair of params.entries()) {
        let key = pair[0];
        let value = pair[1];
        if (!Object.hasOwnProperty(obj, key)) {
            obj[key] = value;
        }
    }
    return obj;
};
