import React from 'react';
import useFormControl from '../formControl/useFormControl';
import formControlState from '../formControl/formControlState';
import clsx from 'clsx';

const staticClass = `formhelpertext`;

const FormHelperText = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        component: Component = 'p',
        disabled,
        error,
        variant,
        size,
        required,
        isError: isErrorProps,
        ...other
    } = props;
    let classNames = staticClass;
    const formControl = useFormControl();
    const fcs = formControlState({
        props,
        formControl,
        states: ['variant', 'size', 'disabled', 'error', 'required'],
    });

    if (fcs.disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (fcs.error || isErrorProps) classNames = clsx(classNames, `${staticClass}-error`);
    if (fcs.size === 'small') classNames = clsx(classNames, `${staticClass}-size-small`);
    if (fcs.variant === 'contained') classNames = clsx(classNames, `${staticClass}-contained`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };
    return (
        <Component {...defaultProperty}>
            {children === ' ' ? (
                // notranslate needed while Google Translate will not fix zero-width space issue
                // eslint-disable-next-line react/no-danger
                <span className="notranslate" dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
            ) : (
                children
            )}
        </Component>
    );
});

export default FormHelperText;
