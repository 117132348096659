import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button, Card, DateField, Form, MenuItem, SelectField, Stack } from '@common/components/';
import useOrderBatchPaymentApi from '@apis/useOrderBatchPaymentApi';
import { paramsToObject, refIsRequiredError, serialize } from '@util/utils';
import { parseDate } from '@util/moment';
import { ACCOUNT_BOOK_BULK_DEBIT_URL } from '@icoach/router/AccountBookRouter';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';

const BatchPaymentManagementSearchBar = (props) => {
    const { className } = props;
    const history = useHistory();
    const { getOrderBatchPaymentOptionsApi, getOrderBatchPaymentApi } = useOrderBatchPaymentApi();
    const { resource, setResource, setTableData, setRefresh, paramData, setSearchConditions } = useAccountBookContext();
    const { orderTypeOptions = [] } = resource;
    const { type, date } = paramData || {};
    const typeRef = useRef(null);
    const dateRef = useRef(null);
    const preloadRef = useRef(false);

    const getParams = () => {
        let result = {};
        if (typeRef.current && typeRef.current.getResult) {
            result = Object.assign(result, { type: typeRef.current.getResult() });
        }

        if (dateRef.current && dateRef.current.getResult) {
            result = Object.assign(result, { date: parseDate(dateRef.current.getResult(), 'YYYY-MM') });
        }
        return result;
    };

    const updateUrlWithSearchQuery = (params) => {
        let url = ACCOUNT_BOOK_BULK_DEBIT_URL;
        if (!isEmpty(params)) url += `?${serialize(params)}`;
        history.replace(url);
    };

    const getOptionsApi = async () => {
        let resp = await getOrderBatchPaymentOptionsApi();
        if (resp) {
            setResource(resp);
            const { location } = history;
            const search = location.search;
            const params = search ? paramsToObject(search) : getParams();
            getOverviewApi(params);
        }
    };

    const getOverviewApi = async (params) => {
        preloadRef.current = false;
        let resp = await getOrderBatchPaymentApi(params);
        if (resp) {
            setTableData(resp);
        }
        preloadRef.current = true;
    };

    const handleSearchOnClick = (_params) => {
        let isError = refIsRequiredError(typeRef, dateRef);
        if (!isError) {
            let params = _params ? _params : getParams();
            getOverviewApi(params);
            setSearchConditions(params);
        }
    };

    useEffect(() => {
        updateUrlWithSearchQuery(paramData);
        // eslint-disable-next-line
    }, [paramData]);

    useEffect(
        () => {
            getOptionsApi();
            setRefresh(handleSearchOnClick);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Card className={clsx('search-bar px-4 py-3', className)}>
            <Card.CardContent>
                <Form onSubmit={() => handleSearchOnClick()}>
                    <Stack alignItems={'start'} spacing={3}>
                        <Stack className={'flex-auto'} spacing={3}>
                            <SelectField key={type} ref={typeRef} defaultValue={type} label={'項目'} direction={'row'} fullWidth required>
                                {Array.isArray(orderTypeOptions) &&
                                    orderTypeOptions
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                            <DateField
                                key={parseDate(date)}
                                ref={dateRef}
                                label={'月份'}
                                defaultValue={parseDate(date)}
                                direction={'row'}
                                views={['year', 'month']}
                                openTo={'month'}
                                format={'YYYY/MM'}
                                mask={'____/__'}
                                maxDate={parseDate()}
                                placeholder={'請選擇月份'}
                                fullWidth
                                required
                            />
                        </Stack>
                        <Button type={'submit'} variant={'contained'}>
                            搜尋
                        </Button>
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default BatchPaymentManagementSearchBar;
