import Mock from 'mockjs';
import { successApi } from './respondData';

export const withMockAuthMenuProvider = () => {
    Mock.mock(/^\/api\/me\/menus$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = MENUS;
        return sourceData;
    });
    Mock.setup({ timeout: '200-800' });
};

const MENUS = {
    routerType: 2,
    clubs: [
        {
            text: 'A000::健康快樂店',
            value: '1',
            disabled: false,
        },
    ],
    menus: [
        {
            frontKey: 'C1X01',
            menuID: 1,
            menuName: '首頁',
            url: '',
            iconClass: 'home',
            isVisible: true,
            isPermissive: true,
            children: [
                {
                    frontKey: 'C1X02',
                    menuID: 2,
                    menuName: '來店會員',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C1X03',
                    menuID: 3,
                    menuName: '預約表',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C1X04',
                    menuID: 35,
                    menuName: '每日班表',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
            ],
        },
        {
            frontKey: 'C2X01',
            menuID: 4,
            menuName: '會員管理',
            url: '',
            iconClass: 'member',
            isVisible: true,
            isPermissive: true,
            children: [
                {
                    frontKey: 'C2X02',
                    menuID: 5,
                    menuName: '會員總覽',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C2X03',
                    menuID: 6,
                    menuName: '會員明細',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C2X04',
                    menuID: 7,
                    menuName: '預約管理',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C2X05',
                    menuID: 8,
                    menuName: '贈品管理',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C2X06',
                    menuID: 9,
                    menuName: '租櫃管理',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
            ],
        },
        {
            frontKey: 'C3X01',
            menuID: 10,
            menuName: '顧客管理',
            url: '',
            iconClass: 'customer',
            isVisible: true,
            isPermissive: true,
            children: [
                {
                    frontKey: 'C3X02',
                    menuID: 11,
                    menuName: '顧客總覽',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C3X03',
                    menuID: 12,
                    menuName: '新增詢問',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C3X04',
                    menuID: 13,
                    menuName: '名單總覽',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C3X05',
                    menuID: 14,
                    menuName: '新增名單',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C3X06',
                    menuID: 15,
                    menuName: '暫存名單',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C3X07',
                    menuID: 16,
                    menuName: '主動顧客製造',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C3X08',
                    menuID: 17,
                    menuName: '預約管理',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C3X09',
                    menuID: 40,
                    menuName: '顧客基本資料',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C3X10',
                    menuID: 41,
                    menuName: '名單基本資料',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
            ],
        },
        {
            frontKey: 'C4X01',
            menuID: 18,
            menuName: '文書管理',
            url: '',
            iconClass: 'document',
            isVisible: true,
            isPermissive: true,
            children: [
                {
                    frontKey: 'C4X02',
                    menuID: 19,
                    menuName: '入會申請書',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X03',
                    menuID: 20,
                    menuName: '健康狀況申告書',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X04',
                    menuID: 21,
                    menuName: '會籍暫停申請書',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X05',
                    menuID: 22,
                    menuName: '會籍終止申請書',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X06',
                    menuID: 23,
                    menuName: '國內移籍申請書(移出)',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X07',
                    menuID: 24,
                    menuName: '國內移籍申請書(移入)',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X08',
                    menuID: 25,
                    menuName: '旅行卡',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X09',
                    menuID: 26,
                    menuName: '健康產品訂購單',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X10',
                    menuID: 27,
                    menuName: '健康產品暫停單',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X11',
                    menuID: 28,
                    menuName: '健康產品退訂單',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C4X12',
                    menuID: 37,
                    menuName: '會籍轉讓申請書',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
            ],
        },
        {
            frontKey: 'C5X01',
            menuID: 29,
            menuName: '訊息管理',
            url: '',
            iconClass: 'message',
            isVisible: false,
            isPermissive: true,
            children: [],
        },
        {
            frontKey: 'C6X01',
            menuID: 30,
            menuName: '營收管理',
            url: '',
            iconClass: 'accountbook',
            isVisible: true,
            isPermissive: true,
            children: [
                {
                    frontKey: 'C6X02',
                    menuID: 31,
                    menuName: '收入總覽',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C6X03',
                    menuID: 32,
                    menuName: '現場收入總覽',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C6X04',
                    menuID: 33,
                    menuName: '總收入總覽',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C6X05',
                    menuID: 34,
                    menuName: '批次扣款',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
                {
                    frontKey: 'C6X06',
                    menuID: 36,
                    menuName: '新增收入',
                    url: '',
                    iconClass: '',
                    isVisible: true,
                    isPermissive: true,
                    children: null,
                },
            ],
        },
        {
            frontKey: 'C7X01',
            menuID: 38,
            menuName: '預約管理',
            url: '',
            iconClass: '',
            isVisible: false,
            isPermissive: true,
            children: [
                {
                    frontKey: 'C7X02',
                    menuID: 39,
                    menuName: '預約單明細',
                    url: '',
                    iconClass: '',
                    isVisible: false,
                    isPermissive: true,
                    children: null,
                },
            ],
        },
    ],
};
