import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, Alert, SelectField, MenuItem, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { ReadTextField } from '@icoach/components/';
import { formatCurrencyFn, refIsRequiredError } from '@util/utils';
import { displayDateText } from '@util/moment';
import useClubRemittanceReturnApi from '@apis/useClubRemittanceReturnApi';

const RemitInvoiceAbandonDialogContent = React.forwardRef((props, ref) => {
    const { data = {} } = props;
    const {
        coachOptions: employeeOptions,
        invoiceNo,
        remitDate,
        amount = 0,
        returnAmount = 0,
    } = data;
    const employeeRef = useRef();
    const notesRef = useRef();

    const getResult = () => {
        let result = {};

        if (employeeRef.current && employeeRef.current.getResult) {
            result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
        }
        if (notesRef.current && notesRef.current.getResult) {
            result = Object.assign(result, { notes: notesRef.current.getResult() });
        }
        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(employeeRef, notesRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <DialogContent className={'container main-container-spacing'}>
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <ReadTextField label={'發票號碼'}>{invoiceNo}</ReadTextField>
                </Grid>
                <Grid xs={6} item>
                    <ReadTextField label={'匯款日期'}>{displayDateText(remitDate)}</ReadTextField>
                </Grid>
                <Grid xs={6} item>
                    <ReadTextField label={'匯款金額'}>{formatCurrencyFn(amount)}</ReadTextField>
                </Grid>
                <Grid xs={6} item>
                    <ReadTextField label={'已退金額'}>
                        {formatCurrencyFn(returnAmount)}
                    </ReadTextField>
                </Grid>
                <Grid xs={6} item>
                    <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                        {Array.isArray(employeeOptions) &&
                            employeeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
                <Grid xs={12} item>
                    <TextField label="備註" ref={notesRef} rows={4} fullWidth multiline required/>
                </Grid>
                <Grid xs={12} item>
                    <Alert variant="filled" severity="info" color="info">
                        {`發票 ${invoiceNo} 作廢送出後將無法再恢復。`}
                    </Alert>
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const RemitInvoiceAbandonDialog = (props) => {
    const { className, open, remittanceID, onClose, refresh } = props;
    const {
        getRemittanceInvalidAbandonApi,
        postRemittanceInvalidAbandonConfirmApi,
    } = useClubRemittanceReturnApi();
    const [data, setData] = useState({});
    const { amount, returnAmount } = data;
    const contentRef = useRef();

    const doRemittanceInvalidAbandonApi = async (orderID) => {
        let res = await getRemittanceInvalidAbandonApi(orderID);
        if (res) {
            setData(res);
        } else {
            onClose();
        }
    };

    const doRemittanceInvalidAbandonConfirmApi = async (params) => {
        let res = await postRemittanceInvalidAbandonConfirmApi(params);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const getParams = () => {
        let result = {
            remittanceID,
            returnAmount: amount - returnAmount,
        };
        if (contentRef.current && contentRef.current.getResult) {
            result = Object.assign(result, contentRef.current.getResult());
        }

        return result;
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doRemittanceInvalidAbandonConfirmApi(params);
        }
    };

    useEffect(() => {
        if (open && remittanceID) doRemittanceInvalidAbandonApi(remittanceID);
        // eslint-disable-next-line
    }, [open, remittanceID]);

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>作廢匯款發票</GradientColorHeader>
            <RemitInvoiceAbandonDialogContent ref={contentRef} data={data} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認作廢發票
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemitInvoiceAbandonDialog;
