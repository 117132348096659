import React from 'react';
import { isNumber } from 'lodash';
import clsx from 'clsx';
import { Box, Typography } from '../../../components';
import img_default from '../../assets/img/default-img.png';
import { formatCurrencyFn } from '../../../util/utils';

const ProductBox = (props) => {
    const {
        className,
        imgUrl,
        name,
        variantName,
        sku,
        stock,
        isLimited = false,
        price,
        onClick,
        desc,
    } = props;
    const isSoldOut = isLimited && isNumber(stock) && stock < 1;
    return (
        <Box
            className={clsx('product-box', className, { 'sold-out': isSoldOut })}
            onClick={onClick}
        >
            <img className={'product-box-img'} src={imgUrl || img_default} alt={name} />
            <div className="product-box-desc">
                {name && variantName ? (
                    <Typography
                        className={'product-box-title'}
                        variant={'h3'}
                    >{`${name} ${variantName}`}</Typography>
                ) : (
                    <Typography className={'product-box-title'} variant={'h3'}>
                        {name}
                    </Typography>
                )}

                {sku && <Typography className={'sku'}>SKU：{sku}</Typography>}
                {isNumber(price) && (
                    <Typography className={'price'}>{'$' + formatCurrencyFn(price)}</Typography>
                )}
                {desc && <Typography className={'sku'}>{desc}</Typography>}
                {isLimited && isNumber(stock) && (
                    <Typography className={'stock'}>
                        {isSoldOut ? '售完' : `庫存：${stock}`}
                    </Typography>
                )}
            </div>
        </Box>
    );
};

export default ProductBox;
