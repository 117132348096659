import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import clsx from 'clsx';
import { Box } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useMembershipApi from '@apis/useMembershipApi';
import { membershipItemReasonsFilterTypeMap } from '@icoach/documents/membership/staticData';
import { MembershipRecordUI } from '@icoach/members/memberDetail/MemberDetailMembershipContentRecord';

const MembershipRecordDialogContent = (props) => {
    const { data, memberID, membershipID } = props;
    return (
        <DialogContent>
            <Box className="container main-container-spacing">
                <MembershipRecordUI data={data} memberID={memberID} membershipID={membershipID} />
            </Box>
        </DialogContent>
    );
};

const MembershipRecordDialog = (props) => {
    const { className, open, memberID, membershipID, onClose } = props;
    const { getMembershipItemReasonsApi } = useMembershipApi();
    const [data, setData] = useState();

    const doMembershipItemReasonsApi = async (memberID, membershipID) => {
        let res = await getMembershipItemReasonsApi(memberID, membershipID, {
            filter: membershipItemReasonsFilterTypeMap.all,
        });
        if (res) {
            setData(res);
        }
    };
    useEffect(() => {
        if (open && memberID && membershipID) {
            doMembershipItemReasonsApi(memberID, membershipID);
        }
        // eslint-disable-next-line
    }, [open, memberID, membershipID]);

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem' }}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>會籍紀錄</GradientColorHeader>
            <MembershipRecordDialogContent
                data={data}
                memberID={memberID}
                membershipID={membershipID}
            />
        </Dialog>
    );
};

export default MembershipRecordDialog;
