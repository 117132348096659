import React, { useRef } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { TextField, Stack, Button, Form } from '../';
import GradientColorHeader from '../../icoach/components/dialog/GradientColorHeader';
import useAuthUserApi from '../../apis/useAuthUserApi';
import { HOME_TABS_ARRIVE_URL } from '../../icoach/router/LandingPageRouter';
import { refIsRequiredError } from '../../util/utils';

const AuthPrivateRoute = (props) => {
    const { as: Comp } = props;
    const history = useHistory();
    const { postAuthAgentOwnerApi } = useAuthUserApi();
    const [open, setOpen] = React.useState(true);
    const [authValue, setAuthValue] = React.useState(null);
    const accountRef = useRef(null);
    const passwordRef = useRef(null);

    const handleOnclose = () => {
        history.replace(HOME_TABS_ARRIVE_URL);
    };

    const handleAuthLoginClick = () => {
        if (!refIsRequiredError(accountRef, passwordRef)) {
            const params = {
                username: accountRef.current.getResult(),
                password: passwordRef.current.getResult(),
            };
            doLoginAgentOwnerApi(params);
        }
    };

    const doLoginAgentOwnerApi = async (params) => {
        const resp = await postAuthAgentOwnerApi(params);
        if (resp) {
            setAuthValue(resp);
            setOpen(false);
        }
    };

    return (
        <React.Fragment>
            {authValue && !open && <Comp {...props} />}
            <Dialog
                open={open}
                onSubmit={handleAuthLoginClick}
                PaperProps={{
                    className: 'wx-90rem',
                    component: Form,
                }}
            >
                <GradientColorHeader onClose={handleOnclose}>需登入權限</GradientColorHeader>
                <DialogContent className="py-4 px-5">
                    <Stack direction="column" spacing="3">
                        <TextField
                            ref={accountRef}
                            direction="row"
                            label="帳號"
                            inputProps={{ autoComplete: 'off' }}
                            fullWidth
                            required
                        />
                        <TextField
                            ref={passwordRef}
                            direction="row"
                            label="密碼"
                            type="password"
                            inputProps={{ autoComplete: 'off' }}
                            fullWidth
                            required
                        />
                    </Stack>
                </DialogContent>
                <DialogActions className="px-5" position="end">
                    <Button variant="contained" type="submit">
                        登入
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default AuthPrivateRoute;
