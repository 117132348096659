import React from 'react';
import clsx from 'clsx';
import { DialogTitle } from '@mui/material';
import { IconButton } from '@common/components/';
import { Close as CloseIcon } from '@common/SvgIcon/';

const GradientColorHeader = (props) => {
    const { title: propsTitle = '請設定標題', onClose: propsOnClose, children } = props;

    const handleOnClose = (e) => {
        propsOnClose && propsOnClose(e);
    };
    return (
        <DialogTitle className="dialog-title-header-primary text-center">
            <div
                className={clsx(
                    'h5 text-gray-50 font-weight-bold',
                    propsOnClose && 'dialog-title-margin'
                )}
            >
                {children || propsTitle}
            </div>
            {propsOnClose && (
                <div className="dialog-title-header-close-icon">
                    <IconButton size="large" onClick={handleOnClose}>
                        <CloseIcon htmlColor="#fff" />
                    </IconButton>
                </div>
            )}
        </DialogTitle>
    );
};

export default GradientColorHeader;
