import React, { useState } from 'react';
import { GiftProvider, useGiftContext } from '@icoach/gift/components/GiftContext';
import GiftDispatchMetricsSearchBar from '@icoach/gift/GiftDispatchMetricsSearchBar';
import GiftDispatchMetricsTable from '@icoach/gift/GiftDispatchMetricsTable';
import { initBookmark } from '@icoach/gift/staticData';

const GiftDispatchMetricsPage = (props) => {
    const giftContext = useGiftContext();
    const [sourceData, setSourceData] = useState({});
    const [bookmark, setBookmark] = useState(initBookmark());

    const childContext = {
        ...giftContext,
        sourceData,
        setSourceData,
        bookmark,
        setBookmarkNum: (num) => setBookmark((prev) => ({ ...prev, pageIndex: num })),
    };

    return (
        <GiftProvider value={childContext}>
            <GiftDispatchMetricsSearchBar className={'mb-2'} />
            <GiftDispatchMetricsTable />
        </GiftProvider>
    );
};

export default GiftDispatchMetricsPage;
