import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { Box, Button, Card, Tab, TabPanel, Tabs } from '@common/components/';
import useOrderAccountBookApi from '@apis/useOrderAccountBookApi';
import { parseDate, isBefore, isSame, getCurrentStartDate, getCurrentEndDay, getFirstOfMonth, getLastOfMonth } from '@util/moment';
import { formatCurrencyFn, objectToQueryString } from '@util/utils';
import { initBookmark, paymentTypeTabs } from './staticData';
import { SettleCreditDialog } from './dialog';
import { PettyMoneyBox, SettleCashBox } from './components';
import { CreditItemTable, DebitItemTableByOrder } from './tables';
import { SettleCashProvider } from './context/SettleCashContext';
import { CounterBox } from '@icoach/members/components';
import MobileDatePickerBox from '@icoach/components/MobileDatePickerBox';
import { ACCOUNT_BOOK_TABS_SETTLE_CASH_PRICE_URL, ACCOUNT_BOOK_ORDERS_OVERVIEW_URL } from '@icoach/router/AccountBookRouter';
import { DateTimeStandard } from '../../components/pickers/enums/DateTimeStandard';

// 訂單類型分類
const OrderTypeBox = (props) => {
    const { data = [], currentIndex, onClickTypeCard } = props;

    return (
        <Box className={'order-type-box'}>
            {data.map(({ dataType, dataTypeText, totalPrice }, i) => (
                <CounterBox
                    key={dataType}
                    className={clsx('order-type-item', {
                        active: i === currentIndex,
                    })}
                    title={dataTypeText}
                    counter={formatCurrencyFn(totalPrice, { symbol: '$' })}
                    onClick={() => {
                        onClickTypeCard(i);
                    }}
                />
            ))}
        </Box>
    );
};

// 收入/退款 列表
const DebitTabContent = (props) => {
    const { paymentType, data = {} } = props;
    const { debitItems = [] } = data;
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleClickTypeCard = (index) => {
        setCurrentIndex(index);
    };

    if (isEmpty(debitItems)) return null;

    return (
        <React.Fragment>
            <OrderTypeBox data={debitItems} currentIndex={currentIndex} onClickTypeCard={handleClickTypeCard} />
            <DebitItemTableByOrder className={'theme-gray'} rowsData={debitItems[currentIndex]?.rows || []} paymentType={paymentType} />
        </React.Fragment>
    );
};

// 收支列表
const RevenueAndExpenditureTable = (props) => {
    const { data = {}, creditItems, creditPrice, debitItems, refundItems } = props;
    const { accountingDate, isSettled } = data;
    const [tabsIndex, setTabsIndex] = useState(1);
    const [isOpen, setOpenDialog] = useState(false);
    const today = parseDate();

    const handleChangeTab = (e, value) => setTabsIndex(value);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    return (
        <React.Fragment>
            <Card>
                <Box className="page-tabs tabs-bottom-line">
                    <Tabs value={tabsIndex} onChange={handleChangeTab}>
                        <Tab className={'py-3'} label={'收入'} value={1} />
                        <Tab className={'py-3'} label={'退費'} value={3} />
                        <Tab className={'py-3'} label={'費用'} value={2} />
                    </Tabs>
                    {!isSettled && (isSame(today, accountingDate) || isBefore(today, accountingDate)) && (
                        <Box className="btn-group mr-4">
                            <Button variant={'outlined'} onClick={handleOpenDialog}>
                                新增支出
                            </Button>
                        </Box>
                    )}
                </Box>
                <Card.CardContent className="pt-4 px-4 pb-3">
                    <TabPanel value={tabsIndex} index={1}>
                        <DebitTabContent data={{ debitItems }} paymentType={paymentTypeTabs.income} />
                    </TabPanel>
                    <TabPanel value={tabsIndex} index={3}>
                        <DebitTabContent data={{ debitItems: refundItems }} paymentType={paymentTypeTabs.refund} />
                    </TabPanel>
                    <TabPanel value={tabsIndex} index={2}>
                        <CreditItemTable className={'theme-gray'} rowsData={creditItems} total={creditPrice} />
                    </TabPanel>
                </Card.CardContent>
            </Card>
            <SettleCreditDialog open={isOpen} data={{ accountingDate }} onClose={handleCloseDialog} />
        </React.Fragment>
    );
};

const AccountBookSettleCashPage = (props) => {
    const { date } = props.match.params;
    const history = useHistory();
    const { getInitOrderAccountBookApi } = useOrderAccountBookApi();
    const [data, setData] = useState({});
    const { resource = {}, accountBookData = {} } = data;
    const { main = {}, creditItems, debitItems, refundItems, isSettled, abnormalOrderCount, abnormalOrderCountTotal } = accountBookData;
    const { fundPrice, fundEmployeeName, creditPrice } = main;

    const dailyAbnormalOrders = {
        status: 9,
        startDate: getCurrentStartDate(date),
        endDate: getCurrentEndDay(date),
        ...initBookmark(),
        name: '',
        orderSourceType: 1,
        receiverType: 99,
        orderType: 99,
    };
    const monthlyAbnormalOrders = {
        status: 9,
        startDate: getCurrentStartDate(getFirstOfMonth(date)),
        endDate: getCurrentEndDay(getLastOfMonth(date)),
        ...initBookmark(),
        name: '',
        orderSourceType: 1,
        receiverType: 99,
        orderType: 99,
    };

    const doInitOrderAccountBookApi = async (date) => {
        const res = await getInitOrderAccountBookApi(date);
        if (res) {
            setData(res);
        }
    };

    const handleChangeDate = (date) => {
        history.push(ACCOUNT_BOOK_TABS_SETTLE_CASH_PRICE_URL(parseDate(date, DateTimeStandard.DisplayDate)));
    };

    const handleAbnormalNumber = (num, searchConditions) => {
        if (parseInt(num, 10) > 0) {
            let url = ACCOUNT_BOOK_ORDERS_OVERVIEW_URL;
            if (!isEmpty(searchConditions)) url += objectToQueryString(searchConditions);
            history.push(url);
        }
    };

    useEffect(() => {
        if (date) {
            doInitOrderAccountBookApi(date);
        }
        // eslint-disable-next-line
    }, [date]);

    return (
        <SettleCashProvider
            value={{
                main,
                creditItems,
                debitItems,
                isSettled,
                resource,
                accountingDate: date,
                refresh: doInitOrderAccountBookApi,
            }}
        >
            <Box className="container main-container-spacing">
                <MobileDatePickerBox
                    className={'mt-1 mb-2 flex-y-flex-start'}
                    value={date}
                    // maxDate={today}
                    onChange={handleChangeDate}
                />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Card className={clsx('text-center px-4 py-3')}>
                            <Card.CardContent>
                                <Box className={'flex'}>
                                    <CounterBox
                                        onClick={() => {
                                            handleAbnormalNumber(abnormalOrderCount, dailyAbnormalOrders);
                                        }}
                                        className={clsx('order-type-item', 'text-center')}
                                        title={'當日累計訂單異常數'}
                                        counter={abnormalOrderCount}
                                    />
                                    <CounterBox
                                        onClick={() => {
                                            handleAbnormalNumber(abnormalOrderCountTotal, monthlyAbnormalOrders);
                                        }}
                                        className={clsx('order-type-item', 'text-center')}
                                        title={'當期累計訂單異常數'}
                                        counter={abnormalOrderCountTotal}
                                    />
                                </Box>
                            </Card.CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <PettyMoneyBox className={'mb-4'} data={{ fundPrice, fundEmployeeName, accountingDate: date }} isSettled={isSettled} />
                    </Grid>
                </Grid>
                <SettleCashBox
                    className={'mb-4'}
                    data={{
                        ...main,
                        accountingDate: date,
                        isSettled,
                    }}
                />
                <RevenueAndExpenditureTable
                    data={{ accountingDate: date, isSettled }}
                    debitItems={debitItems}
                    creditItems={creditItems}
                    refundItems={refundItems}
                    creditPrice={creditPrice}
                />
            </Box>
        </SettleCashProvider>
    );
};

export default AccountBookSettleCashPage;
