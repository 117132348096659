import React from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Tab, Tabs } from '@common/components/';
import {
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
} from '@icoach/router/routerPath';

const MembershipTransferOutSearchBarTabs = (props) => {
    const { moveoutType } = useParams();

    return (
        <Tabs className="pl-2" value={moveoutType} variant="scrollable" allowScrollButtonsMobile>
            <Tab
                className={'pt-3'}
                label={'未移出'}
                value={'unmoveout'}
                component={NavLink}
                to={DOCUMENTS_MEMBERSHIPTRANSFEROUT_UNMOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER}
            />
            <Tab
                className={'pt-3'}
                label={'已移出'}
                value={'moveout'}
                component={NavLink}
                to={DOCUMENTS_MEMBERSHIPTRANSFEROUT_MOVEOUT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER}
            />
        </Tabs>
    );
};

export default MembershipTransferOutSearchBarTabs;
