import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { initialDate, parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';
import { getErrorModuleStatus, refIsRequiredError } from '@util/utils';
import { Card, DateField } from '@common/components/';
import { Grid } from '@mui/material';
import { SelectWithMenuItems } from '@icoach/documents/cyclePurchase/sections/CyclePurchaseUtility';

const EditTopSection = forwardRef((props, ref) => {
    const { effectDate, data = {}, resource = {}, errorModule, onChangeEffectDate } = props;
    const { effectCreateDate = parseDate(), employeeID } = data;
    const { coachOptions: employeeOptions } = resource;
    const createDateRef = useRef(null);
    const dateRef = useRef(null);
    const employeeRef = useRef(null);

    const getResult = () => {
        const result = {};
        if (createDateRef.current && createDateRef.current.getResult)
            result.effectCreateDate = parseDate(createDateRef.current.getResult(), DateTimeStandard.DisplayUtcFull);
        if (dateRef.current && dateRef.current.getResult) result.effectDate = parseDate(dateRef.current.getResult(), DateTimeStandard.DisplayUtcFull);
        if (employeeRef.current && employeeRef.current.getResult) result.employeeID = employeeRef.current.getResult();

        return result;
    };

    const handleChangeEffectDate = (node, val) => {
        if (typeof onChangeEffectDate === 'function') onChangeEffectDate(val);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(dateRef, employeeRef),
            getEffectDate: () => dateRef.current && dateRef.current.getResult(),
        }),
        // eslint-disable-next-line
        [],
    );

    console.log(effectDate, 'effectDate');

    return (
        <React.Fragment>
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <DateField
                            key={initialDate(effectCreateDate)}
                            ref={createDateRef}
                            label="建單日期"
                            defaultValue={initialDate(effectCreateDate)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <DateField
                            key={`${initialDate(effectDate)}-${uuid()}`}
                            ref={dateRef}
                            label="變更生效日期"
                            defaultValue={initialDate(effectDate)}
                            onChange={handleChangeEffectDate}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={4} item>
                        <SelectWithMenuItems
                            refItem={employeeRef}
                            label="擔當"
                            defaultValue={employeeID}
                            options={employeeOptions}
                            {...getErrorModuleStatus(errorModule, 'employeeID')}
                            fullWidth
                            required
                        />
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
});

export default EditTopSection;
