import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const POST_PRINT_API = `/api/print/`;

const usePrintApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    const postPrintApi = useCallback(
        async (uri) => {
            const resp = await Ajax.post(POST_PRINT_API, { uri });
            if (resp && resp.isError) {
                enqueueSnackbar(resp.message, { variant: 'error' });
            }
        },
        // eslint-disable-next-line
        []
    );

    return { postPrintApi };
};

export default usePrintApi;
