import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';
import { serialize } from '@util/utils';

const GET_MEMBER_FILTER_OPTIONS_API = `/api/member/filter/options/`;

// 會員管理快速查詢
const GET_MEMBER_FILTER_BIRTHDAY_API = `/api/member/filter/birthday/`;
const GET_MEMBER_FILTER_WORKOUT_FREQUENCY_API = `/api/member/filter/workout-frequency/`;
const GET_MEMBER_FILTER_HUNDRED_T_SHIRT_API = `/api/member/filter/hundred-t-shirt/`;
const GET_MEMBER_FILTER_RENEW_API = `/api/member/filter/renew/`;
const GET_MEMBER_FILTER_LEAVE_API = `/api/member/filter/leave/`;
const GET_MEMBER_FILTER_INVALID_API = `/api/member/filter/invalid/`;
const GET_MEMBER_FILTER_NEW_API = `/api/member/filter/new/`;
const GET_MEMBER_FILTER_HEALTHCARE_API = `/api/member/filter/healthcare/`;

// 營收管理快入查詢
const GET_MEMBER_FILTER_OVERDRAFT_API = `/api/member/filter/overdraft/`;
const GET_MEMBER_FILTER_MONTH_FEE_API = `/api/member/filter/month-fee/`;
// 租貴缺少

// 量身快速查詢
const GET_MEMBER_FILTER_MEASURE_RESULT_EXCEL_API = `/api/member/filter/measure-result-excel/`;
const GET_MEMBER_FILTER_MEASURED_API = `/api/member/filter/measured/`;
const GET_MEMBER_FILTER_MEASURE_NOT_YET_API = `/api/member/filter/measure-not-yet/`;
const GET_MEMBER_FILTER_MEASURE_RESULT_API = `/api/member/filter/measure-result/`;

const useMemberFilterApi = (args = {}) => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得快速名單的選單
    const getMemberFilterOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 依日期範圍取得會員生日名單
    const getMemberBirthDayDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_BIRTHDAY_API, params);
            if (!resp) {
                enqueueSnackbar('讀取生日名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 依照日期範圍和運動次數範圍取得次數名單
    const getWorkOutFrequencyDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_WORKOUT_FREQUENCY_API, params);
            if (!resp) {
                enqueueSnackbar('讀取運動次數範圍失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 百T名單篩選
    const getHundredTShirtDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_HUNDRED_T_SHIRT_API, params);
            if (!resp) {
                enqueueSnackbar('讀取百T名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得量身成果 EXCEL 匯出
    const getMeasureResultExcelApi = useCallback(
        async (params) => {
            const resp = await window.open(`${GET_MEMBER_FILTER_MEASURE_RESULT_EXCEL_API}?${serialize(params)}`, '_blank');
            if (resp) {
                enqueueSnackbar('已開啟下載視窗', { variant: 'success' });
            }
            // return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 依照指定月份取得已經量身的清單
    const getMeasuredFilterDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_MEASURED_API, params);
            if (!resp) {
                enqueueSnackbar('讀取已量身名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 依照指定月份取得未量身的清單
    const getNotYetMeasureFilterDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_MEASURE_NOT_YET_API, params);
            if (!resp) {
                enqueueSnackbar('讀取尚未量身名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得續約名單
    const getRenewFilterDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_RENEW_API, params);
            if (!resp) {
                enqueueSnackbar('讀取續約名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得請假名單
    const getLeaveFilterDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_LEAVE_API, params);
            if (!resp) {
                enqueueSnackbar('讀取請假名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得無效名單
    const getInvalidFilterDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_INVALID_API, params);
            if (!resp) {
                enqueueSnackbar('讀取無效名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得欠費名單
    const getOverdraftFilterDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_OVERDRAFT_API, params);
            if (!resp) {
                enqueueSnackbar('讀取欠費名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得月費收費名單
    const getMonthFeeFilterDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_MONTH_FEE_API, params);
            if (!resp) {
                enqueueSnackbar('讀取月費收費名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得新會員名單
    const getNewMemberFilterDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_NEW_API, params);
            if (!resp) {
                enqueueSnackbar('讀取新會員名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得健康產品名單
    const postHealthcareFilterDataApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(GET_MEMBER_FILTER_HEALTHCARE_API, params);
            if (!resp) {
                enqueueSnackbar('讀取新會員名料失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const getMeasuredResultApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBER_FILTER_MEASURE_RESULT_API, params);
            if (!resp) {
                enqueueSnackbar('讀取量身名單失敗，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const allApi = {
        getMemberFilterOptionsApi,
        getMemberBirthDayDataApi,
        getWorkOutFrequencyDataApi,
        getHundredTShirtDataApi,
        getMeasureResultExcelApi,
        getMeasuredFilterDataApi,
        getMeasuredResultApi,
        getNotYetMeasureFilterDataApi,
        getRenewFilterDataApi,
        getLeaveFilterDataApi,
        getInvalidFilterDataApi,
        getOverdraftFilterDataApi,
        getMonthFeeFilterDataApi,
        getNewMemberFilterDataApi,
        postHealthcareFilterDataApi,
    };

    const multiApi = useCallback(
        (num) => {
            let newArgs = null;
            try {
                newArgs = Object.entries(args);
                newArgs = newArgs.reduce((acc, cur) => {
                    const newObj = { ...acc };
                    newObj[cur[0]] = allApi[cur[1]];
                    return newObj;
                }, {});
            } catch (error) {
                console.error(error);
            }
            return newArgs ? newArgs[num] : null;
        },
        // eslint-disable-next-line
        [args, allApi],
    );

    return { ...allApi, multiApi };
};

export default useMemberFilterApi;
