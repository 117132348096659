import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { Box, Alert } from '@common/components/';
import useMembershipApi from '@apis/useMembershipApi';
import { membershipItemReasonsFilterTypeMap } from '@icoach/documents/membership/staticData';
import useMessageDialog from '@util/hook/useMessageDialog';
import { MembershipRecordBox } from '@icoach/members/components';
import { parseDate } from '@util/moment';

export const MembershipRecordUI = (props) => {
    const { className, memberID, membershipID, data, refresh } = props;
    const { deleteMemberMembershipManualGiftItemApi } = useMembershipApi();
    const setMessageDialog = useMessageDialog();
    const doDeleteMemberMembershipManualGiftItemApi = async (memberID, membershipID, itemID, onClose) => {
        let res = await deleteMemberMembershipManualGiftItemApi(memberID, membershipID, itemID);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const handleDeleteRecord = (itemID) => {
        setMessageDialog({
            open: true,
            title: '確認刪除會籍紀錄',
            msg: '送出後將無法恢復，請問確定要刪除嗎?',
            onAsyncConfirm: (_, onClose) => doDeleteMemberMembershipManualGiftItemApi(memberID, membershipID, itemID, onClose),
        });
    };
    return (
        <Box className={clsx('membership-record-content', className)}>
            {!isEmpty(data) ? (
                <ul className="time-line membership-record">
                    {data.map((item) => {
                        return (
                            <li key={`${item.itemID}-${item.rangeType}-${parseDate(item.startDate)}`} className="time-line-item">
                                <MembershipRecordBox sourceData={item} onDelete={() => handleDeleteRecord(item.itemID)} />
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <Alert color="info" severity="info" variant="filled" className="my-3">
                    尚無參考資料
                </Alert>
            )}
        </Box>
    );
};

const MemberDetailMembershipContentRecord = (props) => {
    const { className, memberID, membershipID, refresh } = props;
    const { getMembershipItemReasonsApi } = useMembershipApi();
    const [data, setData] = useState({});

    const doMembershipItemReasonsApi = async (memberID, membershipID) => {
        const params = { filter: membershipItemReasonsFilterTypeMap.all };
        let res = await getMembershipItemReasonsApi(memberID, membershipID, params);
        if (res) {
            setData(res);
        }
    };

    const handleSubmitSuccessRefresh = () => {
        doMembershipItemReasonsApi(memberID, membershipID);
        refresh && refresh();
    };

    useEffect(
        () => {
            if (memberID && membershipID) doMembershipItemReasonsApi(memberID, membershipID);
        },
        // eslint-disable-next-line
        [memberID, membershipID]
    );

    return <MembershipRecordUI className={className} memberID={memberID} membershipID={membershipID} data={data} refresh={handleSubmitSuccessRefresh} />;
};

export default MemberDetailMembershipContentRecord;
