import React, { useState } from 'react';
import clsx from 'clsx';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import { EditRecordDialog, RemoveContractRecordDialog } from '@icoach/nonMember/dialog/';
import { ContactSMSRecordCard } from '@icoach/components/';
import { Typography, IconButton, Card, Divider, Stack } from '@common/components/';
import { Trash as TrashIcon, Edit as EditIcon } from '@common/SvgIcon/';
import { useCustomerContactRecordApi } from '@apis/useContactRecordApi';
import { parseDate } from '@util/moment';
import { matchOptionsText } from '@util/utils';

const ContactRecordCardBox = (props) => {
    const {
        contactDate,
        contactResultTypeText,
        coachName,
        title,
        appointmentID,
        contactID,
        notes,
        customerID,
    } = props;

    const [isOpen, setOpen] = useState(null);
    const { refresh } = useNonMember();
    const {
        putCustomerContactRecordDataApi,
        deleteCustomerContactRecordDataApi,
    } = useCustomerContactRecordApi();

    const removeApi = async (cusID, contID) => {
        const resp = await deleteCustomerContactRecordDataApi(cusID, contID);
        if (resp) {
            setOpen(null);
            refresh && refresh.current && refresh.current();
        }
    };

    const editApi = async (cusID, contID, params) => {
        const resp = await putCustomerContactRecordDataApi(params, cusID, contID);
        if (resp) {
            setOpen(null);
            refresh && refresh.current && refresh.current();
        }
    };

    return (
        <Card isOverflowHidden className={clsx('contact-record-card')}>
            <Card.CardContent>
                <Stack justifyContent="space-between" className="pt-2 px-2">
                    <span className="nonmember-info-contact-markbook">
                        <Typography variant="h5" className="nonmember-info-contact-date">
                            {parseDate(contactDate, 'YYYY-MM-DD')}
                        </Typography>
                    </span>
                    <div>
                        <IconButton onClick={() => setOpen('edit')}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => setOpen('remove')}>
                            <TrashIcon />
                        </IconButton>
                    </div>
                </Stack>
                <Stack justifyContent="space-between" className="px-3 py-3">
                    <div className="overflow-hidden">
                        <span className="nonmember-info-contact-label">狀態</span>
                        <span className="nonmember-info-contact-label-text">
                            {contactResultTypeText}
                        </span>
                    </div>
                    <div className="overflow-hidden">
                        <span className="nonmember-info-contact-label">擔當</span>
                        <span className="nonmember-info-contact-label-text">{coachName}</span>
                    </div>
                </Stack>
            </Card.CardContent>
            <Divider />
            <Card.CardContent>
                <div className="px-3">
                    <Typography variant="h6" className="title py-2">
                        {title}
                        &nbsp;&nbsp;{String(appointmentID) !== '0' && `預約編號: ${appointmentID}`}
                    </Typography>
                    <Typography className="content pb-4">{notes}</Typography>
                </div>
            </Card.CardContent>
            <RemoveContractRecordDialog
                open={isOpen === 'remove'}
                setOpen={() => setOpen(null)}
                doAction={() => removeApi(customerID, contactID)}
            />
            <EditRecordDialog
                open={isOpen === 'edit'}
                setOpen={() => setOpen(null)}
                doAction={(params) => editApi(customerID, contactID, params)}
                title={title}
                notes={notes}
            />
        </Card>
    );
};

const CustomerContactRecord = React.forwardRef((props, red) => {
    const { sourceData, customerID, options } = props;
    const len = sourceData.length;

    return (
        <React.Fragment>
            <Typography>聯繫紀錄({len})</Typography>
            {sourceData.map(({ ...item }, _index) => {
                return item.isSMS ? (
                    <ContactSMSRecordCard
                        {...item}
                        employeeName={matchOptionsText(options, item.employeeID, '')}
                    />
                ) : (
                    <ContactRecordCardBox
                        {...item}
                        key={`record_card_${_index}`}
                        customerID={customerID}
                    />
                );
            })}
        </React.Fragment>
    );
});

export default CustomerContactRecord;
