import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';

const GET_REPORT_PJ_API = `/api/reports/pj/`;
const GET_REPORT_CA_API = `/api/reports/ca/`;
const GET_REPORT_KPI_API = `/api/reports/kpi/`;
const POST_REPORT_HC_GOAL_DATE_API = (date) => `/api/reports/kpi-healthcare-target-setting/${date}/`;
const POST_REPORT_GOAL_DATE_API = (date) => `/api/reports/kpi-target-setting/${date}/`;
const GET_REPORT_MEMBERSHIP_API = `/api/reports/membership/`;
const GET_REPORT_SERVICE_API = `/api/reports/service/`;
const GET_REPORT_GUEST_PRODUCTION_API = `/api/reports/guest-production/`;
const GET_REPORT_SALES_API = `/api/reports/not-membership/`;
const GET_REPORT_EMPLOYEE_API = `/api/reports/employee/`;

const useReportApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得 PJ 摘要
    const getReportPJDataApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_REPORT_PJ_API, params);
            if (!resp) {
                enqueueSnackbar('讀取PJ資料失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得 CA 摘要
    const getReportCADataApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_REPORT_CA_API, params);
            if (!resp) {
                enqueueSnackbar('讀取CA資料失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得營運報表目標進度摘要
    const getReportKpiApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_REPORT_KPI_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存營運報表-目標設定
    const postReportKpiGoalApi = useCallback(
        async (date, parameters) => {
            // console.log(parameters, 'dddk');
            let resp = await Ajax.post(POST_REPORT_GOAL_DATE_API(date), parameters);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 儲存營運報表-目標設定
    const postHcReportKpiGoalApi = useCallback(
        async (date, parameters) => {
            let resp = await Ajax.post(POST_REPORT_HC_GOAL_DATE_API(date), parameters);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得營運報表會籍摘要
    const getReportMembershipApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_REPORT_MEMBERSHIP_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得營運報表服務摘要
    const getReportServiceApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_REPORT_SERVICE_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得營運報表顧客製造摘要
    const getReportGuestProductionApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_REPORT_GUEST_PRODUCTION_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得營運報表非會籍摘要
    const getReportSalesApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_REPORT_SALES_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得營運報表教練摘要
    const getReportEmployeeApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_REPORT_EMPLOYEE_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getReportPJDataApi,
        getReportCADataApi,
        getReportKpiApi,
        postReportKpiGoalApi,
        postHcReportKpiGoalApi,
        getReportMembershipApi,
        getReportServiceApi,
        getReportGuestProductionApi,
        getReportSalesApi,
        getReportEmployeeApi,
    };
};

export default useReportApi;
