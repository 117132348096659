import React from 'react';

const Form = React.forwardRef((props, ref) => {
    const { onSubmit: propsOnSubmit, className: propsClassName, children, name } = props;

    const handleOnSubmit = (e) => {
        e.preventDefault();
        propsOnSubmit && propsOnSubmit(e);
    };

    const defaultProps = {
        
        onSubmit: handleOnSubmit,
        className: propsClassName,
        ref,
        name,
    };

    return <form {...defaultProps} autoComplete="off">{children}</form>;
});

export default Form;
