import React from 'react';
import { Card } from '@common/components/';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { displayDateText } from '@util/moment';

const SuspensionSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { suspendStartDate, suspendEndDate, suspendReasonText, suspendNote } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="暫停" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={6} item>
                        <ReadTextField label={'暫停期間'}>
                            {displayDateText(suspendStartDate, suspendEndDate, {
                                format: 'YYYY/MM',
                            })}
                        </ReadTextField>
                    </Grid>
                    <Grid xs={6} item>
                        <ReadTextField label={'暫停原因'}>{suspendReasonText}</ReadTextField>
                    </Grid>
                    <Grid xs={12} item>
                        <ReadTextField label={'暫停原因補充說明'}>{suspendNote}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

export default SuspensionSectionByRead;
