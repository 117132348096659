import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';
import { GET_CYCLE_PURCHASE_PRODUCTS_API } from '@apis/useProductApi';
import { GET_CYCLE_PURCHASE_MEMBER_ORDER_API, GET_CYCLE_PURCHASE_OPTIONS_API } from '@apis/useCyclePurchaseApi';
import useShowLoading from '@util/hook/useShowLoading';

const GET_CYCLE_PURCHASE_EFFECT_ORDER_API = (cycleOrderID, effectID) => `/api/cycle-purchase/effection/${cycleOrderID}/${effectID}/`;
const POST_CYCLE_PURCHASE_EFFECT_ORDER_API = `/api/cycle-purchase/effection/`;
const PUT_CYCLE_PURCHASE_EFFECT_ORDER_API = (effectID) => `/api/cycle-purchase/effection/${effectID}/`;
const DELETE_CYCLE_PURCHASE_EFFECT_ORDER_API = (effectID, employeeID) => `/api/cycle-purchase/effection/${effectID}/${employeeID}/`;
const POST_CYCLE_PURCHASE_EFFECT_RANGE_API = `/api/cycle-purchase/effection/range/`;

const useCyclePurchaseEffectApi = () => {
    const Ajax = useAjax();
    const isLoading = useShowLoading();
    const { enqueueSnackbar } = useSnackbar();

    // 建立訂購單時
    // 取得循環訂單商品清單
    // 取得循環訂單的下拉式選單
    // 取得訂購單內容
    const getInitCyclePurchaseEffectOrderByCreateApi = async (memberID, cycleOrderID) => {
        let result = {};
        isLoading(true);
        const products = await Ajax.get(GET_CYCLE_PURCHASE_PRODUCTS_API);
        const resource = await Ajax.get(GET_CYCLE_PURCHASE_OPTIONS_API);
        const order = await Ajax.get(GET_CYCLE_PURCHASE_MEMBER_ORDER_API(memberID, cycleOrderID));
        if (products) result = Object.assign(result, { products });
        if (resource) result = Object.assign(result, { resource });
        if (order) result = Object.assign(result, { order });
        isLoading(false);
        return result;
    };

    // 更新變更單時
    // 取得循環訂單商品清單
    // 取得循環訂單的下拉式選單
    // 取得變更單內容
    const getInitCyclePurchaseEffectOrderByUpdateApi = async (cycleOrderID, effectID) => {
        let result = {};
        isLoading(true);
        const products = await Ajax.get(GET_CYCLE_PURCHASE_PRODUCTS_API);
        const resource = await Ajax.get(GET_CYCLE_PURCHASE_OPTIONS_API);
        const order = await Ajax.get(GET_CYCLE_PURCHASE_EFFECT_ORDER_API(cycleOrderID, effectID));
        if (products) result = Object.assign(result, { products });
        if (resource) result = Object.assign(result, { resource });
        if (order) result = Object.assign(result, { order });
        isLoading(false);
        return result;
    };

    // 取得循環變更單內容
    const getCyclePurchaseEffectOrderApi = async (cycleOrderID, effectID) => {
        const resp = await Ajax.get(GET_CYCLE_PURCHASE_EFFECT_ORDER_API(cycleOrderID, effectID));
        return resp;
    };

    // 新增變更單
    const postCyclePurchaseEffectOrderApi = async (parameters) => {
        const resp = await Ajax.post(POST_CYCLE_PURCHASE_EFFECT_ORDER_API, parameters);
        return resp;
    };

    // 更新變更單
    const putCyclePurchaseEffectOrderApi = async (parameters, EffectID) => {
        const resp = await Ajax.put(PUT_CYCLE_PURCHASE_EFFECT_ORDER_API(EffectID), parameters);
        return resp;
    };

    // 刪除變更單(須為未生效)
    const deleteCyclePurchaseEffectOrderApi = async (EffectID, employeeID) => {
        const resp = await Ajax.delete(DELETE_CYCLE_PURCHASE_EFFECT_ORDER_API(EffectID, employeeID));
        if (resp) {
            enqueueSnackbar('刪除成功', { variant: 'success' });
        }
        return resp;
    };

    const postCyclePurchaseEffectRangeApi = async (parameters) => {
        const resp = await Ajax.post(POST_CYCLE_PURCHASE_EFFECT_RANGE_API, parameters);
        return resp;
    };

    return {
        getInitCyclePurchaseEffectOrderByCreateApi,
        getInitCyclePurchaseEffectOrderByUpdateApi,
        getCyclePurchaseEffectOrderApi,
        postCyclePurchaseEffectOrderApi,
        putCyclePurchaseEffectOrderApi,
        deleteCyclePurchaseEffectOrderApi,
        postCyclePurchaseEffectRangeApi,
    };
};

export default useCyclePurchaseEffectApi;
