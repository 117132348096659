import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchBar from './InBodyHistorySearchBar';
import InBodyHistoryTable from '@icoach/inbody/history/InBodyHistoryTable';
import InBodyVisitorsDialog from '@icoach/inbody/history/InBodyVisitorsDialog';
import { Typography } from '@common/components/';
import InBodyContext from '@icoach/inbody/components/InBodyContext';
import InbodyUploadExcel from '@icoach/inbody/components/InbodyUploadExcel';

const InBodyHistoryPage = React.forwardRef((props, ref) => {
    const { id: referenceID } = useParams();
    const [sourceData, setSourceData] = useState(null);
    const [isShowContent, setShowContent] = useState(false);

    const inbodyValue = {
        sourceData: sourceData,
        setSourceData: (params) => {
            setSourceData(params);
            setShowContent(true);
        },
        referenceID: referenceID,
    };

    return (
        <InBodyContext.Provider value={inbodyValue}>
            <div className="container main-container-spacing">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        量身紀錄
                    </Typography>
                    <div className="btn-group">
                        <InbodyUploadExcel />
                        <InBodyVisitorsDialog />
                    </div>
                </header>
                <SearchBar className={'mb-4'} referenceID={referenceID} />
                {sourceData !== null && isShowContent && <InBodyHistoryTable />}
            </div>
        </InBodyContext.Provider>
    );
});

export default InBodyHistoryPage;
