import React from 'react';
import useForkRef from '@util/hook/useForkRef';
import SvgIcon from '../SvgIcon';

const ShippingFast = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M19.688,9.982A9.688,9.688,0,0,1,3.92,17.543a.937.937,0,0,1-.072-1.391l.44-.44a.939.939,0,0,1,1.246-.078A7.188,7.188,0,1,0,5.075,4.763L7.058,6.746a.625.625,0,0,1-.442,1.067H.938a.625.625,0,0,1-.625-.625V1.509a.625.625,0,0,1,1.067-.442L3.308,3A9.687,9.687,0,0,1,19.688,9.982Zm-7.067,3.078L13,12.566a.937.937,0,0,0-.164-1.316l-1.59-1.237V5.938A.937.937,0,0,0,10.313,5H9.688a.937.937,0,0,0-.937.938v5.3l2.555,1.987a.938.938,0,0,0,1.316-.164Z"
                transform="translate(2.188 2.188)"
            />
        </SvgIcon>
    );
});

export default ShippingFast;
