import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const FileInvoiceDollar = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M14.727,4.1,10.9.273A.937.937,0,0,0,10.238,0H10V5h5V4.762A.935.935,0,0,0,14.727,4.1ZM8.75,5.313V0H.938A.935.935,0,0,0,0,.938V19.063A.935.935,0,0,0,.938,20H14.063A.935.935,0,0,0,15,19.063V6.25H9.688A.94.94,0,0,1,8.75,5.313ZM2.5,2.813A.312.312,0,0,1,2.813,2.5H5.938a.312.312,0,0,1,.313.313v.625a.312.312,0,0,1-.312.313H2.813A.312.312,0,0,1,2.5,3.438Zm0,3.125V5.313A.312.312,0,0,1,2.813,5H5.938a.312.312,0,0,1,.313.313v.625a.312.312,0,0,1-.312.313H2.813A.312.312,0,0,1,2.5,5.938ZM8.125,16.245v.942a.312.312,0,0,1-.312.313H7.188a.312.312,0,0,1-.312-.312v-.949A2.237,2.237,0,0,1,5.65,15.8a.313.313,0,0,1-.022-.474l.459-.438a.321.321,0,0,1,.4-.029.94.94,0,0,0,.5.145h1.1a.491.491,0,0,0,.461-.515.51.51,0,0,0-.343-.5L6.441,13.46a1.768,1.768,0,0,1-1.234-1.695A1.74,1.74,0,0,1,6.875,10V9.063a.312.312,0,0,1,.313-.312h.625a.312.312,0,0,1,.313.313v.949a2.234,2.234,0,0,1,1.225.443.313.313,0,0,1,.022.474l-.459.438a.321.321,0,0,1-.4.029.937.937,0,0,0-.5-.145h-1.1a.491.491,0,0,0-.461.515.51.51,0,0,0,.343.5l1.758.527a1.768,1.768,0,0,1,1.234,1.695A1.739,1.739,0,0,1,8.125,16.245Z"
                transform="translate(5 2)"
            />
        </SvgIcon>
    );
});

export default FileInvoiceDollar;
