import React from 'react';
import clsx from 'clsx';
import Tablenization from '../../components/Tablenization';
import { TableLabel } from '../components';
import { transposedWeeklyData } from '../staticData';
import { isEmpty } from 'lodash';
import { floatToPercentage, toFixed } from '../../../util/utils';

const getRowsData = (data, cumulative, thData) => {
    let rowsData = [];
    let rowsLabel = [
        {
            cellKey: 'title',
            headerLabel: '項目',
            width: 26,
            formatCell: (title, { tip, isStar }) => {
                return <TableLabel title={title} tip={tip} isStar={isStar} />;
            },
        },
        {
            cellKey: 'goal',
            headerLabel: '目標',
            align: 'center',
            width: 11,
        },
        {
            cellKey: 'cumulative',
            headerLabel: '最新數字',
            align: 'center',
            className: 'total-col',
            minWidth: 82,
        },
    ];

    data = transposedWeeklyData(data);

    if (!isEmpty(data)) {
        rowsData = thData.map(({ key, formatCell, ...other }) => {
            let weeklyData = data[key];
            let rowData = {
                cellKey: '',
                cumulative: cumulative[key],
                ...other,
                ...weeklyData,
            };

            if (formatCell) {
                rowData.cellKey = 'cumulative';
                rowData.cumulative = formatCell(cumulative[key], rowData);
                for (let week in weeklyData) {
                    rowData.cellKey = week;
                    rowData[week] = formatCell(weeklyData[week], rowData);
                }
            }

            return rowData;
        });

        rowsLabel = [...rowsLabel, ...data.week];
    }

    return { rowsLabel, rowsData };
};

// 表格標題與目標資訊
// title: 標題 string
// tip: 說明 string
// isStar: 閃亮之星 boolean 選填
// goal: 目標 string
const thData = [
    {
        key: 'totalWo',
        title: '總WO次數',
        tip: '當月累計WO總次數',
        goal: '>10 x TAM',
        isStar: true,
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'everyOneWeeklyWo',
        title: '每人每週WO數',
        tip: '每週WO總數/每週TAM',
        goal: '>2.5',
        isStar: true,
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'measureAmount',
        title: '量身數',
        tip: '當週量身總數',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'measureRate',
        title: '量身率',
        tip: '量身數/TAM',
        goal: '≥70%TAM',
        isStar: true,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'catA',
        title: 'A會員數',
        tip: '每月來12次以上的有效會員數',
        goal: '45%',
        isStar: true,
        formatCell: (val, { cellKey }) => {
            if (cellKey === 'cumulative') return `${floatToPercentage(val)}%`;
            return val;
        },
    },
    {
        key: 'catB',
        title: 'B會員數',
        tip: '每月來8-11次的有效會員數',
        goal: '20%',
        isStar: true,
        formatCell: (val, { cellKey }) => {
            if (cellKey === 'cumulative') return `${floatToPercentage(val)}%`;
            return val;
        },
    },
    {
        key: 'catC',
        title: 'C會員數',
        tip: '每月來4-7次的有效會員數',
        goal: '15%',
        formatCell: (val, { cellKey }) => {
            if (cellKey === 'cumulative') return `${floatToPercentage(val)}%`;
            return val;
        },
    },
    {
        key: 'catD',
        title: 'D會員數',
        tip: '每月來3-1次的有效會員數',
        goal: '10%',
        formatCell: (val, { cellKey }) => {
            if (cellKey === 'cumulative') return `${floatToPercentage(val)}%`;
            return val;
        },
    },
    {
        key: 'catE',
        title: 'E會員數',
        tip: '每月來0次的有效會員數',
        goal: '10%',
        formatCell: (val, { cellKey }) => {
            if (cellKey === 'cumulative') return `${floatToPercentage(val)}%`;
            return val;
        },
    },
];

const ServiceMonthlyTable = (props) => {
    const { className, rowsData: data = [], cumulative } = props;
    const { rowsLabel, rowsData } = getRowsData(data, cumulative, thData);

    return (
        <Tablenization cardProps={{ className: clsx('monthly-table', className) }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel} />
            <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={rowsData} />
        </Tablenization>
    );
};

export default ServiceMonthlyTable;
