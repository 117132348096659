import Mock from 'mockjs';
import { successApi } from './respondData';

export const withMockAppointmentContactRecordApiProvider = () => {

    Mock.mock(/^\/api\/appointments\/[0-9]+\/contact-records$/, 'post', () => {
        let sourceData = { ...successApi };
        sourceData.result = true;
        return sourceData;
    });
    
    Mock.setup({ timeout: '200-800' });
};
