import Mock from 'mockjs';
import { successApi } from './respondData';

export const withMockOrdersProvider = () => {
    // 取得入會申請書內容api/members/type
    Mock.mock(/^\/api\/orders\/checkout\/options$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = {
            "coachOptions": [
                {
                    "text": "林宗敬(小C)",
                    "value": "11",
                    "disabled": false
                },
                {
                    "text": "Allen(小A)",
                    "value": "12",
                    "disabled": false
                },
                {
                    "text": "Kevin(小K)",
                    "value": "13",
                    "disabled": false
                },
                {
                    "text": "Sabrina(小K)",
                    "value": "14",
                    "disabled": false
                }
            ],
            "receiverOptions": [
                {
                    "text": "無",
                    "value": "0",
                    "disabled": false
                },
                {
                    "text": "會員",
                    "value": "1",
                    "disabled": false
                },
                {
                    "text": "非會員",
                    "value": "2",
                    "disabled": false
                },
                {
                    "text": "跨店會員",
                    "value": "3",
                    "disabled": false
                },
                {
                    "text": "教練",
                    "value": "4",
                    "disabled": false
                },
                {
                    "text": "加盟主",
                    "value": "5",
                    "disabled": false
                }
            ]};

        return sourceData;
    });
    Mock.setup({ timeout: '200-800' });
};
