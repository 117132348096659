import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Tab, Tabs, Card, Button } from '@common/components/';
import MemberDetailCycleOrderContentOverview from '@icoach/members/memberDetail/MemberDetailCycleOrderContentOverview';
import CycleOrderContentBillingRecord from '@icoach/members/memberDetail/MemberDetailCycleOrderContentBillingRecord';
import { DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER } from '@icoach/router/routerPath';
import { serialize } from '@util/utils';

const BtnGroup = (props) => {
    const { memberID } = props;
    return (
        <div className="btn-group no-gutter">
            <Button variant={'outlined'} color="secondary" href={`${DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER}?${serialize({ memberID })}`}>
                新增訂購單
            </Button>
        </div>
    );
};

const MemberDetailCycleOrderContent = (props) => {
    const { className, memberID, setTab } = props;
    const tabs = props.match.params.tabs;
    const [tabsIndex, setTabsIndex] = useState('overview');

    const handleTabChange = (e, value) => {
        setTabsIndex(value);
    };

    useEffect(() => {
        if (tabs) {
            setTab(tabs);
        }
        // eslint-disable-next-line
    }, [tabs]);

    return (
        <Card className={clsx(className, 'tabs-content-layout')}>
            <header className={'page-header'}>
                <Tabs value={tabsIndex} onChange={handleTabChange}>
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'info',
                        })}
                        label={'循環訂單'}
                        value={'overview'}
                    />
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'basic',
                        })}
                        label={'收費明細'}
                        value={'billing'}
                    />
                </Tabs>
                <BtnGroup memberID={memberID} />
            </header>
            <Card.CardContent className="pt-4 px-4 pb-3">
                {tabsIndex === 'overview' && <MemberDetailCycleOrderContentOverview memberID={memberID} />}
                {tabsIndex === 'billing' && <CycleOrderContentBillingRecord memberID={memberID} />}
            </Card.CardContent>
        </Card>
    );
};

export default MemberDetailCycleOrderContent;
