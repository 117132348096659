import React, { useEffect, useRef, useState } from 'react';
import { Card, Typography, Box, Button } from '../../components';
import { CreditCardInfoSectionByRead, QuitSectionByRead, RenterSectionByRead, RentMainSectionByRead, TopSectionByRead } from './components/DocumentComponents';
import useCabinetRentalApi from '../../apis/useCabinetRentalApi';
import { RENT_CABINET_DOCUMENT_CREATE_URL, RENT_CABINET_DOCUMENT_ID_URL } from '../router/MembersRouter';
import RentCabinetDialogBox from '@icoach/rentCabinet/dialog/RentCabinetDialogBox';
import { cabinetPaymentCycleMap } from '@icoach/rentCabinet/staticData';
import { objectToQueryString } from '@util/utils';

const RentCabinetDocumentButtons = (props) => {
    const { data, dialogRef, refresh } = props;
    const { isEditable, isQuitDeletable, canRenew, cabinetRentalID } = data || {};

    return (
        <Box className={'btn-group'}>
            {isEditable && (
                <Button variant={'outlined'} href={RENT_CABINET_DOCUMENT_ID_URL(cabinetRentalID)}>
                    編輯租約
                </Button>
            )}
            {canRenew && (
                <Button variant={'outlined'} href={RENT_CABINET_DOCUMENT_CREATE_URL + objectToQueryString({ renewCabinetRentalID: cabinetRentalID })}>
                    續約
                </Button>
            )}
            {isEditable && (
                <Button
                    variant={'outlined'}
                    onClick={() => dialogRef.current && dialogRef.current.openQuitRentCabinetDialog({ cabinetRentalID: cabinetRentalID }, refresh)}
                >
                    退租申請
                </Button>
            )}
            {isQuitDeletable && (
                <Button
                    variant={'outlined'}
                    onClick={() => dialogRef.current && dialogRef.current.openDeleteQuitRentCabinetDialog({ cabinetRentalID: cabinetRentalID }, refresh)}
                >
                    刪除退租申請
                </Button>
            )}
        </Box>
    );
};

const RentCabinetDocumentPageByRead = (props) => {
    const { rentCabinetID } = props.match.params;
    const { getRentCabinetApi } = useCabinetRentalApi();
    const [data, setData] = useState({});
    const { paymentCycle } = data;
    const dialogRef = useRef();

    const doRentCabinetApi = async (rentCabinetID) => {
        let res = await getRentCabinetApi(rentCabinetID);
        if (res) {
            setData(res);
        }
    };

    const refresh = () => doRentCabinetApi(rentCabinetID);

    useEffect(() => {
        if (rentCabinetID) {
            doRentCabinetApi(rentCabinetID);
        }
        // eslint-disable-next-line
    }, [rentCabinetID]);

    return (
        <Box className="container main-container-spacing rent-cabinet-page">
            <Box component={'header'} className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    置物櫃承租申請書
                </Typography>
                <RentCabinetDocumentButtons data={data} dialogRef={dialogRef} refresh={refresh} />
            </Box>
            <TopSectionByRead className={'mb-4'} data={data} />
            <Card className={'order-info-card mb-4'}>
                <RenterSectionByRead data={data} />
                {String(data?.cabinetRentalStatus) === '2' && <QuitSectionByRead titleClassName={'bg-error-light'} data={data} />}
                <RentMainSectionByRead data={data} />
                {String(paymentCycle) === cabinetPaymentCycleMap.monthly && <CreditCardInfoSectionByRead data={data} />}
            </Card>
            <RentCabinetDialogBox ref={dialogRef} />
        </Box>
    );
};

export default RentCabinetDocumentPageByRead;
