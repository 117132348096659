import React, { useEffect, useRef, useState } from 'react';
import { BillingRecordTable } from './table';
import { ListInfo } from '../../accountBook/components';
import { Stack } from '../../../components';
import { initBookmark } from '../staticData';
import useCabinetRentalApi from '../../../apis/useCabinetRentalApi';

const MemberDetailCycleOrderContent = (props) => {
    const { memberID } = props;
    const { postRentCabinetOrdersApi } = useCabinetRentalApi();
    const [bookmark, setBookmark] = useState(initBookmark());
    const [data, setData] = useState([]);
    const {
        list = [],
        totalCount,
        totalPage,
        pageIndex = bookmark.pageIndex,
        pageSize = bookmark.pageSize,
    } = data;
    const preload = useRef(true);

    const doRentCabinetOrdersApi = async (memberID, params) => {
        let res;
        preload.current = false;
        res = await postRentCabinetOrdersApi(memberID, params);
        if (res) {
            setData(res);
        }
        preload.current = true;
    };

    const handlePageChange = (e, num) => {
        setBookmark((prev) => ({
            ...prev,
            pageIndex: num,
        }));
    };

    useEffect(() => {
        if (preload.current) {
            doRentCabinetOrdersApi(memberID, bookmark);
        }
        // eslint-disable-next-line
    }, [bookmark]);

    useEffect(() => {
        if (memberID) {
            if (preload.current) {
                doRentCabinetOrdersApi(memberID, bookmark);
            }
        }
        // eslint-disable-next-line
    }, [memberID]);
    return (
        <React.Fragment>
            <Stack className={'mb-2'} alignItems={'center'} justifyContent={'end'}>
                <ListInfo
                    className={'ml-auto'}
                    pageIndex={pageIndex}
                    padeSize={pageSize}
                    totalCount={totalCount}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                />
            </Stack>
            <BillingRecordTable
                className={'theme-gray'}
                rowsData={list}
                page={pageIndex}
                count={totalPage}
                handlePageChange={handlePageChange}
            />
        </React.Fragment>
    );
};

export default MemberDetailCycleOrderContent;
