import React, { useEffect, useImperativeHandle, useState, useRef } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Card, List, ListItemText, Box, Button } from '@common/components/';
import { RemitBonusCartTable, RemitCartTable } from '@icoach/accountBook/tables';
import { ShoppingMenuDialog } from '@icoach/accountBook/dialog/';
import { CartCardProvider } from '@icoach/accountBook/context/CartCardContext';
import { formatCurrencyFn } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';
import useToggleDialog from '@util/hook/useToggleDialog';
import { dialogType } from '@icoach/accountBook/staticData';
import { updateCartData } from '@icoach/accountBook/components/CheckoutOrderCart';

// 取得費用類品項資料
const getFeeItemData = (item) => {
    const { revenueType, assignPrice, handingFee, notes } = item;
    return {
        revenueType,
        assignPrice,
        handingFee,
        notes,
    };
};
// 取得紅利類品項資料
const getBonusItemData = (item) => {
    const { bonusText, month, assignPrice, notes } = item;
    return {
        bonusText,
        month,
        assignPrice,
        notes,
    };
};

const CartActions = (props) => {
    const { resource = {}, getReceiver } = props;
    const { forClubOptions = [], forHeadQuarterOptions = [] } = resource;
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const shoppingMenuMap = new Map([
        [5, forClubOptions],
        [6, forHeadQuarterOptions],
    ]);

    return (
        <Box className={'cart-actions'}>
            <Button variant={'outlined'} onClick={() => handleOpenDialog(dialogType.shoppingMenu)}>
                新增匯款項目
            </Button>
            <ShoppingMenuDialog open={openTarget === dialogType.shoppingMenu} onClose={handleCloseDialog} menuData={shoppingMenuMap.get(getReceiver())} />
        </Box>
    );
};

const CartContent = (props) => {
    const { className, isBonus, cartTotal, getReceiver } = props;
    const cartTableMap = new Map([
        [5, RemitCartTable],
        [6, RemitBonusCartTable],
    ]);
    let receiver = getReceiver();
    let CartTable = cartTableMap.get(receiver);
    if (isBonus !== null) CartTable = isBonus ? cartTableMap.get(6) : cartTableMap.get(5);
    return (
        <Box className={clsx('cart-content', className)}>
            {CartTable && <CartTable />}
            <Grid spacing={3} container>
                <Grid className={'ml-auto'} xs={4} item>
                    <List>
                        <ListItemText className={'cart-info total'} secondary={'總計'} primary={formatCurrencyFn(cartTotal)} />
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
};

const CheckoutRemitCart = React.forwardRef((props, ref) => {
    const { className, resource, getReceiver } = props;
    const setMessageDialog = useMessageDialog();
    const [cartTotal, setCartTotal] = useState(0);
    const [cartData, setCartData] = useState([]);
    const [isBonus, setBonus] = useState(null);
    const cartDataRef = useRef([]);

    const filterCartItem = (cart, cartItem) => {
        return cart.filter((item) => {
            if (cartItem.hasOwnProperty('name')) {
                return item.name !== cartItem.name;
            } else {
                return item.name !== cartItem.text;
            }
        });
    };
    // 是否與購物車相同的類別的品項
    const validateSameItemCategory = (item) => {
        return item.isBonus === isBonus;
    };
    // 更新暫存車(與購物車一致)
    const updateCacheCart = () => {
        cartDataRef.current = cartData;
    };

    /**
     * 加入暫存車中
     * @param {{text: string , value: string, isBonus: boolean}} cartItem
     * @return {boolean} 是否有加入
     */
    const addCacheCart = (cartItem) => {
        let item;
        if (isBonus === null) {
            setBonus(cartItem.isBonus);
        } else if (isBonus !== null && !validateSameItemCategory(cartItem)) {
            // 檢查購物車是否混入紅利商品，如果有無法加入購物車
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '紅利商品無法與其他商品一起進行匯款。',
            });

            return false;
        }
        // 費用類型
        item = {
            name: cartItem.text,
            revenueType: cartItem.value,
        };
        cartDataRef.current = [...cartDataRef.current, item];
        return true;
    };

    // 從暫存車移除品項(單品)
    const deleteCacheCart = (cartItem) => {
        cartDataRef.current = filterCartItem(cartDataRef.current, cartItem);

        if (cartDataRef.current.length < 1) {
            setBonus(null);
        }
    };
    // 清除暫存車
    const clearCacheCart = () => {
        cartDataRef.current = [];
        setBonus(null);
    };
    // 暫存車轉入至購物車
    const cacheCartToCart = () => {
        setCartData(cartDataRef.current);
    };

    // 計算購物車總金額
    const calculateCartTotal = () => {
        return cartData.reduce((total, currentObj) => {
            let subtotal = 0;
            // 指定價錢(店舖指定價錢)
            if (currentObj.hasOwnProperty('assignPrice')) {
                subtotal = subtotal + parseInt(currentObj.assignPrice);
            }
            // 手續費
            if (currentObj.hasOwnProperty('handingFee')) {
                subtotal = subtotal + parseInt(currentObj.handingFee);
            }
            return total + subtotal;
        }, 0);
    };
    // 更新購物車
    const updateCart = (cartRef) => {
        const cart = updateCartData(cartData, cartRef);
        setCartData(cart);
    };
    // 從購物車移除品項(單品)
    const deleteCart = (cartItem, cartRef) => {
        let cart = updateCartData(cartData, cartRef);
        cart = filterCartItem(cart, cartItem);

        setCartData(cart);
    };
    // 清除購物車
    const clearCart = () => {
        setCartData([]);
    };

    useEffect(
        () => {
            setCartTotal(calculateCartTotal);
        },
        // eslint-disable-next-line
        [cartData]
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let items = [];
                let bonusItems = [];

                cartData.forEach((item) => {
                    let _item = item;
                    if (isBonus) {
                        _item = getBonusItemData(_item);
                        bonusItems.push(_item);
                    } else {
                        _item = getFeeItemData(_item);
                        items.push(_item);
                    }
                });

                return {
                    items,
                    bonusItems,
                };
            },
            clearCart: () => clearCart(),
            getTotal: () => calculateCartTotal(),
        }),
        // eslint-disable-next-line
        [cartData, isBonus]
    );

    return (
        <CartCardProvider
            value={{
                cartData,
                setCartData,
                cartDataRef,
                deleteCart,
                clearCart,
                updateCart,
                addCacheCart,
                deleteCacheCart,
                clearCacheCart,
                cacheCartToCart,
                updateCacheCart,
            }}
        >
            <Card className={clsx('cart', className)}>
                <Card.CardContent>
                    <CartContent isBonus={isBonus} cartTotal={cartTotal} getReceiver={getReceiver} />
                    <CartActions resource={resource} getReceiver={getReceiver} />
                </Card.CardContent>
            </Card>
        </CartCardProvider>
    );
});

export default CheckoutRemitCart;
