import React, { useImperativeHandle, useRef } from 'react';
import { Stack, DateField } from '../../../components';
import { refIsRequiredError } from '../../../util/utils';
import { parseDate, initialDate } from '../../../util/moment';

/**
 * 當日
 */
const OneDayBox = React.forwardRef((props, ref) => {
    const dateRef = useRef(null);
    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => [dateRef.current.getResult('YYYY-MM-DD')],
                isError: () => refIsRequiredError(dateRef),
            };
        },
        // eslint-disable-next-line
        []
    );
    return (
        <Stack alignItems="end" spacing={2}>
            <DateField
                ref={dateRef}
                label="簽到日期和時間"
                maxDate={parseDate(new Date())}
                defaultValue={initialDate(new Date())}
                required
            />
        </Stack>
    );
});

export default OneDayBox;
