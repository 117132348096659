import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Card, Typography } from '@common/components/';
import { ReportTableHeader, SearchBar } from '@icoach/dataAnalysis/components/';
import { MembershipDailyTable, MembershipMonthlyTable } from '@icoach/dataAnalysis/tables/';
import { CounterBox } from '@icoach/members/components';
import useReportApi from '@apis/useReportApi';
import { tableTargetMap } from './staticData';
import { DataAnalysisProvider } from '@icoach/dataAnalysis/components/DataAnalysisContext';

// 會籍指標摘要
const MembershipSummaryContent = (props) => {
    const { className, data = {} } = props;
    const { newSales = 0, leave = 0, drop = 0, moveIn = 0, moveOut = 0, increase = 0, notYetRenew = 0, expiredRenew = 0 } = data;
    return (
        <Card className={className}>
            <Card.CardContent className={'px-4 pt-3 pb-4'}>
                <Typography className={'font-weight-bold mb-2'} variant={'h5'}>
                    會籍異動
                </Typography>
                <Grid spacing={3} columns={5} container>
                    <Grid xs={1} item>
                        <CounterBox className={'text-left'} title={'新銷售'} tip={'以入會申請書申請日期來統計'} counter={newSales} />
                    </Grid>
                    <Grid xs={1} item>
                        <CounterBox className={'text-left'} title={'補續約'} tip={'會籍到期後辦理續約'} counter={expiredRenew} />
                    </Grid>
                    <Grid xs={1} item>
                        <CounterBox className={'text-left'} title={'請假'} counter={leave} />
                    </Grid>
                    <Grid xs={1} item>
                        <CounterBox className={'text-left'} title={'未續約'} tip={'會籍到期前尚未續約或辦理退會'} counter={notYetRenew} />
                    </Grid>
                    <Grid xs={1} item>
                        <CounterBox className={'text-left'} title={'退會'} tip={'有寫退會申請書'} counter={drop} />
                    </Grid>
                    <Grid xs={1} item>
                        <CounterBox className={'text-left'} title={'移入'} counter={moveIn} />
                    </Grid>
                    <Grid xs={1} item>
                        <CounterBox className={'text-left'} title={'移出'} counter={moveOut} />
                    </Grid>
                    <Grid xs={1} item>
                        <CounterBox className={'text-left'} title={'純增'} counter={increase} tip={'新銷售+(移入+補續約)-(退會+移出+未續約)'} />
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
};

const MembershipReportTableContent = (props) => {
    const { data = {} } = props;
    const { daily = [], weekly = [], cumulative, ...current } = data;

    const [tableTarget, setTable] = useState(tableTargetMap.monthly);
    const handleToggleTable = (target) => {
        setTable(target);
    };

    return (
        <Card className={'mb-4'}>
            <Card.CardContent className={'px-4 pt-3 pb-4'}>
                <ReportTableHeader className={'mb-2'} tableTarget={tableTarget} onToggleTable={handleToggleTable} />
                {tableTarget === tableTargetMap.daily && <MembershipDailyTable rowsData={daily} cumulative={current} />}
                {tableTarget === tableTargetMap.monthly && <MembershipMonthlyTable rowsData={weekly} cumulative={cumulative} />}
            </Card.CardContent>
        </Card>
    );
};

const DataAnalysisContentMembership = (props) => {
    const { defaultDate, dialogRef } = props;
    const { getReportMembershipApi } = useReportApi();
    const [data, setData] = useState({});
    const searchRef = useRef();

    const doReportMembershipApi = async (params) => {
        let res = await getReportMembershipApi(params);
        if (res) {
            setData(res);
        }
    };

    const getParams = () => {
        let result = {};
        if (searchRef.current && searchRef.current.getResult) {
            result = Object.assign(result, searchRef.current.getResult());
        }

        return result;
    };

    const handleSubmit = () => {
        let isError = searchRef.current && searchRef.current.isError();
        if (!isError) {
            let params = getParams();
            doReportMembershipApi(params);
        }
    };

    const childContext = {
        data,
        defaultDate,
        dialogRef,
    };

    useEffect(() => {
        handleSubmit();
        // eslint-disable-next-line
    }, []);

    return (
        <DataAnalysisProvider value={childContext}>
            <SearchBar ref={searchRef} className={'mb-4'} onSubmit={handleSubmit} defaultDate={props.defaultDate} />
            <MembershipSummaryContent className={'mb-4'} data={data} />
            <MembershipReportTableContent data={data} />
        </DataAnalysisProvider>
    );
};

export default DataAnalysisContentMembership;
