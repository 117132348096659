import React, { useRef, useState } from 'react';
import EmployeesInformationsClockInSearchBar from './EmployeesInformationsClockInSearchBar';
import EmployeesInformationsClockInTable from '../tables/EmployeesInformationsClockInTable';
import EmployeesContext from '../compoments/EmployeesContext';
import { Card } from '../../../components';

const EmployeesInformationsClockIn = React.forwardRef((props, ref) => {
    const [sourceData, setSourceData] = useState(null);
    const searchParamsRef = useRef(null);
    const refreshRef = useRef(null);

    const childContext = {
        sourceData: sourceData,
        setSourceData: setSourceData,
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
        searchParams: searchParamsRef,
        setSearchParamsRef: (el) => (searchParamsRef.current = el),
    };

    return (
        <EmployeesContext.Provider value={childContext}>
            <Card.CardContent className={'px-4  py-3'}>
                <EmployeesInformationsClockInSearchBar />
            </Card.CardContent>
            <Card.CardContent className={'px-4 pb-3'}>
                <EmployeesInformationsClockInTable />
            </Card.CardContent>
        </EmployeesContext.Provider>
    );
});

export default EmployeesInformationsClockIn;
