import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
    Button,
    SelectField,
    MenuItem,
    IconButton,
    DateIntervalField,
    Typography,
} from '../../../components';
import { ErrorCircle } from '../../../components/icons/SvgIcon/';

const GiveawayItem = (props) => {
    const { sourceData, options, onRemove: onRemoveProps } = props;

    const handleRemoveClick = () => {
        onRemoveProps && onRemoveProps(sourceData.id);
    };

    return (
        <div className="giveaway-item">
            <SelectField displayEmpty>
                <MenuItem>
                    <em>請選擇</em>
                </MenuItem>
                {Array.isArray(options) &&
                    options.map(({ text, value, disabled }) => (
                        <MenuItem key={value} value={text} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
            </SelectField>
            <span>：</span>
            <DateIntervalField direction="row" fullWidth />
            <IconButton onClick={handleRemoveClick}>
                <ErrorCircle />
            </IconButton>
        </div>
    );
};

const GiveawayCard = (props) => {
    const { source = [], options, cannotCreate = false } = props;
    const [itemData, setItemData] = useState(source);

    const handleAddItem = () => {
        const makeItem = () => ({
            id: uuid(),
            optionsID: 0,
            startDate: '',
            endDate: '',
        });
        setItemData((prevState) => [...prevState, makeItem()]);
    };
    const handleRemove = (id) => {
        setItemData((prevState) => prevState.filter((target) => target.id !== id));
    };

    return (
        <React.Fragment>
            {Array.isArray(itemData) &&
                itemData.map((target) => (
                    <GiveawayItem
                        key={target.id}
                        sourceData={target}
                        options={options}
                        onRemove={handleRemove}
                    />
                ))}
            {cannotCreate && itemData.length < 1 && (
                <Typography className={'font-color-3'} style={{ marginTop: '2px' }}>
                    無相關資料
                </Typography>
            )}
            {!cannotCreate && (
                <Button variant="outlined" color="secondary" onClick={handleAddItem}>
                    新增
                </Button>
            )}
        </React.Fragment>
    );
};

export default GiveawayCard;
