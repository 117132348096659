import React, { useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Card, RadioField, TextField, Typography } from '@common/components/';
import { formatCurrencyFn, refIsRequiredError } from '@util/utils';
import useForkRef from '@util/hook/useForkRef';

export const MultiplePaymentAmountPaidListGroup = React.forwardRef((props, ref) => {
    const { className, cashPaymentAmountProps = {}, cardPaymentAmountProps = {}, total, readOnly, isRefund = false } = props;
    const { inputProps: cashInputProps = { placeholder: '請輸入金額', min: 0, required: true }, ...cashOther } = cashPaymentAmountProps;
    const { inputProps: cardInputProps = { placeholder: '請輸入金額', min: 0, required: true }, ...cardOther } = cardPaymentAmountProps;
    const cashPaymentAmountRef = useRef(null);
    const cardPaymentAmountRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    cashPaymentAmount: cashPaymentAmountRef.current.getResult(),
                    cardPaymentAmount: cardPaymentAmountRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(cashPaymentAmountRef, cardPaymentAmountRef),
        }),
        // eslint-disable-next-line
        []
    );
    
    return (
        <PaidListGroup className={className}>
            <PaidListItem label="合計" className={'font-weight-bold'}>
                <strong className="paid-list-cell text-primary">{formatCurrencyFn(total)}</strong>
            </PaidListItem>
            <PaidListItem label={isRefund ? '現金還款金額': '現金收款金額'}>
                <TextField maskType={'MONEY'} ref={cashPaymentAmountRef} inputProps={cashInputProps} readOnly={readOnly} {...cashOther} />
            </PaidListItem>
            <PaidListItem label={isRefund ? '信用卡還款金額': '信用卡收款金額'}>
                <TextField maskType={'MONEY'} ref={cardPaymentAmountRef} inputProps={cardInputProps} readOnly={readOnly} {...cardOther} />
            </PaidListItem>
        </PaidListGroup>
    );
});

export const PaymentAmountPaidListGroup = React.forwardRef((props, ref) => {
    const { className, cashPaymentAmountProps, cardPaymentAmountProps, total, readOnly } = props;
    const paymentAmountProps = cashPaymentAmountProps || cardPaymentAmountProps;
    const {
        inputProps: paymentAmountInputProps = {
            placeholder: '請輸入金額',
            min: 0,
            required: true,
        },
        ...other
    } = paymentAmountProps;
    const [paymentType, setPaymentType] = useState('cash');
    const paymentAmountRef = useRef();

    const handleChangePaymentType = (e) => {
        setPaymentType(e.target.value);
    };

    const handleCurrentPaymentType = (paymentType) => {
        let current;
        if (paymentType === 'cash') {
            current = 'cashPaymentAmount';
        } else if (paymentType === 'card') {
            current = 'cardPaymentAmount';
        }
        return current;
    };

    useImperativeHandle(ref, () => ({
        getResult: () => {
            let current = handleCurrentPaymentType(paymentType);
            return {
                [current]: paymentAmountRef.current.getResult(),
            };
        },
        isError: () => refIsRequiredError(paymentAmountRef),
    }));
    return (
        <PaidListGroup className={className}>
            <PaidListItem label="合計">
                <strong className="paid-list-cell text-primary">{formatCurrencyFn(total)}</strong>
            </PaidListItem>
            <PaidListItem label="收款方式">
                <RadioField value={paymentType} onChange={handleChangePaymentType} readOnly={readOnly} required row>
                    <RadioField.Radio name="paymentType" value="cash" label="現金" />
                    <RadioField.Radio name="paymentType" value="card" label="信用卡" />
                </RadioField>
            </PaidListItem>
            <PaidListItem label="收款金額">
                <TextField maskType={'MONEY'} ref={paymentAmountRef} inputProps={paymentAmountInputProps} readOnly={readOnly} {...other} />
            </PaidListItem>
        </PaidListGroup>
    );
});

export const TotalPaidListGroup = (props) => {
    const { className, total, children } = props;
    return (
        <PaidListGroup className={className}>
            <PaidListItem label="合計" className={'font-weight-bold'}>
                <strong className="paid-list-cell text-primary">{formatCurrencyFn(total)}</strong>
            </PaidListItem>
            {children}
        </PaidListGroup>
    );
};

const BalancePaidListGroup = (props) => {
    const { className, balance } = props;
    let label = '未收金額';
    if (balance < 0) {
        label = '收款金額已超過應收金額，無法進行結帳。';
    }
    return (
        <PaidListGroup className={className}>
            <PaidListItem label={label} className="balance">
                <strong className="paid-list-cell text-error">{formatCurrencyFn(balance)}</strong>
            </PaidListItem>
        </PaidListGroup>
    );
};

const PaidListItem = (props) => {
    const { label, children, className, required } = props;
    const hasChildren = React.Children.count(children) > 0;

    return (
        <React.Fragment>
            <li {...props} className={clsx('paid-list-item', className)}>
                {label && (
                    <span className="paid-list-cell">
                        {label}
                        {required && (
                            <span className="required">
                                *<span className="text">必填</span>
                            </span>
                        )}
                    </span>
                )}
                {hasChildren && React.cloneElement(children, { required: children.props.required || required })}
            </li>
        </React.Fragment>
    );
};
const PaidListGroup = (props) => {
    const { className, children } = props;

    return <ul className={clsx('paid-list', className)}>{children}</ul>;
};

const PaidList = React.forwardRef((props, ref) => {
    const {
        className,
        title,
        children,
        isMultiplePayment = true,
        cashPaymentAmountProps,
        cardPaymentAmountProps,
        total,
        isShowBalance = false,
        balance,
        readOnly = false,
        ...other
    } = props;
    const paymentAmountRef = useRef();
    const combinedRef = useForkRef(ref, paymentAmountRef);

    return (
        <Card {...other} className={clsx('paid-list-card', className)}>
            <Card.CardContent className="px-4 py-3">
                {title && (
                    <Typography className="mb-2 mt-0 font-weight-bold" variant="h5" component={'h6'}>
                        {title}
                    </Typography>
                )}
                <ul className="paid-list pt-0">{children}</ul>
                <ul className="paid-list">
                    {isMultiplePayment ? (
                        <MultiplePaymentAmountPaidListGroup
                            ref={combinedRef}
                            total={total}
                            cashPaymentAmountProps={cashPaymentAmountProps}
                            cardPaymentAmountProps={cardPaymentAmountProps}
                            readOnly={readOnly}
                        />
                    ) : (
                        <PaymentAmountPaidListGroup
                            ref={combinedRef}
                            total={total}
                            cashPaymentAmountProps={cashPaymentAmountProps}
                            cardPaymentAmountProps={cardPaymentAmountProps}
                            readOnly={readOnly}
                        />
                    )}
                </ul>
                {isShowBalance && <BalancePaidListGroup balance={balance} />}
            </Card.CardContent>
        </Card>
    );
});

export const PaidListNew = (props) => {
    const { className, title, children, other } = props;

    return (
        <Card {...other} className={clsx('paid-list-card', className)}>
            <Card.CardContent className="px-4 py-3">
                {title && (
                    <Typography className={'mb-0 mt-0 font-weight-bold'} variant={'h5'} component={'h6'}>
                        {title}
                    </Typography>
                )}
                {children}
            </Card.CardContent>
        </Card>
    );
};

PaidList.PaidListItem = PaidListItem;
PaidList.PaidListGroup = PaidListGroup;

export default PaidList;
