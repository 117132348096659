import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { ListInfo } from '../components';
import { DebitItemTable } from '../tables';
import { initBookmark, paymentTypeTabs } from '../staticData';
import { CounterBox } from '../../members/components';
import { isEmpty } from 'lodash';
import { Box, Card, Stack, Tab, Tabs, Typography } from '@common/components/';
import useOrderStatisticsApi from '@apis/useOrderStatisticsApi';
import { formatCurrencyFn } from '@util/utils';
import { parseDate } from '@util/moment';

const OrdersDialog = (props) => {
    const { className, open, date, filterType, isOrder = false, isRemit = false, onClose } = props;
    const { getOrderStatisticsFilterItemsApi, getOrderStatisticsOptionsApi } = useOrderStatisticsApi();
    const [bookmark, setBookmark] = useState(initBookmark());
    const [tableData, setTableData] = useState({});
    const {
        list = [],
        itemTotalList = [],
        totalCount,
        pageIndex = bookmark.pageIndex,
        pageSize = bookmark.pageSize,
        totalPage,
        title = '',
        totalPrice = 0,
        orderBy = 1,
    } = tableData;
    const [resource, setResource] = useState({});
    const { orderByOptions } = resource;
    const sortRef = useRef();
    const [tabsIndex, setTabsIndex] = useState(paymentTypeTabs.income);

    const preloadRef = useRef(false);

    const getParams = (sortValue) => {
        let params = {
            date: parseDate(date),
            filterType,
            index: bookmark.pageIndex,
            size: bookmark.pageSize,
            statisticsType: tabsIndex,
        };
        if (sortRef && sortRef.current && sortRef.current.getResult) {
            Object.assign(params, {
                orderBy: sortValue || sortRef.current.getResult() || 1,
            });
        }

        return params;
    };
    const doOrderStatisticsOptionsApi = async () => {
        let res = await getOrderStatisticsOptionsApi();
        if (res) {
            setResource(res);
        }
    };
    const doOrderStatisticsFilterItemsApi = async (sortValue) => {
        let params, res;
        preloadRef.current = false;
        params = getParams(sortValue);
        res = await getOrderStatisticsFilterItemsApi(params);
        if (res) {
            setTableData(res);
            preloadRef.current = true;
        }
    };
    const handlePageChange = (e, num) => {
        setBookmark((prev) => ({
            ...prev,
            pageIndex: num,
        }));
    };
    const handleTabChange = (_, value) => {
        setTabsIndex(value);
        handlePageChange(null, 1);
    };

    useEffect(() => {
        if (preloadRef.current) {
            doOrderStatisticsFilterItemsApi();
        }
        // eslint-disable-next-line
    }, [bookmark.pageIndex]);

    useEffect(() => {
        if (!open) {
            setTabsIndex(isOrder ? paymentTypeTabs.income : paymentTypeTabs.incomeByRemit);
            return false;
        }
        preloadRef.current = true;
        doOrderStatisticsOptionsApi();
        doOrderStatisticsFilterItemsApi();
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        if (preloadRef.current) {
            doOrderStatisticsFilterItemsApi();
        }
        // eslint-disable-next-line
    }, [tabsIndex]);

    if (!open) return null;

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} open={open} fullScreen>
            <GradientColorHeader onClose={onClose}>收入清單</GradientColorHeader>
            <DialogContent className={'bg-light'}>
                <Box className="container main-container-spacing">
                    <Box component={'header'} className={'page-header'}>
                        <Typography className={'page-header-title font-weight-bold'} variant="h4">
                            {`${title}, 總計:${formatCurrencyFn(totalPrice, {
                                symbol: '$',
                            })}`}
                        </Typography>
                    </Box>
                    <Card>
                        <Card.CardContent className={'pt-2 px-4 pb-5'}>
                            {!isEmpty(itemTotalList) && (
                                <Grid className={'mb-2'} spacing={3} container>
                                    {itemTotalList.map((item) => {
                                        return (
                                            <Grid key={item.orderType} xs={2} item>
                                                <CounterBox
                                                    className={'text-left'}
                                                    title={item.orderTypeText}
                                                    counter={formatCurrencyFn(item.totalPrice, {
                                                        symbol: '$',
                                                    })}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            )}
                            <Stack className={'mb-2'} alignItems={'center'} justifyContent={'space-between'}>
                                <Tabs value={tabsIndex} variant="scrollable" scrollButtons="auto" onChange={handleTabChange}>
                                    {isOrder && <Tab className={'py-3'} label={'收入'} value={paymentTypeTabs.income} />}
                                    {isOrder && <Tab className={'py-3'} label={'退費'} value={paymentTypeTabs.refund} />}
                                    {isRemit && <Tab className={'py-3'} label={'匯款收入'} value={paymentTypeTabs.incomeByRemit} />}
                                    {isRemit && <Tab className={'py-3'} label={'匯款支出'} value={paymentTypeTabs.refundByRemit} />}
                                    {isRemit && <Tab className={'py-3'} label={'匯款退費'} value={paymentTypeTabs.returnByRemit} />}
                                </Tabs>
                                <ListInfo
                                    ref={sortRef}
                                    pageSize={pageSize}
                                    pageIndex={pageIndex}
                                    totalCount={totalCount}
                                    sortDefaultValue={orderBy}
                                    sortOptions={orderByOptions}
                                    sortOnChange={doOrderStatisticsFilterItemsApi}
                                    isCurrentPageFirstIndex
                                    isCurrentPageLastIndex
                                    isSort
                                />
                            </Stack>
                            <DebitItemTable
                                className={'theme-gray'}
                                paymentType={tabsIndex}
                                rowsData={list}
                                page={pageIndex}
                                count={totalPage}
                                handlePageChange={handlePageChange}
                            />
                        </Card.CardContent>
                    </Card>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default OrdersDialog;
