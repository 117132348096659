import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Box } from '@common/components/';
import { PaymentCard } from '@icoach/components/';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { MultiplePaymentDialog } from '@icoach/members/memberDetail/dialog';
import useMessageDialog from '@util/hook/useMessageDialog';

const MemberDetailBasicInfoContentPayment = (props) => {
    const { memberID } = props;
    const setMessageDialog = useMessageDialog();
    const { setRefresh } = useMemberDetailContext();
    const { getMemberProfilePaymentsApi } = useMemberProfileApi();
    const [sourceData, setSourceData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState({
        isOpen: false,
        current: null,
        title: '',
    });
    const { isOpen, current, title = '' } = dialogOpen;

    const paymentCardProperty = (targetData) => {
        const { paymentType, creditCardNo, cardExpiryDate, cardRelation, creditCardType, bankAccount, bankName, bankCode } = targetData;
        let paymentInfo, otherInfo, otherInfo2, imgType;
        let title = targetData.title;
        let isNull = false; // 尚未設定

        if (paymentType === 1) {
            paymentInfo = creditCardNo;
            otherInfo = cardExpiryDate;
            imgType = `${paymentType}-${creditCardType}`;
            if (cardRelation) {
                otherInfo2 = `${cardRelation}持有`;
            }
        } else {
            paymentInfo = bankAccount;
            otherInfo = `${bankName} ${bankCode}`;
            imgType = paymentType;
        }

        if (!paymentInfo) {
            isNull = true;
        }

        return {
            title,
            isNull,
            paymentInfo,
            otherInfo,
            otherInfo2,
            paymentType,
            imgType,
        };
    };

    const doMemberProfilePaymentsApi = async (memberID) => {
        let resp = await getMemberProfilePaymentsApi(memberID);
        if (resp) {
            setSourceData(resp);
        }
    };

    const handleOpenDialog = (index, title) => {
        setDialogOpen({
            isOpen: true,
            current: index,
            title,
        });
    };

    const handleCloseDialog = () => {
        setDialogOpen({
            isOpen: false,
            current: null,
            title: '',
        });
    };

    const handleEditPayment = (_index, targetData) => {
        const { sourceType, title = '' } = targetData;
        if (String(sourceType) === '4') {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '請透過健康產品變更單申請變更資料，謝謝',
            });
        } else {
            handleOpenDialog(_index, `${title}付款資訊`);
        }
    };

    useEffect(
        () => {
            if (memberID) {
                setRefresh(doMemberProfilePaymentsApi);
                doMemberProfilePaymentsApi(memberID);
            }
        },
        // eslint-disable-next-line
        [memberID],
    );

    return (
        <Box className="mb-4">
            <Grid container spacing={3}>
                {Array.isArray(sourceData) &&
                    sourceData.map((target, _index) => {
                        return (
                            <Grid key={target.memberPaymentID} item xs={6}>
                                <PaymentCard {...paymentCardProperty(target)} onEdit={() => handleEditPayment(_index, target)} />
                            </Grid>
                        );
                    })}
            </Grid>
            <MultiplePaymentDialog open={isOpen} memberID={memberID} onClose={handleCloseDialog} sourceData={sourceData[current]} title={title} />
        </Box>
    );
};

export default MemberDetailBasicInfoContentPayment;
