import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { sum, isEmpty, cloneDeep } from 'lodash';
import { Grid } from '@mui/material';
import { useMembershipContext } from '@icoach/documents/membership/create/MembershipContext';
import { Alert, CheckboxField, TextField } from '@common/components/';
import { ReadTextField, SignBox } from '@icoach/components/';
import { PaidList } from '@icoach/documents/components/';
import { refIsRequiredError } from '@util/utils';
import { displayDateText } from '@util/moment';
import { isNumber } from '@util/math';
import { getCheckboxValue, setCheckboxValue } from '@icoach/documents/tools';

const MembershipCreateContentFee = React.forwardRef((props, ref) => {
    const { className } = props;
    const { data: sourceData, errorModule, isReadOnly, getErrorModuleStatus, handleChangeRangeFee } = useMembershipContext();
    const { contractVersion, contractMain = {}, contractFee = {}, isImmediatelyIssuing = true } = sourceData;
    const { startDate, endDate, reviewStartDate, reviewEndDate } = contractMain;
    const { joinFee, firstMonthFee, nextMonthFee, cardPaymentAmount, cashPaymentAmount, isConfirm, signImg } = contractFee;
    const [total, setTotal] = useState({
        fee: 0,
        payment: 0,
    });
    const [fieldKey, setFieldKey] = useState(uuid());
    const contractFeeRef = useRef({});
    const paymentRef = useRef();
    const immediatelyIssuingRef = useRef();

    const getResult = () => {
        let result = {
            contractFee: {},
        };
        let contractFeeObj = contractFeeRef.current;

        if (contractFeeObj) {
            for (let key in contractFeeObj) {
                const target = contractFeeObj[key];
                if (target && target.getResult) {
                    let val = target.getResult();

                    if (!isEmpty(val) && Array.isArray(val)) {
                        val = getCheckboxValue(val, { isMultiple: false });
                    }

                    result.contractFee[key] = val;
                }
            }
        }

        if (paymentRef.current && paymentRef.current.getResult) {
            result.contractFee = Object.assign(result.contractFee, paymentRef.current.getResult());
        }

        if (immediatelyIssuingRef.current && immediatelyIssuingRef.current.getResult) {
            let val = immediatelyIssuingRef.current.getResult();
            result = Object.assign(result, {
                isImmediatelyIssuing: getCheckboxValue(val, {
                    isMultiple: false,
                }),
            });
        }

        return result;
    };

    const handleChangeFee = (key, value) => {
        const feeTarget = {
            joinFee: parseInt(contractFeeRef.current.joinFee.getResult()),
            firstMonthFee: parseInt(contractFeeRef.current.firstMonthFee.getResult()),
            nextMonthFee: parseInt(contractFeeRef.current.nextMonthFee.getResult()),
        };

        if (key) {
            feeTarget[key] = parseInt(value, 10);
        }

        setTotal((prev) => {
            return {
                ...prev,
                fee: sum(Object.values(feeTarget)),
            };
        });

        if (key === 'nextMonthFee' && isNumber(feeTarget[key])) {
            let tmpContract = cloneDeep(sourceData);
            const params = getResult();
            tmpContract.contractFee = params.contractFee;
            tmpContract.contractFee.nextMonthFee = feeTarget[key];
            handleChangeRangeFee(tmpContract);
        }
    };

    const handleChangePaymentAmount = (key, value) => {
        const { cardPaymentAmount, cashPaymentAmount } = paymentRef.current.getResult();
        const paymentTarget = {
            cardPaymentAmount: parseInt(cardPaymentAmount),
            cashPaymentAmount: parseInt(cashPaymentAmount),
        };
        if (key) paymentTarget[key] = parseInt(value);
        setTotal((prev) => {
            return {
                ...prev,
                payment: sum(Object.values(paymentTarget)),
            };
        });
    };

    const initCalculate = () => {
        const feeTarget = {
            joinFee: parseInt(joinFee),
            firstMonthFee: parseInt(firstMonthFee),
            nextMonthFee: parseInt(nextMonthFee),
        };
        const paymentTarget = {
            cardPaymentAmount: parseInt(cardPaymentAmount),
            cashPaymentAmount: parseInt(cashPaymentAmount),
        };
        setTotal((prev) => {
            return {
                fee: sum(Object.values(feeTarget)),
                payment: sum(Object.values(paymentTarget)),
            };
        });
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => {
                let allObj = {
                    ...contractFeeRef.current,
                    payment: paymentRef.current,
                };
                return refIsRequiredError.apply(null, Object.values(allObj));
            },
        }),
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        initCalculate();
        setFieldKey(uuid());
        // eslint-disable-next-line
    }, [sourceData]);

    return (
        <div className={clsx('payment', className)}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <PaidList
                        ref={paymentRef}
                        className={'mb-3'}
                        title={'初次費用'}
                        total={total.fee}
                        balance={total.fee - total.payment}
                        isShowBalance
                        cardPaymentAmountProps={{
                            defaultValue: cardPaymentAmount || '',
                            onChangeNative: (e) => handleChangePaymentAmount('cardPaymentAmount', e.target.value),
                            ...getErrorModuleStatus(errorModule.contractFee, 'cardPaymentAmount'),
                        }}
                        cashPaymentAmountProps={{
                            defaultValue: cashPaymentAmount || '',
                            onChangeNative: (e) => handleChangePaymentAmount('cashPaymentAmount', e.target.value),
                            ...getErrorModuleStatus(errorModule.contractFee, 'cashPaymentAmount'),
                        }}
                        readOnly={isReadOnly}
                    >
                        <PaidList.PaidListItem label={'入會費'}>
                            <TextField
                                key={fieldKey}
                                ref={(el) => (contractFeeRef.current.joinFee = el)}
                                defaultValue={joinFee}
                                maskType={'MONEY'}
                                onChangeNative={(e) => handleChangeFee('joinFee', e.target.value)}
                                {...getErrorModuleStatus(errorModule.contractFee, 'joinFee')}
                            />
                        </PaidList.PaidListItem>
                        <PaidList.PaidListItem label={'首月費'} required>
                            <TextField
                                key={fieldKey}
                                ref={(el) => (contractFeeRef.current.firstMonthFee = el)}
                                defaultValue={firstMonthFee}
                                maskType={'MONEY'}
                                onChangeNative={(e) => handleChangeFee('firstMonthFee', e.target.value)}
                                {...getErrorModuleStatus(errorModule.contractFee, 'firstMonthFee')}
                            />
                        </PaidList.PaidListItem>
                        <PaidList.PaidListItem label={'隔月費'} required>
                            <TextField
                                key={fieldKey}
                                ref={(el) => (contractFeeRef.current.nextMonthFee = el)}
                                defaultValue={nextMonthFee}
                                maskType={'MONEY'}
                                onChangeNative={(e) => handleChangeFee('nextMonthFee', e.target.value)}
                                {...getErrorModuleStatus(errorModule.contractFee, 'nextMonthFee')}
                            />
                        </PaidList.PaidListItem>
                        <PaidList.PaidListItem label={'*隔月月費變動，將會直接影響首次扣款日'} className="balance font-color-3" />
                    </PaidList>
                    <CheckboxField className={'mb-1'} ref={immediatelyIssuingRef} defaultChecked={setCheckboxValue(isImmediatelyIssuing)}>
                        <CheckboxField.Checkbox label={'是否收款並直接開立發票'} value={true} />
                    </CheckboxField>
                    <Alert className={'alert-justify-content-start'} severity={'info'} variant={'filled'} color={'info'}>
                        若未直接開立，需到 新增收入 確認結帳方可確認收款及開立
                    </Alert>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ReadTextField label={'會籍期間'}>{displayDateText(startDate, endDate)}</ReadTextField>
                        </Grid>
                        {['102', '103'].indexOf(String(contractVersion)) !== -1 && (
                            <Grid item xs={12}>
                                <ReadTextField label={'審閱期間'}>{displayDateText(reviewStartDate, reviewEndDate)}</ReadTextField>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <CheckboxField
                                ref={(el) => (contractFeeRef.current.isConfirm = el)}
                                defaultChecked={setCheckboxValue(isConfirm)}
                                {...getErrorModuleStatus(errorModule.contractFee, 'isConfirm')}
                                readOnly={isReadOnly}
                                required
                            >
                                <CheckboxField.Checkbox label={'本人已了解會籍內容，並確認使用。'} value={true} />
                            </CheckboxField>
                        </Grid>
                        <Grid item xs={12}>
                            <SignBox
                                className={'m-0'}
                                ref={(el) => (contractFeeRef.current.signImg = el)}
                                defaultValue={signImg}
                                title={'會員簽名'}
                                {...getErrorModuleStatus(errorModule.contractFee, 'signImg')}
                                readOnly={isReadOnly}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
});

export default MembershipCreateContentFee;
