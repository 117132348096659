import { useCallback } from 'react';
import useAjax from '@apis/useAjax';

const GET_INVOICES_OPTIONS_API = `/api/invoices/options/`;

const useInvoiceApi = () => {
    const Ajax = useAjax();

    const getInvoiceOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_INVOICES_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getInvoiceOptionsApi,
    };
};

export default useInvoiceApi;
