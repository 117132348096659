import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Alert, Button, MenuItem, SelectField, Typography } from '../../../components';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { refIsRequiredError } from '../../../util/utils';
import useOrderCheckoutApi from '../../../apis/useOrderCheckoutApi';
import useOrderReturnApi from '../../../apis/useOrderReturnApi';
import OrderAllowanceItemTable from '../tables/OrderAllowanceItemTable';

const AllowanceAbandonDialogContent = React.forwardRef((props, ref) => {
    const { className, coachesOption = [], items } = props;
    const employeeRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
                }
                return result;
            },
            isError: () => refIsRequiredError(employeeRef),
        }), // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Grid className={className} spacing={3} container>
                <Grid xs={12} item>
                    <Typography className={'font-weight-bold'}>折讓品項</Typography>
                    <OrderAllowanceItemTable className={'theme-gray'} rowsData={items} />
                </Grid>
                <Grid xs={12} item>
                    <SelectField label="擔當" ref={employeeRef} fullWidth required>
                        <MenuItem value={''}>
                            <em>請選擇</em>
                        </MenuItem>
                        {coachesOption.map(({ text, value }) => (
                            <MenuItem value={value} key={value}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
            </Grid>
        </React.Fragment>
    );
});
export const AllowanceAbandonDialog = React.forwardRef((props, ref) => {
    const { className, open, data, onClose, onSubmit } = props;
    const { getOrderCheckoutOptionsApi } = useOrderCheckoutApi();
    const [resource, setResource] = useState({});
    const { coachOptions: employeeOption = [] } = resource;

    const doInitOptionsApi = async () => {
        let res = await getOrderCheckoutOptionsApi();
        if (res) {
            setResource(res);
        }
    };

    useEffect(() => {
        if (open) doInitOptionsApi();
        // eslint-disable-next-line
    }, [open]);
    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>確認折讓作廢</GradientColorHeader>
            <DialogContent className="p-4">
                <AllowanceAbandonDialogContent ref={ref} className={'mb-3'} items={data} coachesOption={employeeOption} />
                <Alert className="mb-3" severity="error" variant="filled" color="error">
                    請注意，一旦送出作廢將無法恢復
                </Alert>
            </DialogContent>
            <DialogActions className="px-4 pt-0 pb-2">
                <Button variant="contained" onClick={onSubmit}>
                    確認作廢
                </Button>
            </DialogActions>
        </Dialog>
    );
});

const OrderAllowanceAbandonDialog = (props) => {
    const { className, open, allowanceID, items, onClose, refresh } = props;
    const { postOrderAbandonAllowanceConfirmApi } = useOrderReturnApi();
    const contentRef = useRef();

    const doOrderAbandonAllowanceConfirmApi = async (params) => {
        let res = await postOrderAbandonAllowanceConfirmApi(params);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const getParams = () => {
        let result = { allowanceID };
        if (contentRef.current && contentRef.current.getResult) result = Object.assign(result, contentRef.current.getResult());
        return result;
    };

    const handleSubmit = () => {
        let isError = refIsRequiredError(contentRef);
        if (!isError) {
            let params = getParams();
            doOrderAbandonAllowanceConfirmApi(params);
        }
    };

    return <AllowanceAbandonDialog ref={contentRef} className={className} open={open} data={items} onClose={onClose} onSubmit={handleSubmit} />;
};

export default OrderAllowanceAbandonDialog;
