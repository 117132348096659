
import React, { useImperativeHandle, useState, useRef, useEffect } from 'react';
import { MeasureLeaderboardWarningDialog } from '@icoach/measureLeaderboard';

const MeasureLeaderboardDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState(null);
    const [sourceData, setSourceData] = useState(null);
    const refreshRef = useRef(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // const doRefresh = (...arg) => {
    //     refreshRef.current && typeof refreshRef.current === 'function' && refreshRef.current(arg);
    // };

    useImperativeHandle(
        ref,
        () => {
            return {
                openMeasureLeaderboardWarningDialog: () => {
                    setOpenDialog('MeasureLeaderboardWarning');
                },
                // openContactDialog: (targetData) => {
                //     setSourceData(targetData);
                //     setOpenDialog('memberContact');
                // },
                // openOrderDialog: (target, _refreshFn = () => {}) => {
                //     setSourceData(target);
                //     setOpenDialog('creditOrder');
                //     refreshRef.current = _refreshFn;
                // },
                // openGiftDispatchDialog: (targetData) => {
                //     setSourceData(targetData);
                //     setOpenDialog('giftDispatch');
                // },
                closeDialog: () => handleCloseDialog(),
            };
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            if (!openDialog) {
                refreshRef.current = null;
                setSourceData(null);
            }
        },
        // eslint-disable-next-line
        [openDialog]
    );

    if (!openDialog) return null;
    return (
        <React.Fragment>
            {/* <MemberContactDialog open={openDialog === 'memberContact'} onClose={handleCloseDialog} sourceData={sourceData} refresh={refreshProps} />
            <SendSmsDialog open={openDialog === 'sendSMS'} onClose={handleCloseDialog} sourceData={sourceData} refresh={refreshProps} />
            <CheckoutCreditOrderDialog
                open={openDialog === 'creditOrder'}
                onClose={handleCloseDialog}
                memberID={sourceData?.memberID ?? null}
                refresh={doRefresh}
            />
             */}
            <MeasureLeaderboardWarningDialog open={openDialog === 'MeasureLeaderboardWarning'} onClose={handleCloseDialog} sourceData={sourceData} />
        </React.Fragment>
    );
});

export default MeasureLeaderboardDialogBox;
