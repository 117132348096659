import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import GuestProductionEidtDialog from '@icoach/nonMember/guestProduction/GuestProductionEidtDialog';
import GuestProductionDeleteDialog from '@icoach/nonMember/guestProduction/GuestProductionDeleteDialog';
import useNonMember from '@icoach/nonMember/components/useNonMember';
import Tablenization from '@icoach/components/Tablenization';
import { Stack, Checkbox, Chip, IconButton, Typography, FormControlLabel } from '@common/components/';
import { BorderColor as BorderColorIcon, Trash as TrashIcon } from '@common/SvgIcon/';
import useCustomerManufactureApi from '@apis/useCustomerManufactureApi';
import { findIndexFn } from '@util/utils';
import { parseDate } from '@util/moment';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';

const tableCheckboxFn = (cellValue, rowData, { chkRef, checkedOnChange }) => {
    if (!chkRef || !checkedOnChange) return null;
    return (
        <Checkbox
            color="primary"
            onChange={checkedOnChange}
            value={cellValue}
            inputRef={(el) => {
                if (el && chkRef.current && chkRef.current.indexOf(el) === -1) {
                    chkRef.current.push(el);
                }
            }}
        />
    );
};

const FeaturesMoreLabel = (props) => {
    const { getCustomerManufactureApi } = useCustomerManufactureApi();
    const { refresh, optionEnums = {} } = useNonMember();
    const [dialogOpen, setDialogOpen] = useState(null);
    // Edit use Data
    const [sourceData, setSourceData] = useState(null);
    const { id: customerManufactureID, rowsData = [] } = props;

    const handleOnClose = (e) => setDialogOpen(null);

    const handleEditOnClick = (e) => {
        setDialogOpen('edit');
    };

    const handleDeleteOnClick = (e) => {
        setDialogOpen('delete');
    };

    const getEditDataApi = useCallback(
        () => {
            return (async () => {
                const resp = await getCustomerManufactureApi(customerManufactureID);
                if (resp) setSourceData(resp);
            })();
        },
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        if (dialogOpen === 'edit') getEditDataApi();
        else if (!dialogOpen) setSourceData(null);
    }, [dialogOpen, getEditDataApi]);

    return (
        <Stack>
            <IconButton onClick={handleDeleteOnClick} className={'icon-button-column'}>
                <TrashIcon />
                <Typography className={'icon-button-column-text'}>刪除</Typography>
            </IconButton>
            <IconButton onClick={handleEditOnClick} className={'icon-button-column'}>
                <BorderColorIcon />
                <Typography className={'icon-button-column-text'}>編輯</Typography>
            </IconButton>
            <GuestProductionEidtDialog
                open={dialogOpen === 'edit'}
                onClose={() => handleOnClose()}
                options={dialogOpen === 'edit' ? optionEnums : []}
                refresh={refresh}
                defaultData={sourceData}
                id={customerManufactureID}
            />
            <GuestProductionDeleteDialog open={dialogOpen === 'delete'} onClose={() => handleOnClose()} refresh={refresh} defaultData={[...rowsData]} />
        </Stack>
    );
};

const FeatureHeaderIcon = (props) => {
    const { selectedRef, rowsData } = props;
    const { refresh } = useNonMember();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [sourceData, setSourceData] = useState([]);

    const handleOnClose = (e) => {
        setSourceData([]);
        setDialogOpen(false);
    };

    const selectedOptionAction = (targetArray, targetData) =>
        targetArray.reduce((acc, cur) => {
            const _index = findIndexFn(targetData, 'manufactureID', cur.value);
            let newAcc = [...acc];
            if (cur.checked && _index !== -1) newAcc.push(targetData[_index]);
            return newAcc;
        }, []);

    const handleOpenClick = (e) => {
        const newData = selectedOptionAction(selectedRef.current, rowsData);
        if (newData.length > 0) {
            setDialogOpen(true);
            setSourceData(newData);
        }
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleOpenClick}>
                <TrashIcon />
            </IconButton>
            <GuestProductionDeleteDialog open={dialogOpen} onClose={() => handleOnClose()} refresh={refresh} defaultData={[...sourceData]} />
        </React.Fragment>
    );
};

/**
 *@param {String} cellKey
 *@param {Boolean} isIcon
 *@param { String| Function(@return [NULL | String | React.Component]) } headerLabel
 *@param {Number} minWidth
 *@param {Array['left','center','right']} align
 *@param {null|Function(@return [ NULL | String | React.Component]) (@param {String} cellValue ,@param {Object} rowsData,@param ...args)} formatCell
 */
const headerTitles = [
    { cellKey: 'manufactureID', headerLabel: '選取', isIcon: true, formatCell: tableCheckboxFn },
    {
        cellKey: 'manufactureDate',
        align: 'center',
        headerLabel: '日期',
        width: 10,
        formatCell: (_value) => parseDate(_value, 'MM-DD'),
    },
    { cellKey: 'manufactureTimeRange', align: 'center', headerLabel: '時間', width: 10 },
    { cellKey: 'position', align: 'center', headerLabel: '地點', maxWidth: 15, width: 15 },
    { cellKey: 'coachName', align: 'center', headerLabel: '擔當', width: 15 },
    {
        cellKey: 'manufactureType',
        align: 'center',
        headerLabel: '方式',
        width: 15,
        formatCell: (label) => <Chip label={label} color="warning" />,
    },
    { cellKey: 'quantity', align: 'center', headerLabel: '數量/時數/家數', width: 15 },
    { cellKey: 'notes', align: 'center', maxWidth: 15, headerLabel: '備註' },
    {
        cellKey: 'manufactureID',
        align: 'center',
        headerLabel: '功能',
        isIcon: true,
        formatCell: (_id, rowData) => <FeaturesMoreLabel id={_id} rowsData={[rowData]} />,
    },
];

const GuestProductionTable = () => {
    const { sourceData, setBookmarkNum, refresh } = useNonMember();
    const rowsData = sourceData?.list ?? [];
    const { pageIndex: page, totalPage: count } = sourceData;

    const [handleCheckAllChange, handleCheckedChange, selectedAllRef, selectedOptRef, indeterminateRef] = useGroupCheckbox(sourceData);

    const handlePageChange = (e, num) => {
        setBookmarkNum(num);
    };

    React.useEffect(() => {
        refresh && refresh.current && refresh.current();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="customer-table">
            <Stack>
                <div className={clsx('customer-table-action-icon', rowsData.length === 0 && 'visibility-hidden')}>
                    <FormControlLabel
                        value="all"
                        label="全選"
                        control={
                            <Checkbox
                                color="primary"
                                indeterminateRef={indeterminateRef}
                                inputRef={(el) => (selectedAllRef.current = el)}
                                onChange={handleCheckAllChange}
                            />
                        }
                    />
                    <FeatureHeaderIcon selectedRef={selectedOptRef} rowsData={[...rowsData]} />
                </div>
            </Stack>
            <div>
                <Tablenization isPagination page={page} count={count} onChange={handlePageChange}>
                    <Tablenization.TableRowHeader headerRow={headerTitles} />
                    <Tablenization.TableRowBody
                        headerRow={headerTitles}
                        rowsData={[...rowsData]}
                        chkRef={selectedOptRef}
                        checkedOnChange={handleCheckedChange}
                    />
                </Tablenization>
            </div>
        </div>
    );
};

export default GuestProductionTable;
