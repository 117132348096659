import React, { useState } from 'react';
import _ from 'lodash';
import { Stack, IconButton, Menu, MenuItem, ListItemText, ListItemIcon, Tooltip } from '@common/components/';
import {
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    NoAnswer as NoAnswerIcon,
    InTouch as InTouchIcon,
    NotCall as NotCallIcon,
    LoseTouch as LoseTouchIcon,
} from '@common/SvgIcon/';
import useAppointmentApi from '@apis/useAppointmentApi';
import { mergeArrayByProperty } from '@util/utils';

const contactTypeOption = [
    { text: '已確認', value: '1', icon: <InTouchIcon /> },
    { text: '已打，未接', value: '2', icon: <NoAnswerIcon color="error"/> },
    { text: '尚未聯繫', value: '3', icon: <NotCallIcon /> },
    { text: '電話錯誤', value: '4', icon: <LoseTouchIcon color="gray"/> },
    { text: '預約當日已確認', value: '5', icon: <InTouchIcon /> },
    { text: '預約日前已確認', value: '6', icon: <InTouchIcon color="info"/> },
];

const AppointmentConfirmMenuOption = React.forwardRef((props, ref) => {
    const { className: classNameProps, option: optionProp, value: valueProp, apoId: apoIdProp } = props;
    const [option] = useState(mergeArrayByProperty(optionProp, contactTypeOption, 'value', 'icon'));
    const [value, setValue] = useState(valueProp || null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { putAppointmentConfirmApi } = useAppointmentApi();

    const handleMoreIconClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
    };

    const handleOnClickMenuItem = (_value) => {
        const currentValue = String(_value) !== String(value) ? _value : null;
        const params = { appointmentID: apoIdProp, confirmType: currentValue || 0 };
        setAnchorEl(null);
        putAppointmentAction(params, currentValue);
    };

    const putAppointmentAction = async (params, currentValue) => {
        const resp = await putAppointmentConfirmApi(params);
        resp && setValue(currentValue);
    };

    const item = option.find(({ value: _v }) => String(value) === String(_v)) || {};
    const viewEl = _.isEmpty(item) ? { icon: <CheckBoxOutlineBlankIcon />, text: '' } : item;

    return (
        <Stack direction="column" alignItems="center" spacing={1}>
            <span className={classNameProps}>確認</span>
            <Tooltip disableFocusListener title={viewEl.text}>
                <IconButton onClick={handleMoreIconClick}>{viewEl.icon}</IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={open} onClose={(e) => handleClose(null)}>
                {option.map(({ text, value: _value, icon }) => {
                    const selected = String(_value) === String(value);
                    return (
                        <MenuItem key={text} onClick={() => handleOnClickMenuItem(_value)} selected={selected}>
                            {icon && <ListItemIcon>{icon}</ListItemIcon>}
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Stack>
    );
});

export default AppointmentConfirmMenuOption;
