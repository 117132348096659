import React, { createContext, useContext } from 'react';

const RentCabinetContext = createContext(undefined);

export const RentCabinetProvider = ({ children, value }) => {
    return <RentCabinetContext.Provider value={value}>{children}</RentCabinetContext.Provider>;
};

export const useRentCabinetContext = () => {
    const context = useContext(RentCabinetContext);
    if (context === undefined) {
        throw new Error('useRentCabinetContext 必須在 RentCabinetProvider 中使用');
    }
    return context;
};
