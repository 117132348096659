import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, MenuItem, SelectField } from '../../../components';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { refIsRequiredError } from '../../../util/utils';
import useEmployeeApi from '../../../apis/useEmployeeApi';
import { CheckoutPaidListBox } from '../components';
import useOrderCheckoutApi from '../../../apis/useOrderCheckoutApi';

const PaymentOrderDialogDialogContent = React.forwardRef((props, ref) => {
    const { totalPrice, options } = props;
    const checkoutRef = useRef();
    const employeeRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (employeeRef.current && employeeRef.current.getResult) {
                    result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
                }
                if (checkoutRef.current && checkoutRef.current.getResult) {
                    result = Object.assign(result, checkoutRef.current.getResult());
                }
                return result;
            },
            isError: () => refIsRequiredError(employeeRef, checkoutRef),
        }), // eslint-disable-next-line
        [],
    );

    return (
        <DialogContent>
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                        {Array.isArray(options) &&
                            options
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
                <Grid xs={12} item>
                    <CheckoutPaidListBox className={'mb-4'} ref={checkoutRef} receivableAmount={totalPrice} isShowCash isShoCredit />
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const PaymentOrderDialog = (props) => {
    const { className, open, sourceData, onClose, refresh } = props;
    const { postOrderCheckoutByPeopleApi } = useOrderCheckoutApi();
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const [options, setOptions] = useState([]);
    const contentRef = useRef();

    const doOrderCheckoutByPeopleApi = async (params) => {
        let res = await postOrderCheckoutByPeopleApi(params);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const doEmployeesOptionsApi = async () => {
        let res = await getEmployeesOptionsApi();
        if (res) {
            setOptions(res);
        }
    };
    const getParams = () => {
        const { receiverType, receiverID, orderDate, checkoutOrderIDs } = sourceData;
        let result = {
            receiverType,
            receiverID,
            orderDate,
            checkoutOrderIDs,
        };

        if (contentRef.current && contentRef.current.getResult) {
            result = Object.assign(result, contentRef.current.getResult());
        }

        return result;
    };
    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doOrderCheckoutByPeopleApi(params);
        }
    };

    useEffect(() => {
        if (open) doEmployeesOptionsApi();
        // eslint-disable-next-line
    }, [open]);

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>進行付款</GradientColorHeader>
            <PaymentOrderDialogDialogContent ref={contentRef} totalPrice={sourceData.totalPrice} options={options} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentOrderDialog;
