import React, { useImperativeHandle, useRef, useState } from 'react';
// import CreateOrderDialog from '@icoach/accountBook/dialog/CreateOrderDialog';
import DeleteOrderDialog from '@icoach/accountBook/dialog/DeleteOrderDialog';
// import EditMemoDialog from '@icoach/accountBook/dialog/EditMemoDialog';
import ImportAchDialog from '@icoach/accountBook/dialog/ImportAchDialog';
// import OrderAllowanceAbandonDialog from '@icoach/accountBook/dialog/OrderAllowanceAbandonDialog';
import OrderAllowanceDialog from '@icoach/accountBook/dialog/OrderAllowanceDialog';
import OrderInvoiceAbandonDialog from '@icoach/accountBook/dialog/OrderInvoiceAbandonDialog';
// import OrdersDialog from '@icoach/accountBook/dialog/OrdersDialog';
import PaymentOrderDialog from '@icoach/accountBook/dialog/PaymentOrderDialog';
// import PettyMoneyDialog from '@icoach/accountBook/dialog/PettyMoneyDialog';
import ReinitOrderDialog from '@icoach/accountBook/dialog/ReinitOrderDialog';
// import RemitAllowanceAbandonDialog from '@icoach/accountBook/dialog/RemitAllowanceAbandonDialog';
// import RemitAllowanceDialog from '@icoach/accountBook/dialog/RemitAllowanceDialog';
// import RemitInvoiceAbandonDialog from '@icoach/accountBook/dialog/RemitInvoiceAbandonDialog';
import ResetOrderInvoiceDialog from '@icoach/accountBook/dialog/ResetOrderInvoiceDialog';
// import SettleCashDialog from '@icoach/accountBook/dialog/SettleCashDialog';
// import SettleCreditDialog from '@icoach/accountBook/dialog/SettleCreditDialog';
// import ShoppingMenuDialog from '@icoach/accountBook/dialog/ShoppingMenuDialog';

const CREATE_ORDER_DIALOG = 'createOrder';
const DELETE_ORDER_DIALOG = 'deleteOrder';
const EDIT_MEMO_DIALOG = 'editMemo';
const IMPORT_ACH_DIALOG = 'importAch';
const ORDERS_DIALOG = 'orders';
const PETTY_MONEY_DIALOG = 'pettyMoney';
const PAYMENT_ORDER_DIALOG = 'paymentOrder';
const REINIT_ORDER_DIALOG = 'reinitOrder';
const REMIT_ALLOWANCE_ABANDON_DIALOG = 'remitAllowanceAbandon';
const REMIT_ALLOWANCE_DIALOG = 'remitAllowance';
const REMIT_INVOICE_ABANDON_DIALOG = 'remitInvoiceAbandon';
const RESET_ORDER_INVOICE_DIALOG = 'resetOrderInvoice';
const SETTLE_CASH_DIALOG = 'settleCash';
const SETTLE_CREDIT_DIALOG = 'settleCredit';
const SHOPPING_MENU_DIALOG = 'shoppingMenu';
const ORDER_ALLOWANCE_ABANDON_DIALOG = 'orderAllowanceAbandon';
const ORDER_ALLOWANCE_DIALOG = 'orderAllowance';
const ORDER_INVOICE_ABANDON_DIALOG = 'orderInvoiceAbandon';

const AccountBookDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState('');
    const [sourceData, setSourceData] = useState(null);
    const refreshRef = useRef(null);

    const handleDialogClose = () => {
        setOpenDialog(null);
        setSourceData(null);
    };

    const doRefresh = (...arg) => {
        if (refreshRef.current && typeof refreshRef.current === 'function') {
            setTimeout(() => refreshRef.current(...arg), 5);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            openCreateOrderDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(CREATE_ORDER_DIALOG);
                refreshRef.current = refreshFn;
            },
            openDeleteOrderDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(DELETE_ORDER_DIALOG);
                refreshRef.current = refreshFn;
            },
            openEditMemoDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(EDIT_MEMO_DIALOG);
                refreshRef.current = refreshFn;
            },
            openImportAchDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(IMPORT_ACH_DIALOG);
                refreshRef.current = refreshFn;
            },
            openOrderInvoiceAbandonDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(ORDER_INVOICE_ABANDON_DIALOG);
                refreshRef.current = refreshFn;
            },
            openOrderAllowanceDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(ORDER_ALLOWANCE_DIALOG);
                refreshRef.current = refreshFn;
            },
            openOrderAllowanceAbandonDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(ORDER_ALLOWANCE_ABANDON_DIALOG);
                refreshRef.current = refreshFn;
            },
            openOrdersDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(ORDERS_DIALOG);
                refreshRef.current = refreshFn;
            },
            openPaymentOrderDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(PAYMENT_ORDER_DIALOG);
                refreshRef.current = refreshFn;
            },
            openPettyMoneyDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(PETTY_MONEY_DIALOG);
                refreshRef.current = refreshFn;
            },
            openReinitOrderDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(REINIT_ORDER_DIALOG);
                refreshRef.current = refreshFn;
            },
            openRemitAllowanceAbandonDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(REMIT_ALLOWANCE_ABANDON_DIALOG);
                refreshRef.current = refreshFn;
            },
            openRemitAllowanceDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(REMIT_ALLOWANCE_DIALOG);
                refreshRef.current = refreshFn;
            },
            openRemitInvoiceAbandonDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(REMIT_INVOICE_ABANDON_DIALOG);
                refreshRef.current = refreshFn;
            },
            openResetOrderInvoiceDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(RESET_ORDER_INVOICE_DIALOG);
                refreshRef.current = refreshFn;
            },
            openSettleCashDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(SETTLE_CASH_DIALOG);
                refreshRef.current = refreshFn;
            },
            openSettleCreditDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(SETTLE_CREDIT_DIALOG);
                refreshRef.current = refreshFn;
            },
            openShoppingMenuDialog: (targetData, refreshFn) => {
                setSourceData(targetData);
                setOpenDialog(SHOPPING_MENU_DIALOG);
                refreshRef.current = refreshFn;
            },
        }),
        // eslint-disable-next-line
        [],
    );

    if (!openDialog) return null;

    return (
        <React.Fragment>
            {/*<CreateOrderDialog open={openDialog === CREATE_ORDER_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />*/}
            <DeleteOrderDialog open={openDialog === DELETE_ORDER_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />
            {/*<EditMemoDialog open={openDialog === EDIT_MEMO_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />*/}
            <ImportAchDialog open={openDialog === IMPORT_ACH_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />
            {/*<OrderAllowanceAbandonDialog*/}
            {/*    open={openDialog === ORDER_ALLOWANCE_ABANDON_DIALOG}*/}
            {/*    onClose={handleDialogClose}*/}
            {/*    refresh={doRefresh}*/}
            {/*    sourceData={sourceData}*/}
            {/*/>*/}
            <OrderAllowanceDialog open={openDialog === ORDER_ALLOWANCE_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />
            <OrderInvoiceAbandonDialog
                open={openDialog === ORDER_INVOICE_ABANDON_DIALOG}
                onClose={handleDialogClose}
                refresh={doRefresh}
                sourceData={sourceData}
            />
            {/*<OrdersDialog open={openDialog === ORDERS_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />*/}
            <PaymentOrderDialog open={openDialog === PAYMENT_ORDER_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />
            {/*<PettyMoneyDialog open={openDialog === PETTY_MONEY_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />*/}
            <ReinitOrderDialog open={openDialog === REINIT_ORDER_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />
            {/*<RemitAllowanceAbandonDialog*/}
            {/*    open={openDialog === REMIT_ALLOWANCE_ABANDON_DIALOG}*/}
            {/*    onClose={handleDialogClose}*/}
            {/*    refresh={doRefresh}*/}
            {/*    sourceData={sourceData}*/}
            {/*/>*/}
            {/*<RemitAllowanceDialog open={openDialog === REMIT_ALLOWANCE_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />*/}
            {/*<RemitInvoiceAbandonDialog*/}
            {/*    open={openDialog === REMIT_INVOICE_ABANDON_DIALOG}*/}
            {/*    onClose={handleDialogClose}*/}
            {/*    refresh={doRefresh}*/}
            {/*    sourceData={sourceData}*/}
            {/*/>*/}
            <ResetOrderInvoiceDialog open={openDialog === RESET_ORDER_INVOICE_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />
            {/*<SettleCashDialog open={openDialog === SETTLE_CASH_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />*/}
            {/*<SettleCreditDialog open={openDialog === SETTLE_CREDIT_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />*/}
            {/*<ShoppingMenuDialog open={openDialog === SHOPPING_MENU_DIALOG} onClose={handleDialogClose} refresh={doRefresh} sourceData={sourceData} />*/}
        </React.Fragment>
    );
});

export default AccountBookDialogBox;
