import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import SmsIdOverviewTable from './SmsIdOverviewTable';
import { MESSAGE_SMS_OVERVIEW } from '../../router/MessageRouter';
import { Typography, Card, Stack, Button, Chip } from '../../../components';
import useSmsApi from '../../../apis/useSmsApi';
import { parseDate } from '../../../util/moment';
import useMessageDialog from '../../../util/hook/useMessageDialog';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

// 簡訊內容摘要
const SmsIdSummaryBox = (props) => {
    const { sourceData = {} } = props;
    const { deleteSmsIDCancelApi } = useSmsApi();
    const setMessageDialog = useMessageDialog();
    const history = useHistory();
    const {
        status,
        statusText,
        sendDate,
        smsTemplateName,
        content,
        smsID,
        isCancel,
        isResend,
    } = sourceData;
    const statusColorMap = { 3: 'success', 4: 'error' };

    const handleCanelOnCLick = () => {
        setMessageDialog({
            open: true,
            title: '取消簡訊',
            msg: `確認要取消這則簡訊？取消後就無法復原囉`,
            onConfirm: () => doCancelSmsApi(),
        });
    };

    const doCancelSmsApi = async () => {
        const resp = await deleteSmsIDCancelApi(smsID);
        if (resp) history.push(MESSAGE_SMS_OVERVIEW);
    };

    return (
        <Card>
            <Card.CardContent className="px-3 py-2">
                <Stack justifyContent="space-between" alignItems="start">
                    <Stack direction="column">
                        <Stack spacing={2}>
                            <Chip color={statusColorMap[status] || 'info'} label={statusText} />
                            <Chip
                                color="primary"
                                label={parseDate(sendDate, DateTimeStandard.DisplayFullBySlash)}
                            />
                        </Stack>
                        <Typography variant="h4" component="h3">
                            {smsTemplateName}
                        </Typography>
                    </Stack>
                    <Stack alignItems="center" spacing={2}>
                        {isResend && <Button variant="outlined">重新發送</Button>}
                        {isCancel && (
                            <Button variant="outlined" onClick={handleCanelOnCLick}>
                                取消預約
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            onClick={() => history.push(MESSAGE_SMS_OVERVIEW)}
                        >
                            返回簡訊總覽
                        </Button>
                    </Stack>
                </Stack>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="body1" component="p">
                            {content}
                        </Typography>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
};

const SmsIdOverviewPage = React.forwardRef((props, ref) => {
    const { smsid: smsidProps } = useParams();
    const { getSmsIDOverviewApi } = useSmsApi();
    const [isMounted, setMounted] = useState(false);
    const [sourceData, setSourceData] = useState({});
    const { sms: smsInfo = {}, records: list = [] } = sourceData;

    const initSmsIdOverviewDataApi = async () => {
        const resp = await getSmsIDOverviewApi(smsidProps);
        if (resp) {
            setSourceData(resp);
            setMounted(true);
        }
    };

    useEffect(
        () => {
            initSmsIdOverviewDataApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        []
    );
    // console.log(smsInfo);
    // console.log(list);
    return (
        <div className="container main-container-spacing">
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    簡訊回覆人員清單
                </Typography>
            </header>
            {isMounted && (
                <React.Fragment>
                    <SmsIdSummaryBox sourceData={smsInfo} />
                    <SmsIdOverviewTable sourceData={list} />
                </React.Fragment>
            )}
        </div>
    );
});

export default SmsIdOverviewPage;
