// import React, { useLayoutEffect, useState, useRef } from 'react';
// import pdf from './personal-information-update.pdf';
import React from 'react';
import {
    // HTMLViewPdfComponent,
    PdfComponent,
} from '@icoach/components/';

const PDFTest = React.forwardRef((props, ref) => {
    // const url = 'https://localhost:5011/document/membership.pdf?version=102&club=1';
    const url2 = 'https://localhost:5011/members/1088/doc/cycle-purchase-effection/337/contract.pdf?t=A000SVPnZK9';
    const url3 = 'https://localhost:5011/members/1088/doc/cycle-purchase-effection/340/contract.pdf?t=A000SVPnZK9';
    const urls = [url2, url3];
    return (
        <div className="main-container-spacing" style={{ position: 'relative', height: '100vh' }}>
            <PdfComponent pdfUrl={urls} />
            {/*<HTMLViewPdfComponent */}
            {/*    htmlUrl="https://localhost:5011/document/929/membership?version=102&club=1" */}
            {/*    pdfUrl={`https://localhost:5011/document/membership.pdf?version=102&club=1*/}
            {/*`} />*/}
        </div>
    );
});

export default PDFTest;
