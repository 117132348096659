import React, { useImperativeHandle, useRef, useState } from 'react';
import {
    CreateCabinetDialog,
    DeleteCabinetDialog,
    DeleteQuitRentCabinetDialog,
    EditCabinetDialog,
    ImportCabinetDialog,
    QuitRentCabinetDialog,
} from '@icoach/rentCabinet/dialog/index';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';

const QUIT_RENT_CABINET_DIALOG = 'quitRentCabinet';
const DELETE_QUIT_RENT_CABINET_DIALOG = 'deleteQuitRentCabinet';
const SEND_SMS_DIALOG = 'sendSMS';
const CREATE_CABINET_DIALOG = 'createCabinet';
const EDIT_CABINET_DIALOG = 'editCabinet';
const DELETE_CABINET_DIALOG = 'deleteCabinet';
const IMPORT_CABINET_DIALOG = 'importCabinet';

const RentCabinetDialogBox = React.forwardRef((props, ref) => {
    const [openDialog, setOpenDialog] = useState('');
    const [sourceData, setSourceData] = useState(null);
    const refreshRef = useRef(null);

    const handleDialogClose = () => {
        setOpenDialog(null);
        setSourceData(null);
    };

    const doRefresh = (...arg) => {
        if (refreshRef.current && typeof refreshRef.current === 'function') {
            setTimeout(() => refreshRef.current(...arg), 5);
        }
    };

    useImperativeHandle(ref, () => ({
        openQuitRentCabinetDialog: (targetData, refreshFn) => {
            setSourceData(targetData);
            setOpenDialog(QUIT_RENT_CABINET_DIALOG);
            refreshRef.current = refreshFn;
        },
        openDeleteQuitRentCabinetDialog: (targetData, refreshFn) => {
            setSourceData(targetData);
            setOpenDialog(DELETE_QUIT_RENT_CABINET_DIALOG);
            refreshRef.current = refreshFn;
        },
        openSendSmsDialog: (targetData, refreshFn) => {
            setSourceData(targetData);
            setOpenDialog(SEND_SMS_DIALOG);
            refreshRef.current = refreshFn;
        },
        openCreateCabinetDialog: (targetDate, refreshFn) => {
            setSourceData(targetDate);
            setOpenDialog(CREATE_CABINET_DIALOG);
            refreshRef.current = refreshFn;
        },
        openImportCabinetDialog: (targetDate, refreshFn) => {
            setSourceData(targetDate);
            setOpenDialog(IMPORT_CABINET_DIALOG);
            refreshRef.current = refreshFn;
        },
        openEditCabinetDialog: (targetDate, refreshFn) => {
            setSourceData(targetDate);
            setOpenDialog(EDIT_CABINET_DIALOG);
            refreshRef.current = refreshFn;
        },
        openDeleteCabinetDialog: (targetDate, refreshFn) => {
            setSourceData(targetDate);
            setOpenDialog(DELETE_CABINET_DIALOG);
            console.log(refreshFn, 'refreshFn');
            refreshRef.current = refreshFn;
        },
    }));

    if (!openDialog) return null;

    return (
        <React.Fragment>
            <QuitRentCabinetDialog open={openDialog === QUIT_RENT_CABINET_DIALOG} sourceData={sourceData} onClose={handleDialogClose} refresh={doRefresh} />
            <DeleteQuitRentCabinetDialog
                open={openDialog === DELETE_QUIT_RENT_CABINET_DIALOG}
                sourceData={sourceData}
                onClose={handleDialogClose}
                refresh={doRefresh}
            />
            <SendSmsDialog open={openDialog === SEND_SMS_DIALOG} onClose={handleDialogClose} sourceData={sourceData} refresh={doRefresh} />
            <CreateCabinetDialog open={openDialog === CREATE_CABINET_DIALOG} onClose={handleDialogClose} refresh={doRefresh} />
            <ImportCabinetDialog open={openDialog === IMPORT_CABINET_DIALOG} onClose={handleDialogClose} refresh={doRefresh} />
            <EditCabinetDialog open={openDialog === EDIT_CABINET_DIALOG} onClose={handleDialogClose} sourceData={sourceData} refresh={doRefresh} />
            <DeleteCabinetDialog open={openDialog === DELETE_CABINET_DIALOG} onClose={handleDialogClose} sourceData={sourceData} refresh={doRefresh} />
        </React.Fragment>
    );
});

export default RentCabinetDialogBox;
