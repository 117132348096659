import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import moment from 'moment';
import { Card, Checkbox, MenuItem, SelectField, Stack, Typography } from '@common/components/';
import { getAddMonths, getSubMonths, parseDate, isAfter } from '@util/moment';

// 是否過截止日
export const isAfterDeadline = (date, shippingDay) => {
    let deadline = shippingDay - 5;
    deadline = parseDate(moment(date).date(deadline));
    return isAfter(date, deadline);
};

// 取得生效日 日期
export const getEffectiveDate = (date, shippingDay) => {
    let _date = date;
    if (isAfterDeadline(_date, shippingDay)) {
        _date = getAddMonths(_date, 1);
    }
    return _date;
};

// 取得最後出貨日
export const getLastShippingMonth = (date, shippingDay) => {
    let _date = date;

    if (!isAfterDeadline(date, shippingDay)) {
        _date = getSubMonths(date, 1);
    }
    return _date;
};

// 選取項目
export const PickItem = forwardRef((props, ref) => {
    const { defaultChecked, title, handleChange: handleChangeProps } = props;
    const [helperTextData, setHelperTextData] = useState({
        isError: false,
        text: '錯了送不出去',
    });
    const checkRef = useRef();
    const handleChange = (e, checked) => {
        handleChangeProps && handleChangeProps(checked);
    };
    useImperativeHandle(
        ref,
        () => ({
            getResult: () => checkRef.current && checkRef.current.checked,
            setHelperText: (isError, text) => {
                if (isError) window.scrollTo({ top: checkRef.current.offsetTop, behavior: 'smooth' });
                setHelperTextData({ isError, text });
            },
        }),
        [],
    );

    return (
        <Stack alignItems={'center'}>
            <Checkbox key={defaultChecked} className={'mr-1'} ref={checkRef} defaultChecked={defaultChecked} color={'primary'} onChange={handleChange} />
            <Typography className={'m-0 font-weight-bold'}>{title}</Typography>
            {helperTextData.isError && (
                <Typography className={'m-0 ml-1'} variant={'body2'} color={'error'}>
                    {helperTextData.text}
                </Typography>
            )}
        </Stack>
    );
});

export const SectionHeader = ({ title, isShowCheckBox = false, refItem, handleChange, defaultChecked }) => (
    <Card.CardHeader
        title={isShowCheckBox ? <PickItem ref={refItem} title={title} defaultChecked={defaultChecked} handleChange={handleChange} /> : title}
        className={'bg-light-active px-4'}
    />
);

export const SelectWithMenuItems = ({ refItem, label, defaultValue, options = [], fullWidth = false, required, ...other }) => (
    <SelectField key={defaultValue} ref={refItem} label={label} defaultValue={defaultValue} fullWidth={fullWidth} required={required} {...other}>
        <MenuItem value="">
            <em>請選擇</em>
        </MenuItem>
        {Array.isArray(options) &&
            options.map(({ text, value }) => (
                <MenuItem key={value} value={value}>
                    {text}
                </MenuItem>
            ))}
    </SelectField>
);

export const useSectionEffect = (setShowTip, onChangeEffectTypeProps, isShow, effectTypeID) => {
    useEffect(
        () => {
            setShowTip && setShowTip();
            onChangeEffectTypeProps && onChangeEffectTypeProps(isShow, effectTypeID);
        },
        // eslint-disable-next-line
        [isShow],
    );
};
