import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Button, Card, DateIntervalField, Form, Stack, Typography } from '@common/components/';
import { useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { isBefore, isAfter, getAddMonths, getSubMonths, getSubDays, getAddDays, parseDate } from '@util/moment';
import useMessageDialog from '@util/hook/useMessageDialog';

const OrderStatSearchBar = (props, ref) => {
    const { className, getOrderStatisticsApi, getExcelDownloadApi = null } = props;
    const { setTableData, setRefresh } = useAccountBookContext();
    const setMessageDialog = useMessageDialog();
    const dateRangeRef = useRef(null);
    const preloadRef = useRef(false);

    const getParams = () => {
        let { startDate, endDate } = dateRangeRef.current.getResult();
        return { start: startDate, end: endDate };
    };

    const handleSearchSubmit = async () => {
        preloadRef.current = false;
        const isError = dateRangeRef.current.isError();
        if (!isError) {
            const params = getParams();
            let resp = await getOrderStatisticsApi(params);
            if (resp) {
                setTableData(resp);
                preloadRef.current = true;
            }
        }
    };

    const handleChangeStartDate = (date, endDate) => {
        let maxData = getSubDays(getAddMonths(date, 3), 1);
        let isError = false;
        let helperText = '';
        if (endDate) {
            if (isAfter(endDate, maxData)) {
                isError = true;
                helperText = '日期區間不可大於三個月';
            }
            dateRangeRef.current.setError(isError, helperText);
        }
    };

    const handleChangeEndDate = (date, startDate) => {
        let minData = getAddDays(getSubMonths(date, 3), 1);
        let isError = false;
        let helperText = '';
        if (startDate) {
            if (isBefore(startDate, minData)) {
                isError = true;
                helperText = '日期區間不可大於三個月';
            }
            dateRangeRef.current.setError(isError, helperText);
        }
    };

    const doExcelSendEmailApi = async (params) => {
        if (typeof getExcelDownloadApi !== 'function') return;
        const resp = await getExcelDownloadApi(params);

        if (resp) {
            let { message, url } = resp;
            setMessageDialog({
                open: true,
                title: '個資資訊安全原則',
                MsgComp: () => (
                    <div>
                        <Typography className="word-break-break-word">{message}</Typography>
                        <p />
                        <Button to={url} color="error">
                            保管責任書
                        </Button>
                    </div>
                ),
            });
        }
    };

    const handleExcelOnclick = () => {
        const isError = dateRangeRef.current.isError();
        if (!isError) {
            const params = getParams();
            doExcelSendEmailApi(params);
        }
    };

    useEffect(
        () => {
            setRefresh(handleSearchSubmit);
            preloadRef.current = true;
            handleSearchSubmit();
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Card className={clsx('search-bar', className)}>
            <Card.CardContent>
                <Form onSubmit={handleSearchSubmit}>
                    <Stack className="px-4 py-3" justifyContent={'start'} alignItems={'start'} spacing={3}>
                        <DateIntervalField
                            className={'flex-auto'}
                            label={'建立日期'}
                            ref={dateRangeRef}
                            direction={'row'}
                            startDateProps={{
                                defaultValue: parseDate(),
                                onChange: handleChangeStartDate,
                            }}
                            endDateProps={{
                                defaultValue: parseDate(),
                                onChange: handleChangeEndDate,
                            }}
                            fullWidth
                        />
                        <Button type={'submit'} className={'white-space-nowrap'} variant={'contained'}>
                            搜尋
                        </Button>
                        {getExcelDownloadApi && (
                            <Button className={'white-space-nowrap'} variant={'contained'} color="success" onClick={handleExcelOnclick}>
                                下載Excel
                            </Button>
                        )}
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default OrderStatSearchBar;
