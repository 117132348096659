import { Button, ButtonGroup, Stack, Typography } from '../../../components';
import { tableTargetMap } from '../staticData';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';

const ReportTableHeader = (props) => {
    const {
        className,
        tableTarget,
        onToggleTable,
        groupButtonData = [
            {
                title: '每日紀錄',
                text: '日',
                value: tableTargetMap.daily,
            },
            {
                title: '月表現',
                text: '月',
                value: tableTargetMap.monthly,
            },
        ],
    } = props;
    const findCurrentTitle = useCallback(
        (groupButtonData) => {
            let currentData = groupButtonData.find((item) => item.value === tableTarget);
            if (!isEmpty(currentData)) return currentData.title;
            return '';
        },
        [tableTarget]
    );
    return (
        <Stack className={className} alignItems={'center'} justifyContent={'space-between'}>
            <Typography className={'font-weight-bold'} variant={'h5'}>
                {findCurrentTitle(groupButtonData)}
            </Typography>
            <ButtonGroup className={'report-btn-group'}>
                {!isEmpty(groupButtonData) &&
                    groupButtonData.map(({ text, value }) => (
                        <Button
                            key={value}
                            className={clsx({ current: tableTarget === value })}
                            onClick={() => onToggleTable(value)}
                        >
                            {text}
                        </Button>
                    ))}
            </ButtonGroup>
        </Stack>
    );
};

export default ReportTableHeader;
