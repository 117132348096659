import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import { v4 as uuid } from 'uuid';

const PrivateInformationBox = React.forwardRef((props, ref) => {
    let {
        defaultValue: defaultProps,
        placeholder: placeholderProps,
        value: valueProps,
        as: Component,
        onChange: onChangeProps,
        isError: propsError = false,
        required: requiredProps,
        ...other
    } = props;
    const [valueState, setValue] = useState({
        placeholder: placeholderProps,
        key: uuid(),
    });
    const [required, setRequired] = useState(false);
    const { placeholder, key, ...otherValue } = valueState;
    const isEditRef = useRef(false);
    const filedRef = useRef(null);

    const onChange = (e) => {
        const value = e.target.value;
        if (!requiredProps && !value) {
            setRequired(false);
        } else {
            setRequired(true);
            if (!isEditRef.current) {
                setValue((old) => ({
                    ...old,
                    placeholder: '',
                }));
            }
        }
        isEditRef.current = true;

        if (typeof onChangeProps === 'function') onChangeProps(...props);
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => {
                let isError = propsError;
                if (defaultProps && isEditRef.current && filedRef.current) {
                    isError = filedRef.current.isError();
                }

                return isError;
            },
            getResult: () => {
                let val;
                if (isEditRef.current) {
                    val = filedRef.current.getResult();
                } else {
                    val = defaultProps || valueProps;
                }
                return val;
            },
            getName: () => filedRef.current.name,
            getId: () => filedRef.current.id,
            node: () => filedRef.current,
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(() => {
        if (!defaultProps && !valueProps) return;

        if (defaultProps && valueProps) {
            throw new Error('value 與 defaultValue 擇一設定');
        }

        // 更新 required 狀態
        if (requiredProps && requiredProps !== required) {
            setRequired(requiredProps);
        } else {
            // 初始化正則表達式來檢測星號
            const reg = /\*+/gm;
            const newObj = {};

            // 如果 defaultProps 或 valueProps 包含星號，將其設為 placeholder
            if (reg.test(defaultProps || '') || reg.test(valueProps || '')) {
                newObj.placeholder = defaultProps || valueProps;
                // 更新 required 狀態
                setRequired(requiredProps);
            } else if (defaultProps) {
                newObj.defaultValue = defaultProps;
                // 必填
                setRequired(true);
            } else if (valueProps) {
                newObj.value = valueProps;
                // 必填
                setRequired(true);
            }

            setValue((old) => ({
                ...old,
                ...newObj,
                key: uuid(),
            }));
        }
        // eslint-disable-next-line
    }, [defaultProps, valueProps, requiredProps]); // 依賴列表

    return (
        <Component
            key={key}
            ref={filedRef}
            isError={propsError}
            {...other}
            {...otherValue}
            inputProps={{ placeholder: placeholder }}
            onChange={onChange}
            required={required}
        />
    );
});
export default PrivateInformationBox;
