import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const MoreHorizontal = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12.813,10A2.813,2.813,0,1,1,10,7.188,2.811,2.811,0,0,1,12.813,10Zm4.063-2.812A2.813,2.813,0,1,0,19.688,10,2.811,2.811,0,0,0,16.875,7.188Zm-13.75,0A2.813,2.813,0,1,0,5.938,10,2.811,2.811,0,0,0,3.125,7.188Z"
                transform="translate(1.688 1.813)"
            ></path>
        </SvgIcon>
    );
});

export default MoreHorizontal;
