import React, { useImperativeHandle, useRef } from 'react';
import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { FieldRow } from '@icoach/documents/components/Layout';
import { Alert, Button, TextField, CheckboxField } from '@common/components/';
import { LeaveTextField, PaidList } from '@icoach/documents/components';
import { formatCurrencyFn, getErrorModuleStatus, refIsRequiredError } from '@util/utils';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import { MultiplePaymentAmountPaidListGroup, PaidListNew, TotalPaidListGroup } from '@icoach/documents/components/PaidList';
import { getCheckboxValue, setCheckboxValue } from '@icoach/documents/tools';

// 月費變價資訊
const ChangeFeeRecordsContent = (props) => {
    const { data } = props;
    const { contractMonthTerm, membership, changeFeeRecords } = data;
    const FeeRecord = (props) => {
        const { monthlyFee, contractMonthTerm } = props;
        return (
            <React.Fragment>
                月費 <span className="text-info">{formatCurrencyFn(monthlyFee, { symbol: '$' })}</span> x 合約月數{' '}
                <span className="text-info">{contractMonthTerm}</span>
            </React.Fragment>
        );
    };
    const handleChangeFeeRecords = (data, changeFeeRecords) => {
        let records = [];
        let currentRecord = {};
        let currentMonthlyFee = data.monthlyFee;
        let contractMonthTerm = data.contractMonthTerm;

        if (!isEmpty(changeFeeRecords)) {
            changeFeeRecords.forEach((item) => {
                let itemRecord = {
                    monthlyFee: item.monthlyFee,
                    contractMonthTerm: item.usedTotalMonth,
                };
                contractMonthTerm = contractMonthTerm - item.usedTotalMonth;

                records.push(itemRecord);
            });
        }
        currentRecord.monthlyFee = currentMonthlyFee;
        currentRecord.contractMonthTerm = contractMonthTerm;
        records.push(currentRecord);

        return records;
    };
    const feeRecords = handleChangeFeeRecords({ contractMonthTerm, ...membership }, changeFeeRecords);

    if (feeRecords.length > 1) {
        return (
            <React.Fragment>
                {feeRecords
                    .map((item, i) => {
                        return (
                            <React.Fragment key={i}>
                                <FeeRecord {...item} />
                            </React.Fragment>
                        );
                    })
                    .reduce((prev, curr) => [prev, ' + ', curr])}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {feeRecords.map((item, i) => (
                <FeeRecord key={i} {...item} />
            ))}
        </React.Fragment>
    );
};

const TotalPaidListGroupByMembershipQuit = React.forwardRef((props, ref) => {
    const { data = {}, errorModule, isReadOnly } = props;
    const { cashPaymentAmount, cardPaymentAmount, totalPrice, isRefundCash } = data;
    const paymentRef = useRef(null);
    const isRefundCashRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getResult: () => {
            let result = {
                cashPaymentAmount,
                cardPaymentAmount,
                totalPrice,
            };

            if (paymentRef.current && paymentRef.current.getResult) {
                result = Object.assign(result, paymentRef.current.getResult());
            }

            if (isRefundCashRef.current && isRefundCashRef.current.getResult) {
                result = Object.assign(result, {
                    isRefundCash: getCheckboxValue(isRefundCashRef.current.getResult(), {
                        isMultiple: false,
                    }),
                });
            }
            return result;
        },
        isError: () => refIsRequiredError(paymentRef),
    }));

    return (
        <React.Fragment>
            {totalPrice < 1 ? (
                <TotalPaidListGroup total={totalPrice}>
                    {totalPrice < 0 && (
                        <React.Fragment>
                            <Alert className={'mt-2'} severity={'info'} variant={'filled'} color={'error'}>
                                此筆退款將合併於批次扣款刷退中一併退還予會員
                            </Alert>
                            <CheckboxField ref={isRefundCashRef} defaultValue={setCheckboxValue(isRefundCash)} readOnly={isReadOnly}>
                                <CheckboxField.Checkbox
                                    label="現場現金退款：若會員使用既存月費及ACH付款發票會直接折讓，若信用卡扣款則發票折讓但不會刷退"
                                    value={true}
                                />
                            </CheckboxField>
                        </React.Fragment>
                    )}
                </TotalPaidListGroup>
            ) : (
                <React.Fragment>
                    <MultiplePaymentAmountPaidListGroup
                        ref={paymentRef}
                        total={totalPrice}
                        cardPaymentAmountProps={{
                            defaultValue: cardPaymentAmount,
                            ...getErrorModuleStatus(errorModule, 'cardPaymentAmount'),
                        }}
                        cashPaymentAmountProps={{
                            defaultValue: cashPaymentAmount,
                            ...getErrorModuleStatus(errorModule, 'cashPaymentAmount'),
                        }}
                        readOnly={isReadOnly}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
});

// 102版 費用計算明細
export const MembershipQuitSettlementBy102 = React.forwardRef((props, ref) => {
    const { data, isReadOnly, errorModule, doDocMembershipQuitRangeFeeApi } = props;
    const {
        usedTotalMonth,
        unusedTotalMonth,
        avgMonthFee,
        monthlyFee,
        quitFee,
        processingFee,
        avgMonthFeeDiff,
        withSuspendRefund = 0,
        withExtensionRefund = 0,
        withholdingRefund = 0,
        finalRefund,
        isFreeQuitFee,
    } = data;
    const fieldRef = useRef({});
    const paymentRef = useRef(null);
    const isFreeQuitFeeRef = useRef(null);

    const handleChangeRangeFeeField = (e, key) => {
        let params = {};
        let value = e.target.value;
        if (e.target.type === 'checkbox') value = e.target.checked;
        params[key] = value;
        doDocMembershipQuitRangeFeeApi(params);
    };

    useImperativeHandle(ref, () => ({
        getResult: () => {
            const fieldObj = fieldRef.current;
            let result = {
                withSuspendRefund,
                withExtensionRefund,
                withholdingRefund,
            };

            if (paymentRef.current && paymentRef.current.getResult) {
                result = Object.assign(result, paymentRef.current.getResult());
            }

            if (isFreeQuitFeeRef.current && isFreeQuitFeeRef.current.getResult) {
                result = Object.assign(result, {
                    isFreeQuitFee: isFreeQuitFeeRef.current.getResult()[0].checked,
                });
            }

            if (fieldObj) {
                for (let key in fieldObj) {
                    const target = fieldObj[key];

                    if (target && target.getResult) {
                        let val = target.getResult();
                        if (key === 'contractMonthTerm') {
                            result.contractMonthTerm = val?.month ?? 0;
                        } else {
                            result[key] = val;
                        }
                    }
                }
            }

            return result;
        },
        isError: () => {
            const allObj = {
                ...fieldRef.current,
                payment: paymentRef.current,
            };
            return refIsRequiredError(Object.values(allObj));
        },
    }));
    return (
        <PaidListNew className="mb-3" title="差額費用計算">
            <PaidList.PaidListGroup>
                <PaidList.PaidListItem label="月平均差額">
                    <TextField
                        key={avgMonthFeeDiff}
                        ref={(el) => {
                            fieldRef.current.avgMonthFeeDiff = el;
                        }}
                        maskType={'MONEY'}
                        defaultValue={avgMonthFeeDiff}
                        {...getErrorModuleStatus(errorModule, 'avgMonthFeeDiff')}
                        disabled
                    />
                </PaidList.PaidListItem>
                <PaidList.PaidListItem>
                    <div className="fee-tip">
                        (月費 <span className="text-info">{formatCurrencyFn(monthlyFee, { symbol: '$' })}</span> - 月平均價格{' '}
                        <span className="text-info">{formatCurrencyFn(avgMonthFee, { symbol: '$' })}</span>) x 已使用月數{' '}
                        <span className="text-info">{usedTotalMonth}</span>
                    </div>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="終止手續費">
                    <TextField
                        key={quitFee}
                        ref={(el) => {
                            fieldRef.current.quitFee = el;
                        }}
                        maskType={'MONEY'}
                        defaultValue={quitFee}
                        {...getErrorModuleStatus(errorModule, 'quitFee')}
                        disabled
                    />
                </PaidList.PaidListItem>
                <PaidList.PaidListItem>
                    <div className="fee-tip">
                        月平均價格 <span className="text-info">{formatCurrencyFn(avgMonthFee, { symbol: '$' })}</span> x 未履約月數{' '}
                        <span className="text-info">{unusedTotalMonth}</span> x <span className="text-info">20%</span>
                    </div>
                </PaidList.PaidListItem>
            </PaidList.PaidListGroup>
            <PaidList.PaidListGroup>
                <PaidList.PaidListItem label="終止費用總計" className={'font-weight-bold'}>
                    <strong className="paid-list-cell">{formatCurrencyFn(finalRefund)}</strong>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="" className={'mt-0'}>
                    <CheckboxField
                        ref={isFreeQuitFeeRef}
                        defaultChecked={setCheckboxValue(!!isFreeQuitFee)}
                        onChange={(e, v) => handleChangeRangeFeeField(e, 'isFreeQuitFee')}
                    >
                        <CheckboxField.Checkbox label={'免收終止費用'} />
                    </CheckboxField>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem className={'mt-0'}>
                    <div className="fee-tip">
                        最多向會員收取 <span className="text-info">{formatCurrencyFn(3600, { symbol: '$' })}</span>
                    </div>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="已扣月費退費">
                    <TextField
                        maskType={'MONEY'}
                        key={(withholdingRefund + withExtensionRefund + withSuspendRefund) * -1}
                        defaultValue={(withholdingRefund + withExtensionRefund + withSuspendRefund) * -1}
                        disabled
                    />
                </PaidList.PaidListItem>
                <PaidList.PaidListItem>
                    <div className="fee-tip">
                        當月已扣月費退回 <span className="text-info">{formatCurrencyFn(withholdingRefund, { symbol: '$' })}</span> + 已扣延展退費{' '}
                        <span className="text-info">
                            {formatCurrencyFn(withExtensionRefund + withSuspendRefund, {
                                symbol: '$',
                            })}
                        </span>
                    </div>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="工本費">
                    <TextField
                        key={processingFee}
                        maskType={'MONEY'}
                        ref={(el) => {
                            fieldRef.current.processingFee = el;
                        }}
                        defaultValue={processingFee}
                        onChangeNative={(e) => handleChangeRangeFeeField(e, 'processingFee')}
                        {...getErrorModuleStatus(errorModule, 'processingFee')}
                        readOnly={isReadOnly}
                    />
                </PaidList.PaidListItem>
            </PaidList.PaidListGroup>
            <TotalPaidListGroupByMembershipQuit ref={paymentRef} data={data} errorModule={errorModule} isReadOnly={isReadOnly} />
        </PaidListNew>
    );
});
// 102版 試算表
export const MembershipQuitTrialCalculationBy102 = React.forwardRef((props, ref) => {
    const { data = {}, isReadOnly, errorModule, handleOpenDialog, doDocMembershipQuitRangeFeeApi } = props;
    const {
        firstMonthDay,
        suspendDay,
        suspendMonth,
        giftDay,
        giftMonth,
        giftTotalMonth,
        contractMonthTerm,
        totalMonth,
        firstTotalMonth,
        usedDay,
        usedMonth,
        unusedDay,
        unusedMonth,
        unusedExtensionDay,
        unusedExtensionMonth,
        clubBreakDay,
        clubBreakMonth,
        avgMonthFee,
        firstMonthFee,
        lastMonthlyFee,
    } = data;
    const fieldRef = useRef({});
    const dateRef = useRef({});

    const getResult = () => {
        let fieldObj = fieldRef.current;
        let dateObj = dateRef.current;
        let result = {};

        if (fieldObj) {
            for (let key in fieldObj) {
                const target = fieldObj[key];
                if (target && target.getResult) {
                    let val = target.getResult();
                    if (key === 'contractMonthTerm') {
                        result.contractMonthTerm = val?.month ?? 0;
                    } else {
                        result[key] = val;
                    }
                }
            }
        }

        if (dateObj) {
            for (let key in dateObj) {
                const target = dateObj[key];
                if (target && target.getResult) {
                    const { day, month } = target.getResult();
                    if (day) {
                        result[`${key}Day`] = day;
                    }

                    if (month) {
                        result[`${key}Month`] = month;
                    }
                }
            }
        }

        return result;
    };

    const checkError = () => {
        const allObj = {
            ...fieldRef.current,
            ...dateRef.current,
        };
        return refIsRequiredError(Object.values(allObj));
    };

    const handleQuitCalculation = () => {
        let isError = checkError();
        if (!isError) {
            const params = getResult();
            doDocMembershipQuitRangeFeeApi(params);
        }
    };

    useImperativeHandle(ref, () => ({
        getResult,
        isError: checkError,
    }));

    return (
        <Grid container spacing={3}>
            <FieldRow items={[4, 8]} label="當月已收月費" required>
                <TextField
                    maskType={'MONEY'}
                    ref={(el) => {
                        fieldRef.current.lastMonthlyFee = el;
                    }}
                    key={lastMonthlyFee}
                    defaultValue={lastMonthlyFee}
                    {...getErrorModuleStatus(errorModule, 'lastMonthlyFee')}
                    readOnly={isReadOnly}
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label="破月日" required>
                <TextField
                    type={'number'}
                    ref={(el) => (fieldRef.current.firstMonthDay = el)}
                    key={firstMonthDay}
                    defaultValue={firstMonthDay}
                    {...getErrorModuleStatus(errorModule, 'firstMonthDay')}
                    readOnly={isReadOnly}
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label="會員權暫停" required>
                <LeaveTextField
                    ref={(el) => (dateRef.current.suspend = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: suspendDay,
                        defaultValue: suspendDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: suspendMonth,
                        defaultValue: suspendMonth,
                    }}
                    readOnly={isReadOnly}
                    isShowDay
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]}>
                <Button variant="outlined" color="secondary" className="btn-minWidth" fullWidth onClick={() => handleOpenDialog(dialogTypeMap.suspension)}>
                    查看暫停紀錄
                </Button>
            </FieldRow>
            <FieldRow items={[4, 8]} label="贈送" required>
                <LeaveTextField
                    ref={(el) => (dateRef.current.gift = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: giftDay,
                        defaultValue: giftDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: giftMonth,
                        defaultValue: giftMonth,
                    }}
                    readOnly={isReadOnly}
                    isShowDay
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]}>
                <Button variant="outlined" color="secondary" className="btn-minWidth" fullWidth onClick={() => handleOpenDialog(dialogTypeMap.gift)}>
                    查看贈送紀錄
                </Button>
            </FieldRow>
            <FieldRow items={[4, 8]} label="延展" required>
                <LeaveTextField
                    ref={(el) => (dateRef.current.unusedExtension = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: unusedExtensionDay,
                        defaultValue: unusedExtensionDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: unusedExtensionMonth,
                        defaultValue: unusedExtensionMonth,
                    }}
                    readOnly={isReadOnly}
                    isShowDay
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]}>
                <Button variant="outlined" color="secondary" className="btn-minWidth" fullWidth onClick={() => handleOpenDialog(dialogTypeMap.extension)}>
                    查看延展紀錄
                </Button>
            </FieldRow>
            <FieldRow items={[4, 8]} label="店休" required>
                <LeaveTextField
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: clubBreakDay,
                        defaultValue: clubBreakDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: clubBreakMonth,
                        defaultValue: clubBreakMonth,
                    }}
                    disabled
                    isShowDay
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label="合約總共" required>
                <LeaveTextField
                    ref={(el) => (fieldRef.current.contractMonthTerm = el)}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: contractMonthTerm,
                        defaultValue: contractMonthTerm,
                    }}
                    readOnly={isReadOnly}
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label="契約總共">
                <LeaveTextField
                    ref={(el) => (dateRef.current.totalMonth = el)}
                    monthProps={{
                        type: 'number',
                        key: totalMonth,
                        defaultValue: totalMonth,
                    }}
                    fullWidth
                    disabled
                />
            </FieldRow>
            <FieldRow items={[4, 8]}>
                <div className="fee-tip">
                    合約月數 <span className="text-info">{contractMonthTerm}</span> + 贈送月數 <span className="text-info">{giftTotalMonth}</span> +
                    合約始期破月數 <span className="text-info">{firstTotalMonth}</span>
                </div>
            </FieldRow>
            <FieldRow items={[4, 8]} label="月平均金額">
                <TextField
                    maskType={'MONEY'}
                    ref={(el) => (fieldRef.current.avgMonthFee = el)}
                    key={avgMonthFee}
                    defaultValue={avgMonthFee}
                    {...getErrorModuleStatus(errorModule, 'avgMonthFee')}
                    fullWidth
                    disabled
                />
            </FieldRow>
            <FieldRow items={[4, 8]}>
                <div className="fee-tip">
                    (<ChangeFeeRecordsContent data={data} /> + 破月費金額 <span className="text-info">{formatCurrencyFn(firstMonthFee, { symbol: '$' })}</span>
                    {') '}/ 契約月數 <span className="text-info">{totalMonth}</span>
                </div>
            </FieldRow>
            <FieldRow items={[4, 8]} label="已使用">
                <LeaveTextField
                    ref={(el) => (dateRef.current.used = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: usedDay,
                        defaultValue: usedDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: usedMonth,
                        defaultValue: usedMonth,
                    }}
                    isShowDay
                    fullWidth
                    disabled
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label="未履約">
                <LeaveTextField
                    ref={(el) => (dateRef.current.unused = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: unusedDay,
                        defaultValue: unusedDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: unusedMonth,
                        defaultValue: unusedMonth,
                    }}
                    isShowDay
                    fullWidth
                    disabled
                />
            </FieldRow>
            {!isReadOnly && (
                <FieldRow items={[4, 8]}>
                    <Button variant="contained" color="secondary" className="btn-minWidth" onClick={handleQuitCalculation} readOnly={isReadOnly} fullWidth>
                        計算
                    </Button>
                </FieldRow>
            )}
        </Grid>
    );
});

// 101版 費用計算明細
export const MembershipQuitSettlementBy101 = React.forwardRef((props, ref) => {
    const { data, isReadOnly, errorModule, doDocMembershipQuitRangeFeeApi } = props;
    const {
        contractMonthTerm,
        usedTotalMonth,
        unusedTotalMonth,
        monthlyFee,
        processingFee,
        joinFee,
        refundJoinFee,
        payDifference,
        originalMonthlyFee,
        withSuspendRefund = 0,
        withExtensionRefund = 0,
        withholdingRefund = 0,
        finalRefund,
        isFreeQuitFee,
    } = data;
    const fieldRef = useRef({});
    const paymentRef = useRef(null);
    const isFreeQuitFeeRef = useRef(null);

    const handleChangeRangeFeeField = (e, key) => {
        let params = {};
        let value = e.target.value;
        if (e.target.type === 'checkbox') value = e.target.checked;
        params[key] = value;
        doDocMembershipQuitRangeFeeApi(params);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const fieldObj = fieldRef.current;
                let result = {
                    withSuspendRefund,
                    withExtensionRefund,
                    withholdingRefund,
                };

                if (paymentRef.current && paymentRef.current.getResult) {
                    result = Object.assign(result, paymentRef.current.getResult());
                }

                if (isFreeQuitFeeRef.current && isFreeQuitFeeRef.current.getResult) {
                    result = Object.assign(result, {
                        isFreeQuitFee: isFreeQuitFeeRef.current.getResult()[0].checked,
                    });
                }

                if (fieldObj) {
                    for (let key in fieldObj) {
                        const target = fieldObj[key];

                        if (target && target.getResult) {
                            let val = target.getResult();
                            if (key === 'contractMonthTerm') {
                                result.contractMonthTerm = val?.month ?? 0;
                            } else {
                                result[key] = val;
                            }
                        }
                    }
                }

                return result;
            },
            isError: () => {
                const allObj = {
                    ...fieldRef.current,
                    payment: paymentRef.current,
                };
                return refIsRequiredError(Object.values(allObj));
            },
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <PaidListNew className="mb-3" title="差額費用計算">
            <PaidList.PaidListGroup>
                <PaidList.PaidListItem label="入會費退費">
                    <TextField
                        key={refundJoinFee}
                        ref={(el) => {
                            fieldRef.current.refundJoinFee = el;
                        }}
                        maskType={'MONEY'}
                        defaultValue={refundJoinFee}
                        {...getErrorModuleStatus(errorModule, 'refundJoinFee')}
                        disabled
                    />
                </PaidList.PaidListItem>
                <PaidList.PaidListItem>
                    <div className="fee-tip">
                        (入會費 <span className="text-info">{formatCurrencyFn(joinFee, { symbol: '$' })}</span> / 合約總月數{' '}
                        <span className="text-info">{contractMonthTerm}</span>) * 未使用月數 <span className="text-info">{unusedTotalMonth}</span>
                    </div>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="月費補差額">
                    <TextField
                        key={payDifference}
                        ref={(el) => {
                            fieldRef.current.payDifference = el;
                        }}
                        maskType={'MONEY'}
                        defaultValue={payDifference}
                        {...getErrorModuleStatus(errorModule, 'payDifference')}
                        disabled
                    />
                </PaidList.PaidListItem>
                <PaidList.PaidListItem>
                    <div className="fee-tip">
                        (月費定價 <span className="text-info">{formatCurrencyFn(originalMonthlyFee, { symbol: '$' })}</span> - 月費金額{' '}
                        <span className="text-info">{formatCurrencyFn(monthlyFee, { symbol: '$' })}</span>
                        {') '}x 實際經過月數 <span className="text-info">{usedTotalMonth}</span>
                    </div>
                </PaidList.PaidListItem>
            </PaidList.PaidListGroup>
            <PaidList.PaidListGroup>
                <PaidList.PaidListItem label="終止費用總計" className={'font-weight-bold'}>
                    <strong className="paid-list-cell">{formatCurrencyFn(finalRefund, { symbol: '$' })}</strong>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="" className={'mt-0'}>
                    <CheckboxField
                        ref={isFreeQuitFeeRef}
                        defaultChecked={setCheckboxValue(!!isFreeQuitFee)}
                        onChange={(e, v) => handleChangeRangeFeeField(e, 'isFreeQuitFee')}
                    >
                        <CheckboxField.Checkbox label={'免收終止費用'} />
                    </CheckboxField>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem className={'mt-0'}>
                    <div className="fee-tip">
                        最多向會員收取 <span className="text-info">{formatCurrencyFn(3600, { symbol: '$' })}</span>
                    </div>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="已扣月費退費">
                    <TextField
                        maskType={'MONEY'}
                        key={(withholdingRefund + withExtensionRefund + withSuspendRefund) * -1}
                        defaultValue={(withholdingRefund + withExtensionRefund + withSuspendRefund) * -1}
                        disabled
                    />
                </PaidList.PaidListItem>
                <PaidList.PaidListItem>
                    <div className="fee-tip">
                        當月已扣月費退回 <span className="text-info">{formatCurrencyFn(withholdingRefund, { symbol: '$' })}</span> + 已扣延展退費{' '}
                        <span className="text-info">
                            {formatCurrencyFn(withExtensionRefund + withSuspendRefund, {
                                symbol: '$',
                            })}
                        </span>
                    </div>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="工本費">
                    <TextField
                        key={processingFee}
                        maskType={'MONEY'}
                        ref={(el) => {
                            fieldRef.current.processingFee = el;
                        }}
                        defaultValue={processingFee}
                        onChangeNative={(e) => handleChangeRangeFeeField(e, 'processingFee')}
                        {...getErrorModuleStatus(errorModule, 'processingFee')}
                        readOnly={isReadOnly}
                    />
                </PaidList.PaidListItem>
            </PaidList.PaidListGroup>
            <TotalPaidListGroupByMembershipQuit data={data} errorModule={errorModule} isReadOnly={isReadOnly} />
        </PaidListNew>
    );
});

// 101版 試算表
export const MembershipQuitTrialCalculationBy101 = React.forwardRef((props, ref) => {
    const { data = {}, isReadOnly, errorModule, handleOpenDialog, doDocMembershipQuitRangeFeeApi } = props;
    const {
        firstMonthDay,
        suspendDay,
        suspendMonth,
        giftDay,
        giftMonth,
        contractMonthTerm,
        usedDay,
        usedMonth,
        unusedDay,
        unusedMonth,
        unusedExtensionDay,
        unusedExtensionMonth,
        clubBreakDay,
        clubBreakMonth,
        lastMonthlyFee,
    } = data;
    const fieldRef = useRef({});
    const dateRef = useRef({});

    const getResult = () => {
        let fieldObj = fieldRef.current;
        let dateObj = dateRef.current;
        let result = {};

        if (fieldObj) {
            for (let key in fieldObj) {
                const target = fieldObj[key];

                if (target && target.getResult) {
                    let val = target.getResult();
                    if (key === 'contractMonthTerm') {
                        result.contractMonthTerm = val?.month ?? 0;
                    } else {
                        result[key] = val;
                    }
                }
            }
        }

        if (dateObj) {
            for (let key in dateObj) {
                const target = dateObj[key];
                if (target && target.getResult) {
                    const { day, month } = target.getResult();
                    if (day) {
                        result[`${key}Day`] = day;
                    }

                    if (month) {
                        result[`${key}Month`] = month;
                    }
                }
            }
        }

        return result;
    };

    const checkError = () => {
        const allObj = {
            ...fieldRef.current,
            ...dateRef.current,
        };
        return refIsRequiredError(Object.values(allObj));
    };

    const handleQuitCalculation = () => {
        let isError = checkError();
        if (!isError) {
            const params = getResult();
            doDocMembershipQuitRangeFeeApi(params);
        }
    };

    useImperativeHandle(ref, () => ({
        getResult,
        isError: checkError,
    }));

    return (
        <Grid container spacing={3}>
            <FieldRow items={[4, 8]} label="當月已收月費" required>
                <TextField
                    maskType={'MONEY'}
                    ref={(el) => {
                        fieldRef.current.lastMonthlyFee = el;
                    }}
                    key={lastMonthlyFee}
                    defaultValue={lastMonthlyFee}
                    {...getErrorModuleStatus(errorModule, 'lastMonthlyFee')}
                    readOnly={isReadOnly}
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label="破月日" required>
                <TextField
                    type={'number'}
                    ref={(el) => (fieldRef.current.firstMonthDay = el)}
                    key={firstMonthDay}
                    defaultValue={firstMonthDay}
                    {...getErrorModuleStatus(errorModule, 'firstMonthDay')}
                    readOnly={isReadOnly}
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label="會員權暫停" required>
                <LeaveTextField
                    ref={(el) => (dateRef.current.suspend = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: suspendDay,
                        defaultValue: suspendDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: suspendMonth,
                        defaultValue: suspendMonth,
                    }}
                    readOnly={isReadOnly}
                    isShowDay
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]}>
                <Button variant="outlined" color="secondary" className="btn-minWidth" fullWidth onClick={() => handleOpenDialog(dialogTypeMap.suspension)}>
                    查看暫停紀錄
                </Button>
            </FieldRow>
            <FieldRow items={[4, 8]} label="贈送" required>
                <LeaveTextField
                    ref={(el) => (dateRef.current.gift = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: giftDay,
                        defaultValue: giftDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: giftMonth,
                        defaultValue: giftMonth,
                    }}
                    readOnly={isReadOnly}
                    isShowDay
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]}>
                <Button variant="outlined" color="secondary" className="btn-minWidth" fullWidth onClick={() => handleOpenDialog(dialogTypeMap.gift)}>
                    查看贈送紀錄
                </Button>
            </FieldRow>
            <FieldRow items={[4, 8]} label="延展" required>
                <LeaveTextField
                    ref={(el) => (dateRef.current.unusedExtension = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: unusedExtensionDay,
                        defaultValue: unusedExtensionDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: unusedExtensionMonth,
                        defaultValue: unusedExtensionMonth,
                    }}
                    readOnly={isReadOnly}
                    isShowDay
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]}>
                <Button variant="outlined" color="secondary" className="btn-minWidth" fullWidth onClick={() => handleOpenDialog(dialogTypeMap.extension)}>
                    查看延展紀錄
                </Button>
            </FieldRow>
            <FieldRow items={[4, 8]} label="店休" required>
                <LeaveTextField
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: clubBreakDay,
                        defaultValue: clubBreakDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: clubBreakMonth,
                        defaultValue: clubBreakMonth,
                    }}
                    disabled
                    isShowDay
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label={'合約總共'} required>
                <LeaveTextField
                    ref={(el) => (fieldRef.current.contractMonthTerm = el)}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: contractMonthTerm,
                        defaultValue: contractMonthTerm,
                    }}
                    readOnly={isReadOnly}
                    fullWidth
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label="已使用">
                <LeaveTextField
                    ref={(el) => (dateRef.current.used = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: usedDay,
                        defaultValue: usedDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: usedMonth,
                        defaultValue: usedMonth,
                    }}
                    isShowDay
                    fullWidth
                    disabled
                />
            </FieldRow>
            <FieldRow items={[4, 8]} label="未履約">
                <LeaveTextField
                    ref={(el) => (dateRef.current.unused = el)}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: unusedDay,
                        defaultValue: unusedDay,
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                        key: unusedMonth,
                        defaultValue: unusedMonth,
                    }}
                    isShowDay
                    fullWidth
                    disabled
                />
            </FieldRow>
            {!isReadOnly && (
                <FieldRow items={[4, 8]}>
                    <Button variant="contained" color="secondary" className="btn-minWidth" onClick={handleQuitCalculation} readOnly={isReadOnly} fullWidth>
                        計算
                    </Button>
                </FieldRow>
            )}
        </Grid>
    );
});
