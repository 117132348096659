import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Todo = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#6E488B', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M7.1,18.5h9.5l0,0h0v-4.4c-1,1-2,2.1-2.8,3c-0.3,0.4-0.8,0.2-1,0.1l-1.5-1.3c-0.3-0.2-0.3-0.7,0-0.9
		c0.2-0.3,0.7-0.3,0.9,0l0.9,0.9c0.7-0.8,1.9-2.3,3.4-3.5V5.4H7.1V18.5z M14.3,10.8H9.4c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h4.9
		c0.4,0,0.7,0.3,0.7,0.7S14.7,10.8,14.3,10.8z M9.4,6.8h4.9C14.7,6.8,15,7,15,7.4s-0.3,0.7-0.7,0.7H9.4c-0.4,0-0.7-0.3-0.7-0.7
		S9.1,6.8,9.4,6.8z M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z M20.8,11.5
		c-0.8-0.1-1.8,0.6-2.9,1.4v6.2c0,0.4-0.3,0.7-0.7,0.7H6.5c-0.4,0-0.7-0.3-0.7-0.7V4.8c0-0.4,0.3-0.7,0.7-0.7h10.8
		c0.4,0,0.7,0.3,0.7,0.7v6.5c1-0.7,2.1-1.2,3-1.1l0,0c0.4,0,0.6,0.3,0.6,0.7C21.5,11.3,21.2,11.5,20.8,11.5z"
            />
        </SvgIcon>
    );
});

export default Todo;
