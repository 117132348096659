import React, { useEffect, useState, useRef } from 'react';
import ConfigurationOthersReadContent from './ConfigurationOthersReadContent';
import ConfigurationOthersFormContent from './ConfigurationOthersFormContent';
import {
    Edit as EditIcon,
    Reply as ReplyIcon,
    SuccessOutlined as SuccessOutlinedIcon,
} from '../../../components/icons/SvgIcon/';
import { Stack, Card, Button } from '../../../components/';
import useClubApi from '../../../apis/useClubApi';

const ConfigurationOthersPage = React.forwardRef((props, ref) => {
    const { getClubOthersParamsDataApi, putClubOthersParamsDataApi } = useClubApi();
    const [isRead, setRead] = useState(true);
    const [sourceData, setSourceData] = useState({});
    const configurationOthersFormContentRef = useRef(null);
    const pdClass = `px-5 py-2`;

    const getParams = () => {
        return { ...configurationOthersFormContentRef.current.getResult() };
    };

    const handleEditClick = () => {
        setRead(false);
    };

    const handleReturnRead = () => {
        // console.log(configurationOthersFormContentRef.current);
        setRead(true);
    };

    const handleSendClick = () => {
        const params = getParams();
        editOthersParamsDataApi(params);
    };

    const editOthersParamsDataApi = async (params) => {
        const resp = await putClubOthersParamsDataApi(params);
        if (resp) {
            setTimeout(initClubDataApi, 500);
            setRead(true);
        }
    };

    const initClubDataApi = async () => {
        const resp = await getClubOthersParamsDataApi();
        if (resp) {
            setSourceData(resp);
        }
    };

    useEffect(
        () => {
            initClubDataApi();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Card>
            <Stack className={`px-5 pt-2`} justifyContent="end">
                {isRead ? (
                    <Button startIcon={<EditIcon />} variant="outlined" onClick={handleEditClick}>
                        編輯
                    </Button>
                ) : (
                    <Stack spacing={2}>
                        <Button
                            startIcon={<ReplyIcon />}
                            variant="outlined"
                            onClick={handleReturnRead}
                        >
                            返回
                        </Button>
                        <Button
                            startIcon={<SuccessOutlinedIcon />}
                            variant="contained"
                            onClick={handleSendClick}
                        >
                            儲存
                        </Button>
                    </Stack>
                )}
            </Stack>
            {isRead ? (
                <ConfigurationOthersReadContent sourceData={sourceData || {}} className={pdClass} />
            ) : (
                <ConfigurationOthersFormContent
                    ref={configurationOthersFormContentRef}
                    sourceData={sourceData || {}}
                    className={pdClass}
                />
            )}
        </Card>
    );
});

export default ConfigurationOthersPage;
