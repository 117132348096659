import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { IconButton, Box } from '@common/components/';
import { Heartbeat as HeartbeatIcon } from '@common/SvgIcon/';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { pathRouterShit } from '@util/utils';
import useShowPDF from '@util/hook/useShowPDF';
import useMessageDialog from '@util/hook/useMessageDialog';
import { DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER } from '@icoach/router/routerPath';

const MemberDetailHealthCareDoc = React.memo(
    React.forwardRef((props, ref) => {
        const [pdfModel, setPdfModel] = useState(null);
        const { memberID } = props;
        const history = useHistory();
        const showPDF = useShowPDF();
        const setMessageDialog = useMessageDialog();
        const { getMemberProfileHealthDeclarationApi } = useMemberProfileApi();

        const handleHeartbeatOnClick = () => {
            const { isContract, url } = pdfModel;
            if (isContract) {
                showPDF({
                    open: true,
                    pdfUrl: url,
                });
            } else {
                setMessageDialog({
                    open: true,
                    title: '確認要轉跳？',
                    msg: '尚無填寫健康申告書，確定要轉跳到健康申告書總覽？',
                    onAsyncConfirm: handleGoToDocHealthDeclaration,
                });
            }
        };

        const handleGoToDocHealthDeclaration = (e, onClose) => {
            const parmas = {
                peopleType: 2,
                referenceID: memberID,
            };
            const path = pathRouterShit(DOCUMENTS_HEALTHDECLARATION_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER, parmas);
            onClose && onClose();
            history.push(path);
        };

        const initGetHasPDFApi = async (id) => {
            const resp = await getMemberProfileHealthDeclarationApi(id);
            if (resp) {
                setPdfModel(resp);
            }
        };

        useEffect(
            () => {
                initGetHasPDFApi(memberID);
            },
            // eslint-disable-next-line
            []
        );

        if (!pdfModel) {
            return null;
        }

        return (
            <Box>
                <IconButton color={clsx(pdfModel.isContract ? 'error' : 'gray')} onClick={handleHeartbeatOnClick}>
                    <HeartbeatIcon />
                </IconButton>
            </Box>
        );
    })
);

export default MemberDetailHealthCareDoc;
