import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button, Form, FormControlLabel, Stack, Switch, Typography } from '../../../../components';
import GradientColorHeader from '../../../components/dialog/GradientColorHeader';
import { refIsRequiredError } from '../../../../util/utils';
import { useMemberProfileApi } from '../../../../apis/useMemberProfileApi';

const SwitchField = React.forwardRef((props, ref) => {
    const { title, defaultChecked } = props;
    const [checked, setChecked] = useState(defaultChecked);
    const switchRef = useRef();

    const handleChange = (e, checked) => {
        setChecked(checked);
    };

    useEffect(() => {
        setChecked(defaultChecked);
    }, [defaultChecked]);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => checked,
            isError: refIsRequiredError(switchRef),
        }),
        [checked]
    );

    return (
        <Stack className={'notify-item'} justifyContent={'center'} alignItems={'center'}>
            <Typography className={'mb-0 mr-2 font-weight-bold'}>{title}</Typography>
            <FormControlLabel
                onChange={handleChange}
                control={<Switch defaultChecked={checked} inputProps={{ ref: switchRef }} />}
                label={checked ? '開啟' : '關閉'}
            />
        </Stack>
    );
});

const NotificationDialogContent = forwardRef((props, ref) => {
    const { defaultValue } = props;
    const smsRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return { enabled: smsRef.current && smsRef.current.getResult() };
            },
            isError: () => refIsRequiredError(smsRef),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <DialogContent className={'p-5'}>
            <SwitchField ref={smsRef} title={'簡訊通知'} defaultChecked={defaultValue} />
            {/*<SwitchField*/}
            {/*    ref={(el) => (NotifyRef.current[1] = el)}*/}
            {/*    id={2 ** 1}*/}
            {/*    title={'Email'}*/}
            {/*    defaultChecked={!data.includes(2 ** 1)}*/}
            {/*/>*/}
            {/*<SwitchField*/}
            {/*    ref={(el) => (NotifyRef.current[2] = el)}*/}
            {/*    id={2 ** 2}*/}
            {/*    title={'Line'}*/}
            {/*    defaultChecked={!data.includes(2 ** 2)}*/}
            {/*/>*/}
        </DialogContent>
    );
});

const NotificationDialog = (props) => {
    const { className, open, memberID, data, onClose, refresh } = props;
    const { putMemberProfileNotifyApi } = useMemberProfileApi();
    const contentRef = useRef();

    const doMemberProfileNotifyApi = async (memberID, params) => {
        let res = await putMemberProfileNotifyApi(memberID, params);
        if (res) {
            refresh && refresh(memberID);
            onClose();
        }
    };
    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = contentRef.current && contentRef.current.getResult();
            doMemberProfileNotifyApi(memberID, params);
        }
    };
    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-40rem' }}
            open={open}
            fullWidth
        >
            <Form onSubmit={handleSubmit}>
                <GradientColorHeader onClose={onClose}>通知設定</GradientColorHeader>
                <NotificationDialogContent ref={contentRef} defaultValue={data} />
                <DialogActions>
                    <Button type={'submit'} variant="contained">
                        確認送出
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default NotificationDialog;
