import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';
import useShowLoading from '@util/hook/useShowLoading';

const GET_ORDER_BATCH_PAYMENT_OPTIONS_API = '/api/orders/batch-payments/options/';
const GET_ORDER_BATCH_PAYMENT_API = '/api/orders/batch-payments/';
const POST_ORDER_BATCH_PAYMENT_EXPORT_ORDER_API = '/api/orders/batch-payments/export-order/';
const GET_ORDER_BATCH_PAYMENT_BILLING_API = (billingID) => `/api/orders/batch-payments/${billingID}/`;
const DELETE_ORDER_BATCH_PAYMENT_BILLING_API = GET_ORDER_BATCH_PAYMENT_BILLING_API;
const POST_ORDER_BATCH_PAYMENT_CONFIRM_API = '/api/orders/batch-payments/confirm/';
const GET_ORDER_BATCH_PAYMENT_ACH_EXPORT_API = (billingID) => `/api/orders/batch-payments/${billingID}/export-ach/`;
const POST_ORDER_BATCH_PAYMENT_ACH_IMPORT_API = (billingID) => `/api/orders/batch-payments/${billingID}/import-ach/`;

const useOrderBatchPaymentApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();
    const showLoading = useShowLoading();

    // 取得批次扣款下拉式選單
    const getOrderBatchPaymentOptionsApi = useCallback(
        async () => {
            let resp = await Ajax.get(GET_ORDER_BATCH_PAYMENT_OPTIONS_API);

            if (!resp) {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得批次扣款的群組清單
    const getOrderBatchPaymentApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_ORDER_BATCH_PAYMENT_API, params);

            if (!resp) {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 匯出選取的訂單結果EXCEL
    const postOrderBatchPaymentExportOrderApi = useCallback(
        async (parameters) => {
            let resp = await Ajax.post(POST_ORDER_BATCH_PAYMENT_EXPORT_ORDER_API, parameters, {
                headerOption: { responseType: 'blob' },
            });

            if (!resp) {
                enqueueSnackbar('匯出失敗', {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar('匯出成功', {
                    variant: 'success',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得單一批次群組的扣款清單
    const getOrderBatchPaymentBillingApi = useCallback(
        async (billingID) => {
            let resp = await Ajax.get(GET_ORDER_BATCH_PAYMENT_BILLING_API(billingID));

            if (!resp) {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得批次扣款下拉式選單
    // 取得單一批次群組的扣款清單
    const getInitBatchPaymentBillingApi = useCallback(
        async (billingID) => {
            let result = {};
            showLoading(true);
            const options = await Ajax.get(GET_ORDER_BATCH_PAYMENT_OPTIONS_API);
            const billingData = await Ajax.get(GET_ORDER_BATCH_PAYMENT_BILLING_API(billingID));
            if (!options || !billingData) {
                enqueueSnackbar('讀取資料失敗', {
                    variant: 'error',
                });
            }
            if (options) result = Object.assign(result, { resource: options });
            if (billingData) result = Object.assign(result, { billingData });
            showLoading(false);
            return result;
        },
        // eslint-disable-next-line
        []
    );

    // 刪除
    const deleteOrderBatchPaymentBillingApi = useCallback(
        async (billingID) => {
            let resp = await Ajax.delete(DELETE_ORDER_BATCH_PAYMENT_BILLING_API(billingID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增扣款訂單至批次結帳群組(確認寫入)
    const postOrderBatchPaymentConfirmApi = useCallback(
        async (parameters) => {
            let resp = await Ajax.post(POST_ORDER_BATCH_PAYMENT_CONFIRM_API, parameters);

            if (!resp) {
                enqueueSnackbar('批次扣款群組建立失敗', {
                    variant: 'error',
                });
            } else {
                enqueueSnackbar('成功建立批次扣款群組', {
                    variant: 'success',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 匯出ACH結帳EXCEL
    const getOrderBatchPaymentAchExportApi = useCallback(
        async (billingID) => {
            showLoading(true);
            await window.open(GET_ORDER_BATCH_PAYMENT_ACH_EXPORT_API(billingID), '_blank');
            showLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    // 匯入ACH結帳EXCEL
    const postOrderBatchPaymentAchImportApi = useCallback(
        async (billingID, formData) => {
            let resp = await Ajax.post(POST_ORDER_BATCH_PAYMENT_ACH_IMPORT_API(billingID), formData, {
                headerOption: {
                    contentType: false,
                    processData: false,
                },
            });

            if (!resp) {
                enqueueSnackbar('上傳檔案失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getOrderBatchPaymentOptionsApi,
        getOrderBatchPaymentApi,
        postOrderBatchPaymentExportOrderApi,
        getOrderBatchPaymentBillingApi,
        getInitBatchPaymentBillingApi,
        deleteOrderBatchPaymentBillingApi,
        postOrderBatchPaymentConfirmApi,
        getOrderBatchPaymentAchExportApi,
        postOrderBatchPaymentAchImportApi,
    };
};

export default useOrderBatchPaymentApi;
