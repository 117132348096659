import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { getErrorModuleStatus, refIsRequiredError } from '@util/utils';
import { Alert, CheckboxField, MenuItem, RadioField, SelectField, TextField } from '@common/components/';
import { INVOICE_ISSUING_TYPE_OPTIONS } from '@icoach/documents/components/InvoiceCard';
import { getRadioValue, setCheckboxValue } from '@icoach/documents/tools';

const defaultDonate = 9118595;

const defaultDonateOptions = [
    {
        text: `勵馨基金會 ${defaultDonate}`,
        value: `${defaultDonate}`,
    },
];

/**
 * 2聯發票開立方式
 * 1. 會員載具
 * 2. 手機載具
 * 3. 自然人憑贈
 * 4. 簡訊發送
 */
export const invoiceCarrierTypeMap = {
    email: '1',
    mobile: '2',
    citizenDigitalCertificate1: '3',
    sms: '4',
};

/**
 * 發票開立方式
 * 1. 捐贈發票
 * 2. 二聯式發票
 * 3. 三聯式發票
 * 4. 手開發票
 */
export const issuingTypeMap = {
    donate: '1',
    duplicateUniform: '2',
    triplicateUniform: '3',
    manual: '4',
};

// 發票類型 RadioBox 切換
export const InvoiceByIssuingType = React.forwardRef((props, ref) => {
    const { defaultValue: issuingType, errorData = {}, options = INVOICE_ISSUING_TYPE_OPTIONS, onChange: onChangeProps, ...other } = props;
    const issuingTypeRef = useRef(null);

    const handleChange = (e, value) => {
        if (typeof onChangeProps === 'function') {
            onChangeProps(value);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    issuingType: getRadioValue(issuingTypeRef.current.getResult()),
                };
            },
            isError: () => refIsRequiredError(issuingTypeRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <RadioField
            key={issuingType}
            label={'發票類型'}
            ref={issuingTypeRef}
            defaultValue={issuingType}
            {...getErrorModuleStatus(errorData, 'issuingType')}
            onChange={handleChange}
            fullWidth
            required
            row
            {...other}
        >
            {options.map(({ text, value }) => (
                <RadioField.Radio key={value} value={value} label={text} />
            ))}
        </RadioField>
    );
});

// 發票備註
export const InvoiceByNotes = React.forwardRef((props, ref) => {
    const { defaultValue: invoiceNotes, errorData = {}, onChange, ...other } = props;
    const invoiceNotesRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    invoiceNotes: invoiceNotesRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(invoiceNotesRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <TextField
            key={invoiceNotes}
            label={'發票備註'}
            ref={invoiceNotesRef}
            defaultValue={invoiceNotes}
            {...getErrorModuleStatus(errorData, 'invoiceNotes')}
            onChange={onChange}
            minRows={2}
            multiline
            fullWidth
            {...other}
        />
    );
});

// 捐贈發票
export const InvoiceByDonate = React.forwardRef((props, ref) => {
    const { defaultValue: donationCode = defaultDonate, errorData = {}, readOnly: readOnlyProps = false } = props;
    const defaultValue = donationCode || defaultDonate;
    const isOtherValue = (donationCode) => {
        let index = defaultDonateOptions.findIndex((item) => item.value === String(donationCode));
        return index < 0;
    };
    const [showOther, setShowOther] = useState(isOtherValue(defaultValue));
    const radioFieldRef = useRef();
    const donationCodeRef = useRef();

    const handleRadioValue = (defaultValue) => {
        if (isOtherValue(defaultValue)) {
            return 'other';
        }
        return defaultValue;
    };

    const handleOtherValue = (defaultValue) => {
        if (isOtherValue(defaultValue)) {
            return defaultValue;
        }
        return '';
    };

    const handleRadioChange = (e, v) => {
        setShowOther(v === 'other');
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};

                if (radioFieldRef.current && radioFieldRef.current.getResult) {
                    result = Object.assign(result, {
                        donationCode: getRadioValue(radioFieldRef.current.getResult()),
                    });
                }

                if (donationCodeRef.current && donationCodeRef.current.getResult) {
                    result = Object.assign(result, {
                        donationCode: donationCodeRef.current.getResult(),
                    });
                }

                return result;
            },
            isError: () => refIsRequiredError(donationCodeRef),
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (donationCode) {
                setShowOther(isOtherValue(donationCode));
            }
        },
        // eslint-disable-next-line
        [donationCode],
    );

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <RadioField
                    key={handleRadioValue(defaultValue)}
                    label="愛心碼"
                    ref={radioFieldRef}
                    defaultValue={handleRadioValue(defaultValue)}
                    {...getErrorModuleStatus(errorData, 'donationCode')}
                    onChange={handleRadioChange}
                    readOnly={readOnlyProps}
                    required
                    row
                >
                    {defaultDonateOptions.map((item) => {
                        return <RadioField.Radio key={item.value} label={item.text} value={item.value} />;
                    })}
                    <RadioField.Radio value={'other'} label="其他" />
                </RadioField>
            </Grid>
            {showOther && (
                <Grid item xs={12}>
                    <TextField
                        key={donationCode}
                        ref={donationCodeRef}
                        label={'請輸入愛心碼'}
                        defaultValue={handleOtherValue(defaultValue)}
                        {...getErrorModuleStatus(errorData, 'donationCode')}
                        maskType={'DC'}
                        readOnly={readOnlyProps}
                        fullWidth
                        required
                    />
                </Grid>
            )}
        </React.Fragment>
    );
});

// 手開發票
export const InvoiceByManual = React.forwardRef((props, ref) => {
    const { sourceData = {}, readOnly: readOnlyProps = false } = props;
    const { invoiceNo } = sourceData;
    const invoiceNoRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    invoiceNo: invoiceNoRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(invoiceNoRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <TextField key={invoiceNo} label={'手開發票號碼'} ref={invoiceNoRef} defaultValue={invoiceNo} readOnly={readOnlyProps} fullWidth required />
            <Alert className={'alert-justify-content-start px-0'} color={'info'} severity={'info'}>
                選擇手開發票，發票異動需由店舖自行處理。
            </Alert>
        </React.Fragment>
    );
});

// 統一編號
export const InvoiceByTaxID = React.forwardRef((props, ref) => {
    const { label = '統一編號', defaultValue: taxID, errorData = {}, ...other } = props;
    const taxIDRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    taxID: taxIDRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(taxIDRef),
        }),
        // eslint-disable-next-line
        [],
    );
    return (
        <TextField
            key={taxID}
            label={label}
            ref={taxIDRef}
            defaultValue={taxID}
            maskType={'TID'}
            {...getErrorModuleStatus(errorData, 'taxID')}
            fullWidth
            required
            {...other}
        />
    );
});

// 發票抬頭
export const InvoiceByTaxTitle = React.forwardRef((props, ref) => {
    const { label = '發票抬頭', defaultValue: taxTitle, errorData = {}, ...other } = props;
    const taxTitleRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    taxTitle: taxTitleRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(taxTitleRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <TextField
            key={taxTitle}
            label={label}
            ref={taxTitleRef}
            defaultValue={taxTitle}
            {...getErrorModuleStatus(errorData, 'taxTitle')}
            fullWidth
            required
            {...other}
        />
    );
});

// 發票載具
export const InvoiceByCarrierType = React.forwardRef((props, ref) => {
    const { defaultValue: invoiceCarrierType, options, errorData = {}, onChange: onChangeProps, ...other } = props;
    const invoiceCarrierTypeOptions = options || [];
    const carrierTypeRef = useRef();

    const handleChange = (e, _, value) => {
        if (typeof onChangeProps === 'function') {
            onChangeProps(value);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    carrierType: carrierTypeRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(carrierTypeRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <SelectField
            key={invoiceCarrierType}
            ref={carrierTypeRef}
            label={'發票載具'}
            defaultValue={invoiceCarrierType}
            {...getErrorModuleStatus(errorData, 'invoiceCarrierType')}
            onChange={handleChange}
            fullWidth
            required
            {...other}
        >
            {Array.isArray(invoiceCarrierTypeOptions) &&
                invoiceCarrierTypeOptions
                    .filter((item) => item || !item.disabled)
                    .map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.text}
                        </MenuItem>
                    ))}
        </SelectField>
    );
});

// 載具號碼
export const InvoiceByCarrierNo = React.forwardRef((props, ref) => {
    const { defaultValue: invoiceCarrierNo, invoiceCarrierType, errorData = {}, readOnly: readOnlyProps = false, ...other } = props;
    const carrierNoRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    carrierNo: carrierNoRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(carrierNoRef),
        }),
        // eslint-disable-next-line
        [],
    );
    return (
        <TextField
            key={`${invoiceCarrierType}-${invoiceCarrierNo}`}
            ref={carrierNoRef}
            label={'載具號碼'}
            defaultValue={invoiceCarrierNo}
            {...getErrorModuleStatus(errorData, 'invoiceCarrierNo')}
            maskType={String(invoiceCarrierType) === invoiceCarrierTypeMap.mobile ? 'MB' : 'CDC'}
            readOnly={readOnlyProps}
            fullWidth
            required
            {...other}
        />
    );
});

// 載具信箱
export const InvoiceByEmail = React.forwardRef((props, ref) => {
    const { label = '寄送載具信箱', defaultValue: issuingEmail, errorData = {}, ...other } = props;
    const issuingEmailRef = useRef();
    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    issuingEmail: issuingEmailRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(issuingEmailRef),
        }),
        // eslint-disable-next-line
        [],
    );
    return (
        <TextField
            key={issuingEmail}
            ref={issuingEmailRef}
            label={label}
            type={'email'}
            defaultValue={issuingEmail}
            {...getErrorModuleStatus(errorData, 'issuingEmail')}
            fullWidth
            {...other}
        />
    );
});

// 載具手機
export const InvoiceByPhone = React.forwardRef((props, ref) => {
    const { label = '寄送載具手機', defaultValue: issuingPhone, errorData = {}, readOnly: readOnlyProps = false, ...other } = props;
    const issuingPhoneRef = useRef();
    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    issuingPhone: issuingPhoneRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(issuingPhoneRef),
        }),
        // eslint-disable-next-line
        [],
    );
    return (
        <TextField
            key={issuingPhone}
            ref={issuingPhoneRef}
            label={label}
            defaultValue={issuingPhone}
            {...getErrorModuleStatus(errorData, 'issuingPhone')}
            maskType={'MOB'}
            readOnly={readOnlyProps}
            fullWidth
            {...other}
        />
    );
});

// 同步更新會員電子郵件與發票
export const SynchronizeMemberInvoice = React.forwardRef((props, ref) => {
    const { defaultValue: emailState, readOnly: readOnlyProps = false, className } = props;
    const synchronizeMemberInvoiceRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const synchronizeMemberInvoiceValue = synchronizeMemberInvoiceRef.current && synchronizeMemberInvoiceRef.current.getResult();
                const result = {};

                if (Array.isArray(synchronizeMemberInvoiceValue)) {
                    synchronizeMemberInvoiceValue.forEach((item) => {
                        result[item.value] = item.checked;
                    });
                }

                return result;
            },
            isError: () => refIsRequiredError(synchronizeMemberInvoiceRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <CheckboxField
            key={emailState}
            ref={synchronizeMemberInvoiceRef}
            className={className}
            defaultValue={setCheckboxValue(emailState)}
            readOnly={readOnlyProps}
        >
            <CheckboxField.Checkbox label={'同步更新會員電子郵件'} value={'syncUpgradeMemberEmail'} />
            <CheckboxField.Checkbox label={'同步更新會員發票載具類型'} value={'syncUpgradeMemberCarrier'} />
        </CheckboxField>
    );
});

// 三聯發票
export const InvoiceByTriplicateUniform = React.forwardRef((props, ref) => {
    const { sourceData = {}, errorData = {}, readOnly = false } = props;
    const { taxID = '', taxTitle = '' } = sourceData;
    const taxIDRef = useRef(null);
    const taxTitleRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};

                if (taxIDRef.current && taxIDRef.current.getResult) {
                    Object.assign(result, taxIDRef.current.getResult());
                }

                if (taxTitleRef.current && taxTitleRef.current.getResult) {
                    Object.assign(result, taxTitleRef.current.getResult());
                }

                return result;
            },
            isError: () => refIsRequiredError(taxIDRef, taxTitleRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <InvoiceByTaxID ref={taxIDRef} defaultValue={taxID} errorData={errorData} readOnly={readOnly} />
            </Grid>
            <Grid item xs={12}>
                <InvoiceByTaxTitle ref={taxTitleRef} defaultValue={taxTitle} errorData={errorData} readOnly={readOnly} />
            </Grid>
        </React.Fragment>
    );
});
