import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Box, IconButton, ListItemText, Menu, MenuItem, Stack, Typography } from '@common/components/';
import { MoreVertical as MoreVerticalIcon } from '@common/SvgIcon/';
import { MemoControllerBox, MorePageBox } from '@icoach/components';
import Tablenization from '@icoach/components/Tablenization';
import { ListInfo } from '@icoach/accountBook/components';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { useGiftContext } from '@icoach/gift/components/GiftContext';
import useGiftItemApi from '@apis/useGiftItemApi';
import { formatCurrencyFn } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';

const ActionMenuButton = ({ menuData = [], actionButtonProps = {}, actionMenuProps = {}, textAlign = 'left', enabled = true, ...other }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickActionButton = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // 為啟用與沒有操作項就不顯示
    if (!enabled || menuData.length < 1) return null;

    return (
        <React.Fragment>
            <IconButton {...actionButtonProps} onClick={handleClickActionButton}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu className={clsx('more-label-box-menu', `align-${textAlign}`)} anchorEl={anchorEl} open={open} onClose={(e) => handleCloseMenu(null)}>
                {menuData.map(({ text, ...others }) => {
                    return (
                        <MenuItem {...others} key={`more_label_${text}`}>
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

const headerRow = [
    {
        cellKey: 'giftName',
        headerLabel: '贈品名稱',
    },
    {
        cellKey: 'enabled',
        headerLabel: '贈品狀態',
        align: 'center',
        formatCell: (enabled) => (enabled ? '上架' : '下架'),
    },
    {
        cellKey: 'giftPrice',
        headerLabel: '單價',
        align: 'center',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'giftSpecifications',
        align: 'center',
        headerLabel: '規格',
    },
    {
        cellKey: 'giftDescription',
        headerLabel: '描述',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'商品描述'} />;
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        width: 6.8,
        formatCell: (value, rowData, { refresh, handleFreshFn, handleGiftStatusChange, openGiftDialog, openGiftDispatchDialog }) => {
            const { enabled, giftID: giftItemID } = rowData;
            const menuData = [
                {
                    text: '編輯贈品',
                    onClick: (e) => {
                        openGiftDialog(rowData, ({ params }) => handleFreshFn(refresh)(params));
                    },
                },
                {
                    text: enabled ? '下架' : '上架',
                    onClick: (e) => {
                        handleGiftStatusChange(rowData);
                    },
                },
                {
                    text: '贈品發送',
                    onClick: (e) => {
                        openGiftDispatchDialog({ giftItemID }, refresh);
                    },
                },
            ];
            return <ActionMenuButton menuData={menuData} />;
        },
    },
];

const GiftTable = () => {
    const { dialogRef, sourceData, bookmark, setBookmarkNum, refresh } = useGiftContext();
    const { pageIndex = bookmark.pageIndex, pageSize = bookmark.pageSize, totalPage, totalCount, list = [] } = sourceData;
    const { putGiftItemEnabledApi } = useGiftItemApi();
    const setMessageDialog = useMessageDialog();
    const scrollerArrowRef = useRef(null);

    const handleChangePage = (e, num) => {
        if (typeof setBookmarkNum === 'function') setBookmarkNum(num);
    };

    const handleFreshFn = (refreshFn) => {
        return (params) => {
            setTimeout(() => {
                refreshFn(params);
            }, 5);
        };
    };

    const handleGiftEnabledApi = async (params, onClose) => {
        const { giftID } = params;
        let resp = await putGiftItemEnabledApi(giftID, params);
        if (resp) {
            let refreshFn = handleFreshFn(refresh);
            if (typeof refreshFn === 'function') refreshFn(params);
            if (typeof onClose === 'function') onClose();
        }
    };

    const handleGiftStatusChange = (rowData) => {
        const { giftID, enabled } = rowData;
        const targetText = enabled ? '下架' : '上架';

        setMessageDialog({
            open: true,
            title: '提醒',
            msg: `確定要進行${targetText}嗎？`,
            onAsyncConfirm: (_, onclose) => handleGiftEnabledApi({ giftID, enabled: !enabled }, onclose),
        });
    };

    return (
        <React.Fragment>
            <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography className={'font-weight-bold'} variant="h5">
                    贈品總覽
                </Typography>
                <ListInfo className={'ml-auto'} pageIndex={pageIndex} pageSize={pageSize} totalCount={totalCount} isCurrentPageLastIndex />
            </Stack>
            <Box className={'scroll-arrow-container'}>
                <Tablenization
                    cardProps={{ className: 'theme-gray overview' }}
                    page={pageIndex}
                    count={totalPage}
                    onChange={handleChangePage}
                    tableContainerRef={scrollerArrowRef}
                    Pagination={MorePageBox}
                    isPagination
                >
                    <Tablenization.TableRowHeader headerRow={headerRow} />
                    <Tablenization.TableRowBody
                        headerRow={headerRow}
                        rowsData={list}
                        refresh={refresh}
                        bodyRowClass={{
                            key: 'enabled',
                            value: false,
                            className: 'overview-item-invalid',
                        }}
                        openGiftDialog={dialogRef.current && dialogRef.current.openGiftDialog}
                        openGiftDispatchDialog={dialogRef.current && dialogRef.current.openGiftDispatchDialog}
                        handleGiftStatusChange={handleGiftStatusChange}
                        handleFreshFn={handleFreshFn}
                    />
                </Tablenization>
                <ScrollerArrowButton parentRef={scrollerArrowRef} />
            </Box>
        </React.Fragment>
    );
};

export default GiftTable;
