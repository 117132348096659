import React from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import Tablenization from '@icoach/components/Tablenization';
import { TableLabel } from '@icoach/dataAnalysis/components';
import { getDynamicThData, handleCumulativeData, thData } from '@icoach/dataAnalysis/tables/EmployeesMonthlyTable';
import { transposedEmployeeData } from '../staticData';

const getRowsData = (data, cumulative, thData) => {
    let rowsData = [];
    let rowsLabel = [
        {
            cellKey: 'title',
            headerLabel: '項目',
            width: 26,
            formatCell: (title, { tip, isStar }) => {
                return <TableLabel title={title} tip={tip} isStar={isStar} />;
            },
        },
        {
            cellKey: 'goal',
            headerLabel: '目標',
            align: 'center',
            width: 11,
        },
        {
            cellKey: 'cumulative',
            headerLabel: '最新數字',
            align: 'center',
            className: 'total-col',
            minWidth: 128,
        },
    ];
    let dynamicThData = getDynamicThData(data);

    data = transposedEmployeeData(data);

    if (!isEmpty(data)) {
        if (!isEmpty(dynamicThData)) {
            thData = thData.concat(dynamicThData);
        }

        rowsData = thData.map(({ key, formatCell, ...other }) => {
            let employeesData = data[key];
            let rowData = {
                cellKey: '',
                cumulative: cumulative[key],
                ...other,
                ...employeesData,
            };

            if (formatCell) {
                rowData.cellKey = 'cumulative';
                rowData.cumulative = formatCell(cumulative[key], rowData);
                for (let employeeID in employeesData) {
                    rowData.cellKey = employeeID;
                    rowData[employeeID] = formatCell(employeesData[employeeID], rowData);
                }
            }

            return rowData;
        });

        rowsLabel = [...rowsLabel, ...data.employees];
    }

    return { rowsLabel, rowsData };
};

const EmployeesDifferenceCumulativeTable = (props) => {
    const { className, rowsData: data, cumulative } = props;
    const { rowsLabel, rowsData } = getRowsData(data, handleCumulativeData(cumulative), thData);

    return (
        <Tablenization cardProps={{ className: clsx('monthly-table', className) }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel} />
            <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={rowsData} />
        </Tablenization>
    );
};

export default EmployeesDifferenceCumulativeTable;
