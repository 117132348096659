import React, { useState } from 'react';
import clsx from 'clsx';
import { ClickAwayListener, Tooltip, Typography } from '../../../components';
import { QuestionCircle as QuestionCircleIcon } from '../../../components/icons/SvgIcon/';
export const TitleByTooltip = (props) => {
    const { title, tip } = props;
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipToggle = () => {
        setOpen((prev) => !prev);
    };
    if (tip) {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    title={tip}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <Typography paragraph className="counter-box-title tip" onClick={handleTooltipToggle}>
                        <Typography component={'span'}>{title}</Typography>
                        <QuestionCircleIcon fontSize={'small'} />
                    </Typography>
                </Tooltip>
            </ClickAwayListener>
        );
    }
    return (
        <Typography paragraph className="counter-box-title">
            {title}
        </Typography>
    );
};
const CounterBox = (props) => {
    const { className, title, counter = 0, tip, onClick, children } = props;
    return (
        <div className={clsx('counter-box', className)} onClick={onClick}>
            <TitleByTooltip title={title} tip={tip} />
            <Typography variant="h6" className="counter-box-number">
                {counter}
            </Typography>
            {children}
        </div>
    );
};

export default CounterBox;
