import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, CheckboxField, SelectField, MenuItem, Typography, TextField, Stack, Alert } from '@common/components/';
import { formatCurrencyFn, isEmpty, refIsRequiredError } from '@util/utils';
import useDocMembershipQuitApi from '@apis/useDocMembershipQuitApi';
import { MultiplePaymentAmountPaidListGroup } from '@icoach/documents/components/PaidList';

const DefaultMessage = () => {
    return (
        <Typography>
            刪除後資料就
            <Typography component={'span'} className={'text-error font-weight-bold'}>
                無法恢復
            </Typography>
            囉！
        </Typography>
    );
};

const MembershipQuitPaymentAmount = React.forwardRef((props, ref) => {
    const { total: totalProps, useRefund: useRefundProps } = props;
    const [totalModal, setTotalModal] = useState({ total: 0, cashAmount: 0, cardAmount: 0 });
    const multiplePaymentAmountPaidListGroupRef = useRef(null);

    const handleOnChange =
        (type) =>
        ({ target }) => {
            const { value } = target;
            if (type === 'cash') {
                setTotalModal((prev) => ({ ...prev, cashAmount: value * 1 }));
            } else if (type === 'card') {
                setTotalModal((prev) => ({ ...prev, cardAmount: value * 1 }));
            }
        };

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(multiplePaymentAmountPaidListGroupRef),
                getResult: () => {
                    const { cardPaymentAmount = 0, cashPaymentAmount = 0 } = multiplePaymentAmountPaidListGroupRef.current?.getResult() ?? {};
                    return {
                        cardPaymentAmount: parseInt(cardPaymentAmount, 10),
                        cashPaymentAmount: parseInt(cashPaymentAmount, 10),
                    };
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            setTotalModal((prev) => ({ ...prev, total: totalProps }));
        },
        // eslint-disable-next-line
        [totalProps],
    );

    return (
        <MultiplePaymentAmountPaidListGroup
            ref={multiplePaymentAmountPaidListGroupRef}
            cashPaymentAmountProps={{ required: true, onChange: handleOnChange('cash') }}
            cardPaymentAmountProps={{ required: true, onChange: handleOnChange('card') }}
            total={totalModal.total - totalModal.cashAmount - totalModal.cardAmount}
            isRefund={useRefundProps}
        />
    );
});

const DocumentMembershipQuitContent = React.forwardRef((props, ref) => {
    const { contractID } = props;
    const { getDocMembershipQuitContractInvalidApi } = useDocMembershipQuitApi();
    const [option, setOption] = useState([]);
    const [sourceData, setSourceData] = useState({});
    const employeeIDRef = useRef(null);
    const memoRef = useRef(null);
    const paymentAmountRef = useRef(null);

    const { useRefund, paymentType, totalPrice } = sourceData;

    const initOptions = async (id) => {
        const resp = await getDocMembershipQuitContractInvalidApi(id);
        if (resp) {
            const { employeeOption, ...other } = resp;
            setSourceData(other);
            setOption(employeeOption);
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    return {
                        invalidEmployeeID: employeeIDRef.current.getResult(),
                        invalidMemo: memoRef.current.getResult() || '',
                        ...paymentAmountRef.current.getResult(),
                    };
                },
                isError: () => refIsRequiredError(employeeIDRef, memoRef, paymentAmountRef),
            };
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (option.length === 0) {
                initOptions(contractID);
            }
        },
        // eslint-disable-next-line
        [option],
    );
    if (option.length === 0) return null;

    return (
        <DialogContent>
            <Stack spacing={2} direction="column">
                <SelectField label={'經辦教練'} ref={employeeIDRef} displayEmpty fullWidth required>
                    <MenuItem value={''} disabled>
                        <em>請選擇</em>
                    </MenuItem>
                    {option.map(({ text, value }) => (
                        <MenuItem key={value} value={value}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
                <TextField label="刪除原因" ref={memoRef} rows={3} multiline fullWidth required />
                {useRefund ? (
                    <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                        退會時與會員收取{paymentType === 1 ? '信用卡' : '現金'}共{formatCurrencyFn(totalPrice)}需退還，請選擇折讓方式：
                    </Alert>
                ) : (
                    <Alert severity="info" variant="filled" color="gray" className="alert-justify-content-start">
                        退會時已退還會員共{formatCurrencyFn(totalPrice)}，需額外收回費用設定為既存月費金額，請選擇收款方式：
                    </Alert>
                )}
                <MembershipQuitPaymentAmount ref={paymentAmountRef} total={sourceData.totalPrice} useRefund={useRefund} />
            </Stack>
        </DialogContent>
    );
});

const ConfirmDeleteDocDialogContent = React.forwardRef((props, ref) => {
    const { message, confirmMessage } = props;
    const confirmRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            isError: () => refIsRequiredError(confirmRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <DialogContent>
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    {message}
                </Grid>
                <Grid xs={12} item>
                    <CheckboxField className={'mb-1'} ref={confirmRef} required>
                        <CheckboxField.Checkbox label={confirmMessage} value={true} />
                    </CheckboxField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const ConfirmDeleteDocQuitDialog = (props) => {
    const {
        title = '確認刪除',
        message = <DefaultMessage />,
        confirmMessage = '會員已同意刪除此文書',
        sourceData: sourceDataProps = {},
        open: openProps,
        onClose: onCloseProps,
        deleteDocApi,
        refresh,
    } = props;
    const { isTemporary } = sourceDataProps;
    const documentMembershipQuitContentRef = useRef(null);
    const contentRef = useRef(null);

    const checkError = () => {
        return refIsRequiredError(documentMembershipQuitContentRef, contentRef);
    };

    const getParams = () => {
        let result = {};

        if (!isEmpty(sourceDataProps)) {
            result = Object.assign(result, {
                isTemporary: sourceDataProps.isTemporary,
                contractID: sourceDataProps.contractID,
            });
        }

        if (documentMembershipQuitContentRef.current && documentMembershipQuitContentRef.current.getResult) {
            result = Object.assign(result, documentMembershipQuitContentRef.current.getResult());
        }

        return result;
    };

    const handleSubmit = () => {
        let isError = checkError();
        if (!isError) {
            const params = getParams();
            doDeleteDocApi(params);
        }
    };

    const doDeleteDocApi = async (params) => {
        const resp = await deleteDocApi(params);
        if (resp) {
            onCloseProps();
            refresh && refresh();
        }
    };

    if (!openProps) return null;

    return (
        <Dialog className={clsx('simple-content', 'dialog')} PaperProps={{ className: 'wx-60rem' }} open={openProps} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>{title}</GradientColorHeader>
            {isTemporary ? (
                <ConfirmDeleteDocDialogContent ref={contentRef} message={message} confirmMessage={confirmMessage} />
            ) : (
                <React.Fragment>
                    <DocumentMembershipQuitContent ref={documentMembershipQuitContentRef} contractID={sourceDataProps.contractID} />
                    <ConfirmDeleteDocDialogContent ref={contentRef} message={message} confirmMessage={confirmMessage} />
                </React.Fragment>
            )}
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit}>
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteDocQuitDialog;
