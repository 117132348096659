import React from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead, Tooltip } from '../../../components';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { ProbioticsOrdered as ProbioticsOrderedIcon, WheyOrdered as WheyOrderedIcon, CaOrdered as CaOrderedIcon } from '../../../components/icons/SvgIcon/';

const CycleOrderMonthStatisticsTableCells = (props) => {
    const { data, pageSize, target } = props;
    let colSpan = pageSize - data.length;

    return (
        <React.Fragment>
            {Boolean(colSpan) && <TableCell colSpan={colSpan} />}
            {!isEmpty(data) &&
                data.map((item) => {
                    const { year, month, isSuspension, isQuit } = item;
                    let key = `${year}${month}`;
                    let _className = {
                        isSuspension: isSuspension,
                        isQuit: isQuit,
                    };
                    let text = item[target];
                    const hasOrdered = text > 0;
                    return (
                        <TableCell
                            key={key}
                            className={clsx(_className, {
                                'font-weight-bold': hasOrdered,
                                'text-primary': hasOrdered,
                            })}
                        >
                            {text}
                        </TableCell>
                    );
                })}
        </React.Fragment>
    );
};

const CycleOrderMonthStatisticsTable = (props) => {
    const { className, data = [], pageSize } = props;

    return (
        <Table className={clsx('cycle-order-month-statistics-table', className)}>
            <TableHead>
                <TableRow>
                    <TableCell className={'px-0'}>月份</TableCell>
                    {Array.from({ length: pageSize }).map((_, i) => {
                        let key = uuid();
                        let _className = '';
                        let text = '-';
                        const itemData = data[i];

                        if (!isEmpty(itemData)) {
                            const { year, month, isSuspension, isQuit } = itemData;
                            key = `${year}${month}`;
                            _className = {
                                isSuspension: isSuspension,
                                isQuit: isQuit,
                            };
                            text = month;
                        }
                        return (
                            <TableCell key={key} className={clsx(_className)}>
                                {text}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell className={'px-0'}>
                        <Tooltip title={'乳清蛋白'} disableFocusListener>
                            <WheyOrderedIcon className={'cycle-order-month-statistics-table-icon'} />
                        </Tooltip>
                    </TableCell>
                    <CycleOrderMonthStatisticsTableCells data={data} pageSize={pageSize} target={'proteinCount'} />
                </TableRow>
                <TableRow>
                    <TableCell className={'px-0'}>
                        <Tooltip title={'益生菌'} disableFocusListener>
                            <ProbioticsOrderedIcon className={'cycle-order-month-statistics-table-icon'} />
                        </Tooltip>
                    </TableCell>
                    <CycleOrderMonthStatisticsTableCells data={data} pageSize={pageSize} target={'probioticsCount'} />
                </TableRow>
                <TableRow>
                    <TableCell className={'px-0'}>
                        <Tooltip title={'鈣力嗨'} disableFocusListener>
                            <CaOrderedIcon className={'cycle-order-month-statistics-table-icon'} />
                        </Tooltip>
                    </TableCell>
                    <CycleOrderMonthStatisticsTableCells data={data} pageSize={pageSize} target={'calciumCount'} />
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default CycleOrderMonthStatisticsTable;
