import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';

const GET_MEMBER_WORKOUTS_API = `/api/member-workouts/`;
const GET_MEMBERS_CHECKIN_API = `/api/members/checkin/`;
const POST_MEMBER_CHECKIN_API = GET_MEMBERS_CHECKIN_API;

const useMemberCheckInApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 依門店資訊取得會員刷卡清單
    const getmemberWorkoutsDataApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_MEMBER_WORKOUTS_API);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 取得來店會員清單列表
    const getMemberCheckinApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_MEMBERS_CHECKIN_API, params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 手動刷卡簽到
    const postMemberCheckinApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBER_CHECKIN_API, params);
            if (!resp) {
                enqueueSnackbar('刷卡簽到失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getmemberWorkoutsDataApi,
        getMemberCheckinApi,
        postMemberCheckinApi,
    };
};

export default useMemberCheckInApi;
