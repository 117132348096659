import React, { useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@common/components/';
import { Magnifier as MagnifierIcon } from '@common/SvgIcon/';
import { isEmpty } from '@util/utils';

const SearchMemberField = (props) => {
    const { tableRef } = props;
    const inputRef = useRef(null);
    const isOnComposition = useRef(false);
    const timeoutRef = useRef(null);
    const handleSearch = () => {
        const target = inputRef.current;
        const keyword = target.getResult().trim();
        const tableData = tableRef.current && tableRef.current.rowsDataProps;
        let result = tableData;

        if (!isEmpty(keyword)) {
            result = tableData.filter(({ memberName, memberNo }) => {
                let _result = false;
                if (!_result) _result = memberName.indexOf(keyword) !== -1;
                if (!_result) _result = memberNo.indexOf(keyword) !== -1;
                return _result;
            });
        }

        if (tableRef.current && typeof tableRef.current.setTableData === 'function') {
            tableRef.current.setTableData(result);
        }
    };

    const handleChange = (e) => {
        clearTimeout(timeoutRef.current);
        if (e.target instanceof HTMLInputElement && !isOnComposition.current) {
            const _t = setTimeout(() => {
                handleSearch();
            }, 1000);
            timeoutRef.current = _t;
        }
    };

    const handleComposition = (e) => {
        if (e.type === 'compositionend') {
            // composition is end
            isOnComposition.current = false;
            handleChange(e);
        } else {
            // in composition
            isOnComposition.current = true;
        }
    };

    return (
        <TextField
            style={{ maxWidth: '260px' }}
            ref={inputRef}
            placeholder={'搜尋會員'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position={'end'}>
                        <IconButton onClick={handleSearch}>
                            <MagnifierIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            onCompositionStart={handleComposition}
            onCompositionUpdate={handleComposition}
            onCompositionEnd={handleComposition}
            onChange={handleChange}
        />
    );
};

export default SearchMemberField;
