import React, { createContext, useContext } from 'react';

const CyclePurchaseContext = createContext(undefined);

export const CyclePurchaseProvider = ({ children, value }) => {
    return <CyclePurchaseContext.Provider value={value}>{children}</CyclePurchaseContext.Provider>;
};

export const useCyclePurchaseContext = () => {
    const context = useContext(CyclePurchaseContext);
    if (context === undefined) {
        throw new Error('useCyclePurchaseContext 必須在 CyclePurchaseProvider 中使用');
    }
    return context;
};
