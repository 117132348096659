import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Card, Tab, Tabs } from '@common/components/';
import MemberDetailBasicInfoContentInfo from '@icoach/members/memberDetail/MemberDetailBasicInfoContentInfo';
import MemberDetailBasicInfoContentInvoice from '@icoach/members/memberDetail/MemberDetailBasicInfoContentInvoice';
import MemberDetailBasicInfoContentPayment from '@icoach/members/memberDetail/MemberDetailBasicInfoContentPayment';

const BtnGroup = (props) => {
    const { isEdit, tabsIndex, onClick } = props;
    const displayBtnText = isEdit ? '取消編輯' : '編輯資料';

    let handleOnClick = (e) => {
        if (typeof onClick === 'function') onClick(e);
    };

    return (
        <div className="btn-group no-gutter">
            {tabsIndex === 'info' && (
                <Button
                    variant={'outlined'}
                    color="secondary"
                    onClick={() => handleOnClick(!isEdit)}
                >
                    {displayBtnText}
                </Button>
            )}
        </div>
    );
};

const MemberDetailBasicInfoContent = (props) => {
    const { className, memberID, setTab } = props;
    const tabs = props.match.params.tabs;
    const [tabsIndex, setTabsIndex] = useState('info');

    const [isEdit, setEdit] = useState(false);
    const handleTabChange = (e, value) => {
        setTabsIndex(value);
        setEdit(false);
    };
    const toggleEditBasicInfoForm = (isEdit) => {
        setEdit(isEdit);
    };

    useEffect(() => {
        if (tabs) {
            setTab(tabs);
        }
        // eslint-disable-next-line
    }, [tabs]);

    return (
        <Card className={clsx(className, 'tabs-content-layout')}>
            <header className={'page-header'}>
                <Tabs value={tabsIndex} onChange={handleTabChange}>
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'info',
                        })}
                        label={'基本資料'}
                        value={'info'}
                    />
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'basic',
                        })}
                        label={'付款資料'}
                        value={'payment'}
                    />
                    <Tab
                        className={clsx({
                            state: tabsIndex === 'basic',
                        })}
                        label={'管理發票'}
                        value={'invoice'}
                    />
                </Tabs>
                <BtnGroup tabsIndex={tabsIndex} isEdit={isEdit} onClick={toggleEditBasicInfoForm} />
            </header>
            <Card.CardContent className="pt-4 px-4 pb-3">
                {tabsIndex === 'info' && (
                    <MemberDetailBasicInfoContentInfo
                        memberID={memberID}
                        isEdit={isEdit}
                        toggleEditBasicInfoForm={toggleEditBasicInfoForm}
                    />
                )}
                {tabsIndex === 'payment' && (
                    <MemberDetailBasicInfoContentPayment memberID={memberID} />
                )}
                {tabsIndex === 'invoice' && (
                    <MemberDetailBasicInfoContentInvoice memberID={memberID} />
                )}
            </Card.CardContent>
        </Card>
    );
};

export default MemberDetailBasicInfoContent;
