import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Card, Tab, Tabs, Box } from '../../../components';
import MemberDetailOtherContentOrderUncheckout from './MemberDetailOtherContentOrderUncheckout';
import MemberDetailOtherContentOrderRecord from './MemberDetailOtherContentOrderRecord';
import MemberDetailOtherContentGiftClaimRecord from '@icoach/members/memberDetail/MemberDetailOtherContentGiftClaimRecord';

const UNCHECKOUT = 'uncheckout';
const ORDER_RECORD = 'order-record';
const GIFT_CLAIM = 'gift-claim';

const MemberDetailOtherContent = (props) => {
    const { className, memberID, setTab } = props;
    const tabs = props.match.params.tabs;
    const [tabsIndex, setTabsIndex] = useState('uncheckout');
    const handleTabChange = (e, value) => {
        setTabsIndex(value);
    };

    useEffect(() => {
        if (tabs) {
            setTab(tabs);
        }
        // eslint-disable-next-line
    }, [tabs]);

    return (
        <Card className={clsx(className, 'tabs-content-layout')}>
            <Box className={'page-header'} component={'header'}>
                <Tabs value={tabsIndex} onChange={handleTabChange}>
                    <Tab
                        className={clsx({
                            state: tabsIndex === UNCHECKOUT,
                        })}
                        label={'欠費訂單'}
                        value={UNCHECKOUT}
                    />
                    <Tab
                        className={clsx({
                            state: tabsIndex === ORDER_RECORD,
                        })}
                        label={'購買記錄'}
                        value={ORDER_RECORD}
                    />
                    <Tab
                        className={clsx({
                            state: tabsIndex === GIFT_CLAIM,
                        })}
                        label={'贈品紀錄'}
                        value={GIFT_CLAIM}
                    />
                </Tabs>
            </Box>
            <Card.CardContent className="pt-4 px-4 pb-3">
                {tabsIndex === UNCHECKOUT && <MemberDetailOtherContentOrderUncheckout memberID={memberID} />}
                {tabsIndex === ORDER_RECORD && <MemberDetailOtherContentOrderRecord memberID={memberID} />}
                {tabsIndex === GIFT_CLAIM && <MemberDetailOtherContentGiftClaimRecord memberID={memberID} />}
            </Card.CardContent>
        </Card>
    );
};

export default MemberDetailOtherContent;
