import React from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import clsx from 'clsx';
import { Divider, List, ListItem, ListItemText, Button } from '@common/components/';
import { parseDate } from '@util/moment';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useMemberWorkoutApi from '@apis/useMemberWorkoutApi';

const CheckInDialogContent = (props) => {
    const { data } = props;
    const { checkInRecord } = data;

    return (
        <DialogContent>
            <List>
                <ListItem>
                    <ListItemText primary="入場時間" />
                    <ListItemText primary={checkInRecord.checkinTime} />
                </ListItem>
                {
                    <React.Fragment>
                        <Divider />
                        <ListItem>
                            <ListItemText primary="離場時間" />
                            <ListItemText primary={checkInRecord.checkoutTime} />
                        </ListItem>
                    </React.Fragment>
                }
            </List>
        </DialogContent>
    );
};

const CheckInDialog = (props) => {
    const { className, open, title, data, onClose, memberID, refresh } = props;
    const { deleteWorkoutRecordApi } = useMemberWorkoutApi();
    const handleDelete = (date) => {
        const params = {
            memberID: memberID,
            assignDate: parseDate(date),
        };
        deleteRecordApi(params);
    };

    const deleteRecordApi = async (params) => {
        const resp = await deleteWorkoutRecordApi(params);
        if (resp) {
            onClose();
            typeof refresh === 'function' && refresh();
        }
    };

    if (!open) return null;

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-40rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>{title}</GradientColorHeader>
            <CheckInDialogContent data={data} />
            <DialogActions>
                <Button variant="contained" onClick={() => handleDelete(data.title)}>
                    刪除紀錄
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CheckInDialog;
