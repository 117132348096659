import React, { useLayoutEffect, useRef, useImperativeHandle, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Typography, SelectField, MenuItem, AutocompleteField, FormControlLabel, Checkbox } from '@common/components/';
import { ReadTextField, SignBox } from '@icoach/components/';
import { FieldGroup } from '@icoach/documents/components/Layout';
import usePeopleApi, { peopleType as peopleTypeMap } from '@apis/usePeopleApi';
import { refIsRequiredError } from '@util/utils';
import { CHOOSE_TARGET } from '@icoach/documents/membership/staticData';
import { getAutocompleteFieldOptionKeys } from '@icoach/documents/components/SearchBar';
import FreeSoloCreateOptionDialog from '@icoach/nonMember/customer/FreeSoloCreateOptionDialog';

const MutilPeopleComponent = React.forwardRef((props, ref) => {
    const {
        option: peopleTypeOption,
        peopleType: peopleTypeProps,
        peopleID: peopleIDProps,
        targetName: nameProps,
        targetCellPhone: cellPhoneProps,
        transfereeName,
        readOnly,
    } = props;
    const [peopleType, setPeopleType] = useState(peopleTypeProps || peopleTypeMap.customer);
    const [option, setOption] = useState([]);
    const { people: peopleOption = [] } = usePeopleApi(peopleType);
    const targetTypeRef = useRef(null);
    const peopleIDRef = useRef(null);

    // 對象更新
    const handlePeopleTypeOnChange = (e, target) => {
        const { value } = target.props;
        setPeopleType(value);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    let peopleModel = {};
                    if (String(peopleType) === '99') {
                        const { name, cellphone } = peopleIDRef.current.getResult();
                        peopleModel.targetID = 0;
                        peopleModel.targetType = String(peopleType);
                        peopleModel.targetName = name || nameProps;
                        peopleModel.targetCellPhone = cellphone || cellPhoneProps;
                    } else {
                        peopleModel.targetID = peopleIDRef.current?.getResult('id') ?? 0;
                        peopleModel.targetType = targetTypeRef.current.getResult();
                        peopleModel.targetName = '';
                        peopleModel.targetCellPhone = '';
                    }
                    return peopleModel;
                },
                isError: () => refIsRequiredError(targetTypeRef, peopleIDRef),
            };
        },
        // eslint-disable-next-line
        [peopleType],
    );

    useEffect(
        () => {
            setOption(peopleOption);
        },
        // eslint-disable-next-line
        [peopleOption, peopleType],
    );

    return (
        <FieldGroup label={'資料填寫'}>
            <Grid item xs={4}>
                <SelectField
                    ref={targetTypeRef}
                    value={peopleType || null}
                    label={'對象'}
                    fullWidth
                    onChange={handlePeopleTypeOnChange}
                    displayEmpty
                    required
                    readOnly={readOnly}
                >
                    <MenuItem value={''} disabled>
                        <em>請選擇</em>
                    </MenuItem>
                    {peopleTypeOption.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
            <Grid item xs={6}>
                {readOnly ? (
                    <ReadTextField label={CHOOSE_TARGET[peopleType]?.label}>{transfereeName}</ReadTextField>
                ) : String(peopleType) !== '99' ? (
                    <AutocompleteField
                        key={option}
                        ref={peopleIDRef}
                        label={CHOOSE_TARGET[peopleType]?.label}
                        defaultValue={peopleIDProps}
                        options={option}
                        optionKeys={getAutocompleteFieldOptionKeys(peopleType)}
                        primaryKey={'id'}
                        inputProps={{
                            placeholder: CHOOSE_TARGET[peopleType]?.placeholder,
                        }}
                        InputProps={{ readOnly: readOnly }}
                        fullWidth
                        required
                    />
                ) : (
                    <FreeSoloCreateOptionDialog
                        label={CHOOSE_TARGET[peopleType]?.label}
                        options={[{ id: `${nameProps}${cellPhoneProps}`, name: nameProps, mobile: cellPhoneProps }]}
                        optionKeys={['name', 'mobile']}
                        defaultValue={`${nameProps}${cellPhoneProps}`}
                        ref={peopleIDRef}
                        inputProps={{
                            placeholder: CHOOSE_TARGET[peopleType]?.placeholder,
                        }}
                        fullWidth
                        required
                    />
                )}
            </Grid>
        </FieldGroup>
    );
});

// 承接會員
const MembershipTransferOverContentTaker = React.forwardRef((props, ref) => {
    const { tmpSourceData = {}, options, isReadOnly } = props;
    const itemsRef = useRef([]);
    const targetPeopleRef = useRef(null); // 承接人 id 與 type
    const targetSignRef = useRef(null); // 承接會員 簽名

    const setParams = () => {
        if (isReadOnly) return false;
        const { targetID: id, targetType: type, targetName: name = '', targetCellPhone: cellPhone = '' } = targetPeopleRef.current.getResult();
        tmpSourceData.current.targetType = type;
        tmpSourceData.current.targetID = id;
        tmpSourceData.current.targetName = name || '';
        tmpSourceData.current.targetCellPhone = cellPhone || '';
        tmpSourceData.current.survey.items[4] = itemsRef.current[4].checked;
        tmpSourceData.current.survey.items[5] = itemsRef.current[5].checked;
        tmpSourceData.current.survey.items[6] = itemsRef.current[6].checked;
        tmpSourceData.current.survey.items[7] = itemsRef.current[7].checked;
        tmpSourceData.current.targetSignImg = targetSignRef.current.getResult();
    };
    useImperativeHandle(
        ref,
        () => ({
            isError: () => refIsRequiredError(targetPeopleRef, targetSignRef),
            getResult: () => {
                setParams();
            },
        }),
        // eslint-disable-next-line
        [],
    );

    useLayoutEffect(
        () => {
            return () => {
                setParams();
            };
        },
        // eslint-disable-next-line
        [],
    );

    const { survey = {}, targetSignImg = '', targetType = null, targetID = null, transfereeName, targetName, targetCellPhone } = tmpSourceData.current;
    const { items } = survey;
    const { targetOptions = [] } = options;

    return (
        <React.Fragment>
            <MutilPeopleComponent
                ref={targetPeopleRef}
                option={targetOptions}
                peopleType={targetType}
                peopleID={targetID}
                targetName={targetName}
                targetCellPhone={targetCellPhone}
                transfereeName={transfereeName}
                readOnly={isReadOnly}
            />
            <FieldGroup label={'確認事項'}>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h3" className="mb-2 mt-0">
                        <span className={'mark'}>承接人</span>
                        請詳讀以下會員籍轉讓條款，若同意遵守請打勾之後於下方簽名：
                    </Typography>
                    <ul className="lists controler">
                        <li className="lists-item">
                            <FormControlLabel
                                control={<Checkbox defaultChecked={items[4]} inputRef={(el) => (itemsRef.current[4] = el)} readOnly={isReadOnly} />}
                                label="承接人可於會員籍生效日後，開始享有Curves會員權益與義務，並正式與本公司締結契約。"
                            />
                        </li>
                        <li className="lists-item">
                            <FormControlLabel
                                control={<Checkbox defaultChecked={items[5]} inputRef={(el) => (itemsRef.current[5] = el)} readOnly={isReadOnly} />}
                                label="轉讓申請書上應由會員與承接人簽署，流程完成之前，原會員保有使用器材之權利和付款義務。"
                            />
                        </li>
                        <li className="lists-item">
                            <FormControlLabel
                                control={<Checkbox defaultChecked={items[6]} inputRef={(el) => (itemsRef.current[6] = el)} readOnly={isReadOnly} />}
                                label="承接人加入成為Curves會員後，若於契約期限截止前提出終止契約，須依契約內容承接所有權利義務，包含但不限於終止手續費。"
                            />
                        </li>
                        <li className="lists-item">
                            <FormControlLabel
                                control={<Checkbox defaultChecked={items[7]} inputRef={(el) => (itemsRef.current[7] = el)} readOnly={isReadOnly} />}
                                label="承接人加入成為Curves會員後，運動次數重新計算不進行承接。"
                            />
                        </li>
                    </ul>
                </Grid>
                {!isReadOnly && (
                    <Grid xs={6} item>
                        <SignBox className={'ml-2'} title={'承接會員簽名'} defaultValue={targetSignImg || null} ref={targetSignRef} />
                    </Grid>
                )}
            </FieldGroup>
        </React.Fragment>
    );
});

export default MembershipTransferOverContentTaker;
