import React from 'react';
import { useAccountBookContext } from './components/AccountBookContext';
import BatchPaymentOrdersCreditCardTable from '@icoach/accountBook/tables/BatchPaymentOrdersCreditCardTable';

const BatchPaymentOrderHCPage = () => {
    const { tableData = {} } = useAccountBookContext();
    const { creditItems } = tableData;

    return <BatchPaymentOrdersCreditCardTable className={'cycle-order-table'} rowsData={creditItems} />;
};

export default BatchPaymentOrderHCPage;
