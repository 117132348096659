import React from 'react';
import { Stack } from '../../components/';

const FontTestPage = React.forwardRef((props, ref) => {
    return (
        <div className="container main-container-spacing">
            <Stack direction="column" spacing="2">
                <span style={{ fontWeight: 300 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 400 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 500 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 700 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 300, fontSize: 24 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 400, fontSize: 24 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 500, fontSize: 24 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 700, fontSize: 24 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 300, fontSize: 32 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 400, fontSize: 32 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 500, fontSize: 32 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
                <span style={{ fontWeight: 700, fontSize: 32 }}>
                    春眠不覺曉，處處蚊子多。夜來巴掌聲，蚊子死多少。
                </span>
            </Stack>
        </div>
    );
});

export default FontTestPage;
