import React, { useEffect, useState } from 'react';
import { Stack, IconButton } from '@common/components/';
import { CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, Check as CheckIcon } from '@common/SvgIcon/';
import useAppointmentApi from '@apis/useAppointmentApi';
import { isEmpty } from '@util/utils';
import { FaEnum } from '@icoach/landingPage/staticData';

const AppointmentTelemarketingBox = React.forwardRef((props = {}, ref) => {
    const { apoId, value: valueProps, className: classNameProps, apoType } = props;
    if (!FaEnum.includes(String(apoType))) return null;
    const { putAppointmentTelemarketingApi } = useAppointmentApi();
    const [showState, setShowState] = useState(valueProps);

    const handleShowOnChange = (type) => {
        const params = { appointmentID: apoId, hasTelemarketing: type };
        doPutTelemarketingApi(type, params);
    };

    const doPutTelemarketingApi = async (type, params) => {
        const resp = await putAppointmentTelemarketingApi(params);
        if (resp) {
            setShowState(type);
        }
    };

    useEffect(
        () => {
            if (isEmpty(showState)) {
                setShowState(valueProps);
            }
        },
        // eslint-disable-next-line
        [valueProps]
    );

    return (
        <Stack direction="column" alignItems="center" spacing={1}>
            <span className={classNameProps}>推進</span>
            {String(showState) === '0' && (
                <IconButton onClick={() => handleShowOnChange(1)}>
                    <CheckBoxOutlineBlankIcon />
                </IconButton>
            )}
            {String(showState) === '1' && (
                <IconButton onClick={() => handleShowOnChange(0)}>
                    <CheckIcon color="success" />
                </IconButton>
            )}
        </Stack>
    );
});

export default AppointmentTelemarketingBox;
