import React from 'react';
import { Card } from '@common/components/';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { ReadTextField } from '@icoach/components';
import { displayDateText, getAddMonths } from '@util/moment';

const ResuspensionSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { originalSuspendStartDate, originalSuspendEndDate, reSuspendDate } = data;

    return (
        <React.Fragment>
            <Card.CardHeader title="暫停恢復" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={6} item>
                        <ReadTextField label={'原暫停期間'}>
                            {displayDateText(originalSuspendStartDate, originalSuspendEndDate, {
                                format: 'YYYY/MM',
                            })}
                        </ReadTextField>
                    </Grid>
                    <Grid xs={6} item>
                        <ReadTextField label={'原恢復出貨月份'}>
                            {displayDateText(getAddMonths(originalSuspendEndDate, 1), null, {
                                format: 'YYYY/MM',
                            })}
                        </ReadTextField>
                    </Grid>
                    <Grid xs={6} item>
                        <ReadTextField label={'恢復出貨月份'}>{displayDateText(reSuspendDate, null, { format: 'YYYY/MM' })}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

export default ResuspensionSectionByRead;
