import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const WheyOrdered = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#51A69E', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M9.3,17.4h5.4l1.1-10.8H8.2L9.3,17.4z M14.7,9.3L14,16.6h-4L9.3,9.3H14.7z" />
            <path
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M15.8,18.5H8.2l-1.1-13h5.7v0h1.1v0h3
		L15.8,18.5z"
            />
        </SvgIcon>
    );
});

export default WheyOrdered;
