import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Store = React.forwardRef((props, ref) => {
    const { className, htmlColor, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M23.457,4.621,20.928.584A1.247,1.247,0,0,0,19.873,0H4.13A1.247,1.247,0,0,0,3.074.584L.546,4.621A3.476,3.476,0,0,0,2.837,9.936a4.049,4.049,0,0,0,.534.035,3.848,3.848,0,0,0,2.876-1.29,3.851,3.851,0,0,0,5.751,0,3.851,3.851,0,0,0,5.751,0,3.86,3.86,0,0,0,2.876,1.29,4.006,4.006,0,0,0,.534-.035A3.473,3.473,0,0,0,23.457,4.621Zm-2.825,6.6a4.944,4.944,0,0,1-1.149-.148v3.889H4.52V11.074a5.162,5.162,0,0,1-1.149.148,5.258,5.258,0,0,1-.7-.047,4.9,4.9,0,0,1-.639-.14V18.7A1.246,1.246,0,0,0,3.277,19.95H20.734A1.246,1.246,0,0,0,21.981,18.7V11.035a3.978,3.978,0,0,1-.639.14A5.419,5.419,0,0,1,20.632,11.222Z"
                transform="translate(-0.002 2)"
            />
        </SvgIcon>
    );
});

export default Store;
