import React, { useRef, useState, useMemo } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import InBodyInterviewDialogBox from '@icoach/inbody/interview/InBodyInterviewDialogBox';
import { InBodyInterviewMeasureCompareBox, inBodyInterviewMeasureItems } from '@icoach/inbody/interview/InBodyInterviewMeasureNormalBox';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { Box, Card } from '@common/components/';
import InBodyExtendDiv from '@icoach/inbody/components/InBodyExtendDiv';

const InBodyInterviewMeasureTable = React.forwardRef((props, ref) => {
    const { sourceData = [] } = props;
    const divRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(1);
    const measureDateOptions = useMemo(
        () => {
            let opts = sourceData.map(({ measureDate }, index) => ({
                text: measureDate,
                value: index,
            }));
            opts.shift();
            return opts;
        },
        // eslint-disable-next-line
        [sourceData],
    );
    return (
        <Card>
            <Card.CardContent>
                <Box className="inbody-measure inbody-measure-compare scroll-arrow-container">
                    <Box className="container" ref={divRef}>
                        {inBodyInterviewMeasureItems.map(({ value, key, rowClass, formatCell, formatCompare, formatMutipleCell, style: styleProps = {} }) => {
                            let styles = { ...styleProps };
                            return (
                                // row
                                <Box key={value} className={clsx('row', rowClass, sourceData.length === 0 && 'full-width')} style={{ ...styles }}>
                                    {/* Column 標題 */}
                                    <Box className={clsx('item', 'sticky', 'label')} style={{ width: '180px' }}>
                                        {value}
                                    </Box>
                                    {/*比較內容*/}
                                    {!_.isEmpty(sourceData) && sourceData.length > 1 && (
                                        <InBodyInterviewMeasureCompareBox
                                            datumData={sourceData[0]}
                                            compareData={sourceData[selectedItem]}
                                            measureDateOptions={measureDateOptions}
                                            itemKey={key}
                                            formatCell={formatCell}
                                            formatCompare={formatCompare}
                                            formatMutipleCell={formatMutipleCell}
                                            selectedItem={selectedItem}
                                            setSelectedItem={setSelectedItem}
                                            styles={styles}
                                        />
                                    )}
                                    <InBodyExtendDiv />
                                </Box>
                            );
                        })}
                    </Box>
                    <ScrollerArrowButton arrowLeftPos={600} parentRef={divRef} />
                    <InBodyInterviewDialogBox />
                </Box>
            </Card.CardContent>
        </Card>
    );
});

export default InBodyInterviewMeasureTable;
