import React, { useRef, useLayoutEffect, useImperativeHandle, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import MembershipTransferOverFeeStatement from '@icoach/documents/membership/transferOver/MembershipTransferOverFeeStatement';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { Typography, FormControlLabel, Checkbox, Stack, Button, CheckboxField, Box } from '@common/components/';
import { ReadTextField, SignBox } from '@icoach/components/';
import { displayDateLength } from '@util/utils';
import { displayDateText } from '@util/moment';
import { CheckoutCreditOrderDialog } from '@icoach/members/memberDetail/dialog';
import useToggleDialog from '@util/hook/useToggleDialog';
import useOrderCheckoutApi from '@apis/useOrderCheckoutApi';

// 提醒教練一定要確認會員的欠費與狀態 --- 後端不會記
const CheckMemberStateContent = (props) => {
    const { className, style, handleOpenDialog } = props;
    return (
        <Stack className={className} style={style} flexWrap={'wrap'}>
            <Box className={'mr-2 mb-2'}>會員會籍狀態有效，不在暫停期間內，且需要結清所有款項</Box>
            <Button variant="outlined" onClick={() => handleOpenDialog('checkout')}>
                查看欠費明細
            </Button>
        </Stack>
    );
};

// 原會員
const MembershipTransferOverContentGiver = React.forwardRef((props, ref) => {
    const { sourceData = {}, tmpSourceData = {}, isReadOnly } = props;
    const { getMemberInfoCardApi } = useOrderCheckoutApi();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const [orderSourceData, setOrderSourceData] = useState({});
    const { orderData, resource } = orderSourceData;
    const itemsRef = useRef([]);
    const referenceSignRef = useRef(null);
    const membershipTransferOverFeeStatementRef = useRef(null);

    const setParams = () => {
        if (isReadOnly) return false;
        // 確認事項
        tmpSourceData.current.survey.items[0] = itemsRef.current[0].checked;
        tmpSourceData.current.survey.items[1] = itemsRef.current[1].checked;
        tmpSourceData.current.survey.items[2] = itemsRef.current[2].checked;
        tmpSourceData.current.survey.items[3] = itemsRef.current[3].checked;
        tmpSourceData.current.referenceSignImg = referenceSignRef.current.getResult();
        membershipTransferOverFeeStatementRef.current.getResult();
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => setParams(),
        }),
        // eslint-disable-next-line
        [],
    );

    useLayoutEffect(
        () => {
            return () => {
                setParams();
            };
        },
        // eslint-disable-next-line
        [],
    );

    const { clubName, memberID, memberNo, memberName, cellPhone, membershipStartDate, memberCardNo, membershipEndDate, suspendMonth, suspendDay } = sourceData;

    const { survey = {}, referenceSignImg = '' } = tmpSourceData.current;
    const { items } = survey;

    const getMemberOrderInfoApi = async (memberID) => {
        let res = await getMemberInfoCardApi(memberID);
        if (res) {
            setOrderSourceData(res);
        }
    };

    useEffect(() => {
        if (memberID) {
            getMemberOrderInfoApi(memberID);
        }
        // eslint-disable-next-line
    }, [memberID]);
    return (
        <React.Fragment>
            <FieldGroup label={'會員資料'}>
                <Grid item xs={4}>
                    <ReadTextField label="所屬店舖">{clubName}</ReadTextField>
                </Grid>
                <Grid item xs={4}>
                    <ReadTextField label="姓名">{memberName}</ReadTextField>
                </Grid>
                <Grid item xs={4}>
                    <ReadTextField label="手機">{cellPhone}</ReadTextField>
                </Grid>
                <Grid item xs={4}>
                    <ReadTextField label="會員編號">{memberNo}</ReadTextField>
                </Grid>
                <Grid item xs={4}>
                    <ReadTextField label="簽到卡號">{memberCardNo}</ReadTextField>
                </Grid>
                <Grid item xs={4}>
                    <ReadTextField label="會籍起訖日">{displayDateText(membershipStartDate, membershipEndDate)}</ReadTextField>
                </Grid>
                <Grid item xs={4}>
                    <ReadTextField label="會員權暫停">{displayDateLength(suspendMonth, suspendDay)}</ReadTextField>
                </Grid>
            </FieldGroup>
            <FieldGroup label={'確認事項'}>
                <Grid xs={12} item>
                    <Typography variant="h6" component="h3" className="mb-2 mt-0">
                        <span className={'mark'}>原會員</span>
                        請詳讀以下會員籍轉讓條款，若同意遵守請打勾之後於下方簽名：
                    </Typography>
                    <ul className={'lists controler'}>
                        <li className="lists-item">
                            <FormControlLabel
                                control={<Checkbox defaultChecked={items[0]} inputRef={(el) => (itemsRef.current[0] = el)} readOnly={isReadOnly} />}
                                label="承接人可於會員籍生效日後，開始享有Curves會員權益與義務，並正式與本公司締結契約。"
                            />
                        </li>
                        <li className="lists-item">
                            <FormControlLabel
                                control={<Checkbox defaultChecked={items[1]} inputRef={(el) => (itemsRef.current[1] = el)} readOnly={isReadOnly} />}
                                label="會員籍轉讓費用為300元，轉讓申請書上應由會員與承接人簽署，流程完成之前，原會員保有使用器材之權利和付款義務。"
                            />
                        </li>
                        <li className="lists-item">
                            <FormControlLabel
                                control={<Checkbox defaultChecked={items[2]} inputRef={(el) => (itemsRef.current[2] = el)} readOnly={isReadOnly} />}
                                label="承接人加入成為Curves會員後，若於契約期限截止前提出終止契約，須依契約內容承接所有權利義務，包含但不限於終止手續費。"
                            />
                        </li>
                        <li className="lists-item">
                            <FormControlLabel
                                control={<Checkbox defaultChecked={items[3]} inputRef={(el) => (itemsRef.current[3] = el)} readOnly={isReadOnly} />}
                                label="繳回會員電子簽到卡。若遺失電子簽到卡，須補工本費"
                            />
                        </li>
                    </ul>
                </Grid>
                {!isReadOnly && (
                    <Grid xs={6} item>
                        <SignBox className={'ml-2'} title={'原會員簽名'} ref={referenceSignRef} defaultValue={referenceSignImg || null} />
                    </Grid>
                )}
            </FieldGroup>
            <FieldGroup label={'費用明細'}>
                <MembershipTransferOverFeeStatement ref={membershipTransferOverFeeStatementRef} tmpSourceData={tmpSourceData} isReadOnly={isReadOnly} />
            </FieldGroup>
            <FieldGroup label={''}>
                <Grid xs={12} item>
                    <CheckboxField required>
                        <CheckboxField.Checkbox label={<CheckMemberStateContent handleOpenDialog={handleOpenDialog} />} />
                    </CheckboxField>
                </Grid>
            </FieldGroup>
            <CheckoutCreditOrderDialog
                open={openTarget === 'checkout'}
                title={'欠費明細'}
                data={orderData}
                memberID={memberID}
                resource={resource}
                onClose={handleCloseDialog}
                refresh={getMemberOrderInfoApi}
            />
        </React.Fragment>
    );
});

export default MembershipTransferOverContentGiver;
