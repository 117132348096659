import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import clsx from 'clsx';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, SelectField, MenuItem, TextField, CheckboxField } from '@common/components/';
import useEmployeeApi from '@apis/useEmployeeApi';
import { refIsRequiredError } from '@util/utils';

const ConfirmDeleteDocDialog = (props) => {
    const { className, open, onClose, sourceData, deleteDocApi, refresh } = props;
    const [options, setOptins] = useState([]);
    const employeeIDRef = useRef(null);
    const reasonRef = useRef(null);
    const confirmRef = useRef(null);
    const { getEmployeesOptionsApi } = useEmployeeApi();

    const checkError = () => {
        return refIsRequiredError(employeeIDRef, reasonRef, confirmRef);
    };

    const handleSubmit = () => {
        if (!checkError()) {
            const params = {
                contractID: sourceData.contractID,
                leaveEmployeeID: employeeIDRef.current.getResult(),
                leaveReason: reasonRef.current.getResult(),
            };
            doCancelSuspendDocumentApi(params);
        }
    };

    const doCancelSuspendDocumentApi = async (params) => {
        let res = await deleteDocApi(sourceData, params);
        if (res) {
            onClose();
            refresh && refresh();
        }
    };

    const doInitOptionsApi = async () => {
        const resp = await getEmployeesOptionsApi();
        if (resp) {
            setOptins(resp);
        }
    };

    useEffect(
        () => {
            if (open && options.length === 0) {
                doInitOptionsApi();
            }
        },
        // eslint-disable-next-line
        [open, options]
    );

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-40rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>銷假申請單</GradientColorHeader>
            <DialogContent>
                <Grid spacing={3} container>
                    <Grid xs={12} item>
                        <SelectField label="經辦教練" ref={employeeIDRef} fullWidth displayEmpty required>
                            <MenuItem value={''} disabled>
                                <em>請選擇</em>
                            </MenuItem>
                            {options.map(({ text, value, disabled }) => (
                                <MenuItem value={value} key={value} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField label="銷假原因" ref={reasonRef} rows={6} multiline fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <strong className={'text-error'}>&#42;</strong>銷假後資料就<strong className={'text-error'}>無法恢復</strong>，將回歸正常會籍。
                    </Grid>
                    <Grid xs={12} item>
                        <CheckboxField className={'mb-1'} ref={confirmRef} required>
                            <CheckboxField.Checkbox label={'會員已同意註銷此文書'} value={true} />
                        </CheckboxField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit}>
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteDocDialog;
