import React, { useCallback, useRef } from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import Tablenization from '../../components/Tablenization';
import { DateLabel } from '../components';
import { isValidDate } from '../../../util/moment';
import { getRowsDataTotal } from '../staticData';

const rowsLabel = [
    {
        cellKey: 'date',
        headerLabel: '日期',
        width: 12,
        formatCell: (date) => {
            if (isValidDate(date)) return <DateLabel date={date} />;
            return date;
        },
    },
    {
        cellKey: 'woAmount',
        headerLabel: 'WO數',
        align: 'center',
    },
    {
        cellKey: 'measureAmount',
        headerLabel: '量身數',
        align: 'center',
    },
];

const ServiceDailyTable = (props) => {
    const { rowsData, className } = props;
    const labelTitle = useRef(rowsLabel);

    const handleRowsData = useCallback(() => {
        let totalRow = [
            {
                ...getRowsDataTotal(rowsData),
                date: '最新數字',
            },
        ];
        let array = [...rowsData];
        if (!isEmpty(totalRow)) array = totalRow.concat(array);
        return array;
    }, [rowsData]);

    return (
        <Tablenization cardProps={{ className: clsx('daily-table', className) }}>
            <Tablenization.TableRowHeader headerRow={labelTitle.current} />
            <Tablenization.TableRowBody
                headerRow={labelTitle.current}
                rowsData={handleRowsData()}
                bodyRowClass={{ key: 'date', value: '總計', className: 'total-row' }}
            />
        </Tablenization>
    );
};

export default ServiceDailyTable;
