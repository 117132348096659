import React, { useState, useLayoutEffect, useImperativeHandle } from 'react';
import clsx from 'clsx';
import SwitchBase from '../internal/SwitchBase';
import {
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    IndeterminateCheckBox as IndeterminateCheckBoxIcon,
    CheckBox as CheckBoxIcon,
} from '../icons/SvgIcon/';

const colorMap = ['primary', 'secondary', 'error', 'info', 'success', 'warning'];
const sizeMap = ['medium', 'small'];
const staticClass = `checkbox`;

const defaultCheckedIcon = (size = 'medium', color, disabled = false) => <CheckBoxIcon fontSize={size} color={color} disabled={disabled} />;
const defaultIcon = (size = 'medium', color, disabled = false) => <CheckBoxOutlineBlankIcon fontSize={size} color={color} disabled={disabled} />;
const defaultIndeterminateIcon = (size = 'medium', color, disabled = false) => <IndeterminateCheckBoxIcon fontSize={size} color={color} disabled={disabled} />;

const Checkbox = React.forwardRef((props, ref) => {
    const {
        inputRef,
        indeterminateRef,
        className,
        disabled = false,
        color = 'secondary',
        size = 'medium',
        checkedIcon = defaultCheckedIcon(size, color, disabled),
        icon: iconProp = defaultIcon(size, color, disabled),
        indeterminateIcon: indeterminateIconProp = defaultIndeterminateIcon(size, color, disabled),
        indeterminate = false,
        inputProps,
        onChange: propsOnChange,
        defaultChecked,
        disableRipple = false,
        ...other
    } = props;

    const [indeterminateState, setIndeterminateState] = useState(indeterminate);
    let classNames = staticClass;
    if (colorMap.indexOf(color) !== -1) classNames = clsx(classNames, `${staticClass}-color-${color}`);
    if (sizeMap.indexOf(size) !== -1) classNames = clsx(classNames, `${staticClass}-size-${size}`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (disableRipple) classNames = clsx(classNames, `${staticClass}-disabled-ripple`);

    const icon = indeterminateState ? indeterminateIconProp : iconProp;
    const indeterminateIcon = indeterminateState ? indeterminateIconProp : checkedIcon;

    const defaultProperty = {
        type: 'checkbox',
        className: clsx(classNames, className),
        icon,
        checkedIcon: indeterminateIcon,
        color,
        size,
        inputRef,
        disabled,
        onChange: propsOnChange,
        inputProps: {
            'data-indeterminate': indeterminateState,
            ...inputProps,
        },
        defaultChecked,
        ref,
        ...other,
    };
    
    useImperativeHandle(
        indeterminateRef,
        () => ({
            setIndeterminate: (isIndeterminate) => setIndeterminateState(isIndeterminate),
        }),
        // eslint-disable-next-line
        []
    );

    useLayoutEffect(
        () => {
            setIndeterminateState(indeterminate);
        },
        // eslint-disable-next-line
        [indeterminate]
    );

    return <SwitchBase {...defaultProperty} />;
});

export default Checkbox;
