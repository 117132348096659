import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { parseDate } from '@util/moment';
import { refIsRequiredError, isEmpty } from '@util/utils';
import useShowPDF from '@util/hook/useShowPDF';
import { Card, SelectField, MenuItem, DateField, Typography, Alert } from '@common/components/';
import useDocMembershipTransferOver from '@apis/useDocMembershipTransferOverApi';
import PromptDialog from '@icoach/components/dialog/PromptDialog';
import { Stepper, TermContent } from '@icoach/documents/components/';
import MembershipTransferOverContentSkeleton from '@icoach/documents/membership/transferOver/MembershipTransferOverContentSkeleton';
import MembershipTransferOverContentGiver from '@icoach/documents/membership/transferOver/MembershipTransferOverContentGiver';
import MembershipTransferOverContentTaker from '@icoach/documents/membership/transferOver/MembershipTransferOverContentTaker';
import terms from '@icoach/documents/membership/transferOver/terms';

// 入會申請書步驟內容
const tabsData = [
    {
        id: 'originally',
        label: '原會員',
        content: <MembershipTransferOverContentGiver />,
    },
    {
        id: 'takeOver',
        label: '承接會員',
        content: <MembershipTransferOverContentTaker />,
    },
];

const MembershipTransferOverContent = (props) => {
    const { isReadOnly = false, onReturnHistory } = props;
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps, contractID: contractIDProps } = useParams();
    const { getTransferOverContractApi, postTransferOverContractApi, postDocTemporaryTransferOverContractApi } = useDocMembershipTransferOver();
    const showPDF = useShowPDF();
    const [showPrompt, setShowPrompt] = useState(true);
    const [sourceData, setSourceData] = useState({});
    const [options, setOptions] = useState({});
    const currentContentRef = useRef(null);
    const employeeIDRef = useRef(null);
    const applyDateRef = useRef(null);
    const effectiveDateRef = useRef(null);
    const apiSendingRef = useRef(false);
    let tmpSourceData = useRef({}); // 頁面資料來源
    const { isCompleteContract } = tmpSourceData.current; // 是否為正式文件

    const setParam = () => {
        tmpSourceData.current.employeeID = employeeIDRef.current.getResult() || 0;
        tmpSourceData.current.applyDate = parseDate(applyDateRef.current.getResult());
        tmpSourceData.current.effectiveDate = parseDate(effectiveDateRef.current.getResult());
    };

    const getAllParams = () => {
        const {
            contractID,
            referenceType,
            referenceID,
            targetType,
            targetID,
            survey,
            contractVersion,
            mainID,
            employeeID,
            clubID,
            applyDate,
            effectiveDate,
            transferFee,
            cost,
            cashAmount,
            creditCardAmount,
            contractStatus,
            targetCellPhone,
            targetName,
        } = tmpSourceData.current;
        const params = {
            contractID,
            referenceType,
            referenceID,
            targetType,
            targetID,
            survey,
            contractVersion,
            mainID,
            employeeID,
            clubID,
            applyDate,
            effectiveDate,
            transferFee,
            cost,
            cashAmount,
            creditCardAmount,
            contractStatus,
            targetCellPhone,
            targetName,
        };
        return params;
    };

    const handleSubmit = () => {
        if (apiSendingRef.current) return false;
        if (!refIsRequiredError(employeeIDRef, applyDateRef, currentContentRef)) {
            setParam();
            currentContentRef.current.getResult(); // 設定tmpSourceData 裡的物件
            const params = getAllParams();
            params.targetSignImg = tmpSourceData.current.targetSignImg;
            params.referenceSignImg = tmpSourceData.current.referenceSignImg;
            apiSendingRef.current = true;
            postTransferOverContractDataApi(contractIDProps, params);
        }
    };

    const handelTemporaryStorage = () => {
        if (apiSendingRef.current) return false;
        setParam();
        currentContentRef.current.getResult(); // 設定tmpSourceData 裡的物件
        const params = getAllParams();
        apiSendingRef.current = true;
        postTemporaryTransferOverContractDataApi(params);
    };

    // 送出 轉讓申請書
    const postTransferOverContractDataApi = async (contractID, params) => {
        const resp = await postTransferOverContractApi(contractID, params);
        if (resp) {
            showPDF({
                open: true,
                pdfUrl: resp.completedPath,
            });
            setShowPrompt(false);
            window.setTimeout(() => onReturnHistory && onReturnHistory(), 5);
        } else {
            apiSendingRef.current = false;
        }
    };

    // 送出 暫存轉讓申請書
    const postTemporaryTransferOverContractDataApi = async (params) => {
        const resp = await postDocTemporaryTransferOverContractApi(params);
        if (resp) {
            setShowPrompt(false);
            window.setTimeout(() => onReturnHistory && onReturnHistory(), 5);
        } else {
            apiSendingRef.current = false;
        }
    };

    // 初始轉讓申請書合約資料
    const getInitOverContractDataApi = async () => {
        const resp = await getTransferOverContractApi(contractIDProps, {
            id: referenceIDProps,
            type: peopleTypeProps,
        });
        if (resp) {
            const { resource, ...others } = resp;
            tmpSourceData.current = others;
            setSourceData(others);
            setOptions(resource);
        } else {
            // 如果失敗要失效
            setShowPrompt(false);
            window.setTimeout(() => onReturnHistory && onReturnHistory(), 5);
        }
    };

    useEffect(
        () => {
            getInitOverContractDataApi();
        },
        // eslint-disable-next-line
        []
    );

    const { coachOptions, targetOptions } = options;
    const { employeeID, applyDate, effectiveDate } = sourceData;

    return (
        <React.Fragment>
            {isEmpty(sourceData) || isEmpty(options) ? (
                <MembershipTransferOverContentSkeleton />
            ) : (
                <React.Fragment>
                    <TermContent
                        className="mb-4"
                        title="會員籍轉讓條款"
                        subheader={'依教育部體育署健身中心定型化契約'}
                        describe="我們邀請您與承接人填寫以下資料，以協助完成會籍轉讓手續。"
                        data={terms[102].data}
                    >
                        <Alert variant="filled" severity="info" color="info">
                            Curves 關心您身體健康及營養均衡的重要性，您可用循環優惠價持續訂購 Curves
                            健康產品。為了保障您的權益，如需調整訂單內容，請洽詢該店舖教練。
                        </Alert>
                    </TermContent>
                    <Card>
                        <Card.CardContent className={'p-4'}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <SelectField
                                        ref={employeeIDRef}
                                        label={'經辦教練'}
                                        defaultValue={employeeID || ''}
                                        fullWidth
                                        displayEmpty
                                        required
                                        readOnly={isReadOnly}
                                    >
                                        <MenuItem value={''} disabled>
                                            <em>請選擇</em>
                                        </MenuItem>
                                        {coachOptions.map(({ text, value, disabled }) => (
                                            <MenuItem value={value} key={value} disabled={disabled}>
                                                {text}
                                            </MenuItem>
                                        ))}
                                    </SelectField>
                                </Grid>
                                <Grid item xs={3}>
                                    <DateField
                                        label="申請日期"
                                        defaultValue={parseDate(applyDate)}
                                        ref={applyDateRef}
                                        fullWidth
                                        required
                                        readOnly={isReadOnly}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateField
                                        label="生效日期"
                                        defaultValue={parseDate(effectiveDate)}
                                        ref={effectiveDateRef}
                                        fullWidth
                                        required
                                        readOnly={isReadOnly}
                                    />
                                </Grid>
                            </Grid>
                        </Card.CardContent>
                        <Card.CardContent>
                            <Stepper
                                tabsProps={{ className: 'bg-light' }}
                                stepSourceContent={tabsData}
                                sourceData={sourceData}
                                tmpSourceData={tmpSourceData}
                                options={{ targetOptions }}
                                onSubmitEvent={handleSubmit}
                                onTemporaryStorageEvent={handelTemporaryStorage}
                                isReadOnly={isReadOnly}
                                ref={currentContentRef}
                                isTemporary={!isReadOnly && !isCompleteContract}
                                isSubmit={!isReadOnly}
                                isCancel={!isReadOnly}
                            />
                            <Typography variant="body2" className="bg-light text-center mb-0 pb-2 px-2 font-color-3">
                                版本 {sourceData.contractVersion}
                            </Typography>
                        </Card.CardContent>
                    </Card>
                    {!isReadOnly && <PromptDialog when={showPrompt} />}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MembershipTransferOverContent;
