import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const Heart = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#D02952', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M12,22.9C6,22.9,1.1,18,1.1,12
	S6,1.1,12,1.1S22.9,6,22.9,12S18,22.9,12,22.9z"
            />
            <path
                d="M12,2.2c-5.4,0-9.8,4.4-9.8,9.8c0,5.4,4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8C21.8,6.6,17.4,2.2,12,2.2z M18.5,11.1
	c-0.1,1.2-0.6,2.4-1.3,3.4c-0.8,1.1-1.8,2.1-3,2.9c0,0-1.3,1-1.9,1.4c-0.2,0.2-0.5,0.2-0.7,0c-0.6-0.4-1.9-1.4-1.9-1.4
	c-1.1-0.8-2.1-1.8-3-2.9c-0.7-1-1.2-2.1-1.3-3.4C5.3,8.8,6.7,6.7,8.9,6.7c1.3,0,2.5,0.7,3.1,1.9c0.6-1.1,1.8-1.9,3.1-1.9
	C17.4,6.7,18.7,8.8,18.5,11.1z"
            />
        </SvgIcon>
    );
});

export default Heart;
