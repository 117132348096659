import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Checkbox, Box, Typography, IconButton, Menu, MenuItem, ListItemText } from '@common/components/';
import { BusinessCardBox, MemoControllerBox } from '@icoach/components/';
import Tablenization from '@icoach/components/Tablenization';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { formatCurrencyFn, isEmpty } from '@util/utils';
import useGroupCheckbox from '@util/hook/useGroupCheckbox';
import { ACCOUNT_BOOK_ORDERS_ALLOWANCE_URL, ACCOUNT_BOOK_ORDERS_ORDER_URL } from '@icoach/router/AccountBookRouter';
import { COLOR_MATCH } from '@icoach/accountBook/staticData';
import { StatusChipTooltip } from '@icoach/rentCabinet/CabinetOverviewTable';
import useForkRef from '@util/hook/useForkRef';
import { MoreVertical as MoreVerticalIcon } from '@common/SvgIcon/';

export const ActionMenuButton = (props) => {
    const { menuData = [], actionButtonProps = {}, textAlign = 'left', enabled = true } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickActionButton = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // 為啟用與沒有操作項就不顯示
    if (!enabled || menuData.length < 1) return null;

    return (
        <React.Fragment>
            <IconButton {...actionButtonProps} onClick={handleClickActionButton}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu className={clsx('more-label-box-menu', `align-${textAlign}`)} anchorEl={anchorEl} open={open} onClose={(e) => handleCloseMenu(null)}>
                {menuData.map(({ text, ...others }) => {
                    return (
                        <MenuItem {...others} key={`more_label_${text}`}>
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

export const OrderMoreRowsLabel = {
    cellKey: 'more',
    headerLabel: '',
    align: 'right',
    isIcon: true,
    formatCell: (cellValue, rowData = {}, other) => {
        const { status: orderStatus, canAllowance, canInvalid, canDelete, canReissuing, orderID, orderDate, memberID, totalPrice } = rowData;
        const { isCash = false, goToOrderPage, goToAllowanceRecord, dialogRef, refresh: refreshProps, paramData } = other;
        const {
            openOrderAllowanceDialog,
            openDeleteOrderDialog,
            openReinitOrderDialog,
            openOrderInvoiceAbandonDialog,
            openPaymentOrderDialog,
            openResetOrderInvoiceDialog,
        } = dialogRef?.current ?? {};
        const isShowAllowanceRecord = true; // 常駐
        let menuData = [];

        const refresh = () => {
            if (typeof refreshProps === 'function') refreshProps(paramData);
        };

        const paymentActionData = {
            text: '付款',
            onClick: () => {
                if (typeof openPaymentOrderDialog === 'function')
                    openPaymentOrderDialog({ checkoutOrderIDs: [orderID], orderDate, receiverType: 1, receiverID: memberID, totalPrice }, refresh);
            },
        };

        const reviewOrderActionData = {
            text: '查看訂單',
            onClick: () => {
                if (typeof goToOrderPage === 'function') goToOrderPage(orderID);
            },
        };

        const allowanceActionData = {
            text: '訂單折讓',
            onClick: () => {
                if (typeof openOrderAllowanceDialog === 'function') openOrderAllowanceDialog({ orderID }, refresh);
            },
        };

        const invoiceAbandonActionData = {
            text: '作廢發票',
            onClick: () => {
                if (typeof openOrderInvoiceAbandonDialog === 'function') openOrderInvoiceAbandonDialog({ orderID }, refresh);
            },
        };

        const allowanceRecordActionData = {
            text: '折讓歷史',
            onClick: () => {
                if (typeof goToAllowanceRecord === 'function') goToAllowanceRecord(orderID);
            },
        };

        const deleteActionData = {
            text: '刪除訂單',
            onClick: () => {
                if (typeof openDeleteOrderDialog === 'function') openDeleteOrderDialog({ orderID }, refresh);
            },
        };

        const reinitActionData = {
            text: '恢復訂單',
            onClick: () => {
                if (typeof openReinitOrderDialog === 'function') openReinitOrderDialog({ orderID }, refresh);
            },
        };

        const resetOrderInvoiceActionData = {
            text: '重開發票',
            onClick: () => {
                if (typeof openResetOrderInvoiceDialog === 'function') openResetOrderInvoiceDialog({ orderID }, refresh);
            },
        };

        if (isCash) menuData = [...menuData, paymentActionData];
        if (!!orderID) menuData = [...menuData, reviewOrderActionData];
        if (canAllowance) menuData = [...menuData, allowanceActionData];
        if (canInvalid) menuData = [...menuData, invoiceAbandonActionData];
        if (canReissuing) menuData = [...menuData, resetOrderInvoiceActionData];
        if (isShowAllowanceRecord) menuData = [...menuData, allowanceRecordActionData];
        if (canDelete) menuData = [...menuData, deleteActionData];
        if (String(orderStatus) === '8') menuData = [...menuData, reinitActionData];

        return <ActionMenuButton menuData={menuData} />;
    },
};

export const checkRowsLabel = () => ({
    cellKey: 'orderID',
    isIcon: true,
    headerLabel: ({ allChkRef, indeterminateRef, handleCheckAll }) => {
        return <Checkbox inputRef={allChkRef} indeterminateRef={indeterminateRef} onChange={handleCheckAll} />;
    },
    formatCell: (orderID, rowsData, { chkRef, action, handleCheckOpt }) => {
        const { canPayment } = rowsData;
        let canSelect = () => {
            switch (action) {
                case 'bulk-debit':
                    return canPayment;
                default:
                    return true;
            }
        };
        return (
            <Checkbox
                inputRef={(el) => {
                    if (el && chkRef.current && chkRef.current.indexOf(el) === -1) {
                        chkRef.current.push(el);
                    }
                }}
                value={orderID}
                disabled={!canSelect()}
                onChange={handleCheckOpt}
            />
        );
    },
});

const rowsLabel = [
    {
        cellKey: 'memberSheet',
        headerLabel: '會員',
        align: 'left',
        maxWidth: 20.4,
        formatCell: (cellValue, { memberNo, memberName, memberPicturePath }) => {
            let memberInfo = {
                memberNo,
                memberName,
                memberImg: memberPicturePath,
            };
            return <BusinessCardBox rowData={memberInfo} />;
        },
    },
    {
        cellKey: 'statusText',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (cellValue, { status, statusColor = 0, processResult }) => {
            return <StatusChipTooltip statusText={cellValue} statusMemo={processResult} color={COLOR_MATCH[statusColor]} isShowTip={String(status) === '9'} />;
        },
    },
    {
        cellKey: 'itemName',
        headerLabel: '品項',
        formatCell: (cellValue, { itemQtySet }) => {
            if (!isEmpty(itemQtySet)) {
                return (
                    <Box component={'ul'} className={'item-list'}>
                        {itemQtySet.map((text, i) => {
                            return (
                                <Box component={'li'} key={i}>
                                    {text}
                                </Box>
                            );
                        })}
                    </Box>
                );
            }
            return cellValue;
        },
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '扣款金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'returnPrice',
        headerLabel: '退款金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票號碼',
        align: 'center',
    },
    {
        cellKey: 'creditCardNo',
        headerLabel: '信用卡',
        maxWidth: 21,
        formatCell: (cellValue, { cardExpiryDate }) => {
            return (
                <Box className="payment-card-content">
                    <Typography className={'title'} variant="body1" component={'h6'}>
                        {cellValue}
                    </Typography>
                    {cardExpiryDate ? (
                        <Typography className={'sub-title font-color-3'} variant="body2">
                            {`有效期限：${cardExpiryDate}`}
                        </Typography>
                    ) : (
                        ''
                    )}
                </Box>
            );
        },
    },
    {
        cellKey: 'notes',
        headerLabel: '備註',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
    { ...OrderMoreRowsLabel },
];

export const BatchPaymentOrdersTable = React.forwardRef((props, ref) => {
    const { className, rowsData: rowsDataProps = [], labelTitle, action = '', controllerRef, refresh, dialogRef, paramData } = props;
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [checkAllChange, checkOptChange, selectedAllRef, selectedOptRef, indeterminateRef] = useGroupCheckbox(tableData);
    const scrollerArrowRef = useRef(null);
    const currentDataRef = useRef([]);
    const selfRef = useRef(null);
    const forkRef = useForkRef(ref, selfRef);

    const handleController = (selectedData) => {
        if (Array.isArray(selectedData) && controllerRef && controllerRef.current) {
            const { setTotalOrderAmount, setCount } = controllerRef.current;

            if (typeof setTotalOrderAmount === 'function') {
                const totalOrderAmount = selectedData.reduce((total, currentData) => total + currentData.totalPrice, 0);
                setTotalOrderAmount(totalOrderAmount);
            }

            if (typeof setCount === 'function') {
                setCount(selectedData.length);
            }
        }
    };

    const handleCheck = (e, allChecked) => {
        const selectedData = selectedOptRef?.current?.reduce((accumulator, currentEl, currentIndex) => {
            const currentData = tableData[currentIndex];

            if ((allChecked && !currentEl.disabled) || (allChecked === undefined && currentEl.checked)) {
                accumulator.push({
                    orderID: currentData.orderID,
                    memberID: currentData.memberID,
                    nickName: currentData.nickName,
                    memberName: currentData.memberName,
                    totalPrice: currentData.totalPrice,
                });
            }
            return accumulator;
        }, []);

        currentDataRef.current = selectedData;

        handleController(selectedData);
    };

    const handleCheckAll = (e, checked) => {
        if (typeof checkAllChange === 'function') checkAllChange(e, checked);
        handleCheck(e, checked);
    };

    const handleCheckOpt = (e) => {
        if (typeof checkOptChange === 'function') checkOptChange(e);
        handleCheck(e);
    };

    const updateTableData = (data) => {
        const sortPaymentStatusData = (array) => {
            const _array = [...array];
            // 先排序「是否可以扣款」再排序「訂單狀態
            return _array.sort((a, b) => {
                if (b.canPayment > a.canPayment) return 1;
                if (b.canPayment < a.canPayment) return -1;

                if (b.status > a.status) return 1;
                if (b.status < a.status) return -1;

                return 0;
            });
        };
        setTableData(sortPaymentStatusData(data));
    };

    const goToOrderPage = (orderID) => {
        history.push(ACCOUNT_BOOK_ORDERS_ORDER_URL(orderID));
    };

    const goToAllowanceRecord = (orderID) => {
        history.push(ACCOUNT_BOOK_ORDERS_ALLOWANCE_URL(orderID));
    };

    useEffect(
        () => {
            if (Array.isArray(rowsDataProps)) {
                updateTableData(rowsDataProps);
            }
        },
        // eslint-disable-next-line
        [rowsDataProps],
    );

    useEffect(
        () => {
            currentDataRef.current = [];
            handleController([]);
        },
        // eslint-disable-next-line
        [tableData],
    );

    useImperativeHandle(
        forkRef,
        () => ({
            getCurrentData: () => currentDataRef.current,
            setTableData: (data) => updateTableData(data),
            rowsDataProps,
        }),
        // eslint-disable-next-line
        [rowsDataProps],
    );

    return (
        <React.Fragment>
            <Box className={'scroll-arrow-container'}>
                <Tablenization key={action} cardProps={{ className: clsx(className, 'bulk-debit-table') }} tableContainerRef={scrollerArrowRef}>
                    <Tablenization.TableRowHeader
                        headerRow={labelTitle}
                        allChkRef={selectedAllRef}
                        indeterminateRef={indeterminateRef}
                        handleCheckAll={handleCheckAll}
                    />
                    <Tablenization.TableRowBody
                        action={action}
                        headerRow={labelTitle}
                        rowsData={tableData}
                        bodyRowClass={
                            action === 'bulk-debit' && {
                                key: 'canPayment',
                                value: false,
                                className: 'disabled-row',
                            }
                        }
                        dialogRef={dialogRef}
                        chkRef={selectedOptRef}
                        handleCheckOpt={handleCheckOpt}
                        goToOrderPage={goToOrderPage}
                        goToAllowanceRecord={goToAllowanceRecord}
                        refresh={refresh}
                        paramData={paramData}
                    />
                </Tablenization>
                <ScrollerArrowButton key={`${action}_button`} parentRef={scrollerArrowRef} />
            </Box>
        </React.Fragment>
    );
});

const BatchPaymentOrdersCreditCardTable = React.forwardRef((props, ref) => {
    const { action } = props;
    const labelTitle = useMemo(() => {
        let array = [...rowsLabel];
        if (action) array = [checkRowsLabel(action), ...array];
        return array;
    }, [action]);

    return <BatchPaymentOrdersTable {...props} ref={ref} labelTitle={labelTitle} />;
});

export default BatchPaymentOrdersCreditCardTable;
