import React, { useRef, useImperativeHandle, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import { useMembershipContext } from '@icoach/documents/membership/create/MembershipContext';
import { ACH_INITIAL_STATE, CREDIT_INITIAL_STATE, PAYMENT_TYPE_OPTIONS } from '@icoach/documents/membership/staticData';
import { InvoiceCard, CreditCard, ACHCard } from '@icoach/documents/components';
import { FieldGroup } from '@icoach/documents/components/Layout';
import Tablenization from '@icoach/components/Tablenization';
import { Typography, DateField, DateIntervalField } from '@common/components/';
import { formatCurrencyFn, refIsRequiredError } from '@util/utils';
import { displayDateText, initialDate, parseDate } from '@util/moment';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';

const customerRowsLabel = [
    {
        cellKey: 'item',
        headerLabel: '項目',
    },
    {
        cellKey: 'date',
        headerLabel: '會籍期間',
    },
    {
        cellKey: 'fee',
        headerLabel: '每期金額',
        align: 'right',
    },
];

// 付款項目
const ShoppingList = ({ rowsData }) => {
    const rowsLabel = useRef(customerRowsLabel);
    return (
        <Tablenization cardProps={{ className: 'shopping-list theme-gray' }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel.current} />
            <Tablenization.TableRowBody headerRow={rowsLabel.current} rowsData={rowsData} />
        </Tablenization>
    );
};

// 扣款授權
const MembershipCreateContentPayment = React.forwardRef((props, ref) => {
    const { data, options, errorModule, isReadOnly, getErrorModuleStatus } = useMembershipContext();
    const { contractMain = {}, contractFee = {}, contractBasic = {}, contractPayment = {}, isCompleteContract } = data;
    const { debitStartDate, debitEndDate, debitFirstDate, debitDayOfMonth } = contractPayment;
    const { startDate, endDate, paymentType } = contractMain;
    const { monthlyFee } = contractFee;
    const { identifierID, memberName } = contractBasic;
    const { invoiceCarrierType: invoiceCarrierTypeOptions, invoiceIssuingType: invoiceIssuingTypeOptions } = options;
    const [rowsData, setRowData] = useState([]);
    const creditCardRef = useRef(null);
    const achRef = useRef(null);
    const invoiceRef = useRef(null);
    const debitDateRangeRef = useRef(null);
    const debitFirstDateRef = useRef(null);
    const getPaymentTypeText = (num) => {
        return PAYMENT_TYPE_OPTIONS.find((item) => item.value === parseInt(num))?.text ?? '';
    };

    const getResult = () => {
        let contractPayment = {};
        const initializePayment = () => {
            return {
                ...CREDIT_INITIAL_STATE,
                ...ACH_INITIAL_STATE,
            };
        };

        if (debitDateRangeRef.current && debitDateRangeRef.current.getResult) {
            let { startDate, endDate } = debitDateRangeRef.current.getResult();
            if (startDate) {
                startDate = parseDate(new Date(startDate).setDate(1), DateTimeStandard.DisplayUtcFull);
            }
            if (endDate) {
                endDate = parseDate(new Date(endDate).setDate(1), DateTimeStandard.DisplayUtcFull);
            }
            contractPayment = Object.assign(contractPayment, { debitStartDate: startDate, debitEndDate: endDate });
        }

        if (debitFirstDateRef.current && debitFirstDateRef.current.getResult) {
            let debitFirstDate = debitFirstDateRef.current.getResult();
            debitFirstDate = parseDate(new Date(debitFirstDate).setDate(1), DateTimeStandard.DisplayUtcFull);
            contractPayment = Object.assign(contractPayment, { debitFirstDate });
        }

        if (invoiceRef.current && invoiceRef.current.getResult) {
            contractPayment = Object.assign(contractPayment, invoiceRef.current.getResult());
        }

        if (achRef.current && achRef.current.getResult) {
            contractPayment = Object.assign(contractPayment, initializePayment());
            contractPayment = Object.assign(contractPayment, achRef.current.getResult());
        }

        if (creditCardRef.current && creditCardRef.current.getResult) {
            contractPayment = Object.assign(contractPayment, initializePayment());
            contractPayment = Object.assign(contractPayment, creditCardRef.current.getResult());
        }

        return { contractPayment };
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(creditCardRef, achRef, invoiceRef, debitDateRangeRef, debitFirstDateRef),
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            const dateText = displayDateText(startDate, endDate);
            const feeText = monthlyFee ? formatCurrencyFn(monthlyFee, { symbol: '$' }) : '尚無參考資料';
            const _rowsData = [
                {
                    id: 1,
                    item: '月費',
                    date: dateText,
                    fee: feeText,
                },
            ];
            setRowData(_rowsData);
        },
        // eslint-disable-next-line
        [data],
    );
    return (
        <React.Fragment>
            <Typography variant="h3" className="h5">
                付款項目
            </Typography>
            <p className="mb-2">
                繳費方式：
                <span className="ml-3 text-primary">{getPaymentTypeText(paymentType) || '尚無參考資料'}</span>
            </p>
            <ShoppingList rowsData={rowsData} />
            <React.Fragment>
                <FieldGroup label="扣款期間">
                    <Grid item xs={12}>
                        <DateIntervalField
                            key={`${initialDate(debitStartDate)}_${initialDate(debitEndDate)}`}
                            ref={debitDateRangeRef}
                            readOnly={isReadOnly}
                            startDateProps={{
                                defaultValue: initialDate(debitStartDate),
                                ...getErrorModuleStatus(errorModule.contractPayment, 'debitStartDate'),
                            }}
                            endDateProps={{
                                defaultValue: initialDate(debitEndDate),
                                ...getErrorModuleStatus(errorModule.contractPayment, 'debitStartDate'),
                            }}
                            views={['year', 'month']}
                            openTo={'month'}
                            format={'YYYY/MM'}
                            mask={'____/__'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="info-box">
                            <p className="m-0">
                                每月
                                <span className="mark">{debitDayOfMonth}</span>
                                日扣款
                            </p>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <DateField
                                        key={uuid()}
                                        ref={debitFirstDateRef}
                                        defaultValue={initialDate(debitFirstDate)}
                                        label={'首次扣款日'}
                                        {...getErrorModuleStatus(errorModule.contractPayment, 'debitFirstDate')}
                                        views={['year', 'month']}
                                        openTo={'month'}
                                        // format="YYYY/MM"
                                        mask={'____/__'}
                                        readOnly={isReadOnly}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </FieldGroup>

                {String(paymentType) === '1' && (
                    <FieldGroup label="信用卡資訊">
                        <Grid item xs={12}>
                            <CreditCard
                                ref={creditCardRef}
                                defaultValue={contractPayment}
                                errorModule={errorModule.contractPayment}
                                readOnly={isReadOnly}
                                isShowCreditCardPDF
                                isShowCreditCardBankName
                                isShowCardRelation
                                isPersonalSignatureRequired
                                isDisplaySignature
                            />
                        </Grid>
                    </FieldGroup>
                )}
                {String(paymentType) === '2' && (
                    <FieldGroup label="銀行資訊">
                        <Grid item xs={12}>
                            <ACHCard
                                ref={achRef}
                                defaultValue={contractPayment}
                                errorModule={errorModule.contractPayment}
                                readOnly={isReadOnly}
                                identifierID={identifierID}
                                userName={memberName}
                                isSameBtn={true}
                                bankAccountProps={{ required: isCompleteContract }}
                            />
                        </Grid>
                    </FieldGroup>
                )}

                <FieldGroup label="發票類型">
                    <Grid item xs={8}>
                        <InvoiceCard
                            ref={invoiceRef}
                            sourceData={contractPayment}
                            resource={{ invoiceCarrierTypeOptions, invoiceIssuingTypeOptions }}
                            errorModule={errorModule.contractPayment}
                            readOnly={isReadOnly}
                        />
                    </Grid>
                </FieldGroup>
            </React.Fragment>
        </React.Fragment>
    );
});

export default MembershipCreateContentPayment;
