import React, { useState, useImperativeHandle, useRef } from 'react';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { TextField, CheckboxField, Stack, Typography } from '../../../../components';
import { accAdd } from '../../../../util/math';

// 處理有多組item資料
const mergeItemsTypeData = (targetData = []) => {
    const mergerData = targetData.reduce((acc, { itemType, applyMonth, applyDay }) => {
        let newAcc = { ...acc };
        const num = String(itemType) === '22' ? 22 : 21;
        if (newAcc[num]) {
            newAcc[num].applyMonth = accAdd(newAcc[num].applyMonth, applyMonth);
            newAcc[num].applyDay = accAdd(newAcc[num].applyDay, applyDay);
        } else {
            newAcc[num] = {
                itemType: num,
                itemChecked: true,
                applyMonth,
                applyDay,
            };
        }
        return newAcc;
    }, {});
    return Object.values(mergerData);
};

const CheckboxDateNumItem = React.memo(
    React.forwardRef((props, ref) => {
        const { isChecked = false, value, text, itemType, applyMonth: months = 0, applyDay: days = 0, isReadOnly = false, disabled: disabledProps } = props;

        const [checkedState, setCheckedState] = useState(isChecked || String(itemType) === String(value));
        const monthsRef = useRef(null);
        const daysRef = useRef(null);
        const handleCheckedChange = (dom, chked) => {
            setCheckedState(chked);
        };

        useImperativeHandle(
            ref,
            () => ({
                getResult: () => {
                    return {
                        itemType: value,
                        itemChecked: checkedState,
                        applyMonth: monthsRef.current?.getResult() ?? null,
                        applyDay: daysRef.current?.getResult() ?? null,
                    };
                },
            }),
            // eslint-disable-next-line
            [checkedState]
        );
        // console.log(isReadOnly);
        return (
            <Grid container>
                <Grid xs={4} item>
                    <CheckboxField onChange={handleCheckedChange}>
                        <CheckboxField.Checkbox label={text} checked={checkedState} readOnly={isReadOnly} disabled={disabledProps} />
                    </CheckboxField>
                </Grid>
                {checkedState && (
                    <Grid xs={8} item>
                        <Stack alignItems={'end'} spacing={2}>
                            <TextField
                                ref={monthsRef}
                                type={'number'}
                                inputProps={{ min: 0 }}
                                style={{ width: '100px' }}
                                readOnly={isReadOnly}
                                disabled={disabledProps}
                                defaultValue={months}
                                required
                            />
                            <Typography component={'div'}>月</Typography>
                            <TextField
                                ref={daysRef}
                                type={'number'}
                                inputProps={{ min: 0 }}
                                style={{ width: '100px' }}
                                readOnly={isReadOnly}
                                disabled={disabledProps}
                                defaultValue={days}
                                required
                            />
                            <Typography component={'div'}>日</Typography>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        );
    })
);

const MembershipTransferInDiscountDateItem = React.forwardRef((props, ref) => {
    const { options, sourceData: sourceDataProps = [], isReadOnly = false, disabled: disabledProps = false } = props;
    const checkboxDateNumItemsRef = useRef([]);
    const sourceData = mergeItemsTypeData(Array.isArray(sourceDataProps) ? sourceDataProps : []);

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    const items = checkboxDateNumItemsRef.current.reduce((acc, { getResult }) => {
                        const targetData = getResult();
                        const { itemChecked, ...others } = targetData;
                        if (itemChecked) acc.push(others);
                        return acc;
                    }, []);
                    return items;
                },
            };
        },
        // eslint-disable-next-line
        []
    );
    // console.log('MembershipTransferInDiscountDateItem', isReadOnly);
    return (
        <Stack direction="column">
            {Array.isArray(options) &&
                options
                    .filter((item) => item || !item.disabled)
                    .map((item, index) => {
                        const itemData = _.find(sourceData, ({ itemType }) => {
                            return String(itemType) === String(item.value);
                        });
                        return (
                            <CheckboxDateNumItem
                                {...item}
                                ref={(el) => (checkboxDateNumItemsRef.current[index] = el)}
                                key={item.value}
                                {...(itemData || {})}
                                isReadOnly={isReadOnly}
                                disabled={disabledProps}
                            />
                        );
                    })}
        </Stack>
    );
});

export default MembershipTransferInDiscountDateItem;
