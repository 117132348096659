import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Form, MenuItem, SelectField, TextField } from '../../../components';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { formatCurrencyFn, refIsRequiredError } from '../../../util/utils';
import useClubRemittanceReturnApi from '../../../apis/useClubRemittanceReturnApi';
import { ReadTextField } from '../../components';
import { displayDateText } from '../../../util/moment';

const RemitAllowanceDialogContent = React.forwardRef((props, ref) => {
    const { data = {} } = props;
    const { coachOptions: employeeOptions, invoiceNo, remitDate, amount = 0, returnAmount = 0 } = data;
    const returnAmountRef = useRef();
    const employeeRef = useRef();
    const notesRef = useRef();

    const getResult = () => {
        let result = {};

        if (employeeRef.current && employeeRef.current.getResult) {
            result = Object.assign(result, { employeeID: employeeRef.current.getResult() });
        }
        if (returnAmountRef.current && returnAmountRef.current.getResult) {
            result = Object.assign(result, { returnAmount: returnAmountRef.current.getResult() });
        }
        if (notesRef.current && notesRef.current.getResult) {
            result = Object.assign(result, { notes: notesRef.current.getResult() });
        }
        return result;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(employeeRef, returnAmountRef, notesRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <DialogContent className={'container main-container-spacing p-4'}>
            <Grid spacing={3} container>
                <Grid xs={6} item>
                    <ReadTextField label={'發票號碼'}>{invoiceNo}</ReadTextField>
                </Grid>
                <Grid xs={6} item>
                    <ReadTextField label={'匯款日期'}>{displayDateText(remitDate)}</ReadTextField>
                </Grid>
                <Grid xs={6} item>
                    <ReadTextField label={'匯款金額'}>{formatCurrencyFn(amount)}</ReadTextField>
                </Grid>
                <Grid xs={6} item>
                    <ReadTextField label={'已退金額'}>{formatCurrencyFn(returnAmount)}</ReadTextField>
                </Grid>
                <Grid xs={6} item>
                    <TextField
                        ref={returnAmountRef}
                        label={'退款金額'}
                        maskType={'MONEY'}
                        inputProps={{
                            placeholder: amount - returnAmount,
                            max: amount - returnAmount,
                            min: 1,
                        }}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid xs={6} item>
                    <SelectField label={'擔當'} ref={employeeRef} fullWidth required>
                        {Array.isArray(employeeOptions) &&
                            employeeOptions
                                .filter((item) => item || !item.disabled)
                                .map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.text}
                                    </MenuItem>
                                ))}
                    </SelectField>
                </Grid>
                <Grid xs={12} item>
                    <TextField label="備註" ref={notesRef} rows={4} fullWidth multiline required />
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const RemitAllowanceDialog = (props) => {
    const { className, open, remittanceID, onClose, refresh } = props;
    const { getRemittanceAllowanceApi, postRemittanceAllowanceConfirmApi } = useClubRemittanceReturnApi();
    const [data, setData] = useState({});
    const contentRef = useRef();

    const getParams = () => {
        let result = {
            remittanceID,
        };
        if (contentRef.current && contentRef.current.getResult) {
            result = Object.assign(result, contentRef.current.getResult());
        }

        return result;
    };

    const doRemittanceAllowanceApi = async (remittanceID) => {
        let res = await getRemittanceAllowanceApi(remittanceID);
        if (res) {
            setData(res);
        } else {
            onClose();
        }
    };

    const doRemittanceAllowanceConfirmApi = async (params) => {
        let res = await postRemittanceAllowanceConfirmApi(params);
        if (res) {
            refresh && refresh();
            onClose();
        }
    };

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = getParams();
            doRemittanceAllowanceConfirmApi(params);
        }
    };

    useEffect(() => {
        if (open) doRemittanceAllowanceApi(remittanceID);
        // eslint-disable-next-line
    }, [open]);

    if (!open && isEmpty(data)) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>匯款進行折讓</GradientColorHeader>
            <RemitAllowanceDialogContent ref={contentRef} data={data} />
            <DialogActions>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemitAllowanceDialog;
