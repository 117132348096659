import React, { useRef } from 'react';
import { formatCurrencyFn } from '../../../util/utils';
import Tablenization from '../../components/Tablenization';
import { ProductBox } from '../components';

const rowsLabel = [
    {
        cellKey: 'orderItemID',
        headerLabel: '品項',
        formatCell: (cellValue, rowData = {}) => {
            const { productName, variantName } = rowData;
            return (
                <ProductBox
                    className={'flex-align-center'}
                    name={productName}
                    variantName={variantName}
                />
            );
        },
    },
    {
        cellKey: 'productTypeText',
        headerLabel: '類型',
        align: 'center',
    },
    {
        cellKey: 'salePrice',
        headerLabel: '價格',
        align: 'center',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'quantity',
        headerLabel: '數量',
        align: 'center',
    },
    {
        cellKey: 'returnQuantity',
        headerLabel: '已折讓數量',
        align: 'right',
    },
    {
        cellKey: 'total',
        headerLabel: '總計',
        align: 'right',
        formatCell: (cellValue, rowData) => {
            const { salePrice, quantity } = rowData;
            return formatCurrencyFn(salePrice * quantity);
        },
    },
    {
        cellKey: 'returnPrice',
        headerLabel: '已折讓金額',
        align: 'right',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
];

const SalesAbandonItemTable = (props, ref) => {
    let { rowsData = [], className } = props;
    const labelTitle = useRef(rowsLabel);

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: className }}>
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default SalesAbandonItemTable;
