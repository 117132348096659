import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const DocumentFile = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M15,0v6h6L15,0z M13.4,6V0H5.3C4.1,0,3,1,3,2.3v19.4C3,22.9,4,24,5.3,24h13.4c1.2,0,2.3-1,2.3-2.3V7.4h-6
	C14.2,7.4,13.4,6.8,13.4,6z M15.7,19.4H8.3c-0.4,0-0.7-0.4-0.7-0.7c0-0.4,0.2-0.7,0.7-0.7h7.4c0.4,0,0.7,0.4,0.7,0.7
	C16.6,19.2,16.2,19.4,15.7,19.4z M15.7,16.6H8.3c-0.4,0-0.7-0.4-0.7-0.7s0.4-0.7,0.7-0.7h7.4c0.4,0,0.7,0.4,0.7,0.7
	C16.6,16.2,16.2,16.6,15.7,16.6z M16.6,12.7c0,0.4-0.4,0.7-0.7,0.7H8.3c-0.4,0-0.7-0.4-0.7-0.7c0-0.4,0.2-0.7,0.7-0.7h7.4
	C16.2,12,16.6,12.4,16.6,12.7z"
            />
        </SvgIcon>
    );
});

export default DocumentFile;
