import React, { useEffect, useRef, useState } from 'react';
import { RentCabinetProvider } from '@icoach/rentCabinet/components/RentCabinetContext';
import { initBookmark } from '@icoach/rentCabinet/staticData';
import RentCabinetOverviewSearchBar from '@icoach/rentCabinet/RentCabinetOverviewSearchBar';
import RentCabinetOverviewTable from '@icoach/rentCabinet/RentCabinetOverviewTable';

const RentCabinetOverviewPage = (props) => {
    const { setTabs, value: tabKey, dialogRef, refreshRef } = props;
    const [bookmark, setBookmark] = useState(initBookmark);
    const [tableData, setTableData] = useState({});
    const reSetCheckedItemsRef = useRef(null);

    const setBookmarkNum = (num) => setBookmark((prev) => ({ ...prev, pageIndex: num }));

    const childContext = {
        tableData,
        setTableData,
        bookmark,
        setBookmarkNum,
        refresh: refreshRef,
        setRefresh: (el) => (refreshRef.current = el),
        dialogRef,
        reSetCheckedItemsRef: (fn) => (reSetCheckedItemsRef.current = fn),
        reSetCheckedItems: () => typeof reSetCheckedItemsRef.current === 'function' && reSetCheckedItemsRef.current(),
    };

    useEffect(
        () => {
            setTabs(tabKey);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <RentCabinetProvider value={childContext}>
            <RentCabinetOverviewSearchBar className={'mb-4'} />
            <RentCabinetOverviewTable />
        </RentCabinetProvider>
    );
};

export default RentCabinetOverviewPage;
