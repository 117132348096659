import React, { useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Box, Button, Form, Stack, Typography } from '@common/components/';
import SendSmsDialog from '@icoach/components/dialog/SendSmsDialog';
import useToggleDialog from '@util/hook/useToggleDialog';

const SmsController = React.forwardRef((props, ref) => {
    const { className, tableRef, handleCancel } = props;
    const { openTarget, handleOpenDialog, handleCloseDialog, sourceData } = useToggleDialog();
    const [count, setCount] = useState(0);

    const getParams = () => {
        let result = [];

        if (tableRef.current && tableRef.current.getCurrentData) {
            const currentData = tableRef.current.getCurrentData() || [];
            result = currentData.map(({ memberID, memberName, nickName }) => ({
                type: 2,
                id: memberID,
                name: nickName || memberName,
            }));
        }

        return result;
    };

    const handleSubmit = () => {
        const params = getParams();
        if (params.length > 0) {
            handleOpenDialog('sendSMS', { sourceData: params });
        }
    };

    const refreshFn = () => {
        handleCancel && handleCancel();
    };

    useImperativeHandle(
        ref,
        () => ({
            setCount,
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Box className={clsx('mb-2 bulk-controller-box', className)}>
                <Form onSubmit={handleSubmit}>
                    <Grid spacing={3} container>
                        <Grid xs={8} item>
                            <Typography className={'font-weight-bold'} variant={'body1'}>
                                請選擇要發送簡訊的名單
                            </Typography>
                            <Typography variant={'body1'}>
                                選取筆數：
                                <strong className={'h6 text-primary'}>{count}</strong>
                            </Typography>
                        </Grid>
                        <Grid xs={4} item>
                            <Stack className={'h-100'} direction={'column'} justifyContent={'end'}>
                                <div className={'btn-group'}>
                                    <Button color={'grey'} variant={'outlined'} onClick={handleCancel}>
                                        取消
                                    </Button>
                                    <Button type={'submit'} variant={'outlined'}>
                                        進行簡訊編輯
                                    </Button>
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            </Box>
            <SendSmsDialog open={openTarget === 'sendSMS'} onClose={handleCloseDialog} sourceData={sourceData} refresh={refreshFn} />
        </React.Fragment>
    );
});

export default SmsController;
