import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@util/hook/useForkRef';

const QrCode = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M1,3.36C1,2.06,2.06,1,3.36,1h4.71c1.3,0,2.36,1.06,2.36,2.36v4.71c0,1.3-1.06,2.36-2.36,2.36H3.36
	C2.06,10.43,1,9.37,1,8.07V3.36z M4.14,4.14v3.14h3.14V4.14H4.14z M1,15.93c0-1.3,1.06-2.36,2.36-2.36h4.71
	c1.3,0,2.36,1.06,2.36,2.36v4.71c0,1.3-1.06,2.36-2.36,2.36H3.36C2.06,23,1,21.94,1,20.64V15.93z M4.14,16.71v3.14h3.14v-3.14H4.14z
	 M15.93,1h4.71C21.94,1,23,2.06,23,3.36v4.71c0,1.3-1.06,2.36-2.36,2.36h-4.71c-1.3,0-2.36-1.06-2.36-2.36V3.36
	C13.57,2.06,14.63,1,15.93,1z M19.86,4.14h-3.14v3.14h3.14V4.14z M13.57,14.36c0-0.43,0.35-0.79,0.79-0.79h3.14
	c0.43,0,0.79,0.35,0.79,0.79s0.35,0.79,0.79,0.79h1.57c0.43,0,0.79-0.35,0.79-0.79s0.35-0.79,0.79-0.79S23,13.93,23,14.36v4.71
	c0,0.43-0.35,0.79-0.79,0.79h-3.14c-0.43,0-0.79-0.35-0.79-0.79s-0.35-0.79-0.79-0.79s-0.79,0.35-0.79,0.79v3.14
	c0,0.43-0.35,0.79-0.79,0.79h-1.57c-0.43,0-0.79-0.35-0.79-0.79V14.36z M19.07,23c-0.43,0-0.79-0.35-0.79-0.79
	c0-0.43,0.35-0.79,0.79-0.79s0.79,0.35,0.79,0.79C19.86,22.65,19.51,23,19.07,23z M22.21,23c-0.43,0-0.79-0.35-0.79-0.79
	c0-0.43,0.35-0.79,0.79-0.79c0.43,0,0.79,0.35,0.79,0.79C23,22.65,22.65,23,22.21,23z"
            />
        </SvgIcon>
    );
});

export default QrCode;
