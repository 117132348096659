import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent, Slide } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Typography, Stack, Button } from '@common/components/';
import { LabelHeaderCheckBox, LabelCheckBox, useLabelCheckBox } from '@icoach/components/';
import { Check as CheckIcon } from '@common/SvgIcon/';
import Tablenization from '@icoach/components/Tablenization';
import { isEmpty } from '@util/utils';
import { parseDate } from '@util/moment';
import usePointsApi from '@apis/usePointsApi';
import { initBookmark } from '@icoach/points/staticData';
import { MorePageBox } from '@icoach/components';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const rowsLabel = [
    {
        cellKey: 'rewardID',
        headerLabel: ({ onCheckBoxChange, headerCheckBoxRef }) => {
            return <LabelHeaderCheckBox ref={headerCheckBoxRef} onChange={onCheckBoxChange} />;
        },
        isIcon: true,
        formatCell: (value, rowData, { checkBoxsRef, onCheckBoxChange, getCheckedItems, sequence }) => {
            let isChecked = false;
            const key = `${value}_${sequence}`;
            if ((getCheckedItems() || []).includes(key)) isChecked = true;
            return (
                <LabelCheckBox
                    ref={(el) => (checkBoxsRef.current[key] = el)}
                    onChange={onCheckBoxChange}
                    idKey={key}
                    defalutCehecked={isChecked}
                    sourceData={rowData}
                    disabled={String(rowData.redeemStatus) === '1'}
                />
            );
        },
    },
    {
        cellKey: 'displayName',
        headerLabel: '會員',
        width: 6,
        maxWidth: 6,
        formatCell: (value, { memberNo }) => {
            return (
                <Stack alignItems="start" direction="column">
                    <Typography component="span">{value}</Typography>
                    <Typography component="span">{memberNo}</Typography>
                </Stack>
            );
        },
    },
    {
        cellKey: 'orderNo',
        headerLabel: '訂單編號',
        width: 10,
        maxWidth: 10,
        align: 'center',
        formatCell: (value) => {
            return <Typography component="span">{value}</Typography>;
        },
    },
    {
        cellKey: 'redeemItemName',
        headerLabel: '商品',
        width: 20,
        maxWidth: 20,
        align: 'center',
        formatCell: (value) => {
            return <Typography component="span">{value}</Typography>;
        },
    },
    {
        cellKey: 'orderDate',
        headerLabel: '訂購日期',
        width: 8,
        maxWidth: 8,
        align: 'center',
        formatCell: (value) => {
            return <Typography component="span">{parseDate(value)}</Typography>;
        },
    },
    {
        cellKey: 'redeemStatus',
        headerLabel: '已簽收',
        width: 3,
        maxWidth: 3,
        align: 'center',
        formatCell: (value) => {
            return String(value) === '1' ? <CheckIcon color="success" /> : null;
        },
    },
];

const PointsRedeemOrdersTasksDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: sourceDataProps = {} } = props;
    if (isEmpty(sourceDataProps) || !openProps) return null;
    const { checkBoxsRef, headerCheckBoxRef, onHeaderChange, onCellChange, getCheckedSourceData, doSaveCheckedItem, getCheckedItems } = useLabelCheckBox();
    const [sourceData, setSourceData] = useState([]);
    const [pageModel, setPageModel] = useState(initBookmark());
    const { getPointsRedeemOrdersAllRecordsApi, postPointsRedeemOrdersSyncTasksApi } = usePointsApi();
    const isChangePage = useRef(false);

    const handleSendNotify = () => {
        const checkedData = getCheckedSourceData();
        if (checkedData.length > 0) {
            const orderItemIDs = checkedData.map(({ orderItemID }) => orderItemID);
            const params = {
                orderItemIDs,
                activityID: sourceDataProps.activityID,
            };
            doSyncTasks(params);
        }
    };

    const doSyncTasks = async (params) => {
        const resp = await postPointsRedeemOrdersSyncTasksApi(params);
        if (resp) {
            onCloseProps();
        }
    };

    const doRedeemOrdersTasksApi = useCallback(
        async (actID) => {
            const params = { activityID: actID, ...pageModel };
            const resp = await getPointsRedeemOrdersAllRecordsApi(params);
            isChangePage.current = false;
            if (resp) {
                const { list, ...pageOthers } = resp;
                setPageModel(pageOthers);
                setSourceData((prev) => {
                    let newData = JSON.parse(JSON.stringify(prev));
                    Array.prototype.push.apply(newData, list);
                    return newData;
                });
            }
        },
        // eslint-disable-next-line
        [pageModel]
    );

    const onPageChange = (_e, num) => {
        doSaveCheckedItem();
        setPageModel((prev) => ({ ...prev, pageIndex: num }));
        isChangePage.current = true;
    };

    useEffect(
        () => {
            if (isChangePage.current) {
                doRedeemOrdersTasksApi(sourceDataProps.activityID);
            }
        },
        // eslint-disable-next-line
        [pageModel.pageIndex]
    );

    useEffect(
        () => {
            if (openProps) {
                doRedeemOrdersTasksApi(sourceDataProps.activityID);
            }
        },
        // eslint-disable-next-line
        [sourceDataProps.activityID]
    );

    const { totalPage = 0, pageIndex = 1 } = pageModel;
    return (
        <Dialog open={openProps} TransitionComponent={Transition} fullScreen>
            <GradientColorHeader onClose={onCloseProps}>來店通知列表</GradientColorHeader>
            <DialogContent className="bg-blue-gray-50">
                <Typography variant="h3">{sourceDataProps.title}</Typography>
                <Tablenization
                    cardProps={{ className: 'theme-gray overview' }}
                    Pagination={MorePageBox}
                    onChange={onPageChange}
                    page={pageIndex}
                    count={totalPage}
                    isPagination
                >
                    <Tablenization.TableRowHeader headerRow={rowsLabel} onCheckBoxChange={onHeaderChange} headerCheckBoxRef={headerCheckBoxRef} />
                    <Tablenization.TableRowBody
                        headerRow={rowsLabel}
                        rowsData={sourceData}
                        checkBoxsRef={checkBoxsRef}
                        onCheckBoxChange={onCellChange}
                        getCheckedItems={getCheckedItems}
                        bodyRowClass={{
                            key: 'redeemStatus',
                            value: 1,
                            className: 'overview-item-invalid',
                        }}
                    />
                </Tablenization>
                <Stack className="py-2" justifyContent="end">
                    <Button variant="contained" onClick={handleSendNotify}>
                        送出通知
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
});

export default PointsRedeemOrdersTasksDialog;
