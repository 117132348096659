import React from 'react';
import SnackbarContent from './SnackbarContent';
// import PropTypes from 'prop-types';
import { duration } from '../../util/createTransitions';
import useEventCallback from '../../util/hook/useEventCallback';
import ClickAwayListener from '../clickAwayListener/ClickAwayListener';
import Grow from '../grow/Grow';
import clsx from 'clsx';

export const staticClass = `snackbar`;

const verticalMap = ['top', 'bottom'];
const horizontalMap = ['left', 'right', 'center'];

const Snackbar = React.forwardRef((props, ref) => {
    const {
        action,
        anchorOrigin: { vertical, horizontal } = { vertical: 'bottom', horizontal: 'right' },
        autoHideDuration = null,
        children,
        className,
        ClickAwayListenerProps,
        ContentProps,
        disableWindowBlurListener = false,
        message,
        onClose,
        onFocus,
        onBlur,
        onMouseEnter,
        onMouseLeave,
        open,
        resumeHideDuration,
        TransitionComponent = Grow,
        transitionDuration = {
            enter: duration.enteringScreen,
            exit: duration.leavingScreen,
        },
        TransitionProps: { onEnter, onExited, ...TransitionProps } = {},
        ...other
    } = props;
    const timerAutoHide = React.useRef(null);
    const [exited, setExited] = React.useState(true);
    let classNames = `${staticClass}-root`;

    classNames = clsx(
        classNames,
        `${staticClass}-vertical-${verticalMap.includes(vertical) ? vertical : 'bottom'}`
    );

    classNames = clsx(
        classNames,
        `${staticClass}-horizontal-${horizontalMap.includes(vertical) ? horizontal : 'right'}`
    );

    const handleClose = useEventCallback((...args) => {
        onClose && onClose(...args);
    });

    const setAutoHideTimer = useEventCallback((autoHideDurationParam) => {
        if (!onClose || autoHideDurationParam === null) {
            return;
        }
        clearTimeout(timerAutoHide.current);
        timerAutoHide.current = setTimeout(() => {
            handleClose(null, 'timeout');
        }, autoHideDurationParam);
    });

    React.useEffect(() => {
        open && setAutoHideTimer(autoHideDuration);

        return () => {
            clearTimeout(timerAutoHide.current);
        };
    }, [open, autoHideDuration, setAutoHideTimer]);

    // Pause the timer when the user is interacting with the Snackbar
    // or when the user hide the window.
    const handlePause = () => {
        clearTimeout(timerAutoHide.current);
    };

    // Restart the timer when the user is no longer interacting with the Snackbar
    // or when the window is shown back.
    const handleResume = React.useCallback(() => {
        if (autoHideDuration != null) {
            setAutoHideTimer(
                resumeHideDuration != null ? resumeHideDuration : autoHideDuration * 0.5
            );
        }
    }, [autoHideDuration, resumeHideDuration, setAutoHideTimer]);

    const handleFocus = (event) => {
        onFocus && onFocus(event);
        handlePause();
    };

    const handleMouseEnter = (event) => {
        onMouseEnter && onMouseEnter(event);
        handlePause();
    };

    const handleBlur = (event) => {
        onBlur && onBlur(event);
        handleResume();
    };

    const handleMouseLeave = (event) => {
        onMouseLeave && onMouseLeave(event);
        handleResume();
    };

    const handleClickAway = (event) => {
        onClose && onClose(event, 'clickaway');
    };

    const handleExited = (node) => {
        setExited(true);
        onExited && onExited(node);
    };

    const handleEnter = (node, isAppearing) => {
        setExited(false);
        onEnter && onEnter(node, isAppearing);
    };

    React.useEffect(() => {
        // TODO: window global should be refactored here
        if (!disableWindowBlurListener && open) {
            window.addEventListener('focus', handleResume);
            window.addEventListener('blur', handlePause);

            return () => {
                window.removeEventListener('focus', handleResume);
                window.removeEventListener('blur', handlePause);
            };
        }

        return undefined;
    }, [disableWindowBlurListener, handleResume, open]);

    // So we only render active snackbars.
    if (!open && exited) {
        return null;
    }

    const defaultProperty = {
        className: clsx(classNames, className),
        onBlur: handleBlur,
        onFocus: handleFocus,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        ...other,
        role: 'presentation',
        // anchorOrigin: { vertical, horizontal },
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway} {...ClickAwayListenerProps}>
            <div {...defaultProperty}>
                <TransitionComponent
                    appear
                    in={open}
                    timeout={transitionDuration}
                    direction={vertical === 'top' ? 'down' : 'up'}
                    onEnter={handleEnter}
                    onExited={handleExited}
                    {...TransitionProps}
                >
                    {children || (
                        <SnackbarContent message={message} action={action} {...ContentProps} />
                    )}
                </TransitionComponent>
            </div>
        </ClickAwayListener>
    );
});

// Snackbar.prototype = {};

export default Snackbar;
