import React from 'react';
import { Card } from '@common/components/';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { invoiceCarrierTypeMap, issuingTypeMap, ReadTextField } from '@icoach/components';

const InvoiceSectionByRead = (props) => {
    const { titleClassName = 'bg-light-active', data = {} } = props;
    const { issuingType, issuingTypeText, carrierType, carrierTypeText, carrierNo, taxTitle, taxID, donationCode, cellPhone, mail } = data.invoice || {};

    return (
        <React.Fragment>
            <Card.CardHeader title="發票資訊" className={clsx('px-4', titleClassName)} />
            <Card.CardContent className={'p-4'}>
                <Grid spacing={3} container>
                    <Grid xs={4} item>
                        <ReadTextField label={'發票類型'}>{issuingTypeText}</ReadTextField>
                    </Grid>
                    {issuingType === 1 && (
                        <Grid item xs={4}>
                            <ReadTextField label={'愛心碼'}>{donationCode}</ReadTextField>
                        </Grid>
                    )}
                    {issuingType === 2 && (
                        <React.Fragment>
                            <Grid item xs={4}>
                                <ReadTextField label={'發票載具'}>{carrierTypeText}</ReadTextField>
                            </Grid>
                            {(String(carrierType) === invoiceCarrierTypeMap.mobile ||
                                String(carrierType) === invoiceCarrierTypeMap.citizenDigitalCertificate1) && (
                                <Grid item xs={4}>
                                    <ReadTextField label={'載具號碼'}>{carrierNo}</ReadTextField>
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                    {String(issuingType) === issuingTypeMap.triplicateUniform && (
                        <React.Fragment>
                            <Grid item xs={4}>
                                <ReadTextField label={'統一編號'}>{taxID}</ReadTextField>
                            </Grid>
                            <Grid item xs={4}>
                                <ReadTextField label={'發票抬頭'}>{taxTitle}</ReadTextField>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid xs={4} item>
                        <ReadTextField label={'寄送載具信箱'}>{mail}</ReadTextField>
                    </Grid>
                    <Grid xs={4} item>
                        <ReadTextField label={'通知手機號碼'}>{cellPhone}</ReadTextField>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
};

export default InvoiceSectionByRead;
