import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import IconGroupHeader from '@icoach/nonMember/components/IconGroupHeader';
import { NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER } from '@icoach/router/NonMemberRouter';
import {
    Typography,
    Button,
    TextField,
    Stack,
    SelectField,
    MenuItem,
    Chip,
} from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useCustomerApi from '@apis/useCustomerApi';
import { NON_MEMBER_CUSTOMER_ADD_ROUTER } from '@icoach/router/NonMemberRouter';

const CustomerBasicInformationBox = (props) => {
    const { sourceData = {} } = props;
    const {
        cellPhone,
        customerID,
        customerName,
        requiredType,
        introducer,
        askRecordType = '',
        notes,
        sourceType,
        employeeID,
        isValid,
        resource = {},
    } = sourceData;

    const { putCustomersInfoApi } = useCustomerApi();
    const history = useHistory();
    const nameRef = useRef(null);
    const phoneRef = useRef(null);
    const sourceTypeRef = useRef(null);
    const needsRef = useRef(null);
    const employeeRef = useRef(null);
    const askRecordTypeRef = useRef(null);
    const introducerRef = useRef(null);
    const noteRef = useRef(null);

    const handleBackToOverviewClcik = (e) => redirectCutomerOverview();

    const handleUpdataClick = (e) => {
        if (
            !refIsRequiredError(nameRef, phoneRef, sourceTypeRef, needsRef, introducerRef, noteRef)
        ) {
            const params = {
                name: nameRef.current.getResult(),
                cellPhone: phoneRef.current.getResult(),
                sourceType: sourceTypeRef.current.getResult(),
                requiredType: needsRef.current.getResult(),
                askRecordType: askRecordTypeRef.current.getResult() || 0,
                introducer: introducerRef.current.getResult(),
                notes: noteRef.current.getResult(),
                employeeID: employeeRef.current.getResult(),
            };
            doModifyApi(params);
        }
    };

    const redirectCutomerOverview = () => history.push(NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER);

    const doModifyApi = async (params) => {
        const resp = await putCustomersInfoApi(customerID, params);
        if (resp) history.push(NON_MEMBER_CUSTOMER_OVERVIEW_ROUTER);
    };

    const {
        coachOptions = [],
        sourceTypes = [],
        requiredTypes = [],
        askRecordTypes = [],
    } = resource;

    return (
        <React.Fragment>
            <Stack alignItems="center" justifyContent="space-between">
                <Stack alignItems="center">
                    <Typography variant="h5">顧客資料</Typography>
                    <Chip
                        className="ml-3"
                        label={isValid ? '有效' : '無效'}
                        color={isValid ? 'success' : 'warning'}
                    />
                </Stack>
                <div>
                    <IconGroupHeader
                        viewIconArray={[
                            'appointment',
                            'contact',
                            isValid ? 'invalid' : '',
                            'remove',
                        ]}
                        rowsData={[sourceData]}
                        peopleID={customerID}
                        urls={{
                            apo: `${NON_MEMBER_CUSTOMER_ADD_ROUTER}${customerID}`,
                        }}
                        dialogProps={{
                            remove: { refreshParams: ['successDelete', redirectCutomerOverview] },
                        }}
                    />
                </div>
            </Stack>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        ref={nameRef}
                        label="姓名"
                        defaultValue={customerName}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        ref={phoneRef}
                        label="手機"
                        defaultValue={cellPhone}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectField
                        label="來源"
                        defaultValue={sourceType}
                        ref={sourceTypeRef}
                        displayEmpty
                        required
                        fullWidth
                    >
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {sourceTypes.map(({ text, value, disabled }) => (
                            <MenuItem value={value} key={text} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={6}>
                    <SelectField
                        ref={employeeRef}
                        label="擔當"
                        displayEmpty
                        defaultValue={employeeID}
                        required
                        fullWidth
                    >
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {coachOptions.map(({ text, value, disabled }) => (
                            <MenuItem value={value} key={text} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={6}>
                    <SelectField
                        ref={needsRef}
                        label="需求"
                        displayEmpty
                        defaultValue={requiredType}
                        required
                        fullWidth
                    >
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {requiredTypes.map(({ text, value, disabled }) => (
                            <MenuItem value={value} key={text} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={6}>
                    <SelectField
                        ref={askRecordTypeRef}
                        label="詢問狀態"
                        displayEmpty
                        defaultValue={askRecordType}
                        required
                        fullWidth
                    >
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {askRecordTypes.map(({ text, value, disabled }) => (
                            <MenuItem value={value} key={text} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="介紹人"
                        defaultValue={introducer}
                        ref={introducerRef}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="備註"
                        defaultValue={notes}
                        ref={noteRef}
                        rows={4}
                        fullWidth
                        multiline
                    />
                </Grid>
            </Grid>
            <Stack justifyContent="end" spacing={2} className="mt-4">
                <Button
                    variant="outlined"
                    color="secondary"
                    width={15}
                    onClick={handleBackToOverviewClcik}
                >
                    返回顧客清單
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    width={15}
                    onClick={handleUpdataClick}
                >
                    更新資料
                </Button>
            </Stack>
        </React.Fragment>
    );
};

export default CustomerBasicInformationBox;
