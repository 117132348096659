import React from 'react';
import clsx from 'clsx';
import { isFragment } from 'react-is';
import Avatar from '../avatar/Avatar';

const staticClass = 'avatar-group';
const SPACINGS = {
    small: -16,
    medium: null,
};

const AvatarGroupAvatar = (props) => {
    const {
        children,
        variant,
        style: styleProps,
        className: classNameProps,
        isIcon: isIconProps,
    } = props;
    let styles = {};
    if (isIconProps) {
        Object.assign(styles, {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            backgroundColor: 'rgb(189, 189, 189)',
            width: '26px',
            height: '26px',
            border: '2px solid #fff',
            marginLeft: '-6px',
            WebkitUserSelect: 'none',
        });
    }
    const defaultProperty = {
        style: { ...styles, ...styleProps },
        className: classNameProps,
        variant,
        isIcon: isIconProps,
    };
    return <Avatar {...defaultProperty}>{children}</Avatar>;
};

const AvatarGroup = React.forwardRef((props, ref) => {
    const {
        className,
        children: childrenProp,
        variant = 'circular',
        componentsProps = {},
        spacing = 'medium',
        total,
        isIcon: isIconProps = false,
        max = 5,
    } = props;
    let classNames = `${staticClass}-root`;
    let clampedMax = max < 2 ? 2 : max;

    const children = React.Children.toArray(childrenProp).filter((child) => {
        if (process.env?.REACT_APP_SECRET_CODE?.trim() ?? '' === 'CRA') {
            if (isFragment(child)) {
                console.error(
                    [
                        "Allen: The AvatarGroup component doesn't accept a Fragment as a child.",
                        'Consider providing an array instead.',
                    ].join('\n')
                );
            }
        }

        return React.isValidElement(child);
    });

    const totalAvatars = total || children.length;

    if (totalAvatars === clampedMax) clampedMax += 1;

    const maxAvatars = Math.min(children.length, clampedMax - 1);
    const extraAvatars = Math.max(totalAvatars - clampedMax, totalAvatars - maxAvatars, 0);

    const marginLeft = spacing && SPACINGS[spacing] !== undefined ? SPACINGS[spacing] : -spacing;

    const defaultProperty = {
        className: clsx(classNames, className),
    };

    return (
        <div {...defaultProperty}>
            {extraAvatars ? (
                <AvatarGroupAvatar
                    variant={variant}
                    isIcon={isIconProps}
                    {...(componentsProps.additionalAvatar || {})}
                    className={clsx(
                        `${staticClass}-sub`,
                        componentsProps.additionalAvatar?.className
                    )}
                    style={{
                        marginLeft,
                        ...componentsProps.additionalAvatar?.style,
                    }}
                >
                    +{extraAvatars}
                </AvatarGroupAvatar>
            ) : null}
            {children
                .slice(0, maxAvatars)
                .reverse()
                .map((child) => {
                    return React.cloneElement(child, {
                        className: clsx(child.props.className, `${staticClass}-sub`),
                        variant: child.props.variant || variant,
                        isIcon: isIconProps,
                    });
                })}
        </div>
    );
});

export default AvatarGroup;
