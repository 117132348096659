import React, { useRef, useImperativeHandle, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, DateField, MenuItem, SelectField, TextField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { refIsRequiredError } from '@util/utils';
import { getToday } from '@util/moment';
import { cabinetStatusMap } from '@icoach/rentCabinet/staticData';
import useCabinetApi from '@apis/useCabinetApi';

export const CabinetDetailContent = React.forwardRef((props, ref) => {
    const { employeeOption = [], isEdit = false, data = {} } = props;
    const { cabinetName = '', cabinetFee, memo = '', cabinetStatus } = data;
    const cabinetNameRef = useRef();
    const priceRef = useRef();
    const noteRef = useRef();
    const employeeRef = useRef();
    const dateRef = useRef();
    const today = getToday();

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => ({
                cabinetName: cabinetNameRef.current.getResult(),
                cabinetFee: priceRef.current.getResult(),
                memo: noteRef.current.getResult(),
                employeeID: employeeRef.current.getResult(),
                createCabinetDate: dateRef.current.getResult(),
            }),
            isError: () => refIsRequiredError(cabinetNameRef, priceRef, noteRef, employeeRef, dateRef),
        }), // eslint-disable-next-line
        [],
    );
    return (
        <Grid spacing={3} container>
            <Grid xs={12} item>
                <TextField key={cabinetName} label={'置物櫃名稱'} ref={cabinetNameRef} defaultValue={cabinetName} fullWidth />
            </Grid>
            <Grid xs={12} item>
                <TextField
                    key={cabinetFee}
                    label={'月租金'}
                    ref={priceRef}
                    maskType={'MONEY'}
                    defaultValue={cabinetFee}
                    disabled={cabinetStatus === cabinetStatusMap.renting}
                    fullWidth
                    required
                />
            </Grid>
            <Grid xs={12} item>
                <TextField key={memo} label={'備註'} ref={noteRef} defaultValue={memo} minRows={3} multiline fullWidth />
            </Grid>
            <Grid xs={12} item>
                <SelectField label="擔當" ref={employeeRef} fullWidth required>
                    {Array.isArray(employeeOption) &&
                        employeeOption
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
            <Grid xs={12} item>
                <DateField label={isEdit ? '修改日期' : '新增日期'} ref={dateRef} defaultValue={today} maxDate={today} fullWidth />
            </Grid>
        </Grid>
    );
});

const CreateCabinetDialog = (props) => {
    const { className, open, onClose, refresh } = props;
    const { getCabinetOptionsApi, postCabinetApi } = useCabinetApi();
    const [resource, setResource] = useState({});
    const { employeeOption = [] } = resource;
    const formRef = useRef();

    const doCabinetOptionsApi = async () => {
        let res = await getCabinetOptionsApi();
        if (res) {
            setResource(res);
        }
    };

    const doCabinetApi = async (prams) => {
        let res = await postCabinetApi(prams);
        if (res) {
            if (typeof refresh === 'function') refresh();
            if (typeof onClose === 'function') onClose();
        }
    };

    const handleSubmit = () => {
        let isError = formRef.current.isError();
        if (isError) return false;
        let result = formRef.current.getResult();
        doCabinetApi(result);
    };

    useEffect(() => {
        if (open) doCabinetOptionsApi();
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>新增置物櫃</GradientColorHeader>
            <DialogContent className={'p-4'}>
                <CabinetDetailContent ref={formRef} employeeOption={employeeOption} />
            </DialogContent>
            <DialogActions className="px-4 pt-0 pb-2">
                <Button variant="contained" onClick={handleSubmit}>
                    送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateCabinetDialog;
