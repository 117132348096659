import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, Grid, DialogActions } from '@mui/material';
import { SelectField, MenuItem, Button } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import MemberSelectionPanel from '@icoach/gift/components/MemberSelectionPanel';
import GiftSoloCreateOptionDialog from '@icoach/gift/components/GiftSoloCreateOptionDialog';
import useGiftItemApi from '@apis/useGiftItemApi';
import useGiftItemRecordApi from '@apis/useGiftItemRecordApi';
import { refIsRequiredError } from '@util/utils';

const GiftDispatchDialog = React.forwardRef((props, ref) => {
    const { open = false, onClose, sourceData: giftDispatchData, refresh } = props;
    const { giftItemID, activityID, memberIDs = [], employeeID } = giftDispatchData || {};
    const { getGiftItemRecordOptionsApi, postGiftItemRecordApi } = useGiftItemRecordApi();
    const { getGiftItemEnabledApi } = useGiftItemApi();
    const [options, setOptions] = useState({});
    const [giftList, setGiftList] = useState([]);
    const { giftActivityOption = [], memberOption = [], employeesOption = [] } = options || {};
    const giftRef = useRef(null);
    const eventNameRef = useRef(null);
    const memberIDsRef = useRef(null);
    const employeeRef = useRef(null);

    const getParams = () => {
        let result = {
            recordID: 0,
        };

        if (giftRef.current && giftRef.current.getResult) {
            Object.assign(result, { giftItem: giftRef.current.getResult() });
        }

        if (eventNameRef.current && eventNameRef.current.getResult) {
            Object.assign(result, { activityID: eventNameRef.current.getResult() || 0 });
        }

        if (memberIDsRef.current && memberIDsRef.current.getResult) {
            Object.assign(result, { memberIDs: memberIDsRef.current.getResult() });
        }

        if (employeeRef.current && employeeRef.current.getResult) {
            Object.assign(result, { employee: employeeRef.current.getResult() || 0 });
        }

        return result;
    };

    const handleGiftItemRecordApi = async (params) => {
        let resp = await postGiftItemRecordApi(params);
        if (resp) {
            if (typeof refresh === 'function') refresh();
            onClose();
        }
    };

    const getOptionApi = async () => {
        const params = {
            isAll: false,
        };
        const resp = await getGiftItemRecordOptionsApi(params);

        if (resp) {
            setOptions(resp);
        }
    };

    const getGiftItemApi = async () => {
        const resp = await getGiftItemEnabledApi();
        if (resp) {
            setGiftList(resp);
        }
    };

    const handleSubmitForm = () => {
        const isError = refIsRequiredError(giftRef, eventNameRef, employeeRef, memberIDsRef);
        if (!isError) {
            const params = getParams();
            handleGiftItemRecordApi(params);
        }
    };

    useEffect(
        () => {
            if (open) {
                getGiftItemApi();
                getOptionApi();
            }
        },
        // eslint-disable-next-line
        [open],
    );

    if (!open) return null;

    return (
        <Dialog className={'simple-content dialog'} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>贈品發送</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={12} item>
                        <GiftSoloCreateOptionDialog ref={giftRef} label={'選擇贈品'} defaultValue={giftItemID} options={giftList} fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <SelectField key={activityID} ref={eventNameRef} label={'活動名稱'} defaultValue={activityID} fullWidth required>
                            {giftActivityOption.map(({ value, text }) => (
                                <MenuItem key={value} value={value}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <MemberSelectionPanel ref={memberIDsRef} memberIDs={memberIDs} option={memberOption} />
                    </Grid>
                    <Grid xs={12} item>
                        <SelectField key={employeeID} ref={employeeRef} label={'擔當'} defaultValue={employeeID} fullWidth required>
                            {employeesOption.map(({ value, text }) => (
                                <MenuItem key={value} value={value}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmitForm}>
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default GiftDispatchDialog;
