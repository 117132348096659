import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Switch, useHistory, useParams } from 'react-router-dom';
import { Typography, PrivateRoute } from '@common/components/';
import { BlankFormBox, SearchBar } from '@icoach/documents/components/';
import { ConfirmTemporaryContractDialog } from '@icoach/documents/dialog';
import MembershipQuitHistory from '@icoach/documents/membership/quit/MembershipQuitHistory';
import MembershipQuitContent from '@icoach/documents/membership/quit/MembershipQuitContent';
import useDocMembershipQuitApi from '@apis/useDocMembershipQuitApi';
import { peopleType } from '@apis/usePeopleApi';
import { DOC_MEMBERSHIP_QUIT_LINK } from '@icoach/documents/membership/staticData';
import {
    DOCUMENTS_MEMBERSHIP_QUIT_ROUTER,
    DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER,
    DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER,
    DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER,
} from '@icoach/router/routerPath';
import useToggleDialog from '@util/hook/useToggleDialog';
import { pathRouterShit } from '@util/utils';

const dialogTypeMap = {
    temporary: 'temporary',
};

const MembershipQuitPage = () => {
    const history = useHistory();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps } = useParams();
    const { getDocMembershipQuitOptionApi, getDocMembershipQuitTemporaryApi, deleteDocMembershipQuitTemporaryApi } = useDocMembershipQuitApi();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const [isMounted, setMounted] = useState(false);
    const [options, setOptions] = useState({});
    const searchRef = useRef([]);
    const temporaryIDRef = useRef(0);

    const goToContractPage = (props) => {
        const { peopleType = peopleTypeProps, referenceID = referenceIDProps, contractID } = props;
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
            peopleType,
            referenceID,
            contractID,
        });
        history.push(path);
    };

    const checkPeopleType = (opts) => {
        const { targetOptions = [] } = opts;
        const isInclude = targetOptions.find(({ value }) => String(peopleTypeProps) === String(value));
        const defaultPeopleType = peopleType.member;
        if (!peopleTypeProps || !isInclude) {
            history.replace(`${DOCUMENTS_MEMBERSHIP_QUIT_ROUTER}${defaultPeopleType}`);
        }
    };

    // 重設 按鈕
    const handleResetClick = (type) => {
        const path = `${DOCUMENTS_MEMBERSHIP_QUIT_ROUTER}${type}`;
        history.replace(path);
    };

    // 搜尋 按鈕
    const handleSearchHistoryClick = (id) => {
        const { targetID, targetType } = searchRef.current.getResult();
        const params = {
            peopleType: targetType,
            referenceID: id || targetID,
        };
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER, params);
        history.push(path);
    };

    // 新增 按鈕
    const handleCreateClick = () => {
        const { targetID: id, targetType: type } = searchRef.current.getResult();
        const params = { id, type };
        doCreateBlankContractApi(params, type, id);
    };

    // 彈出視窗 要刪除暫存資料
    const handleDialogNoClick = () => {
        const { targetID, targetType } = searchRef.current.getResult();
        doDeleteTemporaryDataApi(targetType, targetID);
    };

    // 彈出視窗 讀取上次暫存資料繼續編輯
    const handleDialogYseClick = () => {
        if (!temporaryIDRef.current) return;
        handleCloseDialog();
        goToContractPage({ contractID: temporaryIDRef.current });
    };

    // 刪除暫存合約資料
    const doDeleteTemporaryDataApi = async (type, id) => {
        const resp = await deleteDocMembershipQuitTemporaryApi({ type, id });
        if (resp) {
            handleCloseDialog();
            goToContractPage({ contractID: 0 });
        }
    };

    // 新增空白頁面
    const doCreateBlankContractApi = async (params, type, id) => {
        let temporaryID;
        temporaryIDRef.current = 0;
        temporaryID = await getDocMembershipQuitTemporaryApi(params);
        if (temporaryID !== 0 && !!temporaryID) {
            temporaryIDRef.current = temporaryID;
            handleOpenDialog(dialogTypeMap.temporary);
        } else {
            goToContractPage({
                peopleType: type,
                referenceID: id,
                contractID: 0,
            });
        }
    };

    // 初始 search options
    const doInitOptionsApi = async () => {
        const resp = await getDocMembershipQuitOptionApi();
        if (resp) {
            checkPeopleType(resp);
            setOptions(resp);
        }
        setMounted(true);
    };

    const useSearchBarMemo = useMemo(
        () => {
            return (
                <SearchBar
                    ref={searchRef}
                    className="mb-4"
                    sourceOption={options.targetOptions}
                    onResetEvent={handleResetClick}
                    onCreateEvent={handleCreateClick}
                    onSearchHistoryEvent={handleSearchHistoryClick}
                    routerPath={DOCUMENTS_MEMBERSHIP_QUIT_ROUTER}
                />
            );
        },
        // eslint-disable-next-line
        [options]
    );

    useEffect(
        () => {
            doInitOptionsApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        []
    );

    if (!isMounted) return null;

    return (
        <div className="container main-container-spacing">
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    會籍終止申請書
                </Typography>
                <div className="btn-group">
                    <BlankFormBox sourceData={DOC_MEMBERSHIP_QUIT_LINK} />
                </div>
            </header>
            {useSearchBarMemo}
            <Switch>
                <PrivateRoute
                    exact
                    path={DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_HISTORY_ROUTER}
                    as={MembershipQuitHistory}
                    onBlankContractClick={handleCreateClick}
                />
                <PrivateRoute
                    exact
                    path={DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER}
                    as={MembershipQuitContent}
                    searchRef={searchRef}
                    onReturnHistory={handleSearchHistoryClick}
                />
                <PrivateRoute
                    exact
                    path={DOCUMENTS_MEMBERSHIP_QUIT_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER}
                    as={MembershipQuitContent}
                    searchRef={searchRef}
                    isReadOnly={true}
                    onReturnHistory={handleSearchHistoryClick}
                />
            </Switch>
            <ConfirmTemporaryContractDialog
                open={openTarget === dialogTypeMap.temporary}
                handleClose={handleCloseDialog}
                handleClickYes={handleDialogYseClick}
                handleClickNo={handleDialogNoClick}
            />
        </div>
    );
};

export default MembershipQuitPage;
