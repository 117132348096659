import Mock from 'mockjs';
import { successApi } from './respondData';

// 來店會員清單
export const withMockCustomerApiProvider = () => {
    Mock.setup({ timeout: '200-800' });

    Mock.mock(/^\/api\/customers\/overview$/, 'post', () => {
        let sourceData = { ...successApi };
        sourceData.result = {
            pageIndex: 1,
            pageSize: 10,
            totalCount: 12,
            totalPage: 2,
            list: CUSTOMERSOVERVIEW,
        };
        return sourceData;
    });

    Mock.mock(/^\/api\/customers\/overview\/options$/, 'get', () => {
        let sourceData = { ...successApi };
        sourceData.result = CUSTOMERSOVERVIEWOPTIONS;
        return sourceData;
    });

};

const CUSTOMERSOVERVIEW = [
    {
        customerID: 42,
        name: '小美4',
        cellPhone: '09123123324',
        sourceType: '策略夥伴',
        requiredType: '塑身',
        status: 'APO',
        introducer: '大美4',
        contact: {
            contactDate: '2022-04-13T00:00:00',
            coachName: 'Sabrina(小K)',
            askType: '去電',
            description: '美美4',
        },
    },
    {
        customerID: 97,
        name: '測試龜',
        cellPhone: '0900111222',
        sourceType: '手發傳單',
        requiredType: '全部',
        status: 'NO APO',
        introducer: '測試龜的朋友',
        contact: {
            contactDate: '2022-06-30T00:00:00',
            coachName: 'Allen(小A)',
            askType: '去電',
            description: '最近疫情好像起起伏伏，2周後再約體驗',
        },
    },
    {
        customerID: 90,
        name: '測試顧客來源-網路',
        cellPhone: '0911234556',
        sourceType: '無',
        requiredType: '減重',
        status: '無效',
        introducer: '',
        contact: {
            contactDate: '2022-06-23T00:00:00',
            coachName: '林宗敬(小C)',
            askType: '去電',
            description: '',
        },
    },
    {
        customerID: 66,
        name: '測試測試',
        cellPhone: '0922334455',
        sourceType: '自店社群',
        requiredType: '維持健康',
        status: 'NO APO',
        introducer: 'Allen',
        contact: {
            contactDate: '2022-06-21T00:00:00',
            coachName: '林宗敬(小C)',
            askType: '來電',
            description: 'fffff',
        },
    },
    {
        customerID: 70,
        name: 'ssss',
        cellPhone: '0912321123',
        sourceType: '策略夥伴',
        requiredType: '塑身',
        status: '無效',
        introducer: 'fdfsfa',
        contact: {
            contactDate: '2022-04-14T18:20:46',
            coachName: 'Allen(小A)',
            askType: '到店訪問',
            description: 'ooooo',
        },
    },
    {
        customerID: 85,
        name: '阿小七',
        cellPhone: '0911111111',
        sourceType: '無',
        requiredType: '塑身',
        status: '無效',
        introducer: '',
        contact: {
            contactDate: '2022-06-21T00:00:00',
            coachName: '林宗敬(小C)',
            askType: '去電',
            description: '',
        },
    },
    {
        customerID: 47,
        name: '小美9',
        cellPhone: '09123123327',
        sourceType: '自店社群',
        requiredType: '塑身',
        status: '無效',
        introducer: '大美9',
        contact: {
            contactDate: '2022-04-13T11:46:04',
            coachName: 'Allen(小A)',
            askType: '到店訪問',
            description: '預約時間',
        },
    },
    {
        customerID: 72,
        name: '林小美',
        cellPhone: '0929123321',
        sourceType: '策略夥伴',
        requiredType: '維持健康',
        status: '無效',
        introducer: '小明',
        contact: {
            contactDate: '2022-06-02T00:00:00',
            coachName: 'Allen(小A)',
            askType: '來電',
            description: '聯繫備註',
        },
    },
    {
        customerID: 41,
        name: '小美3',
        cellPhone: '09123123323',
        sourceType: '自店社群',
        requiredType: '塑身',
        status: '無效',
        introducer: '大美3',
        contact: {
            contactDate: '2022-04-13T00:00:00',
            coachName: 'Sabrina(小K)',
            askType: '去電',
            description: '美美3',
        },
    },
    {
        customerID: 63,
        name: 'joyceee',
        cellPhone: '0966481177',
        sourceType: '既有名單',
        requiredType: '減重',
        status: 'APO',
        introducer: '',
        contact: {
            contactDate: '2022-04-14T13:33:03',
            coachName: '林宗敬(小C)',
            askType: '到店訪問',
            description: '測試',
        },
    },
];

const CUSTOMERSOVERVIEWOPTIONS = {
    isValidCustomers: [
        {
            text: '無',
            value: '0',
            disabled: false,
        },
        {
            text: '全部',
            value: '1',
            disabled: false,
        },
        {
            text: '有效名單',
            value: '2',
            disabled: false,
        },
        {
            text: '無效名單',
            value: '4',
            disabled: false,
        },
    ],
    customerStatus: [
        {
            text: '無',
            value: '0',
            disabled: false,
        },
        {
            text: 'APO',
            value: '3',
            disabled: false,
        },
        {
            text: 'NO APO',
            value: '4',
            disabled: false,
        },
        {
            text: '無效',
            value: '5',
            disabled: false,
        },
        {
            text: 'FA',
            value: '6',
            disabled: false,
        },
        {
            text: 'NO SHOW',
            value: '7',
            disabled: false,
        },
        {
            text: 'NO SALE',
            value: '8',
            disabled: false,
        },
    ],
    customerSources: [
        {
            text: '策略夥伴',
            value: '1',
            disabled: false,
        },
        {
            text: '自店社群',
            value: '2',
            disabled: false,
        },
        {
            text: '網路',
            value: '3',
            disabled: false,
        },
        {
            text: '既有名單',
            value: '4',
            disabled: false,
        },
        {
            text: 'CP',
            value: '5',
            disabled: false,
        },
        {
            text: '平面媒體',
            value: '6',
            disabled: false,
        },
        {
            text: '信箱傳單',
            value: '7',
            disabled: false,
        },
        {
            text: '自店BR',
            value: '8',
            disabled: false,
        },
        {
            text: '手發傳單',
            value: '9',
            disabled: false,
        },
        {
            text: '他店BR',
            value: '10',
            disabled: false,
        },
        {
            text: 'BR名單',
            value: '11',
            disabled: false,
        },
        {
            text: '招牌',
            value: '12',
            disabled: false,
        },
        {
            text: '其他',
            value: '13',
            disabled: false,
        },
        {
            text: 'CP去電',
            value: '14',
            disabled: false,
        },
        {
            text: 'GP名單',
            value: '15',
            disabled: false,
        },
    ],
    askType: [
        {
            text: '來電',
            value: '1',
            disabled: false,
        },
        {
            text: '到店訪問',
            value: '2',
            disabled: false,
        },
        {
            text: '去電',
            value: '4',
            disabled: false,
        },
    ],
    requirements: [
        {
            text: '減重',
            value: '1',
            disabled: false,
        },
        {
            text: '塑身',
            value: '2',
            disabled: false,
        },
        {
            text: '維持健康',
            value: '3',
            disabled: false,
        },
        {
            text: '全部',
            value: '4',
            disabled: false,
        },
    ],
};
