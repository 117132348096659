import React from 'react';

const TabPanel = React.forwardRef((props, ref) => {
    const { children, value, index, className } = props;
    return (
        <div
            ref={ref}
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            className={className}
            aria-labelledby={`tab-${index}`}
        >
            {value === index && children}
        </div>
    );
});

export default TabPanel;
