import React from 'react';
import clsx from "clsx";
import GradientColorHeader from "@icoach/components/dialog/GradientColorHeader";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import {Button, Typography} from "@common/components/";

const InBodyInterviewImpedanceDialog = (props) => {

    const {className, open, onClose, onConfirm} = props;

    if (!open) return null;
    return (
        <Dialog className={clsx('simple-content', 'dialog', className)} PaperProps={{className: 'wx-65rem'}} open={open} fullWidth>
            <GradientColorHeader onClose={onClose}>量身阻抗異常</GradientColorHeader>
            <DialogContent>
                <Typography paragraph>
                    將造成量身數據不準確，需請會員再次量身，並請注意下述情況
                </Typography>

                <ul className="list list-num-sub">
                    <li>測量電極與皮膚的接觸不良，請擦拭InBody電極或使用濕紙巾或乾淨的布擦拭測量部位的皮膚。</li>
                    <li>避免附近有強電磁干擾源（如手機、無線設備等）。</li>
                    <li>檢查設備的狀態，若持續異常請換人量身或重新開啟InBody</li>
                </ul>

            </DialogContent>
            <DialogActions>
                <Button color="error" variant="contained" onClick={onClose}>
                    否
                </Button>
                <Button color="success" variant="contained" onClick={onConfirm}>
                    是，再次量身
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default InBodyInterviewImpedanceDialog;