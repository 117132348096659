import React from 'react';
import clsx from 'clsx';
const staticClass = `grid`;

const Grid = React.forwardRef((props, ref) => {
    const {
        className,
        container = false,
        xs = false,
        sm = false,
        md = false,
        xl = false,
        lg = false,
        columns: columnsProp = 12,
        columnSpacing: columnSpacingProp,
        // direction = 'row',
        item = false,
        rowSpacing: rowSpacingProp,
        zeroMinWidth = false,
        wrap = 'wrap',
        spacing = 0,
        children,
        style,
        ...others
    } = props;

    let classNames = staticClass;

    const GridContext = React.createContext();
    const columns = React.useContext(GridContext) || columnsProp;

    const wrapChild = (element) =>
        columns !== 12 ? <GridContext.Provider value={columns}>{element}</GridContext.Provider> : element;

    if (container === true) classNames = clsx(classNames, `${staticClass}-container`);
    if (xs) classNames = clsx(classNames, `${staticClass}-xs-${xs}`); // 1-12
    if (sm) classNames = clsx(classNames, `${staticClass}-sm-${sm}`); // 1-12
    if (md) classNames = clsx(classNames, `${staticClass}-md-${md}`); // 1-12
    if (lg) classNames = clsx(classNames, `${staticClass}-lg-${lg}`); // 1-12
    if (xl) classNames = clsx(classNames, `${staticClass}-xl-${xl}`); // 1-12
    if (spacing) classNames = clsx(classNames, `${staticClass}-container-spacing-${spacing}`); // 1-5
    if (zeroMinWidth) classNames = clsx(classNames, `${staticClass}-zero-min-width`);
    if (wrap === 'wrap') classNames = clsx(classNames, `${staticClass}-wrap`);
    if (item) classNames = clsx(classNames, `${staticClass}-item`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        style,
        ...others,
    };
    return wrapChild(<div {...defaultProperty}>{children}</div>);
});

export default Grid;
