import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {CardContent, Grid} from '@mui/material';
import {useMemberDetailContext} from './MemberDetailContext';
import {Box, Card, Typography, Stack} from '@common/components/';
import {WorkoutTimelineBox, WorkoutCalendarBox} from '@icoach/members/components/';
import useMemberWorkoutApi from '@apis/useMemberWorkoutApi';
import useInBodyApi from '@apis/useInBodyApi';
import {getAddMonths, getSubMonths, parseDate, getNowTime, getFirstOfMonth, getLastOfMonth} from '@util/moment';
import useShowLoading from '@util/hook/useShowLoading';

const WorkoutTimelineCard = (props) => {
    const {memberID} = props;
    const {getMemberWorkoutTimeLineApi} = useMemberWorkoutApi();
    const [pageInfo, setPageInfo] = useState({
        pageIndex: 1,
        pageSize: 7,
    });
    const [data, setData] = useState({});
    const {prevText, nextText} = data;

    const doMemberWorkoutTimeLineApi = async (memberID, params) => {
        let res = await getMemberWorkoutTimeLineApi(memberID, params);
        if (res) {
            setData(res);
        }
    };

    const getParams = () => {
        return {
            ...pageInfo,
        };
    };

    const changePage = (pageIndex) => {
        let pageInfoObj = {
            ...pageInfo,
            pageIndex,
        };
        setPageInfo(pageInfoObj);
    };
    const handlePrev = () => {
        let pageIndex = pageInfo.pageIndex;
        if (!prevText) return false;
        pageIndex++;
        changePage(pageIndex);
    };

    const handleNext = () => {
        let pageIndex = pageInfo.pageIndex;
        if (!nextText) return false;
        pageIndex--;
        changePage(pageIndex);
    };

    useEffect(() => {
        doMemberWorkoutTimeLineApi(memberID, getParams());
        // eslint-disable-next-line
    }, [pageInfo]);

    return (
        <Card>
            <Card.CardContent className="py-2 px-4">
                <Typography variant="h6" className="text-center ">
                    WO月統計
                </Typography>
                <WorkoutTimelineBox data={data} pageSize={pageInfo.pageSize} handlePrev={handlePrev} handleNext={handleNext}/>
            </Card.CardContent>
        </Card>
    );
};
const WorkoutCalendarCard = (props) => {
    const {memberID} = props;
    const showLoading = useShowLoading();
    const {getMemberWorkoutCalendarApi} = useMemberWorkoutApi();
    const {getMeasureRangeDateApi} = useInBodyApi();
    const [currentDate, setCurrentDate] = useState(getNowTime());
    const [recordTime, setRecordTime] = useState(getNowTime());
    const [sourceData, setSourceData] = useState({});

    const doMemberWorkoutCalendarApi = async (memberID, p1, p2) => {
        showLoading(true);
        let mergeData = {};
        const workoutResp = await getMemberWorkoutCalendarApi(memberID, p1);
        const measureDatesResp = await getMeasureRangeDateApi(memberID, p2);
        if (workoutResp) {
            Object.assign(mergeData, {workoutData: workoutResp});
        }
        if (measureDatesResp) {
            Object.assign(mergeData, {measureRangeDate: measureDatesResp});
        }
        setSourceData(mergeData);
        setRecordTime(getNowTime());
        showLoading(false);
    };

    const getMemberWorkoutParams = () => {
        return {
            calTime: parseDate(currentDate, 'YYYYMM'),
        };
    };

    const getMeasureRangeDateParams = () => {
        return {
            startDate: getFirstOfMonth(currentDate, -1),
            endDate: getLastOfMonth(currentDate),
        };
    };

    const handlePrev = (calendarToShow) => {
        let calTime = getSubMonths(currentDate, calendarToShow);
        setCurrentDate(calTime);
    };

    const handleNext = (calendarToShow) => {
        let calTime = getAddMonths(currentDate, calendarToShow);
        setCurrentDate(calTime);
    };

    useEffect(() => {
        doMemberWorkoutCalendarApi(memberID, getMemberWorkoutParams(), getMeasureRangeDateParams());
        // eslint-disable-next-line
    }, [currentDate]);

    return (
        <Card>
            <Card.CardContent className="px-4 pt-2 pb-5">
                <Typography variant="h5" className="text-center mb-2">
                    運動紀錄
                </Typography>
                <WorkoutCalendarBox
                    key={`${recordTime}`}
                    memberID={memberID}
                    currentDate={currentDate}
                    sourceData={sourceData}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    refresh={() => doMemberWorkoutCalendarApi(memberID, getMemberWorkoutParams(), getMeasureRangeDateParams())}
                />
            </Card.CardContent>
        </Card>
    );
};

const MemberDetailWorkoutContent = (props) => {
    const {data} = useMemberDetailContext();
    const {className, memberID, setTab} = props;
    const tabs = props.match.params.tabs;

    useEffect(() => {
        if (tabs) {
            setTab(tabs);
        }
        // eslint-disable-next-line
    }, [tabs]);

    return (
        <Box className={clsx('sports-record-content', className)}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Card className="h-100">
                        <CardContent>
                            <Stack direction="column" alignItems="center">
                                <Typography variant="h6" className="mt-1 mb-2">
                                    WO頻率
                                </Typography>
                                <Typography variant="h4" component="p" className="text-primary font-weight-bold">
                                    {data.monthlyWorkoutFrequency}
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={9}>
                    <WorkoutTimelineCard memberID={memberID}/>
                </Grid>
                <Grid item xs={12}>
                    <WorkoutCalendarCard memberID={memberID}/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MemberDetailWorkoutContent;
