import React from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, Alert, Typography } from '@common/components/';
import useTemporaryApi from '@apis/useTemporaryApi';

const TemporariesDeleteDialog = (props) => {
    const { onClose: propsOnClose, open, sourceData = [], refresh, refreshParams = [] } = props;
    const { deleteTemporaryApi } = useTemporaryApi();
    const handleCloseOnClick = () => {
        propsOnClose(null);
    };

    const handleSendOnClick = () => {
        const params = {
            temporaryIDs: sourceData.map(({ temporaryID }) => temporaryID),
        };

        doDeleteTemporaryApi(params);
    };

    const doDeleteTemporaryApi = async (params) => {
        const resp = await deleteTemporaryApi(params);
        if (resp) {
            propsOnClose(null);
            refresh && refresh.current && refresh.current(...refreshParams);
        } else {
            console.error(resp);
        }
    };

    return (
        <Dialog open={open}>
            <GradientColorHeader onClose={handleCloseOnClick}>刪除</GradientColorHeader>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Alert severity="remove" variant="filled" color="error">
                            確定要刪除({sourceData.length})?
                        </Alert>
                    </Grid>
                    <Grid item xs={12} className="my-2">
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="span" className="text-gray-500">
                                    標題
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="span" className="text-gray-500">
                                    筆數
                                </Typography>
                            </Grid>
                        </Grid>
                        {sourceData.map((target, _index) => {
                            const { name, count } = target;
                            if (!name) return null;
                            return (
                                <Grid container spacing={1} key={`error_content_${_index}`}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        <Typography className="ml-5" variant="h5" compoment="span">
                                            {name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                        <Typography className="ml-3" variant="h5" compoment="span">
                                            {count}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <div className="flex flex-y-flex-center mt-2">
                            <Button size="large" variant="contained" color="warning" onClick={handleSendOnClick}>
                                確定
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TemporariesDeleteDialog;
