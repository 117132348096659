import React, { useMemo } from 'react';
import { BusinessCardBox, MemoControllerBox } from '@icoach/components/';
import { formatCurrencyFn } from '@util/utils';
import { COLOR_MATCH } from '@icoach/accountBook/staticData';
import { StatusChipTooltip } from '@icoach/rentCabinet/CabinetOverviewTable';
import { BatchPaymentOrdersTable, OrderMoreRowsLabel } from '@icoach/accountBook/tables/BatchPaymentOrdersCreditCardTable';

const rowsLabel = [
    {
        cellKey: 'memberSheet',
        headerLabel: '會員',
        align: 'left',
        maxWidth: 20.4,
        formatCell: (cellValue, { memberNo, memberName, memberPicturePath }) => {
            let memberInfo = {
                memberNo,
                memberName,
                memberImg: memberPicturePath,
            };
            return <BusinessCardBox rowData={memberInfo} />;
        },
    },
    {
        cellKey: 'statusText',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (cellValue, { status, statusColor = 0, processResult }) => {
            return <StatusChipTooltip statusText={cellValue} statusMemo={processResult} color={COLOR_MATCH[statusColor]} isShowTip={String(status) === '9'} />;
        },
    },
    {
        cellKey: 'itemName',
        headerLabel: '品項',
        align: 'center',
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '扣款金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'returnPrice',
        headerLabel: '退款金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'invoiceNo',
        headerLabel: '發票號碼',
        align: 'center',
    },
    {
        cellKey: 'notes',
        headerLabel: '備註',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
    { ...OrderMoreRowsLabel },
];

const BatchPaymentOrdersCashTable = React.forwardRef((props, ref) => {
    const labelTitle = useMemo(() => rowsLabel, []);

    return <BatchPaymentOrdersTable {...props} ref={ref} labelTitle={labelTitle} />;
});

export default BatchPaymentOrdersCashTable;
