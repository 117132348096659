export default {
    counties: [
        '台北市',
        '基隆市',
        '新北市',
        '宜蘭縣',
        '桃園市',
        '新竹市',
        '新竹縣',
        '苗栗縣',
        '台中市',
        '彰化縣',
        '南投縣',
        '嘉義市',
        '嘉義縣',
        '雲林縣',
        '台南市',
        '高雄市',
        '澎湖縣',
        '金門縣',
        '屏東縣',
        '台東縣',
        '花蓮縣',
        '連江縣',
    ],
    districts: [
        [
            [
                '中正區',
                '大同區',
                '中山區',
                '松山區',
                '大安區',
                '萬華區',
                '信義區',
                '士林區',
                '北投區',
                '內湖區',
                '南港區',
                '文山區',
            ],

            ['100', '103', '104', '105', '106', '108', '110', '111', '112', '114', '115', '116'],
        ],
        [
            ['仁愛區', '信義區', '中正區', '中山區', '安樂區', '暖暖區', '七堵區'],

            ['200', '201', '202', '203', '204', '205', '206'],
        ],
        [
            [
                '萬里區',
                '金山區',
                '板橋區',
                '汐止區',
                '深坑區',
                '石碇區',
                '瑞芳區',
                '平溪區',
                '雙溪區',
                '貢寮區',
                '新店區',
                '坪林區',
                '烏來區',
                '永和區',
                '中和區',
                '土城區',
                '三峽區',
                '樹林區',
                '鶯歌區',
                '三重區',
                '新莊區',
                '泰山區',
                '林口區',
                '蘆洲區',
                '五股區',
                '八里區',
                '淡水區',
                '三芝區',
                '石門區',
            ],

            [
                '207',
                '208',
                '220',
                '221',
                '222',
                '223',
                '224',
                '226',
                '227',
                '228',
                '231',
                '232',
                '233',
                '234',
                '235',
                '236',
                '237',
                '238',
                '239',
                '241',
                '242',
                '243',
                '244',
                '247',
                '248',
                '249',
                '251',
                '252',
                '253',
            ],
        ],
        [
            [
                '宜蘭市',
                '頭城鎮',
                '礁溪鄉',
                '壯圍鄉',
                '員山鄉',
                '羅東鎮',
                '三星鄉',
                '大同鄉',
                '五結鄉',
                '冬山鄉',
                '蘇澳鎮',
                '南澳鄉',
                '釣魚台列嶼',
            ],

            [
                '260',
                '261',
                '262',
                '263',
                '264',
                '265',
                '266',
                '267',
                '268',
                '269',
                '270',
                '272',
                '290',
            ],
        ],
        [
            [
                '中壢區',
                '平鎮區',
                '龍潭區',
                '楊梅區',
                '新屋區',
                '觀音區',
                '桃園區',
                '龜山區',
                '八德區',
                '大溪區',
                '復興區',
                '大園區',
                '蘆竹區',
            ],

            [
                '320',
                '324',
                '325',
                '326',
                '327',
                '328',
                '330',
                '333',
                '334',
                '335',
                '336',
                '337',
                '338',
            ],
        ],
        [
            ['東區', '北區', '香山區'],

            ['300', '300', '300'],
        ],
        [
            [
                '竹北市',
                '湖口鄉',
                '新豐鄉',
                '新埔鎮',
                '關西鎮',
                '芎林鄉',
                '寶山鄉',
                '竹東鎮',
                '五峰鄉',
                '橫山鄉',
                '尖石鄉',
                '北埔鄉',
                '峨眉鄉',
            ],

            [
                '302',
                '303',
                '304',
                '305',
                '306',
                '307',
                '308',
                '310',
                '311',
                '312',
                '313',
                '314',
                '315',
            ],
        ],
        [
            [
                '竹南鎮',
                '頭份市',
                '三灣鄉',
                '南庄鄉',
                '獅潭鄉',
                '後龍鎮',
                '通霄鎮',
                '苑裡鎮',
                '苗栗市',
                '造橋鄉',
                '頭屋鄉',
                '公館鄉',
                '大湖鄉',
                '泰安鄉',
                '銅鑼鄉',
                '三義鄉',
                '西湖鄉',
                '卓蘭鎮',
            ],

            [
                '350',
                '351',
                '352',
                '353',
                '354',
                '356',
                '357',
                '358',
                '360',
                '361',
                '362',
                '363',
                '364',
                '365',
                '366',
                '367',
                '368',
                '369',
            ],
        ],
        [
            [
                '中區',
                '東區',
                '南區',
                '西區',
                '北區',
                '北屯區',
                '西屯區',
                '南屯區',
                '太平區',
                '大里區',
                '霧峰區',
                '烏日區',
                '豐原區',
                '后里區',
                '石岡區',
                '東勢區',
                '和平區',
                '新社區',
                '潭子區',
                '大雅區',
                '神岡區',
                '大肚區',
                '沙鹿區',
                '龍井區',
                '梧棲區',
                '清水區',
                '大甲區',
                '外埔區',
                '大安區',
            ],

            [
                '400',
                '401',
                '402',
                '403',
                '404',
                '406',
                '407',
                '408',
                '411',
                '412',
                '413',
                '414',
                '420',
                '421',
                '422',
                '423',
                '424',
                '426',
                '427',
                '428',
                '429',
                '432',
                '433',
                '434',
                '435',
                '436',
                '437',
                '438',
                '439',
            ],
        ],
        [
            [
                '彰化市',
                '芬園鄉',
                '花壇鄉',
                '秀水鄉',
                '鹿港鎮',
                '福興鄉',
                '線西鄉',
                '和美鎮',
                '伸港鄉',
                '員林市',
                '社頭鄉',
                '永靖鄉',
                '埔心鄉',
                '溪湖鎮',
                '大村鄉',
                '埔鹽鄉',
                '田中鎮',
                '北斗鎮',
                '田尾鄉',
                '埤頭鄉',
                '溪州鄉',
                '竹塘鄉',
                '二林鎮',
                '大城鄉',
                '芳苑鄉',
                '二水鄉',
            ],

            [
                '500',
                '502',
                '503',
                '504',
                '505',
                '506',
                '507',
                '508',
                '509',
                '510',
                '511',
                '512',
                '513',
                '514',
                '515',
                '516',
                '520',
                '521',
                '522',
                '523',
                '524',
                '525',
                '526',
                '527',
                '528',
                '530',
            ],
        ],
        [
            [
                '南投市',
                '中寮鄉',
                '草屯鎮',
                '國姓鄉',
                '埔里鎮',
                '仁愛鄉',
                '名間鄉',
                '集集鎮',
                '水里鄉',
                '魚池鄉',
                '信義鄉',
                '竹山鎮',
                '鹿谷鄉',
            ],

            [
                '540',
                '541',
                '542',
                '544',
                '545',
                '546',
                '551',
                '552',
                '553',
                '555',
                '556',
                '557',
                '558',
            ],
        ],
        [
            ['東區', '西區'],

            ['600', '600'],
        ],
        [
            [
                '番路鄉',
                '梅山鄉',
                '竹崎鄉',
                '阿里山',
                '中埔鄉',
                '大埔鄉',
                '水上鄉',
                '鹿草鄉',
                '太保市',
                '朴子市',
                '東石鄉',
                '六腳鄉',
                '新港鄉',
                '民雄鄉',
                '大林鎮',
                '溪口鄉',
                '義竹鄉',
                '布袋鎮',
            ],

            [
                '602',
                '603',
                '604',
                '605',
                '606',
                '607',
                '608',
                '611',
                '612',
                '613',
                '614',
                '615',
                '616',
                '621',
                '622',
                '623',
                '624',
                '625',
            ],
        ],
        [
            [
                '斗南鎮',
                '大埤鄉',
                '虎尾鎮',
                '土庫鎮',
                '褒忠鄉',
                '東勢鄉',
                '台西鄉',
                '崙背鄉',
                '麥寮鄉',
                '斗六市',
                '林內鄉',
                '古坑鄉',
                '莿桐鄉',
                '西螺鎮',
                '二崙鄉',
                '北港鎮',
                '水林鄉',
                '口湖鄉',
                '四湖鄉',
                '元長鄉',
            ],

            [
                '630',
                '631',
                '632',
                '633',
                '634',
                '635',
                '636',
                '637',
                '638',
                '640',
                '643',
                '646',
                '647',
                '648',
                '649',
                '651',
                '652',
                '653',
                '654',
                '655',
            ],
        ],
        [
            [
                '中西區',
                '東區',
                '南區',
                '北區',
                '安平區',
                '安南區',
                '永康區',
                '歸仁區',
                '新化區',
                '左鎮區',
                '玉井區',
                '楠西區',
                '南化區',
                '仁德區',
                '關廟區',
                '龍崎區',
                '官田區',
                '麻豆區',
                '佳里區',
                '西港區',
                '七股區',
                '將軍區',
                '學甲區',
                '北門區',
                '新營區',
                '後壁區',
                '白河區',
                '東山區',
                '六甲區',
                '下營區',
                '柳營區',
                '鹽水區',
                '善化區',
                '大內區',
                '山上區',
                '新市區',
                '安定區',
            ],

            [
                '700',
                '701',
                '702',
                '704',
                '708',
                '709',
                '710',
                '711',
                '712',
                '713',
                '714',
                '715',
                '716',
                '717',
                '718',
                '719',
                '720',
                '721',
                '722',
                '723',
                '724',
                '725',
                '726',
                '727',
                '730',
                '731',
                '732',
                '733',
                '734',
                '735',
                '736',
                '737',
                '741',
                '742',
                '743',
                '744',
                '745',
            ],
        ],
        [
            [
                '新興區',
                '前金區',
                '苓雅區',
                '鹽埕區',
                '鼓山區',
                '旗津區',
                '前鎮區',
                '三民區',
                '楠梓區',
                '小港區',
                '左營區',
                '仁武區',
                '大社區',
                '東沙群島',
                '南沙群島',
                '岡山區',
                '路竹區',
                '阿蓮區',
                '田寮區',
                '燕巢區',
                '橋頭區',
                '梓官區',
                '彌陀區',
                '永安區',
                '湖內區',
                '鳳山區',
                '大寮區',
                '林園區',
                '鳥松區',
                '大樹區',
                '旗山區',
                '美濃區',
                '六龜區',
                '內門區',
                '杉林區',
                '甲仙區',
                '桃源區',
                '那瑪夏區',
                '茂林區',
                '茄萣區',
            ],

            [
                '800',
                '801',
                '802',
                '803',
                '804',
                '805',
                '806',
                '807',
                '811',
                '812',
                '813',
                '814',
                '815',
                '817',
                '819',
                '820',
                '821',
                '822',
                '823',
                '824',
                '825',
                '826',
                '827',
                '828',
                '829',
                '830',
                '831',
                '832',
                '833',
                '840',
                '842',
                '843',
                '844',
                '845',
                '846',
                '847',
                '848',
                '849',
                '851',
                '852',
            ],
        ],
        [
            ['馬公市', '西嶼鄉', '望安鄉', '七美鄉', '白沙鄉', '湖西鄉'],

            ['880', '881', '882', '883', '884', '885'],
        ],
        [
            ['金沙鎮', '金湖鎮', '金寧鄉', '金城鎮', '烈嶼鄉', '烏坵鄉'],

            ['890', '891', '892', '893', '894', '896'],
        ],
        [
            [
                '屏東市',
                '三地門鄉',
                '霧台鄉',
                '瑪家鄉',
                '九如鄉',
                '里港鄉',
                '高樹鄉',
                '鹽埔鄉',
                '長治鄉',
                '麟洛鄉',
                '竹田鄉',
                '內埔鄉',
                '萬丹鄉',
                '潮州鎮',
                '泰武鄉',
                '來義鄉',
                '萬巒鄉',
                '崁頂鄉',
                '新埤鄉',
                '南州鄉',
                '林邊鄉',
                '東港鎮',
                '琉球鄉',
                '佳冬鄉',
                '新園鄉',
                '枋寮鄉',
                '枋山鄉',
                '春日鄉',
                '獅子鄉',
                '車城鄉',
                '牡丹鄉',
                '恆春鎮',
                '滿州鄉',
            ],

            [
                '900',
                '901',
                '902',
                '903',
                '904',
                '905',
                '906',
                '907',
                '908',
                '909',
                '911',
                '912',
                '913',
                '920',
                '921',
                '922',
                '923',
                '924',
                '925',
                '926',
                '927',
                '928',
                '929',
                '931',
                '932',
                '940',
                '941',
                '942',
                '943',
                '944',
                '945',
                '946',
                '947',
            ],
        ],
        [
            [
                '台東市',
                '綠島鄉',
                '蘭嶼鄉',
                '延平鄉',
                '卑南鄉',
                '鹿野鄉',
                '關山鎮',
                '海端鄉',
                '池上鄉',
                '東河鄉',
                '成功鎮',
                '長濱鄉',
                '太麻里',
                '金峰鄉',
                '大武鄉',
                '達仁鄉',
            ],

            [
                '950',
                '951',
                '952',
                '953',
                '954',
                '955',
                '956',
                '957',
                '958',
                '959',
                '961',
                '962',
                '963',
                '964',
                '965',
                '966',
            ],
        ],
        [
            [
                '花蓮市',
                '新城鄉',
                '秀林鄉',
                '吉安鄉',
                '壽豐鄉',
                '鳳林鎮',
                '光復鄉',
                '豐濱鄉',
                '瑞穗鄉',
                '萬榮鄉',
                '玉里鎮',
                '卓溪鄉',
                '富里鄉',
            ],

            [
                '970',
                '971',
                '972',
                '973',
                '974',
                '975',
                '976',
                '977',
                '978',
                '979',
                '981',
                '982',
                '983',
            ],
        ],
        [
            ['南竿鄉', '北竿鄉', '莒光鄉', '東引鄉'],

            ['209', '210', '211', '212'],
        ],
    ],
    zipCodeObj: {
        100: {
            district: 0,
            city: 0,
        },
        103: {
            district: 1,
            city: 0,
        },
        104: {
            district: 2,
            city: 0,
        },
        105: {
            district: 3,
            city: 0,
        },
        106: {
            district: 4,
            city: 0,
        },
        108: {
            district: 5,
            city: 0,
        },
        110: {
            district: 6,
            city: 0,
        },
        111: {
            district: 7,
            city: 0,
        },
        112: {
            district: 8,
            city: 0,
        },
        114: {
            district: 9,
            city: 0,
        },
        115: {
            district: 10,
            city: 0,
        },
        116: {
            district: 11,
            city: 0,
        },
        200: {
            district: 0,
            city: 1,
        },
        201: {
            district: 1,
            city: 1,
        },
        202: {
            district: 2,
            city: 1,
        },
        203: {
            district: 3,
            city: 1,
        },
        204: {
            district: 4,
            city: 1,
        },
        205: {
            district: 5,
            city: 1,
        },
        206: {
            district: 6,
            city: 1,
        },
        207: {
            district: 0,
            city: 2,
        },
        208: {
            district: 1,
            city: 2,
        },
        209: {
            district: 0,
            city: 21,
        },
        210: {
            district: 1,
            city: 21,
        },
        211: {
            district: 2,
            city: 21,
        },
        212: {
            district: 3,
            city: 21,
        },
        220: {
            district: 2,
            city: 2,
        },
        221: {
            district: 3,
            city: 2,
        },
        222: {
            district: 4,
            city: 2,
        },
        223: {
            district: 5,
            city: 2,
        },
        224: {
            district: 6,
            city: 2,
        },
        226: {
            district: 7,
            city: 2,
        },
        227: {
            district: 8,
            city: 2,
        },
        228: {
            district: 9,
            city: 2,
        },
        231: {
            district: 10,
            city: 2,
        },
        232: {
            district: 11,
            city: 2,
        },
        233: {
            district: 12,
            city: 2,
        },
        234: {
            district: 13,
            city: 2,
        },
        235: {
            district: 14,
            city: 2,
        },
        236: {
            district: 15,
            city: 2,
        },
        237: {
            district: 16,
            city: 2,
        },
        238: {
            district: 17,
            city: 2,
        },
        239: {
            district: 18,
            city: 2,
        },
        241: {
            district: 19,
            city: 2,
        },
        242: {
            district: 20,
            city: 2,
        },
        243: {
            district: 21,
            city: 2,
        },
        244: {
            district: 22,
            city: 2,
        },
        247: {
            district: 23,
            city: 2,
        },
        248: {
            district: 24,
            city: 2,
        },
        249: {
            district: 25,
            city: 2,
        },
        251: {
            district: 26,
            city: 2,
        },
        252: {
            district: 27,
            city: 2,
        },
        253: {
            district: 28,
            city: 2,
        },
        260: {
            district: 0,
            city: 3,
        },
        261: {
            district: 1,
            city: 3,
        },
        262: {
            district: 2,
            city: 3,
        },
        263: {
            district: 3,
            city: 3,
        },
        264: {
            district: 4,
            city: 3,
        },
        265: {
            district: 5,
            city: 3,
        },
        266: {
            district: 6,
            city: 3,
        },
        267: {
            district: 7,
            city: 3,
        },
        268: {
            district: 8,
            city: 3,
        },
        269: {
            district: 9,
            city: 3,
        },
        270: {
            district: 10,
            city: 3,
        },
        272: {
            district: 11,
            city: 3,
        },
        290: {
            district: 12,
            city: 3,
        },
        300: {
            district: 2,
            city: 5,
        },
        302: {
            district: 0,
            city: 6,
        },
        303: {
            district: 1,
            city: 6,
        },
        304: {
            district: 2,
            city: 6,
        },
        305: {
            district: 3,
            city: 6,
        },
        306: {
            district: 4,
            city: 6,
        },
        307: {
            district: 5,
            city: 6,
        },
        308: {
            district: 6,
            city: 6,
        },
        310: {
            district: 7,
            city: 6,
        },
        311: {
            district: 8,
            city: 6,
        },
        312: {
            district: 9,
            city: 6,
        },
        313: {
            district: 10,
            city: 6,
        },
        314: {
            district: 11,
            city: 6,
        },
        315: {
            district: 12,
            city: 6,
        },
        320: {
            district: 0,
            city: 4,
        },
        324: {
            district: 1,
            city: 4,
        },
        325: {
            district: 2,
            city: 4,
        },
        326: {
            district: 3,
            city: 4,
        },
        327: {
            district: 4,
            city: 4,
        },
        328: {
            district: 5,
            city: 4,
        },
        330: {
            district: 6,
            city: 4,
        },
        333: {
            district: 7,
            city: 4,
        },
        334: {
            district: 8,
            city: 4,
        },
        335: {
            district: 9,
            city: 4,
        },
        336: {
            district: 10,
            city: 4,
        },
        337: {
            district: 11,
            city: 4,
        },
        338: {
            district: 12,
            city: 4,
        },
        350: {
            district: 0,
            city: 7,
        },
        351: {
            district: 1,
            city: 7,
        },
        352: {
            district: 2,
            city: 7,
        },
        353: {
            district: 3,
            city: 7,
        },
        354: {
            district: 4,
            city: 7,
        },
        356: {
            district: 5,
            city: 7,
        },
        357: {
            district: 6,
            city: 7,
        },
        358: {
            district: 7,
            city: 7,
        },
        360: {
            district: 8,
            city: 7,
        },
        361: {
            district: 9,
            city: 7,
        },
        362: {
            district: 10,
            city: 7,
        },
        363: {
            district: 11,
            city: 7,
        },
        364: {
            district: 12,
            city: 7,
        },
        365: {
            district: 13,
            city: 7,
        },
        366: {
            district: 14,
            city: 7,
        },
        367: {
            district: 15,
            city: 7,
        },
        368: {
            district: 16,
            city: 7,
        },
        369: {
            district: 17,
            city: 7,
        },
        400: {
            district: 0,
            city: 8,
        },
        401: {
            district: 1,
            city: 8,
        },
        402: {
            district: 2,
            city: 8,
        },
        403: {
            district: 3,
            city: 8,
        },
        404: {
            district: 4,
            city: 8,
        },
        406: {
            district: 5,
            city: 8,
        },
        407: {
            district: 6,
            city: 8,
        },
        408: {
            district: 7,
            city: 8,
        },
        411: {
            district: 8,
            city: 8,
        },
        412: {
            district: 9,
            city: 8,
        },
        413: {
            district: 10,
            city: 8,
        },
        414: {
            district: 11,
            city: 8,
        },
        420: {
            district: 12,
            city: 8,
        },
        421: {
            district: 13,
            city: 8,
        },
        422: {
            district: 14,
            city: 8,
        },
        423: {
            district: 15,
            city: 8,
        },
        424: {
            district: 16,
            city: 8,
        },
        426: {
            district: 17,
            city: 8,
        },
        427: {
            district: 18,
            city: 8,
        },
        428: {
            district: 19,
            city: 8,
        },
        429: {
            district: 20,
            city: 8,
        },
        432: {
            district: 21,
            city: 8,
        },
        433: {
            district: 22,
            city: 8,
        },
        434: {
            district: 23,
            city: 8,
        },
        435: {
            district: 24,
            city: 8,
        },
        436: {
            district: 25,
            city: 8,
        },
        437: {
            district: 26,
            city: 8,
        },
        438: {
            district: 27,
            city: 8,
        },
        439: {
            district: 28,
            city: 8,
        },
        500: {
            district: 0,
            city: 9,
        },
        502: {
            district: 1,
            city: 9,
        },
        503: {
            district: 2,
            city: 9,
        },
        504: {
            district: 3,
            city: 9,
        },
        505: {
            district: 4,
            city: 9,
        },
        506: {
            district: 5,
            city: 9,
        },
        507: {
            district: 6,
            city: 9,
        },
        508: {
            district: 7,
            city: 9,
        },
        509: {
            district: 8,
            city: 9,
        },
        510: {
            district: 9,
            city: 9,
        },
        511: {
            district: 10,
            city: 9,
        },
        512: {
            district: 11,
            city: 9,
        },
        513: {
            district: 12,
            city: 9,
        },
        514: {
            district: 13,
            city: 9,
        },
        515: {
            district: 14,
            city: 9,
        },
        516: {
            district: 15,
            city: 9,
        },
        520: {
            district: 16,
            city: 9,
        },
        521: {
            district: 17,
            city: 9,
        },
        522: {
            district: 18,
            city: 9,
        },
        523: {
            district: 19,
            city: 9,
        },
        524: {
            district: 20,
            city: 9,
        },
        525: {
            district: 21,
            city: 9,
        },
        526: {
            district: 22,
            city: 9,
        },
        527: {
            district: 23,
            city: 9,
        },
        528: {
            district: 24,
            city: 9,
        },
        530: {
            district: 25,
            city: 9,
        },
        540: {
            district: 0,
            city: 10,
        },
        541: {
            district: 1,
            city: 10,
        },
        542: {
            district: 2,
            city: 10,
        },
        544: {
            district: 3,
            city: 10,
        },
        545: {
            district: 4,
            city: 10,
        },
        546: {
            district: 5,
            city: 10,
        },
        551: {
            district: 6,
            city: 10,
        },
        552: {
            district: 7,
            city: 10,
        },
        553: {
            district: 8,
            city: 10,
        },
        555: {
            district: 9,
            city: 10,
        },
        556: {
            district: 10,
            city: 10,
        },
        557: {
            district: 11,
            city: 10,
        },
        558: {
            district: 12,
            city: 10,
        },
        600: {
            district: 1,
            city: 11,
        },
        602: {
            district: 0,
            city: 12,
        },
        603: {
            district: 1,
            city: 12,
        },
        604: {
            district: 2,
            city: 12,
        },
        605: {
            district: 3,
            city: 12,
        },
        606: {
            district: 4,
            city: 12,
        },
        607: {
            district: 5,
            city: 12,
        },
        608: {
            district: 6,
            city: 12,
        },
        611: {
            district: 7,
            city: 12,
        },
        612: {
            district: 8,
            city: 12,
        },
        613: {
            district: 9,
            city: 12,
        },
        614: {
            district: 10,
            city: 12,
        },
        615: {
            district: 11,
            city: 12,
        },
        616: {
            district: 12,
            city: 12,
        },
        621: {
            district: 13,
            city: 12,
        },
        622: {
            district: 14,
            city: 12,
        },
        623: {
            district: 15,
            city: 12,
        },
        624: {
            district: 16,
            city: 12,
        },
        625: {
            district: 17,
            city: 12,
        },
        630: {
            district: 0,
            city: 13,
        },
        631: {
            district: 1,
            city: 13,
        },
        632: {
            district: 2,
            city: 13,
        },
        633: {
            district: 3,
            city: 13,
        },
        634: {
            district: 4,
            city: 13,
        },
        635: {
            district: 5,
            city: 13,
        },
        636: {
            district: 6,
            city: 13,
        },
        637: {
            district: 7,
            city: 13,
        },
        638: {
            district: 8,
            city: 13,
        },
        640: {
            district: 9,
            city: 13,
        },
        643: {
            district: 10,
            city: 13,
        },
        646: {
            district: 11,
            city: 13,
        },
        647: {
            district: 12,
            city: 13,
        },
        648: {
            district: 13,
            city: 13,
        },
        649: {
            district: 14,
            city: 13,
        },
        651: {
            district: 15,
            city: 13,
        },
        652: {
            district: 16,
            city: 13,
        },
        653: {
            district: 17,
            city: 13,
        },
        654: {
            district: 18,
            city: 13,
        },
        655: {
            district: 19,
            city: 13,
        },
        700: {
            district: 0,
            city: 14,
        },
        701: {
            district: 1,
            city: 14,
        },
        702: {
            district: 2,
            city: 14,
        },
        704: {
            district: 3,
            city: 14,
        },
        708: {
            district: 4,
            city: 14,
        },
        709: {
            district: 5,
            city: 14,
        },
        710: {
            district: 6,
            city: 14,
        },
        711: {
            district: 7,
            city: 14,
        },
        712: {
            district: 8,
            city: 14,
        },
        713: {
            district: 9,
            city: 14,
        },
        714: {
            district: 10,
            city: 14,
        },
        715: {
            district: 11,
            city: 14,
        },
        716: {
            district: 12,
            city: 14,
        },
        717: {
            district: 13,
            city: 14,
        },
        718: {
            district: 14,
            city: 14,
        },
        719: {
            district: 15,
            city: 14,
        },
        720: {
            district: 16,
            city: 14,
        },
        721: {
            district: 17,
            city: 14,
        },
        722: {
            district: 18,
            city: 14,
        },
        723: {
            district: 19,
            city: 14,
        },
        724: {
            district: 20,
            city: 14,
        },
        725: {
            district: 21,
            city: 14,
        },
        726: {
            district: 22,
            city: 14,
        },
        727: {
            district: 23,
            city: 14,
        },
        730: {
            district: 24,
            city: 14,
        },
        731: {
            district: 25,
            city: 14,
        },
        732: {
            district: 26,
            city: 14,
        },
        733: {
            district: 27,
            city: 14,
        },
        734: {
            district: 28,
            city: 14,
        },
        735: {
            district: 29,
            city: 14,
        },
        736: {
            district: 30,
            city: 14,
        },
        737: {
            district: 31,
            city: 14,
        },
        741: {
            district: 32,
            city: 14,
        },
        742: {
            district: 33,
            city: 14,
        },
        743: {
            district: 34,
            city: 14,
        },
        744: {
            district: 35,
            city: 14,
        },
        745: {
            district: 36,
            city: 14,
        },
        800: {
            district: 0,
            city: 15,
        },
        801: {
            district: 1,
            city: 15,
        },
        802: {
            district: 2,
            city: 15,
        },
        803: {
            district: 3,
            city: 15,
        },
        804: {
            district: 4,
            city: 15,
        },
        805: {
            district: 5,
            city: 15,
        },
        806: {
            district: 6,
            city: 15,
        },
        807: {
            district: 7,
            city: 15,
        },
        811: {
            district: 8,
            city: 15,
        },
        812: {
            district: 9,
            city: 15,
        },
        813: {
            district: 10,
            city: 15,
        },
        814: {
            district: 11,
            city: 15,
        },
        815: {
            district: 12,
            city: 15,
        },
        817: {
            district: 13,
            city: 15,
        },
        819: {
            district: 14,
            city: 15,
        },
        820: {
            district: 15,
            city: 15,
        },
        821: {
            district: 16,
            city: 15,
        },
        822: {
            district: 17,
            city: 15,
        },
        823: {
            district: 18,
            city: 15,
        },
        824: {
            district: 19,
            city: 15,
        },
        825: {
            district: 20,
            city: 15,
        },
        826: {
            district: 21,
            city: 15,
        },
        827: {
            district: 22,
            city: 15,
        },
        828: {
            district: 23,
            city: 15,
        },
        829: {
            district: 24,
            city: 15,
        },
        830: {
            district: 25,
            city: 15,
        },
        831: {
            district: 26,
            city: 15,
        },
        832: {
            district: 27,
            city: 15,
        },
        833: {
            district: 28,
            city: 15,
        },
        840: {
            district: 29,
            city: 15,
        },
        842: {
            district: 30,
            city: 15,
        },
        843: {
            district: 31,
            city: 15,
        },
        844: {
            district: 32,
            city: 15,
        },
        845: {
            district: 33,
            city: 15,
        },
        846: {
            district: 34,
            city: 15,
        },
        847: {
            district: 35,
            city: 15,
        },
        848: {
            district: 36,
            city: 15,
        },
        849: {
            district: 37,
            city: 15,
        },
        851: {
            district: 38,
            city: 15,
        },
        852: {
            district: 39,
            city: 15,
        },
        880: {
            district: 0,
            city: 16,
        },
        881: {
            district: 1,
            city: 16,
        },
        882: {
            district: 2,
            city: 16,
        },
        883: {
            district: 3,
            city: 16,
        },
        884: {
            district: 4,
            city: 16,
        },
        885: {
            district: 5,
            city: 16,
        },
        890: {
            district: 0,
            city: 17,
        },
        891: {
            district: 1,
            city: 17,
        },
        892: {
            district: 2,
            city: 17,
        },
        893: {
            district: 3,
            city: 17,
        },
        894: {
            district: 4,
            city: 17,
        },
        896: {
            district: 5,
            city: 17,
        },
        900: {
            district: 0,
            city: 18,
        },
        901: {
            district: 1,
            city: 18,
        },
        902: {
            district: 2,
            city: 18,
        },
        903: {
            district: 3,
            city: 18,
        },
        904: {
            district: 4,
            city: 18,
        },
        905: {
            district: 5,
            city: 18,
        },
        906: {
            district: 6,
            city: 18,
        },
        907: {
            district: 7,
            city: 18,
        },
        908: {
            district: 8,
            city: 18,
        },
        909: {
            district: 9,
            city: 18,
        },
        911: {
            district: 10,
            city: 18,
        },
        912: {
            district: 11,
            city: 18,
        },
        913: {
            district: 12,
            city: 18,
        },
        920: {
            district: 13,
            city: 18,
        },
        921: {
            district: 14,
            city: 18,
        },
        922: {
            district: 15,
            city: 18,
        },
        923: {
            district: 16,
            city: 18,
        },
        924: {
            district: 17,
            city: 18,
        },
        925: {
            district: 18,
            city: 18,
        },
        926: {
            district: 19,
            city: 18,
        },
        927: {
            district: 20,
            city: 18,
        },
        928: {
            district: 21,
            city: 18,
        },
        929: {
            district: 22,
            city: 18,
        },
        931: {
            district: 23,
            city: 18,
        },
        932: {
            district: 24,
            city: 18,
        },
        940: {
            district: 25,
            city: 18,
        },
        941: {
            district: 26,
            city: 18,
        },
        942: {
            district: 27,
            city: 18,
        },
        943: {
            district: 28,
            city: 18,
        },
        944: {
            district: 29,
            city: 18,
        },
        945: {
            district: 30,
            city: 18,
        },
        946: {
            district: 31,
            city: 18,
        },
        947: {
            district: 32,
            city: 18,
        },
        950: {
            district: 0,
            city: 19,
        },
        951: {
            district: 1,
            city: 19,
        },
        952: {
            district: 2,
            city: 19,
        },
        953: {
            district: 3,
            city: 19,
        },
        954: {
            district: 4,
            city: 19,
        },
        955: {
            district: 5,
            city: 19,
        },
        956: {
            district: 6,
            city: 19,
        },
        957: {
            district: 7,
            city: 19,
        },
        958: {
            district: 8,
            city: 19,
        },
        959: {
            district: 9,
            city: 19,
        },
        961: {
            district: 10,
            city: 19,
        },
        962: {
            district: 11,
            city: 19,
        },
        963: {
            district: 12,
            city: 19,
        },
        964: {
            district: 13,
            city: 19,
        },
        965: {
            district: 14,
            city: 19,
        },
        966: {
            district: 15,
            city: 19,
        },
        970: {
            district: 0,
            city: 20,
        },
        971: {
            district: 1,
            city: 20,
        },
        972: {
            district: 2,
            city: 20,
        },
        973: {
            district: 3,
            city: 20,
        },
        974: {
            district: 4,
            city: 20,
        },
        975: {
            district: 5,
            city: 20,
        },
        976: {
            district: 6,
            city: 20,
        },
        977: {
            district: 7,
            city: 20,
        },
        978: {
            district: 8,
            city: 20,
        },
        979: {
            district: 9,
            city: 20,
        },
        981: {
            district: 10,
            city: 20,
        },
        982: {
            district: 11,
            city: 20,
        },
        983: {
            district: 12,
            city: 20,
        },
    },
};
