// redux
import { combineReducers } from 'redux';
// types
import { SET_PDF_DIALOG_DATA } from './types';

export function PdfDialog(state = { Data: {} }, action) {
    switch (action.type) {
        case SET_PDF_DIALOG_DATA:
            if (action.Data === null) {
                action.Data = {};
            }
            return { Data: action.Data };

        default:
            return state;
    }
}

const Reducer = combineReducers({
    PdfDialog,
});

export default Reducer;
