import React, { createContext, useContext } from 'react';

const PointsProductGiftContext = createContext(undefined);

export const PointsProductGiftProvider = ({ children, value }) => {
    return <PointsProductGiftContext.Provider value={value}>{children}</PointsProductGiftContext.Provider>;
};

export const usePointsProductGiftContext = () => {
    const context = useContext(PointsProductGiftContext);
    if (context === undefined) {
        throw new Error('usePointsProductGiftContext 必須在 PointsProductGiftProvider 中使用');
    }
    return context;
};
