import React, { useState } from 'react';
import { Button } from '@common/components/';
import useLanding from '@icoach/landingPage/components/useLanding';
import AppointmentFigureAnalysisFormDialog from '@icoach/landingPage/dialog/AppointmentFigureAnalysisFormDialog';
import AppointmentFormDialog from '@icoach/landingPage/dialog/AppointmentFormDialog';

const AppointmentHeaderButtons = React.forwardRef((props, ref) => {
    const [openAppointmentFormDialog, setOpenAppointmentFormDialog] = useState(false);
    const [openAppointmentFigureAnalysisFormDialog, setOpenAppointmentFigureAnalysisFormDialog] = useState(false);
    const { options, refresh, appointmentDateRef } = useLanding();

    const handleMemberOnClick = () => {
        setOpenAppointmentFormDialog(true);
    };
    const handleFAOnClick = () => {
        setOpenAppointmentFigureAnalysisFormDialog(true);
    };
    
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleFAOnClick}>
                FA入會約運動
            </Button>
            <Button variant="contained" onClick={handleMemberOnClick}>
                會員預約
            </Button>
            <AppointmentFormDialog
                sourceData={{ appointmentDate: appointmentDateRef.current }}
                open={openAppointmentFormDialog}
                onClose={() => setOpenAppointmentFormDialog(false)}
                options={options.current}
                refresh={refresh}
            />
            <AppointmentFigureAnalysisFormDialog
                sourceData={{ appointmentDate: appointmentDateRef.current }}
                open={openAppointmentFigureAnalysisFormDialog}
                onClose={() => setOpenAppointmentFigureAnalysisFormDialog(false)}
                options={options.current}
                refresh={refresh}
            />
        </React.Fragment>
    );
});

export default AppointmentHeaderButtons;
