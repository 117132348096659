import React from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { Table, TableHead, TableBody, TableRow, TableCell, Alert } from '../../../components';
import { ProductBox } from '../components';
import { formatCurrencyFn } from '../../../util/utils';

const CartProductItemRow = React.forwardRef((props, ref) => {
    const { data = {} } = props;
    const {
        productID,
        productTypeText,
        productName,
        variantName,
        unitPrice,
        quantity,
        totalPrice,
    } = data;

    return (
        <TableRow className={'cart-table-item product-item'}>
            <TableCell>
                <ProductBox
                    className={'flex-align-center'}
                    name={productName}
                    variantName={variantName}
                />
            </TableCell>
            <TableCell align={'center'}>{productTypeText}</TableCell>
            <TableCell align={'center'}>{formatCurrencyFn(unitPrice)}</TableCell>
            <TableCell align={'center'}>{productID ? quantity : ''}</TableCell>
            <TableCell align={'right'}>
                <strong>{formatCurrencyFn(totalPrice)}</strong>
            </TableCell>
        </TableRow>
    );
});

// const CartFeeItemRow = React.forwardRef((props, ref) => {
//     const { data = {} } = props;
//     const { name, totalPrice } = data;
//
//     return (
//         <TableRow className={'cart-table-item fee-item'}>
//             <TableCell colSpan={3}>
//                 <ProductBox className={'fee-item'} name={name} />
//             </TableCell>
//             <TableCell colSpan={2}>{formatCurrencyFn(totalPrice)}</TableCell>
//             <TableCell align={'right'}>
//                 <strong>{formatCurrencyFn(totalPrice)}</strong>
//             </TableCell>
//         </TableRow>
//     );
// });

const CartTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>品項</TableCell>
                <TableCell align={'center'}>類型</TableCell>
                <TableCell align={'center'}>單價</TableCell>
                <TableCell align={'center'}>數量</TableCell>
                <TableCell align={'right'}>合計</TableCell>
            </TableRow>
        </TableHead>
    );
};

// 顯示訂單的項目
const OrderItemsTable = React.forwardRef((props, ref) => {
    const { className, data } = props;
    return (
        <Table className={clsx('cart-table', className)}>
            <CartTableHead />
            <TableBody>
                {!isEmpty(data) ? (
                    data.map((item) => {
                        return (
                            <CartProductItemRow
                                key={`product-${item.productType}-${item.productID}-${item.variantID}`}
                                data={item}
                            />
                        );
                    })
                ) : (
                    <TableRow tabIndex={-1}>
                        <TableCell colSpan={7}>
                            <Alert variant={'standard'} severity={'info'} color={'info'}>
                                尚無資料
                            </Alert>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
});

export default OrderItemsTable;
