import { useCallback } from 'react';
import useAjax from '@apis/useAjax';

const GET_SYSTEM_BUILD_TIME_API = `/api/system/build-time/`;

const useAppSystemApi = () => {
    const Ajax = useAjax();

    const getWebClubInfoApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_SYSTEM_BUILD_TIME_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getWebClubInfoApi,
    };
};

export default useAppSystemApi;
