import React from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import Tablenization from '../../components/Tablenization';

import { getRowsDataTotal } from '../staticData';
import { isValidDate } from '../../../util/moment';
import { DateLabel } from '../components';

const makeLabelTitleObj = (data) => {
    const { saleCategoryID, saleCategoryName } = data;
    return {
        cellKey: `item${saleCategoryID}`,
        headerLabel: saleCategoryName,
        align: 'center',
        minWidth: 98,
    };
};

const transposedDailyDetailData = (data) => {
    const rowsLabel = [
        {
            cellKey: 'date',
            headerLabel: '日期',
            width: 12,
            formatCell: (date) => {
                if (isValidDate(date)) return <DateLabel date={date} />;
                return date;
            },
        },
    ];
    const rowsData = [];
    if (!isEmpty(data)) {
        let dailyDetail = Object.values(data[0].dailyDetail);

        dailyDetail.forEach((val, j) => {
            const { date } = val;
            if (rowsData[j] === undefined) {
                rowsData.push({});
            }
            rowsData[j].date = date;
            data.forEach((item, i) => {
                rowsData[j][`item${item.saleCategoryID}`] = data[i].dailyDetail[j].amount;
                if (rowsLabel[i + 1] === undefined) {
                    rowsLabel[i + 1] = makeLabelTitleObj(item);
                }
            });
        });
    }
    return { rowsLabel, rowsData };
};

const HCDailyTable = (props) => {
    const { className, data } = props;
    const handleData = (data) => {
        let { rowsData, rowsLabel } = transposedDailyDetailData(data);
        let totalRow = [
            {
                ...getRowsDataTotal(rowsData),
                date: '總盒數',
            },
        ];
        if (!isEmpty(totalRow)) rowsData = totalRow.concat(rowsData);
        return { rowsLabel, rowsData };
    };
    const { rowsData, rowsLabel } = handleData(data);

    return (
        <Tablenization cardProps={{ className: clsx('daily-table', className) }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel} />
            <Tablenization.TableRowBody
                headerRow={rowsLabel}
                rowsData={rowsData}
                bodyRowClass={{ key: 'date', value: '總盒數', className: 'total-row' }}
            />
        </Tablenization>
    );
};

export default HCDailyTable;
