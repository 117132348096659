import React from 'react';
import _ from 'lodash';
import useInBody from '@icoach/inbody/components/useInBody';
import { Edit as EditIcon } from '@common/SvgIcon/';
import { Box, IconButton } from '@common/components/';
import clsx from 'clsx';

const InBodyXFactor = React.forwardRef((props, ref) => {
    const { sourceData = [], measureID } = props;
    const { dialogRef } = useInBody();

    const handleOnClick = () => {
        dialogRef.current.openXFactorDialog(sourceData, measureID);
    };
    const isEmptyData = _.isEmpty(sourceData);

    return (
        <Box onClick={handleOnClick} className={clsx('cursor-pointer', { 'text-left': !isEmptyData })}>
            {isEmptyData ? (
                <IconButton>
                    <EditIcon />
                </IconButton>
            ) : (
                <ol className="x-factort">
                    {sourceData[0] && <li>{sourceData[0].taskTitle}</li>}
                    {sourceData[1] && <li>{sourceData[1].taskTitle}</li>}
                </ol>
            )}
        </Box>
    );
});

export default InBodyXFactor;
