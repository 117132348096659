import React, { useState } from 'react';
import { Grid } from '@mui/material';
import useAppointment from '@icoach/appointment/components/useAppointment';
import { ReadTextField, MemberAvatar } from '@icoach/components/';
import AppointmentFormDialog from '@icoach/landingPage/dialog/AppointmentFormDialog';
import AppointmentFigureAnalysisFormDialog from '@icoach/landingPage/dialog/AppointmentFigureAnalysisFormDialog';
import { Card, Typography, Stack, IconButton } from '@common/components/';
import { Edit as EditIcon } from '@common/SvgIcon/';
import { parseDate } from '@util/moment';

// 1.顧客 2.會員
const EditAppointForm = (props) => {
    const { type, sourceData: sourceDataProps } = props;
    const { options: optionsProps, refresh } = useAppointment();
    const [options] = useState(optionsProps);
    const [open, setOpen] = useState(false);

    return (
        <div>
            <IconButton onClick={() => setOpen(true)}>
                <EditIcon />
            </IconButton>
            {String(type) === '2' && (
                <AppointmentFormDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    options={options}
                    sourceData={sourceDataProps}
                    refresh={refresh}
                    isEdit={true}
                />
            )}
            {String(type) === '1' && (
                <AppointmentFigureAnalysisFormDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    options={options}
                    sourceData={sourceDataProps}
                    refresh={refresh}
                    isEdit={true}
                />
            )}
        </div>
    );
};

const AppointmentPeopleInformationItem = (props) => {
    const { sourceData } = props;
    const {
        name,
        nickName,
        memberNo,
        imageSrc,
        appointmentDate,
        appointmentTypeText,
        cellPhone,
        employeeName,
        createdDate,
        notes,
        peopleType,
    } = sourceData;
    return (
        <Card className="p-3" square>
            <Stack className="pb-2" justifyContent="space-between">
                <Typography variant={'h5'}>預約資訊</Typography>
                <EditAppointForm type={peopleType} sourceData={sourceData} />
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Stack spacing={2}>
                        <MemberAvatar alt={name} src={imageSrc || null} />
                        <ReadTextField className="flex-grow-1" label="預約人姓名">
                            {name}
                        </ReadTextField>
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <ReadTextField label="暱稱">{nickName}</ReadTextField>
                </Grid>
                <Grid item xs={3}>
                    {memberNo ? <ReadTextField label="會員編號">{memberNo}</ReadTextField> : null}
                </Grid>
                <Grid item xs={3}>
                    <ReadTextField label="預約日期">{parseDate(appointmentDate, 'YYYY/MM/DD')}</ReadTextField>
                </Grid>
                <Grid item xs={3}>
                    <ReadTextField label="預約時間">{parseDate(appointmentDate, 'HH:mm')}</ReadTextField>
                </Grid>
                <Grid item xs={3}>
                    <ReadTextField label="預約事項">{appointmentTypeText}</ReadTextField>
                </Grid>
                <Grid item xs={3}>
                    <ReadTextField label="聯絡電話">{cellPhone}</ReadTextField>
                </Grid>
                <Grid item xs={3}>
                    <ReadTextField label="建立擔當">{employeeName}</ReadTextField>
                </Grid>
                <Grid item xs={3}>
                    <ReadTextField label="建立日期">{parseDate(createdDate, 'YYYY/MM/DD HH:mm')}</ReadTextField>
                </Grid>
                <Grid item xs={6}>
                    <ReadTextField label="備註" textProps={{ style: { whiteSpace: 'break-spaces' } }}>
                        {notes}
                    </ReadTextField>
                </Grid>
            </Grid>
        </Card>
    );
};

export default AppointmentPeopleInformationItem;
